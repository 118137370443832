import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-e-contrato',
    templateUrl: './modal-e-contrato.component.html',
    styleUrls: ['./modal-e-contrato.component.css'],
})
export class ModalEContratoComponent implements OnInit, AfterViewInit {
    @Input() docid: string;
    @Input() typedocid: string;
    @Input() idPdV: string;
    typeDocidKyC: string;
    brand: string;
    systemId: string;
    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.typeDocidKyC = this.translateDocumentTypeKyC(this.typedocid);
        this.brand = '1';
        this.systemId = 'DIGO';

    }

    receiveOutput(result) {
        this.activeModal.close(result);
    }
    ngAfterViewInit(): void {
        //En la version 1 esto era necesario para visualizar el componente ya que sin esta parte no se mostraba el kyc
        /*const ocsButton = document.querySelector('ocs-button') as HTMLElement;

        if (ocsButton) {
            ocsButton.click();
        }*/
    }

    translateDocumentTypeKyC(userDataDocumentType):string {
        /*
        DNI: 2
        Pasaporte: 4
        NIE: 3
        */
        const dictionaryEquivalences = {
            type_id_2: '2',
            type_id_4: '1',
            type_id_3: '4'
        }
        console.log('TIPO: ',dictionaryEquivalences[`type_id_${userDataDocumentType}`])
        return dictionaryEquivalences[`type_id_${userDataDocumentType}`];	
    }
}
