import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SimyoService } from '../../services/simyo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TerminalModel } from '../../classes/terminal-model';
import { FormGroup } from '@angular/forms';
import { terminalStatus } from '../../classes/simyo-globals';

@Component({
    selector: 'app-simyo-terminal',
    templateUrl: './simyo-terminal.component.html',
    styleUrls: ['./simyo-terminal.component.css', '../../../../assets/css/simyo-theme.css'],
    encapsulation: ViewEncapsulation.None,
})
export class SimyoTerminalComponent implements OnInit {

    @Input() index = 0;
    @Input() formGroup: FormGroup;

    public terminal: TerminalModel;
    public paymentType: string;
    public errorForm = false;
    public loaded: boolean = false;
    public showAlertTypePay: boolean = false;
    public typeOperation;
    public terminals: TerminalModel[] = [];
    public terminalsFiltered: TerminalModel[] = [];
    public paymentTypes: any[] = [
        {id: 0, name: 'Pago a plazos'},
        {id: 1, name: 'Pago único'}
    ];
    public terminalStatus = terminalStatus;
    public showFilterTypePay:boolean = false;
    public filterPaymentType = -1;
    public brands: any[] = [];
    public filterBrand: string = null;
    public filterModel: string = null;
    public changeTerminal: boolean = false;

    constructor(
        private simyoService: SimyoService,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        if (this.formGroup.get('terminal').value && this.formGroup.get('terminal').value !== '') {
            this.terminal = this.formGroup.get('terminal').value;
        }
        if (this.formGroup.get('terminal_payment_type').value && this.formGroup.get('terminal_payment_type').value !== '') {
            this.paymentType = this.formGroup.get('terminal_payment_type').value;
        }
    }

    open(content) {
        if (this.formGroup.get('type_pay').value && this.formGroup.get('type_pay').value !== '') {
            this.showAlertTypePay = false;
            this.loaded = false;
            this.changeTerminal = false;
            this.typeOperation = this.formGroup.get('type_operation').value;
            this.modalService.open(content, {size: 'xl', centered: true});
            this.simyoService.getTerminals()
                .subscribe((data: any) => {
                    this.terminals = data.msg.filter(terminal => {
                        const optionsTerminal = terminal.terminal_oids.filter((x: any) => {
                            let auxTypeOperation, typeOperation, typePay;
                            auxTypeOperation = this.formGroup.get('type_operation').value === 'registration' ? false : true;
                            typeOperation = x.portability === auxTypeOperation;
                            typePay = x.pay_type === this.formGroup.get('type_pay').value;

                            return typeOperation && typePay;
                        });
                        if (optionsTerminal && optionsTerminal.length) {
                            const found = optionsTerminal.find(x => x.is_installment === true);
                            terminal.is_installment = found ? true : false;
                            return terminal;
                        }
                    });
                    const found = this.terminals.find(x => x['is_installment']);
                    this.showFilterTypePay = found ? true : false;
                    this.terminalsFiltered = this.terminals;

                    this.terminals.forEach((item) => {
                        if (item.brand && !this.brands.includes(item.brand)) {
                            this.brands.push(item.brand);
                        }
                    });
                }, () => {
                    this.loaded = true;
                }, () => {
                    this.loaded = true;
                });
        } else {
            this.showAlertTypePay = true;
        }
    }

    selectTerminalAndPayment(terminal, paymentType) {
        this.terminal = terminal;
        this.paymentType = paymentType;
        this.errorForm = false;
        this.formGroup.get('terminal').patchValue(terminal);
        this.formGroup.get('terminal_payment_type').patchValue(paymentType);
        terminal.paymentType = paymentType;
        this.simyoService.setTerminal(this.index, terminal);
        this.modalService.dismissAll();
    }

    removeSelectedTerminal() {
        this.terminal = null;
        this.paymentType = null;
        this.formGroup.get('terminal').patchValue('');
        this.formGroup.get('terminal_payment_type').patchValue('');
        this.simyoService.removeTerminal(this.index);
    }

    filter() {
        this.terminalsFiltered = this.terminals.filter((item) => {
            let paymentTypeBool = true;
            const brandBool: boolean = this.filterBrand !== null ? item.brand.includes(this.filterBrand) : true;
            const modelBool: boolean = this.filterModel !== null ? item.model.toLowerCase().includes(this.filterModel.toLowerCase()) : true;

            if (this.filterPaymentType === 0) {
                paymentTypeBool = item['is_installment'];
            } else if (this.filterPaymentType === 1) {
                paymentTypeBool = +item.price > 0;
            }

            return paymentTypeBool && brandBool && modelBool;
        });
    }

    selectFilterBrand(brand: string) {
        this.filterBrand = brand;
        this.filter();
    }

    selectFilterPaymentType(paymentType: any) {
        this.filterPaymentType = parseInt(paymentType, null);
        this.filter();
    }

    inputModel(model: string) {
        this.filterModel = model;
        this.filter();
    }

}
