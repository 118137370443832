import { createReducer, on } from '@ngrx/store';
import { OrangeProvince } from '../models/masterAllResponse';
import { filteredPrepaidOrderList, resetPrepaidOrderList, resetState, setConfiFormDataPrepaid, setErrorCancelLinePrepaid, setErrorMaster, setLoadingCancelLinePrepaid, setLoadingMaster, setMasterAll, setPrepaidOrderInfo, setPrepaidOrderList, setProvinceByPostalCode, setRatePrepaid, setStateInfoEsim, setStateSendEmail, setTotal, setUserFormDataPrepaid, setWelcomePackPrepaid, showShoppingCart, setOrderDraftId } from './orange.actions';
import { prepaidInitialState } from './orange.state';


const orangeReducer = createReducer(prepaidInitialState,
    // Prepaid
    on(setConfiFormDataPrepaid, (state, { form }) => ({
            ...state,
            configuration_form_values: {
                ...state.configuration_form_values,
                rate: form.rate,
                welcome_pack: form.welcome_pack,
                msisdns: form.msisdns,
                balance: form.balance,
                type_operation: form.type_operation,
                esim_number_lines: form.esim_number_lines
            }
        })
    ),

    on(setUserFormDataPrepaid, (state, { form }) => ({
            ...state,
            user_data_form_values: {
                ...state.user_data_form_values,
                document_type: form.document_type,
                document_number: form.document_number,
                nacionality: form.nacionality,
                birth_date: form.birth_date,
                name: form.name,
                first_surname: form.first_surname,
                second_surname: form.second_surname,
                sex: form.sex,
                postal_code: form.postal_code,
                // province: form.province,
                email: form.email
            }
        })
    ),

    on(setWelcomePackPrepaid, (state, { welcomePack }) => (
        { ...state, welcome_pack_selected: welcomePack })
    ),

    on(setRatePrepaid, (state, { rate }) => (
        { ...state, rate_selected: rate })
    ),

    on(setPrepaidOrderInfo, (state, { order }) => (
        {
            ...state,
            orderInfo: {
                ...order,
                total_price: (order && order.lines && order.lines.length > 0) ? order.lines.map(line => line.contracted_price).reduce((prev, next) => prev + next) : '0.00'
            }
        })
    ),

    on(setPrepaidOrderList, (state, { orderList }) => {
        // Concatenamos los nuevos valores
        orderList = {...state.orderList, data: state.orderList.data.concat(orderList.data)};
        return {
            ...state,
            orderList: orderList,
            orderListFiltered: orderList.data
        };
    }),

    on(setStateInfoEsim, (state, { currentIndex, newState }) => (
        { ...state,
            orderInfo: {
                ...state.orderInfo,
                lines: state.orderInfo.lines.map((line, index) => {
                    if (currentIndex === index) {
                        return {...line, showEsim: newState};                   
                    }
                    return {...line};
                })
            }
        })
    ),

    on(setErrorCancelLinePrepaid, (state, { msisdn, error }) => (
        { ...state,
            orderInfo: {
                ...state.orderInfo,
                lines: state.orderInfo.lines.map(line => {return {...line, errorCanceled: line.msisdn === msisdn ? error : line.errorCanceled}})
            }
        })
    ),

    on(setLoadingCancelLinePrepaid, (state, { msisdn, loading }) => (
        { ...state,
            orderInfo: {
                ...state.orderInfo,
                lines: state.orderInfo.lines.map(line => {return {...line, cancelLoading: line.msisdn === msisdn ? loading : false}})
            }
        })
    ),

    on(setStateSendEmail, (state, { currentIndex, newState }) => (
        { ...state,
            orderInfo: {
                ...state.orderInfo,
                lines: state.orderInfo.lines.map((line, index) => {
                    if (currentIndex === index) {
                        return {...line, showSendEmail: newState};
                    }
                    return {...line};
                })
            }
        })
    ),

    on(filteredPrepaidOrderList, (state, { text }) => {
        function filterOrderListByText(): any[] {
            return state.orderList.data.filter(item => {
                const fullName: string = item.customer_full_name.toLowerCase();
                const fullNameNormalized = fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                let document: string;

                if (item.customer_document) {
                    document = item.customer_document.toLowerCase();
                }

                return document ? fullName.includes(text.toLowerCase()) || fullNameNormalized.includes(text.toLowerCase()) ||
                    document.includes(text.toLowerCase()) : fullName.includes(text.toLowerCase()) ||
                    fullNameNormalized.includes(text.toLowerCase());
            });
        }
        return {
            ...state,
            orderListFiltered: filterOrderListByText()
        };
    }),

    on(setProvinceByPostalCode, (state, { postalCode }) => (
        { ...state, user_data_form_values: {...state.user_data_form_values, province: getProvinceByPostalCode(postalCode, state.master.provinces)} })
    ),

    on(resetPrepaidOrderList, (state) => (
        { ...state, orderList: prepaidInitialState.orderList, orderListFiltered: []})
    ),

    // Generic
    on(setTotal, (state, { total }) => (
        { ...state, shopping_cart_total: total })
    ),

    on(showShoppingCart, (state, { show }) => (
        { ...state, show_shopping_cart: show })
    ),

    on(setErrorMaster, (state, { error }) => (
        { ...state, errorMaster: error })
    ),

    on(setLoadingMaster, (state, { loading }) => (
        { ...state, loadingMaster: loading })
    ),

    on(setMasterAll, (state, { master }) => (
        { ...state,
            master: master,
            welcome_pack_selected: master.rates.length === 1 ? master.rates[0] : null,
            rate_selected: master.rates.length === 1 && master.rates[0].rates.length === 1 ? master.rates[0].rates[0] : null
        })
    ),

    on(resetState, (state) => (
        state = prepaidInitialState)
    ),
    on(setOrderDraftId, (state, { orderDraftId }) => (
        { ...state, orderDraftId })
    ),
);

function getProvinceByPostalCode(postalCode: number, provinces: OrangeProvince[]): OrangeProvince {
    const postalCodeNormalized = ('00000' + postalCode.toString()).slice(-5);
    if (postalCodeNormalized != null && postalCodeNormalized.length >= 2 && provinces && provinces.length) {
        const result = provinces.filter((province: OrangeProvince) => {
            return province.code === postalCodeNormalized.substring(0, 2);
        });

        if (result && result.length) {
            return result[0];
        }
    }
}

export function orangePrepaidReducer(state, action) {
    return orangeReducer(state, action);
}
