import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { TokenHasExpiredComponent } from 'src/app/core/components/token-has-expired/token-has-expired.component';
import { allLocalStorage } from '../constantes';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    dialogTokenHasExpired: any = null;

    constructor(public dialog: MatDialog) {
    }

    private parseJwt(token): any {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        } catch(error) {
            return false;
        }
    }

    checkIfTokenHasExpired(): boolean {
        const access_token = localStorage.getItem(allLocalStorage.accessToken);

        if (access_token && access_token !== '') {
            const jwt = this.parseJwt(access_token);
            if (!jwt) {
                return true;
            }
            const exp = jwt.exp * 1000;

            return new Date().getTime() >= exp;
        }

        return true;
    }

    getToken(): string {
        if (this.checkIfTokenHasExpired()) {
            console.error('JWT expired');

            if (this.dialogTokenHasExpired == null) {
                this.dialogTokenHasExpired = this.dialog.open(TokenHasExpiredComponent, {
                    // position: {top: '300px'},
                    width: '350px',
                    disableClose: true
                });
            }

            return;
        }

        return localStorage.getItem(allLocalStorage.accessToken);
    }
}
