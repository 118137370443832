import { orangeRefillAmounts } from "src/app/shared/constantes";
import { BonosAdicionalesOrder, BonosAdicionalesOrderListBody } from "../models/bonosAdicionalesInfoResponse";
import { MasterAllOrange, OrangeBonos, OrangeCountry, OrangeDocumentType, OrangeGender, OrangeProvince, OrangeRate, OrangeRatesWP, OrangeStreetType } from "../models/masterAllResponse";
import { MsisdnInfo } from "../models/msisdnInfoResponse";
import { PrepaidOrder } from "../models/prepaidInfoResponse";

export const prepaidInitialState: IOrangePrepaidState = {
    // Prepaid
    welcome_pack_selected: null,
    rate_selected: null,
    configuration_form_values: {
        rate: null,
        msisdns: [],
        balance: 0
    },
    user_data_form_values: {
        document_type: null,
        document_number: '',
        nacionality: null,
        birth_date: '',
        name: '',
        first_surname: '',
        second_surname: '',
        sex: null,
        postal_code: '',
        province: null,
        email: '',
    },
    orderInfo: null,
    orderList: {
        current_page: 1,
        data: [],
        last_page: 1,
        total: 1
    },
    orderListFiltered: [],
    // Generic
    shopping_cart_total: 0,
    show_shopping_cart: false,
    master: null,
    loadingMaster: false,
    errorMaster: false,
    orderDraftId: null
};

export const additionalBonosInitialState: IOrangeAdditionalBonosState = {
    // Additional bonos
    configuration_form_values: {
        msisdn: '',
        rate: null,
        correctBalance: true,
        customer_info: null
    },
    orderInfo: null,
    orderList: {
        current_page: 1,
        data: [],
        last_page: 1,
        total: 1
    },
    orderListFiltered: [],
    // Generic
    shopping_cart_total: 0,
    show_shopping_cart: false,
    master: null,
};

export interface IOrangePrepaidState {
    // Prepaid
    welcome_pack_selected: OrangeRatesWP;
    rate_selected: OrangeRate;
    configuration_form_values: IConfigurationFormPrepaid;
    user_data_form_values: IUserDataFormPrepaid;
    orderInfo: PrepaidOrder;
    orderList: any;
    orderListFiltered: any[];
    // Generic
    shopping_cart_total: number;
    show_shopping_cart: boolean;
    master: MasterAllOrange;
    loadingMaster: boolean;
    errorMaster: boolean;
    orderDraftId?: string;
}

export interface IOrangeAdditionalBonosState {
    // Additional bonos
    configuration_form_values: IConfigurationFormAdditionalBonos;
    orderInfo: BonosAdicionalesOrder;
    orderList: BonosAdicionalesOrderListBody;
    orderListFiltered: BonosAdicionalesOrder[];
    // Generic
    shopping_cart_total: number;
    show_shopping_cart: boolean;
    master: MasterAllOrange;
}

export interface IConfigurationFormAdditionalBonos {
    msisdn: string;
    rate: OrangeBonos;
    correctBalance: boolean;
    customer_info: MsisdnInfo;
}

export interface IConfigurationFormPrepaid {
    rate: OrangeRate;
    msisdns: string[];
    balance: number;
}

export interface IUserDataFormPrepaid {
    document_type: OrangeDocumentType;
    document_number: string;
    nacionality: OrangeCountry;
    birth_date: string;
    name: string;
    first_surname: string;
    second_surname: string;
    sex: OrangeGender;
    postal_code: string;
    province: OrangeProvince;
    email: string;
}

export interface IRefillAmount {
    id: string | 'custom';
    amount: number;
    disabled: boolean;
}