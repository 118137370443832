import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';

@Injectable({
    providedIn: 'root'
})
export class SimyoShoppingCartService {
    private show: any;
    private stepper = null;
    private orderFinished: boolean = false;
    private showIcon: BehaviorSubject<boolean>;

    constructor() {
        this.show = new BehaviorSubject<boolean>(false);
        this.showIcon = new BehaviorSubject<boolean>(false);
    }

    setShow(data: boolean) {
        this.show.next(data);
    }

    getShow() {
        return this.show.asObservable();
    }

    setStepper(stepper: MatStepper) {
        this.stepper = stepper;
    }

    getStepper() {
        return this.stepper;
    }

    setOrderFinished(finished: boolean){
        this.orderFinished = finished;
    }

    getOrderFinished(){
        return this.orderFinished;
    }

    setShowIcon(data: boolean) {
        this.showIcon.next(data);
    }

    getShowIcon() {
        return this.showIcon.asObservable();
    }
}
