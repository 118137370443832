import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HmRateService {
    private filterRatesByBrand: any;
    private filterRatesByType: any;

    constructor() {
        this.filterRatesByBrand = new BehaviorSubject<string>(null);
        this.filterRatesByType = new BehaviorSubject<string>(null);
    }

    setFilterRatesByBrand(data) {
        this.filterRatesByBrand.next(data);
    }

    getFilterRatesByBrand() {
        return this.filterRatesByBrand.asObservable();
    }

    setFilterRatesByType(data) {
        this.filterRatesByType.next(data);
    }

    getFilterRatesByType() {
        return this.filterRatesByType.asObservable();
    }

}
