import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { SimyoNormalizadorService } from 'src/app/simyo/services/simyo-normalizador.service';
import { SimyoResultadoService } from 'src/app/simyo/services/simyo-resultado.service';
import { SimyoService } from 'src/app/simyo/services/simyo.service';
import { SimyoGlobals } from 'src/app/simyo/classes/simyo-globals';
import { Subscription } from 'rxjs';
import { masterAllSimyo, provinceSimyo, trackTypeSimyo } from 'src/app/simyo/models/masterAllSimyo';

@Component({
    selector: 'app-resultado',
    templateUrl: './resultado.component.html',
    styleUrls: ['./resultado.component.css']
})
export class ResultadoComponent implements OnInit, OnDestroy {
    @Input() resultFormGroup: FormGroup;

    resultadoNormalizacion: any = null;
    resultadoCobertura: any = null;
    public provinceNameSimyo: string;
    public migrationData: any;

    // Master
    public simyoRoadTypes: trackTypeSimyo[] = [];
    public simyoProvinces: provinceSimyo[] = [];

    private allSubscription: Subscription[] = [];

    constructor(
        protected simyoNormalizadorService: SimyoNormalizadorService,
        private simyoResultadoService: SimyoResultadoService,
        private simyoService: SimyoService,
        private router: Router,
        public simyoGlobals: SimyoGlobals
    ) { }

    ngOnInit(): void {
        // SIMYO
        if (this.router.url.includes('simyo')) {
            this.allSubscription.push(this.simyoService.getMasterAll()
                .subscribe((data: masterAllSimyo) => {
                    if (data) {
                        // Road types
                        this.simyoRoadTypes = data.track_types;
                        if (this.resultadoCobertura) {
                            this.resultadoCobertura.get('tipoViaCobertura').patchValue(this.simyoGlobals.getRoadTypeLabel(this.simyoRoadTypes, this.resultadoCobertura.value.tipoViaCobertura));
                        }
                        // Provinces
                        this.simyoProvinces = data.provinces;
                        if (this.resultadoCobertura && this.resultadoCobertura.value) {
                            this.provinceNameSimyo = this.simyoGlobals.getProvinceLabel(this.simyoProvinces, this.resultadoCobertura.value.provinciaCobertura);
                        }
                    }
                })
            );

            // Observador para recoger los datos de la normalización
            this.allSubscription.push(this.simyoNormalizadorService.getFormularioNormalizacion()
                .subscribe((valor) => {
                    if (valor) {
                        this.resultadoNormalizacion = valor.getRawValue();
                    }
                })
            );

            // Observador para recoger los datos de la cobertura
            this.allSubscription.push(this.simyoNormalizadorService.getResultadoCobertura()
                .subscribe((valor) => {
                    if (valor) {
                        this.resultadoCobertura = valor;
                        this.resultadoCobertura.get('tipoViaCobertura').patchValue(this.simyoGlobals.getRoadTypeLabel(this.simyoRoadTypes, this.resultadoCobertura.value.tipoViaCobertura));
                        this.provinceNameSimyo = this.simyoGlobals.getProvinceLabel(this.simyoProvinces, this.resultadoCobertura.value.provinciaCobertura);
                    }
                })
            );
        }

    }

    onEdit() {
        // SIMYO
        if (this.router.url.includes('simyo')) {
            this.simyoNormalizadorService.setMuestraCobertura(false);
            this.simyoNormalizadorService.setMuestraNormalizacion(true);
            this.simyoNormalizadorService.setMuestraBotonNormalizacion(true);
            this.simyoNormalizadorService.setErrorPeticionCobertura(false);
            this.simyoNormalizadorService.setMsgErrorCobertura(null);
            this.simyoNormalizadorService.setThereIsCoverage(false);
            this.simyoResultadoService.setDatosEdicion(this.resultadoNormalizacion);
        }

    }

    isNumber(value : string) : any {
        return isNaN(Number(value)) ? value : Number(value);
    }

    includeFloor(value : string) : string {
        let returnValue = '';
        if (typeof value === 'string' && !value.toLowerCase().includes('planta')) {
            returnValue = 'Planta';
        }
        return returnValue;
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

}
