<div id="shopping-cart" class="sidenav" [ngStyle]="{'width.px': (orangeAdditionalBonos$ | async)?.show_shopping_cart ? '350' : '0'}" *ngIf="(orangeAdditionalBonos$ | async)?.show_shopping_cart">
    <div class="sidebar-header">
        <a class="closebtn cursor-pointer" (click)="closeShoppingCart()">
            <img src="./assets/img/times.svg" class="img-fluid icon-close" width="17">
        </a>
        <h1 class="mb-2 text-center" >
            <b>Resumen</b>
        </h1>
    </div>
    <ng-template [ngIf]="(orangeAdditionalBonos$ | async)?.configuration_form_values?.customer_info || (orangePrepaid$ | async)?.configuration_form_values?.rate" [ngIfElse]="emptyShoppingCart">
        <!-- Prepago -->
        <ng-template [ngIf]="(orangePrepaid$ | async)?.configuration_form_values?.rate">
            <!-- Titulo -->
            <div class="resumen d-flex justify-content-between align-items-center mt-2">
                <h6 class="resumen-color">
                    <b>{{getTitleAltaType((orangePrepaid$ | async)?.welcome_pack_selected, (orangePrepaid$ | async)?.configuration_form_values.type_operation)}} Prepago</b>
                </h6>
                <small class="text-muted trash-carrito"></small>
            </div>
            <!-- INFO GENERAL -->
            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="(orangePrepaid$ | async)?.user_data_form_values?.name && (orangePrepaid$ | async)?.user_data_form_values?.name !== ''">
                <span class="sidebar-info5">
                    <b>{{ (orangePrepaid$ | async)?.user_data_form_values?.name + ' ' + (orangePrepaid$ | async)?.user_data_form_values?.first_surname + ' ' + (orangePrepaid$ | async)?.user_data_form_values?.second_surname }}</b>
                </span>
            </div>
            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="(orangePrepaid$ | async)?.user_data_form_values?.document_type?.name && (orangePrepaid$ | async)?.user_data_form_values?.document_type?.name !== '' && (orangePrepaid$ | async)?.user_data_form_values?.document_number && (orangePrepaid$ | async)?.user_data_form_values?.document_number !== ''">
                <span class="sidebar-info5">
                    <img src="./assets/img/file.svg" class="img-fluid me-1" width="13">
                    {{ (orangePrepaid$ | async)?.user_data_form_values?.document_type?.name }}: <b>{{ (orangePrepaid$ | async)?.user_data_form_values?.document_number }}</b>
                </span>
            </div>
            <!-- ESIM -->
            <ng-container *ngIf="isEsimRegistration((orangePrepaid$ | async)); else defaultCard">
                <div *ngFor="let line of getArrayMultiLine((orangePrepaid$ | async)?.configuration_form_values.esim_number_lines); index as i">
                    <app-orange-rate-card
                        [title]="permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim) ? (i + 1) + 'ª línea móvil' : ''"
                        [showEditButton]="true"
                        [showDeleteButton]="true"
                        [productId]="i"
                        [titleLine]="permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim) ? getAltaType((orangePrepaid$ | async)?.welcome_pack_selected, (orangePrepaid$ | async)?.configuration_form_values.type_operation) : ''"
                        [rateName]="(orangePrepaid$ | async)?.welcome_pack_selected?.name + ' - ' + (orangePrepaid$ | async)?.configuration_form_values?.rate?.name"
                        [price]="(+(orangePrepaid$ | async)?.configuration_form_values?.rate?.price).toFixed(2)"
                        (onDelete)="removeLine()"
                        (onEdit)="goToEditEsim()"
                    ></app-orange-rate-card>
                </div>
            </ng-container>
            <!-- NO ESIM o ESIM PORTABILIDAD-->
            <ng-template #defaultCard>
                <div *ngIf="normalizedMsisdns((orangePrepaid$ | async)?.configuration_form_values?.msisdns) == 0 && (orangePrepaid$ | async)?.welcome_pack_selected">
                    <app-orange-rate-card
                        [title]="'1ª línea móvil'"
                        [showEditButton]="true"
                        [showDeleteButton]="true"
                        [productId]="0"
                        [rateName]="(orangePrepaid$ | async)?.welcome_pack_selected?.name + ' - ' + (orangePrepaid$ | async)?.configuration_form_values?.rate?.name"
                        [price]="(+(orangePrepaid$ | async)?.configuration_form_values?.rate?.price).toFixed(2)"
                        (onEdit)="goToEdit(0)"
                    ></app-orange-rate-card>
                </div>
                <div *ngFor="let line of normalizedMsisdns((orangePrepaid$ | async)?.configuration_form_values?.msisdns); index as i">
                    <app-orange-rate-card
                        [title]="(i + 1) + 'ª línea móvil'"
                        [showEditButton]="true"
                        [showDeleteButton]="true"
                        [productId]="i"
                        [titleLine]="getAltaType((orangePrepaid$ | async)?.welcome_pack_selected, (orangePrepaid$ | async)?.configuration_form_values.type_operation) + line"
                        [rateName]="(orangePrepaid$ | async)?.welcome_pack_selected?.name + ' - ' + (orangePrepaid$ | async)?.configuration_form_values?.rate?.name"
                        [price]="(+(orangePrepaid$ | async)?.configuration_form_values?.rate?.price).toFixed(2)"
                        (onEdit)="goToEdit(i)"
                        (onDelete)="removeMsisdn(i)"
                    ></app-orange-rate-card>
                </div>
            </ng-template>
            <br>
            <!-- Recarga -->
            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="(orangePrepaid$ | async)?.configuration_form_values?.balance">
                <span class="sidebar-info">
                    <b>Recarga</b>
                </span>
                <small class="text-muted">
                    <!-- Precio tarifa -->
                    <span class="sidebar-info3">
                        <b>{{ (orangePrepaid$ | async)?.configuration_form_values?.balance }} €</b>
                    </span>
                </small>
            </div>
            <!-- Importe Total -->
            <div class="resumen-total total d-flex justify-content-between align-items-center" *ngIf="getTotalPrice((orangePrepaid$ | async)?.configuration_form_values?.msisdns, (orangePrepaid$ | async)?.rate_selected) > 0">
                <h5 class="total-color">
                    <b>Pago mensual </b><small>(Total)</small>
                </h5>
                <h5 class="text-auto">
                    <b>{{ getTotalPrice((orangePrepaid$ | async)?.configuration_form_values?.msisdns, (orangePrepaid$ | async)?.rate_selected).toFixed(2) }} €</b>
                </h5>
            </div>
            <!-- Importe Total Esim -->
            <div class="resumen-total total d-flex justify-content-between align-items-center" *ngIf="getTotalPriceEsim((orangePrepaid$ | async)?.configuration_form_values.esim_number_lines, (orangePrepaid$ | async)?.rate_selected) > 0">
                <h5 class="total-color">
                    <b>Pago mensual </b><small>(Total)</small>
                </h5>
                <h5 class="text-auto">
                    <b>{{ getTotalPriceEsim((orangePrepaid$ | async)?.configuration_form_values.esim_number_lines, (orangePrepaid$ | async)?.rate_selected).toFixed(2) }} €</b>
                </h5>
            </div>
            <div class="resumen d-flex justify-content-between align-items-center mb-5">
                <span class="sidebar-info">
                    <b>IVA INCLUIDO</b>
                </span>
            </div>
        </ng-template>
        <!-- Additional bonos -->
        <ng-template [ngIf]="(orangeAdditionalBonos$ | async)?.configuration_form_values?.customer_info">
            <!-- Titulo -->
            <div class="resumen d-flex justify-content-between align-items-center mt-2">
                <h6 class="resumen-color">
                    <b>Bonos adicional</b>
                </h6>
                <small class="text-muted trash-carrito"></small>
            </div>
            <!-- INFO GENERAL -->
            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="(orangeAdditionalBonos$ | async)?.configuration_form_values?.customer_info?.first_name && (orangeAdditionalBonos$ | async)?.configuration_form_values?.customer_info?.first_name !== ''">
                <span class="sidebar-info5">
                    <b>{{ (orangeAdditionalBonos$ | async)?.configuration_form_values?.customer_info?.first_name + ' ' + (orangeAdditionalBonos$ | async)?.configuration_form_values?.customer_info?.last_name }}</b>
                </span>
            </div>
            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="(orangeAdditionalBonos$ | async)?.configuration_form_values?.customer_info?.document_type_name && (orangeAdditionalBonos$ | async)?.configuration_form_values?.customer_info?.document_type_name !== ''">
                <span class="sidebar-info5">
                    <img src="./assets/img/file.svg" class="img-fluid me-1" width="13">
                    {{ (orangeAdditionalBonos$ | async)?.configuration_form_values?.customer_info?.document_type_name }}: <b>{{ (orangeAdditionalBonos$ | async)?.configuration_form_values?.customer_info?.document_number }}</b>
                </span>
            </div>
            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="(orangeAdditionalBonos$ | async)?.configuration_form_values?.msisdn && (orangeAdditionalBonos$ | async)?.configuration_form_values?.msisdn !== ''">
                <span class="sidebar-info5">
                    <img src="./assets/img/phone.svg" class="img-fluid me-1" width="13">
                    Msisdn: <b>{{ (orangeAdditionalBonos$ | async)?.configuration_form_values?.msisdn }}</b>
                </span>
            </div>
            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="(orangeAdditionalBonos$ | async)?.configuration_form_values?.rate">
                <span class="sidebar-info5">
                    <img src="./assets/img/package2.svg" class="img-fluid me-1" width="13">
                    {{ (orangeAdditionalBonos$ | async)?.configuration_form_values?.rate?.name }}
                </span>
                <small class="text-muted">
                    <!-- Precio tarifa -->
                    <span class="sidebar-info3">
                        <b>{{ (+(orangeAdditionalBonos$ | async)?.configuration_form_values?.rate?.price).toFixed(2) }} €</b>
                    </span>
                </small>
            </div>
            <ng-container *ngIf="+(orangeAdditionalBonos$ | async)?.configuration_form_values?.rate?.price > 0">
                <br>
                <div class="resumen d-flex justify-content-between align-items-center mt-2">
                    <span class="sidebar-info">
                        <b>Total</b>
                    </span>
                    <small class="text-muted trash-carrito">
                        <!-- Precio tarifa -->
                        <span class="sidebar-info2">
                            <b>{{ (+(orangeAdditionalBonos$ | async)?.configuration_form_values?.rate?.price).toFixed(2) }} €</b>
                        </span>
                    </small>
                </div>
                <!-- Importe Total -->
                <div class="resumen-total total d-flex justify-content-between align-items-center">
                    <h5 class="total-color">
                        <b>pago inicial</b>
                    </h5>
                    <h5 class="text-auto">
                        <b>{{ (+(orangeAdditionalBonos$ | async)?.configuration_form_values?.rate?.price).toFixed(2) }} €</b>
                    </h5>
                </div>
                <!-- Importe Total -->
                <div class="resumen d-flex justify-content-between align-items-center mb-5">
                    <span class="sidebar-info">
                        <b>IVA INCLUIDO</b>
                    </span>
                </div>
            </ng-container>
        </ng-template>
    </ng-template>
    <ng-template #emptyShoppingCart>
        <div class="container">
            <p class="py-4">Todavía no se han añadido servicios al pedido.</p>
        </div>
    </ng-template>
</div>
