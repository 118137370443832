import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HomeService } from 'src/app/home/services/home.service';
import { availableTechnologies, technologyTypes } from 'src/app/shared/constantes';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { autocompleteValidator } from 'src/app/utils/validators/autocomplete-validator';
import { SimyoNormalizadorService } from '../../services/simyo-normalizador.service';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { SimyoService } from '../../services/simyo.service';

@Component({
    selector: 'app-simyo-modal-address',
    templateUrl: './simyo-modal-address.component.html',
    styleUrls: ['./simyo-modal-address.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoModalAddressComponent implements OnInit {

    @Input() pdv: Ipdv;
    @Input() formularioNormalizacion: any;
    @Input() datosNormalizacion: any;
    @Input() formGroup: any;

    public masterCoverage: any;
    public newAddressFormGroup: FormGroup;
    public isLoading: boolean = false;
    public errorNewAddress: string = '';
    public floorFilteredOptions: Observable<any[]>;

    constructor(
        public activeModal: NgbActiveModal,
        private homeService: HomeService,
        private formBuilder: FormBuilder,
        private normalizadorService: SimyoNormalizadorService,
        private permSv: PermissionService,
        private simyoService: SimyoService
    ) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.errorNewAddress = '';

        this.newAddressFormGroup = this.formBuilder.group({
            planta: ['', [Validators.required, blankSpaceValidator()]],
            escalera: [''],
            tipoPuerta: [''],
            puerta: [''],
            bis: [''],
            portal: [''],
            letraPortal: [''],
            bloqueFinca: [''],
            identificadorBloqueFinca: ['']
        });
        this.newAddressFormGroup.disable();

        this.homeService.getMasterCoverage()
            .subscribe((data: any) => {
                this.masterCoverage = data;
                this.floorFilteredOptions = this.newAddressFormGroup.get('planta').valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value, this.masterCoverage?.floor_number))
                );
                this.newAddressFormGroup.get('planta').setValidators([Validators.required, autocompleteValidator(this.masterCoverage?.floor_number.map(i => i.code))]);
                this.newAddressFormGroup.get('planta').updateValueAndValidity();
                this.newAddressFormGroup.enable();
                this.isLoading = false;
            });
    }

    createAddress() {
        if (this.newAddressFormGroup.valid && this.datosNormalizacion && this.datosNormalizacion.length) {
            let body = {
                address: {
                    arvato_code: this.datosNormalizacion[0].ospAddressExternalId.find(x => x.refId.toString().toLowerCase() === 'arvato').externalId,
                    street_number: this.datosNormalizacion[0].streetNr,
                    street_number_suffix: this.newAddressFormGroup.get('bis').value, // master
                    street_name: this.datosNormalizacion[0].streetName,
                    street_type: this.findInMaster(this.datosNormalizacion[0].streetType, this.masterCoverage.street_type, 'code', 'CALLE'), // master
                    post_code: this.datosNormalizacion[0].postCode || this.formularioNormalizacion.get('codigoPostalNormaliza').value,           
                    city: this.datosNormalizacion[0].city,
                    osp_ine_city_code: this.datosNormalizacion[0].ospINECityCode,
                    province: this.findInMaster(('00000' + this.datosNormalizacion[0].postCode.toString()).slice(-5).substring(0, 2), this.masterCoverage.province, 'code', this.datosNormalizacion[0].stateOrProvince), // master
                    building_name: this.newAddressFormGroup.get('portal').value, // master
                    building_name_suffix: this.newAddressFormGroup.get('letraPortal').value,
                    sub_unit_type: this.newAddressFormGroup.get('bloqueFinca').value, // master
                    sub_unit_number: this.newAddressFormGroup.get('identificadorBloqueFinca').value,
                    stair_case: this.newAddressFormGroup.get('escalera').value,
                    floor_number: this.newAddressFormGroup.get('planta').value,
                    door_type: this.newAddressFormGroup.get('tipoPuerta').value,
                    door: this.newAddressFormGroup.get('puerta').value
                }
            };
            this.isLoading = true;
            this.errorNewAddress = '';
            this.homeService.createNewAddress(body)
                .subscribe((newAddress: any) => {
                    this.consultaCoberturaSimyo(newAddress);
                }, (error) => {
                    this.errorNewAddress = error?.error?.msg || 'No se ha podido crear la dirección en este momento. Inténtelo de nuevo más tarde';
                    this.isLoading = false;
                });
        } else {
            this.newAddressFormGroup.markAllAsTouched();
        }        
    }

    consultaCoberturaSimyo(body: any) {
        this.normalizadorService.consultaCobertura(body)
            .subscribe((data: any) => {
                // Solucion para que al cambiar el endpoint no falle del comun al rm
                if (data.msg) {
                    data = data.msg;
                }
                this.isLoading = false;
                if (data.cimaArvatoCode && data.cimaArvatoCode !== '' && data.technology && data.technology.length && availableTechnologies.includes(data.technology[0])) {
                    this.fillFormCobertura();
                    this.formGroup.get('resultadoConsultaCobertura').patchValue(data);
                    this.normalizadorService.setMuestraNormalizacion(false);
                    this.normalizadorService.setMsgErrorCobertura('');
                    if(data.technology.includes(technologyTypes.dir)) {
                        this.simyoService.setVirtualInstallationData({
                            gescal37: body?.ospAddressExternalId?.find(x => x.refId === 'gescal')?.externalId,
                            isDirectBroadband: true
                        });
                    }
                    this.normalizadorService.setThereIsCoverage(true);
                    this.clearFormGroup();
                    this.normalizadorService.setResultadoCobertura(this.formGroup);
                    this.formGroup.enable();
                    this.activeModal.dismiss();
                } else {
                    this.normalizadorService.setThereIsCoverage(false);
                    this.normalizadorService.setClientIsCover(false);
                    this.errorNewAddress = 'Lo sentimos, pero la dirección sugerida no tiene cobertura';
                    this.formGroup.enable();
                }
            }, (error) => {
                this.errorNewAddress = 'Ha ocurrido un error al realizar la consulta. Por favor inténtalo más tarde';
                this.isLoading = false;
                this.formGroup.enable();
            });
    }

    clearFormGroup() {
        for (const field in this.formGroup.controls) {
            if (this.formGroup.get(field).value === 'No tiene') {
                this.formGroup.get(field).patchValue(null);
            }
        }
    }

    fillFormCobertura() {
        this.formGroup.get('plantaCobertura').patchValue(this.newAddressFormGroup.get('planta').value);
        this.formGroup.get('escaleraCobertura').patchValue(this.newAddressFormGroup.get('escalera').value);
        this.formGroup.get('tipoPuertaCobertura').patchValue(this.newAddressFormGroup.get('tipoPuerta').value);
        this.formGroup.get('puertaCobertura').patchValue(this.newAddressFormGroup.get('puerta').value);
        this.formGroup.get('bisCobertura').patchValue(this.newAddressFormGroup.get('bis').value);
        this.formGroup.get('portalCobertura').patchValue(this.newAddressFormGroup.get('portal').value);
        // this.formGroup.get('').patchValue(this.newAddressFormGroup.get('letraPortal').value);
        this.formGroup.get('bloqueCobertura').patchValue(this.newAddressFormGroup.get('bloqueFinca').value);
        // this.formGroup.get('').patchValue(this.newAddressFormGroup.get('identificadorBloqueFinca').value);
    }

    findInMaster(value, allValues, returnOption, defaultValue): string {
        if (value && value !== '' && allValues && allValues.length) {
            const valueNormalized = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            const filterValue = allValues.filter(option => option.code.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(valueNormalized) === 0 || option.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(valueNormalized) === 0)
            return filterValue && filterValue.length ? filterValue[0][returnOption] : defaultValue;
        }
        return defaultValue;
    }

    private _filter(value: string, values: any[]): any[] {
        const filterValue = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        return values.filter(option => option.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(filterValue) >= 0);
    }

    displayFn = (floor) => {
        if (this.masterCoverage && this.masterCoverage?.floor_number) {
            const found = this.masterCoverage?.floor_number.find(x => x.code === floor);
            return found ? found.value : '';
        } 
        return '';
    }

}
