<div #filling></div>

<nav #navbar id="navbar" class="navbar navbar-expand-lg navbar-expand bg-dark">
    <div class="container">
        <!-- Marca -->
        <a class="navbar-brand" routerLink="simyo" [queryParams]="iFrameMode ? {frame: 'true'} : ''" >
            <img src="./assets/img/logo-simyo.svg" class="img-fluid icon-nav2" width="130">
        </a>
        <div class="navbar-nav align-items-center me-auto" [ngSwitch]="href">
            <!-- Proceso de contratación -->
            <span class="navbar-text contratacion" *ngSwitchCase="'/simyo/convergente'">
                Fibra y Móvil
            </span>
            <span class="navbar-text contratacion" *ngSwitchCase="'/simyo/solo-movil'">
                Móvil
            </span>
            <span class="navbar-text contratacion" *ngSwitchCase="'/simyo/solo-fibra'">
                Fibra
            </span>
            <span class="navbar-text contratacion" *ngSwitchCase="'/simyo/duplicados'">
                Duplicados
            </span>
            <span class="navbar-text contratacion" *ngSwitchCase="'/simyo/listado'">
                Listado de operaciones
            </span>
            <span class="navbar-text contratacion" *ngSwitchCase="'/simyo/tarifas'">
                Lista de tarifas
            </span>
            <span class="navbar-text contratacion" *ngSwitchCase="'/simyo/listado-duplicados'">
                Lista de duplicados
            </span>
        </div>

        <div class="nav-item" *ngIf="false">
            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Simyo</a>
            <div class="dropdown-menu fade-up">
                <div class="row">
                    <div class="col-xl-6 themed-grid-col bg-white">
                        <li><a class="dropdown-item" routerLink="/simyo/convergente">Fijo + Móvil</a></li>
                        <li><a class="dropdown-item" routerLink="/simyo/solo-movil">Solo Móvil</a></li>
                        <li><a class="dropdown-item" routerLink="/simyo/solo-fibra">Solo Fijo</a></li>
                        <li><a class="dropdown-item" routerLink="/simyo/listado">Listado</a></li>
                        <li><a class="dropdown-item" routerLink="/simyo/tarifas">Tarifas</a></li>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-2 my-lg-0">
            <span class="carrito" (click)="openShoppingCart()" *ngIf="showIconSimyoShoppingCart">
                <i-feather name="shopping-cart" class="icon-cart"></i-feather>
                <div class="item">
                    <!-- Total de carrito -->
                    <span class="badges rounded-pill badge-light carrito-result">
                        <b>{{ ((simyo$ | async)?.shopping_cart_total)|toFixedDecimals }}<span class="eu"> €</span></b>
                    </span>
                </div>
            </span>

            <a [routerLink]="iFrameMode ? '/simyo' : '/'" [queryParams]="iFrameMode ? {frame: 'true'} : ''" *ngIf="!href.includes('/simyo/order') && !href.includes('/simyo/duplicados/') " class="btn btn-white btn-sm" (click)="sendEvent()">
                Volver al inicio
            </a>
            <a (click)="goBack()" *ngIf="href.includes('/simyo/order')" class="btn btn-white btn-sm">Volver al listado</a>
            <a routerLink="/simyo/listado-duplicados" *ngIf="href.includes('/simyo/duplicados/')" class="btn btn-white btn-sm">Volver al listado</a>
        </div>
    </div>
</nav>
