import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-orange-rate-card',
    templateUrl: './orange-rate-card.component.html',
    styleUrls: ['./orange-rate-card.component.css'],
})
export class OrangeRateCardComponent implements OnInit {
    @Input() title: string;
    @Input() showEditButton: boolean;
    @Input() showDeleteButton: boolean;
    @Input() productId: number;
    @Input() titleLine: string;
    @Input() rateName: string;
    @Input() price: string;

    @Output() onEdit = new EventEmitter();
    @Output() onDelete = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    goToEdit() {
      this.onEdit.emit();
    }

    removeProduct() {
      this.onDelete.emit();
    }
}
