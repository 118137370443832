import { Component, EventEmitter, OnInit, Output, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject, merge, of } from 'rxjs';
import { delay, switchMap, tap, catchError, shareReplay, map, debounceTime } from 'rxjs/operators';
import { PointsService } from 'src/app/home/services/points.service';
import { snakeToCamel } from 'src/app/utils/convertKeysObject';
import * as moment from 'moment/moment';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';

@Component({
    selector: 'app-points-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
    @Output() onGoBack = new EventEmitter();
    public orderList$: Observable<any>;
    public reloadOrderList$: Subject<any> = new Subject();
    public statusOrderList$: BehaviorSubject<string> = new BehaviorSubject( 'initial' );
    public initialFilters = {
        "limit": 20,
        "page": 1
    }
    public filterValues$: BehaviorSubject<any> = new BehaviorSubject(this.initialFilters);
    public filters$$: Subject<any> = new Subject();
    public orderListError: boolean = false;
    orders = [];

    public lastPage: number = 0;
    public showLoadMore: boolean = true;
    public automaticLoad: boolean = true;
    viewMoreItem:boolean [] = []
    constructor(
        private route: ActivatedRoute,
        private pointsService: PointsService,
        private dialog: MatDialog
    ) {
        const route$ = this.route.queryParams.pipe(delay(0));
        const reload$ = route$.pipe(switchMap(() => this.reloadOrderList$.pipe()));

        this.orderList$ = merge(route$, reload$).pipe(
            tap(() => this.statusOrderList$.next('loading')),
            map(filters => ({...this.initialFilters, ...filters})),
            tap((filters) => this.filterValues$.next(filters)),
            switchMap((filters) =>
                this.pointsService.postRedeemList(filters).pipe(
                    tap(() => this.statusOrderList$.next('initial')),
                    catchError((err) => {
                        this.statusOrderList$.next('error');
                        return of({});
                    })
                )
            ),
            map((data: any) => snakeToCamel(data)),
            tap((data: any) => {
                if(data.length > 0) {
                    if(data[0].currentPage === 1) {
                       this.orders = data[0].data;
                    } else {
                      this.orders = [...this.orders, ...data[0].data];
                    }
                    this.lastPage = data[0].lastPage;
                    this.showLoadMore = data[0].currentPage < data[0].lastPage;
                    return;
                  }
                  this.statusOrderList$.next('error');
            }),
            shareReplay()
        );

        this.filters$$.pipe(debounceTime(300)).subscribe(filter => {
            this.reloadOrderList$.next(filter);
        })
    }

    ngOnInit(): void {}

    goBack() {
        this.onGoBack.emit();
    }

    nextPage() {
        let currentFilter = this.filterValues$.getValue();
        let nextPage = currentFilter.page + 1;
        if(nextPage <= this.lastPage) {
            this.filters$$.next({page: nextPage, status: 'loading'});
        }
      }

    convertDate(data): string {
        return data && data !== '' ? moment(data).format('DD/MM/YYYY') : '';
    }
    resendCode(order) {
        if(order.loading) return;
        order.loading = true
        this.pointsService.resendCode(order.id).subscribe((result: any) => {
            let message = result.msg === 'ok' ? `Hemos reenviado el código al correo ${order.email}` : 'Ha ocurrido un error al reenviar el código';
            this.dialog.open(AlertDialogComponent, {
                data: {
                    message
                }
            });
            order.loading = false;
        },
        error => {
            this.dialog.open(AlertDialogComponent, {
                data: {
                    message: 'Ha ocurrido un error al reenviar el código. Por favor, inténtelo de nuevo mas tarde.'
                }
            });
            order.loading = false;
        }
        )
    }
    getImage(product) {
        return `./assets/img/rewards/miniatura-tarjeta-regalo-${product.toLowerCase()}.png`;
    }
  @HostListener('window:scroll', ['$event'])
    doSomethingOnWindowScroll(event: Event) {
        if (this.automaticLoad && this.statusOrderList$.getValue() !== 'loading') {
            const element: any = event.srcElement;
            const scrollOffset = element.children[0].scrollTop;
            const windowHeight = document.documentElement.scrollHeight - window.innerHeight - 100;

            if (scrollOffset > windowHeight) {
                let currentFilter = this.filterValues$.getValue();
                this.automaticLoad = currentFilter.page >= 2 ? false : true;
                this.nextPage();

            }
        }
    }
}
