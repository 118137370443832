<div class="container content" *ngIf="loaded">
    <div class="row mb-4 mt-5">
        <div class="col-lg-12 mb-2">
            <h5 class="title-step text-center" >
                <img src="./assets/img/linea-conf.svg" class="img-fluid ico-title transf-ico" width="20">
                Configuración de tarifa
            </h5>
        </div>
        <div class="col-lg-9 mb-2">
            <div class="card bg-white configurador">
                <div class="row mt-2">
                    <div class="col-xl-4">
                        <h6 class="mb-2 mt-2 fw-bold">
                            Velocidad de <span class="text-simyo">fibra</span>
                        </h6>
                    </div>
                    <div class="col-xl-8">
                        <!-- Fibra -->
                        <div class="radio-btn-group" [formGroup]="ratesListFormGroup" (change)="isAvailableRate()">
                            <div class="radio" *ngFor="let fibra of fibraRates">
                                <input id="{{fibra.rate_id}}" type="radio" name="fibraRate" value="{{fibra.rate_id}}" formControlName="fibraRate">
                                <label for="{{fibra.rate_id}}" class="fw-bold radio-width">{{ fibra.name }}</label>
                            </div>
                            <div class="radio">
                                <input id="nofibra" type="radio" name="fibraRate" value="0" formControlName="fibraRate">
                                <label for="nofibra" class="fw-bold radio-width">No quiero fibra</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="line-config--2">
                <div class="row mt-3">
                    <div class="col-xl-4">
                        <h6 class="mb-2 fw-bold">
                            Tipo de línea <span class="text-simyo">móvil</span>
                        </h6>
                    </div>
                    <div class="col-xl-8">
                        <!-- Tipo de linea -->
                        <div class="radio-btn-group mb-0" style="margin-top: -8px;" [formGroup]="ratesListFormGroup">
                            <div class="radio" *ngIf="showPostpaid && checkPermissions('postpaid')">
                                <input id="postpaid" type="radio" name="movilType" value="postpaid" formControlName="movilType">
                                <label for="postpaid" class="fw-bold radio-width">Pospago</label>
                            </div>
                            <div class="radio" *ngIf="showPrepaid && checkPermissions('prepaid')">
                                <input id="prepaid" type="radio" name="movilType" value="prepaid" formControlName="movilType">
                                <label for="prepaid" class="fw-bold radio-width">Prepago</label>
                            </div>
                            <div class="radio">
                                <input id="nomovil" type="radio" name="movilType" value="0" formControlName="movilType">
                                <label for="nomovil" class="fw-bold radio-width">No quiero móvil</label>
                            </div>
                        </div>
                        <mat-error *ngIf="showErrorMovilType">
                            Debe seleccionar un tipo de línea para continuar
                        </mat-error>
                    </div>
                </div>
                <hr class="line-config--2">
                <div class="row">
                    <ng-template [ngIf]="diffData.length > 1" [ngIfElse]="justOneData">
                        <div class="col-xl-12">
                            <h6 class="mb-2 fw-bold">Elige <span class="text-simyo">datos</span> de internet móvil
                            </h6>

                            <!-- Datos moviles -->
                            <div class="slider-container" [formGroup]="ratesListFormGroup">
                                <input type="range" min="0" max="{{diffData.length - 1}}" class="sliders" formControlName="dataRate" (change)="changeData($event.currentTarget.value)">
                            </div>

                            <div class="label-container">
                                <div class="label-slider1 disabled" *ngFor="let data of diffData" [ngClass]="{'disabled' : data.disabled }">
                                    {{ data.label }}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #justOneData>
                        <div class="col-xl-12 label-container">
                            <h6 class="mb-2 fw-bold">
                                Datos de internet móvil: <span class="text-simyo">{{ diffData[0].label }}</span>
                            </h6>
                        </div>
                    </ng-template>
                    <ng-template [ngIf]="diffVoice.length > 1" [ngIfElse]="justOneVoice">
                        <div class="col-xl-12">
                            <h6 class="mb-2 fw-bold">
                                Elige <span class="text-simyo">minutos</span> de llamadas
                            </h6>

                            <!-- Min llamadas-->
                            <div class="slider-container" [formGroup]="ratesListFormGroup">
                                <input type="range" min="0" max="{{diffVoice.length - 1}}" class="sliders" formControlName="voiceRate" (change)="changeVoice($event.currentTarget.value)">
                            </div>

                            <div class="label-container">
                                <div class="label-slider disabled" *ngFor="let voice of diffVoice" [ngClass]="{'disabled' : voice.disabled }">
                                    {{ voice.label.length > 8 ? voice.label.substr(0, 6) : voice.label }}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #justOneVoice>
                        <div class="col-xl-12 label-container" style="margin-top: -20px;">
                            <h6 class="mb-2 fw-bold">
                                Minutos de llamadas: <span class="text-simyo">{{ diffVoice[0].label }}</span>
                            </h6>
                        </div>
                    </ng-template>
                    <!--<div class="col-xl-12" *ngIf="bonosRates && bonosRates.length">
                        <!-<h6 style="margin-top: -18px;" class="mb-4 fw-bold">
                            Completa tu tarifa con:
                            <a class="text-simyo" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                + añadir bonos
                            </a>
                        </h6>->
                        <div class="collapse" id="collapseExample">
                            <div class="row mb-2" [formGroup]="ratesListFormGroup">
                                <ng-container *ngFor="let bono of bonosRates; let i = index">
                                    <div class="col-xl-3" *ngIf="bono.name === 'Chat'">
                                        <span class="tar-add">{{ bono.name }}</span>
                                        <img src="./assets/img/whatsapp.svg" class="icon-info2 ms-1">
                                        <img src="./assets/img/telegram.svg" class="icon-info ms-1">
                                        <div class="complete--tarifa--1">
                                            <label class="switch">
                                                <input type="checkbox" value="{{i}}" class="warning" [formControl]="ratesListFormGroup?.controls['bonosRate']?.controls[i]" (change)="addBonoToCustomRate(bono, $event.currentTarget.checked)">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xl-4" *ngIf="bono.name === '100 MIN a simyos'">
                                        <span class="tar-add">{{ bono.name }}</span>
                                        <div class="complete--tarifa--1">
                                            <label class="switch">
                                                <input type="checkbox" value="{{i}}" class="warning" [formControl]="ratesListFormGroup?.controls['bonosRate']?.controls[i]" (change)="addBonoToCustomRate(bono, $event.currentTarget.checked)">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xl-5" *ngIf="bono.name === 'Redes Sociales'">
                                        <span class="tar-add">{{ bono.name }}</span>
                                        <img src="./assets/img/facebook.svg" class="icon-info ms-1">
                                        <img src="./assets/img/instagram.svg" class="icon-info ms-1">
                                        <img src="./assets/img/twitter.svg" class="icon-info ms-1">
                                        <img src="./assets/img/snapchat.svg" class="icon-info ms-1">
                                        <div class="complete--tarifa--1">
                                            <label class="switch">
                                                <input type="checkbox" value="{{i}}" class="warning" [formControl]="ratesListFormGroup?.controls['bonosRate']?.controls[i]" (change)="addBonoToCustomRate(bono, $event.currentTarget.checked)">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>

        <!-- Precio Tarifa -->
        <div class="col-lg-3">
            <div>
                <div class="d-flex justify-content-center">
                    <form [formGroup]="taxesFormGroup">
                        <div>
                            <div class="mb-3">
                                <mat-form-field [formGroup]="taxesFormGroup">
                                    <mat-label>Tipo impuesto</mat-label>
                                    <select formControlName="tax" matNativeControl (change)="changeTaxType($event.target.value)">
                                        <option *ngFor="let taxType of taxes" [value]="taxType.value">
                                            {{ taxType.name }}
                                        </option>
                                    </select>
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card tar-simyo tarifas-card-simyo">
                <!-- Precio -->
                <div class="tar-simyo-price fw-bold mb-3 d-flex justify-content-center">
                    {{ (customRate.price)|toFixedDecimals }}<span class="decimals-1">€</span>
                </div>
                <!-- Servicios contratados Linea 1-->
                <p  class="tar-simyo-des--configurador fw-bold mb-4 text-center" *ngIf="customRate.textFibra !== ''">
                    Fibra {{ customRate.textFibra }}
                </p>
                <!-- Servicios contratados Linea 21-->
                <p  class="tar-simyo-des--configurador fw-bold mb-4 text-center" *ngIf="customRate.textType !== '' || customRate.textRate !== ''">
                    {{ customRate.textType }} <br> {{ customRate.textRate }}
                </p>
                <!-- Contratar -->
                <a (click)="selectRate(customRate, false)" class="btn btn-simyo2 btn-sm mb-4">{{ customRate.buttonTitle }}</a>
            </div>
        </div>
        <br>
        <div class="col-xl-12 mb-3" *ngIf="readyRates.length">
            <hr class="hr-text mb-4" data-bs-content="Tarifas listas para llevar">
            <div class="row">
                <div class="col-lg hvr-float" *ngFor="let rate of readyRates">
                    <div class="card-tar card-white mb-5 mb-lg-0">
                        <div class="card-simyo-header">
                            <p  class="tar-simyo-tar text-center">
                                {{ rate?.rate_info?.caption }}
                            </p>
                        </div>
                        <div class="card-tar-body">
                            <!-- Precio -->
                            <div class="tar-simyo-price2 fw-bold mb-2 d-flex justify-content-center">
                                {{ (rate?.rate_info?.real_price)|toFixedDecimals }}<span class="decimals-1">€</span>
                            </div>
                            <!-- Servicios contratados -->
                            <p  class="tar-simyo-des fw-bold mb-4 text-center">
                                {{ customRate.textType }} <br> {{ rate.name }}
                            </p>
                            <!-- Contratar -->
                            <a (click)="selectRate(rate, true)" class="btn btn-simyo2 d-block w-100 btn-sm mb-2">Continuar móvil</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header" style="background-color: #fff;">
        <b class="col text-simyo pe-0 mt-2 text-center" style="font-size: 18px;" >IMPORTANTE</b>
    </div>

    <div class="modal-body pt-0" id="bodyModal">
        <div class="m-3 row">
            <span class="col-12 text-center" >
                Avisa al cliente de que para que se activen los bonos tiene que recargar
                <span class="text-simyo">{{ (+rechargePrice)|toFixedDecimals }}€ </span>
                <b>DESPUÉS DE ACTIVAR LA SIM</b>
            </span>
            <span class="col-12 mt-4 text-center" >
                Si puedes hazle la recarga ahora mismo y si no que tenga el móvil conectado en WIFI sin datos móviles hasta que recargue
            </span>
        </div>
        <a (click)="modal.dismiss('Cross click')" class="btn btn-simyo-o2 d-block w-100 mb-1" role="button">
            Entendido
        </a>
    </div>
</ng-template>
