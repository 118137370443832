import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class RepublicaMovilService {

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
    }

    getHeaders() {
        const token = 'Bearer ' + this.auth.getToken();

        return new HttpHeaders({
            Authorization: token,
            'Content-Type': 'application/json'
        });
    }

    getMasterAllFromService() {
        return this.http.get(environment.rmEndpoint + 'master/all', {headers: this.getHeaders()});
    }

    getContract(order_id) {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            Accept: 'application/pdf'
        });

        return this.http.get(environment.rmEndpoint + 'order/' + order_id + '/document/contract/download', {headers, responseType: 'blob'});
    }

    getSepa(order_id) {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            Accept: 'application/pdf'
        });

        return this.http.get(environment.rmEndpoint + 'order/' + order_id + '/document/sepa/download', {headers, responseType: 'blob'});
    }

    getFileUpload(order_id, assetId) {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            Accept: 'application/pdf'
        });

        return this.http.get(environment.rmEndpoint + 'order/' + order_id + '/document/' + assetId + '/download', {headers, responseType: 'blob'});
    }

    getFilteredOrderList(filterConfig) {
        return this.http.post(environment.rmEndpoint + 'order/list/filter', filterConfig, {headers: this.getHeaders()});
    }

    getFilterAlerts(filterConfig) {
        return this.http.get(environment.rmEndpoint + 'order/list/alerts' + filterConfig, {headers: this.getHeaders()});
    }

    updateAnnotation(order_id, annotation: string) {
        const url = environment.rmEndpoint + 'order/' + order_id + '/annotation';
        const body = {annotation};

        return this.http.patch(url, body, {headers: this.getHeaders()});
    }

    uploadFile(file, orderId) {
        const formData = new FormData();
        const token = 'Bearer ' + this.auth.getToken();
        const header = new HttpHeaders({
            Authorization: token
        });

        formData.append('document', file);
        return this.http.post(environment.rmEndpoint + 'order/' + orderId + '/document/upload', formData, {headers: header});
    }

    removeOrder(id_remove) {
        const url = environment.rmEndpoint + 'order/' + `${id_remove}` + '/delete';
        return this.http.delete(url, {headers: this.getHeaders()});
    }
}
