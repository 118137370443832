import { Component, OnDestroy, OnInit } from '@angular/core';
import { Order } from '../../classes/order';
import { MatDialog } from '@angular/material/dialog';
import { SimyoService } from '../../services/simyo.service';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { SimyoShoppingCartService } from '../../services/simyo-shopping-cart.service';
import { broadbandTypeDiscount, discountWithoutFtth, fiberIndirectTechnologies, mobileContractType, nameTaxType, orderType, priceSwapSim, typeCard } from 'src/app/shared/constantes';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { setTotal } from '../redux/simyo.actions';
import { SimyoGlobals } from '../../classes/simyo-globals';
import { masterAllSimyo, provinceSimyo, trackTypeSimyo } from '../../models/masterAllSimyo';

@Component({
    selector: 'app-simyo-shopping-cart',
    templateUrl: './simyo-shopping-cart.component.html',
    styleUrls: ['./simyo-shopping-cart.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoShoppingCartComponent implements OnInit, OnDestroy {

    public orderType = orderType;
    public priceSwapSim: number = priceSwapSim;
    public fiberIndirectTechnologies = fiberIndirectTechnologies;
    public typeCard = typeCard;
    public broadbandTypeDiscount = broadbandTypeDiscount;

    public order: Order;
    public typeOrder: string;
    public ratesMobiles = [];
    public swapSim: any;
    public today: Date = new Date();
    public customerData: any;
    public isPackaging = false;
    public tax_type = '';

    // Master
    private master: masterAllSimyo;
    public roadTypes: trackTypeSimyo[] = null;
    public provinces: provinceSimyo[] = [];

    private allSubscription: Subscription[] = [];
    constructor(
        public matDialog: MatDialog,
        private simyoService: SimyoService,
        private orderService: SimyoOrderService,
        private shoppingCart: SimyoShoppingCartService,
        private store: Store<{ simyo: number }>,
        public simyoGlobals: SimyoGlobals
    ) {
    }

    ngOnInit(): void {
        this.allSubscription.push(this.simyoService.getTypeOrder()
            .subscribe((data: any) => {
                this.typeOrder = data;
            })
        );

        this.allSubscription.push(
            this.simyoService.getMasterAll().subscribe((data: masterAllSimyo) => {
                if (data) {
                    this.master = data;
                    this.roadTypes = data.track_types;
                    this.provinces = data.provinces;
                }
            })
        );
        this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data: any) => {
                if (data != null) {
                    this.ratesMobiles = data;
                }
            })
        );

        this.allSubscription.push(this.orderService.getOrder()
            .subscribe((data: any) => {
                if (data != null) {
                    this.order = data;
                    if (this.order.mobiles && this.order.mobiles.length) {
                        this.order.mobiles.map((item, index) => {
                            const found = this.ratesMobiles.filter(x=> x.rate_ids).filter(x => {
                                if (item.contract_type === mobileContractType.prepaid) {
                                    return x.rate_ids?.prepaid === item.rate_id;
                                } else {
                                    return x.rate_ids?.postpaid === item.rate_id;
                                }
                            })
                            if (found) {
                                item.rate = this.ratesMobiles[index];
                            }
                        });
                    }
                    this.updateTotalPrice(this.simyoGlobals.getTotalPrice(this.order.broadband?.price, this.order.mobiles));
                }
            })
        );

        this.allSubscription.push(this.shoppingCart.getShow()
            .subscribe((data: boolean) => {
                if (data) {
                    this.openCart();
                } else {
                    this.closeCart();
                }
            })
        );

        this.allSubscription.push(this.simyoService.getCustomerPromotion()
            .subscribe((data) => {
                this.customerData = data;
                // if (this.typeOrder === orderType.MOBILE && this.customerData) {
                //     this.isPackaging = this.customerData.max_broadband !== null && this.simyoService.getMainLine() !== null;
                // }
            })
        );



        this.allSubscription.push(this.simyoService.getSwapSim()
            .subscribe((data) => {
                this.swapSim = data;
            })
        );
    }

    discountWithoutFtth(){
        this.tax_type = this.simyoService.getTaxType();
        return discountWithoutFtth[this.tax_type];
    }

    getIndexFirstPostpaidMobile() {
        return this.order.mobiles.findIndex(x => x.is_main_line);
    }

    updateTotalPrice(price) {
        this.orderService.setTotalPrice(price);

        if (price.totalPrice > 0) {
            this.store.dispatch(setTotal({total: price.totalPrice}));
        }

    }

    closeShoppingCart() {
        this.shoppingCart.setShow(false);
    }

    openCart() {
        if (document.getElementById('shopping-cart') !== null) {
            document.getElementById('shopping-cart').style.width = '350px';
        }
    }

    closeCart() {
        if (document.getElementById('shopping-cart') !== null) {
            document.getElementById('shopping-cart').style.width = '0px';
        }
    }

    goToStep(index) {
        this.shoppingCart.getStepper().selectedIndex = index;
    }

    removeExtraLine(index) {
        this.orderService.orderShowRemoveOrderDialog(index);
    }

    public getAddress(): string {
        if (this.order && this.order.customer.installation_address) {
            let address: string = '';
            address += this.parseText(this.order.customer.installation_address.street_type_id ? this.simyoGlobals.getRoadTypeLabel(this.roadTypes, this.order.customer.installation_address.street_type_id) + ' ' : '');
            address += this.parseText(this.order.customer.installation_address.street_name ? this.order.customer.installation_address.street_name + ' ' : '');
            address += this.parseText(this.order.customer.installation_address.street_number ? this.order.customer.installation_address.street_number + ' ' : '');
            address += this.parseText(this.order.customer.installation_address.floor_number ? this.order.customer.installation_address.floor_number + ' ' : '');
            address += this.parseText(this.order.customer.installation_address.apartment_number ? this.order.customer.installation_address.apartment_number + ' ' : '');
            address += this.parseText(this.order.customer.installation_address.staircase_number ? this.order.customer.installation_address.staircase_number + ' ' : '');
            address += this.parseText(this.order.customer.installation_address.block ? this.order.customer.installation_address.block + ', ' : ', ');
            address += this.parseText(this.order.customer.installation_address.post_code ? this.order.customer.installation_address.post_code + ' ' : '');
            address += this.parseText(this.order.customer.installation_address.city ? this.order.customer.installation_address.city + ', ' : ', ');
            address += this.parseText(this.order.customer.installation_address.province_id ? this.simyoGlobals.getProvinceLabel(this.provinces, this.order.customer.installation_address.province_id) + ' ' : '');

            return address;
        }
    }

    public parseText(text) {
        if (typeof text !== 'string') {
            return '';
        }
        let textUCfirst = '';
        for (let i = 0; i < text.length; i++) {
            if (i === 0) {
                textUCfirst += text.charAt(i).toUpperCase();
            } else {
                textUCfirst += text.charAt(i).toLowerCase();
            }
        }
        return textUCfirst;
    }

    getDocumentType(id): string {
        if (this.master?.document_types?.length && id) {
            const found = this.master.document_types.find(x => x.document_type_id === +id)
            return found ? found.value : '';
        }
    }

    getReasonLabel(id) : string {
        if (this.master?.reason?.length && id) {
            const found = this.master.reason.find(x => x.reason_id === +id)
            return found ? found.value : '';
        }
    }

    getTaxType(){
        this.tax_type = this.simyoService.getTaxType();
        return nameTaxType[this.tax_type];
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    getTitleLine(mobile) {
        if(mobile === undefined) return '';
        const portability = mobile.msisdn && mobile.msisdn !== '';
        const portabilityChange = mobile.customer_donor != null;
        const portabilityChangeText = portabilityChange ? ' (Con cambio de titularidad)' : '';
        if (mobile.is_esim === typeCard.esim) {
            return portability  ? `Portabilidad eSIM de la línea móvil ${mobile.msisdn}${portabilityChangeText}` : 'Alta eSIM';
        }
        return portability  ? `Línea móvil a portar ${mobile.msisdn}${portabilityChangeText}` : 'Alta línea móvil';
    }


    get isOrderFinished(): boolean {
        return this.shoppingCart.getOrderFinished();
    }

    checkPriceGt0(price) {
        return +(price.replace(/,/g, '.')) > 0;
    }
}
