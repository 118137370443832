<form [formGroup]="formGroup" *ngIf="this.currentStepper == this.stepperIndex">
    <app-simyo-rates [index]="index" [showTypeContent]="orderType.MOBILE" (requestRemovePostpaidsLines)="doRemoveAllAdditionalPostpaids($event)" *ngIf="rate == null"></app-simyo-rates>

    <div *ngIf="rate">
        <div class="container content">
            <div class="row">
                <div class="col-lg-12">
                    <h5 class="title-step text-center" >
                        <img src="./assets/img/package.svg" class="img-fluid ico-title" width="20">
                        {{ 'Tarifa ' + (index + 1) + 'ª línea móvil' }}
                    </h5>
                    <div class="card card-default card-horizontal mb-4">
                        <div class="card-heading">
                            <div class="row">
                                <div class="col-lg-6 align-self-center">
                                    <h5  class="tar-name car-tar-1">
                                        <!--{{ (rateFtth !== null && index === 0) ? ((fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'FIBRA IND ' : 'FIBRA ') + rateFtth?.name + ' + ') : '' }}-->
                                         {{ rate?.data + ' + ' + rate?.voice }} <!--{{ rate && rate.bonos && rate.bonos.length ? ' + ' + rate.bonos.length + ' bono(s)' : '' }}-->
                                    </h5>
                                </div>
                                <div class="col-lg-6 align-self-center">
                                    <h5  class="tar-price car-tar-2">
                                        <!-- descuento para primera línea de convergente -->
                                        <ng-container *ngIf="true || (rateFtth === null || index > 0)">
                                            <!-- {{ +rate?.rate_info?.real_price + priceBonos|toFixedDecimals }} -->
                                            {{ +rate?.rate_info?.real_price|toFixedDecimals }}
                                            <span>€/mes</span>
                                        </ng-container>
                                        <!--<ng-container *ngIf="rateFtth && index === 0">
                                            {{ ++getPriceBySpeed(rateFtth?.rate_info?.speed) + priceBonos|toFixedDecimals }}
                                            <span>€/mes</span>
                                        </ng-container>-->
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <!--<div class="row" *ngIf="rateFtth && index === 0">
                                <div class="col-md-5 d-flex flex-column justify-content-center">
                                    <h5  class="tar-name-5 my-0" *ngIf="rateFtth.name">
                                        <b>{{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Fibra IND ' : 'Fibra ') + rateFtth.name }}</b>
                                    </h5>
                                    <h5  class="tar-name-5 my-0">
                                        <b>INSTALACIÓN GRATIS</b>
                                    </h5>
                                    <h5  class="tar-name-5 my-0">
                                        <b>ROUTER EN CESIÓN GRATIS</b>
                                    </h5>
                                </div>
                                <div class="col-md-auto"><span class="vl mobile"></span></div>
                                <div class="col-md-5 d-flex flex-column justify-content-center">
                                    <h5  class="tar-name-5 my-0" *ngIf="rate && rate.data">
                                        {{ rate?.data }}
                                    </h5>
                                    <h5  class="tar-name-5 my-0" *ngIf="rate && rate.voice">
                                        {{ rate?.voice }}
                                    </h5>
                                    <ng-container *ngIf="rate && rate.bonos && rate.bonos.length">
                                        <h5  class="tar-name-5 my-0" *ngFor="let bono of rate.bonos">
                                            {{ bono.rate_info.description }}
                                        </h5>
                                    </ng-container>
                                </div>
                                <div class="col-md-1 btn-change align-self-center">
                                    <a class="btn btn-simyo-a btn-sm color-white" (click)="changeRate()">Cambiar</a>
                                </div>
                            </div>-->

                            <!-- Solo movil -->
                            <div class="row" *ngIf="true || (!rateFtth || index !== 0)">
                                <div class="col-md-5 d-flex flex-column justify-content-center">
                                    <h5  class="tar-name-5 my-0" *ngIf="rate && rate.data">
                                        <b>{{ rate?.data }}</b> de navegación
                                    </h5>
                                </div>
                                <div class="col-md-auto"><span class="vl mobile"></span></div>
                                <div class="col-md-5 d-flex flex-column justify-content-center">
                                    <h5  class="tar-name-5 my-0" *ngIf="rate && rate.voice">
                                        {{ rate?.voice }}
                                    </h5>
                                    <!-- <ng-container *ngIf="rate && rate.bonos && rate.bonos.length">
                                        <h5  class="tar-name-5 my-0" *ngFor="let bono of rate.bonos">
                                            {{ bono.rate_info.description }}
                                        </h5>
                                    </ng-container> -->
                                </div>
                                <div class="col-md-1 btn-change align-self-center">
                                    <a class="btn btn-simyo-a btn-sm color-white" (click)="changeRate()">Cambiar</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container content">
            <div class="row mt-4">
                <div class="col-lg-12">
                    <h5 class="title-step text-center" >
                        <img src="./assets/img/smartphone.svg" class="img-fluid ico-title" width="20"> Tipo de operación línea móvil
                    </h5>
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex justify-content-between align-items-center">
                                <!----- Tipo de operación ------->
                                <div class="radio-btn-group" ngbRadioGroup
                                     formControlName="type_operation"
                                     (change)="changeTypeOperation($event.target.value)">
                                    <h6 class="subtitulo-pack">Tipo de operación: </h6>

                                    <div class="radio radio-pack">
                                        <label for="btnRegister">Alta nueva</label>
                                        <input id="btnRegister" type="radio" value="registration"/>
                                    </div>

                                    <div class="radio radio-pack">
                                        <label for="btnRegister">Portabilidad</label>
                                        <input id="btnRegister" type="radio" value="portability"/>
                                    </div>

                                    <div class="radio radio-pack" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_portabilidad_con_cambio_de_titular)" >
                                        <label ngbButtonLabel [ngClass]="{'label-tooltip': !checkIfFirstPortaChange()}" [ngStyle]="{ 'cursor': checkIfFirstPortaChange() ? 'pointer' : 'context-menu' }">
                                            <input ngbButton type="radio" value="portability_change" [disabled]="checkIfFirstPortaChange() == false"/>
                                            Portabilidad con cambio de titular
                                            <span *ngIf="!checkIfFirstPortaChange()" class="btn-tooltip" title="Ya hay una portabilidad con cambio de titular en esta solicitud, si necesitas más portabilidades con cambio de titular, debes tramitarlo en una nueva solicitud.">
                                                <img [src]="typeOperation == 'portability_change' ? './assets/img/info-white.svg' : './assets/img/info.svg'" class="img-fluid ico-title icon-white" width="16" >
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <small class="text-muted">
                                    <!----- Fecha estimada si es portabilidad / si es alta no aparece nada------->
                                    <!--<h6 class="subtitulo-pack fecha">Fecha estimada de portabilidad: <span class="verde"><b>04/04</b></span></h6>-->
                                </small>
                            </div>
                            <mat-error *ngIf="showErrorTypeOperation">
                                Campo obligatorio
                            </mat-error>
                        </div>
                        <ul class="list-group list-group-flush" *ngIf="typeOperation !== ''">
                            <li class="list-group-item cuerpo-pack">
                                <div class="radio-btn-group" ngbRadioGroup
                                     formControlName="is_esim"
                                     (change)="changeTypeCard($event)"
                                     *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_sell_esim)">
                                    <h6 class="subtitulo-pack">Tipo de tarjeta: </h6>

                                    <div class="radio radio-pack">
                                        <label ngbButtonLabel>
                                            <input ngbButton type="radio" [value]="typeCard.sim"/>
                                            SIM física
                                        </label>
                                    </div>

                                    <div class="radio radio-pack">
                                        <label ngbButtonLabel>
                                            <input ngbButton type="radio"
                                            [value]="typeCard.esim" (click)="showMessage()"/>
                                            eSIM
                                        </label>
                                    </div>


                                </div>
                                <h6 class="subtitulo-pack-form" *ngIf="typeOperation === 'portability' || typeOperation === 'portability_change'">
                                    Datos de alta / portabilidad
                                </h6>
                                <div class="row" *ngIf="typeOperation === 'portability' || typeOperation === 'portability_change'">
                                    <div class="col-lg-3 mb-0 mt-1">
                                        <mat-form-field>
                                            <mat-label>Teléfono móvil a portar</mat-label>
                                            <input type="text" matInput formControlName="portability_phone" maxlength="9" (keypress)="numberOnly($event)">
                                        </mat-form-field>
                                        <mat-error *ngIf="formGroup.get('portability_phone')?.errors?.notmustMatch">
                                            Número de teléfono a portar ya utilizado en este flujo
                                        </mat-error>
                                    </div>
                                    <div class="col-lg-2 mt-1">
                                        <mat-form-field>
                                            <mat-label>Operador donante</mat-label>
                                            <select matNativeControl formControlName="portability_operator">
                                                <option *ngFor="let provider of serviceProviders" [ngValue]="provider.service_provider_id">
                                                    {{ provider.value }}
                                                </option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-3 mt-1">
                                        <mat-form-field>
                                            <mat-label>Tipo de cliente donante</mat-label>
                                            <select matNativeControl formControlName="portability_type_client"
                                                    (change)="changePortabilityTypeClient($event.target.value)">
                                                <option [value]="'postpaid'" [selected]="portabilityTypeClient === 'postpaid'">Contrato</option>
                                                <option [value]="'prepaid'" [selected]="portabilityTypeClient === 'prepaid'">Prepago</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-4 mt-1" *ngIf="portabilityTypeClient === 'prepaid'">
                                        <mat-form-field class="col-11">
                                            <mat-label>ICCID donante</mat-label>
                                            <input type="text" matInput formControlName="portability_iccid" maxlength="19" minlength="19">
                                            <mat-hint class="text-end">{{ formGroup.get('portability_iccid').value.length }} / 19</mat-hint>
                                        </mat-form-field>
                                        <!-- <a class="cursor-pointer" (click)="openModalICCID(ICCID)" title="¿Dónde encuentro el ICCID?">
                                            <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white" width="20">
                                        </a> -->
                                        <mat-error *ngIf="formGroup.get('portability_iccid')?.errors?.notmustMatch">
                                            ICCID ya utilizado en este flujo
                                        </mat-error>
                                    </div>
                                </div>

                                <h6 class="subtitulo-pack-form" *ngIf="typeOperation !== '' && formGroup.get('is_esim').value == typeCard.sim">
                                    Datos SIM en Simyo
                                </h6>
                                <div class="row" *ngIf="typeOperation !== '' && formGroup.get('is_esim').value == typeCard.sim">
                                    <div class="col-lg-5 mb-0">
                                        <mat-form-field class="col-11">
                                            <mat-label>ICCID entregado al cliente</mat-label>
                                            <input type="text" matInput formControlName="iccid" (change)="isValidIccid('iccid')" (input)="isValidIccid('iccid')" maxlength="19" minlength="19">
                                            <span *ngIf="iccidLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            <mat-hint class="text-end">{{ formGroup.get('iccid').value?.length }} / 19</mat-hint>
                                        </mat-form-field>
                                        <a class="cursor-pointer" (click)="openModalICCID(ICCID)" title="¿Dónde encuentro el ICCID?">
                                            <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white" width="20">
                                        </a>
                                        <mat-error *ngIf="formGroup.get('iccid')?.errors?.notmustMatch">
                                            ICCID ya utilizado en este flujo
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.get('iccid')?.errors?.used">
                                            ICCID en uso, utiliza otra sim
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.get('iccid')?.errors?.invalid">
                                            ICCID no es válido
                                        </mat-error>
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Terminal -->
            <div class="row mt-5" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_venta_terminales) && typeOperation !== ''">
                <div class="col-lg-12">
                    <h5 class="title-step text-center" >
                        <img src="./assets/img/smartphone.svg" class="img-fluid ico-title" width="20"> Selección de terminal
                    </h5>
                    <div class="card">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item cuerpo-pack">
                                <div class="row">
                                    <div class="col-lg-7 mb-0">
                                        <div *ngIf="!environment.showSimyoTerminals">Upss, nos encontramos realizando tareas de mantenimiento. Perdón por las molestias.</div>
                                        <app-simyo-terminal *ngIf="environment.showSimyoTerminals" [index]="index" [formGroup]="formGroup" #terminalCmp></app-simyo-terminal>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- bloque línea adicional -->
             <div class="card-header mt-4" *ngIf="allowAddAdditionalLines">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <span class="ad-text">¿Deseas añadir <b>una línea de móvil adicional</b>?</span>
                    </div>
                    <small class="text-muted btn-muted">
                        <a (click)="addMobileLine()" class="btn btn-black btn-sm color-white" role="button">Contratar <span class="mobile">línea de móvil adicional</span></a>
                    </small>
                </div>
            </div>


            <!-- Continuar proceso -->
            <div class="mt-4 d-flex justify-content-center" >
                <a (click)="next()" class="btn btn-simyo-o2 d-block w-100 mb-1 color-white" role="button">
                    Continuar <img src="./assets/img/arrow-right-white.svg" class="img-fluid ico-title" width="15">
                </a>
                <a (click)="prev()" class="btn btn-simyo-o d-block w-100 mb-5" role="button" *ngIf="!(typeOrder === 'mobile' && index === 0)">Volver</a>
                <br><br>
            </div>
        </div>
    </div>
</form>

<ng-template #ICCID let-modal>
    <div class="modal-header bg-simyo-grey">
        <h5 class="modal-title white" id="exampleModalLabel">¿Dónde encuentro el ICCID?</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">
                <img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20">
            </span>
        </button>
    </div>
    <img src="./assets/img/iccid-simyo.png" class="img-fluid ico-title p-5">
</ng-template>

<ng-template #esimNotAvailable let-modal>
    <div class="modal-header" style="background-color: #fff;">
        <b class="col text-simyo pe-0 mt-2 text-center" style="font-size: 18px;" >IMPORTANTE</b>
    </div>

    <div class="modal-body pt-0" id="bodyModal">
        <div class="m-3 row">
            <span class="col-12 text-center" >
                Upssss servicio no disponible hasta nuevo aviso. Disculpa las molestias.
            </span>
        </div>
        <a (click)="modal.dismiss('Cross click')" class="btn btn-simyo-o2 d-block w-100 mb-1" role="button">
            Entendido
        </a>
    </div>
</ng-template>
