<ng-container [formGroup]="formGroup">
    <div class="container content">
        <div class="row">
            <div class="col-lg-10 offset-md-1">
                <h5 class="title-step text-center" >
                    <img src="./assets/img/refresh-cw.svg" class="img-fluid ico-title" width="20"> Duplicado
                </h5>
                <div class="card">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item cuerpo-pack">
                            <div class="row">

                                <div [class]="classColumn">
                                    <mat-form-field class="pt-1">
                                        <mat-label>Motivo del cambio</mat-label>
                                        <select matNativeControl required formControlName="reason">
                                            <option *ngFor="let reason of master?.reason" [value]="reason.reason_id">
                                                {{ reason.value }}
                                            </option>
                                        </select>
                                        <!-- error -->
                                        <mat-error *ngIf="formGroup.get('reason')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_sell_esim)">
                                    <mat-form-field class="pt-1">
                                        <mat-label>Tipo de tarjeta</mat-label>
                                        <select matNativeControl required formControlName="is_esim"
                                        (change)="changeTypeCard($event.target.value)">
                                            <option *ngFor="let type of cardType" [value]="type.is_esim">
                                                {{ type.text }}
                                            </option>
                                        </select>
                                        <!-- error -->
                                        <mat-error *ngIf="formGroup.get('reason')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-4" style="position: relative;" *ngIf="formGroup.get('is_esim').value == typeCard.sim">
                                    <mat-form-field>
                                        <mat-label>Nº <b>nueva</b> tarjeta SIM (ICC-ID)</mat-label>
                                        <input matInput type="text" formControlName="iccid" required (change)="isValidIccid('iccid')" (input)="isValidIccid('iccid')" maxlength="19" minlength="19">
                                        <mat-hint class="text-end">{{ formGroup.get('iccid').value?.length }} / 19</mat-hint>
                                        <!-- error -->
                                        <mat-error *ngIf="formGroup.get('iccid')?.errors?.iccid && !formGroup.get('iccid')?.errors?.required">
                                            ICCID formato incorrecto
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.get('iccid')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.get('iccid')?.errors?.used">
                                            ICCID en uso, utiliza otra sim
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.get('iccid')?.errors?.invalid">
                                            ICCID no es válido
                                        </mat-error>
                                    </mat-form-field>
                                    <span *ngIf="iccidLoading" class="spinner-grow spinner-grow-sm custom-spinner" role="status" aria-hidden="true"></span>
                                    <a class="cursor-pointer custom-buttom-modal" (click)="openModal(ICCID)" title="¿Dónde encuentro el ICCID?">
                                        <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white" width="20">
                                    </a>
                                </div>
                                <div [class]="classColumn">
                                    <mat-form-field>
                                        <mat-label>Nº de teléfono (MSISDN)</mat-label>
                                        <input matInput type="text" formControlName="msisdn" required  maxlength="9" (keypress)="numberOnly($event)">
                                        <!-- error -->
                                        <mat-error *ngIf="formGroup.get('msisdn')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container content">
        <div class="row">
            <div class="col-lg-10 offset-md-1">
                <h5 class="title-step mt-5 text-center" >
                    <img src="./assets/img/users.svg" class="img-fluid ico-title" width="20"> Datos cliente
                </h5>
                <div class="card">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item cuerpo-pack">
                            <div class="row">
                                <div class="col-lg-3 mt-0">
                                    <mat-form-field class="pt-1">
                                        <mat-label>Tipo documento</mat-label>
                                        <select formControlName="documentType" matNativeControl required
                                            (change)="changeTypeDocument($event.target.value); setMaxLengthByDoc($event.target.value)">
                                            <option *ngFor="let documentType of master?.document_types" [value]="documentType.document_type_id" [hidden]="documentType?.document_type_id === 4 && pdv.info.username !== 'lucas.saiz.pdv'">
                                                {{ documentType.value }}
                                            </option>
                                        </select>
                                        <!-- error -->
                                        <mat-error *ngIf="formGroup.get('documentType')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-3 mt-0">
                                    <mat-form-field>
                                        <mat-label>Nº de documento</mat-label>
                                        <input matInput type="text" formControlName="documentNumber"
                                        (input)="formGroup.get('documentNumber').patchValue(formGroup.get('documentNumber').value.toUpperCase());"
                                        [maxLength]="maxLengthByDoc" required (keypress)="alphanumericOnly($event)">
                                        <!-- error -->
                                        <mat-error *ngIf="formGroup.get('documentNumber')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                        <mat-error *ngIf="(formGroup.get('documentNumber')?.errors?.doc || formGroup.get('documentNumber')?.errors?.pattern) && !formGroup.get('documentNumber')?.errors?.required">
                                            Número de documento erróneo
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-3 mt-0">
                                    <mat-form-field class="pt-1">
                                        <mat-label>Nacionalidad</mat-label>
                                        <select matNativeControl required formControlName="nationality">
                                            <ng-container *ngFor="let country of master?.countries">
                                                <option [value]="country.country_id" [hidden]="formGroup.get('documentType').value === '1' && country.country_id === 192">
                                                    {{ country.value }}
                                                </option>
                                            </ng-container>
                                        </select>
                                        <!-- error -->
                                        <mat-error *ngIf="formGroup.get('nationality')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-3 mt-0">
                                    <mat-form-field class="pt-1">
                                        <mat-label>Sexo</mat-label>
                                        <select matNativeControl formControlName="gender" required>
                                            <option *ngFor="let sex of master?.genders" [value]="sex.gender_id">
                                                {{ sex.value }}
                                            </option>
                                        </select>
                                        <!-- error -->
                                        <mat-error *ngIf="formGroup.get('gender')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3 mt-0">
                                    <mat-form-field>
                                        <mat-label>Nombre</mat-label>
                                        <input matInput type="text" formControlName="name" required>
                                        <!-- error -->
                                        <mat-error *ngIf="formGroup.get('name')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-3 mt-0">
                                    <mat-form-field>
                                        <mat-label>Apellido1</mat-label>
                                        <input matInput type="text" formControlName="lastName1" required>
                                        <!-- error -->
                                        <mat-error *ngIf="formGroup.get('lastName1')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-3 mt-0">
                                    <mat-form-field>
                                        <mat-label>Apellido2</mat-label>
                                        <input matInput type="text" formControlName="lastName2">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-3 mt-0">
                                    <app-datepicker-custom
                                    title="Fecha de nacimiento"
                                    [form]="formGroup"
                                    name="birthday"
                                    [maxDate]="maxDate"
                                    [minDate]="minDate"
                                    ></app-datepicker-custom>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- Continuar proceso -->
        <div class="row" *ngIf="!showDocSection">
            <div class="col-xl-10 offset-md-1">
                <div class="d-flex justify-content-center">
                    <br>
                    <button class="btn btn-simyo-o2 d-block w-100 mb-1 fw-bold" (click)="validatePersonalData()" [disabled]="isLoadingDraft" *ngIf="isValidMrz() || isLoadingDraft || isAllowToContinue">
                        Continuar <img src="./assets/img/arrow-right-white.svg" class="img-fluid ico-title" width="15" *ngIf="!isLoadingDraft">
                        <span *ngIf="isLoadingDraft" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    </button>
                    <br><br>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #ICCID let-modal>
    <div class="modal-header bg-simyo-grey">
        <h5 class="modal-title white" id="exampleModalLabel">¿Dónde encuentro el ICCID?</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">
                <img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20">
            </span>
        </button>
    </div>
    <img src="./assets/img/iccid-simyo.png" class="img-fluid ico-title p-5">
</ng-template>
