<mat-form-field [formGroup]="form">
    <mat-label>{{title}}</mat-label>
    <input matInput 
        [formControlName]="name" 
        [max]="maxDate" 
        [min]="minDate" 
        [matDatepicker]="customDate" 
        (dateInput)="changeDate($event)"
        [attr.disabled]="disabledInput">
    <mat-datepicker-toggle matSuffix [for]="customDate">
        <fa-icon matDatepickerToggleIcon [icon]="faCalendarDay"></fa-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #customDate (opened)="selectedDate(customDate)" (monthSelected)="selectedDate(customDate)">
        <mat-datepicker-actions>
            <div class="d-flex justify-content-between w-100">
                <button class="btn btn-action-date-picker" mat-raised-button (click)="clearStartDate(customDate)">Limpiar</button>
                <button class="btn btn-action-date-picker" mat-raised-button (click)="todayClicked(customDate)">Hoy</button>
            </div>
        </mat-datepicker-actions>
    </mat-datepicker>
    <mat-error *ngIf="checkErrorFieldForm(name, ['required'])">
        Campo obligatorio.
    </mat-error>
    <mat-error *ngIf="checkErrorFieldForm(name, ['required']) == false && checkErrorFieldForm(name, ['date_minimum'])">
        Fecha inválida.
    </mat-error>
    <mat-error *ngIf="checkErrorFieldForm(name, ['required']) == false && checkErrorFieldForm(name, ['date_maximum'])">
        El cliente debe ser mayor de edad.
    </mat-error>
</mat-form-field>