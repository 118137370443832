<form [formGroup]="formGroup">
    <div class="row normalizador">
        <!-- <div class="col-lg-3 mb-0">
            <mat-form-field [formGroup]="formGroup">
                <mat-label>Tipo de vía</mat-label>
                <select matNativeControl formControlName="tipoViaNormaliza" [errorStateMatcher]="matcher">
                    <option *ngFor='let datos of roadTypes' value="{{datos.code}}">{{datos.code}}</option>
                </select>
                <mat-error *ngIf="formGroup.get('tipoViaNormaliza').invalid">
                    Campo obligatorio.
                </mat-error>
            </mat-form-field>
        </div> -->
        <div class="col-lg-10 mb-0">
            <mat-form-field [formGroup]="formGroup">
                <mat-label>Nombre de la vía</mat-label>
                <input type="text" matInput matGoogleMapsAutocomplete name="nombreViaNormaliza" formControlName="nombreViaNormaliza" placeholder=""
                       [errorStateMatcher]="matcher"
                       [types]="['address']"
                       [country]="country"
                       (input)="onAddressChange($event);sendEvent($event);formGroup.get('tipoViaNormaliza').patchValue('');"
                       (focusin)="onAddressChange($event)"
                       (focusout)="onAddressFocusOut()"
                       (onAutocompleteSelected)="onAutocompleteSelected($event)"/>
                <!-- error -->
                <mat-error *ngIf="formGroup.get('nombreViaNormaliza').invalid">
                    Campo obligatorio.
                </mat-error>
            </mat-form-field>

            <div #manualAddress class="manual-address" (click)="formGroup.get('tipoViaNormaliza').patchValue('');">
                <strong #manualAddressText class="manual-address-text"></strong>
                <span class="manual-address-link">Continuar con selección manual</span>
            </div>
        </div>
        <div class="col-lg-2 mb-0">
            <app-custom-input
                #formNumber
                type="number"
                [formGroup]="formGroup"
                id="numeroNormaliza"
                controlName="numeroNormaliza"

            ></app-custom-input>
            <!--<mat-form-field [formGroup]="formGroup">
                <mat-label>Número</mat-label>
                <input #formNumber matInput type="number" name="numeroNormaliza" formControlName="numeroNormaliza" [errorStateMatcher]="matcher" pattern="\d+" min="0">
                 error
                <mat-error *ngIf="formGroup.get('numeroNormaliza').invalid">
                    Campo obligatorio.
                </mat-error>
            </mat-form-field>-->
        </div>
    </div>
    <!--SEGUNDA FILA-->
    <div class="row normalizador">
        <div class="col-lg-3 mb-1">
            <mat-form-field [formGroup]="formGroup">
                <mat-label>Código Postal</mat-label>
                <input matInput type="number" name="codigoPostalNormaliza" formControlName="codigoPostalNormaliza" (input)="onChangeZip($event.target.value)"
                       [errorStateMatcher]="matcher" pattern="\d+" min="0" max="99999">
                <!-- error -->
                <mat-error *ngIf="formGroup.get('codigoPostalNormaliza').invalid">
                    Campo obligatorio.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-5 mb-1">
            <mat-form-field [formGroup]="formGroup">
                <mat-label>Localidad</mat-label>
                <input matInput type="text" name="localidadNormaliza" formControlName="localidadNormaliza" [errorStateMatcher]="matcher">
                <!-- error -->
                <mat-error *ngIf="formGroup.get('localidadNormaliza').invalid">
                    Campo obligatorio.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-4 mb-1">
            <mat-form-field [formGroup]="formGroup">
                <mat-label>Provincia</mat-label>
                <select matNativeControl formControlName="provinciaNormaliza" [errorStateMatcher]="matcher">
                    <option *ngFor="let datos of provinces" [value]="datos.code">
                        {{datos.code}}
                    </option>
                </select>
                <!-- error -->
                <mat-error *ngIf="formGroup.get('provinciaNormaliza').invalid">
                    Campo obligatorio.
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <button id="submit" type="submit" [disabled]="isLoading" *ngIf="muestraBoton == true" class="btn btn-simyo mb-1" (click)="onNormalizarDireccion()">
        <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        {{textDefault}}
    </button>
    <mat-error *ngIf="muestraErrorPeticion">
        <p class="alert alert-warning">
            {{msgErrorPeticion}}
        </p>
    </mat-error>
</form>

