import { Injectable } from "@angular/core";
import { availableTechnologies, fibraSpeeds, orderType, rateAvailableIn, rateFtthPrice } from "src/app/shared/constantes";
import { PermissionService } from "src/app/shared/services/permission.service";
import { getNameStreet } from "src/app/utils/normalizerFunctions";
import { SimyoDraftService } from "../services/simyo-draft.service";
import { SimyoNormalizadorService } from "../services/simyo-normalizador.service";
import { SimyoRateService } from "../services/simyo-rate.service";

export var descuento = 4;
export const terminalStatus = {
    AVAILABLE: '0',
    AVAILABLE_SOON: '1',
    NOT_AVAILABLE: '2'
}

@Injectable({
    providedIn: 'root'
})
export class SimyoGlobals {
    constructor(
        private normalizadorService: SimyoNormalizadorService,
        private draftService: SimyoDraftService,
        private rateService: SimyoRateService,
        private permSv: PermissionService
    ) { }

    getTotalPrice(rateFtthPrice, ratesMobiles): any {
        let price: any = {
            totalPrice: 0,
            totalPriceInicial: 0,
            totalMensualPrice: 0
        }

        if (rateFtthPrice != null) {
            //this.setFtthDiscount(rateFtth);
            price.totalMensualPrice += +rateFtthPrice;
            price.totalPrice += +rateFtthPrice;
        }

        if (ratesMobiles.length) {
            ratesMobiles.forEach((rate, index) => {
                if (rateFtthPrice && index === 0) {
                    price.totalPrice = +rateFtthPrice;
                    price.totalMensualPrice = +rateFtthPrice;
                }

                let rateInfo = this.findByKeyInMobile(rate, 'rate_info');
                if(rateInfo) {
                    price.totalPrice += +(rateInfo?.contracted_price ?? rateInfo?.real_price);
                    price.totalMensualPrice += +(rateInfo?.contracted_price ?? rateInfo?.real_price);
                }

                // Precio bonos
                // if (rate.bonos && rate.bonos.length) {
                //     rate.bonos.forEach(bono => {
                //         price.totalPrice += +bono.rate_info.price;
                //         price.totalMensualPrice += +bono.rate_info.price;
                //     });
                // }
                // Precio terminales
                let terminal = this.findByKeyInMobile(rate, 'terminal');
                if (terminal) {
                    if (terminal.paymentType === 'VAP' || rate.terminal_oid?.is_installment == true) {
                        // Pago a plazos
                        price.totalPrice += terminal.upfront && +terminal.upfront > 0 ? +terminal.upfront : 0;
                        price.totalPrice += terminal.installment && +terminal.installment > 0 ? +terminal.installment : 0;

                        price.totalPriceInicial += terminal.upfront && +terminal.upfront > 0 ? +terminal.upfront : 0;

                        price.totalMensualPrice += terminal.installment && +terminal.installment > 0 ? +terminal.installment : 0;
                    } else {
                        // Pago unico
                        price.totalPrice += terminal.price && +terminal.price > 0 ? +terminal.price : 0;
                        price.totalPriceInicial += terminal.price && +terminal.price > 0 ? +terminal.price : 0;
                    }
                }
            });
        }
        return price;
    }

    setFtthDiscount(rateFtth){
        descuento = 4;
        if (rateFtth?.type_normal?.price && rateFtth?.type_discount?.price) {
            descuento = rateFtth?.type_normal?.price - rateFtth?.type_discount?.price;
        }
        return descuento;
    }

    /*pricesBonos(bonos): number {
        let priceBono = 0;
        if (bonos && bonos.length) {
            bonos.map(bono => priceBono += +bono.rate_info.price)
            return priceBono;
        }
        return priceBono;
    }*/

    priceMobileMonth(mobile): number {
        let price = 0;
        if (mobile) {
            if (mobile.terminal && mobile.terminal.paymentType === 'VAP') {
                price += mobile.terminal.installment && +mobile.terminal.installment > 0 ? +mobile.terminal.installment : 0;
            }
            let rateInfo = this.findByKeyInMobile(mobile, 'rate_info');
            if(rateInfo) {
                price += rateInfo.contracted_price ? +rateInfo.contracted_price : (rateInfo.real_price ? +rateInfo.real_price : +rateInfo.price);
            }

            return price; //+ this.pricesBonos(mobile.bonos);
        }
        return price;
    }

    getRoadTypeLabel(roadTypes, value): string {
        if (roadTypes && roadTypes.length && value) {
            const found = roadTypes.find(x => x.track_type_id.toString() === value.toString());
            if (found) {
                return found.value;
            }
        }
        return value;
    }

    getProvinceLabel(provinces, value): string {
        if (provinces && provinces.length && value) {
            const found = provinces.find(x => x.province_id === value);
            if (found) {
                return found.value;
            }
        }
        return value;
    }

    ftthRateIsValid(order, rateMaster, mainLine, allowedFtthReduced, hasPackage) : boolean {
        if (order && order.broadband && order.broadband.rate && order.broadband.rate.rate_info) {
            // Comprobamos si la tarifa existe
            var foundFtth = rateMaster?.RATES?.FTTH_NEW.find(rateFtth => order?.broadband?.rate?.rate_id === rateFtth?.type_discount?.rate_id);
            if (!foundFtth) {
                foundFtth = rateMaster?.RATES?.FTTH_NEW.find(rateFtth => order?.broadband?.rate?.rate_id === rateFtth?.type_normal?.rate_id);
            }
            //Para devolver que es valida,
            // 1 tenemos que tenerla disponible en el matrix

            // ¿Cuando comprobar si la tarifa seleccionada es valida? Excepciones de fibra 100
            if(foundFtth && foundFtth.rate_info.speed === fibraSpeeds.speed_100) {
                // Si no hay linea movil y la fibra es de 100 Mb --> eliminar
                if(mainLine == null) {
                    return false;
                }
                // Si no tiene paquete mirar las fibras permitidas en el movil
                if(hasPackage == false && mainLine.rate_info.available_in.includes(`ftth_${foundFtth.rate_info.speed}`) == false) {
                    return false;
                }
                // Si hay movil, no se permite fibra reducida y la tarifa de 100 Mb --> eliminar
                if(!allowedFtthReduced) {
                    return false;
                }

            }
            return foundFtth != null;

        }
        return false;
    }

    mobileRateIsValid(order, movil, indexMovil, rateMaster, type, customerInfo) : boolean {
        if (movil && movil.rate && rateMaster && rateMaster.RATES && rateMaster.RATES.MOBILE && rateMaster.RATES.ADDITIONALS) {
            const foundMobile = rateMaster.RATES.MOBILE.find(rateMobile => rateMobile.rate_ids && (rateMobile.rate_ids.postpaid === movil.rate_id || rateMobile.rate_ids.prepaid === movil.rate_id));
            const foundAdditional = rateMaster.RATES.ADDITIONALS.find(rateMobile => rateMobile.rate_ids && (rateMobile.rate_ids.postpaid === movil.rate_id || rateMobile.rate_ids.prepaid === movil.rate_id));
            if (foundAdditional) {
                if (type === orderType.CONVERGENT && order.broadband) {
                    return foundAdditional && foundAdditional.rate_info.available_in.includes(this.getAvailableIn(order.broadband.rate.rate_info.speed));
                }
                if (type === orderType.MOBILE) {
                    return foundAdditional && foundAdditional.rate_info.available_in.includes(rateAvailableIn.mobile);
                }
            } else {
                if (type === orderType.CONVERGENT && order.broadband) {
                    return foundMobile && foundMobile.rate_info.available_in.includes(this.getAvailableIn(order.broadband.rate.rate_info.speed));
                }
                if (type === orderType.MOBILE) {
                    return foundMobile && foundMobile.rate_info.available_in.includes(rateAvailableIn.mobile);
                }
            }
        }
        return false;
    }

    getAvailableIn(speed): string {
        if (speed === '300') {
            return rateAvailableIn.broadband_300;
        }
        if (speed === '600') {
            return rateAvailableIn.broadband_600;
        }
        if (speed === '1') {
            return rateAvailableIn.broadband_1;
        }
        if (speed === '100') {
            return rateAvailableIn.broadband_100;
        }
    }

    getFtthPrice(speed): string {
        if (speed === '300') {
            return rateFtthPrice.broadband_300_price;
        }
        if (speed === '600') {
            return rateFtthPrice.broadband_600_price;
        }
        if (speed === '1') {
            return rateFtthPrice.broadband_1_price;
        }
        if (speed === '100') {
            return rateFtthPrice.broadband_100_price;
        }
    }

    getDataNormalizar(normalizador, roadTypes) {
        return {
            streetNr: `${normalizador.numeroNormaliza}`,
            streetName: getNameStreet(normalizador.nombreViaNormaliza, roadTypes, 'value'),
            city: normalizador.localidadNormaliza,
            stateOrProvince: normalizador.provinciaNormaliza,
            streetType: this.getTypeStreet(normalizador.tipoViaNormaliza, roadTypes),
            postCode: `${normalizador.codigoPostalNormaliza}`
        };
    }

    async coverageIsValid(broadband, address, roadTypes) : Promise<boolean> {
        return await this.normalizadorService
            .normalizarHorizontal(this.getDataNormalizar(this.draftService.getNormalizador(address), roadTypes))
            .toPromise()
            .then(async (data: any) => {
                if (data.msg) {
                    data = data.msg;
                }
                if (Array.isArray(data) && data.length > 0) {
                    let foundGeneral: boolean = false;
                    data.forEach(horizontal => {
                        if (horizontal && horizontal.vertical_addresses && horizontal.vertical_addresses.length) {
                            horizontal.vertical_addresses.forEach(address => {
                                if (address && address.ospAddressExternalId && address.ospAddressExternalId.length) {
                                    const foundGescal = address.ospAddressExternalId.find(arvatoOrGescal => arvatoOrGescal.refId === 'gescal');
                                    if (foundGescal && foundGescal.externalId === broadband.cima_gescal_code.replaceAll('_', ' ')) {
                                        foundGeneral = address;
                                    }
                                }
                            });
                        }
                    });
                    if (foundGeneral) {
                        return await this.normalizadorService.consultaCobertura(foundGeneral)
                            .toPromise()
                            .then((data: any) => {
                                if (data.msg) {
                                    data = data.msg;
                                }
                                if (data.cimaArvatoCode && data.cimaArvatoCode !== '' && data.technology && data.technology.length && availableTechnologies.includes(data.technology[0])) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }, (error) => {
                                return false;
                            });
                    }
                    return false;
                }
                return false;
            }, error => {
                return false;
            });
    }

    getTypeStreet(value: string, roadTypes): string {
        if (roadTypes && roadTypes.length && value) {
            if (value.toLowerCase() === 'passatge') {
                value = 'pasaje';
            }
            const valueNormalized = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            const tipoVia = roadTypes.filter(item => {
                const codeNormalized = item.code.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                return valueNormalized.toLowerCase() === codeNormalized.toLowerCase();
            });

            if (tipoVia && tipoVia.length) {
                return tipoVia[0].value;
            }
        }
        return 'CALLE';
    }

    getftthRateMatrix(order, rateMaster) {
        if (order && order.broadband && order.broadband.rate && order.broadband.rate.rate_info) {
            // Comprobamos si la tarifa existe
            var foundFtth = rateMaster?.RATES?.FTTH_NEW.find(rateFtth => order?.broadband?.rate?.rate_id === rateFtth?.type_discount?.rate_id);
            if (!foundFtth) {
                foundFtth = rateMaster?.RATES?.FTTH_NEW.find(rateFtth => order?.broadband?.rate?.rate_id === rateFtth?.type_normal?.rate_id);
            }
            return foundFtth;
        }
        return null;
    }

    getMobilePrice(rateMobile) {
        if (rateMobile) {
            let priceBonos = 0;
            let price = 0;
            // if (rateMobile.bonos && rateMobile.bonos.length) {
            //     rateMobile.bonos.forEach(x => {
            //         priceBonos += +x.rate.rate_info.price;
            //     });
            // }
            let rateInfo = this.findByKeyInMobile(rateMobile, 'rate_info');
            if(rateInfo) {
                price = rateInfo.contracted_price ? rateInfo.contracted_price : (rateInfo.real_price ? rateInfo.real_price : rateInfo.real_price);
            }

            return +price + priceBonos;
        }
        return 0;
    }

    findByKeyInMobile(mobile, keySearch) {
        if(mobile && typeof mobile === 'object') {
            if(keySearch in mobile) {
                return mobile[keySearch];
            }

            for (const key in mobile) {
                if (mobile.hasOwnProperty(key)) {
                    const result = this.findByKeyInMobile(mobile[key], keySearch);
                    if (result) {
                        return result;
                    }
                }
            }
        }
        return null;
    }
}
