<section #procesos class="procesos mt-3">
    <div class="row">
        <!-- Activaciones -->
        <div class="col-xl-12" *ngIf="!activationsByMonthError">
            <div class="card--sidebar mb-3">
                <div class="card-footer--sidebar-kpi1">
                    <div class="row kpi-col" *ngIf="activationStatistics">
                        <div class="col-md-3 ps-4 mb-2 d-flex align-items-center flex-column" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                            <div class="cursor-pointer" (click)="viewOperation(typeFilterFormOperation.totalActivationsFibra)">
                                <span class="title-p3 me-2">Fibra:</span>
                                <span class="kpi2">{{ activationStatistics.countActualMonthFibra }}</span>
                            </div>
                            <div>
                                <span title="Estimación a final de mes"
                                    [ngClass]="{'kpi-per-positive2' : activationStatistics.estimatedFibra > 0, 'kpi-per-negative2': activationStatistics.estimatedFibra < 0, 'kpi-per-neutro2': activationStatistics.estimatedFibra === 0 }">
                                    {{ getAbsoluteValue(activationStatistics.estimatedFibra) }}%
                                </span>
                                <span class="kpi-per-mes1 me-1">- {{ monthNames[lastMonth] }}:</span>
                                <span class="kpi-a1">{{ activationStatistics.countPreviousMonthFibra }}</span>
                            </div>
                        </div>
                        <div class="ps-4 mb-2 border-start d-flex align-items-center flex-column" [ngClass]="{'col-md-3': permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband), 'col-md-4': !permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)}">
                            <div class="cursor-pointer" (click)="viewOperation(typeFilterFormOperation.totalActivationsPostpago)">
                                <span class="title-p3 me-2">Pospago:</span>
                                <span class="kpi2">{{ activationStatistics.countActualMonthPospago }}</span>
                            </div>
                            <div>
                                <span title="Estimación a final de mes"
                                    [ngClass]="{'kpi-per-positive2' : activationStatistics.estimatedPospago > 0, 'kpi-per-negative2': activationStatistics.estimatedPospago < 0, 'kpi-per-neutro2': activationStatistics.estimatedPospago === 0 }">
                                    {{ getAbsoluteValue(activationStatistics.estimatedPospago) }}%
                                </span>
                                <span class="kpi-per-mes1 me-1">- {{ monthNames[lastMonth] }}:</span>
                                <span class="kpi-a1">{{ activationStatistics.countPreviousMonthPospago }}</span>
                            </div>
                        </div>
                        <div class="ps-4 mb-2 border-start d-flex align-items-center flex-column" [ngClass]="{'col-md-3': permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband), 'col-md-4': !permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)}">
                            <div class="cursor-pointer" (click)="viewOperation(typeFilterFormOperation.totalActivationsPrepago)">
                                <span class="title-p3 me-2">Prepago:</span>
                                <span class="kpi2">{{ activationStatistics.countActualMonthPrepago }}</span>
                            </div>
                            <div>
                                <span title="Estimación a final de mes"
                                    [ngClass]="{'kpi-per-positive2' : activationStatistics.estimatedPrepago > 0, 'kpi-per-negative2': activationStatistics.estimatedPrepago < 0, 'kpi-per-neutro2': activationStatistics.estimatedPrepago === 0 }">
                                    {{ getAbsoluteValue(activationStatistics.estimatedPrepago) }}%
                                </span>
                                <span class="kpi-per-mes1 me-1">- {{ monthNames[lastMonth] }}:</span>
                                <span class="kpi-a1">{{ activationStatistics.countPreviousMonthPrepago }}</span>
                            </div>
                        </div>
                        <div class="ps-4 pt-1 mb-2 border-start d-flex align-items-center flex-column" [ngClass]="{'col-md-3': permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband), 'col-md-4': !permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)}">
                            <div>
                                <img src="./assets/img/saldo.svg" class="img-fluid avisos--sidebar--icon" width="16"> Total este mes:
                            </div>
                            <div>
                                <span class="orange ms-0 cursor-pointer" (click)="viewOperation(typeFilterFormOperation.totalActivationsMonth)">
                                    <b>{{ activationStatistics.countActualMonthFibra + activationStatistics.countActualMonthPospago + activationStatistics.countActualMonthPrepago }}</b> activaciones
                                </span>
                            </div>
                        </div>
                    </div>
                    <div style="padding-bottom: 5px;" *ngIf="!activationStatistics">
                        <h5 class="tar-name-preload text-center">
                            <img class="" src="./assets/img/spinner-home.svg" width="30" alt=""> Obteniendo activaciones...
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12" *ngIf="activationsByMonthError">
            <div class="card--sidebar mb-3">
                <div class="card-footer--sidebar-kpi1">
                    <h5 class="tar-name-preload text-center" style="padding-bottom: 5px;">Se ha producido un error, inténtalo de nuevo más tarde</h5>
                </div>
            </div>
        </div>
        <!-- MARCAS -->
        <div class="col-xl-7 col-xxl-8">
            <div class="accordion subpage_top_nav" id="accordionExample">
                <ul class="d-flex flex-column ps-0" style="list-style: none">

                    <!-- Simyo -->
                    <li class="brand" [ngClass]="{'brand-first': brandOpened === brands.simyo, 'order-1': brandOpened !== brands.simyo}" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender) && permSv.hasPermFromV2(allPermissions.others.access_night)">
                        <div class="mb-3">
                            <div [class.card--sm--2]="!showMessages" class="card--sm" id="simyo">
                                <div class="row">
                                    <div class="digo--card-brand {{ titleColumn }} col-xl">
                                        <!-- Logo -->
                                        <img src="./assets/img/sm-login-c.svg" class="img-fluid marca--sm" width="120">
                                        <img src="./assets/img/sm-login-c.svg" class="img-fluid marca--sm--c" width="120">
                                        <!-- Banner normalizado -->
                                        <h4 class="fw-bold banner" *ngIf="showMessages">
                                            <span *ngIf="today.isBefore(smChange) || (!environment.production && smChange.isSameOrAfter(today))">Fibra, móvil y paga lo justo</span>
                                            <br *ngIf="today.isBefore(smChange) || (!environment.production && smChange.isSameOrAfter(today))">
                                            <span style="font-size: 11px !important;" *ngIf="!environment.production && smChange.isSameOrAfter(today)"> El día {{ smChange }} se muestra el mensaje de abajo</span>
                                            <br *ngIf="!environment.production && smChange.isSameOrAfter(today)">
                                            <span *ngIf="today.isSameOrAfter(smChange) || (!environment.production && smChange.isSameOrAfter(today))">En Simyo líneas adicionales</span>
                                        </h4>
                                        <div class="vl--sm"></div>
                                    </div>

                                    <div class="{{ buttonColumn }} col-xl-auto">
                                        <button *ngIf="brandOpened !== brands.simyo && !permSv.hasPermFromV2(allPermissions.simyo.nbss_block)" (click)="changeBrand(brands.simyo)"
                                                class="btn btn-white-outline--sm" type="button" [class.mt--16]="!showMessages"
                                                data-bs-toggle="collapse" data-bs-target="#sm" aria-expanded="true" aria-controls="collapseTwo">
                                            <b>Acceder</b>
                                        </button>

                                        <button *ngIf="permSv.hasPermFromV2(allPermissions.simyo.nbss_block)" (click)="openSimyoTemporaryClosure()"
                                                class="btn btn-white-outline--sm" type="button" [class.mt--16]="!showMessages">
                                            <b>Acceder</b>
                                        </button>

                                        <button *ngIf="brandOpened === brands.simyo" (click)="changeBrand()" class="btn px-0" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#sm" aria-expanded="true" aria-controls="collapseTwo">
                                            <img src="./assets/img/x-white.svg" style="margin-top: -30px;">
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div id="sm" class="collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" *ngIf="!permSv.hasPermFromV2(allPermissions.simyo.nbss_block)">
                                <div class="card-body--sm">
                                    <div class="row">
                                        <div class="col-xl-12" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_viejo_frontal)">
                                            <h6 class="ms-2 marca--sub">Contratación</h6>

                                            <div class="ms-2 mb-3">
                                                <a href="{{ baseUrl }}smy_activa.itx" class="btn btn-black--outline btn-sm me-1 mb-1">Móvil (contrato)</a>
                                                <a href="{{ baseUrl }}smy_activa.itx?mode=prepaid" class="btn btn-black--outline btn-sm me-1 mb-1">Móvil (prepago)</a>
                                            </div>
                                        </div>

                                        <div class="col-xl-12" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_nuevo_frontal)">
                                            <h6 class="ms-2 marca--sub">Contratación</h6>

                                            <div class="ms-2 mb-3">
                                                <a routerLink="/simyo/convergente" class="btn btn-black--outline btn-sm me-1 mb-1" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                                                    Fibra y móvil
                                                </a>
                                                <a routerLink="/simyo/solo-movil" class="btn btn-black--outline btn-sm me-1 mb-1">
                                                    Móvil
                                                </a>
                                                <a routerLink="/simyo/solo-fibra" class="btn btn-black--outline btn-sm me-1 mb-1" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                                                    Fibra
                                                </a>
                                                <button (click)="checkSimyoTemporaryClosure('/simyo/duplicados')" class="btn btn-black--outline btn-sm me-1 mb-1" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_duplicado_sim)">
                                                    Duplicados
                                                </button>
                                            </div>

                                            <div class="ms-2">
                                                <a routerLink="/simyo/listado" class="btn btn-gray--round btn-sm me-1">
                                                    <img src="./assets/img/iconfinder.svg" width="12" alt="Historial contrataciones" class="me-2">
                                                    <span>Consultar operaciones</span>
                                                </a>

                                                <a href="/simyo/listado-duplicados" class="btn btn-gray--round btn-sm me-1" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_duplicado_sim)">
                                                    <img src="./assets/img/iconfinder.svg" width="12" alt="Historial duplicados" class="me-2">
                                                    <span>Consultar duplicados</span>
                                                </a>
                                            </div>
                                        </div>

                                        <div class="row mx-0" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.demo)">
                                            <div class="col-auto">
                                                <i-feather name="info" style="width: 48px;"></i-feather>
                                            </div>

                                            <div class="col ps-0">
                                                <p>Se ha solicitado el acceso <strong>Simyo</strong> para tu usuario Digo.</p>
                                                <p>En 24-72 horas deberás poder acceder desde esta misma opción a las opciones de contratación de la marca Simyo.</p>
                                            </div>
                                        </div>

                                        <div class="row mx-0" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.demo)">
                                            <div class="col-auto">
                                                <i-feather name="info" style="width: 48px;"></i-feather>
                                            </div>

                                            <div class="col ps-0">
                                                <p>Se ha solicitado el acceso <strong>Jazztel</strong> para tu usuario Digo.</p>
                                                <p>En 24-72 horas deberás poder acceder desde esta misma opción a las opciones de contratación de la marca Jazztel.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="card-footer--rm">
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="ms-2">
                                                <!-- VerTarifasBlock
                                                <a *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_viejo_frontal) || !permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)" href="javascript: void(0)" (click)="viewRates(brands.simyo)" class="black pe-3">
                                                    Ver tarifas
                                                </a>
                                                <a *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_nuevo_frontal) && permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)" routerLink="/simyo/tarifas" class="black pe-3">
                                                    Ver tarifas
                                                </a>-->
                                                <a href="javascript: void(0)" (click)="openModalSimyoContratos()" class="black">Contratos</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <!-- Orange -->
                    <li class="brand" [ngClass]="{'brand-first': brandOpened === brands.orange, 'order-3': brandOpened !== brands.orange}"
                        *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)">
                        <div class="mb-3">
                            <div [class.card--or--2]="!showMessages" class="card--or" id="orange--group">
                                <div class="row">
                                    <div class="digo--card-brand {{ titleColumn }} col-xl">
                                        <!-- Logo -->
                                        <img src="./assets/img/or-login-c.svg" class="img-fluid marca--or" width="120">
                                        <img src="./assets/img/or-login-c.svg" class="img-fluid marca--or--c" width="120">
                                        <!-- Banner normalizado -->
                                        <h4 class="fw-bold banner" *ngIf="showMessages">
                                            <span *ngIf="today.isBefore(orChange) || (!environment.production && orChange.isSameOrAfter(today))">Conecta con los tuyos. Únete a Mundo.</span>
                                            <br *ngIf="today.isBefore(orChange) || (!environment.production && orChange.isSameOrAfter(today))">
                                            <span style="font-size: 11px !important;" *ngIf="!environment.production && orChange.isSameOrAfter(today)"> El día {{ orChange }} se muestra el mensaje de abajo</span>
                                            <br *ngIf="!environment.production && orChange.isSameOrAfter(today)">
                                            <span *ngIf="today.isSameOrAfter(orChange) || (!environment.production && orChange.isSameOrAfter(today))">Rompe las reglas con las tarifas Mundo</span>
                                        </h4>
                                        <div class="vl--or"></div>
                                    </div>

                                    <div class="{{ buttonColumn }} col-xl-auto">
                                        <button *ngIf="brandOpened !== brands.orange" (click)="changeBrand(brands.orange)"
                                                class="btn btn-white-outline--sm" type="button" [class.mt--16]="!showMessages"
                                                data-bs-toggle="collapse" data-bs-target="#or" aria-expanded="true" aria-controls="collapseFour">
                                            <b>Acceder</b>
                                        </button>

                                        <button *ngIf="brandOpened === brands.orange" (click)="changeBrand()" class="btn px-0" type="button" data-bs-toggle="collapse" data-bs-target="#or" aria-expanded="true" aria-controls="collapseFour">
                                            <img src="./assets/img/x-white.svg" style="margin-top: -30px;">
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="permSv.hasPermFromV2(allPermissions.orange.nuevo_flujo_prepago_orange)">
                                <div id="or" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="card-body--or" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div>
                                                    <h6 class="ms-2 marca--sub">Móvil prepago</h6>
                                                    <div class="ms-2 mb-3">
                                                        <a routerLink="/orange/prepago/alta" class="btn btn-black--outline btn-sm me-1 mb-1"
                                                            *ngIf="!permSv.hasPermFromV2(allPermissions.orange.permite_sell_only_esim)">
                                                            Alta
                                                        </a>
                                                        <a routerLink="/orange/prepago/portabilidad" class="btn btn-black--outline btn-sm me-1 mb-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_portabilidad)">
                                                           Portabilidad
                                                        </a>
                                                        <!--<a routerLink="/orange/prepago/terminal" class="btn btn-black&#45;&#45;outline btn-sm me-1 mb-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_venta_terminal_sim)">
                                                           Venta terminal + SIM
                                                        </a>-->
                                                        <a href="{{ baseUrl }}rebo_activa.itx?marca=orange" class="btn btn-black--outline btn-sm me-1 mb-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_renovacion_anticipada_bonos)">
                                                           Renovación bonos
                                                        </a>
                                                        <a routerLink="/orange/bonos-adicionales" class="btn btn-black--outline btn-sm me-1 mb-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)">
                                                           Bonos adicionales
                                                        </a>
                                                        <a routerLink="/orange/prepago/esim" class="btn btn-black--outline btn-sm me-1 mb-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_esim)">
                                                           {{permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim) ? 'Activación ': 'Alta '}}eSIM
                                                        </a>
                                                    </div>

                                                    <div class="ms-2">
                                                        <a routerLink="/orange/prepago-listado" class="btn btn-gray--round btn-sm me-1">
                                                            <img src="./assets/img/iconfinder.svg" width="12" alt="Historial contrataciones" class="me-2">
                                                            <span>Consulta operaciones prepago</span>
                                                        </a>

                                                        <a href="{{ baseUrl }}rebo_recogida_pdv.itx" class="btn btn-gray--round btn-sm me-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_renovacion_anticipada_bonos)">
                                                            <img src="./assets/img/iconfinder.svg" width="12" alt="Historial contrataciones" class="me-2">
                                                            <span>Consulta renovaciones bonos</span>
                                                        </a>
                                                        <a routerLink="/orange/bonos-adicionales-listado" class="btn btn-gray--round btn-sm me-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)">
                                                            <img src="./assets/img/iconfinder.svg" width="12" alt="Historial contrataciones" class="me-2">
                                                            <span>Consulta bonos adicionales</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-footer--rm" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="ms-2">
                                                    <a *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago) && permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)" href="javascript: void(0)"
                                                        (click)="viewRates(brands.orange)" class="black pe-3">
                                                        Ver tarifas
                                                    </a>
                                                    <a *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)" href="https://www.simmundo.es/" class="black pe-3" target=”_blank”>
                                                        Tarifas internacionales
                                                    </a>
                                                    <a *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)" href="./assets/pdf/Orange - Codigos prepago.pdf" target="_blank" class="black">
                                                        Códigos prepago Orange
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-body--or" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div>
                                                    <h6 class="ms-2 marca--sub">Móvil Pospago</h6>

                                                    <div class="ms-2">
                                                        <a href="{{ baseUrl }}pos_recogida_pdv.itx" class="btn btn-gray--round btn-sm me-1">
                                                            <img src="./assets/img/iconfinder.svg" width="12" alt="Historial contrataciones" class="me-2">
                                                            <span>Consulta operaciones Mi Fijo</span>
                                                        </a>

                                                        <a href="{{ baseUrl }}cancelar_mi_fijo.itx" class="btn btn-gray--round btn-sm me-1">
                                                            <img src="./assets/img/iconcancel.svg" width="14" alt="Historial contrataciones" class="me-2">
                                                            <span>Anular portab. Mi Fijo</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-footer--rm" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago) && !permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="ms-2">
                                                    <a href="javascript: void(0)" (click)="viewRates(brands.orange)" class="black pe-3">
                                                        Ver tarifas
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="!permSv.hasPermFromV2(allPermissions.orange.nuevo_flujo_prepago_orange)">
                                <div id="or" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="card-body--or" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago)">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div>
                                                    <h6 class="ms-2 marca--sub">Móvil prepago</h6>
                                                    <div class="ms-2 mb-3">
                                                        <a href="{{ baseUrl }}activa_pdv.itx" class="btn btn-black--outline btn-sm me-1 mb-1"
                                                        *ngIf="!permSv.hasPermFromV2(allPermissions.orange.permite_sell_only_esim)">
                                                            Alta
                                                        </a>
                                                        <a href="{{ baseUrl }}activa_pdv_portabilidad.itx" class="btn btn-black--outline btn-sm me-1 mb-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_portabilidad)">
                                                            Portabilidad
                                                        </a>
                                                        <a href="{{ baseUrl }}activa_pdv_terminal_sim.itx" class="btn btn-black--outline btn-sm me-1 mb-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_venta_terminal_sim)">
                                                            Venta terminal + SIM
                                                        </a>
                                                        <a href="{{ baseUrl }}rebo_activa.itx?marca=orange" class="btn btn-black--outline btn-sm me-1 mb-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_renovacion_anticipada_bonos)">
                                                            Renovación bonos
                                                        </a>
                                                        <a routerLink="/orange/bonos-adicionales" class="btn btn-black--outline btn-sm me-1 mb-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)">
                                                            Bonos adicionales
                                                        </a>
                                                        <a routerLink="/orange/prepago/esim" class="btn btn-black--outline btn-sm me-1 mb-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_esim)">
                                                           {{permSv.hasPermFromV2(allPermissions.orange.permite_view_new_esim) ? 'Activación ': 'Alta '}}eSIM
                                                        </a>
                                                    </div>

                                                    <div class="ms-2">
                                                        <a href="{{ baseUrl }}recogida_pdv.itx" class="btn btn-gray--round btn-sm me-1">
                                                            <img src="./assets/img/iconfinder.svg" width="12" alt="Historial contrataciones" class="me-2">
                                                            <span>Consulta operaciones prepago</span>
                                                        </a>

                                                        <a href="{{ baseUrl }}rebo_recogida_pdv.itx" class="btn btn-gray--round btn-sm me-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_renovacion_anticipada_bonos)">
                                                            <img src="./assets/img/iconfinder.svg" width="12" alt="Historial contrataciones" class="me-2">
                                                            <span>Consulta renovaciones bonos</span>
                                                        </a>
                                                        <a routerLink="/orange/bonos-adicionales-listado" class="btn btn-gray--round btn-sm me-1"
                                                           *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)">
                                                            <img src="./assets/img/iconfinder.svg" width="12" alt="Historial contrataciones" class="me-2">
                                                            <span>Consulta bonos adicionales</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </li>

                    <!-- Jazztel -->
                    <li class="brand" [ngClass]="{'brand-first': brandOpened === brands.jazztel, 'order-2': brandOpened !== brands.jazztel}"
                        *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                        <div class="mb-3">
                            <div [class.card--jz--2]="!showMessages" class="card--jz" id="jazztel">
                                <div class="row">
                                    <div class="digo--card-brand {{ titleColumn }} col-xl">
                                        <!-- Logo -->
                                        <img src="./assets/img/jz-login-c.svg" class="img-fluid marca--jz" width="120">
                                        <img src="./assets/img/jz-login-c.svg" class="img-fluid marca--jz--c" width="120">
                                        <!-- Banner normalizado -->
                                        <h4 class="fw-bold banner" *ngIf="showMessages">
                                            <span>Mejoramos las tarifas sin subir el precio</span>
                                        </h4>
                                        <div class="vl--jz"></div>
                                    </div>

                                    <div class="{{ buttonColumn }} col-xl-auto">
                                        <button *ngIf="brandOpened !== brands.jazztel" (click)="changeBrand(brands.jazztel)"
                                                class="btn btn-white-outline--sm" type="button" [class.mt--16]="!showMessages"
                                                data-bs-toggle="collapse" data-bs-target="#jz" aria-expanded="true" aria-controls="collapseOne">
                                            <b>Acceder</b>
                                        </button>

                                        <button *ngIf="brandOpened === brands.jazztel" (click)="changeBrand()" class="btn px-0" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#jz" aria-expanded="true" aria-controls="collapseOne">
                                            <img src="./assets/img/x-white.svg" style="margin-top: -30px;">
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div id="jz" class="collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="card-body--jz">
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <h6 class="ms-2 marca--sub">Contratación</h6>
                                            <div class="ms-2 mb-3">
                                                <a href="https://pangea.orange.es" target="_blank" class="btn btn-black--outline btn-sm me-1 mb-1">
                                                   Acceder a contrataciones {{ pdv?.info?.usuariopangea && pdv?.info?.usuariopangea !== '' ? '(' + pdv?.info?.usuariopangea + ')' : '' }}
                                                </a>
                                            </div>
                                            <div class="ms-2">
                                                <a href="{{ baseUrl }}jazztel_list_pdv.itx" class="btn btn-gray--round btn-sm me-2">
                                                    <img src="./assets/img/iconfinder.svg" width="12" alt="Historial contratación" class="me-2">
                                                    <span>Consultar operaciones</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-footer--rm">
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="ms-2">
                                                <a href="javascript: void(0)" (click)="openModalJazztelContratos()" class="black pe-3">
                                                    Contratos
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <ng-container *ngIf="(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)) && pdv?.info?.tipo_vista !== PDV_MULTIMARCA; then recargasYpedidos">
            </ng-container>
        </div>
        <!-- MARCAS -->

        <!-- OTROS PROCESOS -->
        <div class="col-xl-5 col-xxl-4">
            <div class="banners_group">
                <div *ngIf="brandOpened === brands.simyo">
                    <!-- banner ads -->
                    <div class="banner--ads--sidebar-sm mb-3 fade-in">
                        <ngb-carousel class="container px-0" *ngIf="(today.isBefore(smChangeGif)) || today.isSameOrAfter(smGifFinish) || (!environment.production && pdv?.info?.username === 'demo_pdv' && (smChangeGif.isSameOrAfter(today) || today.isBefore(smGifFinish)))">
                            <ng-template ngbSlide>
                                <img src="./assets/img/simyo_banner_19-08-2024.gif" class="img-fluid">
                            </ng-template>
                        </ngb-carousel>
                        <span *ngIf="!environment.production && pdv?.info?.username === 'demo_pdv' && (smChangeGif.isSameOrAfter(today) || today.isBefore(smGifFinish))">
                             A partir del día {{ smChangeGif.format('DD/MM/YYYY') }} {{ today.isBefore(smGifFinish) ? 'hasta el día ' + smGifFinish?.format('DD/MM/YYYY') : '' }} se muestra el banner de abajo
                        </span>
                        <ngb-carousel class="container px-0" *ngIf="(today.isSameOrAfter(smChangeGif) && today.isBefore(smGifFinish)) || (!environment.production && pdv?.info?.username === 'demo_pdv' && (smChangeGif.isSameOrAfter(today) || today.isBefore(smGifFinish)))">
                            <ng-template ngbSlide>
                                <img src="./assets/img/simyo_banner_19-08-2024.gif" class="img-fluid">
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>

                <div *ngIf="brandOpened === brands.orange">
                    <!-- banner ads -->
                    <div class="banner--ads--sidebar-or mb-3 fade-in">
                        <ngb-carousel [showNavigationIndicators]="pdv?.banners?.image_orange?.images.length > 1" class="container px-0" *ngIf="pdv?.banners?.image_orange && !pdv?.banners?.image_orange?.error">
                            <ng-template ngbSlide *ngFor="let banner of pdv?.banners?.image_orange?.images">
                                <img [src]="'data:image/gif;base64,' + banner" class="img-fluid">
                            </ng-template>
                        </ngb-carousel>
                        <!-- <img *ngIf="pdv?.banners?.image_orange && !pdv?.banners?.image_orange?.error" [src]="'data:image/gif;base64,' + pdv?.banners?.image_orange?.image" class="img-fluid"> -->
                        <img *ngIf="!pdv?.banners || pdv?.banners?.image_orange?.error" src="./assets/img/Banner_DIGO_Mundo_445x300_0421_V2.gif" class="img-fluid">
                    </div>
                </div>

                <div *ngIf="brandOpened === brands.jazztel">
                    <!-- banner ads -->
                    <div class="banner--ads--sidebar-am mb-3 fade-in">
                        <ngb-carousel class="container px-0">
                            <ng-template ngbSlide>
                                <img src="./assets/img/jazztel_banner_09-09-2024.jpg" class="img-fluid" alt="Jazztel">
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>
            </div>

            <!-- Comisiones -->
            <ng-template *ngIf="(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)) && pdv?.info?.tipo_vista !== PDV_MULTIMARCA && permSv.hasPermFromV2(allPermissions.others.access_night); then comisiones">
            </ng-template>

            <!-- Comisiones -->
            <ng-template *ngIf="permSv.hasPermFromV2(allPermissions.others.access_points) && permSv.hasPermFromV2(allPermissions.others.access_night); then points">
            </ng-template>

            <ng-template *ngIf="pdv?.info?.tipo_vista === PDV_MULTIMARCA && permSv.hasPermFromV2(allPermissions.others.terminales_libres); then terminalesLibres">
            </ng-template>

            <ng-template *ngIf="!(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)) && pdv?.info?.tipo_vista !== PDV_MULTIMARCA; then recargasYpedidos">
            </ng-template>
        </div>
    </div>
</section>

<ng-template #recargasYpedidos id="orders_and_credit_phone">
    <div [class.form-row]="(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion))">
        <div [ngClass]="{'col-xl-12 p-0': !(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)), 'col-xl-6': (permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)) }" *ngIf="pdv != null && ((permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && pdv.info_recargas.saldo_promo && pdv.info_recargas.saldo_promo > 0) || (!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga)))">
            <div class="card--sidebar mb-3">
                <div class="card-header--sidebar">
                    Recarga de saldo
                </div>
                <div class="card-body--sidebar">
                    <div class="row">
                        <div class="col-12 col-md pt-1 pe-0">
                            <img src="./assets/img/saldo.svg" class="img-fluid avisos--sidebar--icon" width="16">
                            <span *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga > 0">
                                Saldo: <span class="orange">{{ (+pdv.info_recargas.saldo_promo + +pdv.info_recargas.saldo_dexga).toFixed(2) }}</span> €
                            </span>
                            <span *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga === 0">
                                Habilita el sistema de recargas
                            </span>
                        </div>
                        <div class="col-12 col-md-auto mobile--ver--sidebar align-self-center" *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga > 0">
                            <a href="{{ baseUrl }}recargas_dexga_gestion.itx" class="btn btn-black-sidebar btn-sm">Mi cuenta</a>
                        </div>
                        <div class="col-12 col-md-auto mobile--ver--sidebar align-self-center" *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga === 0">
                            <a href="{{ baseUrl }}recargas_dexga_sol_usuario.itx" class="btn btn-black-sidebar btn-sm">Solicitar</a>
                        </div>
                    </div>
                </div>
                <div class="card-footer--sidebar"
                    *ngIf="(permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && +pdv.info_recargas.saldo_promo > 0) || (!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) &&+ pdv.info_recargas.usuario_dexga > 0)">
                    <a href="{{ baseUrl }}recarga_saldo_origen.itx" class="btn btn-black--outline btn-sm d-block w-100"
                    *ngIf="permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && +pdv.info_recargas.saldo_promo > 0 && !permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga > 0">
                        Hacer una recarga
                    </a>
                    <a href="{{ baseUrl }}recarga_saldo.itx" class="btn btn-black--outline btn-sm d-block w-100"
                    *ngIf="permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && +pdv.info_recargas.saldo_promo > 0 && +pdv.info_recargas.usuario_dexga === 0">
                        Hacer una recarga
                    </a>
                    <a href="{{ baseUrl }}recargas_dexga_recarga.itx" class="btn btn-black--outline btn-sm d-block w-100"
                    *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv.info_recargas.usuario_dexga > 0 && +pdv.info_recargas.saldo_promo === 0">
                        Hacer una recarga
                    </a>
                    <a href="{{ baseUrl }}bolsas_recarga_reparto.itx" class="btn btn-black--outline btn-sm d-block w-100"
                    *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && pdv.info_recargas.usuario_dexga > 0 && pdv.info_recargas.bolsa_saldo === 1">
                        Gestión bolsa de saldo
                    </a>
                </div>
            </div>
        </div>

        <div [ngClass]="{'col-xl-12 p-0': !(permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)), 'col-xl-6': (permSv.hasPermFromV2(allPermissions.others.comissions_permitir) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc) || permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)) }" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)">
            <div class="card--sidebar mb-3">
                <div class="card-header--sidebar">
                    Pedidos
                </div>
                <div class="card-body--sidebar">
                    <div class="row">
                        <div class="col-12 col-md pt-1">
                            <img src="./assets/img/iconinvoice.svg" class="img-fluid avisos--sidebar--icon" width="16">
                            Albaranes
                        </div>
                        <div class="col-12 col-md-auto mobile--ver--sidebar">
                            <a href="{{ baseUrl }}app_albaran_pdv.itx" class="btn btn-black-sidebar btn-sm">Acceder</a>
                        </div>
                    </div>
                </div>

                <div class="card-footer--sidebar">
                    <a href="{{ baseUrl }}pedido_pdv.itx" class="btn btn-black--outline btn-sm d-block w-100">
                        <strong>Hacer un pedido</strong>
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #comisiones id="commissions">
    <div class="card--sidebar mb-3">
        <div class="card-header--sidebar">
            Comisiones
        </div>
        <div class="card-body--sidebar">
            <div class="row" *ngIf="permSv.hasPermFromV2(allPermissions.others.comissions_permitir)">
                <div class="col-12 col-md pt-1">
                    <img src="./assets/img/euro.svg" class="img-fluid avisos--sidebar--icon" width="16">
                    Revisa tus comisiones
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a (click)="viewCommissions()" class="btn btn-black-sidebar btn-sm">Consultar</a>
                </div>
            </div>

            <div class="row" *ngIf="permSv.hasPermFromV2(allPermissions.others.comissions_pend_doc)">
                <div class="col-12 col-md pt-1">
                    <img src="./assets/img/euro.svg" class="img-fluid avisos--sidebar--icon" width="16">
                    Únete al programa de comisiones
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a href="{{ baseUrl }}comision_empresa.itx" class="btn btn-black-sidebar btn-sm">Gestionar</a>
                </div>
            </div>

            <div class="row" *ngIf="permSv.hasPermFromV2(allPermissions.others.comissions_pend_verificacion)">
                <div class="col-12 col-md pt-1">
                    <img src="./assets/img/euro.svg" class="img-fluid avisos--sidebar--icon" width="16">
                    Tu documentación está siendo validada
                </div>
            </div>
        </div>
        <ng-container *ngIf="permSv.hasPermFromV2(allPermissions.others.comissions_permitir) && (permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas) || permSv.hasPermFromV2(allPermissions.simyo.permite_vender))">
            <div class="card-footer--sidebar" *ngIf="rappelSimyoData">
                <div *ngIf="rappelSimyoData.current">
                    <div class="row">
                        <div class="col-8">
                            <span>
                                <b>Categoría actual:</b>
                                <br>
                                <b class="orange">{{ rappelSimyoData.current.text }} </b><span class="badge badge-warning-orange">{{ rappelSimyoData.current.current_activations }} activaciones</span>
                            </span>
                            <br>
                        </div>
                        <div class="col-4 pe-0 pb-1 d-flex justify-content-end"  style="align-self: end;">
                            <img src="./assets/img/sm-login-color.svg" class="img-fluid logo--tarifa" width="80" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                            <img src="./assets/img/jz-login-color.svg" class="img-fluid logo--tarifa mb-1" width="80" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                        </div>
                    </div>
                    <div class="progress mt-4 pro-com">
                        <div class="progress-bar progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngStyle]="{ 'width.%': getWidth() }" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small class="text-muted" *ngIf="rappelSimyoData?.current?.next_level">
                        Te quedan <b>{{ rappelSimyoData.current.next_level.min_activations - rappelSimyoData.current.current_activations }}</b> activaciones para pasar a la categoría <span class="orange">{{ rappelSimyoData.current.next_level.text }}</span>
                    </small>
                    <ng-container *ngIf="today?.date() > dayToShowCategoryRappelPreviousMonth">
                        <br>
                        <small class="text-muted">
                            Categoría mes anterior <b class="orange">{{ translateCategoryRappel[pdv.info.clasificacion] || 'Básico' }}</b>
                        </small>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="!rappelSimyoData && !rappelSimyoDataError" class="col-xl-12 card-footer--sidebar">
                <div class="mt-2">
                    <div class="my-5">
                        <h5 class="tar-name-preload text-center">Obteniendo rappel de Simyo...</h5>
                        <div class="d-flex justify-content-center">
                            <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="rappelSimyoDataError">
                <h5 class="tar-name-preload text-center">Se ha producido un error, inténtalo de nuevo más tarde</h5>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #points id="points">
    <div class="card--sidebar mb-3">
        <div class="card-header--sidebar">
            ¡Atrévete!
        </div>
        <div class="card-body--sidebar">
            <div class="row" *ngIf="pointsService.checkIsAcceptedPointsProgram(pdv.info.points_terms)">
                <div class="col-12 col-md pt-1" *ngIf="dataPoints">
                    <b class="title-points orange">{{dataPoints.pointsAvailable}} </b>
                    Puntos disponibles
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a (click)="viewPoints()" class="btn btn-black-sidebar btn-sm">Ver puntos</a>
                </div>
            </div>

            <div class="row" *ngIf="pointsService.checkIsAcceptedPointsProgram(pdv.info.points_terms) === false">
                <div class="col-12 col-md pt-1">
                    <i-feather name="gift" class="icon--fe"></i-feather>
                    Únete al programa de puntos
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a (click)="viewPoints()" class="btn btn-black-sidebar btn-sm">Gestionar</a>
                </div>
            </div>
        </div>
        <ng-container *ngIf="pointsService.checkIsAcceptedPointsProgram(pdv.info.points_terms)">
            <div class="card-footer--sidebar" *ngIf="dataPoints">
                <div class="progress mt-4 pro-com">
                    <div class="progress-bar progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngStyle]="{ 'width.%': getPointsWidth() }" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <small class="text-muted">
                    Puntos mes actual <span class="kpi-points-home kpi" [ngClass]="{'kpi-per-positive2' : dataPoints.ratePointsWonLastWeek > 0, 'kpi-per-negative2': dataPoints.ratePointsWonLastWeek < 0, 'kpi-per-neutro2': dataPoints.ratePointsWonLastWeek === 0 }">
                        {{ getAbsoluteValue(dataPoints.ratePointsWonLastWeek) }}% </span><span>vs anterior</span>
                </small>
            </div>
            <div *ngIf="dataPointsError">
                <h5 class="tar-name-preload text-center">Se ha producido un error, inténtalo de nuevo más tarde</h5>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #terminalesLibres id="free_terminals">
    <div class="card--sidebar mb-3" *ngIf="pdv?.info?.tipo_vista === PDV_MULTIMARCA && permSv.hasPermFromV2(allPermissions.others.terminales_libres)">
        <div class="card-header--sidebar">
            Terminales libres
        </div>

        <div>
            <div class="card-footer--sidebar row ms-0">
                <div class="col-12 col-md pt-1">
                    <img src="./assets/img/phone_home.svg" class="img-fluid avisos--sidebar--icon" width="16">
                    Venta de terminales
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a href="{{ baseUrl }}tlib_activa.itx" class="btn btn-black-sidebar btn-sm">Acceder</a>
                </div>
            </div>
            <div class="card-footer--sidebar row ms-0">
                <div class="col-12 col-md pt-1">
                    <img src="./assets/img/phone_home.svg" class="img-fluid avisos--sidebar--icon" width="16">
                    Consulta tus ventas
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a href="{{ baseUrl }}tlib_recogida_pdv.itx" class="btn btn-black-sidebar btn-sm">Acceder</a>
                </div>
            </div>
            <div class="card-footer--sidebar row ms-0">
                <div class="col-12 col-md pt-1">
                    <img src="./assets/img/phone_home.svg" class="img-fluid avisos--sidebar--icon" width="16">
                    Gestiona el stock
                </div>
                <div class="col-12 col-md-auto mobile--ver--sidebar">
                    <a href="{{ baseUrl }}tlib_gestion_stock.itx" class="btn btn-black-sidebar btn-sm">Acceder</a>
                </div>
            </div>
        </div>
    </div>
</ng-template>
