import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class RmOrderService {

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
    }

    getHeaders() {
        const token = 'Bearer ' + this.auth.getToken();

        return new HttpHeaders({
            Authorization: token,
            'Content-Type': 'application/json'
        });
    }

    getOrderFromService(id) {
        return this.http.get(environment.rmEndpoint + 'order/' + id, {headers: this.getHeaders()});
    }

}
