<div class="modal-container">
    <div class="modal-body p-0">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 d-flex align-items-center flex-column">
                    <video class="border-video" src="./assets/video/banner-atrevete.mp4"  type="video/mp4" width="800" height="450" 
                        controls muted autoplay playsinline>
                        <p>Su navegador no soporta vídeos HTML5.</p>
                    </video>
                </div>
                <div class="col-lg-12 mt-3 d-flex justify-content-end">
                    <button type="button" class="btn mb-2 px-4 btn-modal" (click)="dialogRef.close(true)">
                        Acceder
                    </button>
                </div>
            </div>
        </div>
    </div> 
</div>

