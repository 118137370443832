<div class="modal-header">
    <h5 class="modal-title fw-bold ms-3 mt-3" id="exampleModalLabel">Manuales</h5>
    <button type="button" class="close close-m2" mat-dialog-close>
        <img src="./assets/img/x-black.svg" width="18" class="close-aviso--1">
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div class="row mb-2">
            <div class="col-12 col-xl">
                <!-- RM -->
                <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.rep_movil.permite_ventas)">
                    <div class="card-body--notify">

                        <div class="row mb-0">
                            <div class="col-12 col-sm">
                                <img src="./assets/img/rm-login-color.svg" class="img-fluid marca--rm soporte--logo" width="120">
                            </div>
                            <div class="col-12 col-sm-auto">
                                <p class="pt-2 mb-0">
                                    <a href="./assets/pdf/RM - Nuevo Frontal DIGO.pdf" target="_blank" class="fw-bold black">Ver manual</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- SIMYO -->
                <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                    <div class="card-body--notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm">
                                <img src="./assets/img/sm-login-color.svg" class="img-fluid marca--rm soporte--logo" width="120"> Guía de uso
                            </div>
                            <div class="col-12 col-sm-auto">
                                <p class="pt-2 mb-0">
                                    <a *ngIf="!permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature)" href="./assets/pdf/Simyo - Nuevo Frontal DIGO.pdf" target="_blank" class="fw-bold black">Ver manual</a>
                                    <a *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature)" href="./assets/pdf/Simyo - Frontal DIGO.v2.9.1.pdf" target="_blank" class="fw-bold black">Ver manual</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                    <div class="card-body--notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm">
                                <img src="./assets/img/sm-login-color.svg" class="img-fluid marca--rm soporte--logo" width="120"> Resumen de contrato
                            </div>
                            <div class="col-12 col-sm-auto">
                                <p class="pt-2 mb-0">
                                    <a href="./assets/pdf/Manual Resumen Contrato simyo_1_2023.pdf" target="_blank" class="fw-bold black">Ver manual</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Manual Resumen Contrato simyo_1_2023.pdf-->


                <!-- ORANGE -->
                <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) && permSv.hasPermFromV2(allPermissions.orange.permite_sell_bonos_aditional)">
                    <div class="card-body--notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm">
                                <img src="./assets/img/or-login-color.svg" class="img-fluid marca--rm soporte--logo" width="120"> Bonos adicionales
                            </div>
                            <div class="col-12 col-sm-auto">
                                <p class="pt-2 mb-0">
                                    <a href="./assets/pdf/Orange - Manual de usuario Bonos adicionales DIGO - v1_0_1.pdf" target="_blank" class="fw-bold black">Ver manual</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_sell_esim) && pdv.info.username === 'demo_pdv'">
                    <div class="card-body--notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm">
                                <img src="./assets/img/or-login-color.svg" class="img-fluid marca--rm soporte--logo" width="120"> eSIM
                            </div>
                            <div class="col-12 col-sm-auto">
                                <p class="pt-2 mb-0">
                                    <a href="./assets/pdf/Manual de usuario - Orange eSIM prepago en DIGO - v1_0_2.pdf" target="_blank" class="fw-bold black">Ver manual</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_sell_esim)">
                    <div class="card-body--notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm">
                                <img src="./assets/img/or-login-color.svg" class="img-fluid marca--rm soporte--logo" width="120"> Resumen de contrato
                            </div>
                            <div class="col-12 col-sm-auto">
                                <p class="pt-2 mb-0">
                                    <a href="./assets/pdf/Manual Resumen Contrato Orange_1_2023.pdf" target="_blank" class="fw-bold black">Ver manual</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- JAZZTEL -->
                <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                    <div class="card-body--notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm">
                                <img src="./assets/img/jz-login-color.svg" class="img-fluid marca--rm soporte--logo" width="120"> Activación Jazztel
                            </div>
                            <div class="col-12 col-sm-auto">
                                <p class="pt-2 mb-0">
                                    <a href="./assets/pdf/Manual de usuario - Jazztel Pangea LOWCOST v_2_7.docx" target="_blank" class="fw-bold black">Ver manual</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Atrevete -->
                <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.others.access_points)">
                    <div class="card-body--notify">
                        <div class="row mb-0">
                            <div class="col-12 col-sm">
                                <img src="./assets/img/logo_atrevete_manual.png" class="img-fluid marca--rm soporte--logo" width="120"> Programa de puntos
                            </div>
                            <div class="col-12 col-sm-auto">
                                <p class="pt-2 mb-0">
                                    <a href="./assets/pdf/Manual de usuario - Atrévete.pdf" target="_blank" class="fw-bold black">Ver manual</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
