import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment/moment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-datepicker-custom',
  templateUrl: './datepicker-custom.component.html',
  styleUrls: ['./datepicker-custom.component.css']
})
export class DatepickerCustomComponent implements OnInit {
  @Input() title: string;
  @Input() form;
  @Input() name: string;
  @Input() maxDate;
  @Input() minDate
  @Input() useCustomHeader: boolean = false;
  @Input() disabled: boolean = false;
  @Input() disabledInput: boolean = false;

  @Output() onChangeDate = new EventEmitter();
  public faCalendarDay = faCalendarDay;
  constructor() { }

  ngOnInit(): void {
  }

  changeDate(event) {
    this.onChangeDate.emit(event);
  }

  todayClicked(picker:any) {
    this.form.get(this.name).setValue(moment());
    picker.close();
  }

  selectedDate(picker:any)
  {
      setTimeout(()=>{
          picker._componentRef?.instance._calendar.monthView.selectedChange.pipe(take(1)).subscribe((res:any)=> {
            this.form.get(this.name).setValue(res);
              picker.close();
          })

      })
  }
  clearStartDate(picker:any) {
    this.form.get(this.name).setValue(null);
      picker.close();
  }

  public checkErrorFieldForm(fieldName, errors:string[]) {
    return this.form.get(fieldName)?.errors ? Object.keys(this.form.get(fieldName)?.errors).findIndex((key) => errors.includes(key)) > -1 : false;
}

}
