import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-accepted-points-program',
  templateUrl: './accepted-points-program.component.html',
  styleUrls: ['./accepted-points-program.component.scss']
})
export class AcceptedPointsProgramComponent implements OnInit {
  termsAndConditionFormGroup: FormGroup;
  hasFormError = false;
  constructor(
    public activeModal: NgbActiveModal,
    public dialogRef: MatDialogRef<AcceptedPointsProgramComponent>,
    private formBuilder: FormBuilder) {

    this.termsAndConditionFormGroup = this.formBuilder.group({
      conditions: [false, [Validators.requiredTrue]]
  });
   }

  ngOnInit(): void {
  }

  validateForm() {
    this.hasFormError = false;
    if(this.termsAndConditionFormGroup.valid) {
      this.dialogRef.close(this.termsAndConditionFormGroup.value);
      return;
    }
    this.hasFormError = true;
  }

}
