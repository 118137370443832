
<div class="container" *ngIf="createLoading">
    <div class="row">
        <div class="col-lg-8 offset-2">
            <section class="centered--carga">
                <h3 class="fw-bold text-center" >
                    Activando los servicios
                </h3>
                <p class="text-center">
                    Manténgase a la espera, este proceso puede tardar varios segundos.
                </p>
                <br>

                <!-- Barra de carga -->
                <div class="barra--carga">
                    <div id="load-bar" class="move"></div>
                </div>
                <!-- ./ Barra de carga -->
            </section>
        </div>
    </div>
</div>

<ng-container *ngIf="!createLoading">
    <div class="container content">
        <div class="row">
            <div class="col-md-12">
                <h5 class="title-step text-center" ><img src="./assets/img/credit-card.svg" class="img-fluid ico-title" width="20">
                    Confirmación del duplicado
                </h5>
                <!-- Notificación -->
                <div class="alert" [ngClass]="{'alert-danger': createError, 'alert-success': createOk, 'alert-warning': createWarning}" role="alert" *ngIf="createOk || createError || createWarning">
                    <h4 class="alert-heading mt-2">
                        <b *ngIf="createOk">Duplicado tramitado correctamente</b>
                        <b *ngIf="createError">Fallo en la tramitacion</b>
                        <b *ngIf="createWarning">Duplicado en proceso</b>
                    </h4>
                    <p *ngIf="createOk">El duplicado de SIM se ha gestionado correctamente</p>
                    <p *ngIf="createError">{{ createErrorMsg || 'No se ha podido realizar el duplicado de SIM en estos momentos. Por favor, inténtelo de nuevo más tarde.' }}</p>
                    <p *ngIf="createWarning">El duplicado está en proceso. Vaya al listado de duplicados para verificar el estado en unos minutos.</p>
                </div>
            </div>
        </div>
    </div>
    <!--------- Resumen de la tramitación ------------->
    <!-------------------------------------------------->

    <div class="container firma mt-4 mb-5">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card mb-3">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2">
                                            <b>Cliente</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body bg-white">
                                <div class="row mt-4">
                                    <div class="col-lg-9">
                                        <!-- Nombre cliente -->
                                        <h5 class="title-list ms-3">
                                            <a class="text-list">
                                                <b>{{ formGroup?.get('name')?.value + ' ' + formGroup?.get('lastName1')?.value + ' ' + formGroup?.get('lastName2')?.value}}</b>
                                            </a>
                                            <br><br>
                                            <!-- Documento de identidad -->
                                            <span class="font-list">
                                                <img src="./assets/img/file.svg" class="img-fluid" width="13">
                                                {{ getDocumentType(formGroup?.get('documentType')?.value) }}: <b>{{ formGroup?.get('documentNumber')?.value }}</b>
                                            </span>
                                            <br>
                                            <!-- Fecha de solicitud: -->
                                            <span class="font-list">
                                                <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                Fecha de solicitud: <b>{{ today | date: 'dd/MM/yyyy, HH:mm' }}</b>
                                            </span>
                                            <br>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-3">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2 ms-3">
                                            <img src="./assets/img/file-text-negro.svg" class="img-fluid ico-title me-1" width="13">
                                            <b>Resumen de la tramitación</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-white">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item cuerpo-pack-2">
                                                <!-- RESUMEN MOVIL PACK -->
                                                <h5  class="tar-name-7 mt-2 ms-3">
                                                    <b>Conceptos</b>
                                                </h5>

                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <span class="sidebar-info4 simyo">Duplicado</span>
                                                    <small class="text-muted"></small>
                                                </div>
                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <!-- MSISDN -->
                                                    <span class="sidebar-info5">
                                                        <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                        Nº de Simyo: <b>{{ formGroup?.get('msisdn')?.value }}</b>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_sell_esim)">
                                                    <!-- Tipo de tarjeta -->
                                                    <span class="sidebar-info5">
                                                        <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                        Tipo de tarjeta: <b>{{ formGroup?.get('is_esim')?.value == typeCard.esim ? 'eSIM' : 'SIM física' }}</b>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="formGroup?.get('is_esim')?.value == typeCard.sim">
                                                    <!-- ICCID -->
                                                    <span class="sidebar-info5">
                                                        <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                        ICCID nuevo: <b>{{ formGroup?.get('iccid')?.value }}</b>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <!-- Motivo del cambio -->
                                                    <span class="sidebar-info5"><img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                        Motivo del cambio: <b>{{ getReasonLabel(formGroup?.get('reason')?.value) }}</b>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="createOk || createError || createWarning">
                                                    <!-- Estado duplicado -->
                                                    <span class="sidebar-info5"><img src="./assets/img/info.svg" class="img-fluid" width="13">
                                                        Estado duplicado:
                                                        <b *ngIf="createOk">Completado</b>
                                                        <b *ngIf="createError">Error de procesamiento</b>
                                                        <b *ngIf="createWarning">En progreso</b>
                                                    </span>
                                                </div>
                                                <br>
                                                <ng-container *ngIf="priceSwapSim > 0">
                                                    <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                                        <span class="sidebar-info">
                                                            <b>Total</b>
                                                        </span>
                                                        <small class="text-muted trash-carrito">
                                                            <!-- Precio tarifa -->
                                                            <span class="sidebar-info2">
                                                                <b>{{ (priceSwapSim).toFixed(2) }} €</b>
                                                            </span>
                                                        </small>
                                                    </div>
                                                    <hr>
                                                    <!-- Importe Total -->
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                        <h5 class="total-color">
                                                            <b>Pago total</b>
                                                        </h5>
                                                        <h5 class="text-auto">
                                                            <b>{{ (priceSwapSim).toFixed(2) }} €</b>
                                                        </h5>
                                                    </div>
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                        <span class="total-color">
                                                            <b>Impuestos indirectos incluidos</b>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <app-simyo-ds-documentation-signature [allowResentDocuments]="allowResentDocuments" (documentsSigned)="onDocumentsSigned($event)"></app-simyo-ds-documentation-signature>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--------- ACTIVAR SERVICIOS ------------>
    <!---------------------------------------->

    <div class="container firma">
        <br>
        <div class="row">
            <div class="col-md-12">
                <a (click)="simSwapCreate()" *ngIf="!createOk && !createWarning && !createError && documentsSigned" class="btn btn-simyo-o2 d-block w-100 mb-5 pt-3" style="padding-bottom: 20px !important;">
                    <b>Tramitar solicitud</b>
                </a>
            </div>
        </div>
    </div>
</ng-container>
