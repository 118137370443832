import { Component, Input, OnInit } from '@angular/core';
import { OrangeRate, OrangeRatesWP } from 'src/app/orange/models/masterAllResponse';

@Component({
  selector: 'app-orange-prepaid-confirmation-card',
  templateUrl: './orange-prepaid-confirmation-card.component.html',
  styleUrls: ['./orange-prepaid-confirmation-card.component.css']
})
export class OrangePrepaidConfirmationCardComponent implements OnInit {

  @Input() lineNumber: number;
  @Input() line: string;
  @Input() title: string;
  @Input() typeOperation: string;
  @Input() welcomePackSelected: OrangeRatesWP
  @Input() rate: OrangeRate;
  constructor() { }

  ngOnInit(): void {
  }


  public getAltaName() {
    if (this.welcomePackSelected.is_esim) {
        if(this.typeOperation === 'portability') {
            return 'Línea móvil a portar ';
        }
        return 'Alta eSIM prepago ';
    }

    if (this.welcomePackSelected.is_portability) {
        return 'Línea móvil a portar ';
    }

    if (this.welcomePackSelected.is_terminal) {
        return 'Alta SIM + Terminal ';
    }

    if (this.welcomePackSelected.is_prepaid && !this.welcomePackSelected.is_esim) {
        return 'Alta linea móvil ';
    }
}
}
