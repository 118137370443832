<div class="modal-body p-0">
    <div class="modal-content">
        <div class="modal-header bg-white">
            <h4 class="modal-title fw-bold ms-3 mt-3" id="exampleModalLabel">¡NOS ESTAMOS ACTUALIZANDO!  ;)</h4>

            <button type="button" class="close close-m2" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <img src="./assets/img/x-black.svg" width="18" class="close-aviso--1" alt="Cerrar">
            </button>
        </div>

        <div class="modal-body mb-3">
            <div class="container">
                <p class="lead">En estos momentos estamos realizando labores de mantenimiento programadas para poder ofrecerte el mejor servicio.</p>

                <p class="lead">Perdón por la espera y gracias por confiar en Simyo.<br>Nos vemos a la vuelta ;)</p>
            </div>
        </div>
    </div>
</div>
