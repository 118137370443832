import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {RmListadoActivacionesComponent} from './components/rm-listado-activaciones/rm-listado-activaciones.component';
import {RmOrderDetailComponent} from './components/rm-order-detail/rm-order-detail.component';

const routes: Routes = [
    {path: '', redirectTo: 'listado', pathMatch: 'full', data: {title:'Republica Movil - listado'}},
    {path: 'listado', component: RmListadoActivacionesComponent, data: {title:'Republica Movil - listado'}},
    {path: 'order/:order_id', component: RmOrderDetailComponent, data: {title:'Republica Movil - detalle'}}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RepublicaMovilRouting { }
