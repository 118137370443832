import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OperatorsService {

  private listOperators = [
    { value: "", title: "Seleccione operador" },
    { value: "104", title: "ADAMO" },
    { value: "39", title: "Aire Networks" },
    { value: "75", title: "Alai" },
    { value: "76", title: "Alów" },
    { value: "77", title: "ALTECOM" },
    { value: "60", title: "Amena" },
    { value: "73", title: "Bankinter movil" },
    { value: "96", title: "BBM" },
    { value: "78", title: "Best Móvil" },
    { value: "67", title: "Bluephone SL" },
    { value: "31", title: "BT" },
    { value: "56", title: "BT Móvil" },
    { value: "80", title: "Cable móvil" },
    { value: "6", title: "Carrefour" },
    { value: "111", title: "Citelia" },
    { value: "12", title: "Convergente BT" },
    { value: "70", title: "Deion Comunicaciones" },
    { value: "10", title: "Dia" },
    { value: "25", title: "Digi Spain" },
    { value: "17", title: "EroskiMovil" },
    { value: "8", title: "Euskaltel" },
    { value: "107", title: "Fi Network" },
    { value: "45", title: "Fleximovil" },
    { value: "108", title: "GLOBAL CARRIER" },
    { value: "97", title: "GRUPALIA INTERNET" },
    { value: "83", title: "GT Mobile" },
    { value: "84", title: "Happy Móvil" },
    { value: "88", title: "HITS" },
    { value: "20", title: "Hits Mobile" },
    { value: "61", title: "Hualong" },
    { value: "50", title: "IBERCOM" },
    { value: "89", title: "INFOVOIP" },
    { value: "66", title: "ION Mobile" },
    { value: "51", title: "IOS" },
    { value: "21", title: "Jazztel" },
    { value: "85", title: "Jetnet" },
    { value: "109", title: "JETNET 5G" },
    { value: "90", title: "JOI MOBILE" },
    { value: "59", title: "Kitel" },
    { value: "86", title: "KNET Móviles" },
    { value: "87", title: "LCR" },
    { value: "52", title: "Least Cost Routing Telecom" },
    { value: "36", title: "Lebara Movil" },
    { value: "55", title: "Lemonvil" },
    { value: "26", title: "Lowy - Vodafone Enabler" },
    { value: "32", title: "Lycamobile" },
    { value: "24", title: "LlamaYa" },
    { value: "18", title: "Masmovil" },
    { value: "68", title: "Masmovil 3.0" },
    { value: "92", title: "Móbilcat" },
    { value: "106", title: "MOMOFONE" },
    { value: "93", title: "Movidata" },
    { value: "91", title: "MOVILDIA" },
    { value: "47", title: "MOVILINE" },
    { value: "2", title: "Movistar" },
    { value: "94", title: "Movizelia" },
    { value: "95", title: "NETHITS MOBILE" },
    { value: "105", title: "O2" },
    { value: "41", title: "Oceans" },
    { value: "23", title: "ONITI" },
    { value: "46", title: "OpenCable Telecomunicaciones" },
    { value: "79", title: "ORANGE NEX" },
    { value: "54", title: "Parlem" },
    { value: "28", title: "PEPEPHONE 3.0" },
    { value: "42", title: "Pepephone 4G" },
    { value: "64", title: "Procono S.A." },
    { value: "58", title: "PTV Telecom 4G" },
    { value: "40", title: "PTVTelecom" },
    { value: "100", title: "QUATTRE" },
    { value: "112", title: "Quattre Internet" },
    { value: "62", title: "Quatre Internet SL" },
    { value: "98", title: "QUATTRE INTERNET SL" },
    { value: "15", title: "R Cable y Telecomunicaciones" },
    { value: "57", title: "Republica Movil" },
    { value: "48", title: "Sarenet" },
    { value: "65", title: "Sewan" },
    { value: "82", title: "Simly" },
    { value: "19", title: "Simyo" },
    { value: "81", title: "Suop" },
    { value: "101", title: "SWENO" },
    { value: "11", title: "Telecable" },
    { value: "110", title: "Telefónica AI of Things" },
    { value: "102", title: "TELSOME" },
    { value: "7", title: "The Phone House (Happy Movil)" },
    { value: "63", title: "The Telecom Boutique" },
    { value: "35", title: "Truphone" },
    { value: "44", title: "Tuenti" },
    { value: "72", title: "Vizzavi" },
    { value: "3", title: "Vodafone" },
    { value: "74", title: "Vodafone Enabler (lowi)" },
    { value: "69", title: "Vodafone Roaming" },
    { value: "43", title: "Vodafone-ONO" },
    { value: "49", title: "Voz Telecom" },
    { value: "4", title: "Yoigo" },
    { value: "29", title: "You Mobile" },
    { value: "71", title: "Zeromovil" },
    { value: "103", title: "ZINNIA" }
  ];
  
  constructor() { }

  getOperators() {
    return this.listOperators;
  }
}
