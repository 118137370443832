import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import * as moment from 'moment';
import {orderType, typeCard} from 'src/app/shared/constantes';
import {Order} from '../classes/order';

@Injectable({
    providedIn: 'root'
})
export class SimyoDraftService {
    public continueNavigation: Subject<boolean> = new Subject<boolean>();
    private updateDraftSignal: BehaviorSubject<boolean>;
    private mobilePackForms: BehaviorSubject<any>;

    constructor() {
        this.updateDraftSignal = new BehaviorSubject<boolean>(false);
        this.mobilePackForms = new BehaviorSubject<any>([]);
    }

    isPersistableOrder(order: Order, ratesMobile) {
        return order.draft && (
            (order.broadband && order.broadband.rate_id && order.broadband.rate_id !== '') ||
            // (order.mobiles && order.mobiles.length) ||
            (ratesMobile && ratesMobile.length)
        );
    }

    updateDraft(val: boolean) {
        this.updateDraftSignal.next(val);
    }

    getUpdateDraft() {
        return this.updateDraftSignal.asObservable();
    }

    setMobilePackForms(data) {
        this.mobilePackForms.next(data);
    }

    getMobilePackForms() {
        return this.mobilePackForms.asObservable();
    }

    getUserDataFromOrder(order) {
        const user = {
            doctype: order.customer_document_type_id,
            doc: order.customer_document,
            country: order.customer_nationality_id,
            firstName: order.customer_name,
            surname1: order.customer_last_name,
            surname2: order.customer_second_last_name,
            birthday: order.customer_birth_date,
            sex: order.customer_gender_id,
            company: order.customer_company_name,
            tipoVia: '',
            nombreVia: '',
            numero: '',
            planta: '',
            puerta: '',
            escalera: '',
            bloque: '',
            codigoPostal: '',
            localidad: '',
            provincia: '',
            phone: order.customer_phone_number,
            email: order.customer_email,
            account_types: '',
            bill_cicle_types: '',
            acct_code: '',
            bank_account: order.iban,
            new_bank_account: '',
            account_doctype: order.customer_document_type_id,
            account_doc: order.customer_document,
            account_country: order.customer_nationality_id,
            account_firstName: order.customer_name,
            account_surname1: order.customer_last_name,
            account_surname2: order.customer_second_last_name,
            account_company: '',
            office: '',
            full_name: '',
            delivery_phone: '',
            road_type: '',
            address: '',
            number: '',
            floor: '',
            door: '',
            stairway: '',
            zip: '',
            city: '',
            province: '',
            additional_info: '',
            is_custom_delivery: false
        };

        if (order.payer != null) {
            user.account_doctype = order.payer.document_type_id;
            user.account_doc = order.payer.document;
            user.account_country = '';
            user.account_firstName = order.payer.name;
            user.account_surname1 = order.payer.last_name;
            user.account_surname2 = order.payer.second_last_name;
            user.account_company = '';
            user.acct_code = order.payer.acct_code;
            user.account_types = order.payer.account_type_id;
            user.bill_cicle_types = order.payer.bill_cycle_type_id;
        }

        if (order.addresses.length) {
            const contactAddress = order.addresses.filter(address => {
                return address.type === 'postal';
            });

            if (contactAddress.length) {
                user.tipoVia = contactAddress[0].street_type_id ? contactAddress[0].street_type_id : 29;
                user.nombreVia = contactAddress[0].street_name;
                user.numero = contactAddress[0].street_number;
                user.planta = contactAddress[0].floor_number;
                user.puerta = contactAddress[0].apartment_number;
                user.escalera = contactAddress[0].staircase_number;
                user.bloque = contactAddress[0].block;
                user.codigoPostal = contactAddress[0].post_code;
                user.localidad = contactAddress[0].city;
                user.provincia = contactAddress[0].province_id ? contactAddress[0].province_id: '';
                // user.additional_info = contactAddress[0].additional_info;
            }

            const deliveryAddress = order.addresses.filter(address => {
                return address.type === 'delivery';
            });

            if (deliveryAddress.length) {
                user.full_name = deliveryAddress[0].name;
                user.delivery_phone = deliveryAddress[0].phone_number;
                user.road_type = deliveryAddress[0].street_type_id ? deliveryAddress[0].street_type_id : 29;
                user.address = deliveryAddress[0].street_name;
                user.number = deliveryAddress[0].street_number;
                user.floor = deliveryAddress[0].floor_number;
                user.door = deliveryAddress[0].apartment_number;
                user.stairway = deliveryAddress[0].staircase_number;
                user.zip = deliveryAddress[0].post_code;
                user.city = deliveryAddress[0].city;
                user.province = deliveryAddress[0].province && deliveryAddress[0].province_id ? deliveryAddress[0].province_id : '';
                user.is_custom_delivery = true;
            } else {
                user.is_custom_delivery = null;
            }
        } else {
            user.is_custom_delivery = null;
        }

        return user;
    }

    getNormalizador(address: any) {
        return {
            tipoViaNormaliza: address.street_type && address.street_type.code ? address.street_type.code : 'CL',
            nombreViaNormaliza: (address.street_type && address.street_type.translation ? address.street_type.translation : 'Calle') + ' ' + address.street_name,
            numeroNormaliza: address.street_number,
            codigoPostalNormaliza: address.post_code,
            localidadNormaliza: address.city,
            provinciaNormaliza: address.province && address.province.value ? address.province.value : '',
        };
    }

    getCobertura(address: any, broadband: any) {
        return {
            localidadCobertura: address.city,
            direccionesCobertura: address.street_name,
            tipoViaCobertura: address.street_type_id ? address.street_type_id : 29,
            nombreViaCobertura: address.street_name,
            numeroCobertura: address.street_number,
            provinciaCobertura: address.province_id ? address.province_id : '',
            codigoPostalCobertura: address.post_code,
            plantaCobertura: address.floor_number,
            bisCobertura: '',
            bloqueCobertura: address.block,
            portalCobertura: '',
            escaleraCobertura: address.staircase_number,
            tipoPuertaCobertura: '',
            puertaCobertura: address.apartment_number,
            resultadoConsultaCobertura: {
                technology: [broadband.access_type],
                commercial_deal: broadband.commercial_deal,
                cimaGescalCode: broadband.cima_gescal_code,
            }
        };
    }

    getMobilePack(item: any) {
        if (item.rate && !item.rate.type_pay) {
            item.rate.type_pay = item.contract_type;
        }

        return {
            type_operation: !item.portability ? 'registration' : 'portability',
            type_pay: item.contract_type,
            rate: {
                ...item.rate,
                //bonos: item.bonos.map(bono => bono.rate),
                terminal: this.getTerminal(item.terminal_oid),
            },
            portability_phone: item.msisdn,
            portability_operator: item.service_provider ? item.service_provider.service_provider_id : '',
            portability_type_client: item.old_contract_type,
            portability_iccid: item.old_iccid,
            portability_client: '',
            portability_date: item.processing_date ? moment(item.processing_date).format('YYYY-MM-DD') : '',
            iccid: item.new_iccid,
            terminal: this.getTerminal(item.terminal_oid),
            terminal_payment_type: item.terminal_oid ? (item.terminal_oid.is_installment ? 'VAP' : 'unico') : '',
            terminal_insurance: '',
            stepper_index: '',
            is_esim: item.is_esim,
            is_main_line: item.is_main_line,
            discount: item.discount,
            total_without_discount: item.total_without_discount,
            total_line: item.total_line,
            recharge: item.recharge,
        };
    }

    getPath(type: string) {
        let path = '';
        switch (type) {
            case orderType.BROADBAND:
                path = '/simyo/solo-fibra';
                break;
            case orderType.MOBILE:
                path = '/simyo/solo-movil';
                break;
            default:
                path = '/simyo/convergente';
                break;
        }
        return path;
    }

    camelToSnake(key: string) {
        return key.replace(/([A-Z])/g, '_$1').toLowerCase();
    }

    snakeToCamel(key: string) {
        key = key.toLowerCase();
        return key.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
        });
    }

    getRate(rate: any, additionalInfo = null) {
        // rate.contract_type = rate.payment_type === 'post' ? 'postpaid' : 'prepaid';
        if(additionalInfo) {
            rate.front_discount = additionalInfo.discount;
            rate.front_total_without_discount = additionalInfo.total_without_discount;
            rate.front_type_discount = additionalInfo.type_discount;
        }
        return rate; // this.keysToCamel(rate);
    }

    keysToCamel(object) {
        const newObject: any = {};

        Object.entries(object).forEach(([key, val]) => {
            if (val != null && parseFloat(val.toString()) > 0) {
                val = parseFloat(val.toString());
            }

            newObject[this.snakeToCamel(key)] = val;
        });

        return newObject;
    }

    getTerminal(terminal_oid): any {
        if (terminal_oid && terminal_oid.terminal) {
            let aux: any;
            aux = terminal_oid.terminal;
            aux.paymentType = terminal_oid.is_installment ? 'VAP' : 'unico';
            return aux;
        }
        return '';
    }
}
