<!-- Filtro rápido y estadísticas -->
<div class="content">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-12">
                    <div class="btn btn-black-noclick btn-sm mt-3">
                        <strong>Actividad</strong> ({{ currentMonth }}):
                    </div>
                    <button type="button" class="btn btn-black-click btn-sm mt-3" (click)="getActivationThisMonth()">
                        <strong>{{ orderListStats?.activations_current_month || '0' }}</strong> activaciones
                    </button>
                    <button type="button" class="btn btn-black-click btn-sm mt-3 me-3" (click)="getRequestThisMonth()">
                        <strong>{{ orderListStats?.orders_current_month || '0' }}</strong> solicitudes
                    </button>

                    <div class="btn btn-grey3-noclick btn-sm mt-3 fw-normal">
                        <strong>Alertas</strong>:
                    </div>
                    <button type="button" class="btn btn-grey3 btn-sm mt-3 fw-normal" (click)="getAlertThisMonth(1); isAlertView = true">
                        <strong>{{ orderListStats?.alerts_last_week || '0' }}</strong> alertas
                    </button>

                    <button type="button" class="btn btn-grey3 btn-sm mt-3 fw-normal" (click)="getSuspendedThisMonth(); isAlertView = true">
                        <strong>{{ orderListStats?.cancelleds_current_month || '0' }}</strong> canceladas
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="resumen d-flex justify-content-between align-items-center">
                <div>
                    <mat-form-field class="mb-2">
                        <mat-label>
                            <img src="./assets/img/search2.svg" class="img-fluid icon-search" width="12"> Buscar por nombre o DNI
                        </mat-label>
                        <input matInput id="search-client" type="text" oninvalid="" (input)="searchClient($event.target.value)">
                        <i class="bar"></i>
                    </mat-form-field>
                </div>
                <!-- Filter -->
                <small class="text-muted">
                    <a href="#" data-bs-toggle="collapse" data-bs-target="#filtros" [attr.aria-expanded]="isCollapsed" aria-controls="filtros" title="Filtros avanzados">
                        <img src="./assets/img/filter.svg" class="img-fluid icon-filter" width="23">
                    </a>
                </small>
            </div>
        </div>
    </div>
</div>

<!-- Filtros avanzados y cuerpo -->
<div (window:scroll)="doSomethingOnWindowScroll($event)">
    <div class="row">
        <div class="col-lg-12">
            <div id="filtros" class="filters-collapse collapse" [ngbCollapse]="isCollapsed">

                <form [formGroup]="formGroup">
                    <div class="row">
                        <div class="col-lg-9">
                            <mat-form-field>
                                <mat-label>Nombre y/o apellidos</mat-label>
                                <input matInput formControlName="full_name">
                            </mat-form-field>
                        </div>

                        <div class="col-lg-3">
                            <mat-form-field>
                                <mat-label>NIF/NIE/Pasaporte/CIF</mat-label>
                                <input matInput formControlName="document">
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4">
                            <mat-form-field>
                                <mat-label>Nº teléfono móvil</mat-label>
                                <input matInput formControlName="msisdn_movil" maxlength="9">
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4">
                            <app-datepicker-custom
                                title="Fecha de solicitud desde"
                                [form]="formGroup"
                                name="min_date"
                                [maxDate]="maxDate"
                                (onChangeDate)="setMinDate($event)"
                                ></app-datepicker-custom>
                        </div>

                        <div class="col-lg-4">
                            <app-datepicker-custom
                                title="Fecha de solicitud hasta"
                                [form]="formGroup"
                                name="max_date"
                                [minDate]="minDate"
                                (onChangeDate)="setMaxDate($event)"
                                ></app-datepicker-custom>
                        </div>

                        <div class="col-lg-4">
                            <mat-form-field>
                                <mat-label>Estado</mat-label>
                                <select matNativeControl formControlName="status">
                                    <option value="">Todas</option>
                                    <option *ngFor="let status of master?.super_statuses" [ngValue]="status.value">
                                        {{ superStatusTranslate[status.super_status_id] }}
                                    </option>
                                </select>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4">
                            <mat-form-field>
                                <mat-label>Tipo de alta</mat-label>
                                <select matNativeControl formControlName="contract_type">
                                    <option value="">Todas</option>
                                    <option *ngFor="let type of listType" [value]="type.id">{{ type.label }}</option>
                                </select>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4">
                            <mat-form-field>
                                <mat-label>Ordenar por fecha</mat-label>
                                <select matNativeControl formControlName="sort_by_date">
                                    <option *ngFor="let type of listSortByDate" [value]="type.id">{{ type.label }}</option>
                                </select>
                            </mat-form-field>
                        </div>
                    </div>

                    <button class="btn btn-black-outline mb-1 btn-sm" type="button" (click)="advanceSearch()">Aplicar filtro</button>
                </form>

                <br>
            </div>
        </div>
    </div>
</div>

<div class="content" *ngIf="ordersFiltered.length > 0">
    <div class="row">
        <div class="col-lg-12">
            <!--- \\\\\\\Cliente -->
            <div class="card gedf-card mb-3" *ngFor="let order of ordersFiltered; let i = index">
                <div class="card-header" *ngIf="!order?.update_full">
                    <div class="row">
                        <div class="col">
                            <ul class="nav nav-pills card-header-pills topnav" id="customer-tab-{{ i }}" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link text-list active" data-bs-toggle="tab" href="#cliente-{{ i }}" role="tab" aria-controls="cliente" aria-selected="true">Cliente</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link text-list" data-bs-toggle="tab" role="tab" aria-controls="ob" aria-selected="false" href="#ob-{{ i }}">
                                        <img src="./assets/img/comment.svg" class="img-fluid icon-list me-1" width="16">
                                        <span class="mobile">Observaciones</span>
                                        <span class="navbar-unread" *ngIf="order.annotation">1</span>
                                    </a>
                                </li>
                                <li class="nav-item" *ngIf="order.doc_warning && order.doc_warning !== ''">
                                    <a class="nav-link text-list" data-bs-toggle="tab" role="tab" aria-controls="in" aria-selected="false" href="#in-{{ i }}">
                                        <img src="./assets/img/info.svg" class="img-fluid icon-list me-1" width="16">
                                        <span class="mobile">Incidencias</span>
                                        <span class="navbar-unread">1</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-auto d-flex align-items-center">
                            <div *ngIf="order.created_at || order.tramited_at || order.user">
                                <!-- Fecha de solicitud -->
                                <span class="info-secundaria dir-text2 d-inline-block" *ngIf="order.created_at || order.tramited_at">
                                    Fecha de solicitud: {{ convertDate(order.tramited_at && order.tramited_at !== '' ? order.tramited_at : order.created_at) }} <br>
                                </span>
                                <!-- Usuario -->
                                <span class="badge badge-light badge-shape" *ngIf="order.user">
                                    {{ order.user?.username}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body bg-white">
                    <div class="tab-content" id="customer-tab-content-{{ i }}" *ngIf="!order?.update_full">
                        <div class="tab-pane fade show active" id="cliente-{{ i }}" role="tabpanel" aria-labelledby="">
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <!-- Nombre cliente -->
                                        <h5 class="title-list mb-0">
                                            <span class="text-list" *ngIf="order.customer_name != null && order.customer_name != ''">
                                                <b *ngIf="order && order.is_contingency" ngbTooltip="Alta por soporte">
                                                    <img src="./assets/img/support.png" alt="Alta por soporte" class="img-fluid me-2" width="24" style="height: auto; top: -1px; position: relative;">
                                                </b>
                                                <b>{{ order.customer_full_name }}</b>
                                                <b *ngIf="order && order.customer_document && order.customer_document !== ''">
                                                    - {{ order?.customer_document_type?.translation }}: {{ order.customer_document }}
                                                </b>
                                                <br><br>
                                            </span>
                                            <!-- Tipo de alta -->
                                            <span class="font-list">
                                                <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                Tipo de alta:
                                                <b *ngIf="order.type === 'broadband' ">
                                                    {{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Alta fibra IND' : 'Alta fibra') }}
                                                </b>
                                                <b *ngIf="order.type === 'convergent'">
                                                    {{ 'Alta ' + (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'fibra IND: ' : 'fibra: ') }}
                                                    {{ (+order?.broadband?.rate?.rate_info?.contracted_price|toFixedDecimals) + '€' }}
                                                </b>
                                                <b *ngIf="order.type !== 'convergent' && order.type !== 'broadband' && order.mobiles && order.mobiles.length">
                                                    {{ (getTitleLine(order.mobiles[0])) + ' (' + order?.mobiles[0]?.rate?.data + ' + ' + order?.mobiles[0]?.rate?.voice + ': ' + (getPriceMobile(order?.mobiles[0])|toFixedDecimals) + '€)' }}
                                                    <!-- {{ (getTitleLine(order.mobiles[0])) + ' (' + order?.mobiles[0]?.rate?.data + ' + ' + order?.mobiles[0]?.rate?.voice + (order?.mobiles[0]?.bonos && order?.mobiles[0]?.bonos.length ? ' + ' + order?.mobiles[0]?.bonos.length + ' bono(s)' : '') + ': ' + (getPriceMobile(order?.mobiles[0])|toFixedDecimals) + '€)' }} -->
                                                </b>
                                            </span>
                                            <!-- Estado fibra -->
                                            <span class="font-list" *ngIf="order.broadband && ((order.broadband.status_description && order.broadband.status_description !== '') || order.broadband.substatus) && order.super_status !== superStatus.draft">
                                                <br>
                                                <img src="./assets/img/info.svg" class="img-fluid" width="13">
                                                Estado fibra:
                                                <span [ngbTooltip]="order?.broadband?.substatus?.description && order?.broadband?.substatus?.description !== '' ? infoBroadband : ''" [placement]="'right'">
                                                    <b [ngClass]="{'cursor-pointer': order?.broadband?.substatus}">{{ order.broadband.substatus?.name && order.broadband.substatus?.name !== '' ? order.broadband.substatus.name : order.broadband.status_description }} </b>
                                                    <span class="custom-info3 mx-1" *ngIf="order?.allow_check_broadband">
                                                        <i-feather (click)="updateStatus(order, typeUpdate.broadband)" name="refresh-cw" class="icon--fe m-1 cursor-pointer" [ngClass]="{'fa-spin disable-click': order?.class_broadband}" style="width: 12px !important; height: auto;"></i-feather>
                                                    </span>
                                                    <span *ngIf="order?.broadband?.substatus?.description && order?.broadband?.substatus?.description !== ''" class="px-2 custom-info cursor-pointer">+info</span>
                                                </span>
                                                <span *ngIf="order?.error_update_broadband" class="ms-1">No se ha podido actualizar el estado</span>
                                            </span>
                                            <ng-template #infoBroadband>
                                                <div class="d-flex flex-column">
                                                    <span>{{ order?.broadband?.substatus?.description }}</span>
                                                </div>
                                            </ng-template>
                                            <!-- MSISDN -->
                                            <span class="font-list" *ngIf="order.mobiles && order.mobiles.length">
                                                <br>
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                Línea móvil {{ order.mobiles[0].contract_type && order.mobiles[0].contract_type !== '' ? (order.mobiles[0].contract_type === 'prepaid' ? 'prepago' : 'pospago') : '' }}: <b>{{ (order.mobiles[0].new_msisdn && order.mobiles[0].new_msisdn !== '' ? order.mobiles[0].new_msisdn : 'Pendiente de asignar') + ' - ' + (order.mobiles[0].terminal_oid ? order.mobiles[0].terminal_oid?.terminal?.brand + ' ' + order.mobiles[0].terminal_oid?.terminal?.model : 'Sin Terminal') }}</b>
                                            </span>
                                            <!-- Estado linea movil -->
                                            <span class="font-list"
                                                  *ngIf="order.mobiles && order.mobiles.length && order.mobiles[0].status_description && order.super_status !== superStatus.draft">
                                                <br>
                                                <img src="./assets/img/info.svg" class="img-fluid" width="13">
                                                Estado línea móvil: <b>{{ order.mobiles[0].status_description }} </b>
                                                <span class="custom-info3 mx-1" *ngIf="order?.allow_check_mobile">
                                                    <i-feather (click)="updateStatus(order, typeUpdate.mobile)" name="refresh-cw" class="icon--fe m-1 cursor-pointer custom-info" [ngClass]="{'fa-spin disable-click': order?.class_mobile}" style="width: 12px !important; height: auto;"></i-feather>
                                                </span>
                                                <span *ngIf="order?.error_update_mobile" class="ms-1">No se ha podido actualizar el estado</span>
                                            </span>
                                            <!-- Estado linea movil cuando no recibo estado -->
                                            <span class="font-list"
                                                  *ngIf="order.mobiles && order.mobiles.length && (!order.mobiles[0].status_description || order.mobiles[0].status_description === '') && order.super_status !== superStatus.draft">
                                                <br>
                                                <img src="./assets/img/info.svg" class="img-fluid" width="13">
                                                Estado línea móvil:
                                                <span (click)="updateStatus(order, typeUpdate.mobile)" class="cursor-pointer custom-info2">
                                                    Consultar estado
                                                    <i-feather name="refresh-cw" class="icon--fe m-1" [ngClass]="{'fa-spin disable-click': order?.class_mobile}" style="width: 12px !important; height: auto;"></i-feather>
                                                </span>
                                                <span *ngIf="order?.error_update_mobile" class="ms-1">No se ha podido actualizar el estado</span>
                                            </span>
                                            <br *ngIf="order.mobiles && (order.mobiles.length > 1 || (order.mobiles.length > 0  && order.type === 'convergent'))">
                                            <br *ngIf="order.mobiles && (order.mobiles.length > 1 || (order.mobiles.length > 0  && order.type === 'convergent'))">

                                        </h5>
                                    </div>
                                    <div  class="col-lg-4 ps-0 d-flex justify-content-end">
                                        <span class="font-list text-list">
                                            <span class="{{ convertStatusClass(order.super_status) }}"></span>Estado: <b>{{ order.status_description }}</b>
                                            <i-feather *ngIf="showUpdateOrder(order)" (click)="updateStatus(order, typeUpdate.full)" name="refresh-cw" class="icon--fe m-1 cursor-pointer" style="width: 15px !important; height: auto; color: #000;" title="Pulse para actualizar el pedido"></i-feather>
                                        </span>
                                        <span *ngIf="order?.error_update_full" class="font-list text-list">
                                            <br>
                                            No se ha podido actualizar el estado
                                        </span>
                                        <span class="font-list text-list cursor-pointer" *ngIf="order && order.doc_status && order.doc_status !== ''" title="{{ order.doc_annotation && order.doc_annotation !== '' ? order.doc_annotation : '' }}">
                                            <br>
                                            <span class="{{ convertStatusDocClass(order.doc_status) }}"></span>Documentación{{ order?.sign_type && order?.sign_type !== 'not_required' && order?.sign_type !== 'old' ? ' (' + translateSignType[order.sign_type] + ')' : '' }}: <b>{{ order.doc_status }}</b>
                                        </span>
                                    </div>
                                    <div class="col-lg-12">
                                        <!-- Lista de moviles -->
                                        <span *ngFor="let mobile of order.mobiles; let i = index">
                                            <div *ngIf="i > 0 || (i == 0 && order.type === 'convergent')">
                                                <div class="font-list info-secundaria" *ngIf="mobile.rate">
                                                    <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                    {{ mobile.portability ? 'Portabilidad ' : 'Alta ' }} {{ (i + 1) + 'ª línea móvil' }} {{ mobile.contract_type && mobile.contract_type !== '' ? (mobile.contract_type === 'prepaid' ? 'prepago' : 'pospago') : '' }} {{mobile.is_esim == typeCard.esim ? 'eSIM' : ''}}:
                                                    <b>
                                                        <!-- {{ mobile.rate.data + ' + ' + mobile.rate.voice + (mobile.bonos && mobile.bonos.length ? ' + ' + mobile.bonos.length + ' bono(s)' : '') + ': ' + (getPriceMobile(mobile)|toFixedDecimals) + '€ - ' + (mobile.new_msisdn ? mobile.new_msisdn : 'Pendiente de asignar') + ' - ' + (mobile.terminal_oid ? mobile.terminal_oid?.terminal?.brand + ' ' + mobile.terminal_oid?.terminal?.model : 'Sin Terminal') }} -->
                                                        {{ mobile.rate.data + ' + ' + mobile.rate.voice + ': ' + (getPriceMobile(mobile)|toFixedDecimals) + '€ - ' + (mobile.new_msisdn ? mobile.new_msisdn : 'Pendiente de asignar') + ' - ' + (mobile.terminal_oid ? mobile.terminal_oid?.terminal?.brand + ' ' + mobile.terminal_oid?.terminal?.model : 'Sin Terminal') }}
                                                    </b>
                                                </div>
                                                <div class="font-list info-secundaria mb-2" *ngIf="mobile.status_description && mobile.status_description !== '' && mobile.status_description !== superStatus.draft && mobile.status_description !== superStatusTranslate.draft">
                                                    <img src="./assets/img/info.svg" class="img-fluid" width="13">
                                                    {{ 'Estado ' + (i + 1) + 'ª línea móvil: ' }}<b>{{ mobile.status_description }}</b>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="ob-{{ i }}" role="tabpanel" aria-labelledby="">
                            <div class="row">
                                <div class="col-xl-7">
                                    <mat-form-field class="form-list">
                                        <mat-label>Observaciones</mat-label>
                                        <textarea #observations id="observations-{{ i }}" matInput rows="3" oninvalid="" required [disabled]="savingAnnotation === order.order_id">{{ order.annotation }}</textarea>
                                    </mat-form-field>

                                    <button type="button" class="btn btn-black btn-sm mt-3" (click)="updateAnnotation(order, observations.value)">
                                        <span *ngIf="savingAnnotation === order.order_id" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        {{ savingAnnotation === order.order_id ? 'Guardando...' : 'Guardar observaciones' }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="in-{{ i }}" role="tabpanel" aria-labelledby="">
                            <div class="row">
                                <div class="col-xl-7">
                                    {{ order.doc_warning }}
                                </div>
                            </div>
                        </div>

                        <div class="btn-toolbar justify-content-end mb-3">
                            <div class="btn-group">
                                <a (click)="openModalNewProcess(order, true)" class="btn btn-simyo-o2 btn-sm me-3"
                                   *ngIf="!permSv.hasPermFromV2(allPermissions.simyo.tuvo_activaciones) && order.super_status !== superStatus.draft && !permSv.hasPermFromV2(allPermissions.simyo.nbss_block)">
                                    Nueva tramitación
                                </a>
                                <a (click)="openModalNewProcess(order, false)" class="btn btn-simyo-o2 btn-sm me-3"
                                   *ngIf="!permSv.hasPermFromV2(allPermissions.simyo.tuvo_activaciones) && (order.super_status === superStatus.cancelled || order.can_retry) && !permSv.hasPermFromV2(allPermissions.simyo.nbss_block)">
                                    Retramitación
                                </a>
                                <a [routerLink]="['/simyo/order/', order.order_id]" [state]="{data: order}" class="btn btn-simyo-o2 btn-sm">
                                    Ver más detalles
                                </a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="order?.update_full">
                        <h5  class="tar-name-preload text-center">
                            Actualizando pedido...
                        </h5>
                        <div class="d-flex justify-content-center">
                            <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="Cargando...">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Cargando -->
<div *ngIf="!loaded" class="mt-2 mb-5">
    <h5  class="tar-name-preload text-center">
        Obteniendo ordenes...
    </h5>
    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="Cargando...">
    </div>
    <br>
</div>

<!-- Error -->
<div *ngIf="errorSearch" class="mt-5">
    <h5  class="tar-name-preload text-center">
        Se ha producido un error, inténtalo de nuevo
    </h5>
</div>

<!-- No hay pedidos -->
<div *ngIf="loaded && ordersFiltered.length == 0 && !errorSearch" class="mt-5">
    <h5  class="tar-name-preload text-center">
        No se han encontrado ordenes.
    </h5>
</div>

<!-- Cargar más pedidos  -->
<div class="mt-4 d-flex justify-content-center" *ngIf="body.page < lastPage && loaded && !errorSearch">
    <button class="btn btn-black-outline d-block w-100 mb-1" (click)="loadMore()">
        Cargar más
    </button>
    <br>
</div>
