import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.css']
})
export class CustomSelectComponent implements OnChanges {

    @Input() id: string;
    @Input() label: string = '';
    @Input() isRequired: boolean = false;
    @Input() control: FormControl;
    @Input() controlName: string;
    @Input() options = []
    @Input() optionValue = 'value'
    @Input() optionLabel = 'value'
    @Input() isDisabled: boolean = false;

    @Input() customeMessageError: string;
    @Input() placeholder: string;

    @Output() OnChange = new EventEmitter();

    isFocused = false;
    isEmpty = true;
    listOptionsFormated = [];

    constructor() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes?.options?.currentValue) {
            console.log(changes.options);
            this.listOptionsFormated = changes.options.currentValue.map( o => {
                return {
                    value: o[this.optionValue],
                    label: o[this.optionLabel]
                }
            });
            console.log(this.listOptionsFormated);
        }

        if(changes?.control?.isFirstChange) {
            this.control.valueChanges.subscribe(data => {
                this.isEmpty = data == '';
            })
        }

        if(changes.isDisabled) {
            if(changes.isDisabled.currentValue) {
                this.control?.disable();
            } else {
                this.control?.enable();
            }
        }
    }
}
