import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFixedDecimals'
})
export class ToFixedDecimalsPipe implements PipeTransform {

  transform(value: any): string {
       let price = parseFloat(value);
        
        if (price.toString()?.split('.')[1] && price.toString().split('.')[1].length >= 10) {
            price += 0.0001;
        }

        const priceFixedStr = price.toFixed(3);
        const lastDecimal = priceFixedStr[priceFixedStr.length - 1];

        if (lastDecimal === '0') {
            return ((price * 100)  / 100).toFixed(2).replace('.',','); //2 DECIMALS
        }
        
        return priceFixedStr.replace('.',',');
  }

}
