import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-hm-promo',
    templateUrl: './hm-promo.component.html',
    styleUrls: ['./hm-promo.component.css', '../../../../assets/css/home-theme.css']
})
export class HmPromoComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
