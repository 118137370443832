import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SimyoService } from '../../services/simyo.service';
import { orderType, documentationTypes } from 'src/app/shared/constantes';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { SimyoConfirmationService } from '../../services/simyo-confirmation.service';
import { Order } from '../../classes/order';
import { SimyoGlobals, descuento } from '../../classes/simyo-globals';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimyoShoppingCartService } from '../../services/simyo-shopping-cart.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../../shared/components/alert-dialog/alert-dialog.component';
import { Subscription } from 'rxjs';
import { SimyoRateService } from '../../services/simyo-rate.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { IstateDocumentationEE, signatureDocuments } from '../../models/signatureDocuments';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { getUnique } from 'src/app/utils/getUniqueFromArray';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import {downloadFile} from 'src/app/utils/downloadFile';
import { HttpErrorResponse } from '@angular/common/http';
import { SimyoDocumentationService } from '../../services/simyo-documentation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmComponent } from '../../modals/confirm/confirm.component';
import { take } from 'rxjs/operators';
import { DigoService } from 'src/app/services/digo.service';
import { SearchPdvComponent } from '../../modals/search-pdv/search-pdv.component';
import { allPermissions } from 'src/app/shared/permissions';


@Component({
    selector: 'app-simyo-confirmation',
    templateUrl: './simyo-confirmation.component.html',
    styleUrls: ['./simyo-confirmation.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoConfirmationComponent implements OnInit, OnChanges, OnDestroy {
    @Input() movilPackForms: FormGroup;
    @Input() clienteFormGroup: FormGroup;
    @Input() fibraFormGroup: FormGroup;
    @Input() pdv: Ipdv;
    @Input() stepper: MatStepper;
    @Input() orderCreateIsFinished: boolean;
    @Input() signatureFormGroup: FormGroup;
    @Output() onActivationRequest = new EventEmitter<boolean>();
    @Output() onActivationLoader = new EventEmitter<any>();
    @ViewChild('infoPreviaActivar') infoPreviaActivar: NgbModalRef;

    public order: Order;
    public dataOrderToSignature: signatureDocuments;
    public typeOrder: string;
    public rateFtth: any = null;
    public ratesMobiles = [];
    public isLoading = false;
    public activationError: any;
    public date = new Date();
    public startGetStatusTime = 0;
    public documentationMode: { [key: string]: string } = documentationTypes;
    public allPermissions = allPermissions;
    public showMsgValidateDocumentation: boolean = false;
    public showActivateButton: boolean = false;
    private enableActivateButton: boolean = false;
    private allSubscription: Subscription[] = [];
    public customerData: any;
    public precontract: boolean = false;
    public hash: string = "";
    public loadingPrecontract: boolean = false;
    public loadingDownload: boolean = false;
    public errorDownload: boolean = false;
    public sendEmailTries: number = 3;
    public downloaded: boolean = false;
    public sended: boolean = false;
    public modalShowed: boolean = false;
    public stepperAqui: boolean = false;
    public rateMaster: any;
    private firstTimeScrollBottom = true;
    public showMsgInstalationVirtual: boolean = false;
    public instalationVirtualFormGroup: FormGroup;
    public dataIV;

    constructor(
        private simyoService: SimyoService,
        private orderService: SimyoOrderService,
        private documentService: SimyoDocumentationService,
        private confirmationService: SimyoConfirmationService,
        private modalService: NgbModal,
        private rateService: SimyoRateService,
        public matDialog: MatDialog,
        private shoppingCart: SimyoShoppingCartService,
        public simyoGlobals: SimyoGlobals,
        public permSv: PermissionService,
        private formBuilder: FormBuilder,
        public digoService: DigoService
    ) {
        this.instalationVirtualFormGroup = this.formBuilder.group({
            instalationVirtualCheck: [null, Validators.required]
        });
    }

    ngOnInit(): void {

        this.clienteFormGroup.get('email').valueChanges.subscribe(()=>{this.resetPrecontract()});
        this.fibraFormGroup.get('rate').valueChanges.subscribe(()=>{this.resetPrecontract()});
        this.movilPackForms.valueChanges.subscribe((configurationFormValue) => {
            if (this.simyoService.getChangeMobilePack() === true) {
                this.simyoService.setChangeMobilePack(false);
                this.resetPrecontract();
            }
            else{
                this.simyoService.setChangeMobilePack(true);
            }

        });

        this.allSubscription.push(this.simyoService.getTypeOrder()
            .subscribe((data: any) => {
                this.typeOrder = data;
            })
        );

        this.allSubscription.push(this.rateService.getRates()
            .subscribe((data: any) => {
                if (data != null) {
                    this.rateMaster = data;
                }
            })
        );


        this.allSubscription.push(this.simyoService.getRateFtth()
            .subscribe((data: any) => {
                this.rateFtth = data;
            })
        );

        this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data: any) => {
                if (data != null) {
                    this.ratesMobiles = data;
                }
            })
        );

        this.allSubscription.push(this.orderService.getOrder()
            .subscribe((data: any) => {
                this.order = data;
            })
        );

        this.allSubscription.push(this.simyoService.getCustomerPromotion()
            .subscribe((data) => {
                this.customerData = data;
            })
        );

        this.stepper.selectionChange
            .subscribe((res: StepperSelectionEvent) => {
                if (this.stepper.steps.last === res.selectedStep) {
                    this.stepperAqui = true;
                    let allPhonesToContact: any[] = [{phone: this.order.customer.phone_number, type: 'Contacto'}];
                    this.order.mobiles.map((mobile, index) => mobile.msisdn !== '' ? allPhonesToContact.push({phone: mobile.msisdn, type: index+1 + 'ª línea'}) : null);
                    this.dataOrderToSignature = {
                        orderId: this.order.order_id,
                        email: this.order.customer.email,
                        phones: getUnique(allPhonesToContact, 'phone'),
                        orderInProcess: this.order,
                        isLastStep: true
                    }
                    if (!this.modalShowed && !this.sended && !this.downloaded && this.sendEmailTries === 0) {
                        this.openModal('No es posible enviar el correo al cliente. Imprime ahora el documento y entrégaselo al cliente. Recuerda que es obligatorio que el cliente disponga de este documento.');
                    }
                    this.scrollToBottom(700);
                }
                else{
                    this.stepperAqui = false;
                }
                if (this.dataOrderToSignature && this.stepper.steps.last !== res.selectedStep) this.dataOrderToSignature.isLastStep = false;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.orderCreateIsFinished?.currentValue) {
            this.showMsgValidateDocumentation = false;
            if(this.stepperAqui) {
                this.scrollToBottom(0);
            }
        }
    }

    activateService(precontract: boolean) {
        if(this.digoService.isOpSimyoUser() && precontract === false) {
            //mostrar modal de username y enviar la firma de documentos vacia
            const options: NgbModalOptions = {
                backdrop: 'static',
                size: 'lg',
                centered: true,
                windowClass: 'modalAlertChange'
            };

            let modalRef = this.modalService.open(SearchPdvComponent, options);
            modalRef.componentInstance.onCreateOrder.subscribe((pdv) => {
                this.orderService.setSupplantAccount(pdv.id_pdv, pdv.id_usuario_principal, pdv.username_principal);
                this.activate(precontract);
            });
            return;
        }
        this.showMsgValidateDocumentation = false;
        if (this.permSv.hasPermFromV2(allPermissions.others.users_demo)) {
            this.openModal('Usuario demostración. No se puede finalizar el alta');
            return;
        }
        if (this.isDisabled() && precontract === false) {
            if (!this.enableActivateButton && this.permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature)) {
                this.showMsgValidateDocumentation = true;
            }
            return;
        }

        if(this.simyoService.getCanVirtualInstallation() && !precontract) {
            let instalationVirtualCheck = this.instalationVirtualFormGroup.value.instalationVirtualCheck;
            if(instalationVirtualCheck) {
                const options: NgbModalOptions = {
                    backdrop: 'static',
                    size: 'lg',
                    centered: true,
                    windowClass: 'modalAlertChange'
                };

                let modalWarningRef = this.modalService.open(ConfirmComponent, options);
                modalWarningRef.componentInstance.text = `Vas a realizar la activación con instalación virtual, más rápida y sin necesidad de visita de un técnico. Ten en cuenta que esto supondrá <b>la baja del fijo ${this.dataIV.phoneNumber}</b> que tu cliente tiene en <b>${this.dataIV.brand}</b>.`;
                modalWarningRef.componentInstance.title = '¡Advertencia!';
                modalWarningRef.componentInstance.onConfirm.pipe(take(1)).subscribe((result) => {
                    this.simyoService.postConfirmVirtualInstallation(result).pipe(take(1)).subscribe();
                    this.orderService.setBroadbandIV(result === 'SI');
                    this.activate(precontract);
                });
            } else {
                this.simyoService.postConfirmVirtualInstallation('NO').pipe(take(1)).subscribe();
                this.orderService.setBroadbandIV(false);
                this.activate(precontract);
            }
            return;
        }
        this.activate(precontract);
    }

    retryCreate(precontract: boolean) {
        if (this.permSv.hasPermFromV2(allPermissions.others.users_demo)) {
            this.openModal('Usuario demostración. No se puede finalizar el alta');
            return;
        }
        if (this.isDisabled() && !precontract) {
            if (!this.enableActivateButton && this.permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature)) {
                this.showMsgValidateDocumentation = true;
            }
            return;
        }
        this.activate(precontract);
    }

    activate(precontract: boolean) {

        if (precontract) {
            this.documentService.createPreOrder(this.order.order_id, this.order.only_send_email)
            .subscribe((response: any) => {
                if (response?.error?.errorCode === 's107') {
                    this.sendEmailTries--;
                    this.sended = false;
                    this.hash = response?.error?.errorCode;
                    if (this.sendEmailTries > 0) {
                        this.order.only_send_email = true;
                        this.activate(true);
                    }else{// se ha intentado ya 2 veces y no se ha enviado el mail
                        if (!this.downloaded && !this.errorDownload && !this.modalShowed && this.stepperAqui) { //Si no ha intentado descargar aun
                            this.openModal('No es posible enviar el correo al cliente. Imprime ahora el documento y entrégaselo al cliente. Recuerda que es obligatorio que el cliente disponga de este documento.');
                        }
                    }
                }else{
                    this.sendEmailTries = 3;
                    this.hash = response.hash;
                    this.sended = true;
                    this.order.only_send_email = false;
                    this.scrollToBottom(500);
                }
                this.loadingPrecontract = false;
            },
            error => {

                this.hash = '';
                this.order.only_send_email = true;
                this.sended = false;
                this.loadingPrecontract = false;
            });
            return;
        }


        let initialTime = new Date().getTime();
        this.activationError = null;
        this.onActivationRequest.emit(false);
        this.confirmationService.setHttpError(false);
        this.startGetStatusTime = initialTime;
        this.isLoading = true;
        this.orderService.createOrder(false)
        .subscribe((response: any) => {
            this.orderService.setSkipKYC(false)
        }, error => {
            console.error('[CREATE ORDER - ACTIVATION]', error);
            this.isLoading = false;
            this.activationError = error;
            this.onActivationRequest.emit(true);
            this.confirmationService.setHttpError(true);
        });


    }

    public preloadingComplete(order) {
        this.onActivationLoader.emit(order);
        this.confirmationService.setFinished(true);
    }

    goToStep(index) {
        this.shoppingCart.getStepper().selectedIndex = index;
    }

    removeExtraLine(index) {
        this.orderService.orderShowRemoveOrderDialog(index);
    }

    public showActivateButtonEvent(stateDocumentation: IstateDocumentationEE): void {
        this.showActivateButton = stateDocumentation.allUploadDocAreValid;
        this.enableActivateButton = stateDocumentation.formIsValid;
        if (stateDocumentation.allUploadDocAreValid) this.showMsgValidateDocumentation = false;
    }

    public isDisabled(): boolean {
        return !this.orderCreateIsFinished || (this.digoService.isOpSimyoUser() === false && !this.enableActivateButton && this.permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature));
    }

    public precontractSended(): void {
        this.precontract = true;
        this.loadingPrecontract = true;
        this.errorDownload = false;
        this.activateService(true);
    }

    public download(){
        if (this.sendEmailTries > 0 && !this.sended && !this.modalShowed) {
            this.openModal('Imprime ahora el documento y entregárselo al cliente. Recuerda que es obligatorio que el cliente disponga de este documento.');
        }
        this.loadingDownload = true;
        this.documentService.downloadPre(this.order.order_id)
            .subscribe(data => {
                if (data) {
                    var reader = new FileReader();
                    let self = this;
                    reader.onloadend = function() {
                        try {
                            const file = JSON.parse(reader.result as string);
                            if (file && file.error) {
                                self.errorDownload = true;
                            }
                        } catch (e) {
                            downloadFile(data, 'Resumen_de_contrato.pdf');
                        }
                    };
                    reader.readAsText(data);
                    this.downloaded = true;
                }
                this.loadingDownload = false;
            }, (error: HttpErrorResponse) => {
                this.loadingDownload = false;
                this.errorDownload = true;
                this.downloaded = false;
            }, () => {
            });
    }

    public resetPrecontract(){
        this.precontract = false;
        this.sendEmailTries = 3;
        this.order.only_send_email = false;
        this.downloaded = false;
        this.sended = false;
        this.modalShowed = false;
        //Reset error
        this.activationError = null;
    }

    public openModal(msg: string){
        this.modalShowed = true;
        this.matDialog.open(AlertDialogComponent, {
            data: {message: msg}
        });
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    scrollToBottom(time) {
        if(this.stepperAqui) {
            setTimeout(() => window.scrollTo({ top: document.body.scrollHeight, left: 0 , behavior: 'smooth' }), time);
        }
    }

    isShowActivateButton() {

        const show = (this.activationError == null
            && (this.digoService.isOpSimyoUser()
                || (this.orderCreateIsFinished && this.showActivateButton)
                || !this.permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature))
            && (this.downloaded || this.sended)
            && (this.simyoService.getCanVirtualInstallation() === false || (this.simyoService.getCanVirtualInstallation() && this.instalationVirtualFormGroup.valid)));
        if(show && this.firstTimeScrollBottom) {
            this.scrollToBottom(500);
            this.firstTimeScrollBottom = false;
        }
        return show;
    }

    public errorHandling = (control: string, error: string) => {
        if (this.instalationVirtualFormGroup.get(control).invalid && (this.instalationVirtualFormGroup.get(control).dirty || this.instalationVirtualFormGroup.get(control).touched)) {
            return this.instalationVirtualFormGroup.controls[control].hasError(error);
        }
    };

    public confirmIV(value) {
        this.instalationVirtualFormGroup.patchValue({
            instalationVirtualCheck: value
        });
    }

    isShowMsgInstalationVirtual() {
        this.showMsgInstalationVirtual = this.simyoService.getCanVirtualInstallation()
        && ((this.showActivateButton && this.enableActivateButton) || !this.permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature));

        if(this.showMsgInstalationVirtual) {
            this.dataIV = this.simyoService.getResponseVirtualInstallationData();
        }
        return this.showMsgInstalationVirtual;
    }
}
