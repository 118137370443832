import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { typeCard } from 'src/app/shared/constantes';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SimyoDuplicadosSimService {

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) { }

    createSimSwap(body) {
        const header = new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken()
        });
        return this.http.post(environment.simyoEndPoint + 'sim-swap/create', body, {headers: header});
    }

    getCustomerEmail(id) {
        return this.http.get(`${environment.simyoEndPoint}sim-swap/${id}/customer-email`, {headers: this.getHeaders()});
    }

    getDocumentation(id) {
        return this.http.get(`${environment.simyoEndPoint}sim-swap/${id}/documentation`, {headers: this.getHeaders()});
    }

    documentSign(body) {
        return this.http.post(environment.simyoEndPoint + 'sim-swap/documentation/sign', body, {headers: this.getHeaders()});
    }

    generateSimSwapContract(body) {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            Accept: 'application/pdf'
        });

        return this.http.post(environment.simyoEndPoint + 'sim-swap/contract/generate', body, {headers, responseType: 'blob'});
    }

    verifyMRZ(body) {
        return this.http.post(environment.simyoEndPoint + 'sim-swap/mrz/verify', body, {headers: this.getHeaders()});
    }

    orderSimSwap(id) {
        return this.http.get(environment.simyoEndPoint + 'sim-swap/' + id, {headers: this.getHeaders()}).pipe(
            map((data:any) => {
                if (data && data.msg) {
                    data.msg = {...data.msg, is_esim: data.msg.is_esim ? typeCard.esim : typeCard.sim}
                }
                return data
            })
        );
    }

    listSimSwap(body) {
        return this.http.post(environment.simyoEndPoint + 'sim-swap/list', body, {headers: this.getHeaders()}).pipe(
            map((data:any) => {
                if (data && data.msg && data.msg.data) {
                    let orders = data.msg.data.map(order =>{
                        return {...order, is_esim: order.is_esim ? typeCard.esim : typeCard.sim};
                    });
                    data.msg.data = orders;
                }
                return data;
            }));
    }

    uploadFileSimSwap(file, simSwapId) {
        const formData = new FormData();
        const token = 'Bearer ' + this.auth.getToken();
        const header = new HttpHeaders({
            Authorization: token
        });

        formData.append('document', file);
        return this.http.post(environment.simyoEndPoint + 'sim-swap/' + simSwapId + '/document/upload', formData, {headers: header});
    }

    getFileUploadSimSwap(simSwapId, assetId) {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            Accept: 'application/pdf'
        });

        return this.http.get(environment.simyoEndPoint + 'sim-swap/' + simSwapId + '/document/' + assetId + '/download', {headers, responseType: 'blob'});
    }

    private getHeaders() : HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            'Content-Type': 'application/json'
        });
    }
}
