import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { Mobile } from '../classes/mobile';
import { Order } from '../classes/order';
import { broadbandTypeDiscount, discountWithoutFtth, fibraSpeeds, mobileContractType, orderType, typeCard } from 'src/app/shared/constantes';
import { SimyoService } from './simyo.service';
import { DeliveryAddress } from '../classes/delivery-address';
import { fillZipCode, getNameStreet } from 'src/app/utils/normalizerFunctions';
import { Broadband } from '../classes/broadband';
import { Address } from '../classes/address';
import { masterAllSimyo, provinceSimyo, trackTypeSimyo } from '../models/masterAllSimyo';
import { simyoDocumentationUploadType } from '../models/signatureDocuments';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SimyoRateService } from './simyo-rate.service';
import { SimyoShoppingCartService } from './simyo-shopping-cart.service';
import { map, tap } from 'rxjs/operators';
import { ToFixedDecimalsPipe } from 'src/app/shared/pipes/to-fixed-decimals.pipe';
import { SimyoGlobals } from '../classes/simyo-globals';


@Injectable({
    providedIn: 'root'
})
export class SimyoOrderService {
    private order = new Order();
    private oldOrder: Order = null;
    private orderObservable: any;
    private typeOrder: any;
    private customerInfo : any;
    private firstTimeRemember: number = 1;
    private skipKYC: boolean = false;
    private customerDonorData = {};

    // Master
    private roadTypes: trackTypeSimyo[] = [];
    private provinces: provinceSimyo[] = [];

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private matDialog: MatDialog,
        private rateService: SimyoRateService,
        private shoppingCart: SimyoShoppingCartService,
        private simyoService: SimyoService,
        private simyoGlobals: SimyoGlobals
    ) {
        this.orderObservable = new BehaviorSubject<Order>(this.order);

        this.simyoService.getMasterAll()
            .subscribe((data: masterAllSimyo) => {
                if (data) {
                    this.roadTypes = data.track_types;
                    this.provinces = data.provinces;
                    // Controlamos que desde el home si se hace la consulta de cobertura obtengamos la provincia correspondiente
                    if (this.order.customer.installation_address && this.order.customer.installation_address.province_id && this.order.customer.installation_address.province_id !== '' && this.order.customer.installation_address.post_code && this.order.customer.installation_address.post_code !== '') {
                        this.order.customer.installation_address.province_id = this.getIdProvinces(this.order.customer.installation_address.province_id, fillZipCode(this.order.customer.installation_address.post_code));
                    }
                }
            });

        this.simyoService.getTypeOrder()
            .subscribe((data: any) => {
                if (data != null) {
                    this.typeOrder = data;
                }
            });

        this.simyoService.getCustomerPromotion()
            .subscribe((data) => {
                this.customerInfo = data;
            });
    }

    public createOrder(draft: boolean = true, isLastStep?: boolean, resetDocuments?: boolean): Observable<any> {
                //Tramitación solo el hash
        this.order.draft = draft;
        // Create user es true si tenemos firma digital y es el ultimo paso.
        // Aprovechamos esto para revisar si se ha cambiado algo del flujo para resetear o no el contrato
        if (isLastStep) {
            this.order.create_user = isLastStep;
            if (this.oldOrder) {
                this.oldOrder.sign_type = this.order.sign_type;
                this.order.reset_documents = JSON.stringify(this.order) !== JSON.stringify(this.oldOrder);
                this.oldOrder = JSON.parse(JSON.stringify(this.order));
            }
            if (!this.oldOrder) {
                this.oldOrder = JSON.parse(JSON.stringify(this.order));
            }
        }

        if (!isLastStep) {
            this.order.reset_documents = false;
            if (this.oldOrder) {
                this.oldOrder.reset_documents = false;
            }
        }

        if (resetDocuments) {
            this.order.reset_documents = true;
        }

        this.order.tax_type = this.simyoService.getTaxType();
        this.order.tax_type_cp = this.simyoService.getZipCode();

        this.order.total_initial = this.order.total_initial.replace(/,/g, '.');
        this.order.total_monthly = this.order.total_monthly.replace(/,/g, '.');

        this.updatePriceForRealPrice();
        let sendOrder = {...this.order};
        if(sendOrder.mobiles && sendOrder.mobiles.length > 0) {
            sendOrder.mobiles = sendOrder.mobiles.map(mobile => {
                return {...mobile,
                    is_esim: mobile.is_esim === typeCard.esim
                }
            })
        }
        if(this.skipKYC){
            sendOrder['skip_kyc_sign'] = true;
        }

        return this.http.post(environment.simyoEndPoint + 'order/create', sendOrder, {headers: this.getHeaders()});
    }

    public getOrderFromService(orderId: number | string): Observable<any> {
        return this.http.get<any>(environment.simyoEndPoint + 'order/' + orderId, {headers: this.getHeaders()}).pipe(
            map(data => {
                if(data && data.msg && data.msg.length) {
                    let order = data.msg[0];

                        if(order.additional_info) {
                            order = {
                                ...order,
                                ...order.additional_info
                            };
                        } else {
                            let priceBroadband = data.msg[0].broadband ? data.msg[0].broadband.rate.rate_info.contracted_price : null;
                            let price = this.simyoGlobals.getTotalPrice(priceBroadband, data.msg[0].mobiles);

                            order = {
                                ...order,
                                total_initial:  price.totalPriceInicial,
                                total_monthly: price.totalMensualPrice
                           }
                        }

                        if(order.broadband) {
                            let broadband = null;
                            if(order.broadband.additional_info) {
                                broadband = {
                                    ...order.broadband,
                                    ...order.broadband.additional_info
                                }
                            } else {
                                let rateInfo = order.broadband.rate.rate_info;
                                broadband = {
                                    ...order.broadband,
                                    discount: rateInfo.already_client ? +discountWithoutFtth[order.tax_type] : null,
                                    type_discount: rateInfo.already_client ? broadbandTypeDiscount.firstPackage : null,
                                    total_without_discount: +rateInfo.contracted_price + ( rateInfo.already_client ? +discountWithoutFtth[order.tax_type] : 0)
                                }
                            }
                            order.broadband = broadband;
                        }

                        if(order.mobiles.length) {
                            let mobiles = order.mobiles.map(mobile => {
                                if(mobile.additional_info) {
                                    return {
                                        ...mobile,
                                        ...mobile.additional_info,
                                        is_esim: mobile.is_esim ? typeCard.esim : typeCard.sim
                                    }
                                } else {
                                    let rateInfo = mobile.rate.rate_info;
                                    let total_without_discount = rateInfo[`old_price_${order.tax_type}`] !== '' ? rateInfo[`old_price_${order.tax_type}`] : rateInfo[`price_${order.tax_type}`]
                                    let terminalPrice = mobile.terminal_oid && mobile.terminal_oid.is_installment ? mobile.terminal_oid.terminal.installment : 0;
                                    return {
                                        ...mobile,
                                        is_main_line: false,
                                        discount: total_without_discount - rateInfo.contracted_price,
                                        total_without_discount,
                                        total_line: +rateInfo.contracted_price + terminalPrice,
                                        recharge: mobile.contract_type === mobileContractType.prepaid ? this.rateService.getRechargePrice(rateInfo.contracted_price) : null,
                                        is_esim: mobile.is_esim ? typeCard.esim : typeCard.sim
                                    }
                                }

                            });
                            order.mobiles = mobiles
                        }

                        order.customer = {
                            birth_date: order.customer_birth_date,
                            id: order.customer_id,
                            company_name: order.customer_company_name,
                            document: order.customer_document,
                            document_type_id: order.customer_document_type_id,
                            email: order.customer_email,
                            full_name: order.customer_full_name,
                            name: order.customer_name,
                            last_name: order.customer_last_name,
                            second_last_name: order.customer_second_last_name,
                            phone_number: order.customer_phone_number
                        }

                        data.msg[0] = order;
                }

                return data;
            })
        );
    }

    public getSummaryData(orderId: number | string): Observable<any> {
        return this.http.post<any>(environment.simyoEndPoint + 'order/' + orderId + '/summary-data', null, {headers: this.getHeaders()});
    }

    public updatePriceForRealPrice(){
        this.order.mobiles.forEach(mobile => {
            let rateInfo = this.simyoGlobals.findByKeyInMobile(mobile, 'rate_info');
            mobile.price = rateInfo?.real_price;
        });
    }

    private getHeaders(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            'Content-Type': 'application/json'
        });
    }

    setAgreements(data) {
        this.order.agreements.allow_data_navigation = data.check1;
        this.order.agreements.receive_third_communications = data.check2;
        this.order.agreements.give_data_orange_group = data.check3;
        this.order.agreements.allow_create_commercial_profile = data.check4;
        this.order.agreements.allow_commercial_communications = data.check5;
        this.setOrder(this.order);
    }

    public setSignType(signType: simyoDocumentationUploadType) {
        this.order.sign_type = signType;
        this.setOrder(this.order);
    }

    setOrder(data) {
        this.orderObservable.next(data);
    }

    getOrder() {
        return this.orderObservable.asObservable();
    }

    setOrderId(orderId: any) {
        this.order.order_id = orderId;
        this.setOrder(this.order);
    }

    setBroadbandRate(rate: any) {
        this.order.broadband.rate_id = rate.rate_id;
        this.order.broadband.price = rate.rate_info?.price;

        this.order.broadband.discount = rate.front_discount;
        this.order.broadband.total_without_discount = rate.front_total_without_discount;
        this.order.broadband.type_discount = rate.front_type_discount; //null|first_package|additional_package
        this.order.broadband.name = rate.name;

        this.setOrder(this.order);
    }

    setBroadbandCoverage(data: any) {
        // Installation Address
        this.order.customer.installation_address.province_id = this.getIdProvinces(data.provinciaCobertura, fillZipCode(data.codigoPostalCobertura));
        // this.order.customer.installation_address.province_id = data.provinciaCobertura;
        this.order.customer.installation_address.city = data.localidadCobertura;
        this.order.customer.installation_address.post_code = fillZipCode(data.codigoPostalCobertura);
        this.order.customer.installation_address.street_type_id = this.getIdStreetType(data.tipoViaCobertura);
        this.order.customer.installation_address.street_name = data.nombreViaCobertura;
        this.order.customer.installation_address.street_name2 = '';
        this.order.customer.installation_address.street_number = data.numeroCobertura;
        this.order.customer.installation_address.staircase_number = data.escaleraCobertura;
        this.order.customer.installation_address.floor_number = data.plantaCobertura;
        this.order.customer.installation_address.apartment_number = data.puertaCobertura;
        this.order.customer.installation_address.block = data.bloqueCobertura && data.bloqueCobertura !== '' ? data.bloqueCobertura : '';

        this.order.broadband.gescal = data.resultadoConsultaCobertura ? data.resultadoConsultaCobertura.cimaGescalCode : '';
        this.order.broadband.access_type = data.resultadoConsultaCobertura?.technology && data.resultadoConsultaCobertura?.technology.length ? data.resultadoConsultaCobertura.technology[0] : '';
        this.order.broadband.commercial_deal = data.resultadoConsultaCobertura?.commercial_deal;
        this.setOrder(this.order);
    }

    setMobiles(array: any[]) {
        this.order.mobiles = [];

        array.forEach((item: any, index: number) => {
            const mobile = new Mobile();
            if (item.type_pay && item.type_pay !== '' && item.rate?.rate_ids) {
                mobile.rate_id = item.type_pay === mobileContractType.prepaid ? item.rate.rate_ids.prepaid : item.rate.rate_ids.postpaid;
            }
            mobile.price = item.rate?.rate_info?.real_price;
            mobile.contract_type = item.type_pay;
            mobile.principal = false; //index === 0; //TODO CALCULAR CUAL ES LA MAXIMA PARA PONERLA DE PRINCIPAL Y QUE NO DEPENDA DEL INDEX
            mobile.new_iccid = item.iccid;
            mobile.msisdn = item.portability_phone;
            mobile.old_iccid = item.portability_iccid;
            mobile.old_contract_type = item.portability_type_client;
            if (item.terminal && item.terminal !== '' && item.terminal.terminal_oids && item.terminal.terminal_oids.length) {
                const found = item.terminal.terminal_oids.find(x => {
                    let modelPay, modelPortability, modelTypePay;

                    modelPay = item.type_pay === mobileContractType.prepaid ? x.pay_type === mobileContractType.prepaid : x.pay_type === mobileContractType.postpaid;
                    modelPortability = item.type_operation === 'registration' ? x.portability === false : x.portability === true;
                    modelTypePay = item.terminal_payment_type === 'unico' ? x.is_installment === false : x.is_installment === true;

                    return modelPay && modelPortability && modelTypePay;
                })
                if (found) {
                    mobile.terminal_oid_id = found.terminal_oid_id;
                }
            }
            // if (item.rate && item.rate.bonos && item.rate.bonos.length) {
            //     mobile.bonos = [];
            //     item.rate.bonos.forEach(element => {
            //         mobile.bonos.push(item.type_pay === mobileContractType.prepaid ? element.rate_ids.prepaid : element.rate_ids.postpaid);
            //     });
            // }
            mobile.service_provider_id = item.portability_operator ? item.portability_operator.toString() : item.portability_operator;
            mobile.processing_date = item.portability_date !== '' ? moment(item.portability_date).format('YYYY-MM-DD') : '';
            mobile.is_esim = item.is_esim;

            if (item.type_operation === 'portability_change') {
                mobile.customer_donor = {};
                mobile.change_owner = true;
            }

            //additional-info
            mobile.is_main_line = item.rate.is_main_line;
            mobile.discount = item.rate.discount;
            mobile.total_without_discount = item.rate.total_without_discount;
            mobile.total_line = item.rate.total_line;
            mobile.recharge = item.rate.recharge;

            this.order.mobiles.push(mobile);
        });

        this.setOrder(this.order);
    }

    getCustomerDonorData(): any{
        return this.customerDonorData;
    }

    setUserData(data: any) {
        this.order.customer.name = data.firstName;
        this.order.customer.last_name = data.surname1;
        this.order.customer.second_last_name = data.surname2;
        this.order.customer.phone_number = data.phone;
        this.order.customer.email = data.email;
        this.order.customer.document_type_id = data.doctype;
        this.order.customer.document = data.doc ? data.doc.replace(/-/g, '') : data.doc;
        this.order.customer.nationality_id = data.country?.country_id ?? data.country;
        let birthday = null;
        if (data.birthday) {
            birthday = moment(data.birthday).format('YYYY-MM-DD');
        }
        this.order.customer.birth_date = birthday;
        this.order.customer.gender_id = data.sex;
        this.order.customer.company_name = data.company;

        // Payment
        this.order.customer.payment.bill_cycle_type = data.bill_cicle_types;
        this.order.customer.payment.account_type = data.account_types;
        this.order.customer.payment.acct_code = data.acct_code;
        this.order.customer.payment.iban = data.bank_account ? data.bank_account.replace(/\s/g, '') : data.bank_account;
        this.order.customer.payment.document_type_id = data.account_doctype;
        this.order.customer.payment.document = data.account_doc ? data.account_doc.replace(/-/g, '') : data.account_doc;
        this.order.customer.payment.name = data.account_firstName;
        this.order.customer.payment.last_name = data.account_surname1;
        this.order.customer.payment.second_last_name = data.account_surname2;

        // Installation Address
        // Solo fibra o convergente
        if (this.order.broadband && this.order.broadband.gescal && this.order.broadband.gescal !== '') {
            this.order.customer.installation_address.country_id = data.country;
        }

        if (this.typeOrder === orderType.MOBILE) {
            this.order.broadband = undefined;
            this.order.customer.installation_address = undefined;
        }

        if (this.typeOrder !== orderType.MOBILE && (this.order.broadband === undefined || this.order.customer.installation_address === undefined)) {
            this.order.broadband = new Broadband();
            this.order.customer.installation_address = new Address();
        }

        // Postal Address
        // Todos los flujos
        this.order.customer.postal_address.country_id = data.country;
        this.order.customer.postal_address.province_id = this.getIdProvinces(data.provincia, fillZipCode(data.codigoPostal));
        this.order.customer.postal_address.city = data.localidad;
        this.order.customer.postal_address.post_code = fillZipCode(data.codigoPostal);
        this.order.customer.postal_address.street_type_id = this.getIdStreetType(data.tipoVia);
        this.order.customer.postal_address.street_name = getNameStreet(data.nombreVia, this.roadTypes, 'value');
        this.order.customer.postal_address.street_name2 = '';
        this.order.customer.postal_address.street_number = data.numero;
        this.order.customer.postal_address.staircase_number = data.escalera;
        this.order.customer.postal_address.block = data.bloque;
        this.order.customer.postal_address.floor_number = data.planta;
        this.order.customer.postal_address.apartment_number = data.puerta;

        // Delivery address
        // Solo movil o convergente y si hay terminales
        this.order.customer.is_delivery = data.is_custom_delivery === '' ? false : data.is_custom_delivery;
        if (this.order.customer.is_delivery) {
            this.order.customer.delivery_address = new DeliveryAddress();
            if (data.address && data.address !== '') {
                // Usamos los datos del formulario de método de entraga
                this.order.customer.delivery_address.apartment_number = data.door;
                this.order.customer.delivery_address.city = data.city;
                this.order.customer.delivery_address.country_id = data.country;
                this.order.customer.delivery_address.floor_number = data.floor;
                this.order.customer.delivery_address.name = data.firstName;
                this.order.customer.delivery_address.phone_number = data.phone;
                this.order.customer.delivery_address.post_code = fillZipCode(data.zip);
                this.order.customer.delivery_address.province_id = this.getIdProvinces(data.province, fillZipCode(data.zip));
                this.order.customer.delivery_address.staircase_number = data.stairway;
                this.order.customer.delivery_address.street_name = getNameStreet(data.address, this.roadTypes, 'value');
                this.order.customer.delivery_address.street_number = data.number;
                this.order.customer.delivery_address.street_type_id = this.getIdStreetType(data.road_type);
            } else {
                // Usamos los datos de contacto
                this.order.customer.delivery_address.apartment_number = data.puerta;
                this.order.customer.delivery_address.city = data.localidad;
                this.order.customer.delivery_address.country_id = data.country;
                this.order.customer.delivery_address.floor_number = data.planta;
                this.order.customer.delivery_address.name = data.firstName;
                this.order.customer.delivery_address.phone_number = data.phone;
                this.order.customer.delivery_address.post_code = fillZipCode(data.codigoPostal);
                this.order.customer.delivery_address.province_id = this.getIdProvinces(data.provincia, fillZipCode(data.codigoPostal));
                this.order.customer.delivery_address.staircase_number = data.escalera;
                this.order.customer.delivery_address.block = data.bloque;
                this.order.customer.delivery_address.street_name = getNameStreet(data.nombreVia, this.roadTypes, 'value');
                this.order.customer.delivery_address.street_number = data.numero;
                this.order.customer.delivery_address.street_type_id = this.getIdStreetType(data.tipoVia);
            }
        } else {
            this.order.customer.delivery_address = undefined;
            this.order.customer.is_delivery = false;
        }


          this.customerDonorData = {
            document_type_id: data.portability_origen_doc_type,
            document_number: data.portability_origen_doc,
            name: data.portability_origen_name.trim(),
            lastname: data.portability_origen_lastname.trim() +' '+ data.portability_origen_lastname2.trim(),
            contact_phone: data.portability_origen_phone,
            birth_date: data.portability_origen_birth_date ? moment(data.portability_origen_birth_date).format('YYYY-MM-DD') : '',
            email: data.portability_origen_email,
            nationality_id: data.portability_origen_nationality_id
            };

          const mobilesWithPortabilityChange = this.order.mobiles.reduce((mobilesDonorArray, mobile, index) => {
            if (mobile.hasOwnProperty('customer_donor')) {
                mobilesDonorArray.push(index);
            }
            return mobilesDonorArray;
          }, [] as number[]);

          //Si alguna linea es portabilidad con cambio de titular se meterá los datos customer_donor en cada mobile, si no se vaciará la info
          if(mobilesWithPortabilityChange.length == 0){
            this.customerDonorData = {};
          } else{
            mobilesWithPortabilityChange.forEach(index => {
                this.order.mobiles[index].customer_donor = this.customerDonorData;
              });
          }


        this.setOrder(this.order);
    }

    setCustomerId(data) {
        this.order.customer_id = data ? data.customer_id : undefined;
        this.setOrder(this.order);
    }

    setCustomerAcctCode(acctCode: string | undefined) {
        this.order.customer.payment.acct_code = acctCode;
        this.setOrder(this.order);
    }

    removeOrder(index) {
        this.order.mobiles.splice(index, 1);
        this.setOrder(this.order);
    }

    getIdStreetType(value): string {
        if (this.roadTypes && this.roadTypes.length && value) {
            if (typeof value === 'number') {
                value = value.toString();
            }
            if (value.toLowerCase() === 'passatge') {
                value = 'pasaje';
            }
            const found = this.roadTypes.find(x => x.code.toLowerCase() === value.toLowerCase() || x.value.toLowerCase() === value.toLowerCase());
            if (found) {
                return found.track_type_id.toString();
            }
        }
        return value;
    }

    getIdProvinces(value, zip) : string {
        if (this.provinces && this.provinces.length && value && zip) {
            const found = this.provinces.find(province => province.code === zip.substring(0, 2));
            if (found) {
                return found.province_id.toString();
            }
        }
        return value;
    }

    getHasPackaged(i){
        if (!this.order) {
            return false;
        }
        var hasPackaged = false;
        var exit = false;
        this.order.mobiles.forEach((order, index) => {
            if (order.contract_type === 'postpaid' && +order.price >= 7 && !exit) {
                //Si el que ha llamado a la funcion es el que está paquetizando, o va antes del que está paquetizando, y está en proceso de cambio, será como si no estuviera paquetizando
                if (i <= index && this.simyoService.getIsChanging()) {
                    hasPackaged = false;
                    exit = true;
                }
                else{
                    hasPackaged = true;

                }
            }
        });

        return hasPackaged;
    }

    public paquetiza(index: number) {
        //if(this.simyoService.getShowFtthReduced() === false) return false;
        let indexCurrentMainLine = this.simyoService.getIndexMainLine();
        //No tenemos linea principal dentro de las lineas del pedido
        if(indexCurrentMainLine === -1) {
            return this.simyoService.getMainLine() != null;
        }
        return indexCurrentMainLine === index;
    }

    public multiplePostpaids(prepaidToPostpaid = false) {
        return this.simyoService.getTotalMobileLines() > 1;
    }

    public orderShowChangePackagedOrderDialog(index : number, prepaidToPostpaid = false): MatDialogRef<ConfirmDialogComponent> {
        //search other main line if not remove
        const dataCustom = {
            title: 'Cambiar ' + (index + 1) + 'ª línea móvil',
            message: '¿Está seguro que desea cambiar la ' + (index + 1) + 'ª línea móvil? <br> <span class="text-red">Si cambias esta línea se eliminarán el resto de líneas pospago del pedido</span>'
        }

        return this.removeChangedOrderDialog(index + 1, dataCustom);
    }

    public orderShowRemoveOrderDialog(index : number) {
        //Agregamos comprobacion si hay segunda linea, si no hubiese y cumple las dos condiciones anteriores se eliminan todas las lineas pospago
        if (this.paquetiza(index) && this.multiplePostpaids() && this.calculateMaxPrice(true) == 0) {
            const dataCustom = {
                title: 'Eliminar ' + (index + 1) + 'ª línea móvil',
                message: '¿Está seguro que desea eliminar la ' + (index + 1) + 'ª línea móvil? <br> <span class="text-red">Si eliminas esta línea se eliminarán el resto de líneas pospago del pedido</span>'
            };
            this.removeOrderDialog(index, dataCustom);
        } else{
            this.removeOrderDialog(index);
        }
    }

    public removeOrderDialog(index : number, dataCustom?: any) {
        const dataDefault = {
            title: 'Eliminar ' + (index + 1) + 'ª línea móvil',
            message: '¿Está seguro que desea eliminar la ' + (index + 1) + 'ª línea móvil?'
        }

        this.matDialog
            .open(ConfirmDialogComponent, {
                data: dataCustom ? dataCustom : dataDefault,
                panelClass: 'simyo'
            })
            .afterClosed()
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    if (dataCustom) {
                        this.shoppingCart.getStepper().selectedIndex = 0;
                        const interval2 = setInterval(() => {
                            clearInterval(interval2);
                            this.removeAllPostpaidsFromIndex(0);
                        }, 500);
                    } else {
                        let newStepper = this.shoppingCart.getStepper().selectedIndex - 1;
                        this.shoppingCart.getStepper().selectedIndex = newStepper < 0 ? 0 : newStepper;
                        const interval1 = setInterval(() => {
                            clearInterval(interval1);
                            this.removeOneFromIndex(index);
                        }, 500);
                    }
                }
            });
    }

    removeChangedOrderDialog(index : number, dataCustom?: any): MatDialogRef<ConfirmDialogComponent> {
        const dataDefault = {
            title: 'Eliminar ' + (index + 1) + 'ª línea móvil',
            message: '¿Está seguro que desea eliminar la ' + (index + 1) + 'ª línea móvil?'
        }

        return this.matDialog
            .open(ConfirmDialogComponent, {
                data: dataCustom ? dataCustom : dataDefault,
                panelClass: 'simyo'
            });
    }

    public orderShowChangePackagedOrderDialog2(index : number): MatDialogRef<ConfirmDialogComponent> {
        const dataCustom = {
            title: 'Cambiar ' + (index + 1) + 'ª línea móvil',
            message: '¿Está seguro que desea cambiar la ' + (index + 1) + 'ª línea móvil? <br> <span class="text-red">Si seleccionas esta velocidad de fibra se eliminarán las líneas móviles pospago del pedido.</span>'
        }

        return this.removeChangedOrderDialog(index + 1, dataCustom);

    }

    removeAllPostpaidsFromIndex(index: number){
        for (let i = this.order.mobiles.length - 1; i >= index; i--) {
            if (this.order.mobiles[i].contract_type === 'postpaid') {
                setTimeout(() => {
                    this.removeOneFromIndex(i);
                }, 100)
            }
        }
    }

    removeAllPostpaidsExceptIndex(index: number){
        for (let i = this.order.mobiles.length - 1; i >= 0; i--) {
            if (this.order.mobiles[i].contract_type === 'postpaid' && index !== i) {
                setTimeout(() => {
                    this.removeOneFromIndex(i);
                }, 100)
            }
        }
    }

    removeOneFromIndex(index: number) {
        //Si el eliminado es el que paquetiza el precio de la fibra vuelve a normal.
        this.simyoService.setIndexToRemove(index);
        this.simyoService.removeLine(index);
        //this.removeOrder(index);

        if (this.typeOrder === orderType.CONVERGENT && this.simyoService.getMainLine() == null) {//&& this.paquetiza(index)) {
            this.updateBroadbandprice();
        }
    }

    get_existing_client():boolean{
        return this.order?.existing_client;
    }

    set_existing_client(exist: boolean): void{
        this.order.existing_client = exist;
    }

    getFirsTimeRemember(){
        if (this.firstTimeRemember) {
            this.firstTimeRemember--;
            return 1;
        }
        return this.firstTimeRemember;
    }

    updateBroadbandprice(){
        var rate = this.simyoService.getRateFtth();
        this.simyoService.getRateFtth()
            .subscribe((data: any) => {
                rate = data;
            })
        rate.rate_info.price = rate.type_normal.price;
        rate.rate_id = rate.type_normal.rate_id;
        this.simyoService.setRateFtth(rate);
        this.setBroadbandRate(rate);
    }

    resetOrder() {
        this.order = new Order();
        this.oldOrder = undefined;
    }

    setBroadbandIV(virtual: boolean) {
        this.order.broadband.virtual = virtual;
        this.setOrder(this.order);
    }

    setSupplantAccount(pdv_id: string, digo_user_id: string, username: string) {
        this.order.pdv_id = pdv_id;
        this.order.digo_user_id = digo_user_id;
        this.order.username = username;
        this.order.is_supplant = true;

    }

    setSkipKYC(value: boolean){
        this.skipKYC = value;
    }

    /*
    * Funcion para saber el precio que tiene la linea principal, puede ser la que tuviese o una nueva.
    */
    calculateMaxPrice(getSecondMaxPrice = false) {
        let mobileMaxPrice = this.simyoService.getMobileMaxPriceCurrentOrder();
        let currentMaxMobile = this.simyoService.getMaxMobileCustomer();

        if(getSecondMaxPrice) {
            return this.getMaxPriceBetweenRate(mobileMaxPrice.beforeMobile, currentMaxMobile)
        }

        return this.getMaxPriceBetweenRate(mobileMaxPrice, currentMaxMobile);
    }

    private getMaxPriceBetweenRate(rateGt, rateLt) {
        if ( rateGt == null && rateGt?.mobile == null && rateLt == null ) {
            return 0;
        }

        let movil = rateGt?.mobile ?? rateGt;
        if (rateLt == null) {
            let mobileRateInfo = movil?.rate_info;
            return mobileRateInfo ? (mobileRateInfo.old_price != '' ? +mobileRateInfo.old_price : +mobileRateInfo.price) : 0;
        }

        if (rateGt == null || movil == null) {
            return +rateLt.price;
        }

        let mobileRateInfo = movil?.rate_info;
        let rateGtPrice = mobileRateInfo ? (mobileRateInfo.old_price != '' ? +mobileRateInfo.old_price : +mobileRateInfo.price) : 0;
        if (+rateGtPrice > rateLt.price) {
            return rateGtPrice;
        }

        return +rateLt.price;
    }

    setTotalPrice(price) {
        const toFixedDecimalsPipe = new ToFixedDecimalsPipe();
        this.order.total_initial = toFixedDecimalsPipe.transform(price.totalPriceInicial);
        this.order.total_monthly = toFixedDecimalsPipe.transform(price.totalMensualPrice);

    }
}
