import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BonosAdicionalesOrderResponse } from 'src/app/orange/models/bonosAdicionalesInfoResponse';
import { MasterAllResponse } from 'src/app/orange/models/masterAllResponse';
import { AppState } from 'src/app/orange/redux';
import { resetState, setMasterAll, setOrderInfo } from 'src/app/orange/redux/orange.actions';
import { IOrangeAdditionalBonosState } from 'src/app/orange/redux/orange.state';
import { OrangeBonosHiringService } from 'src/app/orange/services/orange-bonos-hiring.service';
import { OrangeService } from 'src/app/orange/services/orange.service';

@Component({
    selector: 'app-orange-bonos-hiring-detail',
    templateUrl: './orange-bonos-hiring-detail.component.html',
    styleUrls: ['./orange-bonos-hiring-detail.component.css', '../../../../../assets/css/orange-theme.css']
})
export class OrangeBonosHiringDetailComponent implements OnInit, OnDestroy {

    public orange$: Observable<IOrangeAdditionalBonosState>;
    public loaded: boolean = false;
    public orderNotFound: boolean = false;

    constructor(
        private orangeBonosHiringService: OrangeBonosHiringService,
        private activatedRoute: ActivatedRoute,
        private orangeStore: Store<AppState>,
        private orangeMasterService: OrangeService
    ) {
        this.orange$ = orangeStore.pipe(select('orangeAdditionalBonos'));
    }

    ngOnInit(): void {
        this.orangeMasterService.getMasterAllFromService(false)
            .subscribe((data: MasterAllResponse) => {
                if (data.msg) {
                    this.orangeStore.dispatch(setMasterAll({master: data.msg}));
                    this.getOrderInfo();
                }
            });
    }

    getOrderInfo() {
        this.orangeBonosHiringService.getOrderBonosPrepago(this.activatedRoute.snapshot.paramMap.get('order_bono_id'))
            .subscribe((data: BonosAdicionalesOrderResponse) => {
                if (data && data.msg) {
                    this.orangeStore.dispatch(setOrderInfo({order: data.msg}));
                };
            }, (error: HttpErrorResponse) => {
                this.orderNotFound = true;
            }, () => {
                this.orderNotFound = false;
                this.loaded = true;
            });
    }

    ngOnDestroy() {
        this.orangeStore.dispatch(resetState());
    }

}
