import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { brands, maxDate, minDate, priceSwapSim, stateIccid, typeCard } from 'src/app/shared/constantes';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { SimyoDuplicadosSimService } from 'src/app/simyo/services/simyo-duplicados-sim.service';
import { SimyoService } from 'src/app/simyo/services/simyo.service';
import { cifValidator, dniValidator, getMaxLengthByDoc, nieValidator } from 'src/app/utils/validators/spanish-id-validator';
import { setTotal } from '../../redux/simyo.actions';
import {MatDialog} from '@angular/material/dialog';
import {AlertDialogComponent} from '../../../../shared/components/alert-dialog/alert-dialog.component';
import { allPermissions } from 'src/app/shared/permissions';
import { iccidValidator } from 'src/app/utils/validators/iccid-validator';

@Component({
    selector: 'app-simyo-ds-configuration',
    templateUrl: './simyo-ds-configuration.component.html',
    styleUrls: ['./simyo-ds-configuration.component.css', '../../../../../assets/css/simyo-theme.css']
})
export class SimyoDsConfigurationComponent implements OnInit, OnDestroy {
    @ViewChild('docContainer') docContainer: ElementRef;
    @Input() formGroup: FormGroup;
    @Input() master: any;
    @Input() stepper: MatStepper;
    @Input() pdv: Ipdv;

    private maxLengthMRZ = {
        dniOrNie: 18,
        pasaporte: 28
    };

    public maxLengthByDoc: number = 9;
    public maxLengthByDocMrz: number = this.maxLengthMRZ.dniOrNie;
    public maxDate: Date = maxDate;
    public minDate: Date = minDate;
    public showErrorUploadFile: boolean = false;
    public uploadingFile: boolean = false;
    public iccidLoading: boolean = false;
    public simyo$: Observable<number>;
    public showDocSection: boolean = false;
    public isLoadingDraft: boolean = false;
    public isAllowToContinue: boolean = false;
    public allPermissions = allPermissions;

    public cardType = [
        {text: 'SIM Física', is_esim: typeCard.sim},
        {text: 'eSIM', is_esim: typeCard.esim}
    ];
    public classColumn = 'col-lg-3';
    public typeCard = typeCard;
    constructor(
        private matDialog: MatDialog,
        private modalService: NgbModal,
        public permSv: PermissionService,
        private simyoService: SimyoService,
        private simyoSimSwapService: SimyoDuplicadosSimService,
        private store: Store<{ simyo: number }>
    ) {
        this.simyo$ = store.pipe(select('simyo'));
        this.classColumn = this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_esim) ? 'col-lg-3' : 'col-lg-4';
    }

    ngOnInit(): void {
    }

    isValidMrz() {
        if (
            this.formGroup.get('reason').valid &&
            this.formGroup.get('iccid').valid &&
            this.formGroup.get('msisdn').valid &&
            this.formGroup.get('documentType').valid &&
            this.formGroup.get('documentNumber').valid &&
            this.formGroup.get('nationality').valid &&
            this.formGroup.get('gender').valid &&
            this.formGroup.get('name').valid &&
            this.formGroup.get('lastName1').valid &&
            this.formGroup.get('birthday').valid
        ) {
            return true;
        }
        return false;
    }

    openModal(content) {
        this.modalService.open(content, {size: 'md', centered: true});
    }

    isValidIccid(name) {
        if (this.formGroup.get(name).valid) {
            this.iccidLoading = true;
            this.formGroup.get(name).disable();
            this.simyoService.iccidAvailable(this.formGroup.get(name).value)
                .subscribe((data: any) => {
                    this.iccidLoading = false;
                    this.formGroup.get(name).enable();
                    if (data && data.msg !== null) {
                        if (data.msg === stateIccid.used) {
                            this.formGroup.controls[name].setErrors({ used: true });
                        }
                        if (data.msg === stateIccid.invalid) {
                            this.formGroup.controls[name].setErrors({ invalid: true });
                        }
                        if (data.msg === stateIccid.valid) {
                            this.formGroup.controls[name].setErrors(null);
                        }
                    }
                }, () => {
                    this.iccidLoading = false;
                    this.formGroup.get(name).enable();
                });
        }
    }

    changeTypeDocument(documentTypeId): void {
        const docValidators: ValidatorFn[] = [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')];

        if (typeof documentTypeId === 'number') {
            documentTypeId = documentTypeId.toString();
        }

        switch (documentTypeId) {
            case '2':
                this.formGroup.get('nationality').patchValue('192');
                docValidators.push(dniValidator());
                break;
            case '1':
                this.formGroup.get('nationality').patchValue(null);
                docValidators.push(nieValidator());
                break;
            case '4':
                docValidators.push(cifValidator());
                break;
            default:
                this.formGroup.get('nationality').patchValue(null);
        }

        this.formGroup.get('documentNumber').setValidators(docValidators);
        this.formGroup.get('documentNumber').updateValueAndValidity();
    }

    setMaxLengthByDoc(type) {
        this.maxLengthByDoc = getMaxLengthByDoc(brands.simyo, type);
        if (type && typeof type === 'number') {
            type = type.toString();
        }
        switch (type) {
            case '1':
            case '2':
                this.maxLengthByDocMrz = this.maxLengthMRZ.dniOrNie;
                break;
            case '3':
                this.maxLengthByDocMrz = this.maxLengthMRZ.pasaporte;
                break;
        }
    }

    disableForm() {
        this.formGroup.get('reason').disable();
        this.formGroup.get('iccid').disable();
        this.formGroup.get('msisdn').disable();
        this.formGroup.get('name').disable();
        this.formGroup.get('lastName1').disable();
        this.formGroup.get('lastName2').disable();
        this.formGroup.get('documentType').disable();
        this.formGroup.get('documentNumber').disable();
        this.formGroup.get('birthday').disable();
        this.formGroup.get('gender').disable();
        this.formGroup.get('nationality').disable();
        this.formGroup.get('is_esim').disable();
    }

    enableForm() {
        this.formGroup.get('reason').enable();
        this.formGroup.get('iccid').enable();
        this.formGroup.get('msisdn').enable();
        this.formGroup.get('name').enable();
        this.formGroup.get('lastName1').enable();
        this.formGroup.get('lastName2').enable();
        this.formGroup.get('documentType').enable();
        this.formGroup.get('documentNumber').enable();
        this.formGroup.get('birthday').enable();
        this.formGroup.get('gender').enable();
        this.formGroup.get('nationality').enable();
        this.formGroup.get('is_esim').enable();
    }

    validatePersonalData() {
        if (this.formGroup.get('sim_swap_id').valid) {
            this.stepper.next();
            return;
        }

        if (this.isValidPersonalDataForms()) {
            this.isLoadingDraft = true;
            this.disableForm();

            const body = {
                draft: true,
                reason_id: this.formGroup.get('reason').value,
                new_iccid: this.formGroup.get('iccid').value,
                msisdn: this.formGroup.get('msisdn').value,
                first_name: this.formGroup.get('name').value,
                last_name: this.formGroup.get('lastName1').value,
                second_last_name: this.formGroup.get('lastName2').value,
                document_type_id: this.formGroup.get('documentType').value,
                document_number: this.formGroup.get('documentNumber').value,
                birth_date: this.formGroup.get('birthday').value && this.formGroup.get('birthday').value !== '' ? this.formGroup.get('birthday').value.format('YYYY-MM-DD') : null,
                gender_id: this.formGroup.get('gender').value,
                nationality_id: this.formGroup.get('nationality').value,
                is_esim: this.formGroup.get('is_esim').value == typeCard.esim,
            }

            this.simyoSimSwapService.createSimSwap(body)
                .subscribe((data: any) => {
                    if (data && data.msg) {
                        this.isAllowToContinue = true;
                        this.formGroup.get('sim_swap_id').patchValue(data.msg.sim_swap_id);
                        this.getCustomerEmail();
                    }
                    if (data && data.error) {
                        this.isLoadingDraft = false;
                        if (data.error.msg) {
                            this.matDialog.open(AlertDialogComponent, {
                                data: {message: data.error.msg},
                                width: "450px"
                            });
                        }
    
                        this.enableForm();
                    }
                }, (error) => {
                    this.isLoadingDraft = false;

                    if (error?.error?.msg === 'Banned') {
                        this.matDialog.open(AlertDialogComponent, {
                            data: {
                                className: "text-center",
                                message: "Upss, ha superado el número máximo de intentos para hacer un duplicado.<br>Contacta con el 1644."
                            }
                        });
                    }

                    if (error?.error?.error?.msg) {
                        this.matDialog.open(AlertDialogComponent, {
                            data: {message: error.error.error.msg},
                            width: "450px"
                        });
                    }

                    this.enableForm();
                }, () => {
                    
                });
        }
    }

    getCustomerEmail() {
        this.simyoSimSwapService.getCustomerEmail(this.formGroup.get('sim_swap_id').value)
            .subscribe((data: any) => {
                if (data && data.msg) {
                    this.formGroup.get('email').patchValue(data.msg);
                }
            }, (error) => {
                console.error(error)
            }, () => {
                this.isLoadingDraft = false;
                this.simyoService.setSwapSim(this.formGroup.getRawValue());
                this.store.dispatch(setTotal({total: priceSwapSim}));
                this.stepper.next();
            })
    }

    isValidPersonalDataForms(): boolean {
        if (
            this.formGroup.get('reason').valid &&
            this.formGroup.get('iccid').valid &&
            this.formGroup.get('msisdn').valid &&
            this.formGroup.get('name').valid &&
            this.formGroup.get('lastName1').valid &&
            this.formGroup.get('lastName2').valid &&
            this.formGroup.get('documentType').valid &&
            this.formGroup.get('documentNumber').valid &&
            this.formGroup.get('birthday').valid &&
            this.formGroup.get('gender').valid &&
            this.formGroup.get('nationality').valid
        ) {
            return true;
        } else {
            this.formGroup.get('reason').markAllAsTouched();
            this.formGroup.get('iccid').markAllAsTouched();
            this.formGroup.get('msisdn').markAllAsTouched();
            this.formGroup.get('name').markAllAsTouched();
            this.formGroup.get('lastName1').markAllAsTouched();
            this.formGroup.get('lastName2').markAllAsTouched();
            this.formGroup.get('documentType').markAllAsTouched();
            this.formGroup.get('documentNumber').markAllAsTouched();
            this.formGroup.get('birthday').markAllAsTouched();
            this.formGroup.get('gender').markAllAsTouched();
            this.formGroup.get('nationality').markAllAsTouched();
            this.formGroup.get('reason').updateValueAndValidity();
            this.formGroup.get('iccid').updateValueAndValidity();
            this.formGroup.get('msisdn').updateValueAndValidity();
            this.formGroup.get('name').updateValueAndValidity();
            this.formGroup.get('lastName1').updateValueAndValidity();
            this.formGroup.get('lastName2').updateValueAndValidity();
            this.formGroup.get('documentType').updateValueAndValidity();
            this.formGroup.get('documentNumber').updateValueAndValidity();
            this.formGroup.get('birthday').updateValueAndValidity();
            this.formGroup.get('mrz').updateValueAndValidity();
            this.formGroup.get('gender').updateValueAndValidity();
            this.formGroup.get('nationality').updateValueAndValidity();
            this.formGroup.get('expirationDate').updateValueAndValidity();
            const elements: any = document.getElementsByClassName('mat-input-element ng-invalid');
            if (elements.length > 0) {
                elements[0].focus();
            }
            return false;
        }
    }

    ngOnDestroy() {
        if(this.formGroup !== undefined) this.formGroup.reset();
    }

    public numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;

        return !(charCode > 31 && (charCode < 48 || charCode > 57));
    }

    alphanumericOnly(event) {
        let pattern: RegExp = /^[a-zA-Z0-9]+$/;
        const inputChar = event.key;
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    changeTypeCard(isEsim) {
        if(isEsim) {
            this.formGroup.get('iccid').patchValue(null);
            this.formGroup.get('iccid').clearValidators();
        } else {
            this.formGroup.get('iccid').patchValue('');
            this.formGroup.get('iccid').setValidators([Validators.required, iccidValidator()]);
        }

        this.formGroup.get('iccid').updateValueAndValidity();
    }
}
