<div id="shopping-cart" class="sidenav" *ngIf="!swapSim">
    <div class="sidebar-header">
        <a class="closebtn" (click)="closeShoppingCart()">
            <img src="./assets/img/times.svg" class="img-fluid icon-close" width="17">
        </a>
        <h1 class="mb-2 text-center" >
            <b>Resumen</b>
        </h1>
    </div>

    <!-- PRODUCTO CONTRATADO -->
    <!------------------------->
    <div *ngIf="order && order.broadband">
        <div class="resumen d-flex justify-content-between align-items-center mt-2">
            <h6 class="resumen-color">
                <b>Fibra</b>
            </h6>
            <small class="text-muted trash-carrito">
                <!-- editar producto -->
                <a *ngIf="!isOrderFinished" (click)="goToStep(0)">
                    <img src="./assets/img/edit.svg" class="img-fluid icon-nav-sidebar" width="15">
                </a>
            </small>
        </div>

        <!-- INFO GENERAL -->
        <!-- dirección normalización -->
        <h6 class="info-nav-sidebar">
            <span class="sidebar-info">
                <img src="./assets/img/map-pin2.svg" class="img-fluid" width="13">
                Dirección de instalación
                <br>
                <b>{{ getAddress() }}</b>
            </span>
        </h6>
        <!-- CONCEPTOS -->
        <ng-container *ngIf="order.broadband.name && order.broadband.price">
            <div class="resumen d-flex justify-content-between align-items-center">
                <!-- tarifa -->
                <span class="sidebar-info5">
                    <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                    {{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Fibra IND ' : 'Fibra ') + order.broadband.name }} {{ (order.broadband.discount > 0 && order.broadband.type_discount == broadbandTypeDiscount.additionalPackage )? '*' : '' }}
                </span>
                <small class="text-muted">
                    <!-- Precio tarifa -->
                    <span class="sidebar-info3">
                        <b>{{ (+order?.broadband?.total_without_discount)|toFixedDecimals }} €/mes</b>
                    </span>
                </small>
            </div>

            <div class="resumen d-flex justify-content-between align-items-center mt-2" *ngIf="order.broadband.discount > 0">
                <span class="sidebar-info5">
                    {{ order.broadband.type_discount == broadbandTypeDiscount.firstPackage
                    ? '*Descuento por fibra y móvil pospago'
                    : '*Descuento por línea móvil existente'
                    }}
                </span>
                <small class="text-muted">
                    <!-- Precio tarifa -->
                    <span class="sidebar-info3">
                        <b>-{{ (+order.broadband.discount)|toFixedDecimals }} €/mes</b>
                    </span>
                </small>
            </div>
            <br>

            <div class="resumen d-flex justify-content-between align-items-center mt-2">
                <span class="sidebar-info">
                    <b>Total</b>
                </span>
                <small class="text-muted trash-carrito">
                    <!-- Precio tarifa -->
                    <span class="sidebar-info2">
                        <b>{{ (+order.broadband.price)|toFixedDecimals }} €/mes</b>
                    </span>
                </small>
            </div>
        </ng-container>
    </div>
    <!-- ./PRODUCTO CONTRATADO -->
    <!--------------------------->
    <ng-container *ngFor="let rateMobile of order.mobiles; index as i">
        <div *ngIf="rateMobile.rate">
            <br>
            <!-- PRODUCTO CONTRATADO -->
            <!------------------------->
            <div class="resumen d-flex justify-content-between align-items-center mt-2">
                <h6 class="resumen-color">
                    <b>{{ (i + 1) + 'ª línea móvil' }} <small *ngIf="rateMobile.is_main_line">(principal)</small></b>
                    <i-feather *ngIf="rateMobile.is_main_line" class="icon-main-line ms-2" width="18" name="award"></i-feather>
                </h6>
                <span class="d-flex justify-content-end align-items-center">
                    <small class="text-muted trash-carrito">
                        <!-- editar producto -->
                        <a *ngIf="!isOrderFinished" (click)="goToStep(i + (order.broadband ? 1 : 0))">
                            <img src="./assets/img/edit.svg" class="img-fluid icon-nav-sidebar" width="15">
                        </a>
                    </small>
                    <small class="text-muted trash-carrito" *ngIf="i > 0">
                        <!-- eliminar producto -->
                        <a (click)="removeExtraLine(i)" class="ps-2">
                            <img src="./assets/img/trash.svg" class="img-fluid icon-nav-sidebar" width="15">
                        </a>
                    </small>
                </span>
            </div>

            <!-- INFO GENERAL -->
            <!-- teléfono a portar -->
            <h6 class="info-nav-sidebar" *ngIf="rateMobile">
                <span class="sidebar-info">
                    <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                    {{ getTitleLine(rateMobile) }}
                </span>
            </h6>

            <!-- CONCEPTOS -->
            <div class="resumen d-flex justify-content-between align-items-center">
                <!-- tarifa -->
                <span class="sidebar-info5">
                    <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                    {{ rateMobile?.rate?.data }} + {{ rateMobile?.rate?.voice }} {{ rateMobile?.rate?.type_pay && rateMobile?.rate?.type_pay !== '' ? rateMobile?.rate?.type_pay === 'postpaid' ? '(Pospago)' : '(Prepago)' : '' }}{{ ( i === getIndexFirstPostpaidMobile() && (order.broadband?.discount > 0 || typeOrder === orderType.MOBILE)) ? '*' : '' }}
                </span>
                <small class="text-muted">
                    <!-- Precio tarifa -->
                    <span class="sidebar-info3" *ngIf="rateMobile.total_without_discount">
                        <b>{{ (+rateMobile.total_without_discount)|toFixedDecimals }} €/mes</b>
                    </span>
                    <!--<span class="sidebar-info3" *ngIf="!rateMobile.rate?.is_additional || rateMobile?.rate?.rate_info?.old_price === ''">
                        <b>{{ (+rateMobile.rate?.rate_info?.real_price)|toFixedDecimals }} €/mes</b>
                    </span>
                    <span class="sidebar-info3" *ngIf="rateMobile.rate?.is_additional && rateMobile?.rate?.rate_info?.old_price !== ''">
                        <b>{{ (+rateMobile.rate?.rate_info?.old_price)|toFixedDecimals }} €/mes</b>
                    </span>-->
                </small>
            </div>
            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="typeOrder === orderType.MOBILE && i === getIndexFirstPostpaidMobile()">
                <span class="sidebar-info3">
                    *Al contratar esta tarifa tendrás un descuento de {{ (discountWithoutFtth())|toFixedDecimals }}€ en tu tarifa estándar de fibra si aún no lo tienes.
                </span>
            </div>

            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="rateMobile.rate?.is_additional && rateMobile.rate?.rate_info?.old_price !== ''">
                <span class="sidebar-info5">
                    Descuento por línea adicional
                </span>
                <small class="text-muted">
                    <!-- Precio tarifa -->
                    <span class="sidebar-info3">
                        <b>-{{ (+rateMobile?.discount)|toFixedDecimals }} €/mes</b>
                    </span>
                </small>
            </div>

            <!-- Bonos
            <ng-container *ngIf="rateMobile.bonos && rateMobile.bonos.length">
                <div class="resumen d-flex justify-content-between align-items-center" *ngFor="let bono of rateMobile.bonos">
                    <!- Bono ->
                    <span class="sidebar-info5">
                        <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                        {{ bono.rate_info.description }}
                    </span>
                    <small class="text-muted">
                        <!- Precio bono ->
                        <span class="sidebar-info3">
                            <b>{{ (+bono.rate_info?.price)|toFixedDecimals }} €/mes</b>
                        </span>
                    </small>
                </div>
            </ng-container>-->

            <!-- Terminal -->
            <ng-container *ngIf="rateMobile.rate?.terminal as terminal">
                <div class="resumen d-flex justify-content-between align-items-center">
                    <!-- terminal -->
                    <span class="sidebar-info5">
                        <img src="./assets/img/smartphone2.svg" class="img-fluid" width="13">
                        {{ terminal?.brand }} {{ terminal?.model }}
                        <span *ngIf="terminal.paymentType === 'VAP'">
                            <br>
                            ({{ terminal.dues }} meses)
                        </span>
                    </span>
                    <small class="text-muted">
                        <!-- Precio terminal -->
                        <span class="sidebar-info3">
                            <b *ngIf="terminal.paymentType === 'unico'">
                                Pago único
                            </b>
                            <b *ngIf="terminal.paymentType === 'VAP'">
                                {{ (+terminal?.installment)|toFixedDecimals }} €/mes
                            </b>
                        </span>
                    </small>
                </div>
            </ng-container>
            <br>

            <div class="resumen d-flex justify-content-between align-items-center mt-2">
                <span class="sidebar-info"><b>Total</b></span>
                <small class="text-muted trash-carrito">
                    <!-- Precio tarifa -->
                    <span class="sidebar-info2">
                        <b>{{ (simyoGlobals.priceMobileMonth(rateMobile.rate))|toFixedDecimals }} €/mes</b>
                    </span>
                </small>
            </div>
            <!-- ./PRODUCTO CONTRATADO -->
            <!--------------------------->
        </div>
    </ng-container>

    <ng-template [ngIf]="checkPriceGt0(order.total_initial) || checkPriceGt0(order.total_monthly)" [ngIfElse]="emptyShoppingCart">
        <div>
            <!-- Importe Total -->
            <div class="resumen-total total d-flex justify-content-between align-items-center">
                <h5 class="total-color"><b>Pago mensual</b></h5>
                <h5 class="text-auto"><b>{{ order.total_monthly }} €/mes</b></h5>
            </div>
        </div>
        <div *ngIf="checkPriceGt0(order.total_initial)">
            <br>
            <hr class="line-a">
            <br>
            <!-- Terminal -->
            <ng-container *ngIf="order.mobiles.length">
                <div class="resumen d-flex justify-content-between align-items-center" *ngFor="let rateMobile of order.mobiles">
                    <ng-container *ngIf="rateMobile.rate?.terminal as terminal">
                        <!-- Terminal -->
                        <span class="sidebar-info5">
                            <img src="./assets/img/smartphone2.svg" class="img-fluid" width="13">
                            {{ terminal?.brand }} {{ terminal?.model }} {{ terminal?.paymentType === 'unico' ? '(Pago único)' : '' }}
                        </span>
                        <small class="text-muted">
                            <!-- Precio terminal -->
                            <span class="sidebar-info3" *ngIf="terminal?.paymentType === 'VAP'">
                                <b>{{ (+terminal?.upfront)|toFixedDecimals }} €</b>
                            </span>
                            <span class="sidebar-info3" *ngIf="terminal?.paymentType === 'unico'">
                                <b>{{ +terminal?.price }} €</b>
                            </span>
                        </small>
                    </ng-container>
                </div>
                <br>
            </ng-container>

            <!-- Importe Total -->
            <div class="resumen-total total d-flex justify-content-between align-items-center">
                <h5 class="total-color">
                    <b>Pago inicial</b>
                </h5>
                <h5 class="text-auto">
                    <b>{{ order.total_initial }} €</b>
                </h5>
            </div>
        </div>
        <div class="resumen-total total d-flex justify-content-between align-items-center">
            <span class="total-color">
                <b><span>{{getTaxType()}} </span>incluido</b>
            </span>
        </div>


    </ng-template>
    <ng-template #emptyShoppingCart>
        <div class="container">
            <p class="py-4">Todavía no se han añadido servicios al pedido.</p>
        </div>
    </ng-template>
</div>

<div id="shopping-cart" class="sidenav" *ngIf="swapSim">
    <div class="sidebar-header">
        <a class="closebtn" (click)="closeShoppingCart()">
            <img src="./assets/img/times.svg" class="img-fluid icon-close" width="17">
        </a>
        <h1 class="mb-2 text-center" >
            <b>Resumen</b>
        </h1>
    </div>
    <div>
        <div class="resumen d-flex justify-content-between align-items-center mt-2">
            <h6 class="resumen-color">
                <b>Duplicados</b>
            </h6>
            <small class="text-muted trash-carrito">
            </small>
        </div>
        <!-- INFO GENERAL -->
        <!-- Nombre del cliente -->
        <div class="resumen d-flex justify-content-between align-items-center">
            <span class="sidebar-info5">
                <img src="./assets/img/file.svg" class="img-fluid" width="13">
                Nombre del cliente: <b>{{ swapSim.name + ' ' + swapSim.lastName1 + ' ' + swapSim.lastName2 }}</b>
            </span>
        </div>

        <!-- Documento de identidad -->
        <div class="resumen d-flex justify-content-between align-items-center">
            <span class="sidebar-info5">
                <img src="./assets/img/file.svg" class="img-fluid" width="13">
                Documento de identidad: <b>{{ getDocumentType(swapSim.documentType) + ': ' + swapSim.documentNumber }}</b>
            </span>
        </div>

        <!-- Nº de Simyo -->
        <div class="resumen d-flex justify-content-between align-items-center">
            <span class="sidebar-info5">
                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                Nº de Simyo: <b>{{ swapSim.msisdn }}</b>
            </span>
        </div>

        <!-- Tipo de tarjeta -->
        <div class="resumen d-flex justify-content-between align-items-center">
            <span class="sidebar-info5">
                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                Tipo de tarjeta: <b>{{ swapSim.is_esim == typeCard.esim ? 'eSIM' : 'SIM física' }}</b>
            </span>
        </div>

        <!-- ICCID nuevo -->
        <div class="resumen d-flex justify-content-between align-items-center" *ngIf="swapSim.is_esim == typeCard.sim">
            <span class="sidebar-info5">
                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                ICCID nuevo: <b>{{ swapSim.iccid }}</b>
            </span>
        </div>

        <!-- Motivo del cambio -->
        <div class="resumen d-flex justify-content-between align-items-center">
            <span class="sidebar-info5">
                <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                Motivo del cambio: <b>{{ getReasonLabel(swapSim.reason) }}</b>
            </span>
        </div>

        <!-- Fecha de solicitud -->
        <div class="resumen d-flex justify-content-between align-items-center">
            <span class="sidebar-info5">
                <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                Fecha de solicitud: <b>{{ today | date: 'dd/MM/yyyy, HH:mm' }}</b>
            </span>
        </div>

        <ng-container *ngIf="priceSwapSim > 0">
            <br>
            <div class="resumen d-flex justify-content-between align-items-center mt-2">
                <span class="sidebar-info">
                    <b>Total</b>
                </span>
                <small class="text-muted trash-carrito">
                    <!-- Precio tarifa -->
                    <span class="sidebar-info2">
                        <b>{{ (priceSwapSim)|toFixedDecimals }} €</b>
                    </span>
                </small>
            </div>
            <!-- Importe Total -->

            <!-- ./PRODUCTO CONTRATADO -->
            <!--------------------------->
            <hr class="line-a">

            <!-- Importe Total -->
            <div class="resumen-total total d-flex justify-content-between align-items-center">
                <h5 class="total-color">
                    <b>pago total</b>
                </h5>
                <h5 class="text-auto">
                    <b>{{ (priceSwapSim)|toFixedDecimals }} €</b>
                </h5>
            </div>
            <!-- Importe Total -->
            <div class="resumen d-flex justify-content-between align-items-center mb-5">
                <span class="sidebar-info">
                    <b><span>Impuestos indirectos incluidos</span></b>
                </span>
            </div>
        </ng-container>
    </div>
</div>
