import { Component, HostListener, OnInit, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HomeService, INavigateFromOperation } from 'src/app/home/services/home.service';
import { AppState } from '../../redux';
import { showShoppingCart } from '../../redux/orange.actions';
import { IOrangeAdditionalBonosState, IOrangePrepaidState } from '../../redux/orange.state';
import {OrangeShoppingCartService} from '../../services/orange-shopping-cart.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';

@Component({
    selector: 'app-orange-subheader',
    templateUrl: './orange-subheader.component.html',
    styleUrls: ['./orange-subheader.component.css', '../../../../assets/css/orange-theme.css']
})
export class OrangeSubheaderComponent implements OnInit {
    @ViewChildren('navbar') navbar;
    @ViewChildren('filling') filling;

    public href : string;
    public orangeAdditionalBonos$: Observable<IOrangeAdditionalBonosState>;
    public orangePrepaid$: Observable<IOrangePrepaidState>;
    public dataOperationPosVenta: any = null;
    public dataOperation: any = null;
    private allSubscription: Subscription[] = [];
    public hiddeShoppingCart = false;
    public allPermissions = allPermissions;

    constructor(
        private router: Router,
        private orangeStore: Store<AppState>,
        private homeService: HomeService,
        private shoppingCartService: OrangeShoppingCartService,
        public permSv: PermissionService
    ) {
        this.orangeAdditionalBonos$ = orangeStore.pipe(select('orangeAdditionalBonos'));
        this.orangePrepaid$ = orangeStore.pipe(select('orangePrepaid'));

        this.allSubscription.push(this.homeService.getNavigateFromOperationPosVenta()
            .subscribe((data: INavigateFromOperation) => {
                this.dataOperationPosVenta = data;
            })
        );

        this.allSubscription.push(this.homeService.getNavigateFromOperation()
            .subscribe((data: INavigateFromOperation) => {
                this.dataOperation = data;
            })
        );

        this.shoppingCartService.hiddeShoppingCart.subscribe((b: boolean) => this.hiddeShoppingCart = b);
    }

    ngOnInit(): void {
        this.href = this.router.url;
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.href = event.url;
            });
    }

    @HostListener('window:scroll', ['$event'])
    doSomethingOnWindowScroll(event: Event) {
        const element: any = event.srcElement;
        const scrollOffset = element.children[0].scrollTop;
        const offsetTop = this.filling.first.nativeElement.offsetTop;

        if (scrollOffset >= offsetTop) {
            if (!this.navbar.first.nativeElement.classList.contains('fixed-top')) {
                this.filling.first.nativeElement.setAttribute('style', 'height: ' + this.navbar.first.nativeElement.offsetHeight + 'px');
                this.navbar.first.nativeElement.classList.add('fixed-top');
            }
        } else {
            if (this.navbar.first.nativeElement.classList.contains('fixed-top')) {
                this.filling.first.nativeElement.setAttribute('style', 'height: 0px');
                this.navbar.first.nativeElement.classList.remove('fixed-top');
            }
        }
    }

    openShoppingCart() {
        this.orangeStore.dispatch(showShoppingCart({show: true}));
    }

    goBack() {
        this.dataOperationPosVenta ? this.router.navigate(['/']) : this.router.navigate(['orange/bonos-adicionales-listado']);
    }

    goBackPrepaid() {
        this.dataOperation ? this.router.navigate(['/']) : this.router.navigate(['/orange/prepago-listado']);
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }
}
