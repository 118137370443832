<div class="row">
    <div class="col-xl-12">
        <div class="card mb-3">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <h6 class="subtitulo-pack2">
                            <b>Firma justificante y documentación</b>
                        </h6>
                    </div>
                </div>
            </div>
            <div class="card-body bg-white">
                <div class="mt-3">
                    <ng-container *ngFor="let document of orderDocuments; let i = index">
                        <div class="d-flex justify-content-between align-items-center px-2 py-3" style="border: .5px solid #f1f1f1;">
                            <div [ngClass]="{'col-8': document.hash && document.hash !== '' && document.status === 'signed', 'col-10': !(document.hash && document.hash !== '' && document.status === 'signed')}">
                                <span class="dot-tables me-2" [ngClass]="{'blink': isDocumentPending(document.status) }"></span>
                                <i-feather [name]="getNameIconDocument(document)" class="icon-tables2"></i-feather> <b> {{ document.document_type_name }}</b> {{ document.document_type_description }}
                            </div>
                            <div class="col-2" [align]="document.hash && document.hash !== '' && document.status === 'signed' ? 'center' : 'end'">
                                <span class="badge rounded-pill spacing status-text" [ngClass]="getClassStatusTitle(document)" *ngIf="document.status !== 'not_send'">
                                    {{ translateStatusTitle(document) }}
                                </span>
                            </div>
                            <div class="col-2 d-flex justify-content-end" *ngIf="document.hash && document.hash !== '' && document.status === 'signed'">
                                <a *ngIf="!document.contractDownloaded" (click)="download(document)" class="ms-2 buttonss button5 cursor-pointer" style="position: relative;" [title]="document.contractError ? 'Error al descargar el documento. Inténtelo más tarde' : 'Descargar documento'">
                                    <i-feather name="download" class="icon-tables"></i-feather>
                                    <span *ngIf="document.contractError" class="dot-cancel-red icon-error"></span>
                                </a>
                                <span *ngIf="document.contractDownloaded" class="spinner-grow ms-2" role="status" aria-hidden="true" style="display: inline-block; width: 32px; bottom: -8px; position: relative;"></span>
                            </div>
                        </div>
                    </ng-container>
                    <div class="mt-4 mb-2">
                        <a (click)="errorSignDocuments = false;" *ngIf="allowResentDocuments" data-bs-toggle="modal" data-bs-target="#peticion" class="btn btn-simyo-o2 btn-sm p-2">
                            Enviar peticiones
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="peticion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header bg-simyo-grey">
                <h5 class="modal-title white" id="exampleModalLabel">Enviar peticiones</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" #closeSendLinkModal *ngIf="!loadingSignDocuments">
                    <span aria-hidden="true"><img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20"></span>
                </button>
            </div>

            <div class="modal-body">
                <div class="row" *ngIf="!loadingSignDocuments && !sendDocumentsComplete">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="col-12 col-xl" [formGroup]="signatureFormGroup">
                                <p>Se enviará un email a la dirección <b>{{email}}</b>.</p>
                                <p>Si desea enviar las peticiones a otra dirección, rellene el siguiente campo:</p>

                                <mat-form-field class="col-11 mx-3">
                                    <mat-label>E-mail</mat-label>

                                    <input matInput type="text" oninvalid="" formControlName="email">
                                    <!-- error -->
                                    <mat-error>
                                        Formato incorrecto.
                                    </mat-error>
                                </mat-form-field>

                                <div class="mt-4 mb-3 d-flex justify-content-center">
                                    <a class="btn btn-simyo mt-1 mb-1" role="button" (click)="sendDocumentationSign()">
                                        Enviar link de peticiones
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="my-5 d-flex justify-content-center"  *ngIf="loadingSignDocuments">
                    <h5  class="tar-name-preload text-center" style="font-size: 16px !important;">
                        Enviando documentación para firmar...
                    </h5>
                    <div class="d-flex justify-content-center">
                        <img class="" src="./assets/img/spinner-simyo.svg" width="60" alt="">
                    </div>
                </div>

                <div *ngIf="errorSignDocuments" class=" d-flex justify-content-center">
                    <h5  class="tar-name-preload text-center">
                        Ha ocurrido un error al enviar la documentación.
                        <br>
                        Inténtelo de nuevo más tarde
                    </h5>
                </div>

                <div class="row" *ngIf="sendDocumentsComplete && !loadingSignDocuments">
                    <div class="col-xl-12">
                        <div class="mx-5 mb-4 mt-3 modal-title text-center" >
                            <div class="d-flex justify-content-center">
                                <img class="" src="./assets/img/ok-blue.svg" width="60" alt="">
                            </div>
                            <br>
                            Ya se ha enviado correctamente un email a <b>{{email}}</b> para que pueda firmar la documentación.
                            <br><br>
                            En cuanto haya realizado la firma de los documentos se actualizará en el detalle del pedido.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
