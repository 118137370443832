import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { MasterAllResponse } from '../models/masterAllResponse';

@Injectable({
    providedIn: 'root'
})
export class OrangeService {

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) { }

    getMasterAllFromService(use_legacy: boolean = true) {
        if (use_legacy) {
            return this.http.get<MasterAllResponse>(environment.digoEndPoint + 'api-micros/pdv/orange/prepaid/master.itx', {headers: this.getHeadersMaster()});
        }

        return this.http.get<MasterAllResponse>(environment.orangeEndPoint + 'master/all', {headers: this.getHeadersMasterApiKey()});
    }

    getHeadersMaster() {
        const token = 'Bearer ' + this.auth.getToken();

        return new HttpHeaders({
            Authorization: token,
            'Content-Type': 'application/json'
        });
    }

    getHeadersMasterApiKey() {
        const token = 'Bearer ' + this.auth.getToken();

        return new HttpHeaders({
            Authorization: token,
            'Content-Type': 'application/json',
            'x-api-key': '43379bba-beb6-41a1-a1c4-4bbc387b89ed'
        });
    }
}
