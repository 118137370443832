import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrangeRoutingModule } from './orange-routing.module';
import { OrangeBonosHiringComponent } from './components/orange-bonos-hiring/orange-bonos-hiring.component';
import { SharedModule } from '../shared/shared.module';
import { OrangeBonosHiringConfigurationComponent } from './components/orange-bonos-hiring/orange-bonos-hiring-configuration/orange-bonos-hiring-configuration.component';
import { OrangeBonosHiringConfirmationComponent } from './components/orange-bonos-hiring/orange-bonos-hiring-confirmation/orange-bonos-hiring-confirmation.component';
import { OrangeBonosHiringDetailComponent } from './components/orange-bonos-hiring/orange-bonos-hiring-detail/orange-bonos-hiring-detail.component';
import { OrangeBonosHiringListComponent } from './components/orange-bonos-hiring/orange-bonos-hiring-list/orange-bonos-hiring-list.component';
import { OrangeBonosHiringMsisdnComponent } from './components/orange-bonos-hiring/orange-bonos-hiring-msisdn/orange-bonos-hiring-msisdn.component';
import { OrangeSubheaderComponent } from './components/orange-subheader/orange-subheader.component';
import { OrangeShoppingCartComponent } from './components/orange-shopping-cart/orange-shopping-cart.component';
import { OrangePrepaidHiringComponent } from './components/orange-prepaid-hiring/orange-prepaid-hiring.component';
import { OrangePrepaidConfigurationComponent } from './components/orange-prepaid-hiring/orange-prepaid-configuration/orange-prepaid-configuration.component';
import { OrangePrepaidUserDataComponent } from './components/orange-prepaid-hiring/orange-prepaid-user-data/orange-prepaid-user-data.component';
import { OrangePrepaidConfirmationComponent } from './components/orange-prepaid-hiring/orange-prepaid-confirmation/orange-prepaid-confirmation.component';
import { OrangePrepaidDetailComponent } from './components/orange-prepaid-hiring/orange-prepaid-detail/orange-prepaid-detail.component';
import { OrangePrepaidListComponent } from './components/orange-prepaid-hiring/orange-prepaid-list/orange-prepaid-list.component';
import { OrangeRateCardComponent } from './components/orange-rate-card/orange-rate-card.component';
import { OrangePrepaidConfirmationCardComponent } from './components/orange-prepaid-hiring/orange-prepaid-confirmation-card/orange-prepaid-confirmation-card.component';
import { ModalKycErrorComponent } from './modals/modal-kyc-error/modal-kyc-error.component';
import { ModalEContratoComponent } from './modals/e-contrato/modal-e-contrato.component';


@NgModule({
    declarations: [
        OrangeBonosHiringComponent,
        OrangeBonosHiringConfigurationComponent,
        OrangeBonosHiringConfirmationComponent,
        OrangeBonosHiringDetailComponent,
        OrangeBonosHiringListComponent,
        OrangeBonosHiringMsisdnComponent,
        OrangeSubheaderComponent,
        OrangeShoppingCartComponent,
        OrangePrepaidHiringComponent,
        OrangePrepaidConfigurationComponent,
        OrangePrepaidUserDataComponent,
        OrangePrepaidConfirmationComponent,
        OrangePrepaidDetailComponent,
        OrangePrepaidListComponent,
        OrangeRateCardComponent,
        OrangePrepaidConfirmationCardComponent,
        ModalKycErrorComponent,
        ModalEContratoComponent
    ],
    imports: [
        CommonModule,
        OrangeRoutingModule,
        SharedModule
    ],
    exports: [
        OrangeSubheaderComponent,
        OrangeShoppingCartComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class OrangeModule { }
