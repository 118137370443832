<div>
    <div class="modal-content">
        <div class="modal-header bg-simyo-grey">
            <h6 class="modal-title white subtitulo-pack2 p-0"><b>{{title}}</b></h6>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="confirm('NO')">
                <span aria-hidden="true"><img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20"></span>
            </button>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h6 class="mt-2 text-simyo" [innerHTML]="text">
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="container d-flex justify-content-end p-0 m-0">
                <button type="button" class="btn btn-simyo px-5 me-2" (click)="confirm('SI')">Sí</button>
                <button type="button" class="btn btn-simyo px-5" (click)="confirm('NO')">No</button>
            </div>
        </div>
    </div>
</div>
