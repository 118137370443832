<div *ngIf="!loaded && !orderNotFound" class="mt-5 mb-5">
    <h5  class="tar-name-preload text-center">Obteniendo orden...</h5>

    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
    </div>
</div>

<div class="container mt-3" *ngIf="orderNotFound">
    <div class="row">
        <div class="col-md-12">
            <h5 class="title-step mt-4 text-center" >
                Lo sentimos, el pedido que está buscando no existe
            </h5>
            <div class="d-flex justify-content-center">
                <button routerLink="/orange/prepago-listado" class="btn btn-simyo-o2 btn-sm mt-2">
                    Volver al listado
                </button>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="loaded && (orange$ | async)?.orderInfo">
    <div class="container content mb-2">
        <div class="row">
            <div class="col-md-12">
                <h5 class="title-step mt-4 text-center" >
                    Resumen de la activación
                </h5>
            </div>
        </div>
    </div>
    <!--------- Resumen de la tramitación ------------->
    <!-------------------------------------------------->

    <div class="container firma mt-4 mb-5">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card mb-3">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2">
                                            <b>Cliente</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body bg-white">
                                <div class="row mt-4">
                                    <div class="col-lg-8">
                                        <!-- Nombre cliente -->
                                        <h5 class="title-list ms-3">
                                            <a class="text-list">
                                                <b>{{ (orange$ | async)?.orderInfo?.customer_full_name }}</b>
                                            </a>
                                            <br><br>
                                            <!-- Documento de identidad -->
                                            <span class="font-list">
                                                <img src="./assets/img/file.svg" class="img-fluid" width="13">
                                                {{ (orange$ | async)?.orderInfo?.document_type }}: <b>{{ (orange$ | async)?.orderInfo?.document_number }}</b>
                                            </span>
                                            <br>
                                            <!-- Msisdn -->
                                            <!-- <span class="font-list" *ngIf="(orange$ | async)?.orderInfo?.msisdn && (orange$ | async)?.orderInfo?.msisdn !== ''">
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                Msisdn: <b>{{ (orange$ | async)?.orderInfo?.msisdn }}</b>
                                            </span>
                                            <br> -->
                                            <!-- Fecha de solicitud -->
                                            <ng-container *ngIf="(orange$ | async)?.orderInfo?.created_at && (orange$ | async)?.orderInfo?.created_at !== ''">
                                                <span class="font-list">
                                                    <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                    Fecha de solicitud: <b>{{ (orange$ | async)?.orderInfo?.created_at | date: 'dd/MM/yyyy, HH:mm' }}</b>
                                                </span>
                                                <br>
                                            </ng-container>
                                        </h5>
                                    </div>

                                    <!--<div  class="col-lg-4 d-flex justify-content-end">
                                        <div class="col-lg-12 mb-2" *ngFor="let line of (orange$ | async)?.orderInfo?.lines">
                                            <span class="badge rounded-pill badge-simyo font-list">
                                                {{ line.welcome_pack + ' - ' + line.rate + ': ' + (+line.contracted_price).toFixed(2) }} €
                                            </span>
                                        </div>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                        <div class="card mb-3">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2 ms-3">
                                            <img src="./assets/img/file-text-negro.svg" class="img-fluid ico-title me-1" width="13">
                                            <b>Resumen de la tramitación</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-white">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item cuerpo-pack-2">
                                                <div *ngFor="let line of (orange$ | async)?.orderInfo?.lines; index as i">
                                                    <hr *ngIf="i > 0">
                                                    <!-- RESUMEN MOVIL PACK -->
                                                    <h5  class="tar-name-7 mt-2 ms-3">
                                                        <!-- <b>{{ 'eSIM prepago ' + (i + 1) + 'ª línea móvil' }}</b> -->
                                                        <b>{{ getAltaType(line) }}</b>
                                                        <button class="btn btn-black py-0" style="float: right;" (click)="printOrder(line.order_id)" role="button">
                                                            Imprimir justificante
                                                        </button>
                                                        <button *ngIf="line.can_be_cancelled && !line.cancelLoading" class="btn btn-black py-0" style="float: right; margin-right: 10px;" (click)="cancelLine(line)" role="button">
                                                            Cancelar línea
                                                        </button>
                                                        <span *ngIf="line.cancelLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" style="float: right;"></span>
                                                    </h5>

                                                    <div class="resumen d-flex justify-content-between align-items-center">
                                                        <span class="sidebar-info4 simyo">
                                                            Conceptos
                                                        </span>
                                                        <small class="text-muted"></small>
                                                    </div>

                                                    <div class="resumen d-flex justify-content-between align-items-center">
                                                        <!-- MSISDN -->
                                                        <span>
                                                            <img src="./assets/img/phone.svg" class="img-fluid me-1" width="13">
                                                            <span>{{ getAltaName(line) + line.msisdn }}</span>
                                                        </span>
                                                    </div>

                                                    <div class="resumen d-flex justify-content-between align-items-center">
                                                        <!-- tarifa -->
                                                        <span>
                                                            <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                            {{ line.welcome_pack + ' - ' + line.rate }}
                                                            <!--<span *ngIf="line.is_esim && (orange$ | async)?.orderInfo?.super_status === superStatus.active" class="px-2 ms-2 custom-info cursor-pointer" (click)="changeStateInfoEsin(i, !line.showEsim)">
                                                                {{ line.showEsim ? 'Ocultar datos eSIM' : 'Mostrar datos eSIM' }}
                                                            </span>-->
                                                            <span *ngIf="line.is_esim && line.super_status === superStatus.active && !moreOneDay" class="px-2 mx-2 custom-info cursor-pointer" (click)="changeStateSendEmail(i, !line.showSendEmail)">
                                                                {{ line.showSendEmail ? 'Ocultar eSIM por e-mail' : 'Enviar eSIM por e-mail' }}
                                                            </span>
                                                            <span *ngIf="line.is_esim && line.showEsim"><a (click)="changeStateInfoEsim(i)" class="px-2 mx-2 custom-info cursor-pointer a-link">
                                                                {{ hideEsim(i) ? 'Ver' : 'Ocultar'}} eSIM</a>
                                                            </span>
                                                        </span>
                                                        <small class="text-muted">
                                                            <!-- Precio tarifa -->
                                                            <span class="sidebar-info3 custom-font">
                                                                <b>{{ (+line.contracted_price).toFixed(2) }} €</b>
                                                            </span>
                                                        </small>
                                                    </div>

                                                    <div class="resumen d-flex justify-content-between align-items-center" *ngIf="line.recharge_amount && line.recharge_amount > 0">
                                                        <!-- tarifa -->
                                                        <span>
                                                            <img src="./assets/img/saldo.svg" class="img-fluid" width="13">
                                                            Recarga
                                                        </span>
                                                        <small class="text-muted">
                                                            <!-- Precio tarifa -->
                                                            <span class="sidebar-info3">
                                                                <b>{{ (+line.recharge_amount).toFixed(2) }} €</b>
                                                            </span>
                                                        </small>
                                                    </div>

                                                    <div class="resumen" *ngIf="line.showEsim && !hideEsim(i)">
                                                        <div>
                                                            <div class="px-4">
                                                                <p><b class="col-6 px-0">PIN: </b><span class="simyo">{{ line.pin }}</span></p>
                                                                <img [src]="'data:image/png;base64,' + line.qr_code" [width]="150" class="img-fluid" alt="Qr code"/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="resumen d-flex align-items-center p-3 row" *ngIf="line.showSendEmail">
                                                        <div class="col-12 px-0">
                                                            <mat-form-field class="col-6 me-3">
                                                                <mat-label>Correo electrónico</mat-label>
                                                                <input matInput type="text" [formControl]="sendEmailFormControl" [ngModel]="email">
                                                                <!-- error -->
                                                                <mat-error *ngIf="sendEmailFormControl.errors?.required">
                                                                    Campo obligatorio.
                                                                </mat-error>
                                                                <mat-error *ngIf="sentEmailError">
                                                                    <p>Error al enviar los datos. <br>Inténtalo de nuevo más tarde</p>
                                                                </mat-error>
                                                            </mat-form-field>
                                                            <input type="text" [formControl]="sendMsisdnFormControl" hidden [ngModel]="line.msisdn">
                                                            <button (click)="sendEmail()" target="_blank" class="btn btn-black-outline btn-sm" [disabled]="sentEmailLoading">
                                                                <ng-container *ngIf="sentEmailLoading">
                                                                    <b>Enviando...</b>
                                                                </ng-container>
                                                                <ng-container *ngIf="sentEmailOk">
                                                                    <b>Enviado</b>
                                                                </ng-container>
                                                                <ng-container *ngIf="!sentEmailLoading && !sentEmailOk">
                                                                    <b>Enviar</b>
                                                                </ng-container>
                                                            </button>
                                                        </div>
                                                        <mat-error class="col-12 px-0" *ngIf="sentEmailError">
                                                            <p>Error al enviar el email. Inténtalo de nuevo más tarde</p>
                                                        </mat-error>
                                                    </div>

                                                    <div class="resumen d-flex justify-content-between align-items-center" *ngIf="line.status && line.status !== ''">
                                                        <!-- Estado tramitacion -->
                                                        <span><img src="./assets/img/info.svg" class="img-fluid" width="13">
                                                            Estado: <b>{{ line.status_description }}</b> <b *ngIf="line.error_description && line.error_description !== ''"> ({{ line.error_description }})</b>
                                                        </span>
                                                    </div>

                                                    <!-- <div class="resumen d-flex justify-content-between align-items-center" *ngIf="line.error_description && line.error_description !== ''">
                                                        <span><img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                            Descripción: <b>{{ line.error_description }}</b>
                                                        </span>
                                                    </div> -->

                                                    <div class="resumen d-flex justify-content-between align-items-center" *ngIf="line.activation_date && line.activation_date !== ''">
                                                        <!-- Descripción -->
                                                        <span><img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                            Fecha de activación: <b>{{ line.activation_date | date: 'dd/MM/yyyy, HH:mm' }}</b>
                                                        </span>
                                                    </div>

                                                    <div class="resumen d-flex justify-content-between align-items-center" *ngIf="line.errorCanceled && line.errorCanceled !== ''">
                                                        <!-- Descripción -->
                                                        <span class="sidebar-info5" style="color: red;">
                                                            *{{ line.errorCanceled }}
                                                        </span>
                                                    </div>

                                                </div>
                                                <ng-container>
                                                    <!--<div class="resumen d-flex justify-content-between align-items-center mt-2" *ngIf="(orange$ | async)?.orderInfo?.lines?.length && (orange$ | async)?.orderInfo?.lines[0].is_esim">
                                                        <span class="sidebar-info">
                                                            <b>Total</b>
                                                        </span>
                                                        <small class="text-muted trash-carrito">
                                                            <span class="sidebar-info2">
                                                                <b>{{ (+(orange$ | async)?.orderInfo?.total_price).toFixed(2) }} €</b>
                                                            </span>
                                                        </small>
                                                    </div>
                                                    <hr>-->
                                                    <!-- Importe Total -->
                                                    <!--<div class="resumen-total total d-flex justify-content-between align-items-center" *ngIf="(orange$ | async)?.orderInfo?.lines?.length && (orange$ | async)?.orderInfo?.lines[0].is_esim">
                                                        <h5 class="total-color">
                                                            <b>Pago total</b>
                                                        </h5>
                                                        <h5 class="text-auto">
                                                            <b>{{ (+(orange$ | async)?.orderInfo?.total_price).toFixed(2) }} €</b>
                                                        </h5>
                                                    </div>-->
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                        <span class="total-color">
                                                            <b>IVA incluido</b>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="hash">
                            <div class="row">
                                <div class="col-xl-12 mb-3">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h6 class="subtitulo-pack2">
                                                        <b>Documentación</b>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body bg-white">
                                            <div class="row">
                                                <div class="col-xl-12 mb-3">
                                                    <div class="card-footer" style="border:none">
                                                        <div *ngIf="{ document_type_code: 'contract' } as doc" class="d-flex justify-content-between align-items-center ms-2">
                                                            <div class="cursor-pointer" (click)="downloadPreContract()">
                                                                <b> Resumen Contrato</b>
                                                                <i-feather name="download" class="icon-tables ms-2"></i-feather>
                                                            </div>
                                                            <span *ngIf="loadingPreDownload" class="spinner-grow" role="status" aria-hidden="true" style="display: inline-block; width: 32px; position: relative;"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
</ng-container>
