import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-hm-modal-jazztel-contratos',
    templateUrl: './hm-modal-jazztel-contratos.component.html',
    styleUrls: ['./hm-modal-jazztel-contratos.component.css', '../../../../assets/css/home-theme.css']
})
export class HmModalJazztelContratosComponent implements OnInit {

    constructor(
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {
    }

}
