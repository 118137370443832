<div class="modal-body p-0">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title fw-bold ms-3 mt-3" id="exampleModalLabel">Contratos en blanco</h5>
            <button type="button" class="close close-m2" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <img src="./assets/img/x-black.svg" width="18" class="close-aviso--1">
            </button>
        </div>
        <div class="modal-body mb-3">
            <div class="container">
                <div class="row mb-2">
                    <div class="col-12 col-xl">
                        <div class="card--notify mb-2">
                            <div class="card-body--notify">
                                <div class="row mb-0">
                                    <div class="col-12 col-sm ps-4 row">
                                        <img src="./assets/img/sm-login-color.svg" class="img-fluid marca--rm  soporte--logo" width="120">
                                        <p class="mb-0 align-self-center fw-bold black">Contrato portabilidad</p>
                                    </div>
                                    <div class="col-12 col-sm-auto">
                                        <p class="pt-4">
                                            <a download="Simyo - Contrato portabilidad blanco.pdf" href="./assets/pdf/Simyo - Contrato portabilidad blanco.pdf" target="_blank" class="fw-bold black">Descargar</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card--notify mb-2">
                            <div class="card-body--notify">
                                <div class="row mb-0">
                                    <div class="col-12 col-sm ps-4 row">
                                        <img src="./assets/img/sm-login-color.svg" class="img-fluid marca--rm  soporte--logo" width="120">
                                        <p class="mb-0 align-self-center fw-bold black">Contrato número nuevo</p>
                                    </div>
                                    <div class="col-12 col-sm-auto">
                                        <p class="pt-4">
                                            <a download="Simyo - Contrato número nuevo blanco.pdf" href="./assets/pdf/Simyo - Contrato número nuevo blanco.pdf" target="_blank" class="fw-bold black">Descargar</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                            <div class="card-body--notify">
                                <div class="row mb-0">
                                    <div class="col-12 col-sm ps-4 row">
                                        <img src="./assets/img/sm-login-color.svg" class="img-fluid marca--rm  soporte--logo" width="120">
                                        <p class="mb-0 align-self-center fw-bold black">Contrato fibra</p>
                                    </div>
                                    <div class="col-12 col-sm-auto">
                                        <p class="pt-4">
                                            <a download="Simyo - Contrato fibra blanco.pdf" href="./assets/pdf/Simyo - Contrato fibra blanco.pdf" target="_blank" class="fw-bold black">Descargar</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card--notify mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                            <div class="card-body--notify">
                                <div class="row mb-0">
                                    <div class="col-12 col-sm ps-4 row">
                                        <img src="./assets/img/sm-login-color.svg" class="img-fluid marca--rm  soporte--logo" width="120">
                                        <p class="mb-0 align-self-center fw-bold black">Contrato convergente</p>
                                    </div>
                                    <div class="col-12 col-sm-auto">
                                        <p class="pt-4">
                                            <a download="Simyo - Contrato fibra blanco.pdf" href="./assets/pdf/Simyo - Contrato convergente blanco.pdf" target="_blank" class="fw-bold black">Descargar</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
