import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.css']
})
export class CustomInputComponent implements OnChanges{

    @Input() id: string;
    @Input() label: string = '';
    @Input() labelTemplate!: TemplateRef<any>;  // Para label como template
    @Input() type: TypeInput = TypeInput.text;
    @Input() isRequired: boolean = false;
    @Input() control: FormControl;
    @Input() controlName: string;
    @Input() maxlength: string;
    @Input() minlength: string;
    @Input() customeMessageError: string;
    @Input() placeholder: string;
    @Input() min: string;
    @Input() max: string;
    @Input() pattern: string;
    @Input() autocomplete: string = 'on';
    @Input() activeBlockPaste = false;
    @Input() isDisabled: boolean = false;

    @Output() OnKeyPress = new EventEmitter();
    @Output() OnInput = new EventEmitter();
    @Output() OnKeyDown = new EventEmitter();
    @Output() OnFocusIn = new EventEmitter();
    @Output() OnFocusOut = new EventEmitter();
    @Output() OnAutocompleteSelected = new EventEmitter();

    isFocused = false;
    TypeInput = TypeInput;
    country: string = environment.googleMapsCountry;

    constructor() {

    }
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.isDisabled) {
            if(changes.isDisabled.currentValue) {
                this.control?.disable();
            } else {
                this.control?.enable();
            }
        }
    }

    onFocus() {
        this.isFocused = true;
    }

    onBlur() {
        this.isFocused = false;
    }
}

export enum TypeInput {
    text = 'text',
    number = 'number',
    maps = 'maps'
};
