import { Component } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, tap } from 'rxjs';
import { SimyoService } from 'src/app/simyo/services/simyo.service';
import { onChangeZip, searchRoadType } from 'src/app/utils/normalizerFunctions';
import { isAdult } from 'src/app/utils/validators/any-form-valid';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { MustMatch } from 'src/app/utils/validators/email-confirm-validator';
import { dniValidator } from 'src/app/utils/validators/spanish-id-validator';
import { ZipCodeFormat } from 'src/app/utils/validators/zip-code-validator';

@Component({
  selector: 'app-test-component',
  templateUrl: './test-component.component.html',
  styleUrls: ['./test-component.component.css']
})
export class TestComponentComponent {

    clienteFormGroup: FormGroup;

    public maxLengthByDoc = 9;
    public requireCif: boolean = false;
    listGenders = [
        {
            "gender_id": 1,
            "code": "0",
            "value": "Mujer"
        },
        {
            "gender_id": 2,
            "code": "1",
            "value": "Hombre"
        }
    ];
    datosProvincia = [];
    constructor(private formBuilder: FormBuilder,
        private smService: SimyoService
    ) {
        const formOptions: AbstractControlOptions = {
            validators: [MustMatch('email', 'email_confirm'), ZipCodeFormat('codigoPostal'), MustMatch('portability_origen_email', 'portability_origen_email_confirm')]
         }
        this.clienteFormGroup = this.formBuilder.group({
            doctype: ['', [Validators.required]],
            doc: ['', [Validators.required, blankSpaceValidator(), Validators.pattern('^[a-zA-Z0-9]+$'), dniValidator()]],
            country: ['192', [Validators.required]],
            firstName: ['', [Validators.required, blankSpaceValidator()]],
            surname1: ['', [Validators.required, blankSpaceValidator()]],
            surname2: ['', /*Validators.required*/],
            birthday: ['', [Validators.required, isAdult()]],
            sex: [''/*, Validators.required*/],
            company: [''],
            tipoVia: [''],
            nombreVia: ['', [Validators.required, blankSpaceValidator()]],
            numero: ['', [Validators.required, blankSpaceValidator()]],
            planta: [''],
            puerta: [''],
            escalera: [''],
            bloque: [''],
            codigoPostal: ['', [Validators.required, blankSpaceValidator()]],
            localidad: ['', [Validators.required, blankSpaceValidator()]],
            provincia: ['', [Validators.required]],
            phone: ['', [Validators.required, Validators.pattern('^[6-9][0-9]{0,8}$'), Validators.minLength(9), Validators.maxLength(9)]],
            email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
            email_confirm: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
            account_types: [''/*, Validators.required*/],
            bill_cicle_types: [''/*, Validators.required*/],
            // Postpago
            acct_code: [''],
            bank_account: [''],
            new_bank_account: [''],
            account_doctype: ['', [Validators.required]],
            account_doc: ['', [Validators.required, blankSpaceValidator(), Validators.pattern('^[a-zA-Z0-9]+$')]],
            account_country: ['', [Validators.required, blankSpaceValidator()]],
            account_firstName: ['', [Validators.required, blankSpaceValidator()]],
            account_surname1: ['', [Validators.required, blankSpaceValidator()]],
            account_surname2: ['', /*Validators.required*/],
            account_company: [''],
            // Prepago
            credit_card_type: [''],
            credit_card_name: [''],
            credit_card_number: [''],
            credit_card_date_of_expiry: [''],
            credit_card_cvv: [''],
            // Delivery address
            office: [''],
            full_name: [''],
            delivery_phone: [''],
            road_type: [''],
            address: [''],
            number: [''],
            floor: [''],
            door: [''],
            stairway: [''],
            zip: [''],
            city: [''],
            province: [''],
            additional_info: [''],
            is_custom_delivery: [''],
            //Portability change contact
            portability_origen_name: [''],
            portability_origen_lastname: [''],
            portability_origen_lastname2: [''],
            portability_origen_birth_date: [''],
            portability_origen_doc_type: [''],
            portability_origen_doc: [''],
            portability_origen_phone: [''],
            portability_origen_email: [''],
            portability_origen_email_confirm: [''],
            portability_origen_nationality_id: [192],
            search: ['']
        }, formOptions);

        this.smService.getProvincesCommunFromService().pipe(
            map((data:any) => {
                if(data && data.msg) {
                    this.smService.setProvincesCommun(data.msg);
                    return data.msg;
                }
                return null;
            }),
            tap((data: any) => {
                if (data) {
                    this.datosProvincia = data;
                    this.onChangeZip(this.clienteFormGroup.get('codigoPostal').value);
                }
            })
        ).subscribe()
    }

    public getControl(controlName:string) {
        return this.clienteFormGroup ? (this.clienteFormGroup?.get(controlName) as FormControl) : new FormControl;
    }

    alphanumericOnly(event) {
        let pattern: RegExp = /^[a-zA-Z0-9]+$/;
        const inputChar = event.key;
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    public onChangeZip(value) {
        if (value.length > 5) {
            this.clienteFormGroup.get('codigoPostal').patchValue(value.slice(0, 5));
            }
        onChangeZip(value, this.datosProvincia, 'postal_code', this.clienteFormGroup.get('provincia'));
    }

    onlyPositiveDigits(event){
        if (event.key.length > 1) {
            return true;
        }
        if (+event.key >= 0 && +event.key <= 9 ) {
            return true;
        }

        return false;
    }

    onAddressChange(event) {
        setTimeout(() => {
            const text = event.target.value;
            const autocompleteBoxes = document.getElementsByClassName('pac-container pac-logo');
            let autocompleteBox = autocompleteBoxes[autocompleteBoxes.length - 1];

            for (let i = 0; i < autocompleteBoxes.length; i++) {
                if (autocompleteBoxes.item(i).clientWidth > 0 && autocompleteBoxes.item(i).innerHTML) {
                    autocompleteBox = autocompleteBoxes.item(i);
                    break;
                }
            }

            /*if (text && text !== '') {
                this.manualAddress.nativeElement.style.display = 'flex';
                this.manualAddress.nativeElement.style.top = 'calc(100% + ' + (autocompleteBox.clientHeight - 20) + 'px)';
                this.manualAddressText.nativeElement.innerText = text;
            } else {
                this.onAddressFocusOut();
            }*/
        }, 300)
    }

    sendEvent(event) {
        setTimeout(() => {
            const text = event.target.value;
            if (text && text !== '') {
                //this.homeService.sendIdSessionToService('HM');
            }
        }, 300)
    }

    onAddressFocusOut() {
        setTimeout(() => {
            /*this.manualAddress.nativeElement.style.display = 'none';
            this.manualAddress.nativeElement.style.top = '100%';
            this.manualAddressText.nativeElement.innerText = '';*/
        }, 100)
    }

    onAutocompleteSelected($event) {
        const addressComponents: any[] = $event.address_components;
        const datosTipoVia = [
            {code: "CALLE", value: "CALLE"}
        ];
        if (addressComponents != null && addressComponents.length > 0) {
            this.clienteFormGroup.get('tipoVia').patchValue('');
            this.clienteFormGroup.get('nombreVia').patchValue('');
            this.clienteFormGroup.get('numero').patchValue('');
            this.clienteFormGroup.get('codigoPostal').patchValue('');
            this.clienteFormGroup.get('localidad').patchValue('');
            this.clienteFormGroup.get('provincia').patchValue('');

            for (const addressComponent of addressComponents) {
                let field = '';
                const types: string[] = addressComponent.types;
                let value: string = addressComponent.long_name;

                if (types.includes('route')) {
                    field = 'nombreVia';
                    value = searchRoadType(value, datosTipoVia, 'value', this.clienteFormGroup.get('tipoVia'));
                } else if (types.includes('street_number')) {
                    field = 'numero';
                } else if (types.includes('postal_code')) {
                    field = 'codigoPostal';
                    this.onChangeZip(value);
                } else if (types.includes('locality')) {
                    field = 'localidad';
                } else if (types.includes('administrative_area_level_2')) {
                    field = 'provincia';
                    value = value.toUpperCase();
                }

                if (field !== '') {
                    this.clienteFormGroup.get(field).patchValue(value);
                }
            }
        }
        /*if (this.clienteFormGroup.get('numero').value === '') {
            this.formNumber.nativeElement.focus();
        }*/
    }
}
