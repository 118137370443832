import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { Chart, LineController, CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend, Title, ChartOptions, ChartData, ChartDataset, Filler } from 'chart.js';
import { Observable, Subscription } from 'rxjs';
import { DigoService } from 'src/app/services/digo.service';
import { monthNames, tabHome, typeFilterFormOperation } from 'src/app/shared/constantes';
import { HomeService } from '../../services/home.service';
import * as moment from 'moment/moment';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { Ipdv } from 'src/app/shared/models/pdvResponse';

Chart.register(LineController, CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend, Title, Filler);

@Component({
    selector: 'app-hm-sales',
    templateUrl: './hm-sales.component.html',
    styleUrls: ['./hm-sales.component.css', '../../../../assets/css/home-theme.css']
})
export class HmSalesComponent implements OnInit, OnDestroy {
    @Input() tabGroup: MatTabGroup;
    @Input() initSales: Observable<any>;
    @ViewChild('lineCanvasFibra') private lineCanvasFibra: ElementRef;
    @ViewChild('lineCanvasPospago') private lineCanvasPospago: ElementRef;
    @ViewChild('lineCanvasPrepago') private lineCanvasPrepago: ElementRef;
    public lineChartFibra: any;
    public lineChartPospago: any;
    public lineChartPrepago: any;
    public pdv: Ipdv;
    public filterFormGroup: FormGroup;
    public customFilterFormGroup: FormGroup;
    public activationsByMonth: any;
    public activationStatistics: any;
    public activationsByMonthError: boolean = false;
    public brandPermissions: any[] = [];
    public today = new Date();
    public lastMonth = moment().subtract(1, 'months').month();
    public monthNames = monthNames;
    public typeFilterFormOperation = typeFilterFormOperation;
    public hiddenFibra: boolean = false;
    public hiddenPostpago: boolean = false;
    public hiddenPrepago: boolean = false;
    public allPermissions = allPermissions;
    private changeTabSubscription: Subscription;
    private allSubscription: Subscription[] = [];

    public brands = {
        simyo: 'simyo',
        orange: 'orange',
        jazztel: 'jazztel'
    };

    constructor(
        private digoService: DigoService,
        private formBuilder: FormBuilder,
        private homeService: HomeService,
        public permSv: PermissionService
    ) { }

    ngOnInit(): void {
        this.initActivationStatistics();
        this.filterFormGroup = this.formBuilder.group({
            simyo: [true],
            orange: [true],
            jazztel: [true]
        });

        this.customFilterFormGroup = this.formBuilder.group({
            allBrands: new FormControl({value: true, disabled: false})
        });

        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: Ipdv) => {
                if (data && this.pdv == null) {
                    this.pdv = data;
                    this.getActivationsByMonth(this.homeService.getBrandPermission(this.brands));
                }
            })
        );

        this.changeTabSubscription = this.initSales
            .subscribe((data) => {
                if (data?.tab?.textLabel === tabHome.VENTAS) {
                    if (
                        !(this.filterFormGroup.get('simyo').value && 
                        this.filterFormGroup.get('jazztel').value && this.filterFormGroup.get('orange').value)
                    ) {
                        this.filterFormGroup.patchValue({simyo: true, orange: true, jazztel: true});
                        this.changeFilterBrand('', true);
                    }
                    this.getActivationsByMonth(this.homeService.getBrandPermission(this.brands));
                }
            });
    }

    getActivationsByMonth(marcas) {
        this.activationsByMonthError = false;
        this.activationsByMonth = undefined;
        this.homeService.getActivationsByMonthFromService(marcas)
            .subscribe((data: any) => {
                if (data) {
                    this.activationsByMonth = data;
                    this.initCharts();
                    this.changeActivationStatistics();
                    this.homeService.setActivation(this.activationStatistics);
                    this.homeService.setActivationError(false);
                    this.uniqueBrand();
                }
            }, () => {
                this.activationsByMonthError = true;
                this.homeService.setActivationError(true);
            });
    }

    initCharts() {
        let labels = [];
        let datasetsFibra = [];
        let datasetsPospago = [];
        let datasetsPrepago: ChartDataset[] = [];
        labels = this.changeDataChart(labels, datasetsFibra, datasetsPospago, datasetsPrepago);
        const dataFibra : ChartData = {
            labels: labels,
            datasets: datasetsFibra
        };
        const dataPospago : ChartData = {
            labels: labels,
            datasets: datasetsPospago,
        };
        const dataDataPrepago : ChartData = {
            labels: labels,
            datasets: datasetsPrepago
        };

        if (this.lineChartFibra) {
            this.lineChartFibra.destroy();
        }
        this.lineChartFibra = this.createLineChart(this.lineCanvasFibra, dataFibra, 'Fibra');
        if (this.lineChartPospago) {
            this.lineChartPospago.destroy();
        }
        this.lineChartPospago = this.createLineChart(this.lineCanvasPospago, dataPospago, 'Pospago');
        if (this.lineChartPrepago) {
            this.lineChartPrepago.destroy();
        }
        this.lineChartPrepago = this.createLineChart(this.lineCanvasPrepago, dataDataPrepago, 'Prepago');
    }

    changeActivationStatistics() {
        let countEstimatedFibra = 0;
        let countEstimatedPrepago = 0;
        let countEstimatedPospago = 0;
        this.initActivationStatistics();
        const brands = this.filterFormGroup.getRawValue();
        for (let key of Object.keys(this.activationsByMonth)) {
            if (brands && brands[key]) {
                this.activationStatistics.countPreviousMonthFibra += this.activationsByMonth[key][this.activationsByMonth[key].length - 2].broadband;
                this.activationStatistics.countPreviousMonthPospago += this.activationsByMonth[key][this.activationsByMonth[key].length - 2].postpaid;
                this.activationStatistics.countPreviousMonthPrepago += this.activationsByMonth[key][this.activationsByMonth[key].length - 2].prepaid;
                this.activationStatistics.countActualMonthFibra += this.activationsByMonth[key][this.activationsByMonth[key].length - 1].broadband;
                this.activationStatistics.countActualMonthPospago += this.activationsByMonth[key][this.activationsByMonth[key].length - 1].postpaid;
                this.activationStatistics.countActualMonthPrepago += this.activationsByMonth[key][this.activationsByMonth[key].length - 1].prepaid;                       
                countEstimatedFibra += this.activationsByMonth[key][this.activationsByMonth[key].length - 1].estimated.broadband;
                countEstimatedPospago += this.activationsByMonth[key][this.activationsByMonth[key].length - 1].estimated.postpaid;
                countEstimatedPrepago += this.activationsByMonth[key][this.activationsByMonth[key].length - 1].estimated.prepaid;
            }
        }
        this.activationStatistics.estimatedFibra = this.activationStatistics.countPreviousMonthFibra === 0 && countEstimatedFibra === 0 ? 0 : (this.activationStatistics.countPreviousMonthFibra === 0 ? 100 : Math.floor((countEstimatedFibra * 100 / this.activationStatistics.countPreviousMonthFibra) - 100));
        this.activationStatistics.estimatedPospago = this.activationStatistics.countPreviousMonthPospago === 0 && countEstimatedPospago === 0 ? 0 : (this.activationStatistics.countPreviousMonthPospago === 0 ? 100 : Math.floor((countEstimatedPospago * 100 / this.activationStatistics.countPreviousMonthPospago) - 100));
        this.activationStatistics.estimatedPrepago = this.activationStatistics.countPreviousMonthPrepago === 0 && countEstimatedPrepago === 0 ? 0 : (this.activationStatistics.countPreviousMonthPrepago === 0 ? 100 : Math.floor((countEstimatedPrepago * 100 / this.activationStatistics.countPreviousMonthPrepago) - 100));
    }

    createLineChart(canvas, data, nameTitle) {
        const options: ChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            datasets: {
                line: {
                    fill: true
                }
            },
            interaction: {
                mode: 'index',
                axis: 'x',
                intersect: false
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    }
                },
                y: {
                    min: 0,
                    stacked: true,
                    ticks: {
                        stepSize: 1
                    }
                }
            },
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        usePointStyle: true
                    },
                },
                tooltip: {
                    mode: 'index',
                },
                title: {
                    display: true,
                    position: 'top',
                    align: 'start',
                    text: nameTitle,
                    font: {
                        size: 20
                    },
                }
            }
        };
        return new Chart(canvas.nativeElement, {
            type: 'line',
            data: data,
            options: options
        });
    }

    changeFilterBrand(brand, value) {
        if (this.selectedAllBrandsWithPermissions(brand, value) && value) {
            this.customFilterFormGroup.get('allBrands').patchValue(true);
        } else {            
            this.customFilterFormGroup.get('allBrands').patchValue(false);
        }
        this.hiddenFibra = false;
        this.hiddenPostpago = false;
        this.hiddenPrepago = false;
        if (this.selectedAllBrandsWithPermissions(brand, value) && !value) {
            this.filterFormGroup.get(this.brands.simyo).patchValue(brand === this.brands.simyo);
            this.filterFormGroup.get(this.brands.orange).patchValue(brand === this.brands.orange);
            this.filterFormGroup.get(this.brands.jazztel).patchValue(brand === this.brands.jazztel);
        } else if (Object.keys(this.filterFormGroup.getRawValue()).every((k) => !this.filterFormGroup.getRawValue()[k])) {
            this.filterFormGroup.get(brand).patchValue(true);
            this.customFilterFormGroup.get('allBrands').patchValue(false);
            this.uniqueBrand();
            return;
        }
        this.uniqueBrand();
        this.lineChartFibra.data.datasets = [];
        this.lineChartPospago.data.datasets = [];
        this.lineChartPrepago.data.datasets = [];
        this.lineChartFibra.update();
        this.lineChartPospago.update();
        this.lineChartPrepago.update();

        let datasetsFibra = [];
        let datasetsPospago = [];
        let datasetsPrepago = [];
        this.changeDataChart([], datasetsFibra, datasetsPospago, datasetsPrepago);
        this.lineChartFibra.data.datasets = datasetsFibra;
        this.lineChartPospago.data.datasets = datasetsPospago;
        this.lineChartPrepago.data.datasets = datasetsPrepago;
        this.lineChartFibra.update();
        this.lineChartPospago.update();
        this.lineChartPrepago.update();
        this.changeActivationStatistics();
    }

    allBrands() {
        if (this.customFilterFormGroup.get('allBrands').value) {
            this.filterFormGroup.get('simyo').patchValue(true);
            this.filterFormGroup.get('orange').patchValue(true);
            this.filterFormGroup.get('jazztel').patchValue(true);
            this.changeFilterBrand('', true);
        } else {
            this.customFilterFormGroup.get('allBrands').patchValue(true);
        }
    }

    selectedAllBrandsWithPermissions(brandChanged, newValue): boolean {
        const brandWithPermissions = this.homeService.getBrandPermission(this.brands);
        const brandsSelected = this.filterFormGroup.getRawValue();
        if (!newValue) {
            brandsSelected[brandChanged] = !newValue;
        }
        let countBrands = 0;
        brandWithPermissions.forEach(brand => {
            if (brandsSelected[brand]) {
                countBrands ++;
            }
        });
        return brandWithPermissions.length === countBrands;
    }

    uniqueBrand() {
        const brandWithPermissions = this.homeService.getBrandPermission(this.brands);
        const brandsSelected = this.filterFormGroup.getRawValue();
        const selectedBrands = brandWithPermissions.filter(brand => brandsSelected[brand]);
        if (selectedBrands && selectedBrands.length === 1) {
            if (selectedBrands[0] === this.brands.orange) {
                this.hiddenFibra = true;
                this.hiddenPostpago = true;
            }
            if (selectedBrands[0] === this.brands.jazztel) {
                this.hiddenPrepago = true;
            }
        }
    }

    changeDataChart(labels, datasetsFibra, datasetsPospago, datasetsPrepago) {
        const brands = this.filterFormGroup.getRawValue();
        for (let key of Object.keys(this.activationsByMonth)) {
            if (brands && brands[key]) {
                labels = this.activationsByMonth[key].map(x => monthNames[+x.month - 1]);
                if (key !== this.brands.orange) {
                    datasetsFibra.push({
                        label: this.getNameBrand(key),
                        backgroundColor: this.getColorBrand(key),
                        borderColor: this.getColorBrand(key),
                        data: this.activationsByMonth[key].map(x => x.broadband),
                        id: key,
                        type: 'line',
                        fill: 'start'
                    });
                }
                if (key !== this.brands.orange) {
                    datasetsPospago.push({
                        label: this.getNameBrand(key),
                        backgroundColor: this.getColorBrand(key),
                        borderColor: this.getColorBrand(key),
                        data: this.activationsByMonth[key].map(x => x.postpaid),
                        id: key,
                        fill: 'start'
                    });
                }
                if ( key !== this.brands.jazztel) {
                    datasetsPrepago.push({
                        label: this.getNameBrand(key),
                        backgroundColor: this.getColorBrand(key),
                        borderColor: this.getColorBrand(key),
                        data: this.activationsByMonth[key].map(x => x.prepaid),
                        id: key,
                        fill: 'start'
                    });
                }
            }
        }
        return labels;
    }

    viewOperation(type) {
        if(this.permSv.hasPermFromV2(allPermissions.others.access_night) === false) return;
        this.tabGroup.selectedIndex = this.homeService.findIndexToMove(this.tabGroup, tabHome.OPERACIONES);
        this.homeService.setTypeOperationForms({type: type, filtersBrands: this.filterFormGroup.getRawValue()});
    }

    getNameBrand(id) : string {
        switch (id) {
            case this.brands.simyo:
                return 'Simyo';
            case this.brands.orange:
                return 'Orange';
            case this.brands.jazztel:
                return 'Jazztel';
            default:
                return '';
        }
    }

    getColorBrand(id) : string {
        switch (id) {
            case this.brands.simyo:
                return '#5F5F5F';
            case this.brands.orange:
                return '#ff7900';
            case this.brands.jazztel:
                return '#EBB310';
            default:
                return '';
        }
    }

    initActivationStatistics() {
        this.activationStatistics = {
            countPreviousMonthFibra: 0,
            countPreviousMonthPospago: 0,
            countPreviousMonthPrepago: 0,
            countActualMonthFibra: 0,
            countActualMonthPospago: 0,
            countActualMonthPrepago: 0,
            estimatedFibra: 0,
            estimatedPospago: 0,
            estimatedPrepago: 0,
        }
    }

    getAbsoluteValue(value) {
        return Math.abs(value);
    }

    ngOnDestroy() {
        this.changeTabSubscription.unsubscribe();
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

}
