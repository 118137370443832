import { Payment } from './payment';
import { Address } from './address';
import { DeliveryAddress } from './delivery-address';

export class Customer {
    name?: string;
    last_name?: string;
    second_last_name?: string;
    phone_number?: string;
    email?: string;
    document_type_id?: any;
    document?: string;
    nationality_id?: string;
    birth_date?: string;
    gender_id?: string;
    is_delivery?: boolean; // Indica si hay direccion de entrega
    company_name?: string;
    postal_address? = new Address(); // Direccion de contacto
    installation_address = new Address(); // Direccion de instalacion
    payment? = new Payment(); // Datos de pago
    delivery_address? = new DeliveryAddress(); // Direccion de entrega del terminal
}
