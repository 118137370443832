import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-not-found',
    templateUrl: './user-not-found.component.html',
    styleUrls: ['./user-not-found.component.css']
})
export class UserNotFoundComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<UserNotFoundComponent>,
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    closeAll() {
        this.dialog.close();
        this.router.navigate(['/']);
    }
}
