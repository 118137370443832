<div class="content mt-5">
    <div class="row" *ngIf="pdv">
        <div class="col-lg-3" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
            <a (click)="navigate('convergent')" class="btn btn-black-outline d-block w-100 mb-1 pt-3 pb-3">
                Fibra y móvil
            </a>
        </div>

        <div class="col-lg-3">
            <a (click)="navigate('mobile')" class="btn btn-black-outline d-block w-100 mb-1 pt-3 pb-3">
                Móvil
            </a>
        </div>

        <div class="col-lg-3" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
            <a (click)="navigate('broadband')" class="btn btn-black-outline d-block w-100 mb-1 pt-3 pb-3">
                Fibra
            </a>
        </div>

        <div class="col-lg-3">
            <a (click)="navigate('list')" class="btn btn-black-outline d-block w-100 mb-1 pt-3 pb-3">
                Listado de activaciones
            </a>
        </div>
    </div>
</div>
