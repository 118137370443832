<hr *ngIf="lineNumber > 0">
<!-- RESUMEN MOVIL PACK -->
<h5  class="tar-name-7 mt-2 ms-3">
    <b>{{title}}</b>
</h5>

<div class="resumen d-flex justify-content-between align-items-center">
    <span class="sidebar-info4 simyo">
        Conceptos
    </span>
    <small class="text-muted"></small>
</div>
<div class="resumen d-flex justify-content-between align-items-center" *ngIf="line && line !== ''">
    <!-- MSISDN -->
    <span class="sidebar-info5">
        <img src="./assets/img/phone.svg" class="img-fluid me-1" width="13">
        <span>{{ getAltaName() + line }}</span>
    </span>
</div>
<div class="resumen d-flex justify-content-between align-items-center">
    <!-- tarifa -->
    <span class="sidebar-info5 sidebar-flex" style="max-width: none !important;">
        <img src="./assets/img/package2.svg" class="img-fluid mt-1 me-1" width="13">
        <span [innerHTML]="welcomePackSelected?.name + ' - ' + rate?.name"></span>
    </span>
    <small class="text-muted">
        <!-- Precio tarifa -->
        <span class="sidebar-info3 custom-font">
            <b>{{ (+rate?.price).toFixed(2) }} €</b>
        </span>
    </small>
</div>
