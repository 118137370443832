import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { interval, Observable, Subscription } from 'rxjs';
import { startWith, take } from 'rxjs/operators';
import { BonosAdicionalesOrderResponse } from 'src/app/orange/models/bonosAdicionalesInfoResponse';
import { CreateBonosAdicionalesResponse } from 'src/app/orange/models/createBonosAdicionales';
import { AppState } from 'src/app/orange/redux';
import { IOrangeAdditionalBonosState } from 'src/app/orange/redux/orange.state';
import { MsisdnBalanceResponse } from 'src/app/orange/models/msisdnBalanceResponse';
import { MsisdnInfo } from 'src/app/orange/models/msisdnInfoResponse';
import { resetState, setOrderInfo, updateBalanceMsisdn } from 'src/app/orange/redux/orange.actions';
import { OrangeBonosHiringService } from 'src/app/orange/services/orange-bonos-hiring.service';
import { superStatus } from 'src/app/shared/constantes';
import { Ipdv } from 'src/app/shared/models/pdvResponse';

@Component({
    selector: 'app-orange-bonos-hiring-confirmation',
    templateUrl: './orange-bonos-hiring-confirmation.component.html',
    styleUrls: ['./orange-bonos-hiring-confirmation.component.css', '../../../../../assets/css/orange-theme.css']
})
export class OrangeBonosHiringConfirmationComponent implements OnInit, OnDestroy {
    @Input() formGroup: FormGroup;
    @Input() pdv: Ipdv;
    @Output() isEditable = new EventEmitter<boolean>();

    public showProgressBar: boolean = false;
    public showErrorOrder: boolean = false;
    public showCreateButton: boolean = true;
    public showErrorOrderMsg: string = '';
    public today: Date = new Date();
    public orange$: Observable<IOrangeAdditionalBonosState>;
    public checkStatusSubscription: Subscription;
    public superStatus = superStatus;
    public progressBarTimer;
    public loadingBalance: boolean = false;
    public errorRequestBalance: boolean = false;

    private numConsecutiveErrorRequest = 0;
    private MAX_CONSECUTIVE_ERROR_REQUEST = 3;
    private PROGRESS_BAR_TIME = 90;
    private TIME_BETWEEN_REQUEST = 2500;
    private CHECK_STATUS_TIME = 30;

    constructor(
        private orangeBonosHiringService: OrangeBonosHiringService,
        private orangeStore: Store<AppState>,
    ) {
        this.orange$ = orangeStore.pipe(select('orangeAdditionalBonos'));
    }

    ngOnInit(): void {
    }

    public create() {
        this.loadingBalance = true;
        this.errorRequestBalance = false;
        this.orangeBonosHiringService.getMsisdnBalanceFromService(this.formGroup.get('msisdn').value)
            .subscribe((response: MsisdnBalanceResponse) => {
                if (response?.msg) {
                    let valueCustomerInfo: MsisdnInfo = JSON.parse(JSON.stringify(this.formGroup.get('customer_info')?.value));
                    valueCustomerInfo.balance = response.msg.balance;
                    this.formGroup.get('customer_info').patchValue(valueCustomerInfo);
                    this.orangeStore.dispatch(updateBalanceMsisdn({balance: response.msg.balance.toString()}));
                }
                this.isCorrectBalance();
                this.createOrder();
                this.loadingBalance = false;
            }, (error: HttpErrorResponse) => {
                this.isCorrectBalance();
                this.createOrder();
                this.errorRequestBalance = true;
                this.loadingBalance = false;
            })
    }

    private createOrder() {
        if (this.formGroup.valid) {
            this.showProgressBar = true;
            this.clearTimerProgressBar();
            this.initTimerProgressBar();
            this.showErrorOrder = false;
            this.showCreateButton = false;
            this.orangeBonosHiringService.createBonosOrder({
                    msisdn: this.formGroup.get('msisdn').value,
                    first_name: this.formGroup.get('customer_info').value?.first_name,
                    last_name: this.formGroup.get('customer_info').value?.last_name,
                    document_type_id: this.formGroup.get('customer_info').value?.document_type_id,
                    document_number: this.formGroup.get('customer_info').value?.document_number,
                    bono_id: this.formGroup.get('rate').value?.bono_id,
                    contracted_price: this.formGroup.get('rate').value?.price,
                    activation_sfid: this.pdv?.sfids?.orange
                })
                .subscribe((response: CreateBonosAdicionalesResponse) => {
                    if (response?.msg) {
                        if (response?.msg?.order_bono_id && response?.msg?.order_bono_id !== '') {
                            this.checkStatus(response?.msg?.order_bono_id);
                            return;
                        }
                    }
                    if (response?.error?.error_code.toString().includes('E')) {
                        this.showErrorOrderMsg = response.error.msg + ' ('+ response?.error?.error_code.toString() + ')';
                    }
                    this.showErrorOrder = true;
                    this.notShowProgressBar();
                }, (error: HttpErrorResponse) => {
                    this.showErrorOrder = true;
                    this.notShowProgressBar();
                    this.isEditable.emit(false);
                }, () => {
                    this.isEditable.emit(false);
                })
        }
    }

    private checkStatus(orderBonoId) {
        this.checkStatusSubscription = interval(this.TIME_BETWEEN_REQUEST)
            .pipe(startWith(0))
            .pipe(take((this.PROGRESS_BAR_TIME / (this.TIME_BETWEEN_REQUEST / 1000)) + (this.CHECK_STATUS_TIME / (this.TIME_BETWEEN_REQUEST / 1000))))
            .subscribe(val => {
                if (orderBonoId && orderBonoId !== '') {
                    this.orangeBonosHiringService.getOrderBonosPrepago(orderBonoId, true)
                        .subscribe((data: BonosAdicionalesOrderResponse) => {
                            if (data && data.msg) {
                                this.orangeStore.dispatch(setOrderInfo({order: data.msg}));
                                if (data.msg.exit_progress_bar) {
                                    this.notShowProgressBar();
                                }
                                if (data.msg.super_status === superStatus.active || data.msg.super_status === superStatus.error || data.msg.super_status === superStatus.cancelled) {
                                    this.unsubscribeCheckStatus();
                                }
                            };
                        }, (error: HttpErrorResponse) => {
                            this.evaluateStatusError();
                        });
                }
            });
    }

    private evaluateStatusError() {
        this.numConsecutiveErrorRequest++;
        if (this.numConsecutiveErrorRequest >= this.MAX_CONSECUTIVE_ERROR_REQUEST) {
            this.notShowProgressBar();
            this.showErrorOrder = true;
            this.showErrorOrderMsg = 'No se ha podido comprobar el estado de la tramitación.';
            this.unsubscribeCheckStatus();
        }
    }

    private initTimerProgressBar() {
        var timeleft = this.PROGRESS_BAR_TIME;
        let self = this;
        this.progressBarTimer = setInterval(function(){
            if (timeleft <= 0){
                clearInterval(self.progressBarTimer);
                self.showProgressBar = false;
            }
            timeleft -= 1;
        }, 1000);
    }

    private clearTimerProgressBar() {
        if (this.progressBarTimer) {
            clearInterval(this.progressBarTimer);
        }
    }

    private notShowProgressBar() {
        this.clearTimerProgressBar();
        this.showProgressBar = false;
    }

    private isCorrectBalance() {
        let haveEnoughBalance = +this.formGroup.get('customer_info')?.value?.balance > 0 && +this.formGroup.get('rate')?.value?.price <= +this.formGroup.get('customer_info')?.value?.balance
        this.formGroup.get('correctBalance').patchValue(haveEnoughBalance);
    }

    private unsubscribeCheckStatus() {
        if (this.checkStatusSubscription) {
            this.checkStatusSubscription.unsubscribe();
        }
    }

    ngOnDestroy() {
        this.orangeStore.dispatch(resetState());
        this.unsubscribeCheckStatus();
    }
}
