import {EventEmitter, Injectable} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';

@Injectable({
    providedIn: 'root'
})
export class OrangeShoppingCartService {
    private stepper = null;

    public focusMsisdn = new EventEmitter<number>();
    public removeMsisdn = new EventEmitter<number>();
    public hiddeShoppingCart = new EventEmitter<boolean>();
    public removeLine = new EventEmitter<number>();

    constructor() {
    }

    setStepper(stepper: MatStepper) {
        this.stepper = stepper;
    }

    getStepper() {
        return this.stepper;
    }
}
