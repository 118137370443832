<ng-container *ngIf="showView()">
    <section *ngIf="content === 'points'" #points class="points mt-3">
        <div class="row content-points">
            <ng-container >
                <div class="col-xl-12">
                    <div class="card--sidebar">
                        <div class="card--kpi">
                            <div class="content-kpi kpi-col" *ngIf="points$ | async as points">
                                <div class="box-kpi justify-content-center">
                                    <div class="kpi-title font-size-xxl">
                                        <img src="./assets/img/logo_atrevete.png">
                                    </div>
                                </div>
                                <div class="line first-line"></div>
                                <div class="box-kpi justify-content-center">
                                    <div class="kpi-title font-size-xl">
                                        <p>Puntos disponibles</p>
                                    </div>
                                    <div class="kpi-data font-size-xl">
                                        <p class="orange ms-0">
                                            <b>{{points.pointsAvailable}}</b>
                                        </p>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="box-kpi">
                                    <div class="kpi-title">
                                        <p>Conseguidos esta semana</p>
                                    </div>
                                    <div class="kpi-data">
                                        <div>
                                            <p class="orange ms-0 ">
                                                <b>{{points.pointsWonThisWeek}}</b>
                                            </p>
                                        </div>
                                        <div class="kpi-status">
                                            <p
                                                [ngClass]="{'kpi-per-positive2' : points.ratePointsWonLastWeek > 0, 'kpi-per-negative2': points.ratePointsWonLastWeek < 0, 'kpi-per-neutro2': points.ratePointsWonLastWeek === 0 }">
                                                {{ getAbsoluteValue(points.ratePointsWonLastWeek) }}% <span>vs anterior</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="box-kpi">
                                    <div class="kpi-title">
                                        <p>Conseguidos este mes</p>
                                    </div>
                                    <div class="kpi-data">
                                        <div>
                                            <p class="orange ms-0 ">
                                                <b>{{points.pointsWonThisMonth}}</b>
                                            </p>
                                        </div>
                                        <div class="kpi-status">
                                            <p
                                                [ngClass]="{'kpi-per-positive2' : points.ratePointsWonLastMonth > 0, 'kpi-per-negative2': points.ratePointsWonLastMonth < 0, 'kpi-per-neutro2': points.ratePointsWonLastMonth === 0 }">
                                                {{ getAbsoluteValue(points.ratePointsWonLastMonth) }}% <span>vs anterior</span>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div class="box-kpi btn">
                                    <div class="">
                                        <p class="btn-movements cursor-pointer"  (click)="changeContent('movements')">Ver movimientos</p>
                                    </div>
                                    <div class="">
                                        <p class="btn-movements cursor-pointer"  (click)="changeContent('orders')">Ver pedidos</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12"  *ngIf="(statusPoints$ | async) === 'loading'">
                                <div class="text-center" style="padding-bottom: 5px;" >
                                    <h5 class="tar-name-preload">
                                        <img src="./assets/img/spinner-home.svg" width="30" alt=""> Obteniendo puntos...
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="rewards$ | async as rewards">
                <div class="col-xl-12">
                    <h2 class="title">Premios disponibles</h2>
                </div>
                <div class="col-xl-12 rewards">
                    <div class="card-rewards" *ngFor="let reward of rewards">
                        <div class="img-amounts">
                            <div>
                                <img class="card-img" [src]="'./assets/img/rewards/tarjeta-regalo-'+ reward.image +'.png'">
                            </div>
                            <div>
                                <div class="amounts">
                                    <div class="amount cursor-pointer" *ngFor="let amount of reward.amountsAvailables"
                                     [ngClass]="{'active': amount.available}"
                                     (click)="navigateToCreateOrder(reward, amount)">
                                        <div class="tooltip-info">
                                            <p>{{amount.amount}}€ - <small>{{amount.points}} ptos</small></p>
                                            <span class="tooltip-info-text">{{amount.tooltip}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="col-xl-12"  *ngIf="(statusRewards$ | async) === 'loading'">
                <div class="text-center" style="padding-bottom: 5px;" >
                    <h5 class="tar-name-preload">
                        <img src="./assets/img/spinner-home.svg" width="30" alt=""> Obteniendo recompensas...
                    </h5>
                </div>
            </div>
            <div class="col-xl-12" *ngIf="(statusPoints$ | async) === 'error' || (statusRewards$ | async) === 'error'">
                <div class="card--sidebar">
                    <div class="text-center card-footer--sidebar-kpi1">
                        <h5 class="tar-name-preload" style="padding-bottom: 5px;">Se ha producido un error, inténtalo de nuevo más tarde</h5>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <app-points-movements *ngIf="content === 'movements'" (onGoBack)="changeContent('points')" [pdv]="pdv"></app-points-movements>
    <app-points-orders *ngIf="content === 'orders'" (onGoBack)="changeContent('points')"></app-points-orders>
</ng-container>
