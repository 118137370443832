<div class="modal-content--ads">
    <div class="d-flex justify-content-center">
        <button type="button" class="close mb-1" mat-dialog-close>
            <img src="./assets/img/x.svg">
        </button>

        <div style="">
            <img src="./assets/img/ads.png" width="800" class="img-fluid">
        </div>
    </div>
</div>
