import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SimyoResultadoService {
    private datosEdicion: any;

    constructor() {
        this.datosEdicion = new BehaviorSubject<any>(null);
    }

    getDataEdicion(){
        return this.datosEdicion.asObservable();
    }

    setDatosEdicion(valor){
        this.datosEdicion.next(valor);
    }
}
