import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RepublicaMovilService} from '../../services/republica-movil.service';
import {superStatus} from 'src/app/shared/constantes';
import {RmOrderService} from '../../services/rm-order.service';
import { DigoService } from 'src/app/services/digo.service';
import { UserNotFoundComponent } from 'src/app/shared/components/user-not-found/user-not-found.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/home/services/home.service';

@Component({
    selector: 'app-rm-order-detail',
    templateUrl: './rm-order-detail.component.html',
    styleUrls: ['./rm-order-detail.component.css', '../../../../assets/css/rm-theme.css']
})
export class RmOrderDetailComponent implements OnInit, OnDestroy {
    public order = null;
    public totalPrice = 0;
    public downloaded = {
        sepa: false,
        sepa_error: false,
        contract: false,
        contract_error: false
    };
    public uploadingFile = false;
    public pdv: any;
    public showErrorUploadFile: boolean = false;
    public orderNotFound: boolean = false;
    public superStatus: any = superStatus;
    public loadingCobertura: boolean = false;
    public dataOperation: any = null;
    private allSubscription: Subscription[] = [];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private rmService: RepublicaMovilService,
        private orderService: RmOrderService,
        private digoService: DigoService,
        private homeService: HomeService,
        public dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.orderService.getOrderFromService(this.activatedRoute.snapshot.paramMap.get('order_id'))
            .subscribe((data: any) => {
                this.orderNotFound = false;
                this.order = data.msg;
                this.updateTotalPrice();
            }, (error) => {
                if (error && error.error && error.error.error && error.error.error.errorCode !== null && (error.error.error.errorCode === 462 || error.error.error.errorCode === 1007)) {
                    this.dialog.open(UserNotFoundComponent, {
                        width: '350px',
                        disableClose: true,
                        data: {
                            marca: 'República móvil'
                          }
                    });
                } else {
                    this.orderNotFound = true;
                }
            });

        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: any) => {
                if (data != null) {
                    this.pdv = data;
                }
            })
        );

        this.allSubscription.push(this.homeService.getNavigateFromOperation()
            .subscribe((data: any) => {
                this.dataOperation = data;
            })
        );
    }

    priceWithIVA(price: number): number {
        return price * 1.21;
    }

    getRatePrice(rate: any): string {
        if (rate == null) {
            return '0.00';
        }

        let price = rate.rate_amount;

        if (rate.apply_ftth_discount != null && rate.apply_ftth_discount === true && rate.discount != null) {
            console.log(rate.rate_amount, rate.discount);
            price -= rate.discount;
        }

        return (price * 1.21).toFixed(2);
    }

    getConvergentPrice(order): string {
        if (order.broadband && order.broadband.rate && order.mobiles && order.mobiles.length && order.mobiles[0].rate) {
            let price = +order.broadband.rate.rate_amount - +order.broadband.rate.discount + +order.mobiles[0].rate.rate_amount;
            return (price * 1.21).toFixed(0);
        }
    }

    updateTotalPrice() {
        this.totalPrice = 0;

        if (this.order.broadband && this.order.broadband.rate) {
            this.totalPrice += parseFloat(this.order.broadband.rate.rate_amount);
        }

        if (this.order.mobiles.length > 0) {
            this.order.mobiles.forEach((mobile, index, array) => {
                if (mobile.rate.apply_ftth_discount != null && mobile.rate.apply_ftth_discount === true && mobile.rate.discount != null) {
                    this.totalPrice += parseFloat(mobile.rate.rate_amount) - parseFloat(mobile.rate.discount);
                } else if (
                    mobile.rate.apply_ftth_discount && mobile.rate.apply_ftth_discount && mobile.principal &&
                    this.order.broadband && this.order.broadband.rate && this.order.broadband.rate.discount !== ''
                ) {
                    this.totalPrice += parseFloat(mobile.rate.rate_amount) - parseFloat(this.order.broadband.rate.discount);
                } else {
                    this.totalPrice += parseFloat(mobile.rate.rate_amount);
                }
            });
        }
    }

    // Cajetines de descarga de documentación
    descargar(type, file?) {
        if (this.order) {
            if (type === 'sepa') {
                this.downloaded.sepa = true;
                this.downloaded.sepa_error = false;
                this.rmService.getSepa(this.order.order_id)
                    .subscribe(data => {
                        if (data) {
                            this.downloaded.sepa = false;
                            this.downlandFile(data, 'Sepa_' + this.order.order_id + '.pdf');
                        }
                    }, (error) => {
                        this.downloaded.sepa = false;
                        this.downloaded.sepa_error = true;
                    });
            }
            if (type === 'contrato') {
                this.downloaded.contract = true;
                this.downloaded.contract_error = false;
                this.rmService.getContract(this.order.order_id)
                    .subscribe(data => {
                        if (data) {
                            this.downloaded.contract = false;
                            this.downlandFile(data, 'Contrato_' + this.order.order_id + '.pdf');
                        }
                    }, (error) => {
                        this.downloaded.contract = false;
                        this.downloaded.contract_error = true;
                    });
            }
            if (type === 'other') {
                file.downloaded = true;
                this.rmService.getFileUpload(this.order.order_id, file.asset_id)
                    .subscribe(data => {
                        if (data) {
                            file.downloaded = false;
                            this.downlandFile(data, file.file_name);
                        }
                    });
            }
        }
    }

    downlandFile(file, name) {
        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    allDownloaded() {
        let allDownloaded = true;
        Object.values(this.downloaded).forEach(value => {
            if (!value) {
                allDownloaded = false;
            }
        });
        return allDownloaded;
    }

    uploadFile(file) {
        this.uploadingFile = true;
        this.showErrorUploadFile = false;
        this.rmService.uploadFile(file, this.order.order_id)
            .subscribe((data: any) => {
                if (data) {
                    this.order.document_uploads.push(data.msg);
                }
            }, (error) => {
                console.log(error);
                this.showErrorUploadFile = true;
                this.uploadingFile = false;
            }, () => {
                this.uploadingFile = false;
            });

    }

    dropFile(ev) {
        ev.preventDefault();
        if (ev.dataTransfer && ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
            this.uploadFile(ev.dataTransfer.files[0]);
        }
    }

    fileChanged(ev) {
        ev.preventDefault();
        if (ev.target && ev.target.files && ev.target.files.length > 0) {
            this.uploadFile(ev.target.files[0]);
        }
    }

    allowDrop(ev) {
        ev.preventDefault();
    }

    goBack() {
        this.dataOperation ? this.router.navigate(['/']) : this.router.navigate(['/republica-movil/listado']);
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }
}
