import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { fibraSpeeds, orderType } from 'src/app/shared/constantes';
import { SimyoService } from './simyo.service';
import { map, tap } from 'rxjs/operators';

export interface IshowOffer {
    index: any,
    status: boolean
}

@Injectable({
    providedIn: 'root'
})
export class SimyoRateService {

    private rates: any;
    private errorRate: any;
    private errorRateAdditional: any;
    private showRateOnOffer: any;

    constructor(
        protected http: HttpClient,
        private auth: AuthService,
        //private simyoService: SimyoService

    ) {
        this.rates = new BehaviorSubject<any>(null);
        this.errorRate = new BehaviorSubject(false);
        this.errorRateAdditional = new BehaviorSubject(false);
        this.showRateOnOffer = new BehaviorSubject<IshowOffer>({index: 0, status: true});
    }

    private getHeaders(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            'Content-Type': 'application/json'
        });
    }

    getRatesFromService(zip_code?) {
        return this.http.get(environment.simyoEndPoint + 'master/rates/matrix' + (zip_code ? ('?cp=' + zip_code) : ''), {headers: this.getHeaders()});
    }

    getRechargePrice(price): any {
        return Math.ceil(price/5)*5;
    }

    setRates(data) {
        this.rates.next(data);
    }

    getRates() {
        return this.rates.asObservable();
    }

    setErrorRate(data) {
        this.errorRate.next(data);
    }

    getErrorRate() {
        return this.errorRate.asObservable();
    }

    setErrorRateAdditional(data) {
        this.errorRateAdditional.next(data);
    }

    getErrorRateAdditional() {
        return this.errorRateAdditional.asObservable();
    }

    setShowRateOnOffer(data: IshowOffer) {
        this.showRateOnOffer.next(data);
    }

    getShowRateOnOffer() {
        return this.showRateOnOffer.asObservable();
    }

    isAdditional(index, customerInfo, typeOrder, order?, speed = '300'): boolean {
        if(typeOrder === orderType.BROADBAND) {
            return false;
        }
        if (!customerInfo && !speed) {
            return false;
        }
        if(customerInfo?.max_broadband == null && order?.broadband?.rate_id == null) {
            return false;
        }

        if (order && order.mobiles) {
            let indexMainLine = order.mobiles.findIndex(mobile => mobile.rate?.is_main_line && mobile.rate?.rate_info.available_in.includes(`ftth_${speed}`));
            if(indexMainLine < 0 && customerInfo && customerInfo.max_mobile) {
                return customerInfo.max_mobile.rate_info.available_in.includes(`ftth_${speed}`)
            }
            return indexMainLine >= 0 && indexMainLine !== index;
        }

        if (customerInfo && customerInfo.max_mobile) {
            return customerInfo.max_mobile.rate_info?.available_in?.includes(`ftth_${speed}`)
        }

        return false;
    }

    anyRateMobileForOffer(order): boolean {
        let result = false;
        if (order && order.mobiles && order.mobiles.length) {
            order.mobiles.map(mobile => {
                if (this.getPriceMobile(mobile) >= 7) {
                    result = true;
                }
            })
        }
        return result;
    }

    getPriceMobile(rateMobile: any): number {
        if (rateMobile && rateMobile.rate) {
            let priceBonos = 0;
            // if (rateMobile.rate.bonos && rateMobile.rate.bonos.length) {
            //     rateMobile.rate.bonos.forEach(x => {
            //         priceBonos += +x.rate_info.price;
            //     });
            // }
            return +rateMobile.rate.rate_info.price + priceBonos;
        }
        return 0;
    }
}
