export class DeliveryAddress {
    country_id: string;
    province_id: string;
    city: string;
    name: string;
    phone_number: string;
    post_code: string;
    street_type_id: string;
    street_name: string;
    street_number: string;
    staircase_number: string;
    block: string;
    floor_number: string;
    apartment_number: string;
    additional_info: string;
}