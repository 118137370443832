import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  @Output() onConfirm: EventEmitter<any> = new EventEmitter();
  @Input() text: string = '';
  @Input() title: string = '';

  constructor(protected modalService: NgbModal) { }

  ngOnInit(): void {
  }

  public confirm(value): void {        
    this.onConfirm.emit(value);
    this.modalService.dismissAll();
}
}
