import * as moment from 'moment/moment';
import { IRefillAmount } from '../orange/redux/orange.state';

export interface GenericList {
    id: string;
    label: string;
};

export const mobileContractType: { [key: string]: string } = {
    prepaid: 'prepaid',
    postpaid: 'postpaid'
}

export const orderType: { [key: string]: string } = {
    CONVERGENT: 'convergent',
    MOBILE: 'mobile',
    BROADBAND: 'broadband'
}

export const realTechnologyNames: { [key: string]: string } = {
    adsl: 'ADSL',
    ftth_dir: 'Fibra directa',
    ftth_ind: 'Fibra indirecta',
};

export const superStatus: { [key: string]: string } = {
    cancelled: 'cancelled',
    in_process: 'in_process',
    active: 'active',
    error: 'error',
    draft: 'draft',
    rejected: 'rejected'
}

export const superStatusTranslate: { [key: string]: string } = {
    cancelled: 'Cancelado',
    in_process: 'En progreso',
    active: 'Activado',
    error: 'Error de procesamiento',
    draft: 'Borrador',
    rejected: 'Rechazado'
}

export const brands: { [key: string]: string } = {
    amena: 'amena',
    simyo: 'simyo',
    rm: 'rm',
    orange: 'orange'
}

export const listType: GenericList[] = [
    { id: orderType.CONVERGENT, label: 'Fibra y móvil' },
    { id: orderType.BROADBAND, label: 'Solo fibra' },
    { id: orderType.MOBILE, label: 'Móvil' },
];

export const listTypeOperation: GenericList[] = [
    { id: 'broadband', label: 'Fibra' },
    { id: 'postpaid', label: 'Pospago' },
    { id: 'prepaid', label: 'Prepago' }
]

export const listSortByDate: GenericList[] = [
    { id: 'desc', label: 'Descendente' },
    { id: 'asc', label: 'Ascendente' }
];

export const typeFilterFormOperation: { [key: string]: string } = {
    totalActivationsMonth: 'totalActivationsMonth',
    totalActivationsFibra: 'totalActivationsFibra',
    totalActivationsPostpago: 'totalActivationsPostpago',
    totalActivationsPrepago: 'totalActivationsPrepago'
}

export const monthNames: string[] = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

export const tabHome: { [key: string]: string } = {
    CONTRATACIONES: 'contrataciones',
    COBERTURA: 'cobertura',
    OPERACIONES: 'operaciones',
    OPERACIONES_POSVENTA: 'operaciones-posventa',
    VENTAS: 'ventas',
    COMISIONES: 'comisiones',
    TARIFAS: 'tarifas',
    PUNTOS: 'puntos'
}

export const rateAvailableIn: { [key: string]: string } = {
    mobile: 'mobile',
    broadband_300: 'ftth_300',
    broadband_600: 'ftth_600',
    broadband_1: 'ftth_1',
    broadband_100: 'ftth_100'
}

export const rateFtthPrice: { [key: string]: string } = {
    broadband_300_price: 'ftth_300_price',
    broadband_600_price: 'ftth_600_price',
    broadband_1_price: 'ftth_1_price',
    broadband_100_price: 'ftth_100_price',
}

export const stateIccid: { [key: string]: string } = {
    valid: 'valid',
    used: 'used',
    invalid: 'invalid'
}

export const priceSwapSim: number = 5;
export const technologyTypes = {
    none: 'NINGUNO',
    dir: 'FIBRA DIR',
    ind_neba_rrmm: 'NEBA RRMM',
    ind_vula_rrmm: 'VULA RRMM',
    ind_neba: 'FIBRA NEBA',
    ind_vula: 'FIBRA VULA'
}

export const availableTechnologies: string[] = [technologyTypes.dir, technologyTypes.ind_vula_rrmm, technologyTypes.ind_neba_rrmm, technologyTypes.ind_vula, technologyTypes.ind_neba];
export const fiberIndirectTechnologies = [technologyTypes.ind_neba_rrmm, technologyTypes.ind_vula_rrmm, technologyTypes.ind_vula, technologyTypes.ind_neba];

export const iva: number = 1.21;

export const maxDate: Date = moment().subtract(18, 'years').toDate();
export const minDate: Date = moment("01/01/1900", "DD/MM/YYYY").toDate();

export const allLocalStorage: { [key: string]: string } = {
    accessToken: 'access_token',
    notificationHome: 'notification_home',
    promoSaveDate: 'promo_save_date',
    idSession: 'idSession',
    notificationTecalisCommissions: 'notification-tecalis' // Deprecated 01/02/2022
}

export const pdvAreas = {
    nuevosCanales: 2
}

// Notificaciones
export const notificationsName: { [key: string]: string } = {
    notSimyoAdicionales: 'simyo_adicionales',
    notAmenaClose: 'amena_close',
    notSimyoMR: 'simyo_mr',
    notSimyoFibra500: 'simyo_fibra_500',
    notJazztelOferta: 'jazztel_oferta_tactica',
    notJazztelProvision: 'jazztel_consulta_provision',
    notSimyoUpdateStandAlone: 'simyo_update_stand_alone',
    notJazztelRevista: 'jazztel_revista',
    notComercialJuly: 'comercial_july',
    notSimyoCommercial: 'simyo_commercial',
    notJazztelCommercial: 'jazztel_commercial',
    notOrangeCommercial: 'orange_commercial',

}

// Permisos
export const PDV_MULTIMARCA: string = 'multimarca';

// Simyo - Estado documentación
export const simyoDocStatus: { [key: string]: string } = {
    ok: 'Correcta',
    notSend: 'Sin enviar',
    ko: 'Documentación KO',
    pte: 'Pdte. revisión'
}

export const documentationTypes: { [key: string]: string } = {
    viewDocumentation: 'viewDocumentation',
    uploadDocumentation: 'uploadDocumentation'
}

export const documentTypes: { [key: string]: string } = {
    principal: 'main',
    autentication: 'kyc',
    attachments: 'attach'
}

export const translateSignType: { [key: string]: string } = {
    digital: 'Digital',
    manual: 'Manual',
    old: 'Manual',
    not_required: 'No requerida'
}

export const translateCategoryRappel = {
    BASICO: 'Básico',
    VALOR: 'Valor',
    TOP: 'Top',
    EXPERTO: 'Experto',
    PREMIUM: 'Premium'
}

// Orange - Prepago code
export const orangeWelcomePackCodes: { [key: string]: string } = {
    esim: 'esim'
}

export const orangePrepaidTypes = {
    esim: 'esim',
    portabilidad: 'portabilidad',
    terminal: 'terminal',
    alta: 'alta'
}

export const orangeRefillAmounts: IRefillAmount[] = [
    {
        id: 'recarga0',
        amount: 0,
        disabled: false
    }, {
        id: 'recarga5',
        amount: 5,
        disabled: true
    }, {
        id: 'recarga10',
        amount: 10,
        disabled: true
    }, {
        id: 'recarga15',
        amount: 15,
        disabled: true
    }, {
        id: 'recarga20',
        amount: 20,
        disabled: true
    }, {
        id: 'recarga25',
        amount: 25,
        disabled: true
    }, {
        id: 'custom',
        amount: 30,
        disabled: true
    }
]

export const fibraSpeeds = {
    speed_100: "100",
    speed_300: "300",
    speed_600: "600",
    speed_1gb: "1",
}

export const countriesAllowedWithPassport = [11, 17, 28, 34, 41, 52, 54, 55, 57, 64, 68, 69, 76, 80, 93, 94, 99, 101, 104, 115, 119, 120, 126, 131, 142, 153, 163, 164, 170, 171, 186, 188, 192, 197, 198, 216, 218, 223]

export const typeCard = {
    sim: 'sim',
    esim: 'esim'
}

export const broadbandTypeDiscount = {
    firstPackage: 'first_package',
    additionalPackage: 'additional_package'
}

export const discountWithoutFtth = {
    iva: 4.0,
    ipsi_8: 3.57,
    ipsi_10: 3.63,
    igic: 3.54
};

export const nameTaxType = {
    iva: 'IVA',
    ipsi_8: 'IPSI 8%',
    ipsi_10: 'IPSI 10%',
    igic: 'IGIC'
}



