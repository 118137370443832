import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {DigoService} from 'src/app/services/digo.service';
import {countriesAllowedWithPassport, fiberIndirectTechnologies, fibraSpeeds, mobileContractType, orderType, rateAvailableIn, technologyTypes} from 'src/app/shared/constantes';
import {Ipdv} from 'src/app/shared/models/pdvResponse';
import { allPermissions } from 'src/app/shared/permissions';
import {PermissionService} from 'src/app/shared/services/permission.service';
import {SimyoGlobals} from '../../classes/simyo-globals';
import {SimyoOrderService} from '../../services/simyo-order.service';
import {SimyoRateService} from '../../services/simyo-rate.service';
import {SimyoShoppingCartService} from '../../services/simyo-shopping-cart.service';
import {SimyoService} from '../../services/simyo.service';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';

@Component({
    selector: 'app-simyo-rate-additional',
    templateUrl: './simyo-rate-additional.component.html',
    styleUrls: ['./simyo-rate-additional.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoRateAdditionalComponent implements OnInit, OnDestroy {
    @Input() index = 0;
    @Input() fibraRate = '';
    @Input() customerFormGroup: FormGroup;
    @Input() clienteFormGroup: FormGroup;
    @Input() showTypeContent = '';

    @Output() onChangeRate = new EventEmitter<any>();

    @Input() set resultadoCobertura(val: FormGroup) {
        this.isNebaTechnology = (val?.get('resultadoConsultaCobertura')?.value?.technology && val?.get('resultadoConsultaCobertura')?.value?.technology.length && val?.get('resultadoConsultaCobertura')?.value?.technology[0] === technologyTypes.ind_neba && val?.get('resultadoConsultaCobertura')?.value?.commercial_deal === null);
    }

    @ViewChild('content') content: NgbModalRef;
    @ViewChild('modalFibraSpeed') modalFibraSpeed: NgbModalRef;
    @ViewChild('notAllowedPrepaid') notAllowedPrepaid: NgbModalRef;

    public typeOrder: any = null;
    public loaded: boolean = false;
    public showErrorRate: boolean = false;
    public allRates: any;
    public readyRates: any[] = [];
    //public bonosRates: any[] = [];
    public allFibraRates: any[] = [];
    public fibraRates: any[] = [];
    public movilRates: any[] = [];
    public diffVoice: any[] = [];
    public diffData: any[] = [];
    public showPostpaid: boolean = false;
    public showPrepaid: boolean = false;
    public customRate: any = {
        oldPrice: 0,
        price: 0,
        textFibra: '',
        textType: '',
        textRate: '',
        rates: {
            fibra: null,
            movil: null,
            // bonos: []
        }
    };
    public currentVoiceIndex = -1;
    public orderType = orderType;
    public ratesFormGroup: FormGroup;
    public pdv: Ipdv;
    public rechargePrice: any;
    public showRateOnOffer: boolean = true;
    public isStand: boolean = false;
    public mobileContractType = mobileContractType;
    public allPermissions = allPermissions;
    private allSubscription: Subscription[] = [];
    // Distribuidor CSQ
    public isCSQ: boolean = false;
    public csqMovilRates: any;
    public order;
    public isNebaTechnology: boolean = false;
    public fiberIndirectTechnologies = fiberIndirectTechnologies;
    public rateChangedTerminal = null;
    public plusFibraSpeed = 2
    public modalShowedOnFibra300 = false
    public modalShowedOnFibra600 = false

    broadbandSpeedSelected = '';
    constructor(
        private simyoService: SimyoService,
        private rateService: SimyoRateService,
        private shoppingCartService: SimyoShoppingCartService,
        private formBuilder: FormBuilder,
        private router: Router,
        private orderService: SimyoOrderService,
        private modalService: NgbModal,
        private digoService: DigoService,
        public simyoGlobals: SimyoGlobals,
        public permSv: PermissionService
    ) {
        this.ratesFormGroup = this.formBuilder.group({
            fibraRate: [this.fibraRate, Validators.required],
            dataRate: ['', Validators.required],
            voiceRate: ['', Validators.required],
            //bonosRate: new FormArray([]),
            movilType: ['', Validators.required]
        });
    }

    ngOnInit(): void {
        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: Ipdv) => {
                if (data) {
                    this.pdv = data;
                    this.isStand = !!Number(data.info?.isstand);
                    this.isCSQ = data?.info?.isCSQ;
                }
            })
        );

        this.allSubscription.push(this.rateService.getRates()
            .subscribe((data: any) => {
                if (data) {
                    this.allRates = data;
                    this.getDiffRates(this.allRates);
                    if (this.typeOrder) {
                        this.initForm();
                        this.getDiffMovilType(this.allRates);
                    }
                    this.loaded = true;
                }
            })
        );

        this.allSubscription.push(this.simyoService.getTypeOrder()
            .subscribe((data: any) => {
                this.typeOrder = data;

                if (this.allRates) {
                    this.showCorrectFibraRates();
                    this.initForm();
                    this.getDiffMovilType(this.allRates);
                }
            })
        );

        this.ratesFormGroup.valueChanges
            .subscribe((data: any) => {
                if (data) {
                    this.evaluateCustomRate(data);
                    this.evaluateActiveOptions(data);
                }
            });

        this.allSubscription.push(this.simyoService.getCustomerPromotion()
            .subscribe((data) => {
                this.showCorrectFibraRates();
                this.initForm();
                this.getDiffMovilType(this.allRates);
            })
        );

        this.allSubscription.push(this.orderService.getOrder()
            .subscribe((order) => {
                if (order) {
                    this.order = order;
                    if (order.broadband?.rate_info?.speed && !this.showErrorRate && !this.isNebaTechnology) {
                        this.ratesFormGroup.get('fibraRate').patchValue(order.broadband.rate_info.speed.toString());
                    }
                    if(this.allRates) this.getDiffRates(this.allRates);
                }
            })
        );

        this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data: any) => {
                if (data && this.index !== null && data.length && data[this.index] && data[this.index] && this.allRates) {
                    if (data[this.index].type_pay) {
                        this.ratesFormGroup.get('movilType').patchValue(data[this.index].type_pay);
                    }
                    this.setMobileRate(data[this.index]);
                    //this.setBonosRates(data[this.index].bonos);

                    // Mostrar método de pago
                    // Convergente o solo fibra
                    let showBankingData: boolean;
                    if (this.typeOrder === orderType.BROADBAND || this.typeOrder === orderType.CONVERGENT) {
                        showBankingData = true;
                        // Solo móvil
                    } else if (data && data.length) {
                        const found = data.find(mobile => mobile?.type_pay === mobileContractType.postpaid);
                        showBankingData = found ? true : false;
                    }

                    // Validadores de la forma de pago
                    if (showBankingData) {
                        this.clienteFormGroup.get('bank_account').setValidators([Validators.required, blankSpaceValidator()]);
                        this.clienteFormGroup.get('bank_account').updateValueAndValidity();
                    }
                    if (!showBankingData) {
                        this.clienteFormGroup.get('bank_account').clearValidators();
                        this.clienteFormGroup.get('bank_account').setValidators([]);
                        this.clienteFormGroup.get('bank_account').updateValueAndValidity();
                        this.clienteFormGroup.get('bank_account').patchValue('');
                    }
                }
            })
        );

        this.allSubscription.push(this.rateService.getErrorRateAdditional()
            .subscribe((data: boolean) => {
                this.showErrorRate = data;
            })
        );

        this.allSubscription.push(this.rateService.getShowRateOnOffer()
            .subscribe((data) => {
                if (data && data.index === this.index) {
                    this.showRateOnOffer = data.status;
                }
                if (this.isStand || this.isCSQ) {
                    this.showRateOnOffer = true;
                }
                if (this.typeOrder === orderType.CONVERGENT && this.index === 0) {
                    this.showRateOnOffer = false;
                }
            })
        );

        if (this.simyoService.getIsChanging()) {
            this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data: any) => {
                if (data && typeof data[this.index] !== 'undefined') {
                        this.rateChangedTerminal = data[this.index].terminal;
                    }
                })
            );
        }

    }

    getDiffMovilType(rates) {
        let type = rateAvailableIn.mobile;
        if (rates.RATES && rates.RATES.ADDITIONALS) {
            if (this.typeOrder === orderType.CONVERGENT && this.index === 0) {
                type = this.simyoGlobals.getAvailableIn(this.customRate.rates.fibra?.rate_info?.speed);
            }
            const thereIsPostpaid = this.getAuxRates(rates)
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids['postpaid'] !== ''
                )
                .map(rate => rate && rate['rate_ids'])
                .filter(rate_ids => rate_ids.postpaid !== '');

            const thereIsPrepaid = this.getAuxRates(rates)
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids['prepaid'] !== ''
                )
                .map(rate => rate && rate['rate_ids'])
                .filter(rate_ids => rate_ids.prepaid !== '');

            this.showPostpaid = thereIsPostpaid && thereIsPostpaid.length ? true : false;
            this.showPrepaid = thereIsPrepaid && thereIsPrepaid.length ? true : false;

            this.initMovilType();
        }
    }

    getDiffRates(rates) {
        // FIBRA
        if (rates.RATES && rates.RATES.FTTH_NEW) {
            this.allFibraRates = rates.RATES.FTTH_NEW;
        }
        // MOVILES
        if (rates.RATES && rates.RATES.ADDITIONALS) {
            const type = rateAvailableIn.mobile;
            const auxdiffData = [...new Set(this.getAuxRates(rates)
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesFormGroup.get('movilType').value] !== ''
                )
                .map(rate => rate && rate['data'])
                .filter(rate => rate))];

            this.diffData = auxdiffData
                .map(item => {
                    return {
                        isOffer: this.getOffer(this.getAuxRates(rates), 'data', item),
                        label: item,
                        disabled: false
                    };
                })
                .sort(this.compare);

            const auxDiffVoice = [...new Set(this.getAuxRates(rates)
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesFormGroup.get('movilType').value] !== ''
                )
                .map(rate => rate && rate['voice'])
                .filter(rate => rate))];

            // Ponemos el ultimo elemento del array al principio
            auxDiffVoice.splice(0, 0, auxDiffVoice.splice(auxDiffVoice.length - 1, 1)[0]);

            this.diffVoice = auxDiffVoice
                .map((item: any) => {
                    return {
                        isOffer: false,
                        label: item,
                        disabled: false
                    };
                })
                .sort(this.compare);

            this.movilRates = this.getAuxRates(rates);

            if (this.isCSQ) {
                this.csqMovilRates = this.movilRates.filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesFormGroup.get('movilType').value] !== ''
                );
            }
        }

        // BONOS
        // if (rates.BONOS) {
        //     this.bonosRates = rates.BONOS;
        //     this.bonosRates.map(x => (this.ratesFormGroup.get('bonosRate') as FormArray).push(new FormControl(false)));
        // }

        // Offer additional
        if (rates.RATES.ADDITIONALS) {
            //TODO SI LA TARIFA addicional es superior a la tarifa principal, marcarla como normal
            const maxPrice = this.orderService.calculateMaxPrice();
            let speed = this.simyoService.getMaxFtthSpeed();
            let _readyRates = rates.RATES.ADDITIONALS.filter(rate => rate.is_additional || rate.show_additional_for_stand);
            //Identificar cual es la principal, la del cliente o la del pedido
            const customerMaxMobile = this.simyoService.getMaxMobileCustomer();
            const mainLineIsCurrentOrder = this.simyoService.getIndexMainLine() > -1;

            _readyRates = _readyRates.map(rateAdd => {
                if((+rateAdd.rate_info.old_price > maxPrice
                    || (+rateAdd.rate_info.old_price == maxPrice && mainLineIsCurrentOrder == false && customerMaxMobile !== null && customerMaxMobile.is_additional == true))
                    && rateAdd.rate_ids.refered != '') {

                    let foundMobile = rates.RATES.MOBILE.find(rateMobile => rateMobile.rate_info.available_in.includes(`ftth_${speed}`) && (rateMobile.rate_ids.postpaid == rateAdd.rate_ids.refered || rateMobile.rate_ids.prepaid == rateAdd.rate_ids.refered));
                    if(foundMobile)
                        return foundMobile;
                }
                 return rateAdd;
            })
            this.readyRates = _readyRates
                .sort((a, b) => {
                    if (+a.rate_info.real_price < +b.rate_info.real_price) {
                        return -1;
                    } else if (+a.rate_info.real_price > +b.rate_info.real_price) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
        }
    }

    getOffer(rates, type, item): boolean {
        if (rates) {
            const found = rates.find(rate => rate[type] === item && rate.is_additional && rate.rate_info?.old_price !== '');
            if (found) {
                return true;
            }
        }
        return false;
    }

    initForm() {
        if (this.typeOrder === orderType.CONVERGENT && this.index === 0) {
            this.initFibraRate();
        }
        this.initMobileRate();
        this.evaluateCustomRate(this.ratesFormGroup.getRawValue());
        this.evaluateActiveOptions(this.ratesFormGroup.getRawValue());
    }

    // Inicializamos la tarifa por defecto de móvil
    initMobileRate() {
        // Tarifa por defecto
        if (this.movilRates && this.movilRates.length) {
            // Buscamos una por defecto
            let type = rateAvailableIn.mobile;
            if (this.typeOrder === orderType.CONVERGENT && this.index === 0) {
                type = rateAvailableIn.broadband_100;
            }
            const compatibleRateDefault = this.movilRates
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesFormGroup.get('movilType').value] !== ''
                );

            const rateDefault = compatibleRateDefault.find(x => x.default_selected);

            if (rateDefault) {
                this.setMobileRate(rateDefault);
            } else {
                // Seleccionamos una de las listas para llevar
                if (compatibleRateDefault && compatibleRateDefault.length) {
                    this.setMobileRate(compatibleRateDefault[compatibleRateDefault.length - 1]);
                }
            }
        }
        this.initMovilType();
    }

    // Inicializamos la tarifa por defecto de fibra
    initFibraRate() {
        if (this.fibraRates && this.fibraRates.length) {
            const rateDefault = this.fibraRates.find(x => {
                if (this.fibraRate && this.fibraRate != '') {
                    return x.rate_info.speed === this.fibraRate;
                }

                return x.default_selected;
            });

            if (rateDefault) {
                this.ratesFormGroup.get('fibraRate').patchValue(rateDefault.rate_info.speed.toString());
            } else {
                this.ratesFormGroup.get('fibraRate').patchValue(fibraSpeeds.speed_1gb);
            }
        }
    }

    initMovilType() {
        if (!this.showPostpaid && this.showPrepaid) {
            this.ratesFormGroup.get('movilType').patchValue(mobileContractType.prepaid);
        }

        if (this.showPostpaid && !this.showPrepaid) {
            this.ratesFormGroup.get('movilType').patchValue(mobileContractType.postpaid);
        }

        if (this.showPostpaid && this.showPrepaid) {
            this.ratesFormGroup.get('movilType').patchValue('');
        }

        if (!this.showPostpaid && !this.showPrepaid) {
            this.ratesFormGroup.get('movilType').patchValue('');
        }

        if (this.isCSQ) {
            this.ratesFormGroup.get('movilType').patchValue(mobileContractType.postpaid);
        }

        // Flujo movil con pasaporte solo se muestra prepago
        if (this.typeOrder == orderType.MOBILE && +this.customerFormGroup?.get('customer_doctype')?.value === 3 && !countriesAllowedWithPassport.includes(parseInt(this.customerFormGroup.value.country))) {
            this.showPostpaid = false;
            this.ratesFormGroup.get('movilType').patchValue(mobileContractType.prepaid);
        }

    }

    // setBonosRates(rateBonos) {
    //     rateBonos.map(item => {
    //         (this.ratesFormGroup.get('bonosRate') as FormArray).at(this.bonosRates.findIndex(x => x.name === item.name)).patchValue(true);
    //         this.addBonoToCustomRate(item, true);
    //     });
    // }

    // Funcion que se lanza al seleccionar una tarifa en cualquiera de los 3 flujos
    selectRate(rate, isReadyToBuy) {
        if(this.ratesFormGroup.get('movilType').value === mobileContractType.prepaid && this.disableIfOnlyBroadband100() == true) {
            if(this.notAllowedPrepaid) {
                this.modalService.open(this.notAllowedPrepaid, {size: 'md', centered: true, backdrop: 'static', keyboard: false});
            }
            return;
        }
        const newRate = JSON.parse(JSON.stringify(rate));
        this.rateService.setErrorRateAdditional(false);
        if (this.typeOrder === orderType.CONVERGENT && this.index === 0) {
            newRate.rates.movil.type_pay = this.ratesFormGroup.get('movilType').value;
            this.simyoService.setRateFtth(this.updateNewRate(newRate.rates.fibra));
            //this.simyoService.setRatesMobile(this.index, {movil: newRate.rates.movil, bonos: newRate.rates.bonos, terminal: this.rateChangedTerminal}, this.order);
            this.simyoService.setRatesMobile(this.index, {...newRate.rates.movil, terminal: this.rateChangedTerminal}, this.order);
            this.shoppingCartService.getStepper().next();
        } else {
            if (isReadyToBuy) {
                newRate.type_pay = this.ratesFormGroup.get('movilType').value;
                //this.simyoService.setRatesMobile(this.index, {...newRate, bonos: [], terminal: this.rateChangedTerminal}, this.order);
                this.simyoService.setRatesMobile(this.index, {...newRate, terminal: this.rateChangedTerminal}, this.order);
            } else {
                newRate.rates.movil.type_pay = this.ratesFormGroup.get('movilType').value;
                //this.simyoService.setRatesMobile(this.index, {...newRate.rates.movil, bonos: newRate.rates.bonos, terminal: this.rateChangedTerminal}, this.order);
                this.simyoService.setRatesMobile(this.index, {...newRate.rates.movil, terminal: this.rateChangedTerminal}, this.order);
            }
        }
        if (this.typeOrder === orderType.CONVERGENT && this.ratesFormGroup.get('movilType').value === mobileContractType.prepaid) {
            this.rechargePrice = this.rateService.getRechargePrice(+newRate.rates.movil.rate_info.real_price);// + this.simyoGlobals.pricesBonos(newRate.rates.bonos));
            if (this.rechargePrice > 0) {
                this.modalService.open(this.content, {size: 'md', centered: true, backdrop: 'static', keyboard: false});
            }
        }

        if (this.typeOrder === orderType.MOBILE && this.ratesFormGroup.get('movilType').value === mobileContractType.prepaid) {
            this.rechargePrice = isReadyToBuy ? this.rateService.getRechargePrice(+newRate.rate_info.real_price) : this.rateService.getRechargePrice((+newRate.rates.movil.rate_info.real_price));// + this.simyoGlobals.pricesBonos(newRate.rates.bonos)));
            if (this.rechargePrice > 0) {
                this.modalService.open(this.content, {size: 'md', centered: true, backdrop: 'static', keyboard: false});
            }
        }
        this.simyoService.setIsChanging(false);
    }

    updateNewRate(fibra, movil?){
        fibra.rate_id = fibra.type_normal.rate_id;
        fibra.rate_info.price = fibra.type_normal.price;
        if(this.simyoService.getShowFtthReduced() == false) return fibra;
        if (!movil) {
            fibra.rate_id = fibra.type_discount.rate_id;
            fibra.rate_info.price = fibra.type_discount.price;
        }
        if ( movil) {
            fibra.rate_id = fibra.type_discount.rate_id;
            fibra.rate_info.price = fibra.type_discount.price;
        }
        return fibra;
    }

    // Funcion que se llama para gestionar la informacion que se le pasa de la tarifa personalizada
    evaluateCustomRate(data) {
        this.customRate.oldPrice = 0;
        this.customRate.price = 0;
        this.customRate.textRate = '';
        this.customRate.textFibra = '';
        this.customRate.textType = '';
        let auxText = [];
        // Fibra
        if (data.fibraRate && data.fibraRate !== '' && this.index === 0) {
            var foundRate = this.fibraRates.find(rate => rate.rate_info.speed === data.fibraRate);
            if (foundRate) {
                if (this.typeOrder === orderType.BROADBAND) {
                        foundRate.rate_info.price = foundRate.type_discount.price;
                        foundRate.rate_id = foundRate.type_discount.rate_id;
                }

                const found = foundRate;
                this.customRate.price += +found.rate_info.price.replace(/,/g, '.');
                this.customRate.textFibra = found.rate_info.speed + ' Mb';
                if (found.rate_info.speed < 100) {
                    this.customRate.textFibra = found.rate_info.speed + ' Gb';
                }
                this.customRate.rates.fibra = found;
            }
        }
        // Tarifa movil
        // NO quitar !== null que hace validaciones con el 0
        if (data.dataRate !== null && data.dataRate !== '' && data.voiceRate !== null && data.voiceRate !== '' && this.diffVoice[+data.voiceRate] && this.diffData[+data.dataRate]) {
            const found = this.movilRates.filter(
                rate => rate.voice === this.diffVoice[+data.voiceRate].label &&
                    rate.data === this.diffData[+data.dataRate].label &&
                    rate.rate_ids[this.ratesFormGroup.get('movilType').value] !== ''
            );

            if (found && found.length) {
                let auxFound: any;
                if (found.length > 1) {
                    if (this.customRate.rates.fibra) {
                        auxFound = found.find(rate => rate.rate_info.available_in.includes(this.simyoGlobals.getAvailableIn(this.customRate.rates.fibra.rate_info.speed)));
                    } else {
                        auxFound = found.find(rate => rate.rate_info.available_in.includes(rateAvailableIn.mobile));
                    }
                } else {
                    auxFound = found[0];
                }
                let priceconvergente = '';
                if (this.customRate.rates.fibra) {
                    priceconvergente = this.simyoGlobals.getFtthPrice(this.customRate.rates.fibra.rate_info.speed);
                    if (auxFound.rate_info[priceconvergente] && auxFound.rate_info[priceconvergente] !== '') {
                        this.customRate.price = 0;
                        this.customRate.price += +auxFound.rate_info[priceconvergente];
                    }
                } else {
                    this.customRate.price += +auxFound.rate_info.real_price.replace(/,/g, '.');
                }

                // this.customRate.price += +auxFound.rate_info.price.replace(/,/g, '.');
                this.customRate.oldPrice = auxFound.is_additional && !this.customRate.rates.fibra && this.ratesFormGroup.get('movilType').value === mobileContractType.postpaid ? +auxFound.rate_info.old_price.replace(/,/g, '.') : 0;

                auxText.push(auxFound.data, auxFound.voice);
                this.customRate.rates.movil = auxFound;
            }
        }
        // Bonos
        // if (this.customRate.rates.bonos && this.customRate.rates.bonos.length) {
        //     this.customRate.rates.bonos.forEach(bono => {
        //         auxText.push(bono.rate_info.description);
        //         this.customRate.price += +bono.rate_info.price;
        //     });
        // }

        // Tipo de línea móvil
        if (data.movilType && data.movilType !== '' && data.movilType !== '0') {
            this.customRate.textType = data.movilType === mobileContractType.prepaid ? 'Prepago' : 'Pospago';
        }

        if (auxText && auxText.length) {
            this.customRate.textRate = auxText.join(' + ');
        }
    }

    // Funcion para añadir un bono a la tarifa
    // addBonoToCustomRate(rate, state) {
    //     if (state) {
    //         // Añadimos el bono si no está añadido
    //         const found = this.customRate.rates.bonos.find(x => rate.name === x.name);
    //         if (!found) {
    //             this.customRate.rates.bonos.push(rate);
    //         }
    //     } else {
    //         // Eliminamos ese bono de la tarifa
    //         if (this.customRate.rates.bonos && this.customRate.rates.bonos.length) {
    //             const found = this.customRate.rates.bonos.findIndex(x => x.rate_ids.postpaid === rate.rate_ids.postpaid);
    //             if (found !== -1) {
    //                 this.customRate.rates.bonos.splice(found, 1);
    //             }
    //         }
    //     }
    //     if (this.customRate.rates.bonos && this.customRate.rates.bonos.length) {
    //         this.customRate.rates.bonos.map(bono => {
    //             this.customRate.textRate += ' + ' + bono.rate_info.description;
    //             this.customRate.price += +bono.rate_info.price;
    //         });
    //     }
    //     this.evaluateCustomRate(this.ratesFormGroup.getRawValue());
    // }

    // Funcion para mostrar que opciones de la configuracion están disponibles
    evaluateActiveOptions(data) {
        if (this.isCSQ) {
            return;
        }
        let type = rateAvailableIn.mobile;
        if (this.customRate.rates.fibra) {
            type = this.simyoGlobals.getAvailableIn(this.customRate.rates.fibra.rate_info.speed);
        }

        if (data.dataRate !== null && data.dataRate !== '' && this.diffData[+data.dataRate]) {
            // Obtenemos la tarifa de voz disponible para ese data
            const aux = this.movilRates
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesFormGroup.get('movilType').value] !== '' &&
                    rate.data === this.diffData[+data.dataRate].label
                );

            // Mostramos como deshabilitado si no lo encontramos
            this.diffVoice = this.movilRates
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesFormGroup.get('movilType').value] !== ''
                )
                .map(item => {
                    return {
                        isOffer: aux.find(rate => rate.is_additional && rate.voice === item.voice && rate.rate_info?.old_price !== '') ? true : false,
                        label: item.voice,
                        disabled: aux.find(rate => rate.voice === item.voice) ? false : true
                    };
                })
                .reduce(this.filterByValue, [])
                .sort(this.compare);

            if (aux && aux.length === 0) {
                this.initMobileRate();
                return;
            }

            if (!this.diffVoice[this.currentVoiceIndex] || this.diffVoice[this.currentVoiceIndex].disabled) {
                let allIndex = this.diffVoice.map((x, i) => x.disabled === false ? i : '').filter(String);
                if (allIndex && allIndex.length) {
                    this.currentVoiceIndex = +allIndex[allIndex.length - 1];
                }
                // this.currentVoiceIndex = this.diffVoice.findIndex(x => x.disabled === false);
                this.ratesFormGroup.get('voiceRate').patchValue(this.currentVoiceIndex);
            }
        }

        if (data.voiceRate !== null && data.voiceRate !== '') {
            // Obtenemos la tarifa de voz disponible para ese data
            // Mostramos como deshabilitado si no lo encontramos
            this.diffData = this.movilRates
                .filter(rate =>
                    rate.rate_info.available_in.includes(type) &&
                    rate.rate_ids[this.ratesFormGroup.get('movilType').value] !== ''
                )
                .map(item => {
                    return {
                        isOffer: item.is_additional && item.rate_info?.old_price !== '',
                        label: item.data,
                        disabled: false
                    };
                })
                .reduce(this.filterByValue, [])
                .sort(this.compare);
        }
    }

    filterByValue(accumulator, current) {
        const x = accumulator.find(item => item.label === current.label);

        if (!x) {
            return accumulator.concat([current]);
        } else {
            if (current.isOffer) {
                const foundIndex = accumulator.findIndex(item => item.label === current.label);
                if (foundIndex !== -1) {
                    accumulator[foundIndex].isOffer = current.isOffer;
                }
            }
            return accumulator;
        }
    }

    compare(a, b) {
        let labelA = parseInt(a.label.replace('MIN', '').replace('MB', '').replace('GB', ''), 10);
        let labelB = parseInt(b.label.replace('MIN', '').replace('MB', '').replace('GB', ''), 10);
        labelA = isNaN(labelA) ? 1 : labelA;
        labelB = isNaN(labelB) ? 1 : labelB;

        if (a.label.includes('ILIMIT')) {
            labelA = labelA * 1000000;
        } else if (a.label.includes('GB')) {
            labelA = labelA * 100;
        }

        if (b.label.includes('ILIMIT')) {
            labelB = labelB * 1000000;
        } else if (b.label.includes('GB')) {
            labelB = labelB * 100;
        }

        if (labelA < labelB) {
            return -1;
        }

        if (labelA > labelB) {
            return 1;
        }

        return 0;
    }

    // Funcion que evalua si la tarifa de fibra es correcta dependiendo si es cliente o no
    showCorrectFibraRates() {
        this.fibraRates = this.allFibraRates.filter(x => {
            if(this.simyoService.getCustomer() != null && this.simyoService.getCustomer().num_ftth.total_ftth >= 3 && x.rate_info.speed == fibraSpeeds.speed_100) return false;
            return true;
        }).sort((a, b) => {
            if (a?.rate_info?.speed === '1') {
                return 1;
            }
            if (b?.rate_info?.speed === '1') {
                return -1;
            }
            if (a?.rate_info?.speed < b?.rate_info?.speed) {
                return -1;
            }
            if (a?.rate_info?.speed > b?.rate_info?.speed) {
                return 1;
            }
            return 0;
        });
        // Con fibra neba
        // if (this.isNebaTechnology) {
        //     this.fibraRates = this.fibraRates.filter(fibraRate => +fibraRate.rate_info.speed === 300 || +fibraRate.rate_info.speed === 500);
        //     this.ratesFormGroup.get('fibraRate').patchValue(this.fibraRates[0].rate_id.toString());
        // }
    }

    // Funcion que se llama al cambiar los minutos de las llamadas
    changeVoice(newData) {
        if (newData && newData !== '') {
            if (!this.diffVoice[newData].disabled) {
                this.currentVoiceIndex = newData;
                this.evaluateActiveOptions(this.ratesFormGroup.getRawValue());
                this.setNewRate(this.diffData, this.ratesFormGroup.get('dataRate').value, 'dataRate');
            } else {
                this.ratesFormGroup.get('voiceRate').patchValue(this.currentVoiceIndex);
            }
        }
    }

    // Funcion que se llama al cambiar los datos del movil
    changeData(newData) {
        if (newData && newData !== '') {
            this.evaluateActiveOptions(this.ratesFormGroup.getRawValue());
            this.setNewRate(this.diffVoice, this.ratesFormGroup.get('voiceRate').value, 'voiceRate');
        }
    }

    isAvailableRate(fibra?) {
        if (fibra) {
            this.onChangeRate.emit(fibra);

            if (fibra.rate_info.speed != fibraSpeeds.speed_1gb) {
                this.showFibraModal(fibra.rate_info.speed)
            }
            this.broadbandSpeedSelected = fibra.rate_info.speed;
        }

        this.initMobileRate();
    }

    showFibraModal(speed) {
        if (speed === fibraSpeeds.speed_300 && this.modalShowedOnFibra300) {
            return
        }

        if (speed === fibraSpeeds.speed_600 && this.modalShowedOnFibra600) {
            return
        }

        this.modalShowedOnFibra300 = this.modalShowedOnFibra300 || speed === fibraSpeeds.speed_300;
        this.modalShowedOnFibra600 = this.modalShowedOnFibra600 || speed === fibraSpeeds.speed_600;
        if (this.simyoService.getTaxType() === 'iva') {
            this.plusFibraSpeed = speed === fibraSpeeds.speed_300 ? 4 : 2;
        }
        else{
            this.plusFibraSpeed = speed === fibraSpeeds.speed_300 ? 3.54 : 1.77;
        }
        //TODO igic precio isic_8
        //this.modalService.open(this.modalFibraSpeed, {size: 'md', centered: true, backdrop: 'static', keyboard: false});
    }

    setNewRate(data, actualValue, formName) {
        if (data[+actualValue].disabled) {
            const found = data.findIndex(item => !item.disabled);
            if (found !== -1) {
                this.ratesFormGroup.get(formName).patchValue(found);
            }
        }
    }

    setMobileRate(rateMobile) {
        this.currentVoiceIndex = this.diffVoice.findIndex(x => x.label === rateMobile.voice);
        this.ratesFormGroup.get('dataRate').patchValue(this.diffData.findIndex(x => x.label === rateMobile.data));
        this.ratesFormGroup.get('voiceRate').patchValue(this.diffVoice.findIndex(x => x.label === rateMobile.voice));
    }

    clearValidations(nameForm: string) {
        this.ratesFormGroup.get(nameForm).clearValidators();
        this.ratesFormGroup.get(nameForm).setValidators([]);
        this.ratesFormGroup.get(nameForm).updateValueAndValidity();
    }

    showRateConfigurator() {
        this.showRateOnOffer = false;
    }

    changeMovilType() {
        if (this.typeOrder === orderType.CONVERGENT && this.index === 0) {
            this.showRateOnOffer = false;
        }

        /*setTimeout(() => {
            // Tarifa por defecto
            if (this.movilRates && this.movilRates.length) {
                // Buscamos una por defecto
                let type = rateAvailableIn.mobile;
                if (this.customRate.rates.fibra) {
                    type = this.simyoGlobals.getAvailableIn(this.customRate.rates.fibra.rate_info.speed);
                }
                const compatibleRateDefault = this.movilRates
                    .filter(rate =>
                        rate.rate_info.available_in.includes(type) &&
                        rate.rate_ids[this.ratesFormGroup.get('movilType').value] !== ''
                    );

                const rateDefault = compatibleRateDefault.find(x => x.default_selected);

                if (rateDefault) {
                    this.setMobileRate(rateDefault);
                } else {
                    // Seleccionamos una de las listas para llevar
                    if (compatibleRateDefault && compatibleRateDefault.length) {
                        this.setMobileRate(compatibleRateDefault[compatibleRateDefault.length - 1]);
                    }
                }
            }
            this.evaluateCustomRate(this.ratesFormGroup.getRawValue());
            this.evaluateActiveOptions(this.ratesFormGroup.getRawValue());
        });*/
    }

    getAuxRates(rates) {

        if (this.isCSQ) {
            let auxRates = rates.RATES.MOBILE;
            auxRates = auxRates.filter(rate => !rates.RATES.ADDITIONALS.some(rateAdd => rate.data === rateAdd.data
                && rate.contract_type === rateAdd.contract_type
                && rate.voice === rateAdd.voice
            ));
            auxRates.map(rate => {
                rate.rate_ids.postpaid = '';
                rate.isPrepaid = rate.rate_ids.prepaid !== '';
            });
            return rates.RATES.ADDITIONALS.concat(auxRates);
        } else {
            let speed = this.simyoService.getMaxFtthSpeed();
            const maxPrice = this.orderService.calculateMaxPrice();
            //Identificar cual es la principal, la del cliente o la del pedido
            const customerMaxMobile = this.simyoService.getMaxMobileCustomer();
            const mainLineIsCurrentOrder = this.simyoService.getIndexMainLine() > -1;

            return rates.RATES.ADDITIONALS.map(rateAdd => {
                if((+rateAdd.rate_info.old_price > maxPrice
                    || (+rateAdd.rate_info.old_price == maxPrice && mainLineIsCurrentOrder == false && customerMaxMobile !== null && customerMaxMobile.is_additional == true))
                    && rateAdd.rate_ids.refered != '') {
                    let foundMobile = rates.RATES.MOBILE.find(rateMobile => rateMobile.rate_info.available_in.includes(`ftth_${speed}`) && (rateMobile.rate_ids.postpaid == rateAdd.rate_ids.refered || rateMobile.rate_ids.prepaid == rateAdd.rate_ids.refered));
                    /**
                     * Si encontramos el movil en las tarifas normales, comprobar lo siguient:
                     * La tarifa maxima es la que traia el cliente o una del order
                     * Si es la del cliente comprobar si es adicional, si es asi mostrar a precio normal, ya que la que trae es a precio reducido
                     */
                    if(foundMobile)
                        return foundMobile;
                }
                 return rateAdd;
            });
        }
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    checkPermissions(type) {
        if(this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_prepaid_postpaid)) {
            return true;
        }
        if(type === 'postpaid' && this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_postpaid)) {
            if(this.ratesFormGroup.get('movilType').value !== type) {
                this.ratesFormGroup.patchValue({'movilType': type})
                this.changeMovilType();
            }

            return true;
        }

        if(type === 'prepaid' && this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_prepaid)) {
            if(this.ratesFormGroup.get('movilType').value !== type) {
                this.ratesFormGroup.patchValue({'movilType': type})
                this.changeMovilType();
            }
            return true;
        }

        return false;
    }

    calculatePrice(customRate) {
        let total = customRate.price;
        if(this.showTypeContent == '' && this.simyoService.getShowFtthReduced() == false && customRate.rates.fibra){
            this.customRate.rates.fibra.rate_id = this.customRate.rates.fibra.type_normal.rate_id;
            this.customRate.rates.fibra.rate_info.price = this.customRate.rates.fibra.type_normal.price;

            total = +this.customRate.rates.fibra.rate_info.price;
            if(customRate.rates.movil) {
                total += +customRate.rates.movil.rate_info.real_price;
            }
        }

        if(customRate.rates.movil && this.showTypeContent == orderType.MOBILE) {
            total = +customRate.rates.movil.rate_info.real_price;
        }

        return total;
    }

    disableIfOnlyBroadband100() {
        return this.broadbandSpeedSelected == fibraSpeeds.speed_100;
    }
}
