import {HttpErrorResponse} from '@angular/common/http';
import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {interval, Subscription} from 'rxjs';
import {startWith, take} from 'rxjs/operators';
import {documentationTypes, documentTypes, simyoDocStatus, superStatus, typeCard} from 'src/app/shared/constantes';
import {uploadFileResponse} from 'src/app/shared/models/uploadFileResponse';
import { allPermissions } from 'src/app/shared/permissions';
import {PermissionService} from 'src/app/shared/services/permission.service';
import {downloadFile} from 'src/app/utils/downloadFile';
import {scrollBottom} from 'src/app/utils/scrollBottom';
import {IstateDocumentationEE, signatureDocuments, signatureDocumentsBody, signatureDocumentsResponse, simyoDocumentStatus, TdocumentationUploadType} from '../../models/signatureDocuments';
import {SimyoDocumentationService} from '../../services/simyo-documentation.service';
import {SimyoOrderService} from '../../services/simyo-order.service';
import {SimyoService} from '../../services/simyo.service';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-simyo-documentation-signature',
    templateUrl: './simyo-documentation-signature.component.html',
    styleUrls: ['./simyo-documentation-signature.component.css', '../../../../assets/css/simyo-theme.css']
})

export class SimyoDocumentationSignatureComponent implements OnInit, OnDestroy {
    @ViewChild('closeDocumentationModal') closeDocumentationModal: ElementRef;
    @ViewChild('closeSendLinkModal') closeSendLinkModal: ElementRef;
    @Input() dataOrderToSignature: signatureDocuments;
    @Input() fullOrder: any;
    @Input() documentationMode: string;
    @Input() orderCreateIsFinished: boolean;
    @Input() signatureFormGroup: FormGroup;
    @Input() pdv: Ipdv;
    @Output() stateDocumentation = new EventEmitter<IstateDocumentationEE>(null);

    //public signatureFormGroup: FormGroup;
    public allPermissions = allPermissions;
    public simyoDocStatus: { [key: string]: string } = simyoDocStatus;
    public documentationTypes: { [key: string]: string } = documentationTypes;
    public documentTypes: { [key: string]: string } = documentTypes;
    public orderDocuments: signatureDocumentsBody[];
    public orderDocumentsError: boolean = false;
    public superStatus: { [key: string]: string } = superStatus;
    public resetDocuments: boolean = false;
    public oldOrder;
    public warningMsgChangeType: string = '';
    public nextMonthDay5: boolean = false;
    public showSkipKYC: boolean = true;
    public confirmSkipKYC: boolean = false;
    public hideContractInfo: boolean = true;
    public isPortabilityTitularChange: boolean = false;

    // Document upload
    public selectDocToUpload: signatureDocumentsBody;
    public uploadingFile: boolean = false;
    public showErrorUploadFile: string;
    public errorNumberFileUpload: boolean = false;
    public errorDuplicateFileUpload: boolean = false;
    public NUMBER_MAX_DOCUMENTS_FOR_DOCUMENT: number = 2;

    // Document digital
    public loadingSignDocuments: boolean = false;
    public errorSignDocuments: boolean = false;
    public signDocumentsComplete: boolean = false;
    public signDocumentsDonorComplete: boolean = false;
    public disabledDigital: boolean = false;
    public disabledManual: boolean = false;

    // Precontract
    public loadingPreDownload: boolean = false;
    public errorPreDownload: boolean = false;

    private checkStatusSubscription: Subscription;
    private timerToSing;
    private timerToResendLink;

    public environment = environment;

    constructor(
        private formBuilder: FormBuilder,
        private simyoService: SimyoService,
        public permSv: PermissionService,
        private simyoDocumentationService: SimyoDocumentationService,
        private simyoOrderService: SimyoOrderService
    ) {
    }

    ngOnInit(): void {
        this.signatureFormGroup.valueChanges
            .subscribe((res) => {
                if (this.orderDocuments && this.orderDocuments.length === 0) {
                    this.stateDocumentation.emit({allUploadDocAreValid: true, formIsValid: true});
                } else {
                    this.stateDocumentation.emit({allUploadDocAreValid: this.allUploadDocAreValid(), formIsValid: this.signatureFormGroup.valid});
                }

                if(this.signatureFormGroup.value.documentationUploadType !== this.dataOrderToSignature?.orderInProcess?.sign_type) {
                    this.setSignatureType();
                }

            });
        this.setSignatureType();

        this.evaluateHideContractInfo();
        this.evaluateNextMonthDay5();

        this.checkPortabilityChange();
    }

    skypKYC() {
        this.errorSignDocuments = false;
        this.confirmSkipKYC = true;
        this.orderDocuments.map(doc => doc.status = 'signed');
        this.stateDocumentation.emit({ allUploadDocAreValid: true, formIsValid: false });
        this.simyoOrderService.setSkipKYC(true);
    }

    //Comprueba si el pedido tiene una portabilidad con cambio de titular
    checkPortabilityChange(){
        this.isPortabilityTitularChange = this.fullOrder.mobiles.some(item => item.hasOwnProperty('customer_donor'));
    }

    checkIfIsEsimOrder(){
        return this.fullOrder.mobiles.some(mobile => mobile.is_esim === 'esim');
    }

    setSignatureType() {
        let type: TdocumentationUploadType = 'digital';
        if (this.dataOrderToSignature?.orderInProcess?.sign_type && this.dataOrderToSignature?.orderInProcess?.sign_type !== '') {
            type = this.dataOrderToSignature?.orderInProcess?.sign_type;
            if( type === 'digital' && !this.permSv.hasPermFromV2(allPermissions.simyo.permite_digital_signature) && this.permSv.hasPermFromV2(allPermissions.simyo.permite_manual_signature)) {
                type = 'manual';
            }
        }
        else if (!this.permSv.hasPermFromV2(allPermissions.simyo.permite_digital_signature) && this.permSv.hasPermFromV2(allPermissions.simyo.permite_manual_signature)) {
            type = 'manual';
        }
        if(this.documentationMode == documentationTypes.uploadDocumentation) {
            this.changeDocumentationUploadType(type);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes?.dataOrderToSignature?.currentValue?.orderId) || changes?.orderCreateIsFinished?.currentValue) {
            if (changes?.dataOrderToSignature?.currentValue?.isLastStep) {
                if (this.oldOrder) {
                    this.oldOrder.sign_type = changes?.dataOrderToSignature?.currentValue?.orderInProcess?.sign_type;
                    this.oldOrder.reset_documents = changes?.dataOrderToSignature?.currentValue?.orderInProcess.reset_documents;
                    //this.resetDocuments = JSON.stringify(changes?.dataOrderToSignature?.currentValue?.orderInProcess) !== JSON.stringify(this.oldOrder);
                    this.resetDocuments = Object.keys(changes?.dataOrderToSignature?.currentValue?.orderInProcess)
                        .filter((key) => key !== "draft")
                        .every((key) => JSON.stringify(changes?.dataOrderToSignature?.currentValue?.orderInProcess[key]) !== JSON.stringify(this.oldOrder[key]));
                    this.oldOrder = JSON.parse(JSON.stringify(changes?.dataOrderToSignature?.currentValue?.orderInProcess));
                }
                if (!this.oldOrder) {
                    this.oldOrder = JSON.parse(JSON.stringify(changes?.dataOrderToSignature?.currentValue?.orderInProcess));
                }
            }
            if (changes?.orderCreateIsFinished?.currentValue && this.dataOrderToSignature?.isLastStep || this.documentationMode === this.documentationTypes.viewDocumentation) {
                this.orderDocuments = null;
                this.getDocumentsInfo(this.dataOrderToSignature.orderId);
            }
            if (this.resetDocuments && changes?.orderCreateIsFinished?.currentValue && this.dataOrderToSignature?.isLastStep) {
                this.signatureFormGroup.get('documentationUploadType').patchValue('digital');
                this.signDocumentsComplete = false;
                this.clearTimer(this.timerToSing);
                this.clearTimer(this.timerToResendLink);
                this.orderDocuments = null;
                this.unsubscribeCheckStatus();
                this.resetDocuments = false;
                this.warningMsgChangeType = '';
            }
            ;
        }

        if (!changes?.orderCreateIsFinished?.currentValue) {
            this.orderDocuments = null;
        }
        this.evaluateHideContractInfo();
        this.evaluateNextMonthDay5();
    }

    public changeDocumentationUploadType(type: TdocumentationUploadType) {
        if (type === 'digital' && this.orderDocuments?.find(doc => doc.document_upload_type === 'manual' && doc.merged_images > 0)) {
            this.warningMsgChangeType = 'Si inicias este proceso de firma digital, se anulará el proceso de firma manual en curso';
        } else if (type === 'manual' && this.orderDocuments?.every(doc => doc.document_upload_type === 'auto')) {
            this.warningMsgChangeType = 'Si inicias este proceso de firma manual, se anulará el proceso de firma digital en curso';
        } else {
            this.warningMsgChangeType = '';
        }

        if(this.signatureFormGroup.get('documentationUploadType').value !== type) {
            this.signatureFormGroup.get('documentationUploadType').patchValue(type);
            this.simyoOrderService.setSignType(type);
            this.signatureFormGroup.get('documentationUploadCheck').patchValue(false);
            scrollBottom();
        }
    }

    private uploadFile(file: File) {
        this.uploadingFile = true;
        this.showErrorUploadFile = '';
        this.signDocumentsComplete = false;
        this.simyoService.uploadFile(file, this.dataOrderToSignature.orderId, this.selectDocToUpload?.document_type_code || 'other', this.selectDocToUpload?.document_upload_id)
            .subscribe((data: uploadFileResponse) => {
                if (data?.msg && this.selectDocToUpload) {
                    this.warningMsgChangeType = '';
                    this.selectDocToUpload.file_name = data.msg.file_name;
                    this.selectDocToUpload.document_upload_id = data.msg.order_document_id;
                }
                this.signatureFormGroup.get('documentationUploadCheck').patchValue(false);
                this.getDocumentsInfo(this.dataOrderToSignature.orderId);
            }, (error: HttpErrorResponse) => {
                this.errorUploadFile(error);
            }, () => {
                this.uploadingFile = false;
            });
    }

    private uploadFileMultiple(files: File[]) {
        this.uploadingFile = true;
        this.showErrorUploadFile = '';
        this.signDocumentsComplete = false;
        this.simyoService.uploadFileMultiple(files, this.dataOrderToSignature.orderId, this.selectDocToUpload?.document_type_code || 'other', this.selectDocToUpload?.document_upload_id)
            .subscribe((data: uploadFileResponse) => {
                if (data?.msg && this.selectDocToUpload) {
                    this.warningMsgChangeType = '';
                    this.selectDocToUpload.file_name = data.msg.file_name;
                    this.selectDocToUpload.document_upload_id = data.msg.order_document_id;
                    this.selectDocToUpload.merged_images += files.length;
                    if (this.selectDocToUpload.merged_images >= this.NUMBER_MAX_DOCUMENTS_FOR_DOCUMENT) {
                        this.closeDocumentationModal?.nativeElement?.click();
                    }
                }
                this.signatureFormGroup.get('documentationUploadCheck').patchValue(false);
                this.getDocumentsInfo(this.dataOrderToSignature.orderId);
            }, (error: HttpErrorResponse) => {
                this.errorUploadFile(error);
            }, () => {
                this.uploadingFile = false;
            });
    }

    private errorUploadFile(error: HttpErrorResponse): void {
        this.showErrorUploadFile = 'Ha ocurrido un error al subir el documento. Por favor, inténtelo de nuevo más tarde.';
        if ((error?.error?.error?.errorCode === 502 || error?.error?.error?.errorCode === 503) && error?.error?.error?.msg_complete && error?.error?.error?.msg_complete !== '') {
            this.showErrorUploadFile = error?.error?.error?.msg_complete;
        }
        this.uploadingFile = false;
    }

    public download(doc: signatureDocumentsBody) {
        doc.contractDownloaded = true;
        doc.contractError = false;
        if (doc.document_type_code === 'contract') {
            if (this.signatureFormGroup.get('documentationUploadType').value === 'manual') {
                this.signDocumentsComplete = false;
            }
            this.simyoDocumentationService.getContractSignature(this.dataOrderToSignature.orderId)
                .subscribe(data => {
                    if (data) {
                        this.warningMsgChangeType = '';
                        var reader = new FileReader();
                        let self = this;
                        reader.onloadend = function() {
                            try {
                                const file = JSON.parse(reader.result as string);
                                if (file && file.error) {
                                    doc.contractError = true;
                                    doc.contractDownloaded = false;
                                }
                            } catch (e) {
                                downloadFile(data, 'Contrato_' + self.dataOrderToSignature.orderId + '.pdf');
                                self.getDocumentsInfo(self.dataOrderToSignature.orderId);
                            }
                        };
                        reader.readAsText(data);
                    }
                }, (error: HttpErrorResponse) => {
                    doc.contractError = true;
                    doc.contractDownloaded = false;
                }, () => {
                    doc.contractDownloaded = false;
                });
        }
        if (doc.document_type_code !== 'contract') {
            this.simyoService.getFileUpload(this.dataOrderToSignature.orderId, doc.hash)
                .subscribe(data => {
                    if (data) {
                        this.warningMsgChangeType = '';
                        downloadFile(data, doc.file_name);
                    }
                }, (error: HttpErrorResponse) => {
                    doc.contractError = true;
                    doc.contractDownloaded = false;
                }, () => {
                    doc.contractDownloaded = false;
                });
        }
    }

    public downloadContractWithoutSing(doc: signatureDocumentsBody) {
        doc.contractDownloaded = true;
        doc.contractError = false;
        this.simyoDocumentationService.getContractWithOutSing(this.dataOrderToSignature.orderId)
            .subscribe(data => {
                if (data) {
                    var reader = new FileReader();
                    let self = this;
                    reader.onloadend = function() {
                        try {
                            const file = JSON.parse(reader.result as string);
                            if (file && file.error) {
                                doc.contractError = true;
                                doc.contractDownloaded = false;
                            }
                        } catch (e) {
                            downloadFile(data, 'Contrato_' + self.dataOrderToSignature.orderId + '.pdf');
                            self.getDocumentsInfo(self.dataOrderToSignature.orderId);
                        }
                    };
                    reader.readAsText(data);
                }
            }, (error: HttpErrorResponse) => {
                doc.contractError = true;
                doc.contractDownloaded = false;
            }, () => {
                doc.contractDownloaded = false;
            });
    }

    public downloadPreContract(){
        this.loadingPreDownload = true;
        this.simyoDocumentationService.downloadPre(this.dataOrderToSignature.orderId)
            .subscribe(data => {
                    this.loadingPreDownload = false;
                    if (data) {
                        var reader = new FileReader();
                        let self = this;
                        reader.onloadend = function() {
                            try {
                                const file = JSON.parse(reader.result as string);
                                if (file && file.error) {
                                    self.errorPreDownload = true;
                                }
                            } catch (e) {
                                downloadFile(data, 'Resumen_de_contrato.pdf');
                            }
                        };
                        reader.readAsText(data);
                    }
            }, (error: HttpErrorResponse) => {
                this.loadingPreDownload = false;
                this.errorPreDownload = true;
            }, () => {
            });
    }

    public sendLink() {
        if (this.signatureFormGroup.get('smsCheck').value || this.signatureFormGroup.get('emailCheck').value) {
            this.loadingSignDocuments = true;
            this.signDocumentsComplete = false;
            this.errorSignDocuments = false;
            this.unsubscribeCheckStatus();
            this.orderDocuments.map(doc => doc.status = 'sign_pending');
            this.stateDocumentation.emit({ allUploadDocAreValid: false, formIsValid: false });

            //Si es una portabilidad con cambio de titular se llamará previamente a signDocumentsDonor
            if(this.isPortabilityTitularChange){
                this.signDocumentsDonor();
            }else{
                this.signDocuments();
            };

        } else {
            const elements: any = document.getElementsByClassName('mat-input-element ng-invalid');
            if (elements.length > 0) {
                elements[0].focus();
            }
        }
    }

    //Llamada para firmar documentos
    private signDocuments(){
        const body = {
            sms: this.signatureFormGroup.get('smsCheck').value ? this.signatureFormGroup.get('smsData').value : null,
            email: this.signatureFormGroup.get('emailCheck').value ? this.signatureFormGroup.get('emailData').value : null
        };

        this.simyoDocumentationService.signDocuments(this.dataOrderToSignature.orderId, body)
        .subscribe((data: any) => {
            if (data?.msg) {
                this.warningMsgChangeType = '';
                this.signDocumentsComplete = true;
                this.initTimerToSign();
                this.initTimerToResendLink();
            }
            if (data?.error) {
                this.errorSignDocuments = true;
            }
        }, (error: HttpErrorResponse) => {
            this.errorSignDocuments = true;
            this.loadingSignDocuments = false;
        }, () => {
            this.loadingSignDocuments = false;
            this.subscribeCheckStatus();
        });
    }

    //Llamada para firmar documentos de portabilidad con cambio de titular
    private signDocumentsDonor(){
        this.signDocumentsDonorComplete = false;

        const donorBody = {
            sms: this.signatureFormGroup.get('smsDataDonor').value,
            skip_selfie_validation: this.permSv.hasPermFromV2(allPermissions.simyo.permite_portabilidad_saltar_kyc)
        };

        this.simyoDocumentationService.signDocumentsDonor(this.dataOrderToSignature.orderId, donorBody)
        .subscribe((data: any) => {
            if (data?.msg) {
                this.warningMsgChangeType = '';
                this.signDocumentsComplete = true;
                this.initTimerToSign();
                this.initTimerToResendLink();
            }
            if (data?.error) {
                this.errorSignDocuments = true;
            }
        }, (error: HttpErrorResponse) => {
            this.errorSignDocuments = true;
            this.loadingSignDocuments = false;
        }, () => {
            this.loadingSignDocuments = false;
            this.subscribeCheckStatus();

        });
    }

    /*
        Funcion para iniciar un intervalo de 15 min para firmar
    */
    private initTimerToSign() {
        this.clearTimer(this.timerToSing);
        this.timerToSing = setInterval(() => {
            if (this.signatureFormGroup.get('documentationUploadType').value === 'digital' && !this.orderDocuments.every(doc => doc.status === 'signed')) {
                this.unsubscribeCheckStatus();
                this.signDocumentsComplete = false;
                this.resetDocuments = true;
                this.getDocumentsInfo(this.dataOrderToSignature.orderId);
                this.resetDocuments = false;
                this.clearTimer(this.timerToSing);
            }
        }, 60000 * 15);
    }

    /*
        Funcion para iniciar un intervalo de 2 min para volver a enviar el link
    */
    private initTimerToResendLink() {
        this.clearTimer(this.timerToResendLink);
        this.timerToResendLink = setInterval(() => {
            if (this.signatureFormGroup.get('documentationUploadType').value === 'digital' && !this.orderDocuments.every(doc => doc.status === 'signed')) {
                this.signDocumentsComplete = false;
            }
            this.closeSendLinkModal?.nativeElement?.click();
            this.clearTimer(this.timerToResendLink);
        }, 60000 * 2);
    }

    private clearTimer(timer) {
        if (timer) {
            clearInterval(timer);
        }
    }

    public allUploadedDocumentsAreSignedOrValidationPending(): boolean {
        if (this.orderDocuments && this.orderDocuments.length) {
            if (this.signatureFormGroup.get('documentationUploadType').value === 'digital') {
                this.disabledManual = this.orderDocuments.every(doc => doc.status === 'signed');
                if (this.disabledManual) {
                    this.clearTimer(this.timerToSing);
                }
                if (!this.disabledManual) {
                    this.signatureFormGroup.get('documentationUploadCheck').patchValue(false);
                }
                return this.disabledManual;
            }

            if (this.signatureFormGroup.get('documentationUploadType').value === 'manual') {
                this.disabledDigital = this.orderDocuments.every(doc => doc.status === 'validation_pending');
                if (!this.disabledDigital) {
                    this.signatureFormGroup.get('documentationUploadCheck').patchValue(false);
                }
                return this.disabledDigital;
            }
        }
        this.signatureFormGroup.get('documentationUploadCheck').patchValue(false);
        return false;
    }

    private allUploadDocAreValid(): boolean {
        if (this.orderDocuments && this.orderDocuments.length) {
            if (this.signatureFormGroup.get('documentationUploadType').value === 'digital') {
                return this.orderDocuments.every(doc => doc.status === 'signed');
            }

            if (this.signatureFormGroup.get('documentationUploadType').value === 'manual') {
                return this.orderDocuments.every(doc => doc.status === 'validation_pending');
            }
        }
        return false;
    }

    public deleteDoc(document: signatureDocumentsBody) {
        document.removing = true;
        this.simyoDocumentationService.deleteOrderDocument(this.dataOrderToSignature.orderId, document.document_upload_id)
            .subscribe((data: any) => {
                document.previousUploadFile = '';
                this.getDocumentsInfo(this.dataOrderToSignature.orderId, document);
            }, (error: HttpErrorResponse) => {
                document.removing = false;
            });
    }

    public formsDataIsRequired(type) {
        if(this.signatureFormGroup.get('smsCheck').value === false && this.signatureFormGroup.get('emailCheck').value === false && type != '') {
            this.signatureFormGroup.get(type).setValue(true);
        }
        if (this.signatureFormGroup.get('smsCheck').value !== '' && this.signatureFormGroup.get('smsCheck').value) {
            this.signatureFormGroup.get('smsData').setValidators([Validators.required, blankSpaceValidator()]);
            this.signatureFormGroup.get('smsData').markAsTouched();
            this.signatureFormGroup.get('smsData').updateValueAndValidity();
        } else {
            this.clearValidations('smsData');
        }
        if (this.signatureFormGroup.get('emailCheck').value !== '' && this.signatureFormGroup.get('emailCheck').value) {
            this.signatureFormGroup.get('emailData').setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]);
            this.signatureFormGroup.get('emailData').markAsTouched();
            this.signatureFormGroup.get('emailData').updateValueAndValidity();
        } else {
            this.clearValidations('emailData');
        }
    }

    private fillDataSign() {
        if (this.dataOrderToSignature.phones?.length) {
            if (this.signatureFormGroup.get('smsData').value && this.signatureFormGroup.get('smsData').value !== '') {
                const found = this.dataOrderToSignature.phones.find(phone => phone.phone === this.signatureFormGroup.get('smsData').value);
                if (!found) {
                    this.signatureFormGroup.get('smsData').patchValue(this.dataOrderToSignature.phones[0].phone);
                }
            } else {
                this.signatureFormGroup.get('smsData').patchValue(this.dataOrderToSignature.phones[0].phone);
            }
        }
        if (this.dataOrderToSignature.email && this.dataOrderToSignature.email !== '') {
            this.signatureFormGroup.get('emailData').patchValue(this.dataOrderToSignature.email);
            this.signatureFormGroup.get('emailData').disable();
        }
        if(this.isPortabilityTitularChange){
            let mobileDonor = this.fullOrder.mobiles?.find(x => x.customer_donor != null)
            this.signatureFormGroup.get('smsDataDonor').patchValue(mobileDonor.msisdn);
            this.signatureFormGroup.get('smsDataNewOwner').patchValue(this.dataOrderToSignature.phones[0].phone);
        }
    }

    private getDocumentsInfo(orderId: number, documentSignature?: signatureDocumentsBody): void {
        this.orderDocumentsError = false;
        this.simyoDocumentationService.getOrderDocumentsFromService(orderId, this.resetDocuments)
            .subscribe((data: signatureDocumentsResponse) => {
                if (data?.msg) {
                    this.orderDocuments = data.msg.documents;

                    //si hay portabilidad con cambio de titular estos documentos deben aparecer primero
                    if(this.isPortabilityTitularChange){
                        this.changeDocumentsOrderForDonorChange();
                    };

                    //Verificamos si todos los documentos con document_type_code "contract_donor" están firmados
                    if(this.isPortabilityTitularChange && !this.signDocumentsDonorComplete){
                        const allDonorContractsSigned = this.orderDocuments.filter(doc => doc.document_type_code === "contract_donor").every(doc => doc.status === "signed");
                        //Si todos los documentos de donor están firmados se hará la firma de los demás documentos
                        if(allDonorContractsSigned){
                            this.signDocumentsDonorComplete = true;
                            this.unsubscribeCheckStatus();
                            this.signDocuments();
                        }
                    }

                    this.simyoDocumentationService.setOrderDocuments({
                        type: this.signatureFormGroup.get('documentationUploadType').value,
                        documents: this.orderDocuments
                    });
                    // Asignamos el tipo de documentacion
                    if (this.orderDocuments.length === 0) {
                        this.simyoOrderService.setSignType('not_required');
                    } else {
                        if(this.signatureFormGroup.get('documentationUploadType').value === 'not_required' || this.isPortabilityTitularChange) {
                            this.simyoOrderService.setSignType('digital');
                        }

                    }
                    if (this.signDocumentsComplete) {
                        this.signDocumentsComplete = !this.orderDocuments.every(doc => doc.status === 'signed');
                    }
                    this.formsDataIsRequired('');
                    this.fillDataSign();
                    // No necesita documentación, activamos el botón de confirmar
                    if (this.orderDocuments.length === 0) {
                        this.stateDocumentation.emit({allUploadDocAreValid: true, formIsValid: true});
                        this.unsubscribeCheckStatus();
                    }
                    if (this.allUploadedDocumentsAreSignedOrValidationPending()) {
                        this.closeSendLinkModal?.nativeElement?.click();
                        //scrollBottom();
                        this.allUploadDocumentsHaveHash();
                        this.unsubscribeCheckStatus();
                    }
                }
                // Si hemos eliminado un fichero, nos esperamos a que tengamos el nuevo estado de la documentacion para mostrarlo.
                if (documentSignature && documentSignature.removing) {
                    documentSignature.removing = false;
                }
                setTimeout(() => window.scrollTo({ top: document.body.scrollHeight, left: 0 , behavior: 'smooth' }), 300);
            }, (error: HttpErrorResponse) => {
                this.orderDocuments = [];
                this.orderDocumentsError = true;
                // Si hemos eliminado un fichero, nos esperamos a que tengamos el nuevo estado de la documentacion para mostrarlo.
                if (documentSignature && documentSignature.removing) {
                    documentSignature.removing = false;
                }
            });
    }

    /*
        Funcion que utilizamos para cuando pulse en el check de validacion que haga un scroll al final de la página
    */
    public changeCheckDocumentsAreCorrect(state: boolean): void {
        if (state) {
            scrollBottom();
        }
    }

    /*
        Funcion que se encarga de en el caso de que algún fichero subido digitalmente ya haya sido firmado pero no
        tenga todavía el hash y el nombre del fichero nos subscribimos a la llamada un numero determinado de veces y
        con un tiempo inferior
    */
    private allUploadDocumentsHaveHash() {
        if (!this.orderDocuments.every(doc => doc.status === 'signed' && doc.file_name !== '' && doc.hash !== '') && !this.checkStatusSubscription) {
            this.subscribeCheckStatus(2000, 3);
        }
        if (this.orderDocuments.every(doc => doc.status === 'signed' && doc.file_name !== '' && doc.hash !== '')) {
            this.unsubscribeCheckStatus();
        }
    }

    private subscribeCheckStatus(intervalTime: number = 10000, count: number = 90) {
        this.checkStatusSubscription = interval(intervalTime)
            .pipe(startWith(0))
            .pipe(take(count))
            .subscribe(val => {
                if (this.dataOrderToSignature.orderId) {
                    this.getDocumentsInfo(this.dataOrderToSignature.orderId);
                }
            });
    }

    private unsubscribeCheckStatus() {
        if (this.checkStatusSubscription) {
            this.checkStatusSubscription.unsubscribe();
        }
    }

    public errorHandling = (control: string, error: string) => {
        if (this.signatureFormGroup.get(control).invalid && (this.signatureFormGroup.get(control).dirty || this.signatureFormGroup.get(control).touched)) {
            return this.signatureFormGroup.controls[control].hasError(error);
        }
    };

    private clearValidations(nameForm: string) {
        this.signatureFormGroup.get(nameForm).clearValidators();
        this.signatureFormGroup.get(nameForm).setValidators([]);
        this.signatureFormGroup.get(nameForm).updateValueAndValidity();
    }

    private singleOrMultipleUpload(files: File[]) {
        this.errorNumberFileUpload = false;
        if (this.selectDocToUpload?.document_type_code === 'document') {
            this.uploadFileMultiple(files);
            return;
        }
        if (this.selectDocToUpload?.document_type_code === 'contract') {
            this.uploadFilesForContract(files);
            return;
        }
        if (files.length === 1) {
            this.uploadFile(files[0]);
        }
        if (files.length === 2) {
            this.uploadFileMultiple(files);
        }
        if (files.length > 2) {
            this.errorNumberFileUpload = true;
        }
    }

    private uploadFilesForContract(files: File[]) {
        this.uploadingFile = true;
        this.showErrorUploadFile = '';
        this.simyoService.uploadFileContract(files, this.dataOrderToSignature.orderId, this.selectDocToUpload?.document_upload_id)
            .subscribe((data: uploadFileResponse) => {
                if (data?.msg && this.selectDocToUpload) {
                    this.warningMsgChangeType = '';
                    this.selectDocToUpload.file_name = data.msg.file_name;
                    this.selectDocToUpload.document_upload_id = data.msg.order_document_id;
                    this.selectDocToUpload.merged_images += files.length;
                }
                this.signatureFormGroup.get('documentationUploadCheck').patchValue(false);
                this.getDocumentsInfo(this.dataOrderToSignature.orderId);
            }, (error: HttpErrorResponse) => {
                this.errorUploadFile(error);
            }, () => {
                this.uploadingFile = false;
            });
    }

    public openModalUploadDoc(document: signatureDocumentsBody): void {
        this.errorDuplicateFileUpload = false;
        this.selectDocToUpload = document;
        if (this.selectDocToUpload.document_upload_type === 'auto') {
            this.selectDocToUpload.merged_images = 0;
        }
        this.errorNumberFileUpload = (this.selectDocToUpload.merged_images >= this.NUMBER_MAX_DOCUMENTS_FOR_DOCUMENT && this.selectDocToUpload.document_type_code === 'document');
        this.showErrorUploadFile = '';
    }

    public fileChanged(ev) {
        ev.preventDefault();
        this.validateUploadFiles(ev?.target?.files);
    }

    public dropFile(ev) {
        ev.preventDefault();
        this.validateUploadFiles(ev?.dataTransfer?.files);
    }

    private validateUploadFiles(files: File[]) {
        this.errorDuplicateFileUpload = false;
        if (this.selectDocToUpload?.document_type_code === 'document' && files && files.length === 1) {
            if (this.selectDocToUpload?.previousUploadFile && this.selectDocToUpload?.previousUploadFile !== '') {
                if (this.selectDocToUpload?.previousUploadFile === files[0].name) {
                    this.errorDuplicateFileUpload = true;
                    return;
                }
            } else {
                this.selectDocToUpload.previousUploadFile = files[0].name;
            }
        }
        if (files && files.length > 0) {
            if (this.selectDocToUpload?.document_type_code !== 'contract' && (this.selectDocToUpload?.merged_images >= this.NUMBER_MAX_DOCUMENTS_FOR_DOCUMENT || files.length > this.NUMBER_MAX_DOCUMENTS_FOR_DOCUMENT || (this.selectDocToUpload?.merged_images + files.length) > this.NUMBER_MAX_DOCUMENTS_FOR_DOCUMENT)) {
                this.errorNumberFileUpload = true;
                return;
            }
            this.singleOrMultipleUpload(files);
        }
    }

    public allowDrop(ev) {
        ev.preventDefault();
    }

    public translateStatusTitle(doc: signatureDocumentsBody): string {
        if (this.signatureFormGroup.get('documentationUploadType').value === 'digital') {
            switch (doc.status) {
                case 'validation_pending':
                    return 'EN ESPERA';
                case 'ok':
                    return 'COMPLETADO';
                case 'ko':
                    return 'ERROR';
                case 'not_send':
                    return 'EN ESPERA';
                case 'sign_pending':
                    return 'PENDIENTE FIRMAR';
                case 'signed':
                    return 'COMPLETADO';
                default:
                    return 'EN ESPERA';
            }
        }
        if (this.signatureFormGroup.get('documentationUploadType').value === 'manual') {
            switch (doc.status) {
                case 'validation_pending':
                    return 'SUBIDO';
                case 'ok':
                    return 'SUBIDO';
                case 'ko':
                    return 'ERROR';
                case 'not_send':
                    return 'PENDIENTE DE SUBIR';
                case 'sign_pending':
                    return 'PENDIENTE DE SUBIR';
                case 'signed':
                    return 'PENDIENTE DE SUBIR';
                default:
                    return 'EN ESPERA';
            }
        }
    }

    public getClassStatusTitle(doc: signatureDocumentsBody): string {
        if (this.signatureFormGroup.get('documentationUploadType').value === 'digital') {
            switch (doc.status) {
                case 'validation_pending':
                case 'not_send':
                case 'sign_pending':
                    return 'badge-espera blink';
                case 'ok':
                case 'signed':
                    return 'badge-success';
                case 'ko':
                    return 'badge-error';
                default:
                    return 'badge-espera blink';
            }
        }

        if (this.signatureFormGroup.get('documentationUploadType').value === 'manual') {
            switch (doc.status) {
                case 'not_send':
                case 'sign_pending':
                case 'signed':
                    return 'badge-espera blink';
                case 'ok':
                case 'validation_pending':
                    return 'badge-success';
                case 'ko':
                    return 'badge-error';
                default:
                    return 'badge-espera blink';
            }
        }
    }

    public convertStatusDocumentClass(doc: signatureDocumentsBody): string {
        switch (doc.status) {
            case 'ok':
                return 'dot-complete';
            case 'ko':
                return 'dot-cancel-red';
            default:
                return 'dot-pending';
        }
    }

    public getLastUploadFileDate(): string {
        if (this.orderDocuments && this.orderDocuments.length && this.orderDocuments.filter(doc => doc.document_uploaded_at) && this.orderDocuments.filter(doc => doc.document_uploaded_at).length) {
            return new Date(Math.max.apply(null, this.orderDocuments.filter(doc => doc.document_uploaded_at).map(e => new Date(e.document_uploaded_at)))).toLocaleString();
        }
        return '';
    }

    public isDocumentPending(status: simyoDocumentStatus): boolean {
        return (status === 'sign_pending' || status === 'not_send');
    }

    public getNameIconDocument(doc: signatureDocumentsBody): string {
        switch (doc.document_type_code) {
            case 'contract':
                return 'file';
            case 'document':
                return 'credit-card';
            case 'iban':
                return 'file-text';
            case 'other':
                return 'file-text';
            default:
                return 'file';
        }
    }

    public evaluateHideContractInfo() {
        this.hideContractInfo = false;
        if (this.fullOrder?.tramited_at) {
            var dateTramited = new Date(this.fullOrder.tramited_at);
            var today = new Date();
            var differenceDays = (today.getTime() - dateTramited.getTime()) / (1000 * 3600 * 24);
            if (differenceDays >= 37 || (this.fullOrder?.doc_status === simyoDocStatus.ok && !this.fullOrder?.doc_status_recent_revision)) { //Expiration 37 days or (Doc ok and margin time from revision passed)
                this.hideContractInfo = true;
            }
        }
    }

    public evaluateNextMonthDay5(){
        this.nextMonthDay5 = false;
        if(this.fullOrder?.tramited_at){
            var dateTramited = new Date(this.fullOrder.tramited_at);
            var today = new Date();
            if (today.getFullYear() > dateTramited.getFullYear()) {
                this.nextMonthDay5 = true;
                if (this.diffYearSpecialCase(dateTramited,today)) { // Special case example -> Tramited: 28 December 2023, Today: 3 January 2024
                    this.nextMonthDay5 = false;
                }
            }
            else{
                var nextMonth = today.getMonth() - dateTramited.getMonth();
                if (nextMonth && today.getUTCDate() > 5) { //getDate: day of the week / getUTCDate: day of the month
                    this.nextMonthDay5 = true;
                }
                if (nextMonth > 1) { // more than next month
                    this.nextMonthDay5 = true;
                }
            }
        }
    }

    public diffYearSpecialCase(dateTramited: Date, today:Date){
        if (today.getFullYear() - dateTramited.getFullYear() === 1) {
            if (dateTramited.getMonth() === 11 && today.getMonth() === 0) { //December and January
                if (today.getUTCDate() <= 5) {
                    return true;
                }
            }
        }
        return false;

    }


    ngOnDestroy() {
        this.unsubscribeCheckStatus();
    }

    checkTypeSignSelected(type:string) {
        return this.signatureFormGroup.get('documentationUploadType').value === type;
    }

    showManualSignature() {
        if(this.permSv.hasPermFromV2(allPermissions.simyo.permite_manual_signature) === false) {
            return false;
        }

        if(this.isPortabilityTitularChange){
            return false;
        }

        if(this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_esim) === true
        && this.fullOrder.mobiles
        && this.fullOrder.mobiles.length > 0) {
            return this.fullOrder.mobiles.find(mobile => mobile.is_esim === typeCard.esim) ? false: true;
        }

        return true;
    }

    //Cambia el orden de los documentos para que aparezcan primero los de tipo donor
    changeDocumentsOrderForDonorChange(){
        const contractDonorDocuments = this.orderDocuments.filter(doc => doc.document_type_code === 'contract_donor');
        const documentDonorDocuments = this.orderDocuments.filter(doc => doc.document_type_code === 'document_donor');
        const otherDocuments = this.orderDocuments.filter(doc => doc.document_type_code !== 'contract_donor' && doc.document_type_code !== 'document_donor');

        this.orderDocuments = [
            ...contractDonorDocuments,
            ...documentDonorDocuments,
            ...otherDocuments
        ];
    }
}
