<div class="container content">
    <div class="row mb-4" [hidden]="!loaded">
        <div class="col-lg-8 offset-lg-2">
            <div class="card bg-white configurador pb-4">
                <h6 style="position: relative; left: 0px;" class="fw-bold">
                    Introduce el teléfono del cliente
                </h6>
                <div class="row" [formGroup]="formGroup">
                    <div class="col-lg-8">
                        <mat-form-field>
                            <input matInput type="text" formControlName="msisdn" [ngModel]="(orange$ | async)?.configuration_form_values.msisdn" minlength="9" maxlength="9">
                            <!-- Mensajes de error -->
                            <mat-error *ngIf="formGroup.get('msisdn')?.errors?.minlength">
                                El número de teléfono debe tener 9 dígitos
                            </mat-error>
                            <mat-error *ngIf="formGroup.get('msisdn')?.errors?.pattern && !formGroup.get('msisdn')?.errors?.minlength">
                                El número de teléfono debe empezar por 6 o 7 y sólo números
                            </mat-error>
                            <mat-error *ngIf="formGroup.get('msisdn')?.errors?.required">
                                Campo obligatorio.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <small class="text-muted">
                    <span (click)="searchCustomerInfo()" class="btn btn-black-outline btn-sm my-3">
                        <b>Buscar</b>
                    </span>
                </small>
                <mat-error class="mb-3" *ngIf="showErrorCustomer">
                    No se ha podido realizar la comprobación del cliente. Inténtelo de nuevo más tarde
                </mat-error>
            </div>
        </div>
    </div>
</div>

<div [hidden]="loaded" class="mt-2 mb-5">
    <h5  class="tar-name-preload text-center">Buscando datos del cliente...</h5>

    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
    </div>
</div>


<ng-template #inactiveUser let-modal>
    <div class="modal-header bg-black">
        <h5 class="modal-title white" id="exampleModalLabel">Aviso Importante</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><img src="./assets/img/times-white.svg" class="img-fluid ico-title" width="20"></span>
        </button>
    </div>
    <div class="modal-body m-3">
        <div class="row">
            <div class="col-lg-12">
                <p>
                    {{ customerControlledError }}
                </p>
                <a class="btn btn-black fw-bold float-end" role="button" (click)="modal.dismiss('Cross click')">
                    Aceptar
                </a>
            </div>
        </div>
    </div>
</ng-template>
