import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimyoOrderService } from '../../services/simyo-order.service';

@Component({
    selector: 'app-simyo-agreements',
    templateUrl: './simyo-agreements.component.html',
    styleUrls: ['./simyo-agreements.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoAgreementsComponent implements OnInit {
    public agreementsFormGroup: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private simyoOrderService: SimyoOrderService
    ) { }

    ngOnInit(): void {
        this.agreementsFormGroup = this.formBuilder.group({
            check1: [false],
            check2: [false],
            check3: [false],
            check4: [false],
            check5: [false],
        });

        this.agreementsFormGroup.valueChanges
            .subscribe((data: any) => {
                if (data) {
                    this.simyoOrderService.setAgreements(this.agreementsFormGroup.getRawValue());
                }
            });
    }

    public errorHandling = (control: string, error: string) => {
        if (this.agreementsFormGroup.get(control).invalid && (this.agreementsFormGroup.get(control).dirty || this.agreementsFormGroup.get(control).touched)) {
            return this.agreementsFormGroup.controls[control].hasError(error);
        }
    }

    public openMoreInfo(content) {
        this.modalService.open(content, {size: 'lg', centered: true});
    }

}
