import { AbstractControl, ValidatorFn } from '@angular/forms';

export function blankSpaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const value = control.value;
        // Check if the value is null or undefined
        if (value == null || value === undefined) {
            return null;
        }
        // Check if the value consists of only spaces
        const isEmpty = /^\s*$/.test(value);
        return isEmpty ? { 'blankSpace': true } : null;
    };
}