import { Injectable } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Ipdv } from '../shared/models/pdvResponse';

@Injectable({
    providedIn: 'root'
})
export class DigoService {
    private pdv: ReplaySubject<Ipdv>;
    private renewPDV: BehaviorSubject<boolean>;
    private username: string;

    constructor(
        private auth: AuthService,
        private http: HttpClient
    ) {
        this.pdv = new ReplaySubject<Ipdv>();
        this.renewPDV = new BehaviorSubject<boolean>(false);
    }

    private getHeaders(): HttpHeaders {
        const token = 'Bearer ' + this.auth.getToken();

        return new HttpHeaders({
            Authorization: token,
            'Content-Type': 'application/json'
        });
    }

    public getPdvFromService(): Observable<Ipdv> {
        return this.http.post<Ipdv>(environment.comunEndpoint + 'pdv/data', null, {headers: this.getHeaders()});
    }

    public setPDV(pdv: Ipdv) {
        this.username = pdv?.info?.username;
        this.pdv.next(pdv);
    }

    public getPDV(): Observable<Ipdv> {
        return this.pdv.asObservable();
    }

    // mock Orage prepago
    public getOrangeRates(pdv_id: any = '4') {
        let url = '';
        if (environment.production) {
            url = environment.digoEndPoint + 'api-micros/getTarifasOrange.itx?id_pdv=' + pdv_id;
        } else {
            url = environment.digoEndPoint + 'api-micros/getTarifasOrange.itx?id_pdv=' + pdv_id;
        }

        return this.http.get(url);
    }

    // mock Simyo mobile
    public getSimyoRates(pdv_id: any) {
        const url = environment.digoEndPoint + 'api-micros/getTarifasSimyo.itx?id_pdv=' + pdv_id;

        return this.http.get(url);
    }

    public setRenewPDV(data) {
        this.renewPDV.next(data);
    }

    public getRenewPDV() {
        return this.renewPDV.asObservable();
    }

    public putSupportInfo(data: any) {
        return this.http.post(environment.digoEndPoint + 'api-micros/pdv/update-pdv-support.itx', data);
    }

    public keepAlive(): Observable<any> {
        return this.http.get<any>(environment.digoEndPoint + 'keepAlive.php', {headers: this.getHeaders()});
    }
    
    public goLogin() {
        if (!environment.production) {
            if (!location.origin.includes('localhost')) {
                window.location.href = environment.digoEndPoint + 'signin.itx?ac=999';
            }
        } else {
            window.location.href = environment.digoEndPoint + 'signin.itx?ac=999';
        }
    }

    public putTermsInfo(data: any) {
        return this.http.post(environment.digoEndPoint + 'api-micros/pdv/update-pdv-points-terms.itx', data);
    }

    public isOpSimyoUser() {
        return this.username === 'op_simyo.pdv';
    }
}
