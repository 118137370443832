import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { allLocalStorage, notificationsName, pdvAreas, PDV_MULTIMARCA } from 'src/app/shared/constantes';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-hm-notifications',
    templateUrl: './hm-notifications.component.html',
    styleUrls: ['./hm-notifications.component.css', '../../../../assets/css/home-theme.css']
})
export class HmNotificationsComponent implements OnInit {
    public pdv: any;
    public peninsula: boolean = true;
    public deadline: boolean = false;
    public today = new Date();
    public deadlineDate = new Date('2024-07-31');
    public allPermissions = allPermissions;
    public PDV_MULTIMARCA = PDV_MULTIMARCA;
    public notifications = [];
    public notificationsName = notificationsName;
    public pdvAreas = pdvAreas;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public permSv: PermissionService
    ) {
        this.pdv = data.pdv;
        this.notifications = localStorage.getItem(allLocalStorage.notificationHome) ? localStorage.getItem(allLocalStorage.notificationHome).split(',') : [];
    }

    ngOnInit(): void {
        if (Number(String(this.pdv.address.postCode).slice(0, 2)) === 35 || Number(String(this.pdv.address.postCode).slice(0, 2)) === 38) {
            this.peninsula = false;
        }

        if(this.today >= this.deadlineDate){ // Si la fecha de hoy es mayor, significa que la fecha deadline esta en el pasado
            this.deadline = true;
        }
    }

    removeNotification(nameNotification) {
        let storageNotifications = localStorage.getItem(allLocalStorage.notificationHome) ? localStorage.getItem(allLocalStorage.notificationHome).split(',') : null;
        if (storageNotifications && storageNotifications.includes(nameNotification)) {
            const index = storageNotifications.indexOf(nameNotification);
            if (index > -1) {
                storageNotifications.splice(index, 1);
                this.notifications.splice(index, 1);
            }
            localStorage.setItem(allLocalStorage.notificationHome, storageNotifications.toString());
        }
    }
}
