import { Component, OnInit, ViewChildren, HostListener, Input, OnDestroy } from '@angular/core';
import { SimyoShoppingCartService } from '../../services/simyo-shopping-cart.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { HomeService, INavigateFromOperation } from 'src/app/home/services/home.service';

@Component({
    selector: 'app-simyo-subheader',
    templateUrl: './simyo-subheader.component.html',
    styleUrls: ['./simyo-subheader.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoSubheaderComponent implements OnInit, OnDestroy {
    @Input() iFrameMode: boolean;
    public amount = 0;
    public href : string;
    public simyo$: Observable<number>;
    @ViewChildren('navbar') navbar;
    @ViewChildren('filling') filling;
    public dataOperation: any = null;
    private allSubscription: Subscription[] = [];

    public urlSimyoShowShoppingCart = ['/simyo/convergente','/simyo/solo-movil', '/simyo/solo-fibra', '/simyo/duplicados'];
    public showIconSimyoShoppingCart: boolean;

    constructor(
        private shoppingCart: SimyoShoppingCartService,
        private router: Router,
        private store: Store<{ simyo: number }>,
        private homeService: HomeService,
        private simyoShoppingCartService: SimyoShoppingCartService
    ) {
        this.simyo$ = store.pipe(select('simyo'));
    }

    ngOnInit(): void {
        this.href = this.router.url;
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.href = event.url;
                this.showIconSimyoShoppingCart = this.urlSimyoShowShoppingCart.includes(this.href);
            });

        this.allSubscription.push(this.homeService.getNavigateFromOperation()
            .subscribe((data: INavigateFromOperation) => {
                this.dataOperation = data;
            })
        );

        this.simyoShoppingCartService.getShowIcon().subscribe((show: boolean) => {
            this.showIconSimyoShoppingCart = show;
        });

    }

    openShoppingCart() {
        this.shoppingCart.setShow(true);
    }

    @HostListener('window:scroll', ['$event'])
    doSomethingOnWindowScroll(event: Event) {
        const element: any = event.srcElement;
        const scrollOffset = element.children[0].scrollTop;
        const offsetTop = this.filling.first.nativeElement.offsetTop;

        if (scrollOffset >= offsetTop) {
            if (!this.navbar.first.nativeElement.classList.contains('fixed-top')) {
                this.filling.first.nativeElement.setAttribute('style', 'height: ' + this.navbar.first.nativeElement.offsetHeight + 'px');
                this.navbar.first.nativeElement.classList.add('fixed-top');
            }
        } else {
            if (this.navbar.first.nativeElement.classList.contains('fixed-top')) {
                this.filling.first.nativeElement.setAttribute('style', 'height: 0px');
                this.navbar.first.nativeElement.classList.remove('fixed-top');
            }
        }
    }

    sendEvent() {
        if (this.iFrameMode) {
            var data = { goHome: 'true' }
            var event = new CustomEvent('iFrameSimyo', { detail: data });
            window.parent.document.dispatchEvent(event);
            console.log('Evento lanzado');
        }
    }

    goBack() {
        this.dataOperation ? this.router.navigate(['/']) : this.router.navigate(['/simyo/listado']);
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }
}
