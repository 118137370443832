import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {signatureDocumentsBody, simyoDocumentStatus} from '../../../models/signatureDocuments';
import {SimyoDuplicadosSimService} from '../../../services/simyo-duplicados-sim.service';
import {SimyoService} from '../../../services/simyo.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {interval, Subscription} from 'rxjs';
import {startWith, take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {downloadFile} from '../../../../utils/downloadFile';
import {SimyoDocumentationService} from '../../../services/simyo-documentation.service';

@Component({
    selector: 'app-simyo-ds-documentation-signature',
    templateUrl: './simyo-ds-documentation-signature.component.html',
    styleUrls: ['./simyo-ds-documentation-signature.component.css', '../../../../../assets/css/simyo-theme.css']
})
export class SimyoDsDocumentationSignatureComponent implements OnInit, OnDestroy {
    @Input() allowResentDocuments: boolean;
    @Output() documentsSigned = new EventEmitter<boolean>();
    @ViewChild('closeSendLinkModal') closeSendLinkModal: ElementRef;

    public email: string;
    public signatureFormGroup: FormGroup;
    public orderDocuments: signatureDocumentsBody[] = [];
    public loadingSignDocuments: boolean = false;
    public sendDocumentsComplete: boolean = false;
    public errorSignDocuments: boolean = false;

    private allSubscription: Subscription[] = [];
    private checkStatusSubscription: Subscription;
    private timerToSing;
    private timerToResendLink;
    private simSwap: any;


    constructor(
        private formBuilder: FormBuilder,
        private simyoService: SimyoService,
        private simyoSimSwapService: SimyoDuplicadosSimService,
        private simyoDocumentationService: SimyoDocumentationService,
    ) {
        this.signatureFormGroup = this.formBuilder.group({
            email: [null, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')],
        });
    }

    ngOnInit(): void {
        this.allSubscription.push(this.simyoService.getSwapSim()
            .subscribe((data) => {
                console.log('ngOnInit', data);

                if (data) {
                    this.simSwap = data;
                    this.email = this.simSwap.email;
                    this.getDocumentation();
                }
            }));
    }

    ngOnDestroy() {
        this.unsubscribeCheckStatus();

        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    getDocumentation() {
        console.log('getDocumentation ' + this.simSwap.sim_swap_id)

        this.simyoSimSwapService.getDocumentation(this.simSwap.sim_swap_id)
            .subscribe((data: any) => {
                if (data && data.msg) {
                    this.orderDocuments = data.msg.documents;

                    if (this.orderDocuments.every(doc => doc.status === 'signed')) {
                        this.documentsSigned.emit(true);
                        this.unsubscribeCheckStatus();
                    }
                }
            }, (error) => {
                console.error(error);
            });
    }

    public isDocumentPending(status: simyoDocumentStatus): boolean {
        return (status === 'sign_pending' || status === 'not_send');
    }

    public getNameIconDocument(doc: signatureDocumentsBody): string {
        switch (doc.document_type_code) {
            case 'contract':
                return 'file';
            case 'document':
                return 'credit-card';
            case 'iban':
                return 'file-text';
            case 'other':
                return 'file-text';
            default:
                return 'file';
        }
    }

    public getClassStatusTitle(doc: signatureDocumentsBody): string {
        switch (doc.status) {
            case 'validation_pending':
            case 'not_send':
            case 'sign_pending':
                return 'badge-espera blink';
            case 'ok':
            case 'signed':
                return 'badge-success';
            case 'ko':
                return 'badge-error';
            default:
                return 'badge-espera blink';
        }
    }

    public translateStatusTitle(doc: signatureDocumentsBody): string {
        switch (doc.status) {
            case 'validation_pending':
                return 'EN ESPERA';
            case 'ok':
                return 'COMPLETADO';
            case 'ko':
                return 'KO, no permitido';
            case 'not_send':
                return 'EN ESPERA';
            case 'sign_pending':
                return 'PENDIENTE FIRMAR';
            case 'signed':
                return 'COMPLETADO';
            default:
                return 'EN ESPERA';
        }
    }

    public download(doc: signatureDocumentsBody) {
        doc.contractDownloaded = true;
        doc.contractError = false;

        this.simyoDocumentationService.getSwapContractSignature(this.simSwap.sim_swap_id, doc.hash)
            .subscribe(data => {
                if (data) {
                    let reader = new FileReader();
                    let self = this;
                    reader.onloadend = function() {
                        try {
                            const file = JSON.parse(reader.result as string);
                            if (file && file.error) {
                                doc.contractError = true;
                                doc.contractDownloaded = false;
                            }
                        } catch (e) {
                            downloadFile(data, doc.document_type_code === 'contract' ? doc.file_name + '.pdf' : doc.file_name);
                            self.getDocumentation();
                        }
                    };
                    reader.readAsText(data);
                }
            }, (error: HttpErrorResponse) => {
                doc.contractError = true;
                doc.contractDownloaded = false;
            }, () => {
                doc.contractDownloaded = false;
            });
    }

    public sendDocumentationSign() {
        if (this.signatureFormGroup.get('email').valid) {
            this.loadingSignDocuments = true;

            const body = {
                sim_swap_id: this.simSwap.sim_swap_id,
                email: this.signatureFormGroup.get('email').value
            }

            this.simyoSimSwapService.documentSign(body)
                .subscribe((data: any) => {
                    if (data?.msg) {
                        if (this.signatureFormGroup.get('email').value) {
                            this.email = this.signatureFormGroup.get('email').value;
                        }
                        //Reset actual Documents
                        this.orderDocuments.forEach(doc => {
                            doc.status = 'sign_pending';
                        });
                        this.documentsSigned.emit(false);
                        this.sendDocumentsComplete = true;
                        this.initTimerToSign();
                        this.initTimerToResendLink();
                    }

                    if (data?.error) {
                        this.errorSignDocuments = true;
                    }
                }, (error: HttpErrorResponse) => {
                    this.loadingSignDocuments = false;
                    this.errorSignDocuments = true;
                    console.error(error)
                }, () => {
                    this.loadingSignDocuments = false;
                    this.subscribeCheckStatus();
                });
        } else {
            this.signatureFormGroup.get('email').markAllAsTouched();
            this.signatureFormGroup.get('email').updateValueAndValidity();
        }
    }

    private initTimerToSign() {
        this.clearTimer(this.timerToSing);
        this.timerToSing = setInterval(() => {
            if (!this.orderDocuments.every(doc => doc.status === 'signed')) {
                this.unsubscribeCheckStatus();
                this.sendDocumentsComplete = false;
                this.clearTimer(this.timerToSing);
            }
        }, 60000 * 15);
    }

    /*
        Funcion para iniciar un intervalo de 2 min para volver a enviar el link
    */
    private initTimerToResendLink() {
        this.clearTimer(this.timerToResendLink);
        this.timerToResendLink = setInterval(() => {
            if (!this.orderDocuments.every(doc => doc.status === 'signed')) {
                this.sendDocumentsComplete = false;
            }
            this.closeSendLinkModal?.nativeElement?.click();
            this.email = this.simSwap.email;
            this.signatureFormGroup.get('email').patchValue(null);
            this.clearTimer(this.timerToResendLink);
        }, 60000 * 2);
    }

    private clearTimer(timer) {
        if (timer) {
            clearInterval(timer);
        }
    }

    private subscribeCheckStatus(intervalTime: number = 10000, count: number = 90) {
        this.checkStatusSubscription = interval(intervalTime)
            .pipe(startWith(0))
            .pipe(take(count))
            .subscribe(val => this.getDocumentation());
    }

    private unsubscribeCheckStatus() {
        if (this.checkStatusSubscription) {
            this.checkStatusSubscription.unsubscribe();
        }
    }
}
