import {FormGroup, ValidatorFn} from '@angular/forms';

export function ZipCodeFormat(zip_code: string): ValidatorFn | null {
    return (formGroup: FormGroup): { [key: string]: any } | null => {
        const control = formGroup.controls[zip_code];
        if (control.errors) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        let zip = (control.value).toString();
        const zipRegex = /^(0[1-9]|[1-4]\d|5[0-2])\d{3}$/;

        // set error on matchingControl if validation fails
        if (zip && !zipRegex.test(zip)) {
            control.setErrors({zipCodeInvalid: true});
        } else {
            control.setErrors(null);
        }
    };
}
