import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { IObservableDocuments, signatureDocumentsResponse } from '../models/signatureDocuments';

@Injectable({
    providedIn: 'root'
})
export class SimyoDocumentationService {

    private orderDocuments: BehaviorSubject<IObservableDocuments>;

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
        this.orderDocuments = new BehaviorSubject<IObservableDocuments>(null);
    }

    public signDocuments(order_id: number, body): Observable<any> {
        return this.http.post(environment.simyoEndPoint + 'order/' + order_id + '/documents/sign', body, {headers: this.getHeaders()});
    }

    public signDocumentsDonor(order_id: number, body): Observable<any> {
        return this.http.post(environment.simyoEndPoint + 'order/' + order_id + '/documents/sign/donor', body, {headers: this.getHeaders()});
    }

    public getOrderDocumentsFromService(order_id: number, resetDocuments: boolean): Observable<signatureDocumentsResponse> {
        return this.http.get<signatureDocumentsResponse>(environment.simyoEndPoint + 'order/' + order_id + '/documents' + (resetDocuments ? '?reset_documents=1' : ''), {headers: this.getHeaders()});
    }

    public getContractSignature(order_id: number): Observable<any> {
        return this.http.get(environment.simyoEndPoint + 'order/' + order_id + '/document/digo-contract/download', {headers: this.getHeadersPdf(), responseType: 'blob'});
    }

    public getSwapContractSignature(id, hash): Observable<any> {
        return this.http.get(environment.simyoEndPoint + `sim-swap/${id}/document/${hash}/download`, {headers: this.getHeadersPdf(), responseType: 'blob'});
    }

    public getContractWithOutSing(order_id: number): Observable<any> {
        return this.http.get(environment.simyoEndPoint + 'order/' + order_id + '/document/digo-contract/generate', {headers: this.getHeadersPdf(), responseType: 'blob'});
    }

    public deleteOrderDocument(order_id: number, documentUploadId: any): Observable<any> {
        return this.http.post<any>(environment.simyoEndPoint + 'order/' + order_id + '/document/delete', {document_upload_id: documentUploadId}, {headers: this.getHeaders()});
    }

    // Observables
    public getOrderDocuments(): Observable<any> {
        return this.orderDocuments.asObservable();
    }

    public setOrderDocuments(orderDocuments: IObservableDocuments) {
        this.orderDocuments.next(orderDocuments);
    }

    public createPreOrder(orderId: number | string, only_send_email: boolean){
        //Endpoint precontrato
        return this.http.get(environment.simyoEndPoint + 'order/' + orderId + '/precontract/generate'+ (only_send_email ? '?only_send_email=true' : ''), {headers: this.getHeaders()});
    }

    public downloadPre(orderId: number | string){
        //Endpoint precontrato
        return this.http.get(environment.simyoEndPoint + 'order/' + orderId + '/precontract/download', {headers: this.getHeadersPdf(), responseType: 'blob'});
    }

    private getHeadersPdf(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            Accept: 'application/pdf'
        });
    }

    private getHeaders(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            'Content-Type': 'application/json'
        });
    }
}
