import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {AlertDialogComponent} from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import {priceSwapSim, simyoDocStatus, typeCard} from 'src/app/shared/constantes';
import {PermissionService} from 'src/app/shared/services/permission.service';
import {SimyoDuplicadosSimService} from 'src/app/simyo/services/simyo-duplicados-sim.service';
import {downloadFile} from 'src/app/utils/downloadFile';
import {SimyoService} from '../../../services/simyo.service';
import {Subscription} from 'rxjs';
import { allPermissions } from 'src/app/shared/permissions';

@Component({
    selector: 'app-simyo-ds-confirmation',
    templateUrl: './simyo-ds-confirmation.component.html',
    styleUrls: ['./simyo-ds-confirmation.component.css', '../../../../../assets/css/simyo-theme.css']
})
export class SimyoDsConfirmationComponent implements OnInit, OnDestroy {
    @Input() formGroup: FormGroup;
    @Input() master: any;
    @Output() isEditable = new EventEmitter<boolean>();

    public createLoading: boolean = false;
    public createError: boolean = false;
    public createOk: boolean = false;
    public createWarning: boolean = false;
    public createErrorMsg: string = '';
    public allowResentDocuments: boolean = true;
    public today: Date = new Date();
    public downloadTimer;
    public priceSwapSim: number = priceSwapSim;
    public simSwapOrder: any;
    public loaded: boolean = false;
    public orderNotFound: boolean = false;
    public showErrorUploadFile: boolean = false;
    public uploadingFile: boolean = false;
    public simyoDocStatus = simyoDocStatus;
    private simSwapId: any;
    public documentsSigned = false;
    private allSubscription: Subscription[] = [];
    public allPermissions = allPermissions;
    public typeCard = typeCard;
    constructor(
        private simyoService: SimyoService,
        private simyoSimSwapService: SimyoDuplicadosSimService,
        public permSv: PermissionService,
        private matDialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.allSubscription.push(this.simyoService.getSwapSim()
            .subscribe((data) => {
                if (data) {
                    this.simSwapId = data.sim_swap_id;
                }
            }));
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    simSwapCreate() {
        if (this.permSv.hasPermFromV2(allPermissions.others.users_demo)) {
            this.matDialog.open(AlertDialogComponent, {
                data: {message: 'Usuario demostración. No se puede finalizar el alta'}
            });
        } else {
            this.createLoading = true;
            this.createError = false;
            this.createOk = false;
            this.createWarning = false;
            this.allowResentDocuments = false;
            this.createErrorMsg = '';
            this.clearInterval();
            this.initTimer();

            const body = {
                draft: false,
                sim_swap_id: this.simSwapId,
                reason_id: this.formGroup.get('reason').value,
                new_iccid: this.formGroup.get('iccid').value,
                msisdn: this.formGroup.get('msisdn').value,
                first_name: this.formGroup.get('name').value,
                last_name: this.formGroup.get('lastName1').value,
                second_last_name: this.formGroup.get('lastName2').value,
                document_type_id: this.formGroup.get('documentType').value,
                document_number: this.formGroup.get('documentNumber').value,
                birth_date: this.formGroup.get('birthday').value && this.formGroup.get('birthday').value !== '' ? this.formGroup.get('birthday').value.format('YYYY-MM-DD') : null,
                gender_id: this.formGroup.get('gender').value,
                nationality_id: this.formGroup.get('nationality').value,
                is_esim: this.formGroup.get('is_esim').value == typeCard.esim
            };
            
            this.simyoSimSwapService.createSimSwap(body)
                .subscribe((data: any) => {
                    if (data && data.msg) {
                        this.createOk = true;
                        this.simSwapId = data.msg.sim_swap_id;
                        this.getOrderDetail();
                    }
                }, (error) => {
                    this.clearInterval();
                    this.createError = true;
                    this.createWarning = false;
                    this.createLoading = false;
                    this.createErrorMsg = error?.error?.error?.msg;
                    this.isEditable.emit(false);

                    if (error?.error?.msg === 'Banned') {
                        this.matDialog.open(AlertDialogComponent, {
                            data: {
                                className: "text-center",
                                message: "Upss, ha superado el número máximo de intentos para hacer un duplicado.<br>Contacta con el 1644."
                            }
                        });
                    }

                }, () => {
                    this.clearInterval();
                    this.createWarning = false;
                    this.createLoading = false;
                    this.isEditable.emit(false);
                });
        }
    }

    onDocumentsSigned(signed) {
        this.documentsSigned = signed;
    }

    getOrderDetail() {
        this.simyoSimSwapService.orderSimSwap(this.simSwapId)
            .subscribe((data: any) => {
                if (data && data.msg) {
                    this.simSwapOrder = data.msg;
                }
                ;
            }, (error) => {
                this.orderNotFound = true;
            }, () => {
                this.orderNotFound = false;
                this.loaded = true;
            });
    }

    initTimer() {
        var timeleft = 90;
        let self = this;
        this.downloadTimer = setInterval(function() {
            if (timeleft <= 0) {
                clearInterval(self.downloadTimer);
                self.createLoading = false;
                if (!self.createError && !self.createOk) {
                    self.createWarning = true;
                    self.isEditable.emit(false);
                }
            }
            timeleft -= 1;
        }, 1000);
    }

    clearInterval() {
        if (this.downloadTimer) {
            clearInterval(this.downloadTimer);
        }
    }

    getDocumentType(id): string {
        if (this.master?.document_types?.length && id) {
            const found = this.master.document_types.find(x => x.document_type_id === +id);
            return found ? found.value : '';
        }
    }

    getReasonLabel(id): string {
        if (this.master?.reason?.length && id) {
            const found = this.master.reason.find(x => x.reason_id === +id);
            return found ? found.value : '';
        }
    }

    convertStatusClass(status): string {
        switch (status) {
            case 'OK':
                return 'dot-complete';
            case 'KO':
                return 'dot-cancel-red';
            default:
                return 'dot-pending';
        }
    }

    descargar(file) {
        if (this.simSwapOrder) {
            file.downloaded = true;
            this.simyoSimSwapService.getFileUploadSimSwap(this.simSwapOrder.sim_swap_id, file.hash)
                .subscribe(data => {
                    if (data) {
                        file.downloaded = false;
                        downloadFile(data, file.file_name);
                    }
                }, () => {
                    file.downloaded = false;
                });
        }
    }

    dropFile(ev) {
        ev.preventDefault();
        if (ev.dataTransfer && ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
            this.uploadFile(ev.dataTransfer.files[0]);
        }
    }

    fileChanged(ev) {
        ev.preventDefault();
        if (ev.target && ev.target.files && ev.target.files.length > 0) {
            this.uploadFile(ev.target.files[0]);
        }
    }

    allowDrop(ev) {
        ev.preventDefault();
    }

    uploadFile(file) {
        this.uploadingFile = true;
        this.showErrorUploadFile = false;
        this.simyoSimSwapService.uploadFileSimSwap(file, this.simSwapOrder.sim_swap_id)
            .subscribe((data: any) => {
                if (data && data.msg) {
                    this.simSwapOrder.documents.push(data.msg);
                }
                this.getOrderDetail();
            }, (error) => {
                this.showErrorUploadFile = true;
                this.uploadingFile = false;
            }, () => {
                this.uploadingFile = false;
            });
    }
}
