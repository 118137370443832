import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {MasterAllResponse, OrangeRatesWP} from 'src/app/orange/models/masterAllResponse';
import {PrepaidOrderResponse} from 'src/app/orange/models/prepaidInfoResponse';
import {AppState} from 'src/app/orange/redux';
import {resetState, setErrorCancelLinePrepaid, setLoadingCancelLinePrepaid, setMasterAll, setPrepaidOrderInfo, setStateInfoEsim, setStateSendEmail} from 'src/app/orange/redux/orange.actions';
import {IOrangePrepaidState} from 'src/app/orange/redux/orange.state';
import {OrangePrepaidHiringService} from 'src/app/orange/services/orange-prepaid-hiring.service';
import {OrangeService} from 'src/app/orange/services/orange.service';
import {superStatus} from 'src/app/shared/constantes';
import { downloadFile } from 'src/app/utils/downloadFile';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-orange-prepaid-detail',
    templateUrl: './orange-prepaid-detail.component.html',
    styleUrls: ['./orange-prepaid-detail.component.css', '../../../../../assets/css/orange-theme.css']
})
export class OrangePrepaidDetailComponent implements OnInit {

    public orange$: Observable<IOrangePrepaidState>;
    public loaded: boolean = false;
    public orderNotFound: boolean = false;
    public superStatus: { [key: string]: string } = superStatus;
    public sendEmailFormControl: FormControl;
    public sentEmailLoading: boolean = false;
    public sentEmailError: boolean = false;
    public sentEmailOk: boolean = false;
    public moreOneDay: boolean = false; 
    public email: string = "";
    public isCollapsed: boolean[] = [];
    public sendMsisdnFormControl: FormControl;

    //Download precontract
    public hash: string = '';
    public loadingPreDownload: boolean = false;
    public errorDownload: boolean = false;

    constructor(
        private orangePrepaidHiringService: OrangePrepaidHiringService,
        private activatedRoute: ActivatedRoute,
        private orangeStore: Store<AppState>,
        private orangeMasterService: OrangeService
    ) {
        this.orange$ = orangeStore.pipe(select('orangePrepaid'));
        console.log(this.orange$);
    }

    ngOnInit(): void {
        this.sendEmailFormControl = new FormControl('', Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-_]+\\.[a-zA-Z]{2,4}$'));
        this.sendEmailFormControl.disable();
        this.sendMsisdnFormControl = new FormControl('');
        this.sendMsisdnFormControl.disable();
        this.orangeMasterService.getMasterAllFromService()
            .subscribe((data: MasterAllResponse) => {
                if (data.msg) {
                    this.orangeStore.dispatch(setMasterAll({master: data.msg}));
                    this.getOrderInfo();
                }
            }, error => {
                console.error('getMasterAllFromService', error);
                this.getOrderInfo();
            });
        
    }

    public changeStateInfoEsim(index) {
        this.resetStateSendEmail();
        this.orangeStore.dispatch(setStateSendEmail({currentIndex: index, newState: false}));
        this.isCollapsed[index] = !this.isCollapsed[index];
    }

    public changeStateSendEmail(index, newState) {
        this.resetStateSendEmail();
        this.orangeStore.dispatch(setStateSendEmail({currentIndex: index, newState: newState}));
        this.isCollapsed[index] = true;
    }

    public sendEmail() {
        this.sendEmailFormControl.markAllAsTouched();
        this.sendEmailFormControl.updateValueAndValidity();
        if (this.sendEmailFormControl.value !== '' && !this.sendEmailFormControl?.errors?.pattern) {
            this.sentEmailOk = false;
            this.sentEmailError = false;
            this.sentEmailLoading = true;
            this.orangePrepaidHiringService.sendEmailEsim(this.activatedRoute.snapshot.paramMap.get('order_prepaid_id'), this.sendEmailFormControl.value, this.sendMsisdnFormControl.value)
                .subscribe(data => {
                    this.sentEmailOk = true;
                    this.sentEmailLoading = false;
                }, (error: HttpErrorResponse) => {
                    this.sentEmailError = true;
                    this.sentEmailLoading = false;
                });
        }
    }

    private resetStateSendEmail() {
        this.sendEmailFormControl.reset();
        this.sentEmailOk = false;
        this.sentEmailError = false;
    }

    private getOrderInfo() {
        this.orangePrepaidHiringService.getOrderPrepaid(this.activatedRoute.snapshot.paramMap.get('order_prepaid_id'))
            .subscribe((data: PrepaidOrderResponse) => {
                if (data && data.msg) {
                    this.hash = data.msg.precontract_hash;
                    this.orangeStore.dispatch(setPrepaidOrderInfo({order: data.msg}));
                    this.moreOneDay = this.oneDayAfter(data.msg.created_at);
                    this.email = data.msg.email;

                }
            }, (error: HttpErrorResponse) => {
                this.orderNotFound = true;
                this.loaded = true;
            }, () => {
                this.orderNotFound = false;
                this.loaded = true;
            });
    }

    public downloadPreContract(){
        this.loadingPreDownload = true;
        this.orangePrepaidHiringService.downloadPre(this.hash)
            .subscribe(data => {
                if (data) {
                    this.loadingPreDownload = false;
                    if (data) {
                        var reader = new FileReader();
                        let self = this;
                        reader.onloadend = function() {
                            try {
                                const file = JSON.parse(reader.result as string);
                                if (file && file.error) {
                                    self.errorDownload = true;
                                }
                            } catch (e) {
                                downloadFile(data, 'Resumen_de_contrato.pdf');
                            }
                        };
                        reader.readAsText(data);
                    }

                }
            }, (error: HttpErrorResponse) => {
                this.loadingPreDownload = false;
                this.errorDownload = true;
            }, () => {
            });
    }

    ngOnDestroy() {
        this.orangeStore.dispatch(resetState());
    }

    public getAltaType(line: any): string {
        if (line.portability) {
            return 'Portabilidad';
        }

        if (line.is_esim) {
            return 'eSIM prepago';
        }

        if (line.terminal) {
            return 'Terminal + SIM';
        }

        return 'Alta prepago';
    }

    public getAltaName(line: any) {
        if (line.portability) {
            return 'Línea móvil a portar ';
        }

        if (line.is_esim) {
            return 'Alta eSIM prepago ';
        }

        if (line.terminal) {
            return 'Alta SIM + Terminal ';
        }

        return 'Alta linea móvil ';
    }

    public cancelLine(line: any) {
        this.orangeStore.dispatch(setErrorCancelLinePrepaid({msisdn: line.msisdn, error: ''}));
        this.orangeStore.dispatch(setLoadingCancelLinePrepaid({msisdn: line.msisdn, loading: true}));
        this.orangePrepaidHiringService.cancelOrderPrepaid(line.order_id)
            .subscribe((data: any) => {
                if (data) {
                    if (data.cancelled && data.cancelled === 'ok') {
                        this.getOrderInfo();
                    }
                    if (data.cancelled && data.cancelled === 'ko') {
                        this.orangeStore.dispatch(setErrorCancelLinePrepaid({msisdn: line.msisdn, error: data.error.msg}));
                        this.orangeStore.dispatch(setLoadingCancelLinePrepaid({msisdn: line.msisdn, loading: false}));
                    }
                } else {
                    this.orangeStore.dispatch(setLoadingCancelLinePrepaid({msisdn: line.msisdn, loading: false}));
                }
            }, (error: HttpErrorResponse) => {
                this.orangeStore.dispatch(setErrorCancelLinePrepaid({msisdn: line.msisdn, error: 'Ha ocurrido un error al intentar cancelar la línea. Inténtelo de nuevo más tarde'}));
                this.orangeStore.dispatch(setLoadingCancelLinePrepaid({msisdn: line.msisdn, loading: false}));
            }, () => {
            });
    }

    public oneDayAfter(created_at: string) {//Devuelve true si ha pasado más de un dia
        var today = new Date();
        var tramited = new Date(created_at);
        return (today.getTime() - (tramited.getTime() + 86400000)) > 0;
    }

    public printOrder(orderId) {
        window.open(environment.digoEndPoint + '/justify_print.itx?recogida_id_recogida=' + orderId);
    }

    public hideEsim(index) {
        if (this.isCollapsed[index] === undefined) {
            this.isCollapsed[index] = true;
        }
        return this.isCollapsed[index]
    }
}
