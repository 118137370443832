import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { UserNotFoundComponent } from 'src/app/shared/components/user-not-found/user-not-found.component';
import { DigoService } from 'src/app/services/digo.service';
import { iccidValidator } from 'src/app/utils/validators/iccid-validator';
import { SimyoDuplicadosSimService } from '../../services/simyo-duplicados-sim.service';
import { SimyoService } from '../../services/simyo.service';
import { setTotal } from '../redux/simyo.actions';
import { masterAllResponseSimyo, masterAllSimyo } from '../../models/masterAllSimyo';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';
import { typeCard } from 'src/app/shared/constantes';

@Component({
    selector: 'app-simyo-duplicados-sim',
    templateUrl: './simyo-duplicados-sim.component.html',
    styleUrls: ['./simyo-duplicados-sim.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoDuplicadosSimComponent implements OnInit, OnDestroy {

    public master: masterAllSimyo;
    public configurationFormGroup: FormGroup;
    public isEditable: boolean = true;
    public simyo$: Observable<number>;
    public pdv: Ipdv;
    private allSubscription: Subscription[] = [];

    constructor(
        private simyoService: SimyoService,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private digoService: DigoService,
        private store: Store<{ simyo: number }>,
        public permSv: PermissionService,
    ) {
        this.simyo$ = store.pipe(select('simyo'));
    }

    ngOnInit(): void {
        this.digoService.keepAlive()
            .subscribe({
                next: (resp: any) => {
                    if (!resp || resp !== 200) {
                        this.digoService.goLogin();
                    }
                }
                , error: (error: HttpErrorResponse) => {
                    this.digoService.goLogin();
                }
            });
            
        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: Ipdv) => {
                if (data) {
                    this.pdv = data;
                }
            })
        );

        this.simyoService.getMasterAllFromService()
            .subscribe((data: masterAllResponseSimyo) => {
                if (data && data.msg) {
                    this.simyoService.setMasterAll(data.msg);
                    this.master = data.msg;
                }
            }, (error) => {
                if (error && error.error && error.error.error && error.error.error.errorCode !== null && error.error.error.errorCode === 462) {
                    this.dialog.open(UserNotFoundComponent, {
                        width: '350px',
                        disableClose: true,
                        data: {
                            marca: 'Simyo'
                        }
                    });
                }
            });
        let validatorEsim = this.permSv.hasPermFromV2(allPermissions.simyo.permite_sell_esim) ? [Validators.required] : [];
        this.configurationFormGroup = this.formBuilder.group({
            sim_swap_id: ['', Validators.required],
            email: ['', Validators.required],
            reason: ['', Validators.required],
            iccid: ['', [Validators.required, iccidValidator()]],
            msisdn: ['', [Validators.required, Validators.minLength(9)]],
            name: ['', [Validators.required, blankSpaceValidator()]],
            lastName1: ['', [Validators.required, blankSpaceValidator()]],
            lastName2: [''],
            documentNumber: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
            gender: ['', Validators.required],
            nationality: ['192', Validators.required],
            birthday: ['', Validators.required],
            documentType: ['', Validators.required],
            is_esim: [typeCard.sim, validatorEsim]
        });

        this.store.dispatch(setTotal({total: 0}));
    }

    stateEditable(event) {
        this.isEditable = event;
    }

    ngOnDestroy() {
        this.simyoService.setSwapSim(null);
        if(this.configurationFormGroup !== undefined) this.configurationFormGroup.reset();
        this.store.dispatch(setTotal({total: 0}));
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }
}
