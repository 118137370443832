<section class="main-content">

    <div class="col-xl-12">
        <div class="header">
            <a class="back mb-4 cursor-pointer" (click)="goBack()"><i-feather name="arrow-left" class="icon--fe"></i-feather> Volver</a>
            <h5 class="title my-4">Movimientos</h5>
        </div>
        <!-- FILTROS -->
        <div class="card--sidebar mt-2">
            <div class="card-body">
                <div class="row filters">
                    <div class="col-lg-12 p-0">
                        <small class="text-muted">
                            <i-feather name="filter" class="icon--fe"></i-feather> Filtros
                        </small>
                    </div>
                    <div class="row col-lg-12 p-0 m-0 filters-group">
                        <div class="row col-xl-6 p-0" [formGroup]="filtersFormGroup">
                            <div class="col-lg-4">
                                <mat-form-field style="margin-top: 2px;">
                                    <mat-label>Tipo</mat-label>
                                    <select matNativeControl formControlName="type"
                                    [attr.disabled]="(statusFilterList$ |async) === 'loading' ? 'disabled' : null">
                                        <option value="">Todas</option>
                                        <option *ngFor="let type of listType | keyvalue" [value]="type.key">
                                            {{ type.value }}
                                        </option>
                                    </select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4">
                                <mat-form-field style="margin-top: 2px;">
                                    <mat-label>Producto</mat-label>
                                    <select matNativeControl formControlName="product"
                                    [attr.disabled]="(statusFilterList$ |async) === 'loading' ? 'disabled' : null">
                                        <option value="">Todas</option>
                                        <option *ngFor="let type of productType | keyvalue" [value]="type.key">
                                            {{ type.value }}
                                        </option>
                                    </select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4">
                                <app-datepicker-custom
                                title="Fecha"
                                [form]="filtersFormGroup"
                                name="created_at"
                                [maxDate]="maxDate"
                                [minDate]="minDate"
                                [disabledInput]="(statusFilterList$ |async) === 'loading' ? 'disabled' : null"
                                >
                                </app-datepicker-custom>
                            </div>
                        </div>
                        <div class="col-xl-6 row mx-0 border-start brands-filter" [formGroup]="filterBrandFormGroup">
                            <div class="col-xl-12 btn-group">
                                <label title="Incluye Go" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago) || permSv.hasPermFromV2(allPermissions.orange.tuvo_activaciones_prepago) || permSv.hasPermFromV2(allPermissions.orange.tuvo_activaciones_pospago)">
                                    <input type="checkbox" [formControlName]="brands.orange" [value]="brands.orange"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/or-login-color.svg" class="img-fluid ps-1" width="120">
                                    </span>
                                </label>
                                <label *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas) || permSv.hasPermFromV2(allPermissions.jazztel.tuvo_activaciones)">
                                    <input type="checkbox" [formControlName]="brands.jazztel" [value]="brands.jazztel"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/jz-login-color.svg" class="img-fluid ps-1" width="120">
                                    </span>
                                </label>
                                <label *ngIf="!permSv.hasPermFromV2(allPermissions.simyo.bloquea_ventas)">
                                    <input type="checkbox" [formControlName]="brands.simyo" [value]="brands.simyo"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/sm-login-color.svg" class="img-fluid ps-1" width="120">
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TABLA -->
        <div class="row">
            <div class="col-xl-12">
                <div class="card--sidebar mt-2 mb-2">
                    <div class="card-body">
                        <h6 class="mb-2 text-end" *ngIf="pdv.info.points_terms_date"><small>Unido a Atrévete el {{pdv.info.points_terms_date}}</small></h6>
                        <table mat-table [dataSource]="dataSource" *ngIf="(pointList$ | async)" multiTemplateDataRows>
                            <!-- Puntos -->
                            <ng-container matColumnDef="points">
                                <th mat-header-cell *matHeaderCellDef class="header-column"> Puntos </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="color-orange fw-bold">
                                        {{element.points}}
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Tipo -->
                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef class="header-column"> Tipo </th>
                                <td mat-cell *matCellDef="let element"> {{element.typeLabel}} </td>
                            </ng-container>

                            <!-- Producto -->
                            <ng-container matColumnDef="product">
                                <th mat-header-cell *matHeaderCellDef class="header-column"> Producto </th>
                                <td mat-cell *matCellDef="let element"> {{element.product === null || element.product.trim().length === 0 ? '-' : element.product}} </td>
                            </ng-container>

                            <!-- MSISDN -->
                            <ng-container matColumnDef="msisdn">
                                <th mat-header-cell *matHeaderCellDef class="header-column"> MSISDN </th>
                                <td mat-cell *matCellDef="let element"> {{element.msisdn === null || element.msisdn.trim().length === 0 ? '-' : element.msisdn }} </td>
                            </ng-container>

                            <!-- Fecha Activacion -->
                            <ng-container matColumnDef="activationDate">
                                <th mat-header-cell *matHeaderCellDef class="header-column"> Activación</th>
                                <td mat-cell *matCellDef="let element"> {{convertDate(element.activationDate)}} </td>
                            </ng-container>

                            <!-- Fecha -->
                            <ng-container matColumnDef="date">
                                <th mat-header-cell *matHeaderCellDef class="header-column"> Fecha</th>
                                <td mat-cell *matCellDef="let element"> {{convertDate(element.createdAt)}} </td>
                            </ng-container>

                            <!-- Marca -->
                            <ng-container matColumnDef="brand">
                                <th mat-header-cell *matHeaderCellDef class="header-column"> Marca </th>
                                <td mat-cell *matCellDef="let element">
                                    <img *ngIf="element.brandName !== null" [src]="'./assets/img/' + getImgBrand(element.brandName)" class="img-fluid" width="70" style="margin-left: -10px;">
                                    <p *ngIf="element.brandName === null">-</p>
                                </td>
                            </ng-container>

                            <!-- Balance -->
                            <ng-container matColumnDef="balance">
                                <th mat-header-cell *matHeaderCellDef class="header-column"> Balance </th>
                                <td mat-cell *matCellDef="let element"> {{element.balance}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer"></tr>
                        </table>
                        <div *ngIf="!dataSource.length && (statusPointsList$ | async) === 'initial'">
                            <h5 class="tar-name-preload text-center">No se han encontrado movimientos para estos filtros</h5>
                        </div>
                        <div *ngIf="!automaticLoad && (statusPointsList$ | async) === 'initial' && showLoadMore">
                            <button class="btn btn-black--outline btn-sm mb-1 mt-4 w-100" (click)="nextPage()">Cargar más</button>
                        </div>
                        <div *ngIf="(statusPointsList$ | async) === 'loading' || (statusPointsList$ | async) === 'loadingMore'" class="col-xl-12">
                            <div class="my-3 text-center">
                                <h5 class="tar-name-preload">Obteniendo movimientos...</h5>
                                <div>
                                    <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="mt-5 text-center" *ngIf="(statusPointsList$ | async) === 'error'">
        <h5 class="tar-name-preload">Se ha producido un error, inténtalo de nuevo más tarde</h5>
    </div>
</section>
