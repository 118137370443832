import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { DigoService } from 'src/app/services/digo.service';
import { resetState, setConfiFormData, setMasterAll } from '../../redux/orange.actions';
import { IOrangeAdditionalBonosState } from '../../redux/orange.state';
import { MasterAllResponse } from 'src/app/orange/models/masterAllResponse';
import { OrangeService } from '../../services/orange.service';
import { AppState } from '../../redux';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';

@Component({
    selector: 'app-orange-bonos-hiring',
    templateUrl: './orange-bonos-hiring.component.html',
    styleUrls: ['./orange-bonos-hiring.component.css', '../../../../assets/css/orange-theme.css']
})
export class OrangeBonosHiringComponent implements OnInit, OnDestroy {

    public pdv: Ipdv;
    private allSubscription: Subscription[] = [];
    public orange$: Observable<IOrangeAdditionalBonosState>;
    public configurationFormGroup: FormGroup;
    public isEditable: boolean = true;

    constructor(
        private digoService: DigoService,
        private formBuilder: FormBuilder,
        private orangeStore: Store<AppState>,
        private orangeMasterService: OrangeService
    ) {
        this.orange$ = orangeStore.pipe(select('orangeAdditionalBonos'));
    }

    ngOnInit(): void {
        this.configurationFormGroup = this.formBuilder.group({
            msisdn: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[6-7][0-9]{0,8}$')]],
            rate: [null, Validators.required],
            customer_info: [null, Validators.required],
            correctBalance: [false, [Validators.required, Validators.requiredTrue]]
        });

        this.configurationFormGroup.valueChanges
            .subscribe((newValue) => {
                this.orangeStore.dispatch(setConfiFormData({form: newValue}));
            });

        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: Ipdv) => {
                if (data) {
                    this.pdv = data;
                }
            })
        );

        this.orangeMasterService.getMasterAllFromService(false)
            .subscribe((data: MasterAllResponse) => {
                if (data?.msg) {
                    this.orangeStore.dispatch(setMasterAll({master: data.msg}));
                }
            });
    }

    stateEditable(event) {
        this.isEditable = event;
    }

    ngOnDestroy() {
        this.orangeStore.dispatch(resetState());
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }
}
