import { Component, Input, OnInit } from '@angular/core';
import {
    fiberIndirectTechnologies,
    orderType,
    typeCard,
} from 'src/app/shared/constantes';
import { documentTypeSimyo, masterAllSimyo } from '../../models/masterAllSimyo';
import { SimyoService } from '../../services/simyo.service';
import { take, tap } from 'rxjs/operators';
import { SimyoGlobals } from '../../classes/simyo-globals';

@Component({
    selector: 'app-simyo-summary-client',
    templateUrl: './simyo-summary-client.component.html',
    styleUrls: ['./simyo-summary-client.component.css', '../../../../assets/css/simyo-theme.css'],
})
export class SimyoSummaryClientComponent implements OnInit {
    @Input() customer = null;
    @Input() typeOrder = null;
    @Input() broadband = null;
    @Input() mobiles = [];
    @Input() date = null;
    @Input() rateFtth = null;
    @Input() contract_id = null;
    @Input() is_contingency = false;
    @Input() isDetail = false;

    public orderType = orderType;
    public fiberIndirectTechnologies = fiberIndirectTechnologies;

    public documentTypes: documentTypeSimyo[];

    constructor(
        private simyoService: SimyoService,
        public simyoGlobals: SimyoGlobals
    ) {
        this.simyoService
            .getMasterAll()
            .pipe(
                tap((data: masterAllSimyo) => {
                    if (data) {
                        this.documentTypes = data.document_types;
                    }
                })
            )
            .subscribe();
    }

    ngOnInit(): void {}

    getName() {
        if (this.customer.document_type_id === 4) {
            return this.customer.company_name;
        }
        if (this.customer.full_name) {
            return this.customer.full_name;
        }
        return `${this.customer.name} ${this.customer.last_name} ${
            this.customer.second_last_name ? this.customer.second_last_name : ''
        }`;
    }

    getTypeDischarge() {
        if (this.typeOrder === orderType.CONVERGENT) {
            return `Alta fibra${
                fiberIndirectTechnologies.includes(this.broadband?.access_type)
                    ? ' IND'
                    : ''
            } + ${
                this.mobiles.length > 0
                    ? this.getTitleTypeMobile(this.mobiles[0])
                    : ''
            }`;
        }

        if (this.typeOrder === orderType.MOBILE) {
            return `${
                this.mobiles.length > 0
                    ? this.getTitleTypeMobile(this.mobiles[0])
                    : 'Alta móvil'
            }`;
        }

        if (this.typeOrder === orderType.BROADBAND) {
            return `${
                fiberIndirectTechnologies.includes(this.broadband?.access_type)
                    ? 'Alta fibra IND'
                    : 'Alta fibra'
            }`;
        }
        return '';
    }

    getTitleTypeMobile(mobile) {
        if (mobile === undefined) return '';
        const portability = mobile.msisdn && mobile.msisdn !== '';
        if (mobile.is_esim === typeCard.esim) {
            return portability ? `Portabilidad eSIM móvil` : 'Alta eSIM';
        }

        return portability ? `Portabilidad móvil` : 'Alta móvil';
    }

    public getDocumentLabel(id): string {
      if (this.documentTypes && this.documentTypes.length && id) {
          const found = this.documentTypes.find(x => x.document_type_id === +id)
          return found ? found.value : '';
      }
    }

    public getContractNumber(): string {
        if (this.contract_id && this.contract_id !== '') {
            return this.contract_id.toUpperCase()
        }

        return this.is_contingency ? '-' : 'Pendiente de asignar';
    }
}
