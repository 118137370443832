import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { OrangeBonos, OrangeBonosType } from 'src/app/orange/models/masterAllResponse';
import { MsisdnBalanceResponse } from 'src/app/orange/models/msisdnBalanceResponse';
import { MsisdnInfo } from 'src/app/orange/models/msisdnInfoResponse';
import { AppState } from 'src/app/orange/redux';
import { selectedBono, setTotal, updateBalanceMsisdn } from 'src/app/orange/redux/orange.actions';
import { IOrangeAdditionalBonosState } from 'src/app/orange/redux/orange.state';
import { OrangeBonosHiringService } from 'src/app/orange/services/orange-bonos-hiring.service';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { scrollBottom } from 'src/app/utils/scrollBottom';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-orange-bonos-hiring-configuration',
    templateUrl: './orange-bonos-hiring-configuration.component.html',
    styleUrls: ['./orange-bonos-hiring-configuration.component.css', '../../../../../assets/css/orange-theme.css'],
    encapsulation: ViewEncapsulation.None
})
export class OrangeBonosHiringConfigurationComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() stepper: MatStepper;
    @Input() pdv: Ipdv;
    @ViewChild('modalRecarga') modalRecarga: NgbModalRef;

    public orange$: Observable<IOrangeAdditionalBonosState>;
    public baseUrl: string;
    public allPermissions = allPermissions;
    public loadingBalance: boolean = false;
    public disabledRequestBalance: boolean = false;
    public errorRequestBalance: boolean = false;
    public timeToRequest: number = 12;
    public showPersonalInfo: boolean = false;
    public selectedBonoNotAvailable: string = '';
    public selectedBono: OrangeBonos = null;

    //Dates
    public today: Date = new Date();
    public startBonoEcuador: Date = new Date('2023-03-22');
    public startBonoMorocco: Date = new Date('2023-03-03');
    public startBonoRamadan: Date = new Date('2023-04-03');
    public endBonoEcuador: Date = new Date('2023-04-23');
    public endBonoMorocco: Date = new Date('2023-05-01');
    public endBonoRamadan: Date = new Date('2023-04-28');



    public translateBonosAdditional = {
        voice: 'VOZ',
        internet: 'DATOS',
        sms: 'SMS'
    };
    public colorsBonosAdditional = {
        voice: '#FF7900',
        internet: '#FFD200',
        sms: '#05a7db'
    };
    private timerRequestBalance: any;

    constructor(
        private orangeBonosHiringService: OrangeBonosHiringService,
        private orangeStore: Store<AppState>,
        private modalService: NgbModal,
        public permSv: PermissionService
    ) { 
        this.orange$ = orangeStore.pipe(select('orangeAdditionalBonos'));
        this.baseUrl = environment.digoEndPoint;
    }

    ngOnInit(): void {
    }

    openModal(modal, bono?: OrangeBonos) {
        this.selectedBono = bono;
        this.modalService.open(modal, {size: 'lg', centered: true});
    }

    setBonoRate(bono: OrangeBonos) {
        this.orangeStore.dispatch(selectedBono({selectedBono: bono.active ? bono : null}));
        if (bono.active) {
            this.selectedBonoNotAvailable = '';
            this.formGroup.get('rate').patchValue(bono);
            this.orangeStore.dispatch(setTotal({total: +bono.price}));
            this.isCorrectBalance();
        }
        if (!bono.active) {
            this.selectedBonoNotAvailable = bono.blocked_msg;
            this.formGroup.get('rate').patchValue(null);
            this.orangeStore.dispatch(setTotal({total: 0}));
        }
        scrollBottom();
    }

    async evaluateNextStep(haveEnoughBalance) {
        if (haveEnoughBalance) {
            this.stepper.next();
        }
        if (!haveEnoughBalance) {
            await this.updateBalance(false);
            if (this.formGroup.get('correctBalance').value) {
                this.stepper.next();
            }
            if (!this.formGroup.get('correctBalance').value) {
                this.modalService.open(this.modalRecarga, {size: 'lg', centered: true});
            }
        }
    }

    async updateBalance(withLimitTimeRequest = true) {
        this.loadingBalance = true;
        this.errorRequestBalance = false;
        await this.orangeBonosHiringService.getMsisdnBalanceFromService(this.formGroup.get('msisdn').value)
            .toPromise()
            .then((response: MsisdnBalanceResponse) => {
                if (response?.msg) {
                    let valueCustomerInfo: MsisdnInfo = JSON.parse(JSON.stringify(this.formGroup.get('customer_info')?.value));
                    valueCustomerInfo.balance = response.msg.balance;
                    this.formGroup.get('customer_info').patchValue(valueCustomerInfo);
                    this.orangeStore.dispatch(updateBalanceMsisdn({balance: response.msg.balance.toString()}));
                    this.isCorrectBalance();
                } else {
                    this.errorRequestBalance = true;
                }
                if (withLimitTimeRequest) {
                    this.initTimer();
                }
                this.loadingBalance = false;
            }, (error: HttpErrorResponse) => {
                this.errorRequestBalance = true;
                this.loadingBalance = false;
                this.disabledRequestBalance = false;
            })
    }

    initTimer() {
        let self = this;
        this.disabledRequestBalance = true;
        this.timerRequestBalance = setInterval(function(){
            if (self.timeToRequest <= 0){
                self.timeToRequest = 12;
                self.disabledRequestBalance = false;
                clearInterval(self.timerRequestBalance);
            }
            self.timeToRequest -= 1;
        }, 1000);
    }

    isCorrectBalance() {
        let haveEnoughBalance = +this.formGroup.get('customer_info')?.value?.balance > 0 && +this.formGroup.get('rate')?.value?.price <= +this.formGroup.get('customer_info')?.value?.balance
        this.formGroup.get('correctBalance').patchValue(haveEnoughBalance);
    }

    deadlineEcuador() {
        if (this.today >= this.startBonoEcuador && this.today < this.endBonoEcuador) {
            return true;
        }
        return false;
    }

    deadlineMorocco() {
        if (this.today >= this.startBonoMorocco && this.today < this.endBonoMorocco) {
            return true;
        }
        return false;
    }

    startRamadan(){
        if (this.today >= this.startBonoRamadan && this.today < this.endBonoRamadan) {
            return true;
        }
        return false;
    }

}
