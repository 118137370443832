export function snakeToCamel(obj: any): any {
    if (obj === null || typeof obj !== 'object') {
        return obj;
      }
    
      if (Array.isArray(obj)) {
        return obj.map(item => snakeToCamel(item));
      }
    
      return Object.keys(obj).reduce((acc, key) => {
        const camelKey = key.replace(/_([a-z])/g, (_, letra) => letra.toUpperCase());
        acc[camelKey] = snakeToCamel(obj[key]);
        return acc;
      }, {});
}