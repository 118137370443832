import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import moment from 'moment/moment';
import {maxDate, minDate} from '../../shared/constantes';

export function anyFormValid(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (isAnyFormValid(control.value)) {
            return null;
        }
        return {insertAnyValue: true};
    };
}

function isAnyFormValid(arrayValues) {
    if (arrayValues && arrayValues.length) {
        return !arrayValues.every(element => element === '');
    }
    return false;
}

export function isValueDup() {
    const validator: ValidatorFn = (formControl: FormControl) => {
        const formArray = formControl.parent as FormArray;
        const totalSelected = formArray.controls.map(control => control.value);
        const values = totalSelected.map(value => value);
        const hasDuplicate = values.some((value, index) => {
            if (value === '') {
                return false;
            }

            return values.indexOf(value, index + 1) !== -1;
        });

        return hasDuplicate ? {duplicate: true} : null;
    };

    return validator;
}

export function isValueDupWith(input: string) {
    const validator: ValidatorFn = (formControl: FormControl) => {
        const formGroup = formControl.parent as FormGroup;
        const otherValue = formGroup.get(input).value;
        const value = formControl.value;
        const hasDuplicate = (value !== '' || otherValue !== '') && value === otherValue;

        return hasDuplicate ? {duplicate: true} : null;
    };

    return validator;
}

export function isAdult(): ValidatorFn {
    const FORMAT_DATE = 'DD/MM/YYYY';

    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value == null) {
            return null;
        }

        const controlDate = moment(control.value, FORMAT_DATE);

        if (!controlDate.isValid()) {
            return null;
        }

        if (!controlDate.isAfter(minDate)) {
            return {
                'date_minimum': {
                    'date_minimum': moment(minDate).format(FORMAT_DATE),
                    'actual': controlDate.format(FORMAT_DATE)
                }
            };
        }

        return controlDate.isBefore(maxDate) ? null : {
            'date_maximum': {
                'date_maximum': moment(maxDate).format(FORMAT_DATE),
                'actual': controlDate.format(FORMAT_DATE)
            }
        };
    };
}
