export function getNameStreet(value: string, roadTypes: any[], idRoadTypes: string): string {

    if (roadTypes && roadTypes.length && value && value !== '') {
        let valueNormalized = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        let res: any = valueNormalized.split(" ");
        let tipoVia = [];
        if (res.length == 1) return valueNormalized;
        tipoVia = roadTypes.filter(item => {
            const codeNormalized = item[idRoadTypes].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            if (res && res.length && res[0].toLowerCase() === 'passatge') {
                res[0] = 'pasaje';
            }
            return res[0].toLowerCase() === codeNormalized.toLowerCase();
        });

        if (tipoVia && tipoVia.length) {
            res.shift()
            valueNormalized = res.join(' ');
        } else {
            tipoVia = [];
            tipoVia = roadTypes.filter(item => {
                const codeNormalized = item[idRoadTypes].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                if (res && res.length && res[0].toLowerCase() === 'passatge') {
                    res[0] = 'pasaje';
                }
                return res[res.length - 1].toLowerCase() === codeNormalized.toLowerCase();
            });
            if (tipoVia && tipoVia.length) {
                res.pop()
                valueNormalized = res.join(' ');
            }
        }
        return valueNormalized;
    }
    return value;
}

export function searchRoadType(value: string, roadTypes: any[], idRoadTypes: string, formRoadType: any, listener?: SearchRoadTypeListener): string {
    if (value && roadTypes && roadTypes.length) {
        const valueNormalized = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const res: any = valueNormalized.split(' ');
        let tipoVia: any[] = roadTypes.filter(item => {
            const codeNormalized = item[idRoadTypes].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            if (res && res.length && res[0].toLowerCase() === 'passatge') {
                res[0] = 'pasaje';
            }
            return res && res.length ? res[0].toLowerCase() === codeNormalized.toLowerCase() : valueNormalized.toLowerCase() === codeNormalized.toLowerCase();
        });

        if (tipoVia && tipoVia.length) {
            let code = tipoVia[0].code;
            if (tipoVia.length > 1) {
                const found = tipoVia.find(x => x.code.toLowerCase() === value.toLowerCase());
                code = found ? found.code : tipoVia[0].code;
            }
            const regEx = new RegExp(code, 'gi');

            formRoadType.patchValue(code);

            return valueNormalized;
        } else {
            tipoVia = roadTypes.filter(item => {
                const codeNormalized = item[idRoadTypes].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                if (res && res.length && res[res.length - 1].toLowerCase() === 'passatge') {
                    res[res.length - 1] = 'pasaje';
                }
                return res && res.length ? res[res.length - 1].toLowerCase() === codeNormalized.toLowerCase() : valueNormalized.toLowerCase() === codeNormalized.toLowerCase();
            });

            if (tipoVia && tipoVia.length) {
                const code = tipoVia[0].code;
                const regEx = new RegExp(code, 'gi');

                formRoadType.patchValue(code);

                return valueNormalized;
            } else {
                if (listener) {
                    listener.onZeroResults();
                    console.error('No se encontró Tipo de vía');
                } else {
                    console.error('No se encontró Tipo de vía pero se enviará CALLE');
                    formRoadType.patchValue('CALLE');
                }
            }
        }
    }

    return value;
}

export function onChangeZip(value, provinces, provincesId, formsControl) {
    value = ('00000' + value.toString()).slice(-5);
    if (value != null && value.length >= 2 && provinces && provinces.length) {
        const result = provinces.filter((item: any) => {
            return item[provincesId] === value.substring(0, 2);
        });

        if (result != null && result.length > 0) {
            //formsControl.patchValue(result[0].value);
            formsControl.patchValue(result[0].code);
            return;
        }
    }
    formsControl.patchValue('');
}

export function fillZipCode(code) {
    let zipCode: string = '';
    if (code && code !== '') {
        zipCode = ('00000' + code.toString()).slice(-5);
    }
    return zipCode;
}

export interface SearchRoadTypeListener {
    onZeroResults();
}
