<div class="modal-header">
    <h5 class="modal-title fw-bold ms-3 mt-3" id="exampleModalLabel">Comunicados</h5>
    <button type="button" class="close close-m2" mat-dialog-close>
        <img src="./assets/img/x-black.svg" width="18" class="close-aviso--1">
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div class="row mb-2">
            <div class="col-xl-12">
                <div class="accordion mb-3" id="accordionExample1">
                    <!-- General -->
                    <div class="card--notify mb-3" *ngIf="false">
                        <!--unread -->
                        <!-- <span class="dot--alert-2"></span> -->
                        <div class="card-header--notify" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-link d-block w-100 text-start" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    General
                                </button>
                            </h2>
                        </div>
                    </div>

                    <!-- Simyo -->
                    <div class="card--notify mb-3" *ngIf="(permSv.hasPermFromV2(allPermissions.simyo.permite_vender) || (permSv.hasPermFromV2(allPermissions.simyo.permite_vender) && permSv.hasPermFromV2(allPermissions.others.comissions_permitir)) && pdv.info.tipo_vista !== PDV_MULTIMARCA) || permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                        <span class="dot--alert-2" *ngIf="notifications.includes(notificationsName.notSimyoAdicionales) || notifications.includes(notificationsName.notSimyoFibra500) || notifications.includes(notificationsName.notSimyoUpdateStandAlone) || notifications.includes(notificationsName.notSimyoCommercial)"></span>
                        <div class="card-header--notify" id="headingThree">
                            <h2 class="mb-0">
                                <button class="btn btn-link d-block w-100 text-start collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseTwo">
                                    <img src="./assets/img/sm-login-color.svg" alt="external" width="120">
                                </button>
                            </h2>
                        </div>

                        <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="card-body--notify">
                                <p *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                                    <a href="./assets/pdf/Comunicado líneas adicionales Simyo.pdf" target="_blank" class="fw-bold black" (click)="removeNotification(notificationsName.notSimyoAdicionales)">
                                        <span class="dot--alert-3" *ngIf="notifications.includes(notificationsName.notSimyoAdicionales)"></span>
                                        <img src="./assets/img/external.svg" class="me-1" style="margin-bottom: 2px;" alt="external" width="14">
                                        Comunicado - Simyo líneas adicionales
                                    </a>
                                </p>
                                <p *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
                                    <a href="./assets/pdf/Comunicado Nueva velocidades Simyo.pdf" target="_blank" class="fw-bold black" (click)="removeNotification(notificationsName.notSimyoFibra500)">
                                        <span class="dot--alert-3" *ngIf="notifications.includes(notificationsName.notSimyoFibra500)"></span>
                                        <img src="./assets/img/external.svg" class="me-1" style="margin-bottom: 2px;" alt="external" width="14">
                                        Comunicado - Simyo nuevas velocidades
                                    </a>
                                </p>
                                <p *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender) && +pdv.info.area === pdvAreas.nuevosCanales">
                                    <a href="./assets/pdf/Comunicado Mejora fibra stand alone Simyo.pdf" target="_blank" class="fw-bold black" (click)="removeNotification(notificationsName.notSimyoUpdateStandAlone)">
                                        <span class="dot--alert-3" *ngIf="notifications.includes(notificationsName.notSimyoUpdateStandAlone)"></span>
                                        <img src="./assets/img/external.svg" class="me-1" style="margin-bottom: 2px;" alt="external" width="14">
                                        Comunicado - Simyo mejora fibra stand alone
                                    </a>
                                </p>
                                <p *ngIf="!deadline">
                                    <a href="./assets/pdf/Simyo - Oferta Comercial 07-24.pdf" target="_blank" class="fw-bold black" (click)="removeNotification(notificationsName.notSimyoCommercial)">
                                        <span class="dot--alert-3" *ngIf="notifications.includes(notificationsName.notSimyoCommercial)"></span>
                                        <img src="./assets/img/external.svg" class="me-1" style="margin-bottom: 2px;" alt="external" width="14">
                                        Comunicado - Oferta Comercial Julio
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- Jazztel -->
                    <div class="card--notify mb-3" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                        <span class="dot--alert-2" *ngIf="notifications.includes(notificationsName.notJazztelOferta) || notifications.includes(notificationsName.notJazztelProvision) || notifications.includes(notificationsName.notJazztelRevista)"></span>
                        <div class="card-header--notify" id="headingTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link d-block w-100 text-start collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <img src="./assets/img/jz-login-color.svg" alt="external" width="120">
                                </button>
                            </h2>
                        </div>

                        <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="card-body--notify">
                                <p>
                                    <a href="./assets/img/Comunicado Jazztel nuevo Pack.jpg" target="_blank" class="fw-bold black" (click)="removeNotification(notificationsName.notJazztelOferta)">
                                        <span class="dot--alert-3" *ngIf="notifications.includes(notificationsName.notJazztelOferta)"></span>
                                        <img src="./assets/img/external.svg" class="me-1" style="margin-bottom: 2px;" alt="external" width="14">
                                        Comunicado - Jazztel nuevo Pack
                                    </a>
                                </p>
                                <p>
                                    <a href="./assets/pdf/Comunicado consulta de provisión.pdf" target="_blank" class="fw-bold black" (click)="removeNotification(notificationsName.notJazztelProvision)">
                                        <span class="dot--alert-3" *ngIf="notifications.includes(notificationsName.notJazztelProvision)"></span>
                                        <img src="./assets/img/external.svg" class="me-1" style="margin-bottom: 2px;" alt="external" width="14">
                                        Comunicado - Jazztel consulta de provisión
                                    </a>
                                </p>
                                <p *ngIf="peninsula && !deadline">
                                    <a href="./assets/pdf/Jazztel - Revista Julio'24 Peninsula.pdf" target="_blank" class="fw-bold black" (click)="removeNotification(notificationsName.notJazztelRevista)">
                                        <span class="dot--alert-3" *ngIf="notifications.includes(notificationsName.notJazztelRevista)"></span>
                                        <img src="./assets/img/external.svg" class="me-1" style="margin-bottom: 2px;" alt="external" width="14">
                                        Comunicado - Revista Jazztel Julio'24 (Península)
                                    </a>
                                </p>
                                <p *ngIf="!deadline">
                                    <a href="./assets/pdf/Jazztel - Oferta Comercial 07-24.pdf" target="_blank" class="fw-bold black" (click)="removeNotification(notificationsName.notJazztelCommercial)">
                                        <span class="dot--alert-3" *ngIf="notifications.includes(notificationsName.notJazztelCommercial)"></span>
                                        <img src="./assets/img/external.svg" class="me-1" style="margin-bottom: 2px;" alt="external" width="14">
                                        Comunicado - Oferta Comercial Julio
                                    </a>
                                </p>
                                <p *ngIf="!peninsula && !deadline">
                                    <a href="./assets/pdf/Jazztel - Revista Julio'24 Canarias.pdf" target="_blank" class="fw-bold black" (click)="removeNotification(notificationsName.notJazztelRevista)">
                                        <span class="dot--alert-3" *ngIf="notifications.includes(notificationsName.notJazztelRevista)"></span>
                                        <img src="./assets/img/external.svg" class="me-1" style="margin-bottom: 2px;" alt="external" width="14">
                                        Comunicado - Revista Jazztel Julio'24 (Canarias)
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>

                     <!-- Orange -->
                     <div class="card--notify mb-3" *ngIf="(permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)) && !deadline">
                        <span class="dot--alert-2" *ngIf="notifications.includes(notificationsName.notOrangeCommercial)"></span>
                        <div class="card-header--notify" id="headingThree">
                            <h2 class="mb-0">
                                <button class="btn btn-link d-block w-100 text-start collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <img src="./assets/img/or-login-color.svg" alt="external" width="120">
                                </button>
                            </h2>
                        </div>

                        <div id="collapseFour" class="collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="card-body--notify">
                                <p *ngIf="!deadline">
                                    <a href="./assets/pdf/Orange Red Mundo - Oferta Comercial 07-24.pdf" target="_blank" class="fw-bold black" (click)="removeNotification(notificationsName.notOrangeCommercial)">
                                        <span class="dot--alert-3" *ngIf="notifications.includes(notificationsName.notOrangeCommercial)"></span>
                                        <img src="./assets/img/external.svg" class="me-1" style="margin-bottom: 2px;" alt="external" width="14">
                                        Comunicado - Oferta Comercial Julio
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
