import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appBlockPaste]'
})
export class BlockPasteDirective {
  @Input() activeBlockPaste: boolean = true;  // Parámetro para habilitar o no el bloqueo
  constructor() { }

  @HostListener('paste', ['$event']) onPaste(event: any) {
    if(this.activeBlockPaste) {
        event.preventDefault();
    }
  }

  @HostListener('copy', ['$event']) blockCopy(event: KeyboardEvent) {
    if(this.activeBlockPaste) {
        event.preventDefault();
    }
  }

  @HostListener('cut', ['$event']) blockCut(event: KeyboardEvent) {
    if(this.activeBlockPaste) {
        event.preventDefault();
    }
  }

}
