<section class="redeemPoints-section">
    <div class="header">
        <p class="title">Canjear puntos</p>
        <button class="btn-back" (click)="goBack()">Salir</button>
    </div>
    <mat-progress-bar mode="determinate" [value]="currentStep.percentage"></mat-progress-bar>
    <div *ngIf="loading" class="loading"><img src="./assets/img/spinner-orange.svg" width="92" class="spinner"></div>
    <div *ngIf="!loading" class="container">
        <div class="step-1" *ngIf="currentStep.id == 1">
            <p class="title">Selecciona el importe de tu tarjeta regalo</p>
            <p class="points">Puntos disponibles <span>{{this.availablePoints}} puntos</span></p>
            <div class="cards">
                <ng-container *ngFor="let amount of reward.amountsAvailables; let i = index">
                    <div class="card-amount" *ngIf="amount.available" (click)="selectAmount(i, amount)" [class.selected]="i === indexAmountSelected">
                        <div class="amount-item" >
                            <div class="card-img">
                                <img [src]="'./assets/img/rewards/tarjeta-regalo-'+ reward.image +'.png'">
                                <p class="price">{{amount.amount}}€</p>
                            </div>
                            <div class="detail">
                                <p class="price">{{amount.amount}}€</p>
                                <p class="description">{{reward.title}}</p>
                                <div class="resume-points">
                                    <p><span class="discount">-{{amount.points}} puntos</span> {{calculatePoints(amount.points)}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="btn-container">
                <button class="btn-next" [disabled]="indexAmountSelected == null" [class.disabled]="indexAmountSelected == null" (click)="nextStep()">Continuar</button>
            </div>
        </div>
        <div class="step-2" *ngIf="currentStep.id == 2">
            <div class="resume-redeem">
                <div class="cards">
                    <div class="card-amount" *ngIf="amountSelected.available">
                        <div class="amount-item" >
                            <div class="card-img">
                                <img width="70" [src]="'./assets/img/rewards/miniatura-tarjeta-regalo-'+ reward.image +'.png'">
                            </div>
                            <div class="detail">
                                <p class="description">{{reward.title}} {{amountSelected.amount}}€</p>
                                <!--<div class="resume-points">
                                    <p><span class="discount">-{{amountSelected.points}} puntos</span> {{calculatePoints(amountSelected.points)}}</p>
                                </div>-->
                            </div>
                            <small class="text-muted trash-carrito edit-icon">
                                <!-- editar producto -->
                                <a (click)="goToFirstStep()" *ngIf="getAmountPossibles() > 1">
                                    <img src="./assets/img/edit.svg" class="img-fluid icon-nav-sidebar" width="25">
                                </a>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="title">
                    <p>Confirma tu punto de venta</p>
                </div>
                <div class="detail-pdv">
                    <div class="title-pdv">
                        <p class="name">{{pdv.info.name}}</p>
                        <p class="id">ID: {{pdv.info.idpdv}}</p>
                    </div>
                    <p class="info">Por favor, asegúrate de que los siguientes datos son correctos antes de continuar:</p>
                    <div class="confirm-email">
                        <p class="mail"><i-feather class="icon" name="mail"></i-feather>E-mail <b>{{getEmail()}}</b></p>
                        <p class="info">Si no es correcto, contacta con tu GPV <i-feather class="icon" width="16" name="alert-circle"></i-feather></p>
                    </div>
                </div>
            </div>
            <div class="btn-container">
                <!--<button class="btn-previous" (click)="previousStep()">Atrás</button>-->
                <button class="btn-next" (click)="nextStep()">Continuar</button>
            </div>
        </div>
        <!--loading create order-->
        <div class="step-3" *ngIf="currentStep.id == 3">
            <div class="loading-step">
                <p>Estamos creando tu tarjeta...</p>
                <img src="./assets/img/spinner-orange.svg" width="92" class="spinner">
            </div>
        </div>
        <div class="step-4" *ngIf="currentStep.id == 4">
            <ng-container *ngIf="errorRedeemCreate == false">
                <div class="title">
                    <p>¡Aquí tienes tu tarjeta regalo!</p>
                </div>
                <div class="content">
                    <div class="card-img">
                        <img [src]="'./assets/img/rewards/tarjeta-regalo-'+ reward.image +'.png'">
                        <p class="price" [ngClass]="{'c-grey': reward.image === 'google_play', 'c-dark': reward.image === 'spotify'}">{{amountSelected.amount}}€</p>
                    </div>
                    <div class="info">
                        <p *ngIf="result.expireDate">Puedes usar la tarjeta hasta el {{convertDate(result.expireDate)}}. Recibirás una copia de ella en tu e-mail {{getEmail()}}</p>
                        <p *ngIf="!result.expireDate">Recibirás una copia de tu tarjeta en tu e-mail {{getEmail()}}</p>
                        <p class="link cursor-pointer" (click)="goToLink(reward.urlTerms)">Ver condiciones de canjeo en {{reward.name}}</p>
                    </div>
                    <div class="btn-container">
                        <button (click)="goToOrders()">Ver lista de pedidos</button>
                        <button class="btn-next" (click)="goToLink(reward.urlProduct)">Ir a {{reward.name}}</button>
                    </div>
                </div>
                <hr>
                <div class="remaining-balance">
                    <p class="title">Tu saldo restante:</p>
                    <p class="points">{{result.points}} puntos</p>
                </div>
            </ng-container>
            <ng-container *ngIf="errorRedeemCreate == true">
                <div class="content">
                    <div [innerHTML]="messageerrorRedeemCreate"></div>
                    <div class="btn-container">
                        <button (click)="goToOrders()">Volver a puntos</button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
