<div class="container content" *ngIf="loaded">
    <div class="row mb-4" *ngIf="!isCSQ">
        <div class="col-lg-12 mb-2">
            <h5 class="title-step text-center" >
                <img src="./assets/img/linea-conf.svg" class="img-fluid ico-title transf-ico" width="20">
                {{ showRateOnOffer ? 'Ofertas para el cliente' : 'Configuración de tarifa' }}
            </h5>
        </div>

        <div class="row justify-content-center col-lg-8 offset-md-2">
            <div class="alert alert-warning alert-list mb-3" role="alert" *ngIf="showErrorRate">
                Se ha eliminado la tarifa anterior porque no es compatible con la nueva contratación
                <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">
                    <img src="./assets/img/times2.svg" width="23" class="ms-2">
                </button>
            </div>
        </div>

        <ng-template [ngIf]="diffData?.length && diffVoice?.length" [ngIfElse]="unsupportedRates">
            <div class="col-lg-12" *ngIf="showRateOnOffer && ratesFormGroup.get('movilType').value !== mobileContractType.prepaid">
                <div class="card bg-white configurador">
                    <div class="row mx-0">
                        <div class="col-12 d-flex align-items-center mb-4" [ngClass]="{'justify-content-between': typeOrder === orderType.CONVERGENT && index === 0 && showTypeContent == ''}">
                            <h6 class="fw-bold" *ngIf="typeOrder === orderType.CONVERGENT && index === 0 && showTypeContent == ''">
                                Velocidad de <span class="text-simyo">fibra</span>
                            </h6>

                            <div class="radio-btn-group me-2" [formGroup]="ratesFormGroup" *ngIf="typeOrder === orderType.CONVERGENT && index === 0 && showTypeContent == ''">
                                <div class="radio" *ngFor="let fibra of fibraRates">
                                    <input id="{{fibra.rate_id}}" type="radio" name="fibraRate" value="{{fibra.rate_info?.speed}}" formControlName="fibraRate" (change)="isAvailableRate()">
                                    <label for="{{fibra.rate_id}}" class="fw-bold">{{ fibra.name }}</label>
                                </div>
                            </div>

                            <h6 class="mb-2 fw-bold">
                                Tipo de línea <span class="text-simyo">móvil</span>
                            </h6>
                            <div class="radio-btn-group ms-3" [formGroup]="ratesFormGroup">
                                <div class="radio" *ngIf="showPostpaid && checkPermissions('postpaid')">
                                    <input id="postpaid" type="radio" name="movilType" value="postpaid" formControlName="movilType" (change)="changeMovilType()">
                                    <label for="postpaid" class="fw-bold radio-width">
                                        Pospago <img src="./assets/img/promo.svg" width="16" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" class="ms-1" style="position: relative; top: -2px;">
                                    </label>
                                </div>
                                <div class="radio btn-movilType" *ngIf="showPrepaid && checkPermissions('prepaid')" [ngClass]="{'not-allowed': disableIfOnlyBroadband100()}">
                                    <input id="prepaid" type="radio" name="movilType" value="prepaid" formControlName="movilType" (change)="changeMovilType()">
                                    <label for="prepaid" class="fw-bold radio-width">Prepago</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 mb-3" *ngIf="readyRates && readyRates.length && ratesFormGroup.get('movilType').value && ratesFormGroup.get('movilType').value === mobileContractType.postpaid">
                    <div class="alert alert-success-orange mb-3" role="alert" *ngIf="ratesFormGroup.get('movilType').value === mobileContractType.postpaid">
                        <b>La línea principal siempre será la línea Móvil pospago con mayor cuota mensual</b>, esto puede hacer cambiar el precio de línea principal y adicional
                    </div>
                    <!-- Listas para llevar -->
                    <div class="alert alert-success-blue alert-list mb-3" role="alert">
                        Te mostramos a continuación <b>las mejores tarifas</b> para ti
                        <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">
                            <img src="./assets/img/times2.svg" width="23" class="ms-2">
                        </button>
                    </div>
                    <br>

                    <div class="row mb-3">
                        <div class="col-lg hvr-float" *ngFor="let rate of readyRates">
                            <div class="card-tar card-white mb-5 mb-lg-0">
                                <div class="card-tar-body-1">
                                    <!-- Precio -->
                                    <div class="text-muted fw-bold mb-0 d-flex justify-content-center" style="text-decoration: line-through; min-height: 24px;">
                                        {{ rate.rate_info.old_price && rate.rate_info.old_price !== '' && !rate.show_additional_for_stand ? (rate?.rate_info?.old_price|toFixedDecimals) + '€' : ' ' }}
                                    </div>
                                    <div class="tar-simyo-price fw-bold mb-2 d-flex justify-content-center">
                                        {{ rate?.rate_info?.real_price|toFixedDecimals }}<span class="decimals-1">€</span>
                                    </div>
                                    <!-- Servicios contratados -->
                                    <p  class="tar-simyo-des fw-bold mb-4 text-center">
                                        {{ rate.name }}
                                    </p>
                                    <!-- Contratar -->
                                    <a (click)="selectRate(rate, true)" class="btn btn-simyo2 d-block w-100 btn-sm mb-2">Continuar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <hr>
                    <div class="row animated fadeIn" *ngIf="!isStand">
                        <div class="col-12 col-md">
                            <!-- Text -->
                            <p class="font-size-lg mb-3 mb-md-0 mt-1 pt-3">
                                ¿No estás interesado en nuestra recomendación de tarifas? <b>Configura tu propia tarifa</b>
                            </p>
                        </div>
                        <div class="col-12 col-md-auto">
                            <!-- Button -->
                            <a (click)="showRateConfigurator()" class="btn btn-simyo2 btn-sm mt-2">
                                Abrir configurador
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Configurador de tarifas -->
            <div class="mb-2"
                [ngClass]="{'col-lg-9': ratesFormGroup.get('movilType').value && ratesFormGroup.get('movilType').value !== '', 'col-lg-12': !ratesFormGroup.get('movilType').value || ratesFormGroup.get('movilType').value === ''}"
                *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_configurador_tarifas) && (!showRateOnOffer || ratesFormGroup.get('movilType').value === mobileContractType.prepaid)">
                <div class="card bg-white configurador">
                    <div class="alert alert-success-orange mb-4 mt-0" role="alert" *ngIf="ratesFormGroup.get('movilType').value == mobileContractType.postpaid">
                        <b>La línea principal siempre será la línea Móvil pospago con mayor cuota mensual</b>, esto puede hacer cambiar el precio de línea principal y adicional
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex align-items-center"
                            [ngClass]="{'justify-content-between': typeOrder === orderType.CONVERGENT && index === 0 && showTypeContent == '', 'mb-4': !ratesFormGroup.get('movilType').value || ratesFormGroup.get('movilType').value === ''}">
                            <h6 class="fw-bold col ps-0" *ngIf="typeOrder === orderType.CONVERGENT && index === 0 && showTypeContent == ''">
                                Velocidad de <span class="text-simyo">fibra</span>
                            </h6>

                            <div class="radio-btn-group me-2" [formGroup]="ratesFormGroup" *ngIf="typeOrder === orderType.CONVERGENT  && index === 0 && showTypeContent == ''">
                                <div class="radio" *ngFor="let fibra of fibraRates">
                                    <input id="{{fibra.rate_id}}" type="radio" name="fibraRate" value="{{fibra.rate_info?.speed}}" formControlName="fibraRate" (change)="isAvailableRate(fibra)">
                                    <label for="{{fibra.rate_id}}" class="fw-bold">{{ fibra.name }}</label>
                                </div>
                            </div>

                            <h6 class="mb-2 fw-bold" [ngClass]="{'col': typeOrder === orderType.CONVERGENT  && index === 0 && showTypeContent == ''}">
                                Tipo de línea <span class="text-simyo">móvil</span>
                            </h6>
                            <div class="radio-btn-group ms-3" [formGroup]="ratesFormGroup">
                                <div class="radio" *ngIf="showPostpaid && checkPermissions('postpaid')">
                                    <input id="postpaid" type="radio" name="movilType" value="postpaid" formControlName="movilType" (change)="changeMovilType()">
                                    <label for="postpaid" class="fw-bold radio-width">
                                        Pospago <img src="./assets/img/promo.svg" width="16" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" class="ms-1" style="position: relative; top: -2px;">
                                    </label>
                                </div>
                                <div class="radio btn-movilType" *ngIf="showPrepaid && checkPermissions('prepaid')" [ngClass]="{'not-allowed': disableIfOnlyBroadband100()}">
                                    <input id="prepaid" type="radio" name="movilType" value="prepaid" formControlName="movilType" (change)="changeMovilType()">
                                    <label for="prepaid" class="fw-bold radio-width">Prepago</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="line-config--2" *ngIf="ratesFormGroup.get('movilType').value && ratesFormGroup.get('movilType').value !== ''">
                    <div class="row">
                        <ng-container *ngIf="ratesFormGroup.get('movilType').value && ratesFormGroup.get('movilType').value !== ''">
                            <ng-template [ngIf]="diffData.length > 1 || diffVoice.length > 1" [ngIfElse]="justOne">
                                <ng-template [ngIf]="diffData.length > 1" [ngIfElse]="justOneData">
                                    <div class="col-xl-12 pe-0">
                                        <h6 class="mb-2 fw-bold">
                                            Elige <span class="text-simyo">datos</span> de internet móvil
                                        </h6>

                                        <!-- Datos moviles -->
                                        <div class="slider-container" [formGroup]="ratesFormGroup">
                                            <input type="range" min="0" max="{{diffData.length - 1}}" class="sliders" formControlName="dataRate" (change)="changeData($event.currentTarget.value)">
                                        </div>

                                        <div class="label-container">
                                            <div class="disabled" *ngFor="let data of diffData" [ngClass]="{'disabled' : data.disabled, 'label-slider3': ratesFormGroup.get('movilType').value === mobileContractType.postpaid, 'label-slider1': ratesFormGroup.get('movilType').value === mobileContractType.prepaid }">
                                                {{ data.label }} <img [ngClass]="{'invisible': !(data.isOffer && ratesFormGroup.get('movilType').value === mobileContractType.postpaid)}" src="./assets/img/promo.svg" width="16" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" class="ms-1" style="position: relative; top: -2px;">
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template [ngIf]="diffVoice.length > 1" [ngIfElse]="justOneVoice">
                                    <div class="col-xl-12 pe-0">
                                        <h6 class="mb-2 fw-bold">
                                            Elige <span class="text-simyo">minutos</span> de llamadas
                                        </h6>

                                        <!-- Min llamadas-->
                                        <div class="slider-container" [formGroup]="ratesFormGroup">
                                            <input type="range" min="0" max="{{diffVoice.length - 1}}" class="sliders" formControlName="voiceRate" (change)="changeVoice($event.currentTarget.value)">
                                        </div>

                                        <div class="label-container">
                                            <div class="label-slider disabled" *ngFor="let voice of diffVoice" [ngClass]="{'disabled' : voice.disabled }">
                                                {{ voice.label.length > 8 ? voice.label.substr(0, 6) : voice.label }} <img *ngIf="voice.isOffer && ratesFormGroup.get('movilType').value === mobileContractType.postpaid" src="./assets/img/promo.svg" width="16" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" class="ms-1" style="position: relative; top: -2px;">
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-template>
                            <!--<div class="col-xl-12" *ngIf="bonosRates && bonosRates.length">
                                <!-<h6 style="margin-top: -18px;" class="mb-4 fw-bold">
                                    Completa tu tarifa con:
                                    <a class="text-simyo" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                        + añadir bonos
                                    </a>
                                </h6>->
                                <div class="collapse" id="collapseExample">
                                    <div class="row mb-2" [formGroup]="ratesFormGroup">
                                        <ng-container *ngFor="let bono of bonosRates; let i = index">
                                            <div class="col-xl-3" *ngIf="bono.name === 'Chat'">
                                                <span class="tar-add">{{ bono.name }}</span>
                                                <img src="./assets/img/whatsapp.svg" class="icon-info2 ms-1">
                                                <img src="./assets/img/telegram.svg" class="icon-info ms-1">
                                                <div class="complete--tarifa--1">
                                                    <label class="switch">
                                                        <input type="checkbox" value="{{i}}" class="warning" [formControl]="ratesFormGroup?.controls['bonosRate']?.controls[i]" (change)="addBonoToCustomRate(bono, $event.currentTarget.checked)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-xl-4" *ngIf="bono.name === '100 MIN a simyos'">
                                                <span class="tar-add">{{ bono.name }}</span>
                                                <div class="complete--tarifa--1">
                                                    <label class="switch">
                                                        <input type="checkbox" value="{{i}}" class="warning" [formControl]="ratesFormGroup?.controls['bonosRate']?.controls[i]" (change)="addBonoToCustomRate(bono, $event.currentTarget.checked)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-xl-5" *ngIf="bono.name === 'Redes Sociales'">
                                                <span class="tar-add">{{ bono.name }}</span>
                                                <img src="./assets/img/facebook.svg" class="icon-info ms-1">
                                                <img src="./assets/img/instagram.svg" class="icon-info ms-1">
                                                <img src="./assets/img/twitter.svg" class="icon-info ms-1">
                                                <img src="./assets/img/snapchat.svg" class="icon-info ms-1">
                                                <div class="complete--tarifa--1">
                                                    <label class="switch">
                                                        <input type="checkbox" value="{{i}}" class="warning" [formControl]="ratesFormGroup?.controls['bonosRate']?.controls[i]" (change)="addBonoToCustomRate(bono, $event.currentTarget.checked)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>-->
                        </ng-container>
                    </div>
                </div>
            </div>

            <!-- Precio Tarifa -->
            <div class="col-lg-3" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_configurador_tarifas) && ratesFormGroup.get('movilType').value && ratesFormGroup.get('movilType').value !== '' && (!showRateOnOffer || ratesFormGroup.get('movilType').value == mobileContractType.prepaid)">
                <div class="card tar-simyo tarifas-card-simyo">
                    <!-- Precio -->
                    <div class="text-muted fw-bold mb-0 d-flex justify-content-center" style="text-decoration: line-through;" *ngIf="customRate.oldPrice">
                        {{ customRate.oldPrice }}€
                    </div>
                    <div class="tar-simyo-price fw-bold mb-3 d-flex justify-content-center">
                        {{ calculatePrice(customRate)|toFixedDecimals }}<span class="decimals-1">€</span>
                    </div>
                    <!-- Servicios contratados Linea 1-->
                    <p  class="tar-simyo-des--configurador fw-bold mb-4 text-center" *ngIf="customRate.textFibra !== ''">
                        {{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Fibra IND ' : 'Fibra ') + customRate.textFibra }}
                    </p>
                    <!-- Servicios contratados Linea 21-->
                    <p  class="tar-simyo-des--configurador fw-bold mb-4 text-center" *ngIf="customRate.textType !== '' || customRate.textRate !== ''">
                        {{ customRate.textType }} <br> {{ customRate.textRate }}
                    </p>
                    <!-- Contratar -->
                    <a (click)="selectRate(customRate, false)" class="btn btn-simyo2 btn-sm mb-4">Continuar</a>
                </div>
            </div>
        </ng-template>
    </div>

    <!-- Desarrollo para CSQ -->
    <div class="row mb-4" *ngIf="isCSQ">
        <div class="col-lg-12 mb-2">
            <h5 class="title-step text-center" >
                <img src="./assets/img/linea-conf.svg" class="img-fluid ico-title transf-ico" width="20">
                Ofertas para el cliente
            </h5>
        </div>

        <div class="row justify-content-center col-lg-8 offset-md-2">
            <div class="alert alert-warning alert-list mb-3" role="alert" *ngIf="showErrorRate">
                Se ha eliminado la tarifa anterior porque no es compatible con la nueva contratación
                <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">
                    <img src="./assets/img/times2.svg" width="23" class="ms-2">
                </button>
            </div>
        </div>

        <!-- Solo movil -->
        <ng-container *ngIf="typeOrder === orderType.MOBILE">
            <!-- Precio Tarifa -->
            <div class="col-xl-12 mb-3" *ngIf="csqMovilRates && csqMovilRates.length && ratesFormGroup.get('movilType').value && ratesFormGroup.get('movilType').value !== ''">
                <div class="row">
                    <div class="col-lg hvr-float" *ngFor="let rate of csqMovilRates" [hidden]="(ratesFormGroup.get('movilType').value === mobileContractType.postpaid && rate.isPrepaid) || (ratesFormGroup.get('movilType').value === mobileContractType.prepaid && !rate.isPrepaid)">
                        <div class="card-tar card-white mb-5 mb-lg-0">
                            <div class="card-tar-body-1">
                                <!-- Precio -->
                                <div class="text-muted fw-bold mb-0 d-flex justify-content-center" style="text-decoration: line-through; min-height: 24px;" *ngIf="ratesFormGroup.get('movilType').value === mobileContractType.postpaid">
                                    {{ rate.rate_info.old_price && rate.rate_info.old_price !== '' && !rate.show_additional_for_stand ? rate?.rate_info?.old_price + '€' : ' ' }}
                                </div>
                                <div class="tar-simyo-price fw-bold mb-2 d-flex justify-content-center">
                                    {{ rate?.rate_info?.real_price }}<span class="decimals-1">€</span>
                                </div>
                                <!-- Servicios contratados -->
                                <p  class="tar-simyo-des fw-bold mb-4 text-center">
                                    {{ customRate.textType }} <br>{{ rate.name }}
                                </p>
                                <!-- Contratar -->
                                <a (click)="selectRate(rate, true)" class="btn btn-simyo2 d-block w-100 btn-sm mb-2">Continuar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card bg-white configurador pt-2">
                    <div class="row">
                        <div class="col-xl-12 pe-0 d-flex align-items-center">
                            <h6 class="mb-2 fw-bold">
                                Tipo de línea <span class="text-simyo">móvil</span>
                            </h6>
                            <div class="radio-btn-group ms-3" [formGroup]="ratesFormGroup">
                                <div class="radio" *ngIf="showPostpaid && checkPermissions('postpaid')">
                                    <input id="postpaid" type="radio" name="movilType" value="postpaid" formControlName="movilType">
                                    <label for="postpaid" class="fw-bold radio-width">
                                        Pospago <img src="./assets/img/promo.svg" width="16" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" class="ms-1" style="position: relative; top: -2px;">
                                    </label>
                                </div>
                                <div class="radio" *ngIf="checkPermissions('prepaid')">
                                    <input id="prepaid" type="radio" name="movilType" value="prepaid" formControlName="movilType">
                                    <label for="prepaid" class="fw-bold radio-width">Prepago</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #unsupportedRates>
    <div class="row justify-content-center col-lg-8 offset-md-2">
        <div class="alert alert-warning alert-list mb-3" role="alert">
            No hay tarifas disponibles. Inténtelo de nuevo más tarde
        </div>
    </div>
</ng-template>

<ng-template #justOne>
    <div class="col-xl-12 label-container">
        <h6 class="mb-2 fw-bold">
            Datos de internet móvil: <span class="text-simyo">{{ diffData[0].label }}</span>
            <img *ngIf="diffData[0].isOffer && ratesFormGroup.get('movilType').value === mobileContractType.postpaid" src="./assets/img/promo.svg" width="16" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" class="ms-1" style="position: relative; top: -2px;" alt="Promo">
        </h6>
    </div>

    <div class="col-xl-12 label-container" style="margin-top: -20px;">
        <h6 class="mb-2 fw-bold">
            Minutos de llamadas: <span class="text-simyo">{{ diffVoice[0].label }}</span>
            <img *ngIf="diffVoice[0].isOffer && ratesFormGroup.get('movilType').value === mobileContractType.postpaid" src="./assets/img/promo.svg" width="16" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" class="ms-1" style="position: relative; top: -2px;">
        </h6>
    </div>
</ng-template>

<ng-template #justOneData>
    <div class="col-xl-12 label-container">
        <h6 class="mb-2 fw-bold">
            <span class="text-simyo">Datos</span> de internet móvil
            <strong class="just-one-data float-end">{{ diffData[0].label }} <img *ngIf="diffData[0].isOffer && ratesFormGroup.get('movilType').value === mobileContractType.postpaid" src="./assets/img/promo.svg" width="16" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" class="ms-1" style="position: relative; top: -2px;" alt="Promo"></strong>
        </h6>
    </div>
</ng-template>

<ng-template #justOneVoice>
    <div class="col-xl-12 label-container" style="margin-top: -20px;">
        <h6 class="mb-2 fw-bold">
            Minutos de llamadas: <span class="text-simyo">{{ diffVoice[0].label }}</span>
            <img *ngIf="diffVoice[0].isOffer && ratesFormGroup.get('movilType').value === mobileContractType.postpaid" src="./assets/img/promo.svg" width="16" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" class="ms-1" style="position: relative; top: -2px;">
        </h6>
    </div>
</ng-template>

<div *ngIf="!loaded" class="mt-2 mb-5">
    <h5  class="tar-name-preload text-center">Obteniendo tarifas...</h5>

    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header" style="background-color: #fff;">
        <b class="col text-simyo pe-0 mt-2 text-center" style="font-size: 18px;" >IMPORTANTE</b>
    </div>

    <div class="modal-body pt-0" id="bodyModal">
        <div class="m-3 row">
            <span class="col-12 text-center" >
                Avisa al cliente de que para que se activen los bonos tiene que recargar
                <span class="text-simyo">{{ +rechargePrice|toFixedDecimals }}€ </span>
                <b>DESPUÉS DE ACTIVAR LA SIM</b>
            </span>
            <span class="col-12 mt-4 text-center" >
                Si puedes hazle la recarga ahora mismo y si no que tenga el móvil conectado en WIFI sin datos móviles hasta que recargue
            </span>
        </div>
        <a (click)="modal.dismiss('Cross click')" class="btn btn-simyo-o2 d-block w-100 mb-1" role="button">
            Entendido
        </a>
    </div>
</ng-template>

<ng-template #notAllowedPrepaid let-modal>
    <div class="modal-header" style="background-color: #fff;">
        <b class="col text-simyo pe-0 mt-2 text-center" style="font-size: 18px;" >IMPORTANTE</b>
    </div>

    <div class="modal-body pt-0" id="bodyModal">
        <div class="m-3 row">
            <span class="col-12 text-center" >
                Esta tarifa no es compatible con la fibra seleccionada.
            </span>
        </div>
        <a (click)="modal.dismiss('Cross click')" class="btn btn-simyo-o2 d-block w-100 mb-1" role="button">
            Entendido
        </a>
    </div>
</ng-template>
<!--
<ng-template #modalFibraSpeed let-modal>
    <div class="modal-header" style="background-color: #fff;">
        <b class="col text-simyo pe-0 mt-2 text-center" style="font-size: 18px;" >IMPORTANTE</b>
    </div>

    <div class="modal-body pt-0" id="bodyModal">
        <div class="my-3 row">
            <span class="col-12 text-center" >
                Recuerda que por {{plusFibraSpeed}}€ más podrás disfrutar de 1Gb de velocidad
            </span>
        </div>
        <a (click)="modal.dismiss('Cross click')" class="btn btn-simyo-o2 d-block w-100 mb-1" role="button">
            Entendido
        </a>
    </div>
</ng-template>
-->
