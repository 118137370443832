import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PointsService } from 'src/app/home/services/points.service';
import { take, map, tap, catchError, pluck } from 'rxjs/operators';
import { snakeToCamel } from 'src/app/utils/convertKeysObject';
import * as moment from 'moment/moment';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-point-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
})
export class PointOrderComponent implements OnInit {
    @Input() reward: any;
    @Input() availablePoints: any;
    @Input() pdv: any;
    @Input() amountSelected = null;
    @Output() onGoBack = new EventEmitter();
    loading = true;
    indexAmountSelected = null;
    steps = [
        {
            id: 1,
            percentage: 10,
        },
        {
            id: 2,
            percentage: 40,
        },
        {
            id: 3,
            percentage: 75,
        },
        {
            id: 4,
            percentage: 100,
        },
    ];
    currentStep = this.steps[0];
    result = null;
    errorRedeemCreate = false;
    messageerrorRedeemCreate = '<p>Lo sentimos, ha ocurrido un error al realizar su canjeo. Intentelo de nuevo más tarde.</p>';
    constructor(public pointsService: PointsService) {}

    ngOnInit(): void {
        if(this.amountSelected !== null) {
            this.currentStep = this.steps[1];
            this.indexAmountSelected = this.reward.amountsAvailables.findIndex(x => x == this.amountSelected)
        }
        this.loading = false;
    }

    goBack() {
        this.onGoBack.emit('points');
    }

    nextStep() {
        this.currentStep = this.steps.find(
            (step) => step.id == this.currentStep.id + 1
        );
        if (this.currentStep.id === 3) {
            this.pointsService
                .postRedeemCreate({
                    pin: this.amountSelected.pin,
                    amount: this.amountSelected.amount,
                })
                .pipe(
                    take(1),
                    map((data) => snakeToCamel(data)),
                    catchError((response: HttpErrorResponse) => {
                        const error = response.error?.error;
                        if (error && error.errorCode === 401) {
                            this.messageerrorRedeemCreate = `<p>En estos momentos no se puede obtener el código de <b>${this.reward.title}</b>, en un plazo máximo de 72 horas te lo enviaremos a tu correo <b>${this.getEmail()}</b></p>`;
                        }
                        this.errorRedeemCreate = true;
                        return of({});
                    }),
                    pluck('redeem'),
                    tap((data) => {
                        if (data) {
                            this.result = data;
                            this.errorRedeemCreate = false;
                        }
                        this.nextStep();
                    })
                )
                .subscribe();
        }
    }
    previousStep() {
        this.currentStep = this.steps.find(
            (step) => step.id == this.currentStep.id - 1
        );
    }

    selectAmount(index, amount) {
        this.indexAmountSelected = index;
        this.amountSelected = amount;
    }

    goToOrders() {
        this.onGoBack.emit('orders');
    }

    goToPoints() {
        this.onGoBack.emit('points');
    }

    goToLink(link) {
        window.open(link, '_blank').focus();
    }

    calculatePoints(points) {
        let checkPoints = this.availablePoints - points;
        return checkPoints >= 0
            ? `Te sobran ${checkPoints}`
            : `Te faltan ${points}`;
    }

    convertDate(data): string {
        return data && data !== '' ? moment(data).format('DD/MM/YYYY') : '';
    }
    goToFirstStep() {
        this.previousStep()
    }

    getEmail() {
        return this.pdv.info.email;
    }

    getAmountPossibles(){
        let amountPossibles = this.reward.amountsAvailables.filter(amount => amount.points <= this.availablePoints).length;
        return amountPossibles;
    }
}
