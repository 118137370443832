import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SimyoService } from '../../services/simyo.service';
import { SimyoShoppingCartService } from '../../services/simyo-shopping-cart.service';
import { SimyoDraftService } from '../../services/simyo-draft.service';
import { availableTechnologies, fiberIndirectTechnologies, orderType, realTechnologyNames, technologyTypes, typeCard } from 'src/app/shared/constantes';
import { iccidValidator } from 'src/app/utils/validators/iccid-validator';
import { NotMustMatch } from 'src/app/utils/validators/not-much-match-validator';
import { MustMatch } from 'src/app/utils/validators/email-confirm-validator';
import { SimyoNormalizadorService } from '../../services/simyo-normalizador.service';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { SimyoRateService } from '../../services/simyo-rate.service';
import { SimyoUserDataService } from '../../services/simyo-user-data.service';
import { SimyoConfirmationService } from '../../services/simyo-confirmation.service';
import { SimyoMovilPackService } from '../../services/simyo-movil-pack.service';
import { DigoService } from 'src/app/services/digo.service';
import { environment } from 'src/environments/environment';
import { HomeService } from "../../../home/services/home.service";
import { HmRateService } from "../../../home/services/hm-rate.service";
import { MatDialog } from '@angular/material/dialog';
import { UserNotFoundComponent } from 'src/app/shared/components/user-not-found/user-not-found.component';
import { select, Store } from '@ngrx/store';
import { setTotal } from '../redux/simyo.actions';
import { EMPTY, Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';
import { masterAllResponseSimyo } from '../../models/masterAllSimyo';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import {isAdult} from '../../../utils/validators/any-form-valid';
import { ZipCodeFormat } from 'src/app/utils/validators/zip-code-validator';
import { HttpErrorResponse } from '@angular/common/http';
import { blankSpaceValidator } from 'src/app/utils/validators/blank-space-validator';
import { SimyoUserDataComponent } from '../simyo-user-data/simyo-user-data.component';
import { catchError, map, pluck, tap } from 'rxjs/operators';
import { responseReuseIVAccessSimyo } from '../../models/installationVirtual';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { SimyoGlobals } from '../../classes/simyo-globals';

@Component({
    selector: 'app-simyo',
    encapsulation: ViewEncapsulation.Emulated,
    templateUrl: './simyo.component.html',
    styleUrls: ['./simyo.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoComponent implements OnInit, OnDestroy {
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('tabUserData') tabUserData: SimyoUserDataComponent;

    public isLoading = false;
    public isEditable = true;
    public finished = false;
    public rate: any;
    public mobileRates: any;
    //public priceBonos: number = 0;
    public muestraNormalizacion = true;
    public typeOrder = '';
    public order;
    public enviroment = environment;
    public pdv: Ipdv;

    // Forms para los steps
    public fibraFormGroup: FormGroup;
    public movilPackFormGroup: FormGroup;
    public movilPackForms: FormArray;
    public clienteFormGroup: FormGroup;
    //public confirmacionFormGroup: FormGroup;
    // Forms para los componentes
    public normalizadorFormGroup: FormGroup;
    public coberturaFormGroup: FormGroup;
    public customerFormGroup: FormGroup;
    public signatureFormGroup: FormGroup;
    private maxAdditionalRates = 5;
    private preventDestroy = false;
    public muestraErrorPeticion = false;
    public msgErrorPeticion: string = null;
    public thereIsCoverage = false;
    public clientIsCover: boolean = false;
    public orderType = orderType;
    public realTechnologyNames = realTechnologyNames;
    public baseUrl: string;
    public simyo$: Observable<number>;
    public isAdditional: boolean = false;
    public customerInfo: any;
    public allPermissions = allPermissions;
    public orderLoader: any;
    public retryCoverageSubject: Subject<void> = new Subject<void>();
    public technologyTypes = technologyTypes;
    public arrayTechnologyTypes = Object.values(technologyTypes);
    public fiberIndirectTechnologies = fiberIndirectTechnologies;
    public selectedTechnology = technologyTypes.dir;
    public resultadoCobertura: any;
    public orderCreateIsFinished: boolean = false;
    public fibraRateSpeed = '';

    public showTypeContent = '';

    private allSubscription: Subscription[] = [];

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private orderService: SimyoOrderService,
        private rateService: SimyoRateService,
        private simyoService: SimyoService,
        private userDataService: SimyoUserDataService,
        private normalizadorService: SimyoNormalizadorService,
        private confirmationService: SimyoConfirmationService,
        private shoppingCartService: SimyoShoppingCartService,
        private movilPackService: SimyoMovilPackService,
        private draftService: SimyoDraftService,
        private digoService: DigoService,
        private homeService: HomeService,
        private hmRateService: HmRateService,
        public dialog: MatDialog,
        private store: Store<{ simyo: number }>,
        public permSv: PermissionService,
        public simyoGlobals: SimyoGlobals
    ) {
        this.shoppingCartService.setShowIcon(true);
        this.simyo$ = store.pipe(select('simyo'));
        this.baseUrl = environment.digoEndPoint;
        this.defineTypeOrder();
    }

    ngOnInit(): void {
        this.initComponent();
        this.digoService.keepAlive()
            .subscribe({
                next: (resp: any) => {
                    if (!resp || resp !== 200) {
                        this.digoService.goLogin();
                    }
                }
                , error: (error: HttpErrorResponse) => {
                    this.digoService.goLogin();
                }
            });
    }

    ngAfterViewInit() {
        this.shoppingCartService.setStepper(this.stepper);
        this.listenDraftServices();
    }

    initComponent() {
        this.allSubscription.push(this.simyoService.getPreventDestroy()
            .subscribe((data: boolean) => {
                this.preventDestroy = data;
            })
        );

        this.allSubscription.push(this.simyoService.getTypeOrder()
            .subscribe((data: any) => {
                if (data) {
                    this.typeOrder = data;
                    this.isAdditional = this.rateService.isAdditional(0, this.customerInfo, this.typeOrder, this.order);
                }
            })
        );

        this.initForms();
        this.getMasters();

        this.allSubscription.push(this.simyoService.getRateFtth()
            .subscribe((data: any) => {
                if (data != null) {
                    this.rate = data;
                    this.fibraFormGroup.get('rate').patchValue(data);
                    this.orderService.setBroadbandRate(this.rate);
                } else {
                    this.rate = null;
                    this.fibraFormGroup.get('rate').patchValue(null);
                }
            })
        );

        this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data: any) => {
                this.mobileRates = data;
                // if (this.mobileRates && this.mobileRates.length && this.mobileRates[0].bonos && this.mobileRates[0].bonos.length) {
                //     this.priceBonos = 0;
                //     this.mobileRates[0].bonos.map(x => this.priceBonos += +x.rate_info.price)
                // }
            })
        );

        this.allSubscription.push(this.normalizadorService.getMuestraNormalizacion()
            .subscribe((value) => {
                this.muestraNormalizacion = value;

                if (this.muestraNormalizacion) {
                    this.customerFormGroup.reset();
                }
            })
        );

        this.allSubscription.push(this.normalizadorService.getResultadoCobertura()
            .subscribe((data: any) => {
                if (data != null) {
                    this.resultadoCobertura = data;
                    // Con fibra neba
                    if
                    (this.resultadoCobertura.get('resultadoConsultaCobertura')?.value?.technology &&
                        this.resultadoCobertura?.get('resultadoConsultaCobertura')?.value?.technology.length &&
                        this.resultadoCobertura?.get('resultadoConsultaCobertura')?.value?.technology[0] === technologyTypes.ind_neba &&
                        this.resultadoCobertura?.get('resultadoConsultaCobertura')?.value?.commercial_deal === null
                    ) {
                        this.simyoService.setNebaNoCommercial(true);
                    }
                    else{
                        this.simyoService.setNebaNoCommercial(false);
                    }


                    if (
                        this.typeOrder === orderType.BROADBAND &&
                        ((this.resultadoCobertura?.get('resultadoConsultaCobertura')?.value?.technology &&
                        this.resultadoCobertura?.get('resultadoConsultaCobertura')?.value?.technology.length &&
                        this.resultadoCobertura?.get('resultadoConsultaCobertura')?.value?.technology[0] === technologyTypes.ind_neba) ||
                        this.resultadoCobertura?.get('resultadoConsultaCobertura')?.value?.commercial_deal === null)
                    ) {
                        if (!(this.rate && this.resultadoCobertura && !this.customerInfo)) {
                            this.simyoService.setRateFtth(null);
                            this.store.dispatch(setTotal({total: 0}));
                        }
                    }
                    if (
                        this.typeOrder === orderType.CONVERGENT &&
                        ((this.resultadoCobertura.get('resultadoConsultaCobertura')?.value?.technology &&
                        this.resultadoCobertura?.get('resultadoConsultaCobertura')?.value?.technology.length &&
                        this.resultadoCobertura?.get('resultadoConsultaCobertura')?.value?.technology[0] === technologyTypes.ind_neba) ||
                        this.resultadoCobertura?.get('resultadoConsultaCobertura')?.value?.commercial_deal === null) &&
                        (this.rate?.rate_info?.speed === '300')
                    ) {
                        this.simyoService.setRateFtth(null);
                        this.store.dispatch(setTotal({total: 0}));
                    }
                    this.orderService.setBroadbandCoverage(data.getRawValue());
                }
            })
        );

        this.allSubscription.push(this.simyoService.getMobilePacks()
            .subscribe((data: any) => {
                if (data != null) {
                    this.orderService.setMobiles(data);
                }
            })
        );

        this.allSubscription.push(this.userDataService.getUserData()
            .subscribe((data: any) => {
                if (data != null) {
                    this.orderService.setUserData(data);
                }
            })
        );

        this.allSubscription.push(this.confirmationService.getFinished()
            .subscribe((data: boolean) => {
                this.finished = data;
                this.shoppingCartService.setOrderFinished(data);
                this.isEditable = !this.finished;
            })
        );

        // Iniciar tramitación desde otra
        this.allSubscription.push(this.simyoService.getUserData()
            .subscribe((data: any) => {
                if (data != null) {
                    this.clienteFormGroup.patchValue(data);
                }
            })
        );

        // Retomar borrador
        this.allSubscription.push(this.simyoService.getFormsData()
            .subscribe((data: any) => {
                if (data != null) {
                    if (data.normalizador != null) {
                        this.normalizadorFormGroup.patchValue(data.normalizador);
                        this.normalizadorService.setFormularioNormalizacion(this.normalizadorFormGroup);
                    }

                    if (data.cobertura != null) {
                        if (!data.haveCoverage) {
                            data.cobertura.resultadoConsultaCobertura = null;
                        }
                        this.coberturaFormGroup.patchValue(data.cobertura);
                        this.normalizadorService.setResultadoCobertura(this.coberturaFormGroup);
                    }

                    if (data.fibra != null) {
                        this.updateFormFtth(data.fibra);
                        this.fibraFormGroup.patchValue(data.fibra);
                        this.simyoService.setRateFtth(data.fibra.rate);
                        if (data.haveCoverage) {
                            this.normalizadorService.setMuestraNormalizacion(false);
                            this.normalizadorService.setMuestraBotonNormalizacion(false);
                            this.normalizadorService.setMuestraCobertura(true);
                            if(data.cobertura.resultadoConsultaCobertura.technology.includes(technologyTypes.dir)) {
                                this.simyoService.setVirtualInstallationData({
                                    gescal37: data.cobertura.resultadoConsultaCobertura.cimaGescalCode,
                                    isDirectBroadband: true
                                });
                            }
                            this.normalizadorService.setThereIsCoverage(true);
                        } else {
                            this.normalizadorService.setMuestraNormalizacion(true);
                            this.normalizadorService.setMuestraBotonNormalizacion(true);
                            this.normalizadorService.setMuestraCobertura(false);
                            this.normalizadorService.setThereIsCoverage(false);
                        }
                    }

                    if (data.movilPacks != null && data.movilPacks.length > 0 && this.typeOrder !== orderType.BROADBAND) {
                        data.movilPacks.forEach((movilPack: any, index: number) => {
                            this.updateFormMobile(movilPack);
                            if (!this.movilPackForms.controls[index]) {
                                this.movilPackForms.insert(index, this.setMobilePack());
                            }
                            const movilPackForm: any = this.movilPackForms.controls[index];
                            movilPackForm.patchValue(movilPack);
                            if (movilPack.rate && this.simyoGlobals.findByKeyInMobile(movilPack.rate, 'rate_info')) {
                                this.simyoService.setRatesMobile(index, movilPack.rate, this.order);
                            }
                            if(movilPack.is_esim == typeCard.esim) {
                                movilPackForm.get('iccid').patchValue(null);
                                movilPackForm.get('iccid').clearValidators();
                            }

                            this.simyoService.setMobilePacks(index, movilPackForm.getRawValue());
                        });
                    }

                    if (data.cliente != null) {
                        if (!data.haveCoverage) {
                            data.cliente.nombreVia = '';
                            data.cliente.numero = '';
                            data.cliente.planta = '';
                            data.cliente.puerta = '';
                            data.cliente.escalera = '';
                            data.cliente.codigoPostal = '';
                            data.cliente.localidad = '';
                            data.cliente.provincia = '';
                        }
                        this.clienteFormGroup.patchValue(data.cliente);
                        this.userDataService.setUserData(this.clienteFormGroup.getRawValue());
                    }
                }
            })
        );

        this.allSubscription.push(this.simyoService.getIndexToRemove()
            .subscribe(data => {
                if (data) {
                    this.removeAdditionalLine(data);
                }
            })
        );

        this.allSubscription.push(this.draftService.getUpdateDraft()
            .subscribe((update) => {
                if (update) {
                    this.updateOrderWithLastChanges();
                }
            })
        );

        this.allSubscription.push(this.orderService.getOrder()
            .subscribe((order) => {
                this.order = order;
                this.isAdditional = this.rateService.isAdditional(0, this.customerInfo, this.typeOrder, this.order);
            })
        );

        // Errores petición http o direcciones vacias
        this.allSubscription.push(this.normalizadorService.getErrorPeticionCobertura()
            .subscribe((value: any) => {
                this.muestraErrorPeticion = value;
            })
        );

        this.allSubscription.push(this.normalizadorService.getMsgErrorCobertura()
            .subscribe((value: any) => {
                this.msgErrorPeticion = value;
            })
        );

        this.allSubscription.push(combineLatest([this.normalizadorService.getThereIsCoverage(),this.normalizadorService.getClientIsCover()])
            .subscribe(([thereIsCoverage, clientIsCover]: any) => {
                this.thereIsCoverage = thereIsCoverage;
                this.clientIsCover = clientIsCover;

                let viData = this.simyoService.getVirtualInstallationData();
                this.simyoService.setCanVirtualInstallation(false);
                if(this.clientIsCover === true && thereIsCoverage === true && viData.gescal37 !== null) {
                    let clientData = this.clienteFormGroup.value;
                    if (viData.documentNumber == null
                        && clientData.account_doc !== null
                        && clientData.account_doc !== ''
                        && clientData.account_doctype !== null
                        && clientData.account_doctype !== '') {
                        this.simyoService.setVirtualInstallationData({
                            documentNumber: clientData.account_doc,
                            documentType: clientData.account_doctype
                        })
                    }
                    //update virtual installation
                    this.simyoService.postVirtualInstallationData().pipe(
                    catchError(error => of({msg: {message: 'ko'}})),
                    /*map(() => {
                        return {
                            "msg": {
                                "message": "ok",
                                "description": "Operation Successful",
                                "registerId": null,
                                "phoneNumber": "00000000"
                            }
                        }
                    }),*/
                    pluck('msg'),
                    map((response:responseReuseIVAccessSimyo) => {
                        if(response.message === 'ok') {

                            return {
                                ...response,
                                brand: response.brand?.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()) || ''
                            };
                        }
                        return response;
                    }),
                    tap((response:responseReuseIVAccessSimyo) => {
                        if(response.message !== 'ok') {
                            this.simyoService.setCanVirtualInstallation(false);
                            return;
                        }
                        this.simyoService.setCanVirtualInstallation(true);
                        this.simyoService.setResponseVirtualInstallationData(response)
                    }),

                )
                .subscribe()
                }
            })
        );

        this.allSubscription.push(this.normalizadorService.getClientIsCover()
            .subscribe((value: any) => {
                this.clientIsCover = value;
            })
        );

        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: Ipdv) => {
                if (data) {
                    this.pdv = data;
                }
            })
        );

        this.allSubscription.push(this.simyoService.getCustomerPromotion()
            .subscribe((data) => {
                this.customerInfo = data;
                this.isAdditional = this.rateService.isAdditional(0, this.customerInfo, this.typeOrder, this.order);
            })
        );
    }

    initForms() {
        this.fibraFormGroup = this.formBuilder.group({
            rate: ['', [Validators.required, blankSpaceValidator()]]
        });
        let arrayForms = [];
        if (this.typeOrder !== orderType.BROADBAND) {
            arrayForms = [this.setMobilePack()];
        }
        this.movilPackForms = this.formBuilder.array(arrayForms);
        this.movilPackFormGroup = this.formBuilder.group({
            form: this.movilPackForms
        });

        this.clienteFormGroup = this.formBuilder.group({
            doctype: ['', [Validators.required]],
            doc: ['', [Validators.required, blankSpaceValidator(), Validators.pattern('^[a-zA-Z0-9]+$')]],
            country: ['192', [Validators.required]],
            firstName: ['', [Validators.required, blankSpaceValidator()]],
            surname1: ['', [Validators.required, blankSpaceValidator()]],
            surname2: ['', /*Validators.required*/],
            birthday: ['', [Validators.required, isAdult()]],
            sex: [''/*, Validators.required*/],
            company: [''],
            tipoVia: [''],
            nombreVia: ['', [Validators.required, blankSpaceValidator()]],
            numero: ['', [Validators.required, blankSpaceValidator()]],
            planta: [''],
            puerta: [''],
            escalera: [''],
            bloque: [''],
            codigoPostal: ['', [Validators.required, blankSpaceValidator()]],
            localidad: ['', [Validators.required, blankSpaceValidator()]],
            provincia: ['', [Validators.required]],
            phone: ['', [Validators.required, Validators.pattern('^[6-9][0-9]{0,8}$'), Validators.minLength(9), Validators.maxLength(9)]],
            email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
            email_confirm: [{value:'', disabled:true}, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
            account_types: [''/*, Validators.required*/],
            bill_cicle_types: [''/*, Validators.required*/],
            // Postpago
            acct_code: [''],
            bank_account: [''],
            new_bank_account: [''],
            account_doctype: ['', [Validators.required]],
            account_doc: ['', [Validators.required, blankSpaceValidator(), Validators.pattern('^[a-zA-Z0-9]+$')]],
            account_country: ['', [Validators.required, blankSpaceValidator()]],
            account_firstName: ['', [Validators.required, blankSpaceValidator()]],
            account_surname1: ['', [Validators.required, blankSpaceValidator()]],
            account_surname2: ['', /*Validators.required*/],
            account_company: [''],
            // Prepago
            credit_card_type: [''],
            credit_card_name: [''],
            credit_card_number: [''],
            credit_card_date_of_expiry: [''],
            credit_card_cvv: [''],
            // Delivery address
            office: [''],
            full_name: [''],
            delivery_phone: [''],
            road_type: [''],
            address: [''],
            number: [''],
            floor: [''],
            door: [''],
            stairway: [''],
            zip: [''],
            city: [''],
            province: [''],
            additional_info: [''],
            is_custom_delivery: [''],
            //Portability change contact
            portability_origen_name: [''],
            portability_origen_lastname: [''],
            portability_origen_lastname2: [''],
            portability_origen_birth_date: [''],
            portability_origen_doc_type: [''],
            portability_origen_doc: [''],
            portability_origen_phone: [''],
            portability_origen_email: [''],
            portability_origen_email_confirm: [''],
            portability_origen_nationality_id: [192]
        }, {
            validators: [MustMatch('email', 'email_confirm'), ZipCodeFormat('codigoPostal'), MustMatch('portability_origen_email', 'portability_origen_email_confirm')]
        });

        /*this.confirmacionFormGroup = this.formBuilder.group({
            fourthCtrl: ['', Validators.required]
        });*/

        // Componentes
        this.normalizadorFormGroup = this.formBuilder.group({
            tipoViaNormaliza: ['', [Validators.required]],
            nombreViaNormaliza: ['', [Validators.required, blankSpaceValidator()]],
            numeroNormaliza: ['', [Validators.required, blankSpaceValidator()]],
            codigoPostalNormaliza: ['', [Validators.required, blankSpaceValidator()]],
            localidadNormaliza: ['', [Validators.required, blankSpaceValidator()]],
            provinciaNormaliza: ['', [Validators.required, blankSpaceValidator()]]
        });

        this.coberturaFormGroup = this.formBuilder.group({
            localidadCobertura: ['', [Validators.required, blankSpaceValidator()]],
            direccionesCobertura: ['', [Validators.required, blankSpaceValidator()]],
            tipoViaCobertura: ['', ''],
            nombreViaCobertura: ['', ''],
            numeroCobertura: ['', ''],
            provinciaCobertura: ['', ''],
            codigoPostalCobertura: ['', ''],
            plantaCobertura: ['', [Validators.required]],
            bisCobertura: [null, ''],
            bloqueCobertura: [null, ''],
            portalCobertura: [null, ''],
            escaleraCobertura: [null, ''],
            tipoPuertaCobertura: [null, ''],
            puertaCobertura: [null, ''],
            resultadoConsultaCobertura: ['', ''],
            numeroApartamento: ['']
        });

        this.customerFormGroup = this.formBuilder.group({
            customer_doctype: ['', [Validators.required]],
            customer_doc: ['', [Validators.required, blankSpaceValidator(), Validators.pattern('^[a-zA-Z0-9]+$')]],
            customer_birthday: [''],
            zip_code: ['', [Validators.required, blankSpaceValidator()]],
            country: [''],
        }, {
            validator: ZipCodeFormat('zip_code')
        });

        this.signatureFormGroup = this.formBuilder.group({
            smsCheck: new FormControl(false),
            smsData: new FormControl(''),
            emailCheck: new FormControl(true),
            emailData: new FormControl(''),
            documentationUploadCheck: new FormControl(false, Validators.requiredTrue),
            documentationUploadType: new FormControl('digital'),
            smsCheckChangeOwner: new FormControl(true),
            smsDataDonor: new FormControl(''),
            smsDataNewOwner: new FormControl(''),

        });
    }

    setMobilePack() {
        return this.formBuilder.group({
            type_operation: ['', [Validators.required]],
            type_pay: ['', [Validators.required]],
            rate: ['', [Validators.required, blankSpaceValidator()]],
            portability_phone: [''],
            portability_operator: [''],
            portability_type_client: [''],
            portability_iccid: [''],
            portability_client: [''],
            portability_date: [''],
            iccid: ['', [Validators.required, iccidValidator(), Validators.minLength(19)]],
            terminal: [''],
            terminal_payment_type: [''],
            terminal_insurance: [''],
            stepper_index: [''],
            is_esim: [typeCard.sim, Validators.required],
        }, {
            validators: [NotMustMatch('iccid', 'portability_iccid'), NotMustMatch('portability_iccid', 'iccid')]
        });
    }

    addMobilePack() {
        if (this.movilPackForms.length <= this.maxAdditionalRates) {
            this.movilPackForms.push(this.setMobilePack());
        }
        this.movilPackService.setAllowAddAdditionalLines(this.movilPackForms.length < this.maxAdditionalRates);
    }

    getMasters() {
        this.simyoService.getProvincesCommunFromService()
            .subscribe((data: any) => {
                if (data && data.msg) {
                    this.simyoService.setProvincesCommun(data.msg);
                }
            });

        this.simyoService.getRoadTypeCommunFromService()
            .subscribe((data: any) => {
                if (data && data.msg) {
                    this.simyoService.setRoadTypesCommun(data.msg);
                }
            });

        this.simyoService.getMasterAllFromService()
            .subscribe((data: masterAllResponseSimyo) => {
                if (data && data.msg) {
                    this.simyoService.setMasterAll(data.msg);
                }
            }, (error) => {
                if (error && error.error && error.error.error && error.error.error.errorCode !== null && error.error.error.errorCode === 462) {
                    this.dialog.open(UserNotFoundComponent, {
                        width: '350px',
                        disableClose: true,
                        data: {
                            marca: 'Simyo'
                          }
                    });
                }
            });
    }

    listenDraftServices() {
        this.allSubscription.push(this.draftService.getMobilePackForms()
            .subscribe((arrayForms) => {
                if (arrayForms.length) {
                    this.movilPackForms = this.formBuilder.array(arrayForms);
                    this.movilPackFormGroup = this.formBuilder.group({
                        form: this.movilPackForms
                    });
                }
            })
        );
    }

    defineTypeOrder() {
        if (this.router.url.includes('/simyo/convergente')) {
            this.simyoService.setTypeOrder(orderType.CONVERGENT);
        } else if (this.router.url.includes('/simyo/solo-fibra')) {
            this.simyoService.setTypeOrder(orderType.BROADBAND);
        } else {
            this.simyoService.setTypeOrder(orderType.MOBILE);
        }
    }

    removeAllAdditionalPostpaids(index){
        for (let i = index + 1; i < this.movilPackForms.length; i++) {
            if (this.movilPackForms.controls[i].value.contract_type === 'postpaid') {
                this.simyoService.setIndexToRemove(i);
                //this.orderService.removeOrder(i);
                this.simyoService.removeLine(i);
                i--;
            }
        }
    }

    removeAdditionalLine(index) {
        if (index !== 0) {
            const value = this.movilPackForms.value;

            this.movilPackForms.setValue(
                value.slice(0, index).concat(
                value.slice(index + 1),
                ).concat(value[index]),
            );
            this.movilPackForms.removeAt(value.length - 1);
        }
        this.movilPackService.setAllowAddAdditionalLines(this.movilPackForms.length < this.maxAdditionalRates);
    }

    // metodo que llamamos antes de guardar un borrador, debería meter todo al order aunque esté a medias
    updateOrderWithLastChanges() {
        if (this.typeOrder !== orderType.BROADBAND) {
            for (const [index, mobileForm] of this.movilPackForms.controls.entries()) {
                // Si tiene tarifa revisamos el formulario para guardarlo en el borrador
                if (mobileForm && mobileForm.get('rate') && mobileForm.get('rate').value && mobileForm.get('rate').value !== '') {
                    // Comprobamos que el iccid es valido y en caso contrario lo eliminamos
                    if (mobileForm.get('iccid').status === 'INVALID') {
                        mobileForm.get('iccid').patchValue('');
                    }
                    // Comprobamos que el portability_iccid es valido y en caso contrario lo eliminamos
                    if (mobileForm.get('portability_iccid').status === 'INVALID') {
                        mobileForm.get('portability_iccid').patchValue('');
                    }
                    this.simyoService.setMobilePacks(index, (mobileForm as FormGroup).getRawValue());
                }
            }
        }

        this.userDataService.setUserData(this.clienteFormGroup.getRawValue());
    }

    changeRate(type) {
        this.rate = null;
        this.simyoService.setIsChanging(true);
        this.stepper.previous();
        this.fibraFormGroup.get('rate').patchValue(null);
        this.showTypeContent = type;

        if (this.isAdditional) {
            this.rateService.setShowRateOnOffer({index: 0, status: false});
        }
    }

    next() {
        if (this.fibraFormGroup.valid) {
            this.stepper.next();
        }
    }

    public onStepChange(stepChange: StepperSelectionEvent) {
        if (!this.permSv.hasPermFromV2(allPermissions.others.users_demo)) {
            this.orderCreateIsFinished = false;
            // Se inicializa el valor a 'old' para el flujo antiguo de firma por lo que si tiene permiso del nuevo flujo a priori
            // asignamos el valor como manual y cuando seleccione el tipo de firma se mandará el valor correspondiente
            if (this.order.sign_type === 'old' && this.permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature)) this.orderService.setSignType('digital');

            if (this.digoService.isOpSimyoUser()) this.orderService.setSignType('not_required');

            this.orderService.createOrder(true, this.permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature) && this.stepper.steps.last === stepChange.selectedStep)
                .subscribe((data: any) => {
                    this.orderCreateIsFinished = true;
                    this.orderService.setOrderId(data.msg.orderId);
                }, error => {
                    console.error('[CREATE ORDER]', error);
                    this.orderCreateIsFinished = true;
                });
        }
        if (stepChange.previouslySelectedIndex > stepChange.selectedIndex) {
            stepChange.previouslySelectedStep.interacted = false;
           }
    }

    onActivationRequest(event) {
        this.isEditable = event;
    }

    goToHomeRates() {
        this.homeService.setViewBrandRates('am');
        this.hmRateService.setFilterRatesByType(this.orderType.CONVERGENT);
        this.router.navigate(['/']).then(() => this.normalizadorService.resetNormalizacion());
    }

    setAddress(type) {
        const auxForm = this.formBuilder.group({
            localidadCobertura: ['MADRID', [Validators.required, blankSpaceValidator()]],
            direccionesCobertura: ['CALLE , PORFIRIO , 15 , 28039', [Validators.required, blankSpaceValidator()]],
            tipoViaCobertura: ['CALLE', ''],
            nombreViaCobertura: ['PORFIRIO', ''],
            numeroCobertura: ['15', ''],
            provinciaCobertura: ['MADRID', ''],
            codigoPostalCobertura: ['28039', ''],
            plantaCobertura: ['Planta 1', [Validators.required, blankSpaceValidator()]],
            bisCobertura: [null, ''],
            bloqueCobertura: [null, ''],
            portalCobertura: [null, ''],
            escaleraCobertura: [null, ''],
            tipoPuertaCobertura: [null, ''],
            puertaCobertura: [null, ''],
            resultadoConsultaCobertura: [{
                cimaArvatoCode: "28079  5252    15",
                cimaBisCode: null,
                cimaBlockCode: null,
                cimaGescalCode: "28000010633200015         001        ",
                cimaINETownCode: "28079",
                cimaPortalCode: null,
                cimaStairCode: null,
                cimaStairOtherCode: null,
                cimaUniqueEntity: null,
                technology: [type],
                commercial_deal: null
            }, ''],
            numeroApartamento: ['']
        });
        this.normalizadorService.setMuestraCobertura(false);
        this.normalizadorService.setMuestraNormalizacion(false);
        this.normalizadorService.setResultadoCobertura(auxForm);
        if (availableTechnologies.includes(type)) {
            if(type == technologyTypes.dir) {
                this.simyoService.setVirtualInstallationData({
                    gescal37: "28000010633200015         001        ",
                    isDirectBroadband: true
                });
            }
            this.normalizadorService.setThereIsCoverage(true);
        } else {
            this.normalizadorService.setThereIsCoverage(false);
            this.normalizadorService.setClientIsCover(false);
            this.normalizadorService.setErrorPeticionCobertura(true);
            this.normalizadorService.setMsgErrorCobertura('Lo sentimos, no tienes cobertura de fibra a través de la red de Simyo');
        }
    }

    activationLoader(orderLoader) {
        this.orderLoader = orderLoader;
    }

    retryCheckCoverage() {
        this.retryCoverageSubject.next();
    }

    changeIsAdditionalIfNeed(fibra) {
        this.isAdditional = this.rateService.isAdditional(0, this.customerInfo, this.typeOrder, this.order, fibra.rate_info.speed);
        this.fibraRateSpeed = fibra.rate_info.speed;
    }

    updateFormFtth(ftth){
        if (ftth?.rate?.rate_info?.contracted_price){
            ftth.rate.rate_info.price = ftth.rate.rate_info.contracted_price;
        }
    }

    updateFormMobile(mobile){
        if (mobile?.rate?.rate_info?.contracted_price){
            mobile.rate.rate_info.real_price = mobile.rate.rate_info.contracted_price;
        }
    }

    ngOnDestroy() {
        console.log('DESTROY SIMYO COMPONENT');
        if (!this.preventDestroy) {
            // Reiniciamos formularios
            this.fibraFormGroup.reset();
            this.movilPackForms.reset();
            this.movilPackFormGroup.reset();
            this.clienteFormGroup.reset();
            //this.confirmacionFormGroup.reset();
            this.normalizadorFormGroup.reset();
            this.coberturaFormGroup.reset();
            this.customerFormGroup.reset();
            // Reiniciamos varaibles de control
            this.isLoading = false;
            this.isEditable = true;
            this.finished = false;
            this.typeOrder = '';
            // this.priceBonos = 0;
            this.orderLoader = null;
            // Reseteamos info del cliente
            this.simyoService.setDataFromNotCustomer(null);
            this.simyoService.setDataFromCustomer(null);
            this.simyoService.setCustomerPromotion(null);
            this.userDataService.setUserData(null);
            this.normalizadorService.setClientIsCover(false);
            // Reiniciamos normalización
            this.normalizadorService.resetNormalizacion();
            this.normalizadorService.setErrorPeticionCobertura(false);
            this.simyoService.setNebaNoCommercial(false);
            // Reiniciamos pedido
            this.orderService.resetOrder();
            this.simyoService.setRateFtth(null);
            this.simyoService.resetMobile();
            this.store.dispatch(setTotal({total: 0}));
            this.confirmationService.setFinished(false);
            this.simyoService.setTypeOrder(null);
            this.simyoService.setFormsData(null);
            this.rateService.setShowRateOnOffer({index: 0, status: true});
            this.rateService.setErrorRate(false)
            this.rateService.setErrorRateAdditional(false);
            this.movilPackService.setAllowAddAdditionalLines(true);

            this.simyoService.clearVirtualInstallationData();

            if (this.stepper) {
                this.stepper.reset();
            }
            if (this.allSubscription && this.allSubscription.length) {
                this.allSubscription.map(subs => subs.unsubscribe());
            }
            this.shoppingCartService.setShowIcon(false);
            this.shoppingCartService.setShow(false);
        }
    }

    validateUserData() {
        if (this.tabUserData.validate()) {
            this.userDataService.setUserData(this.clienteFormGroup.getRawValue());
        }
        if ( this.tabUserData.terminalDelivery !== undefined) {
            if (!this.clienteFormGroup.get('is_custom_delivery').valid) {
                this.tabUserData.terminalDelivery.errorForm = true;
            } else {
                this.tabUserData.terminalDelivery.errorForm = false;
            }
        }
    }

    getPriceBySpeed(speed) {
        return this.mobileRates[0]?.rate_info ? this.mobileRates[0]?.rate_info[`ftth_${speed}_price`] : '0';
    }

    setUser() {
        this.customerFormGroup.patchValue({
            customer_doctype: '2',
            customer_doc: '12345678Z',
            customer_birthday: formatDate('1991-05-05', 'yyyy-MM-dd', 'en'),
            zip_code: '13700'
        })
    }
}
