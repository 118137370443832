import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { fiberIndirectTechnologies, orderType, superStatus } from 'src/app/shared/constantes';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { SimyoDraftService } from 'src/app/simyo/services/simyo-draft.service';
import { SimyoOrderService } from 'src/app/simyo/services/simyo-order.service';
import { SimyoService } from 'src/app/simyo/services/simyo.service';
import { priceWithIVA } from 'src/app/utils/priceWithIVA';
import { SimyoGlobals } from '../../classes/simyo-globals';
import { masterAllSimyo, trackTypeSimyo } from '../../models/masterAllSimyo';
import { SimyoNormalizadorService } from '../../services/simyo-normalizador.service';
import { SimyoRateService } from '../../services/simyo-rate.service';
import * as moment from 'moment';

const typeReProcess = {
    full: 'full',
    partial: 'partial',
    onlyClient: 'cliente'
}

@Component({
    selector: 'app-simyo-modal-reprocess',
    templateUrl: './simyo-modal-reprocess.component.html',
    styleUrls: ['./simyo-modal-reprocess.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoModalReprocessComponent implements OnInit, OnDestroy {
    @Input() order: any;
    @Input() rates: any;
    @Input() showOptionClient: boolean = true;
    @Input() customerData: any;
    public typeReProcess = typeReProcess;
    public showConvergente: boolean = true;
    public showMovil: boolean = true;
    public showFibra: boolean = true;
    public orderType = orderType;
    public loading: boolean = false;
    public loadingText: string = 'Obteniendo datos del cliente...';
    public anyRateInvalid: boolean = false;
    public superStatus: any = superStatus;
    public errorOrder: boolean = false;
    public roadTypes: trackTypeSimyo[];
    public selected: any = {
        type: typeReProcess.full,
        countCanceledProduct: 0,
        elements: {
            fibra: false,
            movil: [],
            cliente: true,
        }
    }
    public allPermissions = allPermissions;
    public fiberIndirectTechnologies = fiberIndirectTechnologies;
    private allSubscription: Subscription[] = [];

    constructor(
        private router: Router,
        public activeModal: NgbActiveModal,
        private simyoService: SimyoService,
        private orderService: SimyoOrderService,
        private draftService: SimyoDraftService,
        private normalizadorService: SimyoNormalizadorService,
        private rateService: SimyoRateService,
        public simyoGlobals: SimyoGlobals,
        public permSv: PermissionService
    ) { }

    ngOnInit(): void {
        this.loading = true;
        if (!this.order.order_id) {
            this.orderService.getOrderFromService(this.order)
                .subscribe((data: any) => {
                    if (data && data.msg && data.msg.length) {
                        this.order = data.msg[0];
                        this.initOrder();
                    };
                }, (error) => {
                    this.errorOrder = true;
                });
        } else {
            this.initOrder();
        }

        this.allSubscription.push(this.simyoService.getMasterAll()
            .subscribe((data: masterAllSimyo) => {
                if (data) {
                    this.roadTypes = data.track_types;
                }
            })
        );
    }

    initOrder() {
        if (this.order && this.order.mobiles && this.order.mobiles.length) {
            this.selected.elements.movil = this.order.mobiles.map(x => false);
        }

        // Obtenemos el número de productos cancelados que tenemos
        this.selected.countCanceledProduct = 0;
        if (this.order && (this.order.super_status === superStatus.cancelled || this.order.can_retry)) {
            if (this.order.broadband) {
                this.selected.countCanceledProduct++;
                this.anyRateInvalidOrder(this.order.type);
            }
            if (this.order.mobiles && this.order.mobiles.length > 0) {
                this.selected.countCanceledProduct += this.order.mobiles.length;
                this.anyRateInvalidOrder(this.order.type);
                const foundTerminal = this.order.mobiles.find(movil => movil.terminal_oid);
                if (foundTerminal) {
                    this.simyoService.terminalsAreValid(this.order);
                }
            }
        }

        if (this.showOptionClient) {
            this.selected.type = typeReProcess.onlyClient;
        }

        if (!this.customerData && this.order.customer_id && this.order.customer_id !== '') {
            this.findCustomer();
        } else {
            this.loading = false;
        }
    }

    async copyOrderFull() {
        let path;
        const mobileForms = [];
        const forms = {
            fibra: null,
            movilPacks: null,
            cliente: null,
            normalizador: null,
            cobertura: null,
            disabledCliente: true,
            haveCoverage: true
        };

        if (this.order.broadband) {
            this.loading = true;
            this.loadingText = 'Comprobando cobertura...';
            forms.haveCoverage = await this.simyoGlobals.coverageIsValid(this.order.broadband, this.order.addresses.find(x => x.type === "installation"), this.roadTypes);
            if (forms.haveCoverage) {
                if (this.simyoGlobals.ftthRateIsValid(this.order, this.rates, this.simyoService.getMainLineWithoutSpeed(), this.simyoService.getShowFtthReduced(), this.simyoService.customerHasPackage())) {
                    forms.fibra = {
                        rate: {...this.draftService.getRate(this.order.broadband.rate, this.order.broadband.additional_info), ...this.simyoGlobals.getftthRateMatrix(this.order, this.rates)}
                    };
                } else {
                    forms.fibra = {
                        rate: null
                    };
                    this.rateService.setErrorRate(true);
                }
            } else {
                forms.fibra = {
                    rate: null
                };
            }
            forms.normalizador = this.draftService.getNormalizador(this.order.addresses.find(x => x.type === "installation"));
            forms.cobertura = this.draftService.getCobertura(this.order.addresses.find(x => x.type === "installation"), this.order.broadband);
        }

        if (this.order.mobiles && this.order.mobiles.length > 0) {
            this.order.mobiles.forEach((item: any, index) => {
                // Revisamos si la tarifa de movil existe actualmente y si es convergente se elimina la tarifa de fijo tb
                // Tarifa disponible pero no sabemos para que flujos
                this.setFtthMobilePrice(item.rate.rate_info);
                this.setOldPrice(item.rate.rate_info);
                if (!this.simyoGlobals.mobileRateIsValid(this.order, item, index, this.rates, this.order.type, this.customerData)) {
                    item.rate = null;
                    // item.bonos = [];
                    if (forms.fibra && index === 0) {
                        forms.fibra.rate = null;
                    }
                    if (this.rateService.isAdditional(index, this.customerData, this.order.type, this.order)) {
                        this.rateService.setErrorRateAdditional(true);
                    } else {
                        this.rateService.setErrorRate(true);
                    }
                }
                if (index === 0 && this.order.type === this.orderType.CONVERGENT && !forms.haveCoverage) {
                    item.rate = null;
                    // item.bonos = [];
                    if (forms.fibra) {
                        forms.fibra.rate = null;
                    }
                }
                if (item.terminal_oid && !item.isValidTerminal) {
                    item.terminal_oid = null;
                }
                let getMobilePack = this.draftService.getMobilePack(item);
                getMobilePack.iccid = '';
                mobileForms.push(getMobilePack);
            });
            forms.movilPacks = mobileForms;
        }
        forms.cliente = this.draftService.getUserDataFromOrder(this.order);

        this.orderService.resetOrder();
        await this.isClient(forms);
        // Convergente
        if (forms.fibra && forms.movilPacks) {
            path = this.draftService.getPath(orderType.CONVERGENT);
            this.simyoService.setTypeOrder(orderType.CONVERGENT);
        }
        // Solo Fibra
        if (forms.fibra && !forms.movilPacks) {
            path = this.draftService.getPath(orderType.BROADBAND);
            this.simyoService.setTypeOrder(orderType.BROADBAND);
        }
        // Solo movil
        if (!forms.fibra && forms.movilPacks) {
            path = this.draftService.getPath(orderType.MOBILE);
            this.simyoService.setTypeOrder(orderType.MOBILE);
        }
        this.simyoService.setFormsData(forms);
        this.activeModal.dismiss();
        this.router.navigate([path]);
    }

    async copyOrderPartial(type: string) {
        let auxOrder = JSON.parse(JSON.stringify(this.order));
        const path = this.draftService.getPath(type);
        const mobileForms = [];
        const forms = {
            fibra: null,
            movilPacks: null,
            cliente: null,
            normalizador: null,
            cobertura: null,
            disabledCliente: true,
            haveCoverage: true
        };

        // Si es modo solo cliente no deshabilitamos algunos formularios del cliente
        if (this.showOptionClient) {
            forms.disabledCliente = false;
        }

        if (this.selected.elements.fibra) {
            this.loading = true;
            this.loadingText = 'Comprobando cobertura...';
            forms.haveCoverage = await this.simyoGlobals.coverageIsValid(auxOrder.broadband, auxOrder.addresses.find(x => x.type === "installation"), this.roadTypes);
            if (forms.haveCoverage) {
                if (this.simyoGlobals.ftthRateIsValid(this.order, this.rates, this.simyoService.getMainLineWithoutSpeed(), this.simyoService.getShowFtthReduced(), this.simyoService.customerHasPackage())) {
                    forms.fibra = {
                        rate: {...this.draftService.getRate(this.order.broadband.rate, this.order.broadband.additional_info), ...this.simyoGlobals.getftthRateMatrix(this.order, this.rates)}
                    };
                } else {
                    forms.fibra = {
                        rate: null
                    };
                    this.rateService.setErrorRate(true);
                }
            } else {
                forms.fibra = {
                    rate: null
                };
            }
            forms.normalizador = this.draftService.getNormalizador(auxOrder.addresses.find(x => x.type === "installation"));
            forms.cobertura = this.draftService.getCobertura(auxOrder.addresses.find(x => x.type === "installation"), auxOrder.broadband);
        }

        if (this.selected.elements.movil.find(x => x === true)) {
            this.selected.elements.movil.forEach((element, index) => {
                if (element) {
                    // Revisamos si la tarifa de movil existe actualemente y si es convergente se elimina la tarifa de fijo tb
                    // Tarifa disponible pero no sabemos para que flujos
                    this.setFtthMobilePrice(auxOrder.mobiles[index].rate.rate_info);
                    this.setOldPrice(auxOrder.mobiles[index].rate.rate_info);
                    if (!this.simyoGlobals.mobileRateIsValid(this.order, auxOrder.mobiles[index], index, this.rates, type, this.customerData)) {
                        auxOrder.mobiles[index].rate = null;
                        // auxOrder.mobiles[index].bonos = [];
                        if (forms.fibra && index === 0) {
                            forms.fibra.rate = null;
                        }
                        if (this.rateService.isAdditional(index, this.customerData, this.order.type, this.order)) {
                            this.rateService.setErrorRateAdditional(true);
                        } else {
                            this.rateService.setErrorRate(true);
                        }
                    }
                    if (index === 0 && type === this.orderType.CONVERGENT && !forms.haveCoverage) {
                        auxOrder.mobiles[index].rate = null;
                        // auxOrder.mobiles[index].bonos = [];
                        if (forms.fibra) {
                            forms.fibra.rate = null;
                        }
                    }
                    if (auxOrder.mobiles[index].terminal_oid && !auxOrder.mobiles[index].isValidTerminal) {
                        auxOrder.mobiles[index].terminal_oid = null;
                    }
                    let getMobilePack = this.draftService.getMobilePack(auxOrder.mobiles[index]);
                    getMobilePack.iccid = '';
                    mobileForms.push(getMobilePack);
                }
            });
            forms.movilPacks = mobileForms;
        }

        if (this.selected.elements.cliente) {
            forms.cliente = this.draftService.getUserDataFromOrder(this.order);
        }

        this.orderService.resetOrder();
        await this.isClient(forms);
        this.simyoService.setTypeOrder(this.order.type);
        this.simyoService.setFormsData(forms);
        this.activeModal.dismiss();
        this.router.navigate([path]);
    }

    changeOptions() {
        const found = this.selected.elements.movil.find(x => x === true);
        this.showConvergente = this.showMovil = this.showFibra = false;
        this.anyRateInvalid = false;

        // Fibra y movil
        if (this.selected.elements.fibra && found) {
            this.showConvergente = true;
            this.anyRateInvalidOrder(orderType.CONVERGENT);
        }

        // Solo fibra
        if (this.selected.elements.fibra && !found) {
            this.showFibra = true;
            this.anyRateInvalidOrder(orderType.BROADBAND);
        }

        // Solo movil
        if (!this.selected.elements.fibra && found) {
            this.showMovil = true;
            this.anyRateInvalidOrder(orderType.MOBILE);
        }

        // Nada seleccionado
        if (!this.selected.elements.fibra && !found) {
            this.anyRateInvalidOrder(this.order.type);
        }
    }

    priceWithIVA(num) {
        return priceWithIVA(num)
    }

    findCustomer() {
        if (this.order.customer_document && this.order.customer_document !== '' && this.order.customer_document_type_id) {
            const body = {
                document_type_id: this.order.customer_document_type_id,
                document_number: this.order.customer_document,
                birth_date: this.order.customer_birth_date && this.order.customer_birth_date !== '' ? moment(this.order.customer_birth_date).format('YYYY-MM-DD') : undefined
            }
            this.simyoService.getCustomerInfoFromService(body)
                .subscribe((response : any) => {
                    if (response && response.msg) {
                        this.customerData = response.msg;
                        this.simyoService.setCustomerPromotion(this.customerData);
                        this.anyRateInvalidOrder(this.order.type);
                        this.orderService.set_existing_client(true);
                    }
                    if (response.error && response.error.errorCode && response.error.errorCode === 's631') {
                        this.orderService.set_existing_client(false);
                    }
                }, (error) => {
                    this.loading = false;
                }, () => {
                    this.loading = false;
                })
        }
    }

    async isClient(forms) {
        if (this.order.customer_id && this.order.customer_id !== '' && this.customerData) {
            this.normalizadorService.setClientIsCover(true);
            this.simyoService.setDataFromCustomer(this.customerData);
            this.simyoService.setCustomerPromotion(this.customerData);
            this.orderService.setCustomerId(this.order);
            await this.rateService.getRatesFromService(forms.cliente.codigoPostal)
            .toPromise()
            .then((data: any) => {
                if (data && data.msg) {
                    this.rateService.setRates(data.msg);
                }

            });
        } else {
            this.normalizadorService.setClientIsCover(false);
            this.simyoService.setDataFromNotCustomer(this.order);
            forms.cliente.new_bank_account = forms.cliente.bank_account;
        }
    }

    anyRateInvalidOrder(type) {
        this.anyRateInvalid = false;
        if (type === orderType.CONVERGENT) {
            this.order.broadband.isValidRate = this.simyoGlobals.ftthRateIsValid(this.order, this.rates, this.simyoService.getMainLineWithoutSpeed(), this.simyoService.getShowFtthReduced(), this.simyoService.customerHasPackage());
            this.anyRateInvalid = !this.order.broadband.isValidRate;
            this.order.mobiles.forEach((movil, index) => {
                movil.isValidRate = this.simyoGlobals.mobileRateIsValid(this.order, movil, index, this.rates, type, this.customerData);
                if (!movil.isValidRate) {
                    this.anyRateInvalid = true;
                }
            });
        }

        if (type === orderType.BROADBAND) {
            this.order.broadband.isValidRate = this.simyoGlobals.ftthRateIsValid(this.order, this.rates, this.simyoService.getMainLineWithoutSpeed(), this.simyoService.getShowFtthReduced(), this.simyoService.customerHasPackage());
            this.anyRateInvalid = !this.order.broadband.isValidRate;
        }

        if (type === orderType.MOBILE) {
            this.order.mobiles.forEach((movil, index) => {
                movil.isValidRate = this.simyoGlobals.mobileRateIsValid(this.order, movil, index, this.rates, type, this.customerData);
                if (!movil.isValidRate) {
                    this.anyRateInvalid = true;
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    setFtthMobilePrice(rate_info){
        if (!rate_info) {
            return "";
        }

        if (this.order.tax_type === 'igic') {
            rate_info.ftth_100_price = rate_info?.ftth_100_price_igic;
            rate_info.ftth_300_price = rate_info?.ftth_300_price_igic;
            rate_info.ftth_500_price = rate_info?.ftth_500_price_igic;
            rate_info.ftth_600_price = rate_info?.ftth_600_price_igic;
            rate_info.ftth_1_price = rate_info?.ftth_1_price_igic;
        }
        if (this.order.tax_type === 'ipsi_8') {
            rate_info.ftth_100_price = rate_info?.ftth_100_price_ipsi_8;
            rate_info.ftth_300_price = rate_info?.ftth_300_price_ipsi_8;
            rate_info.ftth_500_price = rate_info?.ftth_500_price_ipsi_8;
            rate_info.ftth_600_price = rate_info?.ftth_600_price_ipsi_8;
            rate_info.ftth_1_price = rate_info?.ftth_1_price_ipsi_8;
        }
        if (this.order.tax_type === 'ipsi_10') {
            rate_info.ftth_100_price = rate_info?.ftth_100_price_ipsi_10;
            rate_info.ftth_300_price = rate_info?.ftth_300_price_ipsi_10;
            rate_info.ftth_500_price = rate_info?.ftth_500_price_ipsi_10;
            rate_info.ftth_600_price = rate_info?.ftth_600_price_ipsi_10;
            rate_info.ftth_1_price = rate_info?.ftth_1_price_ipsi_10;

        }
        if (this.order.tax_type === 'iva') {
            rate_info.ftth_100_price = rate_info?.ftth_100_price_iva;
            rate_info.ftth_300_price = rate_info?.ftth_300_price_iva;
            rate_info.ftth_500_price = rate_info?.ftth_500_price_iva;
            rate_info.ftth_600_price = rate_info?.ftth_600_price_iva;
            rate_info.ftth_1_price = rate_info?.ftth_1_price_iva;
        }
    }

    setOldPrice(rate_info){
        if (rate_info) {
            rate_info.old_price = this.getOldPrice(rate_info);
        }
    }

    getOldPrice(rate_info){
        if (!rate_info) {
            return "";
        }
        if (this.order.tax_type === 'igic') {
            return rate_info.old_price_igic;
        }
        if (this.order.tax_type === 'ipsi_8') {
            return rate_info.old_price_ipsi_8;
        }
        if (this.order.tax_type === 'ipsi_10') {
            return rate_info.old_price_ipsi_10;
        }
        if (this.order.tax_type === 'iva') {
            return rate_info.old_price_iva;
        }
        return "";
    }
}
