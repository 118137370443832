<div class="container">
    <div class="row">
        <div class="col-lg-8 offset-2">
            <section class="centered--carga">
                <h3 class="fw-bold text-center" >
                    Activando los servicios
                </h3>
                <p class="text-center">
                    Manténgase a la espera, este proceso puede tardar varios segundos.
                </p>
                <br>

                <!-- Barra de carga -->
                <div class="barra--carga">
                    <div id="load-bar" class="move" [ngStyle]="{width: percentage + '%'}"></div>
                </div>
                <!-- ./ Barra de carga -->

            </section>
        </div>
    </div>
</div>
