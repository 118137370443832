/*
Para agregar un error, si este es especifico de un campo ponemos primero un identificador del campo y
dentro de ese objeto el listado de errores
*/
export const inputErros = {
    required: "Campo obligatorio.",
    doc: "Número de documento erróneo",
    blankSpace: "Campo obligatorio",
    invalid: "Campo obligatorio.",
    codigoPostal: {
        zipCodeInvalid: "Formato de CP incorrecto.",
        sameTax: "CP incorrecto."
    },
    phone: {
        pattern: "El número de teléfono es incorrecto",
        minlength: "El número de teléfono debe tener 9 dígitos",
        phonepattern: "El número de teléfono debe empezar por 6 o 7 y sólo números",
        duplicate: "Número igual al antiguo titular",

    },
    email: {
        pattern: "Correo electrónico no válido"
    },
    email_confirm: {
        pattern: "Correo electrónico no válido",
        mustMatch: "El correo electrónico no coincide"
    },
    birthday: {
        date_maximum: "El cliente debe ser mayor de edad.",
        date_minimum: "Fecha inválida."
    }
}
