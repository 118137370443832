import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtComponent } from './components/jwt/jwt.component';
import { TokenHasExpiredComponent } from './components/token-has-expired/token-has-expired.component';
import { NotificationCarouselComponent } from './components/notification-carousel/notification-carousel.component';
import { MainLoadingComponent } from './components/main-loading/main-loading.component';
import { IconsModule } from '../icons/icons.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        JwtComponent,
        TokenHasExpiredComponent,
        NotificationCarouselComponent,
        MainLoadingComponent
    ],
    exports: [
        JwtComponent,
        TokenHasExpiredComponent,
        NotificationCarouselComponent,
        MainLoadingComponent
    ],
    imports: [
        CommonModule,
        IconsModule,
        NgbModule
    ]
})
export class CoreModule { }
