import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { BonosAdicionalesOrderListResponse, BonosAdicionalesOrderResponse } from '../models/bonosAdicionalesInfoResponse';
import { CreateBonosAdicionalesRequest, CreateBonosAdicionalesResponse } from '../models/createBonosAdicionales';
import { MsisdnBalanceResponse } from '../models/msisdnBalanceResponse';
import { MsisdnInfoResponse } from '../models/msisdnInfoResponse';

@Injectable({
    providedIn: 'root'
})
export class OrangeBonosHiringService {

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) { }

    getMsisdnInfoFromService(body) {
        return this.http.post<MsisdnInfoResponse>(environment.orangeEndPoint + 'bonos/msisdn/info', {msisdn: body}, {headers: this.getHeaders()});
    }

    getMsisdnBalanceFromService(body) {
        return this.http.post<MsisdnBalanceResponse>(environment.orangeEndPoint + 'bonos/msisdn/balance', {msisdn: body}, {headers: this.getHeaders()});
    }

    getOrderBonosPrepago(id, notCheckStatus?: boolean) {
        return this.http.get<BonosAdicionalesOrderResponse>(environment.orangeEndPoint + 'bonos/' + id + (notCheckStatus ? '?check_status=0' : ''), {headers: this.getHeaders()});
    }

    listBonosPrepago(body) {
        return this.http.post<BonosAdicionalesOrderListResponse>(environment.orangeEndPoint + 'bonos/list', body, {headers: this.getHeaders()});
    }

    createBonosOrder(body: CreateBonosAdicionalesRequest) {
        return this.http.post<CreateBonosAdicionalesResponse>(environment.orangeEndPoint + 'bonos/create', body, {headers: this.getHeaders()});
    }

    getHeaders() {
        const token = 'Bearer ' + this.auth.getToken();

        return new HttpHeaders({
            Authorization: token,
            'Content-Type': 'application/json'
        });
    }
}
