<div class="container firma mt-4" *ngIf="!isLoading">
    <!-- Error al tramitar -->
    <ng-container *ngIf="activationError">
        <div class="alert alert-danger" role="alert">
            <h4 class="alert-heading mt-2"><b>Error</b></h4>
            <p *ngIf="activationError">{{ activationError.error.error.msg }}</p>
        </div>

        <button class="btn btn-black-outline d-block w-100 mb-5" (click)="retryCreate(false)" [disabled]="!orderCreateIsFinished">
            <b>{{ !orderCreateIsFinished ? 'Verificando pedido ' : 'Reintentar' }} <span *ngIf="!orderCreateIsFinished" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></b>
        </button>
    </ng-container>

    <!-- Cliente -->
    <app-simyo-summary-client
    [customer]="order.customer"
    [typeOrder]="typeOrder"
    [broadband]="order.broadband"
    [mobiles]="order.mobiles"
    [date]="date"
    [rateFtth]="rateFtth"></app-simyo-summary-client>

    <!-- Resumen tramitacion -->
    <app-simyo-summary-processing
    [typeOrder]="typeOrder"
    [broadband]="order.broadband"
    [mobiles]="order.mobiles"
    [rateFtth]="rateFtth"
    [customerData]="customerData"
    [ratesMobiles]="ratesMobiles"
    [showButtonsEditLine]="true"
    [showAllInfo]="false"
    (eventGoToStep)="goToStep($event)"
    (eventremoveLine)="removeExtraLine($event)"></app-simyo-summary-processing>

    <div *ngIf="!orderCreateIsFinished" class="loaderSimyo d-flex justify-content-center">
        <img class="" src="./assets/img/loader.svg" width="60" alt="">
    </div>
    <!-- Resumen de Contrato -->
    <div *ngIf="orderCreateIsFinished" class="card mt-3 mb-3">
        <div class="card-header bg-rm">
            <div class="d-flex justify-content-between">
                <div>
                    <h6 class="subtitulo-pack2"><b>Resumen de contrato</b></h6>
                </div>
            </div>
        </div>
        <div  class="card-body bg-white">
            <div class="row total my-0">
                <div class="col-lg-12 enviar p-0">
                    <div>
                        <button id="submit" type="submit" [disabled]="sended || loadingPrecontract || sendEmailTries < 3" class="btn btn-simyo-o2 btn-sm p-2" (click)="precontractSended()">
                            Enviar resumen de contrato
                        </button>
                        <!-- Enviando -->
                        <span *ngIf="loadingPrecontract">
                            Enviando...
                        </span>
                        <span *ngIf="sendEmailTries > 0 && sendEmailTries < 3">
                            Reintentando el envío de email...
                        </span>
                    </div>
                    <div>
                        <!-- Enviado -->
                        <a *ngIf="precontract && hash !== '' && !loadingPrecontract" (click)="download()" class="ms-2 buttonss button5 cursor-pointer" style="position: relative;" [title]="errorDownload ? 'Error al descargar el documento. Inténtelo más tarde' : 'Descargar documento'">
                            <i-feather name="download" class="icon-tables"></i-feather>
                            <span *ngIf="errorDownload" class="dot-cancel-red icon-error"></span>
                        </a>
                        <!-- No Enviado -->
                        <mat-error *ngIf="precontract && hash === '' && !loadingPrecontract">
                            Error al enviar resumen de contrato. Inténtelo más tarde
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="digoService.isOpSimyoUser() === false">
        <!-- Documentacion -->
        <div *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature) && (downloaded || sended)">
            <app-simyo-documentation-signature [dataOrderToSignature]="dataOrderToSignature" [orderCreateIsFinished]="orderCreateIsFinished" [documentationMode]="documentationMode.uploadDocumentation" [signatureFormGroup]="signatureFormGroup" [fullOrder]="order" [pdv]="pdv" (stateDocumentation)="showActivateButtonEvent($event)"></app-simyo-documentation-signature>
            <br>
        </div>

    <div class="row" *ngIf="isShowMsgInstalationVirtual()  && (downloaded || sended)" >
        <div class="col-xl-12">
            <div class="card mb-3">
                <div class="card-header">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <h6 class="subtitulo-pack2">
                                <b>Instalación Virtual</b>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="card-body bg-white">
                    <div class="content-iv border-simyo mt-0 ms-3">
                        <p>Hemos detectado que en esta dirección tienes instalado con <b>{{dataIV.brand | titlecase }}</b> la línea <b>{{dataIV.phoneNumber}}</b>.<br>
                            Vamos a realizar la instalación sobre el equipo que tienes sin necesidad de que acuda un técnico a tu domicilio.
                            Recuerda que esta gestión supondrá <b>la baja del número {{dataIV.phoneNumber}}</b>, y es necesario que el equipo lo tengas encendido.<br><br>
                            <b>¿Deseas realizar el trámite sobre esta instalación?</b>
                        </p>
                    </div>
                    <div class="container d-flex justify-content-end">
                        <button type="button" class="btn btn-iv me-2" [ngClass]="{ 'active-iv' : instalationVirtualFormGroup.get('instalationVirtualCheck').value === false }" (click)="confirmIV(false)">No, alta nueva</button>
                        <button type="button" class="btn btn-iv" [ngClass]="{ 'active-iv' : instalationVirtualFormGroup.get('instalationVirtualCheck').value === true }" (click)="confirmIV(true)">Sí, quiero reutilizar la instalación</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <!-- Agreements -->
        <div *ngIf="!permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature) && (downloaded || sended)">
            <app-simyo-agreements></app-simyo-agreements>
            <br>
        </div>
    </ng-container>
    <!-- Boton activacion -->
    <div *ngIf="isShowActivateButton()">
        <div class="row">
            <div class="col-md-12">
                <mat-error class="mb-2" *ngIf="showMsgValidateDocumentation">
                    Se debe validar la documentación para poder activar los servicios
                </mat-error>
                <button (click)="activateService(false)" class="btn btn-simyo-o2 d-block w-100 mb-5" [ngClass]="{'disabled-2': isDisabled()}">
                    <b>{{ !orderCreateIsFinished ? 'Verificando pedido ' : 'Activar servicios' }} <span *ngIf="!orderCreateIsFinished" style="position: inherit !important;" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></b>
                </button>
            </div>
        </div>
    </div>
</div>

<app-simyo-loader *ngIf="isLoading" (onPreloadingComplete)="preloadingComplete($event)" [typeOrder]="typeOrder" [startGetStatus]="startGetStatusTime"></app-simyo-loader>

<ng-template #check1 let-modal>
    <div class="modal-header bg-simyo-grey">
        <h5 class="modal-title white" id="exampleModalLabel">Más información</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">
                <img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20">
            </span>
        </button>
    </div>
    <div class="p-5">
        Simyo te enviará ofertas y promociones de sus propios productos y servicios que puedan resultarte de tu interés, en base a la información que disponemos de tus datos generados por tus servicios, como tráfico, navegación, y localización. (P. ej. Conociendo tus hábitos de consumo podemos ofrecerte la tarifa que mejor se adapta a tus necesidades y las ofertas que están más cerca de ti)
    </div>
</ng-template>
