<section class="procesos">
    <div *ngIf="!activationsByMonthError">
        <div class="row mb-2">
            <div class="col-xl-12 text-center" [formGroup]="filterFormGroup" [hidden]="!activationsByMonth">
                <div class="card--sidebar mt-2">
                    <div class="card-body filters">
                        <div class="btn-group col-12 mt-2">
                            <div style="align-self: center; width: 20%; text-align: initial;" [formGroup]="customFilterFormGroup">
                                <mat-slide-toggle hideIcon="true" formControlName="allBrands" (change)="allBrands()">
                                    Todas las marcas
                                </mat-slide-toggle>
                            </div>
                            <div class="mx-auto brands">
                                <label style="margin-left: 6px;" *ngIf="!permSv.hasPermFromV2(allPermissions.simyo.bloquea_ventas)">
                                    <input type="checkbox" [formControlName]="brands.simyo" [value]="brands.simyo"
                                        (change)="changeFilterBrand($event.target.value, $event.target.checked)"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/sm-login-color.svg" class="img-fluid ps-1" width="120">
                                    </span>
                                </label>
                                <label style="margin-left: 6px;" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas) || permSv.hasPermFromV2(allPermissions.jazztel.tuvo_activaciones)">
                                    <input type="checkbox" [formControlName]="brands.jazztel" [value]="brands.jazztel"
                                        (change)="changeFilterBrand($event.target.value, $event.target.checked)" />
                                    <span class="btn-marca">
                                        <img src="./assets/img/jz-login-color.svg" class="img-fluid ps-1" width="120">
                                    </span>
                                </label>
                                <label title="Incluye Go" style="margin-left: 6px;" *ngIf="permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago) || permSv.hasPermFromV2(allPermissions.orange.tuvo_activaciones_prepago) || permSv.hasPermFromV2(allPermissions.orange.tuvo_activaciones_pospago)">
                                    <input type="checkbox" [formControlName]="brands.orange" [value]="brands.orange"
                                        (change)="changeFilterBrand($event.target.value, $event.target.checked)"/>
                                    <span class="btn-marca">
                                        <img src="./assets/img/or-login-color.svg" class="img-fluid ps-1" width="120">
                                    </span>
                                </label>
                            </div>
                            <div style="width: 20%;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Ventas de Fibra -->
        <div class="form-row mb-2" [hidden]="hiddenFibra || !permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)">
            <div class="col-xl-9" [hidden]="!activationsByMonth">
                <div class="card--sidebar mt-2">
                    <div class="card-body" style="height:350px;">
                        <canvas #lineCanvasFibra></canvas>
                    </div>
                </div>
            </div>
            <div class="col-xl-3" [hidden]="!activationsByMonth">
                <div class="card--sidebar mt-2">
                    <div class="card-body" style="height:350px;">
                        <div class="d-flex flex-column align-items-center text-center">
                            <h5 class="mt-4">
                                Mes actual: <b>{{ monthNames[today.getMonth()] }}</b>
                            </h5>
                            <br><br>
                            <div [ngClass]="{'cursor-pointer':permSv.hasPermFromV2(allPermissions.others.access_night)}" (click)="viewOperation(typeFilterFormOperation.totalActivationsFibra)">
                                <span class="title-p2 mt-2">Fibra</span>
                                <br>
                                <span class="kpi1">{{ activationStatistics.countActualMonthFibra }}</span>
                            </div>
                            <br>
                            <span title="Estimación a final de mes"
                                [ngClass]="{'kpi-per-positive1' : activationStatistics.estimatedFibra > 0, 'kpi-per-negative1': activationStatistics.estimatedFibra < 0, 'kpi-per-neutro': activationStatistics.estimatedFibra === 0 }">
                                {{ getAbsoluteValue(activationStatistics.estimatedFibra) }}%
                            </span>
                            <div>
                                <span class="kpi-per-mes me-1">{{ monthNames[lastMonth] }}</span>
                                <span class="kpi-a">{{ activationStatistics.countPreviousMonthFibra }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!activationsByMonth" class="col-xl-12">
                <div class="card--sidebar mt-2">
                    <div class="my-5">
                        <h5  class="tar-name-preload text-center">Obteniendo ventas de Fibra...</h5>
                        <div class="d-flex justify-content-center">
                            <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Ventas de Pospago -->
        <div class="form-row mb-2" [hidden]="hiddenPostpago">
            <div class="col-xl-9" [hidden]="!activationsByMonth">
                <div class="card--sidebar mt-2">
                    <div class="card-body" style="height:350px;">
                        <canvas #lineCanvasPospago></canvas>
                    </div>
                </div>
            </div>
            <div class="col-xl-3" [hidden]="!activationsByMonth">
                <div class="card--sidebar mt-2">
                    <div class="card-body" style="height:350px;">
                        <div class="d-flex flex-column align-items-center text-center">
                            <h5 class="mt-4">
                                Mes actual: <b>{{ monthNames[today.getMonth()] }}</b>
                            </h5>
                            <br><br>
                            <div [ngClass]="{'cursor-pointer':permSv.hasPermFromV2(allPermissions.others.access_night)}" (click)="viewOperation(typeFilterFormOperation.totalActivationsPostpago)">
                                <span class="title-p2 mt-2">Pospago</span>
                                <br>
                                <span class="kpi1">{{ activationStatistics.countActualMonthPospago }}</span>
                            </div>
                            <br>
                            <span title="Estimación a final de mes"
                                [ngClass]="{'kpi-per-positive1' : activationStatistics.estimatedPospago > 0, 'kpi-per-negative1': activationStatistics.estimatedPospago < 0, 'kpi-per-neutro': activationStatistics.estimatedPospago === 0 }">
                                {{ getAbsoluteValue(activationStatistics.estimatedPospago) }}%
                            </span>
                            <div>
                                <span class="kpi-per-mes me-1">{{ monthNames[lastMonth] }}</span>
                                <span class="kpi-a">{{ activationStatistics.countPreviousMonthPospago }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!activationsByMonth" class="col-xl-12">
                <div class="card--sidebar mt-2">
                    <div class="my-5">
                        <h5  class="tar-name-preload text-center">Obteniendo ventas de Pospago...</h5>
                        <div class="d-flex justify-content-center">
                            <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Ventas de Prepago -->
        <div class="form-row mb-2" [hidden]="hiddenPrepago">
            <div class="col-xl-9" [hidden]="!activationsByMonth">
                <div class="card--sidebar mt-2">
                    <div class="card-body" style="height:350px;">
                        <canvas #lineCanvasPrepago></canvas>
                    </div>
                </div>
            </div>
            <div class="col-xl-3" [hidden]="!activationsByMonth">
                <div class="card--sidebar mt-2">
                    <div class="card-body" style="height:350px;">
                        <div class="d-flex flex-column align-items-center text-center">
                            <h5 class="mt-4">
                                Mes actual: <b>{{ monthNames[today.getMonth()] }}</b>
                            </h5>
                            <br><br>
                            <div [ngClass]="{'cursor-pointer':permSv.hasPermFromV2(allPermissions.others.access_night)}" (click)="viewOperation(typeFilterFormOperation.totalActivationsPrepago)">
                                <span class="title-p2 mt-2">Prepago</span>
                                <br>
                                <span class="kpi1">{{ activationStatistics.countActualMonthPrepago }}</span>
                            </div>
                            <br>
                            <span title="Estimación a final de mes"
                                [ngClass]="{'kpi-per-positive1': activationStatistics.estimatedPrepago > 0, 'kpi-per-negative1': activationStatistics.estimatedPrepago < 0, 'kpi-per-neutro': activationStatistics.estimatedPrepago === 0 }">
                                {{ getAbsoluteValue(activationStatistics.estimatedPrepago) }}%
                            </span>
                            <div>
                                <span class="kpi-per-mes me-1">{{ monthNames[lastMonth] }}</span>
                                <span class="kpi-a">{{ activationStatistics.countPreviousMonthPrepago }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!activationsByMonth" class="col-xl-12">
                <div class="card--sidebar mt-2">
                    <div class="my-5">
                        <h5  class="tar-name-preload text-center">Obteniendo ventas de Prepago...</h5>
                        <div class="d-flex justify-content-center">
                            <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5" *ngIf="activationsByMonthError">
        <h5  class="tar-name-preload text-center">Se ha producido un error, inténtalo de nuevo más tarde</h5>
    </div>
</section>
