<section class="procesos">
    <div class="row my-2">
        <!-- Mensaje informativo -->
        <div class="col-xl-12 mt-2" *ngIf="pdv?.info?.document_type && (pdv?.info?.document_type === 'DNI' || pdv?.info?.document_type === 'CIF' || pdv?.info?.document_type === 'NIE')">
            <div class="col-xl-12 mb-2 info-notify" style="border: 1px solid;">
                <p class="mt-3" style="font-size: 0.9rem; padding: 0 15px;" *ngIf="pdv?.info?.document_type === 'DNI' || pdv?.info?.document_type === 'CIF'">
                    Es muy importante que si cambia algún dato de tu punto de venta, nos lo comuniques a la mayor brevedad <b>para evitar incidencias ante la Agencia Tributaria.</b> Los datos más importantes son: {{ pdv?.info?.document_type }}, nombre, dirección, actividad económica y datos bancarios.
                </p>
                <p class="mt-3" style="font-size: 0.9rem;" *ngIf="pdv?.info?.document_type === 'NIE'">
                    En caso de que <b>tu NIE cambie o se convierta en DNI</b>, es importante que nos lo comuniques con urgencia <b>para evitar incidencias y sanciones ante la Agencia Tributaria.</b> Te recordamos que también deberás de avisarnos si cambian otros datos como por ejemplo tu nombre, dirección o datos de pago.
                </p>
            </div>
        </div>

        <!-- Simyo rappel -->
        <div class="col-xl-8 my-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender) || permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
            <div *ngIf="rappelSimyoData">
                <div class="card--sidebar">
                    <div class="card-body">
                        <div *ngIf="rappelSimyoData.current">
                            <div class="row">
                                <div class="col-8">
                                    <span>
                                        <b>Categoría actual: </b><b class="orange">{{ rappelSimyoData.current.text }} </b><span class="badge badge-warning-orange">{{ rappelSimyoData.current.current_activations }} activaciones</span>
                                    </span>
                                </div>
                                <div class="col-4 pe-0 d-flex justify-content-end" >
                                    <img src="./assets/img/sm-login-color.svg" class="img-fluid logo--tarifa" width="80" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_vender)">
                                    <img src="./assets/img/jz-login-color.svg" class="img-fluid logo--tarifa mb-1" width="80" *ngIf="permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
                                </div>
                            </div>
                            <div class="progress mt-3 pro-com">
                                <div class="progress-bar progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngStyle]="{ 'width.%': getWidth() }" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <small class="text-muted" *ngIf="rappelSimyoData?.current?.next_level">
                                Te quedan <b>{{ rappelSimyoData.current.next_level.min_activations - rappelSimyoData.current.current_activations }}</b> activaciones para pasar a la categoría <span class="orange">{{ rappelSimyoData.current.next_level.text }}</span>
                            </small>
                            <ng-container *ngIf="today?.date() > dayToShowCategoryRappelPreviousMonth">
                                <br>
                                <small class="text-muted">
                                    Categoría mes anterior <b class="orange">{{ translateCategoryRappel[pdv.info.clasificacion] || 'Básico' }}</b>
                                </small>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!rappelSimyoData && !rappelSimyoDataError">
                <div class="card--sidebar mt-2">
                    <div class="my-5">
                        <h5 class="tar-name-preload text-center">Obteniendo rappel de Simyo...</h5>
                        <div class="d-flex justify-content-center">
                            <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="card--sidebar mt-5" *ngIf="rappelSimyoDataError">
                <h5 class="tar-name-preload text-center">Se ha producido un error, inténtalo de nuevo más tarde</h5>
            </div>
        </div>

        <!-- Mis comisiones -->
        <div class="my-2" [ngClass]="{'col-xl-12': !(permSv.hasPermFromV2(allPermissions.simyo.permite_vender) || permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)),'col-xl-4': permSv.hasPermFromV2(allPermissions.simyo.permite_vender) || permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)}">
            <div [hidden]="commissionsDataError || !commissionsData">
                <div class="card--sidebar">
                    <div class="card-body" style="height:242px;">
                        <p class="com-bar2 mt-1 mb-0">
                            <b>
                                <img src="./assets/img/euro.svg" class="img-fluid avisos--sidebar--icon" width="16"> Mis comisiones
                            </b>
                        </p>
                        <div style="height:175px;">
                            <canvas #barCanvasCommissions></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card--sidebar mt-2 py-4 px-3" *ngIf="commissionsDataError && !commissionsData">
                <h5 class="tar-name-preload text-center">Se ha producido un error, inténtalo de nuevo más tarde</h5>
            </div>
            <div *ngIf="!commissionsDataError && !commissionsData">
                <div class="card--sidebar">
                    <div class="my-5">
                        <h5 class="tar-name-preload text-center">Obteniendo comisiones...</h5>
                        <div class="d-flex justify-content-center">
                            <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Autofacturas -->
        <div class="col-xl-12">
            <div class="card--sidebar mt-2" *ngIf="!autoInvoiceDataError && autoInvoiceData">
                <div class="card-body--sidebar">
                    <div class="row">
                        <div class="col-12 col-md pt-1">
                            <i-feather name="file" class="icon--fe" stroke-width="2.5"></i-feather>
                            <b> Mis autofacturas</b>
                        </div>
                        <div class="col-12 col-md-auto" *ngIf="autoInvoiceData.mr != null">
                            <div (click)="openMRDialog()" class="link-black-factura cursor-pointer">
                                <img src="./assets/img/pdf.svg" width="42" style="position: relative; top: -1px;"> Marcos Retributivos
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer--sidebar">
                    <table mat-table [dataSource]="dataSource" *ngIf="dataSource.length">
                        <!-- Index Column -->
                        <ng-container matColumnDef="index">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> # </th>
                            <td mat-cell *matCellDef="let element; let i = index" class="link-black-factura fw-bold"> {{ i + 1 }} </td>
                        </ng-container>

                        <!-- Nº de factura -->
                        <ng-container matColumnDef="invoice_number">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Nº de factura </th>
                            <td mat-cell *matCellDef="let element"> {{ element.invoice_number }} </td>
                        </ng-container>

                        <!-- Fecha emisión -->
                        <ng-container matColumnDef="issue_date">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Fecha emisión </th>
                            <td mat-cell *matCellDef="let element"> {{ element.issue_date }} </td>
                        </ng-container>

                        <!-- Conceptos/descripción -->
                        <ng-container matColumnDef="concepts">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Conceptos/descripción </th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngFor="let concept of element.concepts" class="col-12 p-0">
                                    {{ concept }}
                                </div>
                            </td>
                        </ng-container>

                        <!-- Periodo facturación -->
                        <ng-container matColumnDef="billing_period">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Periodo facturación </th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngFor="let period of element.billing_period" class="col-12 p-0">
                                    {{ period }}
                                </div>
                            </td>
                        </ng-container>

                        <!-- Importe base -->
                        <ng-container matColumnDef="base_amount">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Importe base </th>
                            <td mat-cell *matCellDef="let element">  {{ element.base_amount }} € </td>
                        </ng-container>

                        <!-- Importe total -->
                        <ng-container matColumnDef="total_amount">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Importe total </th>
                            <td mat-cell *matCellDef="let element"> {{ element.total_amount }} € </td>
                        </ng-container>

                        <!-- Descargas -->
                        <ng-container matColumnDef="download">
                            <th mat-header-cell *matHeaderCellDef class="header-column"> Descargas </th>
                            <td mat-cell *matCellDef="let element">
                                <a *ngIf="element.link_pdf" [href]="element.link_pdf" target="_blank">
                                    <img src="./assets/img/pdf.svg" width="42" style="position: relative; top: -1px;">
                                </a>
                                <a *ngIf="element.link_xls" [href]="element.link_xls" target="_blank" class="ms-2">
                                    <img src="./assets/img/xls.svg" width="42" style="position: relative; top: -1px;">
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <div *ngIf="!dataSource.length">
                        <h5 class="tar-name-preload text-center">Aún no se ha generado ninguna factura</h5>
                    </div>
                    <div *ngIf="dataSource.length < autoInvoiceData?.invoices?.length">
                        <button class="btn btn-black--outline btn-sm mb-1 mt-4 w-100" (click)="nextPage()">Cargar más</button>
                    </div>
                </div>
                <div class="card--sidebar mt-2">
                    <div class="card-header--sidebar2">
                        <div class="row">
                            <div class="col-12 col-md">
                                <i-feather name="info" class="icon--fe" stroke-width="2.5"></i-feather>
                                <b> ¿Dudas con tus autofacturas?</b>
                            </div>
                            <div class="col-12 col-md-auto">
                                <a (click)="openSupportDialog()" class="link-white cursor-pointer" style="font-weight: 400;">
                                    Ir a soporte <i-feather name="arrow-right" class="icon--fe" stroke-width="2.5"></i-feather>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card--sidebar mt-2 py-4" *ngIf="autoInvoiceDataError && !autoInvoiceData">
                <h5 class="tar-name-preload text-center">Se ha producido un error, inténtalo de nuevo más tarde</h5>
            </div>
            <div *ngIf="!autoInvoiceDataError && !autoInvoiceData" class="card--sidebar">
                <div class="my-5">
                    <h5 class="tar-name-preload text-center">Obteniendo autofacturas...</h5>
                    <div class="d-flex justify-content-center">
                        <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!-- Informe anual -->
        <div class="col-xl-12" *ngIf="!autoInvoiceDataError && autoInvoiceData">
            <div class="card--sidebar mt-2">
                <div class="card-header--sidebar">
                    <div class="row">
                        <div class="col-12 col-md">
                            <i-feather name="file" class="icon--fe" stroke-width="2.5"></i-feather>
                            <b> Informe anual de operaciones {{ lastYear }} <span class="g-light">(Modelo 347)</span></b>
                        </div>
                        <div class="col-12 col-md-auto">
                        <a [href]="autoInvoiceData.anual_operation_report" target="_blank" class="link-black-factura">
                            <img src="./assets/img/pdf.svg" width="42" style="position: relative; top: -1px;"> Descargar
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
