import { Component, Input, OnInit } from '@angular/core';
import { Ipdv } from 'src/app/shared/models/pdvResponse';

@Component({
  selector: 'app-main-loading',
  templateUrl: './main-loading.component.html',
  styleUrls: ['./main-loading.component.css']
})
export class MainLoadingComponent implements OnInit {
    @Input() pdv: Ipdv;
    constructor() { }

    ngOnInit(): void {
    }

}
