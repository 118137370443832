import { createReducer, on } from '@ngrx/store';
import { setTotal } from './simyo.actions';

export interface ISimyoState {
    shopping_cart_total: number;
}

export const initialState: ISimyoState = {
    shopping_cart_total: 0
};
 
const reducer = createReducer(initialState,
    on(setTotal, (state, { total }) => (
        { ...state, shopping_cart_total: total })
    )
);
 
export function simyoReducer(state, action) {
    return reducer(state, action);
}