import {AbstractControl, ValidatorFn} from '@angular/forms';

export function autocompleteValidator(array: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value === null || control.value === '') {
            return null;
        }

        if (array.length === 0) {
            return {match: true};
        }

        const filtered = array.filter((item) => item === control.value);

        if (filtered.length > 0) {
            return null;
        } else {
            return {match: true};
        }
    };
}