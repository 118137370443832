<h5 class="title-step mt-0 mb-3 text-center">{{ title }}</h5>

<div>
    <p [innerHTML]="message"></p>
</div>

<div class="row">
  <div class="col-sm-6">
    <button class="btn d-block w-100 btn-simyo-o2" (click)="goRefresh()">{{optionRedirect}}</button>
  </div>
    <div class="col-sm-6">
        <button class="btn d-block w-100 btn-simyo-o2" (click)="stay()">{{optionStay}}</button>
    </div>
</div>
