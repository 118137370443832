import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SimyoService } from '../../services/simyo.service';
import { ValidatorFn, Validators } from '@angular/forms';
import { dniValidator, nieValidator, cifValidator, getMaxLengthByDoc } from 'src/app/utils/validators/spanish-id-validator';
import { brands } from 'src/app/shared/constantes';
import { Subscription } from 'rxjs';
import { countrySimyo, documentTypeSimyo, masterAllSimyo } from '../../models/masterAllSimyo';
import { DialogData } from 'src/app/shared/models/dialogData';

@Component({
    selector: 'app-simyo-user-banking-data',
    templateUrl: './simyo-user-banking-data.component.html',
    styleUrls: ['./simyo-user-banking-data.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoUserBankingDataComponent implements OnInit, OnDestroy {

    public country = environment.googleMapsCountry;
    public requireCif = false;
    public maxLengthByDoc = 9;

    // Master
    public documentTypes: documentTypeSimyo[] = [];
    public countries: countrySimyo[] = [];

    private allSubscription: Subscription[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialogRef: MatDialogRef<SimyoUserBankingDataComponent>,
        private simyoService: SimyoService
    ) { }

    ngOnInit(): void {
        this.allSubscription.push(this.simyoService.getMasterAll()
            .subscribe((data: masterAllSimyo) => {
                if (data) {
                    this.documentTypes = data.document_types;
                    this.countries = data.countries;
                }
            })
        );

        this.changeTypeDocument(this.data.formGroup.get('account_doctype').value);
    }

    copyUserData() {
        const userData = this.data.formGroup.getRawValue();

        this.data.formGroup.get('account_doctype').patchValue(userData.doctype);
        this.data.formGroup.get('account_doc').patchValue(userData.doc);
        this.data.formGroup.get('account_country').patchValue(userData.country);
        this.data.formGroup.get('account_firstName').patchValue(userData.firstName);
        this.data.formGroup.get('account_surname1').patchValue(userData.surname1);
        this.data.formGroup.get('account_surname2').patchValue(userData.surname2);
        this.data.formGroup.get('account_surname2').patchValue(userData.surname2);
        this.data.formGroup.get('account_company').patchValue(userData.company);

        this.changeTypeDocument(userData.doctype);
    }

    changeTypeDocument(value) {
        const docValidators: ValidatorFn[] = [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')];

        if (typeof value === 'number') {
            value = value.toString();
        }

        this.requireCif = value === '4';

        if (this.requireCif) {
            this.data.formGroup.get('account_company').setValidators([Validators.required]);
        } else {
            this.data.formGroup.get('account_company').clearValidators();
        }

        switch (value) {
            case '2':
                docValidators.push(dniValidator());
                break;
            case '1':
                if (this.data.formGroup.get('account_country').value === '192') {
                    this.data.formGroup.get('account_country').patchValue(null);
                }
                docValidators.push(nieValidator());
                break;
            case '4':
                docValidators.push(cifValidator());
                break;
            default:
        }

        this.data.formGroup.get('account_doc').setValidators(docValidators);
        this.data.formGroup.get('account_doc').updateValueAndValidity();
        this.data.formGroup.get('account_company').updateValueAndValidity();
    }

    save() {
        if (this.validate()) {
            this.dialogRef.close(this.data);
        }
    }

    validate(): boolean {
        this.data.formGroup.markAllAsTouched();
        this.data.formGroup.updateValueAndValidity();

        if (
            this.data.formGroup.get('account_doctype').valid &&
            this.data.formGroup.get('account_doc').valid &&
            this.data.formGroup.get('account_country').valid &&
            this.data.formGroup.get('account_firstName').valid &&
            this.data.formGroup.get('account_surname1').valid &&
            this.data.formGroup.get('account_surname2').valid &&
            this.data.formGroup.get('account_surname2').valid &&
            this.data.formGroup.get('account_company').valid
        ) {
            return true;
        } else {
            const elements: any = document.getElementsByClassName('mat-input-element ng-invalid');

            if (elements.length > 0) {
                elements[0].focus();
            }

            return false;
        }
    }

    setMaxLengthByDoc(type) {
        this.maxLengthByDoc = getMaxLengthByDoc(brands.simyo, type);
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    alphanumericOnly(event) {
        let pattern: RegExp = /^[a-zA-Z0-9]+$/;
        const inputChar = event.key;
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }
}
