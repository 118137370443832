import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-kyc-error',
  templateUrl: './modal-kyc-error.component.html',
  styleUrls: ['./modal-kyc-error.component.scss']
})
export class ModalKycErrorComponent implements OnInit {
  @Input() messageError: string;
  @Input() iconError: string;
  @Input() canContinueProcess: boolean;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
