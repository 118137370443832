<!-- Modal Error KyC -->
<div class="modal-header bg-black">
    <h5 class="modal-title white">Aviso</h5>
</div>
<div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 d-flex align-items-center message">
                <mat-icon *ngIf="iconError" [ngClass]="{'error-color': iconError === 'highlight_off', 'alert-color': iconError === 'error_outline'}">{{iconError}}</mat-icon>
                <p class="my-3">                        
                    {{messageError}}
                </p>
            </div>
            <div class="col-lg-12 text-center">
                <button type="button" class="btn btn-simyo2 mb-2 btn-modal" (click)="activeModal.close()">
                    {{ canContinueProcess ? 'Continuar' : 'Aceptar'}}
                </button>
            </div>
        </div>
    </div>
</div>