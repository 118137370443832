import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';

@Component({
    selector: 'app-hm-modal-mr',
    templateUrl: './hm-modal-mr.component.html',
    styleUrls: ['./hm-modal-mr.component.css']
})
export class HmModalMrComponent implements OnInit {

    public allPermissions = allPermissions;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public permSv: PermissionService
    ) { }

    ngOnInit(): void {
    }

}
