<div class="container mb-5">
    <div class="row">
        <div class="col-lg-12 mb-2 mt-4" *ngIf="typeOrder === orderType.MOBILE">
            <h5 class="title-step text-center" >
                <img src="./assets/img/linea-conf.svg" class="img-fluid ico-title transf-ico" width="20">
                Configuración de tarifa
            </h5>
        </div>
        <div [ngClass]="{'jumbotron-dir col-md-8 offset-md-2' : typeOrder === orderType.MOBILE && !showCountries, 'jumbotron-dir col-md-10 offset-md-1' : typeOrder === orderType.MOBILE && showCountries, 'col-12' : typeOrder !== orderType.MOBILE}" style="background-color: #fff;" [hidden]="!loaded">
            <div>
                <div class="row animated fadeIn ps-1 pe-1">
                    <div class="col-12 col-md mb-3">
                        <p class="mb-3 mb-md-0 mt-2 pt-1 fw-bold text-simyo">
                            Comprueba si es cliente de Simyo
                        </p>
                    </div>
                </div>
            </div>
            <!-- Teléfono del cliente titular -->
            <div>
                <div class="mt-1 ms-1 mb-3">
                    <form [formGroup]="formGroup">
                        <div class="row">
                            <div [class]="(+formGroup.get('customer_doctype')?.value === 1 || +formGroup.get('customer_doctype')?.value === 2 || +formGroup.get('customer_doctype')?.value === 3) ? 'col-lg-2' : 'col-lg-3'" style="margin-top: 6px;">
                                <div class="mb-3">
                                    <mat-form-field [formGroup]="formGroup">
                                        <mat-label>Tipo documento</mat-label>
                                        <select formControlName="customer_doctype" matNativeControl
                                            (change)="changeTypeDocument($event.target.value); setMaxLengthByDoc($event.target.value); showNotCustomer = false">
                                            <!--
                                                Requisitos para no mostrar algunos de los tipos de documento:
                                                -> No se muestra CIF si no tiene el permiso
                                                -> No se muestra el Pasaporte si el flujo es tiene fibra
                                                -> Si se muestra el Pasaporte si es stand y el flujo es movil
                                            -->
                                            <option *ngFor="let documentType of documentTypes" [value]="documentType.document_type_id"
                                                [hidden]="(!permSv.hasPermFromV2(allPermissions.simyo.permite_vender_cif) && documentType?.document_type_id === 4)">
                                                {{ documentType.value }}
                                            </option>
                                        </select>
                                        <!-- error -->
                                        <mat-error *ngIf="formGroup.get('customer_doctype').invalid">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-lg-3 mt-0">
                                <div class="mb-3">
                                    <mat-form-field>
                                        <mat-label>Nº de documento</mat-label>
                                        <input matInput="" type="text" oninvalid="" formControlName="customer_doc"
                                        (input)="showNotCustomer = false; formGroup.get('customer_doc').patchValue(formGroup.get('customer_doc').value.toUpperCase())"
                                        [maxLength]="maxLengthByDoc" (keypress)="alphanumericOnly($event)">
                                        <!-- error -->
                                        <mat-error *ngIf="formGroup.get('customer_doc')?.errors?.required">
                                            Campo obligatorio.
                                        </mat-error>
                                        <mat-error *ngIf="(formGroup.get('customer_doc')?.errors?.doc || formGroup.get('customer_doc')?.errors?.pattern) && !formGroup.get('customer_doc')?.errors?.required">
                                            Número de documento erróneo
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-lg-2" *ngIf="showCountries"  style="margin-top: 6px;">
                                <div class="mb-3">
                                    <mat-form-field>
                                        <mat-label>Nacionalidad</mat-label>
                                        <select matNativeControl required formControlName="country" (change)="changeCountry($event.target.value)">
                                            <option *ngFor="let country of countries" [value]="country.country_id">
                                                {{ country.value }}
                                            </option>
                                            <option [value]="-1" *ngIf="typeOrder !== orderType.MOBILE">
                                                Otros
                                            </option>
                                        </select>
                                        <mat-error *ngIf="formGroup.get('country').invalid">
                                            Campo obligatorio.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div [class]="showCountries ? 'col-lg-3' : 'col-lg-4'" class="mt-0" *ngIf="+formGroup.get('customer_doctype')?.value === 1 || +formGroup.get('customer_doctype')?.value === 2 || +formGroup.get('customer_doctype')?.value === 3">
                                <div class="mb-3">
                                    <app-datepicker-custom
                                    title="Fecha de nacimiento"
                                    [form]="formGroup"
                                    name="customer_birthday"
                                    [maxDate]="maxDate"
                                    [minDate]="minDate"
                                    (onChangeDate)="parseYear(formGroup.get('customer_birthday').value)"
                                    ></app-datepicker-custom>
                                </div>
                            </div>

                            <div [class]="showCountries ? 'col-lg-2' : 'col-lg-3'" class="mt-0">
                                <div class="mb-3">
                                    <mat-form-field>
                                        <mat-label>CP cliente</mat-label>
                                        <input matInput type="text" autocomplete="off" required name="zip_code" (input)="onChangeZip($event.target.value)" formControlName="zip_code" (keydown)="onlyPositiveDigits($event)">
                                        <mat-error *ngIf="formGroup.get('zip_code')?.errors?.required || formGroup.get('zip_code')?.errors?.blankSpace">
                                            Campo obligatorio.
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.get('zip_code')?.errors?.zipCodeInvalid">
                                            Formato de CP incorrecto.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: -1.5rem; margin-bottom: 1.25rem;" *ngIf="!showErrorCustomer && typeOrder === orderType.MOBILE && onlyPrepaid && !pdv?.info?.isstand">
                            <div class="col-12">
                                <div class="alert alert-warning alert-list mb-0" role="alert">
                                    Recuerda que con este pasaporte solo puedes dar altas de prepago
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <a (click)="checkSimyoTemporaryClosure()" id="show" class="btn btn-simyo mb-1" type="submit" *ngIf="!lockSearch">
                                Buscar
                            </a>
                        </div>
                        <div class="row" *ngIf="showNotCustomer">
                            <div class="col-6 col-md">
                                <!-- Text -->
                                <p class="mb-3 mb-md-0 mt-2 pt-1 fw-bold text-simyo">
                                    Todavía no es cliente Simyo
                                </p>
                            </div>
                            <div class="col-6 col-md">
                                <!-- Button -->
                                <a (click)="setNotCustomer()" class="btn btn-black btn-sm mt-2 float-end">
                                    Continuar como cliente nuevo
                                </a>
                            </div>
                        </div>
                        <div class="mt-2" *ngIf="showErrorCustomer && showErrorCustomer !== ''">
                            {{ showErrorCustomer || 'No se ha podido realizar la comprobación del cliente' }}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div [hidden]="loaded" class="mt-2 mb-5">
    <h5  class="tar-name-preload text-center">Buscando datos del cliente...</h5>

    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
    </div>
</div>

<ng-template #infoCustomer let-modal>
    <div class="modal-header bg-simyo-grey">
        <h5 class="modal-title white">Información del cliente</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20"></span>
        </button>
    </div>

    <div class="modal-body" id="bodyModal">
        <div class="container">
            <div class="row">
                <h6>Información personal</h6>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="col-12 text-muted">
                        {{ customerInfo?.customer?.document_type_id === '4' ? 'Razón social y tipo de sociedad' : 'Nombre' }}
                    </div>
                    <div class="col-12">
                        {{ customerInfo?.customer?.name }} {{ customerInfo?.customer?.last_name }} {{ customerInfo?.customer?.second_last_name }}
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="col-12 text-muted">
                        Número de documento
                    </div>
                    <div class="col-12" *ngIf="customerInfo?.customer?.document_type_id">
                        {{ getDocumentLabel(customerInfo?.customer?.document_type_id) }}: {{ customerInfo?.customer?.document }}
                    </div>
                    <div class="col-12" *ngIf="!customerInfo?.customer?.document_type_id">
                        {{ customerInfo?.customer?.document }}
                    </div>
                </div>
            </div>
            <div *ngIf="!isSameTaxZone()"  class="margins mb-2 mt-4 d-flex justify-content-center">
                <div class="row">
                    <h6 style="color: #ff5900; font-size: larger;text-align: center;">Antes de continuar confirma con el cliente que su código postal actual es el {{customerInfo?.customer?.zip_code}}</h6>
                </div>
            </div>
            <div class="row mt-4 float-end">
                <a (click)="modal.dismiss('Cross click'); setCustomer()" id="show" class="btn btn-simyo-o2 mb-1 me-3" type="submit">Continuar</a>
                <a (click)="modal.dismiss('Cross click')" id="show" class="btn btn-simyo-o mb-1" type="submit">Cancelar</a>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #infoBroadband>
    <div *ngFor="let broadband of customerInfo?.broadbands; index as i" class="mb-2" >
        Fibra: {{ i + 1 }}
        <br>
        <div class="ms-2" >
            MSISDN: {{ broadband.msisdn }}
            <br>
            Estado: {{ broadband.substatus }}
        </div>
    </div>
</ng-template>
<ng-template #infoMobile>
    <div *ngFor="let mobile of customerInfo?.mobiles; index as i" class="mb-2" >
        Móvil: {{ i + 1 }}
        <br>
        <div class="ms-2" >
            MSISDN: {{ mobile.msisdn }}
            <br>
            Estado: {{ mobile.substatus }}
            <br>
            Precio: {{ mobile.price }}
            <br>
            Id tarifa: {{ mobile.rate }}
        </div>
    </div>
</ng-template>
