import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimyoService } from '../../services/simyo.service';
import { catchError, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'app-search-pdv',
    templateUrl: './search-pdv.component.html',
    styleUrls: ['./search-pdv.component.css'],
})
export class SearchPdvComponent implements OnInit {
    @Output() onCreateOrder: EventEmitter<any> = new EventEmitter();
    public loading: boolean = false;
    public formGroup: FormGroup;
    public pdv = null;
    public error;

    constructor(
        protected modalService: NgbModal,
        private formBuilder: FormBuilder,
        private simyoService: SimyoService
    ) {
        this.formGroup = this.formBuilder.group({
            username: ['PdV_Or', Validators.required],
        });
    }

    ngOnInit(): void {}

    public closeModal(): void {
        this.modalService.dismissAll();
    }

    public searchPdV(): void {
        if (this.formGroup.invalid) return;

        this.loading = true;
        this.error = false;
        this.pdv = null;

        let username = `${this.formGroup.value.username}`;
        this.simyoService
            .searchPdVByUsername(username)
            .pipe(
                take(1),
                catchError((err) => {
                    this.error = true;
                    return of({ msg: 'ko' });
                }),
                tap((result: any) => {
                    if (result.msg === 'ok' 
                    && result.data !== null
                    && result.data.id_pdv !== null
                    && result.data.pdv_denominacion !== null
                    && result.data.id_usuario_principal !== null
                    && result.data.username_principal !== null) {
                        this.pdv = result.data;
                        return;
                    }
                    this.error = true;
                }),
                tap(() => (this.loading = false))
            )
            .subscribe();
    }

    public createOrder(): void {        
        this.onCreateOrder.emit(this.pdv);
        this.modalService.dismissAll();
    }
}
