import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    broadbandTypeDiscount,
    discountWithoutFtth,
    fiberIndirectTechnologies,
    nameTaxType,
    orderType,
    superStatus,
    typeCard,
} from 'src/app/shared/constantes';
import { SimyoService } from '../../services/simyo.service';
import { SimyoGlobals, descuento } from '../../classes/simyo-globals';
import { SimyoRateService } from '../../services/simyo-rate.service';
import { catchError, take, tap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';

@Component({
    selector: 'app-simyo-summary-processing',
    templateUrl: './simyo-summary-processing.component.html',
    styleUrls: ['./simyo-summary-processing.component.css', '../../../../assets/css/simyo-theme.css'],
})
export class SimyoSummaryProcessingComponent implements OnInit, OnChanges {
    @Input() typeOrder = null;
    @Input() broadband = null;
    @Input() mobiles = [];
    @Input() rateFtth = null;
    @Input() customerData = null;
    @Input() ratesMobiles = [];
    @Input() showButtonsEditLine = false;
    @Input() showAllInfo = true;
    @Input() super_status = null;
    @Input() orderTotalInitial = null;
    @Input() orderTotalMonthly = null;
    @Input() date = null;
    @Input() isDetail = false;

    @Output() eventGoToStep = new EventEmitter();
    @Output() eventremoveLine = new EventEmitter();

    public orderType = orderType;
    public fiberIndirectTechnologies = fiberIndirectTechnologies;
    public superStatus = superStatus;
    public typeCard = typeCard;

    public discount = descuento;
    public totalPriceInitial = 0;
    public totalPriceMonthly = 0;
    private broadbandSpeedSelected = 0;

    /*variables esim */
    public isCollapsed: boolean[] = [];
    public statusDisableSend = ['loading', 'success'];
    public statusSendQR$: BehaviorSubject<[string]> = new BehaviorSubject(
        ['initial']
    );
    messageBlocked = 'Deben transcurrir al menos 5 minutos entre reenvios de QR';

    constructor(
      private simyoService: SimyoService,
      public simyoGlobals: SimyoGlobals,
      private rateService: SimyoRateService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.customerData?.currentValue || changes?.rateFtth?.currentValue) {
            this.broadbandSpeedSelected = this.simyoService.getMaxFtthSpeed();
        }

        if(this.broadband) {
            this.getFtthRate();
        }

        this.updateTotalPrice(this.simyoGlobals.getTotalPrice(this.rateFtth?.rate_info?.price, this.ratesMobiles));
    }

    ngOnInit(): void {}

    getFtthRate() {
        if(this.broadband && this.broadband.discount) {
            this.discount = this.broadband.discount;
        }

        this.updateTotalPrice(this.simyoGlobals.getTotalPrice(this.rateFtth?.rate_info?.price, this.ratesMobiles));
    }

    goToStep(index) {
        this.eventGoToStep.emit(index);
    }

    removeExtraLine(index) {
        this.eventremoveLine.emit(index);
    }

    getTitleTypeMobile(mobile) {
        if (mobile === undefined) return '';
        const portability = mobile.msisdn && mobile.msisdn !== '';
        if (mobile.is_esim === typeCard.esim) {
            return portability
                ? `Portabilidad eSIM de la línea móvil ${mobile.msisdn}`
                : 'Alta eSIM';
        }

        return portability
            ? `Línea móvil a portar ${mobile.msisdn}`
            : 'Alta línea móvil';
    }

    discountWithoutFtth() {
        let tax_type = this.simyoService.getTaxType();
        return discountWithoutFtth[tax_type];
    }

    getTaxType(){
      let tax_type = this.simyoService.getTaxType();
      return nameTaxType[tax_type];
  }

    getRechargePrice(price) {
      return this.rateService.getRechargePrice(price);
    }

    updateTotalPrice(price) {
        this.totalPriceInitial = this.orderTotalInitial != null ? this.orderTotalInitial : price.totalPriceInicial;
        this.totalPriceMonthly = this.orderTotalMonthly != null ? this.orderTotalMonthly : price.totalMensualPrice;
    }

    getTextInstallationType(broadband) {
        let message = broadband.iv_frontal_confirmation === true ? 'Solicitada Instalación Virtual.<br>' : '';
        if(broadband.virtual_installation !== null && broadband.virtual_installation !== undefined) {
            return `${message}Realizada Instalación ${broadband.virtual_installation}.`;
        }

        if((broadband.super_status === superStatus.active
            && (broadband.virtual_installation == null || broadband.virtual_installation == undefined))
            || (broadband.iv_frontal_confirmation == null || broadband.iv_frontal_confirmation == false)) {
            return `${message}Instalación física.`;
        }
        return '';
    }

    getDataMobile(mobile, data) {
        if(data == 'rate_info') {
            return this.simyoGlobals.findByKeyInMobile(mobile, 'rate_info');
        }
        let rate =  mobile.rate?.movil ?? mobile.rate;
        if(rate && rate[data]) {
            return rate[data];
        }
        return null;
    }

    public changeStateSendEmail(index) {
        this.mobiles[index].showSendEmail = !this.mobiles[index].showSendEmail;
        this.isCollapsed[index] = true;
    }

    public hideEsim(index) {
        if (this.isCollapsed[index] === undefined) {
            this.isCollapsed[index] = true;
        }

        return this.isCollapsed[index]
    }

    public changeStateInfoEsim(index) {
        this.isCollapsed[index] = !this.isCollapsed[index];
        this.mobiles[index].showSendEmail = false;
    }

    public sendEmail(index) {
        let statuses = this.statusSendQR$.getValue();
        let currentStatus = statuses[index];
        if(currentStatus === 'blocked') {
            return;
        }
        statuses[index] = 'loading';
        this.statusSendQR$.next(statuses);

        let data = {
            customer_id: this.customerData.id,
            msisdn: this.mobiles[index].new_msisdn,
            order_mobile_id: this.mobiles[index].order_mobile_id
        }
        this.simyoService.sendEsimQR(data).pipe(
            take(1),
            catchError(err => {
                statuses[index] = 'error';
                if(err.error.error.errorCode === 403) {
                    statuses[index] = 'blocked';
                }

                this.statusSendQR$.next(statuses);
                return of({});
            }),
            tap((data: any) => {
                if(data.hasOwnProperty('msg')) {
                    statuses[index] = 'success';
                    this.statusSendQR$.next(statuses);
                }
                setTimeout(() => {
                    statuses[index] = 'initial';
                    this.statusSendQR$.next(statuses);
                }, 10000);
            })
        )
        .subscribe();
    }

    getTextButtonSendQR(status) {
        if(status === 'loading') return 'Enviando...';
        if(status === 'success') return 'Enviado';
        return 'Enviar';
    }

    isMainLine(mobile) {
        return mobile?.rate?.movil?.is_main_line ?? mobile?.is_main_line;
    }

    getPriceBroadbandWithoutDiscount() {
        if(this.broadband.type_discount != null) {
            return this.broadband.total_without_discount;
        }
        return this.getPriceBroadband();
    }

    getPriceBroadband() {
        let rateInfo = this.simyoGlobals.findByKeyInMobile(this.rateFtth, 'rate_info');
        if(rateInfo) {
            return rateInfo.contracted_price ?? rateInfo.price;
        }
        return 0;
        //return +this.rateFtth.rate_info?.price ?? +this.rateFtth.rate_info?.contracted_price;
    }

    messageBroadbandTypeDiscount() {
        if(this.broadband.type_discount === broadbandTypeDiscount.firstPackage) {
            if(this.date && new Date(this.date) < new Date('2023-03-14')) {
                return `${ !this.isDetail ? '*' : '' }Descuento por fibra y móvil`;
            }
            return `${ !this.isDetail ? '*' : '' }Descuento por fibra y móvil pospago`;
        }
        if(this.broadband.type_discount === broadbandTypeDiscount.additionalPackage) {
            return `${ !this.isDetail ? '*' : '' }Descuento por línea móvil existente`;
        }
        return '';
    }

    getTerminalByMobile(mobile) {
        if(mobile.terminal_oid) {
            return {
                ...mobile.terminal_oid.terminal,
                is_installment: mobile.terminal_oid.is_installment
            };
        }
        if(mobile.rate && mobile.rate.terminal) {
            return mobile.rate.terminal
        }

        return null;
    }
}
