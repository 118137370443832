import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { inputErros } from '../../errors';

@Component({
    selector: 'input-error',
    templateUrl: './input-error.component.html',
    styleUrls: ['./input-error.component.css'],
})
export class InputErrorComponent implements OnChanges {
    @Input() id;
    @Input() control;
    @Input() message;
    isFormControl = false;

    constructor() {
        this.isFormControl = this.control != null;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.control?.currentValue) {
            this.isFormControl = true;
        }
    }

    showError() {
        if (this.isFormControl) {
            return this.control.errors && this.control.touched;
        }
        return this.message != null && this.message !== '';
    }

    getFirstError(errors) {
        console.log('list erros', errors);
        return Object.keys(errors)[0];
    }

    translateError(error) {
        if (inputErros.hasOwnProperty(error)) {
            return inputErros[error];
        }
        if (
            inputErros.hasOwnProperty(this.id) &&
            inputErros[this.id].hasOwnProperty(error)
        ) {
            return inputErros[this.id][error];
        }
        return `no existe mensaje de error para ${this.id} ${error}`;
    }
}
