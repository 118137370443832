import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {DigoService} from '../../../services/digo.service';
import {Ipdv} from '../../../shared/models/pdvResponse';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-hm-modal-contact-pdv',
    templateUrl: './hm-modal-contact-pdv.component.html',
    styleUrls: ['./hm-modal-contact-pdv.component.css']
})
export class HmModalContactPdvComponent implements OnInit {
    public pdv: Ipdv;
    public showAlert = false;
    public isLoading = false;
    public alertType: string = null;
    public alertMessage: string = null;
    public formGroup: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private digoService: DigoService, private formBuilder: FormBuilder) {
        this.showAlert = data.showAlert;
        this.pdv = data.pdv;
    }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            id_pdv: [this.pdv.info.idpdv, Validators.required],
            key: [environment.digoApiKey, Validators.required],
            supportphone: [this.pdv.info.support.phone, [Validators.required, Validators.pattern('[0-9 ]*'), Validators.minLength(9), Validators.maxLength(9)]],
            supportemail: [this.pdv.info.support.email, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
            supportcontact: [this.pdv.info.support.contact, Validators.required]
        });
    }

    errorHandling = (control: string, error: string) => {
        if (this.formGroup.get(control).invalid && (this.formGroup.get(control).dirty || this.formGroup.get(control).touched)) {
            return this.formGroup.controls[control].hasError(error);
        }
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;

        return !(charCode > 31 && (charCode < 48 || charCode > 57));
    }

    validateForm() {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();

        if (this.formGroup.valid) {
            this.isLoading = true;
            this.digoService.putSupportInfo(this.formGroup.getRawValue()).subscribe((data: any) => {
                this.isLoading = false;
                this.alertType = data.sucess ? 'success' : 'danger';
                this.alertMessage = data.sucess ? '¡Cambios guardados correctamente!' : 'Ocurrió un error al guardar los datos. Por favor, vuelve a intentarlo.';
            }, error => {
                console.error(error);
                this.isLoading = false;
                this.alertType = 'danger';
                this.alertMessage = 'Ocurrió un error al guardar los datos. Por favor, vuelve a intentarlo.';
            });
        }
    }

}
