import {AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HmRateService} from '../../services/hm-rate.service';
import {MatTabGroup} from '@angular/material/tabs';
import {environment} from '../../../../environments/environment';
import * as moment from 'moment/moment';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HmModalSimyoContratosComponent } from '../hm-modal-simyo-contratos/hm-modal-simyo-contratos.component';
import { PDV_MULTIMARCA, monthNames, tabHome, typeFilterFormOperation, translateCategoryRappel } from 'src/app/shared/constantes';
import { Subscription } from 'rxjs';
import { HomeService } from '../../services/home.service';
import { HmModalJazztelContratosComponent } from '../hm-modal-jazztel-contratos/hm-modal-jazztel-contratos.component';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { dayToShowCategoryRappelPreviousMonth, IrappelMaster } from '../../models/Irappel';
import {SimyoTemporaryClosureComponent} from '../../../simyo/components/simyo-temporary-closure/simyo-temporary-closure.component';
import {Router} from '@angular/router';
import { PointsService } from '../../services/points.service';

@Component({
    selector: 'app-hm-hiring',
    templateUrl: './hm-hiring.component.html',
    styleUrls: ['./hm-hiring.component.css', '../../../../assets/css/home-theme.css'],
    providers: [NgbCarouselConfig]
})
export class HmHiringComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() tabGroup: MatTabGroup;
    @Input() pdv: Ipdv;
    @Input() dataPoints;
    @ViewChild('procesos') procesos;

    private innerWidth: any;
    public brands = {
        simyo: 'sm',
        orange: 'or',
        jazztel: 'jz'
    };
    public brandsPerm = {
        simyo: 'simyo',
        orange: 'orange',
        jazztel: 'jazztel'
    };
    public baseUrl: string;
    public brandOpened: string;
    public titleColumn = 'col-12';
    public buttonColumn = 'col-12';
    public showMessages = true;
    public today = moment();
    public lastMonth = moment().subtract(1, 'months').month();
    public orChange = moment('2022-04-24');
    public smChange = moment('2021-08-23');
    public smChangeGif = moment('2024-02-05');
    public smGifFinish = moment('2024-02-05');
    public environment = environment;
    public activationStatistics: any;
    public activationsByMonthError: boolean = false;
    public rappelSimyoData: any;
    public rappelSimyoDataError: boolean = false;
    public monthNames = monthNames;
    public typeFilterFormOperation = typeFilterFormOperation;
    public allPermissions = allPermissions;
    public PDV_MULTIMARCA = PDV_MULTIMARCA;
    public numberOfRecords: number = 0;
    public translateCategoryRappel = translateCategoryRappel;
    public dayToShowCategoryRappelPreviousMonth = dayToShowCategoryRappelPreviousMonth;
    private allSubscription: Subscription[] = [];
    public dataPointsError = false;

    constructor(
        private rateService: HmRateService,
        private modalService: NgbModal,
        private homeService: HomeService,
        public permSv: PermissionService,
        public config: NgbCarouselConfig,
        private router: Router,
        public pointsService: PointsService
    ) {
        this.innerWidth = window.innerWidth;
        this.baseUrl = environment.digoEndPoint;

        config.interval = 4770;
        config.pauseOnHover = false;
        config.showNavigationArrows = false;
        config.showNavigationIndicators = false;
    }

    ngOnInit(): void {
        this.allSubscription.push(this.homeService.getActivation()
            .subscribe((data: any) => {
                if (data != null) {
                    this.activationStatistics = data;
                }
            })
        );

        this.allSubscription.push(this.homeService.getActivationError()
            .subscribe((data: any) => {
                if (data != null) {
                    this.activationsByMonthError = data;
                }
            })
        );

        this.allSubscription.push(this.homeService.getRappelSimyo()
            .subscribe((data: any) => {
                if (data != null) {
                    this.rappelSimyoData = data;
                }
            })
        );

        this.allSubscription.push(this.homeService.getRappelSimyoError()
            .subscribe((data: any) => {
                if (data != null) {
                    this.rappelSimyoDataError = data;
                }
            })
        );
    }

    ngAfterViewInit() {
        if (this.homeService.getBrandPermission(this.brandsPerm).length === 1 && !(document.getElementById('orders_and_credit_phone') || document.getElementById('commissions') || document.getElementById('free_terminals'))) {
            this.changeBrand(this.brands[this.homeService.getBrandPermission(this.brandsPerm)[0]]);
            document.getElementById(this.brands[this.homeService.getBrandPermission(this.brandsPerm)[0]]).classList.add('show');
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    changeBrand(brand?: string) {
        this.brandOpened = brand;
        this.showMessages = brand == null;
        this.titleColumn = brand == null ? 'col-12' : 'col';
        this.buttonColumn = brand == null ? 'col-12' : 'col-auto';

        if (brand != null && this.innerWidth <= 920) {
            setTimeout(() => window.scrollTo(0, 250));
        }
    }

    viewRates(brand) {
        this.tabGroup.selectedIndex = this.homeService.findIndexToMove(this.tabGroup, tabHome.TARIFAS);
        this.rateService.setFilterRatesByBrand(brand);
    }

    viewOperation(type) {
        this.tabGroup.selectedIndex = this.homeService.findIndexToMove(this.tabGroup, tabHome.OPERACIONES);
        this.homeService.setTypeOperationForms({type: type});
    }

    viewCommissions() {
        this.tabGroup.selectedIndex = this.homeService.findIndexToMove(this.tabGroup, tabHome.COMISIONES);
    }

    openModalSimyoContratos() {
        this.modalService.open(HmModalSimyoContratosComponent, {size: 'lg', centered: true});
    }

    openModalJazztelContratos() {
        this.modalService.open(HmModalJazztelContratosComponent, {size: 'lg', centered: true});
    }

    getAbsoluteValue(value) {
        return Math.abs(value);
    }

    public getWidth(): number {
        if (this.rappelSimyoData) {
            let maxValueProgressBar: number = 0;
            // La barra de progreso representa el número de activaciones con respecto al mínimo de activaciones del nivel más alto
            maxValueProgressBar = Math.max.apply(Math, this.rappelSimyoData.master.map((level: IrappelMaster) => { return level.min_activations; })) || 20;
            return (this.rappelSimyoData.current.current_activations * 100) / maxValueProgressBar;
        }
        return 0;
    }

    public checkSimyoTemporaryClosure(path: string) {
        if (this.permSv.hasPermFromV2(this.allPermissions.simyo.nbss_block)) {
            this.openSimyoTemporaryClosure();
            return
        }

        this.router.navigate([path]);
    }

    public openSimyoTemporaryClosure() {
        this.modalService.open(SimyoTemporaryClosureComponent, {size: 'lg', centered: true, backdrop: 'static'});
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    viewPoints() {
        this.tabGroup.selectedIndex = this.homeService.findIndexToMove(this.tabGroup, tabHome.PUNTOS);
    }

    getPointsWidth() {
        if(!this.dataPoints) return 0;
        return Number(this.dataPoints.pointsWonThisMonth) * 100 / Number(this.dataPoints.pointsWonLastMonth);
    }

}
