import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-hm-gpv',
    templateUrl: './hm-gpv.component.html',
    styleUrls: ['./hm-gpv.component.css', '../../../../assets/css/home-theme.css']
})
export class HmGpvComponent implements OnInit {
    public pdv: any;
    public phone: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.pdv = data.pdv;
        this.phone = this.pdv.gpv.phone?.replace('+34', '').replace(/(.{3})/g, '$1 ').trim();
    }

    ngOnInit(): void {
    }

    requestCall() {
        alert('Solicitud de llamada registrada. Tu GPV te contactará a la mayor brevedad posible.');
        window.location.href = 'https://digo.dexga.com/index_pdv.itx?ac=addvisit';
    }
}
