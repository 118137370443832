import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-redirect-dialog',
  templateUrl: './redirect-dialog.component.html',
  styleUrls: ['./redirect-dialog.component.css']
})
export class RedirectDialogComponent implements OnInit {

  title: string;
  message: string;
  optionStay: string;
  optionRedirect: any;

  constructor(
    public dialog: MatDialogRef<RedirectDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
) {
    this.title = data.title;
    this.message = data.message;
    this.optionRedirect = data.optionRedirect;
    this.optionStay = data.optionStay;
}

ngOnInit(): void {
}

goRefresh(): void {
    window.location.reload();
    this.dialog.close(false);
}

stay(): void {
    this.dialog.close(true);
}
}
