/** A hero's name can't match the given regular expression */
import {AbstractControl, ValidatorFn} from '@angular/forms';


export function ibanValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (isValidIBANNumber(control.value) === 1) {
            return null;
        }
        return {iban: {value: control.value}};
    };
}

/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIBANNumber(input) {
    const CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
    };

    // keep only alphanumeric characters
    const iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, '');
    // match and capture (1) the country code, (2) the check digits, and (3) the rest
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    let digits;

    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    // tslint:disable-next-line:only-arrow-functions
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => (letter.charCodeAt(0) - 55) + '');
    // final check
    let checkSumValidator = mod97(digits);
    let accValidatorValue = accValidator(digits);
    return checkSumValidator === 1 && accValidatorValue === digits.slice(8, 10) ? 1 : 0;
    // return mod97(digits);
}

function mod97(str) {
    let checksum = str.slice(0, 2);
    let fragment;

    for (let offset = 2; offset < str.length; offset += 7) {
        fragment = String(checksum) + str.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}

function accValidator(str) {
    let pesos = [1,2,4,8,5,10,9,7,3,6];
    let digitControl = '';
    for (let z = 0; z < 2; z++) {
        let value = '';
        value = z === 0 ? '00' + str.slice(0, 8) : str.slice(10, 20);
        let d = 0;
        for (let i = 0; i <= 9; i++) {
            d += parseInt(value.charAt(i), 10) * pesos[i];
        }
        d = 11 - (d % 11);
        if (d === 11) d = 0;
        if (d === 10) d = 1;
        digitControl += String(d);
    }   
    return digitControl;
}