import { Component, OnDestroy, OnInit } from '@angular/core';
import { SimyoDraftService } from '../../services/simyo-draft.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { SimyoService } from '../../services/simyo.service';
import { orderType } from 'src/app/shared/constantes';
import { SimyoNormalizadorService } from '../../services/simyo-normalizador.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { Subscription } from 'rxjs';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { SimyoDocumentationService } from '../../services/simyo-documentation.service';
import { IObservableDocuments, signatureDocumentsBody } from '../../models/signatureDocuments';
import { Router } from '@angular/router';
import { allPermissions } from 'src/app/shared/permissions';

@Component({
    selector: 'app-simyo-modal-save',
    templateUrl: './simyo-modal-save.component.html',
    styleUrls: ['./simyo-modal-save.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoModalSaveComponent implements OnInit, OnDestroy {
    protected preventDestroy = false;
    protected order;
    protected ratesMobile;
    public loading: boolean = false;
    private typeOrder: string;
    public classClickedNo: boolean = false;
    public classClickedYes: boolean = false;
    public orderDocuments: IObservableDocuments;

    private thereIsCoverage: boolean;
    private allSubscription: Subscription[] = [];
    
    constructor(
        protected modalService: NgbModal,
        protected draftService: SimyoDraftService,
        private orderService: SimyoOrderService,
        protected simyoService: SimyoService,
        private normalizadorService: SimyoNormalizadorService,
        private matdialog: MatDialog,
        protected permSv: PermissionService,
        private simyoDocumentationService: SimyoDocumentationService,
        protected router: Router
    ) {
        this.allSubscription.push(this.orderService.getOrder()
            .subscribe((order) => {
                this.order = order;
            })
        );

        this.allSubscription.push(this.simyoService.getPreventDestroy()
            .subscribe((data: boolean) => {
                this.preventDestroy = data;
            })
        );

        this.allSubscription.push(this.simyoService.getRatesMobile()
            .subscribe((data) => {
                this.ratesMobile = data;
            })
        );

        this.allSubscription.push(this.simyoService.getTypeOrder()
            .subscribe((data: any) => {
                this.typeOrder = data;
            })
        );

        this.allSubscription.push(this.normalizadorService.getThereIsCoverage()
            .subscribe((value: any) => {
                this.thereIsCoverage = value;
            })
        );

        this.allSubscription.push(this.simyoDocumentationService.getOrderDocuments()
            .subscribe((data: IObservableDocuments) => {
                if (data) this.orderDocuments = data;
            })
        );
    }

    ngOnInit(): void {
    }

    public closeModal(): void {
        this.modalService.dismissAll();
        this.draftService.continueNavigation.next(false);
    }

    public onRemoveDraft(): void {
        if (!this.openModalUsuarioDemo()) {
            this.loading = true;
            if (this.order && this.order.order_id && this.order.order_id !== '') {
                this.simyoService.removeOrder(this.order.order_id)
                    .subscribe(response => {
                        console.log('Order borrada');
                        this.loading = false;
                        this.modalService.dismissAll();
                    }, error => {
                        console.log('Error al borrar la order');
                        this.loading = false;
                        this.modalService.dismissAll();
                        this.draftService.continueNavigation.next(true);
                    }, () => this.draftService.continueNavigation.next(true));
            } else {
                this.modalService.dismissAll();
                this.draftService.continueNavigation.next(true);
            }
        }
    }

    public onSaveDraft(): void {
        if (!this.openModalUsuarioDemo()) {
            if (!this.loading && !((this.typeOrder === orderType.CONVERGENT || this.typeOrder === orderType.BROADBAND) && !this.thereIsCoverage)) {
                this.loading = true;
                // Mandamos señal para que SimyoComponent meta los ultimos cambios en el order
                this.draftService.updateDraft(true);
                // persistimos el draft
                this.orderService.createOrder(true, false, this.needResetDocumentation())
                    .subscribe((data) => {},
                        () => {
                            this.loading = false;
                            this.modalService.dismissAll();
                            this.draftService.updateDraft(false);
                            this.draftService.continueNavigation.next(true);
                        }, () => {
                            this.loading = false;
                            this.modalService.dismissAll();
                            this.draftService.updateDraft(false);
                            this.draftService.continueNavigation.next(true);
                        });
            } else {
                this.modalService.dismissAll();
                this.draftService.continueNavigation.next(true);
            }
        }
    }

    private openModalUsuarioDemo(): boolean {
        if (this.permSv.hasPermFromV2(allPermissions.others.users_demo)) {
            this.modalService.dismissAll();
            this.matdialog.open(AlertDialogComponent, {
                data: {message: 'Usuario demostración. No se puede guardar o eliminar un borrador'}
            });
            this.draftService.continueNavigation.next(true);
            return true;
        }
        return false
    }

    public needResetDocumentation(): boolean {
        return this.orderDocuments && this.orderDocuments.type === 'digital' && this.orderDocuments.documents.find(document => document.status === 'sign_pending' || document.status === 'signed') ? true : false;
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }
}
