<div *ngIf="!loaded && !orderNotFound" class="mt-5 mb-5">
    <h5  class="tar-name-preload text-center">Obteniendo orden...</h5>

    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
    </div>
</div>

<div class="container mt-3" *ngIf="orderNotFound">
    <div class="row">
        <div class="col-md-12">
            <h5 class="title-step mt-4 text-center" >
                Lo sentimos, el pedido que está buscando no existe
            </h5>
            <div class="d-flex justify-content-center">
                <button routerLink="/orange/bonos-adicionales-listado" class="btn btn-simyo-o2 btn-sm mt-2">
                    Volver al listado
                </button>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="loaded && (orange$ | async)?.orderInfo">
    <div class="container content mb-2">
        <div class="row">
            <div class="col-md-12">
                <h5 class="title-step mt-4 text-center" >
                    Resumen de la activación
                </h5>
            </div>
        </div>
    </div>
    <!--------- Resumen de la tramitación ------------->
    <!-------------------------------------------------->

    <div class="container firma mt-4 mb-5">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card mb-3">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2">
                                            <b>Cliente</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body bg-white">
                                <div class="row mt-4">
                                    <div class="col-lg-9">
                                        <!-- Nombre cliente -->
                                        <h5 class="title-list ms-3">
                                            <a class="text-list">
                                                <b>{{ (orange$ | async)?.orderInfo?.customer_full_name }}</b>
                                            </a>
                                            <br><br>
                                            <!-- Documento de identidad -->
                                            <span class="font-list">
                                                <img src="./assets/img/file.svg" class="img-fluid" width="13">
                                                {{ (orange$ | async)?.orderInfo?.document_type }}: <b>{{ (orange$ | async)?.orderInfo?.document_number }}</b>
                                            </span>
                                            <br>
                                            <!-- Msisdn -->
                                            <span class="font-list">
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                Msisdn: <b>{{ (orange$ | async)?.orderInfo?.msisdn }}</b>
                                            </span>
                                            <br>
                                            <!-- Fecha de solicitud -->
                                            <ng-container *ngIf="(orange$ | async)?.orderInfo?.created_at && (orange$ | async)?.orderInfo?.created_at !== ''">
                                                <span class="font-list">
                                                    <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                    Fecha de solicitud: <b>{{ (orange$ | async)?.orderInfo?.created_at | date: 'dd/MM/yyyy, HH:mm' }}</b>
                                                </span>
                                                <br>
                                            </ng-container>
                                            <!-- Fecha de activacion -->
                                            <ng-container *ngIf="(orange$ | async)?.orderInfo?.activation_date && (orange$ | async)?.orderInfo?.activation_date !== ''">
                                                <span class="font-list">
                                                    <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                    Fecha de activación: <b>{{ (orange$ | async)?.orderInfo?.activation_date | date: 'dd/MM/yyyy, HH:mm' }}</b>
                                                </span>
                                                <br>
                                            </ng-container>
                                        </h5>
                                    </div>

                                    <div  class="col-lg-3 d-flex justify-content-end">
                                        <div class="col-lg-12 mb-2">
                                            <span class="badge rounded-pill badge-simyo font-list">
                                                {{ (orange$ | async)?.orderInfo?.bono + ': ' + (+(orange$ | async)?.orderInfo?.contracted_price).toFixed(2) }} €
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">

                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="subtitulo-pack2 ms-3">
                                            <img src="./assets/img/file-text-negro.svg" class="img-fluid ico-title me-1" width="13">
                                            <b>Resumen de la tramitación</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-white">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item cuerpo-pack-2">
                                                <!-- RESUMEN MOVIL PACK -->
                                                <h5 class="tar-name-7 mt-2 ms-3">
                                                    <b>Bono adicional</b>
                                                </h5>

                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <span class="sidebar-info4 simyo">
                                                        Conceptos
                                                    </span>
                                                    <small class="text-muted"></small>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <!-- tarifa -->
                                                    <span class="sidebar-info5">
                                                        <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                        {{ (orange$ | async)?.orderInfo?.bono }}
                                                    </span>
                                                    <small class="text-muted">
                                                        <!-- Precio tarifa -->
                                                        <span class="sidebar-info3">
                                                            <b>{{ (+(orange$ | async)?.orderInfo?.contracted_price).toFixed(2) }} €</b>
                                                        </span>
                                                    </small>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="(orange$ | async)?.orderInfo?.status && (orange$ | async)?.orderInfo?.status !== ''">
                                                    <!-- Estado tramitacion -->
                                                    <span class="sidebar-info5"><img src="./assets/img/info.svg" class="img-fluid" width="13">
                                                        Estado: <b>{{ (orange$ | async)?.orderInfo?.status }}</b>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="(orange$ | async)?.orderInfo?.error_description && (orange$ | async)?.orderInfo?.error_description !== ''">
                                                    <!-- Descripción -->
                                                    <span class="sidebar-info5"><img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                                        Descripción: <b>{{ (orange$ | async)?.orderInfo?.error_description }}</b>
                                                    </span>
                                                </div>
                                                <br>
                                                <ng-container *ngIf="+(orange$ | async)?.orderInfo?.contracted_price > 0">
                                                    <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                                        <span class="sidebar-info">
                                                            <b>Total</b>
                                                        </span>
                                                        <small class="text-muted trash-carrito">
                                                            <!-- Precio tarifa -->
                                                            <span class="sidebar-info2">
                                                                <b>{{ (+(orange$ | async)?.orderInfo?.contracted_price).toFixed(2) }} €</b>
                                                            </span>
                                                        </small>
                                                    </div>
                                                    <hr>
                                                    <!-- Importe Total -->
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                        <h5 class="total-color">
                                                            <b>Pago total</b>
                                                        </h5>
                                                        <h5 class="text-auto">
                                                            <b>{{ (+(orange$ | async)?.orderInfo?.contracted_price).toFixed(2) }} €</b>
                                                        </h5>
                                                    </div>
                                                    <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                        <span class="total-color">
                                                            <b>IVA incluido</b>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
</ng-container>
