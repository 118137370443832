import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SimyoMovilPackService {

    private allowAddAdditionalLines: any;

    constructor() {
        this.allowAddAdditionalLines = new BehaviorSubject<boolean>(true);
    }

    setAllowAddAdditionalLines(data) {
        this.allowAddAdditionalLines.next(data);
    }

    getAllowAddAdditionalLines() {
        return this.allowAddAdditionalLines.asObservable();
    }
}
