import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';

@Component({
    selector: 'app-hm-manuals',
    templateUrl: './hm-manuals.component.html',
    styleUrls: ['./hm-manuals.component.scss', '../../../../assets/css/home-theme.css']
})
export class HmManualsComponent implements OnInit {

    public allPermissions = allPermissions;
    public pdv: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public permSv: PermissionService
    ) {
        this.pdv = data.pdv;
    }

    ngOnInit(): void {
    }
}
