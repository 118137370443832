import { createAction, props } from '@ngrx/store';
import { BonosAdicionalesOrder, BonosAdicionalesOrderListBody } from '../models/bonosAdicionalesInfoResponse';
import { MasterAllOrange, OrangeBonos, OrangeRatesWP, OrangeRate } from '../models/masterAllResponse';
import { MsisdnInfo } from '../models/msisdnInfoResponse';
import { PrepaidOrder } from '../models/prepaidInfoResponse';

// Prepago
export const setConfiFormDataPrepaid = createAction(
    '[Orange Component] Set configuration form data for prepaid', 
    props<{form: any}>()
);

export const setUserFormDataPrepaid = createAction(
    '[Orange Component] Set user form data for prepaid', 
    props<{form: any}>()
);

export const setWelcomePackPrepaid = createAction(
    '[Orange Component] Set welcome pack for prepaid', 
    props<{welcomePack: OrangeRatesWP}>()
);

export const setRatePrepaid = createAction(
    '[Orange Component] Set rate for prepaid', 
    props<{rate: OrangeRate}>()
);

export const setPrepaidOrderInfo = createAction(
    '[Orange Component] Set order info for prepaid', 
    props<{order: PrepaidOrder}>()
);

export const setPrepaidOrderList = createAction(
    '[Orange Component] Set order list for prepaid', 
    props<{orderList: any}>()
);

export const setStateInfoEsim = createAction(
    '[Orange Component] Set state info esim for prepaid', 
    props<{currentIndex: number, newState: boolean}>()
);

export const setStateSendEmail = createAction(
    '[Orange Component] Set state send email for prepaid', 
    props<{currentIndex: number, newState: boolean}>()
);

export const filteredPrepaidOrderList = createAction(
    '[Orange Component] Filtered order list for prepaid', 
    props<{text: string}>()
);

export const setProvinceByPostalCode = createAction(
    '[Orange Component] Set province by postal code for prepaid',
    props<{postalCode: number}>()
);

export const resetPrepaidOrderList = createAction(
    '[Orange Component] Reset order list for prepaid'
);

export const setLoadingCancelLinePrepaid = createAction(
    '[Orange Component] Set loading cancel line for prepaid', 
    props<{msisdn: string | null, loading: boolean}>()
);

export const setErrorCancelLinePrepaid = createAction(
    '[Orange Component] Set error cancel line for prepaid', 
    props<{msisdn: string | null, error: string}>()
);

// Additional bonos
export const setCustomerInfo = createAction(
    '[Orange Component] Set customer info', 
    props<{info: MsisdnInfo}>()
);

export const setConfiFormData = createAction(
    '[Orange Component] Set configuration form data for additional bonos', 
    props<{form: any}>()
);

export const updateBalanceMsisdn = createAction(
    '[Orange Component] Update balance msisdn', 
    props<{balance: string}>()
);

export const setOrderInfo = createAction(
    '[Orange Component] Set order info for additional bonos', 
    props<{order: BonosAdicionalesOrder}>()
);

export const setOrderList = createAction(
    '[Orange Component] Set order list for additional bonos', 
    props<{orderList: BonosAdicionalesOrderListBody}>()
);

export const filteredOrderList = createAction(
    '[Orange Component] Filtered order list for additional bonos', 
    props<{text: string}>()
);

export const selectedBono = createAction(
    '[Orange Component] Selected bono for additional bonos', 
    props<{selectedBono: OrangeBonos}>()
);

export const resetOrderList = createAction(
    '[Orange Component] Reset order list for additional bonos'
);

// Generic
export const setTotal = createAction(
    '[Orange Component] Total shopping-cart', 
    props<{total: number}>()
);

export const showShoppingCart = createAction(
    '[Orange Component] Show shopping-cart', 
    props<{show: boolean}>()
);

export const setMasterAll = createAction(
    '[Orange Component] Set master all', 
    props<{master: MasterAllOrange}>()
);

export const setErrorMaster = createAction(
    '[Orange Component] Set error request master', 
    props<{error: boolean}>()
);

export const setLoadingMaster = createAction(
    '[Orange Component] Set loading request master', 
    props<{loading: boolean}>()
);

export const resetState = createAction(
    '[Orange Component] Reset all state'
);

export const setOrderDraftId = createAction(
    '[Orange Component] Set order id for draft esim',
    props<{orderDraftId: string}>()
);