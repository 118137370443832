import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';

@Component({
    selector: 'app-hm-modal-simyo-contratos',
    templateUrl: './hm-modal-simyo-contratos.component.html',
    styleUrls: ['./hm-modal-simyo-contratos.component.css', '../../../../assets/css/home-theme.css']
})
export class HmModalSimyoContratosComponent implements OnInit {
    @Input() pdv: Ipdv;
    public allPermissions = allPermissions;

    constructor(
        public activeModal: NgbActiveModal,
        public permSv: PermissionService
    ) { }

    ngOnInit(): void {
    }

}
