import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment/moment';
import { take } from 'rxjs';

@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.css']
})
export class CustomDatepickerComponent implements OnChanges {

    @Input() id: string;
    @Input() label: string = '';
    @Input() isRequired: boolean = false;
    @Input() control: FormControl;
    @Input() controlName: string;
    @Input() options = []
    @Input() minDate = 'value'
    @Input() maxDate = 'value'
    @Input() isDisabled: boolean = false;

    @Input() customeMessageError: string;
    @Input() placeholder: string;


    @Output() OnChange = new EventEmitter();

    isFocused = false;
    isEmpty = true;
    public faCalendarDay = faCalendarDay;

    ngOnChanges(changes: SimpleChanges): void {
        if(changes?.control?.isFirstChange) {
            this.control.valueChanges.subscribe(data => {
                this.isEmpty = data == '';
            })
        }
    }

    onFocus() {
        this.isFocused = true;
    }

    onBlur() {
        this.isFocused = false;
    }

    formatDate(date) {
        if (date.includes('/') == false) {
            let format = "DDMMYYYY";
            if (date.length >= 6 && date.length < 8) {
                format = "DDMMYY";
            }
            this.control.patchValue((moment(date, format)).format("DD/MM/YYYY"));
        }
    }

    todayClicked(picker:any) {
        this.control.setValue(moment());
        picker.close();
      }

      selectedDate(picker:any)
      {
          setTimeout(()=>{
              picker._componentRef?.instance._calendar.monthView.selectedChange.pipe(take(1)).subscribe((res:any)=> {
                this.control.setValue(res);
                  picker.close();
              })

          })
      }
      clearStartDate(picker:any) {
        this.control.setValue(null);
          picker.close();
      }
}
