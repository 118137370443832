import {AfterViewInit, Component, OnInit, HostListener, ViewChildren, OnDestroy} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/home/services/home.service';

@Component({
    selector: 'app-rm-subheader',
    templateUrl: './rm-subheader.component.html',
    styleUrls: ['./rm-subheader.component.css', '../../../../assets/css/rm-theme.css']
})
export class RmSubheaderComponent implements OnInit, AfterViewInit, OnDestroy {
    public href : string;
    @ViewChildren('navbar') navbar;
    @ViewChildren('filling') filling;
    public dataOperation: any = null;

    private allSubscription: Subscription[] = [];

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private homeService: HomeService
    ) { }

    ngOnInit(): void {
        this.href = this.router.url;
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.href = event.url;
            });

        this.allSubscription.push(this.homeService.getNavigateFromOperation()
            .subscribe((data: any) => {
                this.dataOperation = data;
            })
        );

    }

    ngAfterViewInit() {
    }

    openModalHelp(content) {
        this.modalService.open(content, {size: 'md', centered: true});
    }

    @HostListener('window:scroll', ['$event'])
    doSomethingOnWindowScroll(event: Event) {
        const element: any = event.srcElement;
        const scrollOffset = element.children[0].scrollTop;
        const offsetTop = this.filling.first.nativeElement.offsetTop;

        if (scrollOffset >= offsetTop) {
            if (!this.navbar.first.nativeElement.classList.contains('fixed-top')) {
                this.filling.first.nativeElement.setAttribute('style', 'height: ' + this.navbar.first.nativeElement.offsetHeight + 'px');
                this.navbar.first.nativeElement.classList.add('fixed-top');
            }
        } else {
            if (this.navbar.first.nativeElement.classList.contains('fixed-top')) {
                this.filling.first.nativeElement.setAttribute('style', 'height: 0px');
                this.navbar.first.nativeElement.classList.remove('fixed-top');
            }
        }
    }

    goBack() {
        this.dataOperation ? this.router.navigate(['/']) : this.router.navigate(['/republica-movil/listado']);
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }
}
