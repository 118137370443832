import { Customer } from './customer';
import { Mobile } from './mobile';
import { Broadband } from './broadband';
import { simyoDocumentationUploadType } from '../models/signatureDocuments';

export class Order {
    draft: boolean = true;
    create_user?: boolean;
    reset_documents?: boolean;
    order_id: number = null;
    customer = new Customer();
    customer_id?: string;
    mobiles = new Array<Mobile>();
    broadband = new Broadband();
    sign_type: simyoDocumentationUploadType = 'old';
    only_send_email: boolean = false;
    existing_client: boolean;
    tax_type?: string;
    tax_type_cp?: string;
    agreements = {
        allow_data_navigation: false,
        receive_third_communications: false,
        give_data_orange_group: false,
        allow_create_commercial_profile: false,
        allow_commercial_communications: false
    };
    digo_user_id?: string;
    pdv_id?: string;
    username?: string;
    is_supplant: boolean = false;
    total_monthly?: any;
    total_initial?: any;
}
