import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import { allLocalStorage } from 'src/app/shared/constantes';

@Component({
    selector: 'app-token-has-expired',
    templateUrl: './token-has-expired.component.html',
    styleUrls: ['./token-has-expired.component.css']
})
export class TokenHasExpiredComponent implements OnInit {

    constructor(public dialog: MatDialogRef<TokenHasExpiredComponent>) {
    }

    ngOnInit(): void {
        console.log(location.origin);
    }

    goToLogin() {
        localStorage.removeItem(allLocalStorage.accessToken);
        if (!environment.production) {
            if (location.origin.includes('localhost')) {
                window.location.href = 'http://localhost/nuevo-frontal-digo-angular/index.php';
            } else {
                window.location.href = 'https://int.web-pdv.digo.tecalis.dev/';
            }
        } else {
            window.location.href = 'https://digo.dexga.com';
        }

        this.dialog.close();
    }
}
