import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-simyo-temporary-closure',
    templateUrl: './simyo-temporary-closure.component.html',
    styleUrls: ['./simyo-temporary-closure.component.css']
})
export class SimyoTemporaryClosureComponent implements OnInit {

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
    }

}
