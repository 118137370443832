<ng-container>
    <div class="stepline"></div>
    <mat-horizontal-stepper [@.disabled]="true" labelPosition="bottom" #stepper="matHorizontalStepper" 
        class="row simyo-steppper" [linear]="true" [disableRipple]="true">
        <ng-template matStepperIcon="edit" let-index="index">{{index + 1}}</ng-template>
        <mat-step [editable]="isEditable" [stepControl]="configurationFormGroup" label="Configuración" state="number">
            <app-simyo-ds-configuration [formGroup]="configurationFormGroup" [stepper]="stepper" [master]="master" [pdv]="pdv"></app-simyo-ds-configuration>
        </mat-step>

        <mat-step label="Confirmación" state="number">
            <app-simyo-ds-confirmation [formGroup]="configurationFormGroup" [master]="master" (isEditable)="stateEditable($event)"></app-simyo-ds-confirmation>
        </mat-step>
    </mat-horizontal-stepper>
</ng-container>