<ng-container>
    <div class="stepline"></div>
    <mat-horizontal-stepper [@.disabled]="true" labelPosition="bottom" #stepper="matHorizontalStepper" class="row" [linear]="true" [disableRipple]="true"  (selectionChange)="onStepChange($event)">
        <ng-template matStepperIcon="edit" let-index="index">{{index + 1}}</ng-template>

        <mat-step [editable]="isEditable" [stepControl]="configurationFormGroup" label="Línea móvil" state="number">
            <app-orange-prepaid-configuration [userDataFormGroup]="userDataFormGroup" [configurationFormGroup]="configurationFormGroup" [stepper]="stepper" [pdv]="pdv" [type]="type" (goTop)="goTop($event)"></app-orange-prepaid-configuration>
        </mat-step>

        <mat-step [editable]="isEditable" [stepControl]="userDataFormGroup" label="Cliente" state="number">
            <app-orange-prepaid-user-data [userDataFormGroup]="userDataFormGroup" [agreementsFormGroup]="agreementsFormGroup" [stepper]="stepper" [pdv]="pdv" [configurationFormGroup]="configurationFormGroup" [type]="type"></app-orange-prepaid-user-data>
        </mat-step>

        <mat-step label="Confirmación" state="number">
            <app-orange-prepaid-confirmation [configurationFormGroup]="configurationFormGroup" [userDataFormGroup]="userDataFormGroup" [agreementsFormGroup]="agreementsFormGroup" [stepper]="stepper" [pdv]="pdv" [type]="type" (isEditable)="stateEditable($event)"></app-orange-prepaid-confirmation>
        </mat-step>
    </mat-horizontal-stepper>
</ng-container>
