import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {JwtComponent} from './core/components/jwt/jwt.component';

const routes: Routes = [
    {path: 'jwt/:jwt', component: JwtComponent, data: {title:'jwt'}},
    {path: 'jwt/:jwt/:brand', component: JwtComponent, data: {title:'jwt brand'}},
    {path: 'jwt/:jwt/:brand/:state', component: JwtComponent, data: {title:'jwt brand state'}},
    { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), data: {title:'Home'}},
    { path: 'republica-movil', loadChildren: () => import('./republica-movil/republica-movil.module').then(m => m.RepublicaMovilModule), data: {title:'Republica Movil'} },
    { path: 'simyo', loadChildren: () => import('./simyo/simyo.module').then(m => m.SimyoModule), data: {title:'simyo'} },
    { path: 'orange', loadChildren: () => import('./orange/orange.module').then(m => m.OrangeModule), data: {title:'Orange'} },
    { path: '**', redirectTo: '', data: {title:'Home'} }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
