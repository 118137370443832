<div *ngIf="!loaded && !orderNotFound" class="mt-5 mb-5">
    <h5  class="tar-name-preload text-center">Obteniendo orden...</h5>

    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
    </div>
</div>

<div *ngIf="loadingCobertura" class="mt-5 mb-5">
    <h5  class="tar-name-preload text-center">Consultando cobertura...</h5>

    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="">
    </div>
</div>

<div class="container content mb-2" *ngIf="loaded && !loadingCobertura">
    <!-- Titulo -->
    <div class="row">
        <div class="col-md-12">
            <h5 class="title-step mt-4 text-center" >
                Resumen de la activación
            </h5>
        </div>
    </div>

    <!-- Descripcion rapida -->
    <div class="row mt-3 mb-2">
        <div class="col-md-12">
            <div class="jumbotron-norm-r mb-3">
                <div class="row ms-1">
                    <div class="col-lg-2">
                        <h5  class="tar-name-6 mt-2 mb-1">Nº de contrato</h5>
                        <h5  class="tar-name-7 mt-1">{{ getContractNumber() }}</h5>
                    </div>
                    <!-- Caducidad -->
                    <div class="col-lg-2">
                        <h5  class="tar-name-6 mt-2 mb-1">Fecha</h5>
                        <h5  class="tar-name-7 mt-1">{{ order.tramited_at && order.tramited_at !== '' ? (order.tramited_at | date: 'dd/MM/yyyy, HH:mm') : (order.created_at | date: 'dd/MM/yyyy, HH:mm') }}</h5>
                    </div>
                    <!-- Estado -->
                    <div class="col-lg-2">
                        <h5  class="tar-name-6 mt-2 mb-1">Estado</h5>
                        <h5  class="tar-name-7 mt-1 complete">
                            {{ order.status_description }}
                            <i-feather *ngIf="showUpdateOrder(order)" (click)="getOrderInfo(true)" name="refresh-cw" class="icon--fe m-1 cursor-pointer" style="width: 15px !important; height: auto; color: #000;" title="Pulse para actualizar el pedido"></i-feather>
                        </h5>
                    </div>
                    <!-- Descripcion -->
                    <div class="col-lg-2" *ngIf="order.error_description && order.error_description !== ''">
                        <h5  class="tar-name-6 mt-2 mb-1">Descripción</h5>
                        <h5  class="tar-name-7 mt-1">{{ order.error_description }}</h5>
                    </div>
                    <!-- Documentación -->
                    <div class="col" *ngIf="order.doc_status && order.doc_status !== ''">
                        <h5  class="tar-name-6 mt-2 mb-1">Documentación {{ order?.sign_type && order?.sign_type !== 'not_required' && order?.sign_type !== 'old' ? '(' + translateSignType[order.sign_type] + ')' : '' }}</h5>
                        <h5  class="tar-name-7 mt-1">{{ order.doc_status }}{{ order.doc_annotation && order.doc_annotation !== '' ? ': ' + order.doc_annotation : '' }}</h5>
                    </div>
                    <!-- Incidencias -->
                    <div class="col-auto me-3 d-flex justify-content-end"  *ngIf="order.doc_warning && order.doc_warning !== ''" style="align-self: center;" title="Ver incidencias" (click)="moveTo('incidencias')">
                        <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white cursor-pointer" width="20">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Cliente -->
    <div class="row mb-2">
        <div class="col-md-12">
            <div class="card mb-3">
                <div class="card-header">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <h6 class="subtitulo-pack2">
                                <b>Cliente</b>
                            </h6>
                        </div>
                    </div>
                </div>

                <div class="card-body bg-white">
                    <div class="row mt-4">
                        <div class="col-lg-8">
                            <!-- Nombre cliente -->
                            <h5 class="title-list ms-3">
                                <b *ngIf="order && order.is_contingency" ngbTooltip="Alta por soporte">
                                    <img src="./assets/img/support.png" alt="Alta por soporte" class="img-fluid me-2" width="24" style="height: auto; top: -1px; position: relative;">
                                </b>

                                <span class="text-list"
                                      *ngIf="(!order.customer_document_type_id || order.customer_document_type_id !== '4') && order.customer_full_name && order.customer_full_name != ''">
                                    <b>{{ order.customer_full_name }} </b>
                                <br><br>
                                </span>
                                <span class="text-list"
                                      *ngIf="order.customer_document_type_id && order.customer_document_type_id === '4'">
                                    <b>{{ order.customer_company_name }}</b>
                                <br><br>
                                </span>
                                <!-- Documento de identidad -->
                                <span class="font-list" *ngIf="order.customer_document_type_id">
                                    <img src="./assets/img/file.svg" class="img-fluid" width="13">
                                    {{ getDocumentLabel(order?.customer_document_type_id) }}: <b>{{ order.customer_document }}</b>
                                <br>
                                </span>
                                <!-- Fecha de solicitud -->
                                <span class="font-list">
                                    <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                    Fecha de solicitud: <b>{{ order.tramited_at && order.tramited_at !== '' ? (order.tramited_at | date: 'dd/MM/yyyy, HH:mm') : (order.created_at | date: 'dd/MM/yyyy, HH:mm') }}</b>
                                </span>
                                <br>
                                <!-- Tipo de alta -->
                                <span class="font-list">
                                    <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                    Tipo de alta:
                                    <b *ngIf="order.type === orderType.BROADBAND">
                                        {{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Alta fibra IND' : 'Alta fibra') }}
                                    </b>
                                    <b *ngIf="order.type === orderType.CONVERGENT">
                                        {{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Alta fibra IND + ' : 'Alta fibra + ') + order.mobiles.length + (order.mobiles.length > 1 ? ' líneas móviles' : ' línea móvil') }}
                                    </b>
                                    <b *ngIf="order.type !== orderType.CONVERGENT && order.type !== orderType.BROADBAND">
                                        {{ order.mobiles.length + (order.mobiles.length > 1 ? ' líneas móviles' : ' línea móvil') }}
                                    </b>
                                </span>
                            </h5>
                        </div>

                        <div  class="col-lg-4 d-flex justify-content-end">
                            <div class="col-lg-12 mb-2">

                                <span class="badge rounded-pill badge-simyo"
                                      *ngIf="(order.type === orderType.CONVERGENT || order.type === orderType.BROADBAND) && order.broadband && order.broadband.rate">
                                      <span>
                                        {{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Fibra IND ' : 'Fibra ') + order.broadband?.rate?.name + ': ' + (+order?.broadband?.rate?.rate_info?.contracted_price|toFixedDecimals) }} €
                                    </span>
                                </span>
                            </div>
                            <div class="col-lg-12 mb-2" *ngFor="let mobile of order.mobiles; index as i">
                                <span class="badge rounded-pill badge-simyo" *ngIf="mobile.rate">
                                    {{ mobile.rate?.data + ' + ' + mobile.rate?.voice + ': ' + (simyoGlobals.getMobilePrice(mobile)|toFixedDecimals) }} €
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Resumen tramitacion -->
    <div class="row mb-2">
        <div class="col-md-12">
            <div class="accordion" id="accordionExample">
                <div class="card mb-3">
                    <div class="card-header bg-rm">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <h6 class="subtitulo-pack2 ms-3">
                                    <b>Resumen de la tramitación</b>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <ng-template [ngIf]="order.mobiles && (order.mobiles.length > 0) || (order.broadband && order.broadband.rate)" [ngIfElse]="viewNotMobiles">
                        <div class="bg-white">
                            <div class="row">
                                <div class="col-md-12">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item cuerpo-pack-2">
                                            <!-- CONVERGENTE -->
                                            <div *ngIf="order.type === orderType.CONVERGENT && order.broadband">
                                                <h5  class="tar-name-7 mt-2 ms-3">
                                                    <b>Fibra</b>
                                                </h5>

                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <span class="sidebar-info4 simyo"> Conceptos</span>
                                                    <small class="text-muted"></small>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <!-- tarifa -->
                                                    <span class="sidebar-info5"><img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                        {{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Fibra IND ' : 'Fibra ') + order.broadband.rate.name }} <!--{{ showDiscount ? '' : '' }}   ? '*' : ''  -->
                                                    </span>
                                                    <small class="text-muted">
                                                        <!-- Precio tarifa -->
                                                        <span class="sidebar-info3">
                                                            <b>{{ (+order?.broadband?.rate?.rate_info?.contracted_price + (order?.broadband?.rate?.rate_info?.already_client ? descuento : 0))|toFixedDecimals }} €/mes</b>
                                                        </span>
                                                    </small>
                                                </div>

                                                <!-- Descuento -->
                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="order?.broadband?.rate?.rate_info?.already_client">
                                                    <!-- tarifa -->
                                                    <span class="sidebar-info5">
                                                        Descuento por fibra y móvil {{tramitedAfter14March}}
                                                    </span>
                                                    <small class="text-muted trash-carrito">
                                                        <!-- Precio tarifa -->
                                                        <span class="sidebar-info3">
                                                            <b>-{{ (descuento)|toFixedDecimals }} €/mes</b>
                                                        </span>
                                                    </small>
                                                </div>
                                                <div class="resumen d-flex justify-content-between align-items-center ps-3"
                                                    *ngIf="order.super_status !== superStatus.draft && order.broadband && ((order.broadband.status_description && order.broadband.status_description !== '') || order.broadband.substatus)">
                                                    <span class="sidebar-info5">
                                                        Estado fibra:
                                                        <span [ngbTooltip]="order?.broadband?.substatus?.description && order?.broadband?.substatus?.description !== '' ? infoBroadband : ''" [placement]="'right'">
                                                            <b [ngClass]="{'cursor-pointer': order?.broadband?.substatus}">{{ order.broadband.substatus?.name && order.broadband.substatus?.name !== '' ? order.broadband.substatus.name : order.broadband.status_description }} </b>
                                                            <span *ngIf="order?.broadband?.substatus?.description && order?.broadband?.substatus?.description !== ''" class="px-2 custom-info cursor-pointer">+info</span>
                                                        </span>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-start ps-3">
                                                    <span class="sidebar-info5">
                                                        Tipo de instalación:
                                                    </span>
                                                    <div class="fw-bold ms-1 sidebar-info5" [innerHTML]="getTextInstallationType(order?.broadband)"></div>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center ps-3"
                                                    *ngIf="order.broadband && order.broadband.activation_date && order.broadband.activation_date !== ''">
                                                    <span class="sidebar-info5">
                                                        Fecha de activación: <b> {{ order.broadband.activation_date | date: 'dd/MM/yyyy'}}</b>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                                    <span class="sidebar-info"><b>Total</b></span>
                                                    <small class="text-muted trash-carrito">
                                                        <!-- Precio tarifa -->
                                                        <span class="sidebar-info2" *ngIf="order.mobiles && order.mobiles.length">
                                                            <ng-container *ngIf="order.broadband">
                                                                <b>{{ (+order.broadband.rate?.rate_info.contracted_price)|toFixedDecimals }} €/mes</b>
                                                            </ng-container>
                                                        </span>
                                                    </small>
                                                </div>
                                            </div>

                                            <!-- SOLO FIBRA -->
                                            <div *ngIf="order.type === orderType.BROADBAND && order.broadband">
                                                <h5  class="tar-name-7 mt-2 ms-3">
                                                    <b>Alta fibra</b>
                                                </h5>
                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <span class="sidebar-info4 simyo"> Conceptos</span>
                                                    <small class="text-muted"></small>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <!-- tarifa -->
                                                    <span class="sidebar-info5"><img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                        {{ (fiberIndirectTechnologies.includes(order?.broadband?.access_type) ? 'Fibra IND ' : 'Fibra ') + order.broadband.rate.name }}
                                                        </span>
                                                    <small class="text-muted">
                                                        <!-- Precio tarifa -->
                                                        <span class="sidebar-info3">
                                                            <b>{{ (+order?.broadband?.rate?.rate_info?.contracted_price + (order?.broadband?.rate?.rate_info?.already_client ? descuento : 0))|toFixedDecimals }} €/mes</b>
                                                        </span>
                                                    </small>
                                                </div>

                                                <!-- Descuento -->
                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="order?.broadband?.rate?.rate_info?.already_client">
                                                    <!-- tarifa -->
                                                    <span class="sidebar-info5">
                                                        Descuento por fibra y móvil {{tramitedAfter14March}}
                                                    </span>
                                                    <small class="text-muted trash-carrito">
                                                        <!-- Precio tarifa -->
                                                        <span class="sidebar-info3">
                                                            <b>-{{ (descuento)|toFixedDecimals }} €/mes</b>
                                                        </span>
                                                    </small>
                                                </div>
                                                <div class="resumen d-flex justify-content-between align-items-center ps-3"
                                                    *ngIf="order.super_status !== superStatus.draft && order.broadband && ((order.broadband.status_description && order.broadband.status_description !== '') || order.broadband.substatus)">
                                                    <span class="sidebar-info5">
                                                        Estado fibra:
                                                        <span [ngbTooltip]="order?.broadband?.substatus?.description && order?.broadband?.substatus?.description !== '' ? infoBroadband : ''" [placement]="'right'">
                                                            <b [ngClass]="{'cursor-pointer': order?.broadband?.substatus}">{{ order.broadband.substatus?.name && order.broadband.substatus?.name !== '' ? order.broadband.substatus.name : order.broadband.status_description }} </b>
                                                            <span *ngIf="order?.broadband?.substatus?.description && order?.broadband?.substatus?.description !== ''" class="px-2 custom-info cursor-pointer">+info</span>
                                                        </span>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-start ps-3">
                                                    <span class="sidebar-info5">
                                                        Tipo de instalación:
                                                    </span>
                                                    <div class="fw-bold ms-1 sidebar-info5" [innerHTML]="getTextInstallationType(order?.broadband)"></div>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center ps-3"
                                                    *ngIf="order.broadband && order.broadband.activation_date && order.broadband.activation_date !== ''">
                                                    <span class="sidebar-info5">
                                                        Fecha de activación: <b> {{ order.broadband.activation_date | date: 'dd/MM/yyyy'}}</b>
                                                    </span>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                                    <span class="sidebar-info"><b>Total</b></span>
                                                    <small class="text-muted trash-carrito">
                                                        <!-- Precio tarifa -->
                                                        <span class="sidebar-info2"><b>{{ (+order?.broadband?.rate?.rate_info?.contracted_price)|toFixedDecimals }} €/mes</b></span>
                                                    </small>
                                                </div>
                                            </div>

                                            <!-- RESUMEN LÍNEA ADICIONAL -->

                                            <div *ngFor="let mobile of order.mobiles; index as i">
                                                <hr *ngIf="order.type !== orderType.MOBILE">

                                                <h5  class="tar-name-7 mt-2 ms-3">
                                                    <b>{{ (i + 1) + 'ª línea móvil' }}</b>
                                                </h5>

                                                <!-- CONCEPTOS -->
                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <span class="sidebar-info4 simyo"> Conceptos</span>
                                                    <small class="text-muted">
                                                    </small>
                                                </div>

                                                <!-- Tipo tarifa movil -->
                                                <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                                    <span class="sidebar-info5">
                                                        <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                        {{ getTitleLine(mobile) }}
                                                    </span>
                                                </div>
                                                <div class="resumen d-flex justify-content-between align-items-center">
                                                    <!-- tarifa -->
                                                    <span class="sidebar-info5">
                                                        <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                        {{ mobile.rate.data + ' + ' + mobile.rate.voice }} {{ mobile.contract_type === 'postpaid' ? '(Pospago)' : '(Prepago)' }}<!--{{ (showDiscount && i === getIndexFirstPostpaidMobile()) && !(showDiscount && showDiscountForAdditional)? '' : '' }}  ? '*' : ''
                                                        -->
                                                        <ng-container *ngIf="order.mobiles[i].super_status === superStatus.active">
                                                            <span *ngIf="order.mobiles[i].is_esim == typeCard.esim && order.mobiles[i].show_email" class="px-2 mx-2 custom-info cursor-pointer" (click)="changeStateSendEmail(i)">
                                                                {{ order.mobiles[i].showSendEmail ? 'Ocultar eSIM por e-mail' : 'Enviar eSIM por e-mail' }}
                                                            </span>
                                                            <span *ngIf="order.mobiles[i].is_esim == typeCard.esim && order.mobiles[i].show_qr && order.mobiles[i].pin"><a (click)="changeStateInfoEsim(i)" class="px-2 mx-2 custom-info cursor-pointer a-link">
                                                                {{ hideEsim(i) ? 'Ver' : 'Ocultar'}} eSIM</a>
                                                            </span>
                                                        </ng-container>
                                                    </span>
                                                    <small class="text-muted">
                                                        <!-- Precio tarifa -->
                                                        <span class="sidebar-info3" *ngIf="!mobile.rate?.is_additional || getOldPrice(mobile.rate?.rate_info) === ''">
                                                            <b>{{ (+mobile.rate.rate_info.contracted_price)|toFixedDecimals }} €/mes</b>
                                                        </span>
                                                        <span class="sidebar-info3" *ngIf="mobile.rate?.is_additional && getOldPrice(mobile.rate?.rate_info) !== ''">
                                                            <b>{{ (+getOldPrice(mobile.rate?.rate_info))|toFixedDecimals }} €/mes</b>
                                                        </span>
                                                    </small>
                                                </div>

                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="mobile.rate?.is_additional && getOldPrice(mobile.rate?.rate_info) !== ''">
                                                    <span class="sidebar-info5">
                                                        Descuento por línea adicional
                                                    </span>
                                                    <small class="text-muted trash-carrito">
                                                        <span class="sidebar-info3">
                                                            <b>-{{ (+getOldPrice(mobile.rate?.rate_info) - +mobile.rate?.rate_info?.contracted_price)|toFixedDecimals }} €/mes</b>
                                                        </span>
                                                    </small>
                                                </div>

                                                <div class="resumen" *ngIf="order.mobiles[i].show_qr && !hideEsim(i) && order.mobiles[i].pin">
                                                    <div>
                                                        <div class="px-4 pb-2">
                                                            <p><b class="col-6 px-0">PIN: </b><span class="simyo">{{ order.mobiles[i].pin }}</span></p>
                                                            <img [src]="order.mobiles[i].qr_code" [width]="150" class="img-fluid" alt="Qr code"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="(statusSendQR$ | async) as statusSendQR">
                                                    <div class="resumen d-flex align-items-center p-3 row" *ngIf="order.mobiles[i].showSendEmail">
                                                        <div class="col-12 px-0">
                                                            <mat-form-field class="col-6 me-3">
                                                                <mat-label>Correo electrónico</mat-label>
                                                                <input matInput type="text" disabled [ngModel]="email">
                                                            </mat-form-field>
                                                            <button (click)="sendEmail(i)" target="_blank" class="btn btn-black-outline btn-sm" [disabled]="statusDisableSend.includes(statusSendQR[i])">
                                                                <b>{{getTextButtonSendQR((statusSendQR$ | async))}}</b>
                                                            </button>
                                                        </div>
                                                        <mat-error class="col-12 px-0" *ngIf="statusSendQR[i] == 'error'">
                                                            <p>Error al enviar el email. Inténtalo de nuevo más tarde</p>
                                                        </mat-error>
                                                        <p class="message-status success mb-0" *ngIf="statusSendQR[i] == 'success'">El email se ha enviado correctamente.</p>
                                                        <p class="message-status block mb-0" *ngIf="statusSendQR[i] == 'blocked'">{{messageBlocked}}</p>
                                                    </div>
                                                </ng-container>

                                                <!-- Bonos
                                                <ng-container *ngIf="mobile.bonos && mobile.bonos.length">
                                                    <div class="resumen d-flex justify-content-between align-items-center" *ngFor="let bono of mobile.bonos">
                                                        <!- Bono ->
                                                        <span class="sidebar-info5">
                                                            <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                            {{ bono.rate?.rate_info?.description }}
                                                        </span>
                                                        <small class="text-muted">
                                                            <!- Precio bono ->
                                                            <span class="sidebar-info3">
                                                                <b>{{ (+bono.rate?.rate_info?.price)|toFixedDecimals }} €/mes</b>
                                                            </span>
                                                        </small>
                                                    </div>
                                                </ng-container>-->

                                                <!-- Terminal -->
                                                <ng-container *ngIf="mobile.terminal_oid">
                                                    <div class="resumen d-flex justify-content-between align-items-center">
                                                        <!-- terminal -->
                                                        <span class="sidebar-info5">
                                                            <img src="./assets/img/smartphone2.svg" class="img-fluid" width="13">
                                                            {{ mobile.terminal_oid?.terminal?.brand }} {{ mobile.terminal_oid?.terminal?.model }}
                                                            <span *ngIf="mobile.terminal_oid.is_installment">
                                                                ({{ mobile.terminal_oid?.terminal?.dues }} meses)
                                                            </span>
                                                        </span>
                                                        <small class="text-muted">
                                                            <!-- Precio terminal -->
                                                            <span class="sidebar-info3">
                                                                <b *ngIf="!mobile.terminal_oid.is_installment">
                                                                    Pago único
                                                                </b>
                                                                <b *ngIf="mobile.terminal_oid.is_installment">
                                                                    {{ (+mobile.terminal_oid?.terminal?.installment)|toFixedDecimals }} €/mes
                                                                </b>
                                                            </span>
                                                        </small>
                                                    </div>
                                                </ng-container>

                                                <div class="resumen pt-0 d-flex flex-column align-items-start">
                                                    <span class="ms-3 d-block" *ngIf="!mobile.portability && mobile.new_msisdn">
                                                        <small>Línea Móvil: <strong>{{ mobile.new_msisdn }}</strong></small>
                                                    </span>

                                                    <span class="ms-3 d-block" *ngIf="mobile.portability && mobile.msisdn != ''">
                                                        <small>Número Portabilidad Móvil: <strong>{{ mobile.msisdn }}</strong></small>
                                                    </span>

                                                    <span class="ml-3 d-block" *ngIf="mobile.portability && mobile.msisdn != ''">
                                                        <small>Número Portabilidad Móvil: <strong>{{ mobile.msisdn }}</strong></small>
                                                    </span>

                                                    <span class="ml-3 d-block" *ngIf="order.super_status !== superStatus.draft && mobile.status_description && mobile.status_description != ''">
                                                        <small>Estado línea móvil: <strong>{{ mobile.status_description }}</strong></small>
                                                    </span>

                                                    <span class="ms-3 d-block" *ngIf="mobile.new_iccid && mobile.new_iccid !== ''">
                                                        <small>ICCID: <strong>{{ mobile.new_iccid }}</strong></small>
                                                    </span>

                                                    <span class="ms-3 d-block" *ngIf="mobile.portability && mobile.processing_date != '' && mobile.processing_date != undefined ">
                                                        <small>Fecha de portabilidad: <strong>{{ mobile.new_iccid | date: 'dd/MM/yyyy' : '+1000' }}</strong></small>
                                                    </span>

                                                    <span class="ms-3 d-block" *ngIf="mobile.activation_date && mobile.activation_date != ''">
                                                        <small>Fecha de activación: <strong>{{ mobile.activation_date | date: 'dd/MM/yyyy' }}</strong></small>
                                                    </span>
                                                </div>

                                                <!-- Recordatorio movil y convergente -->
                                                <div class="resumen d-flex justify-content-between align-items-center" *ngIf="(order.type === orderType.MOBILE || order.type === orderType.CONVERGENT) && mobile.contract_type === 'prepaid' && getRechargePrice(simyoGlobals.getMobilePrice(mobile)) > 0">
                                                    <!-- Titulo -->
                                                    <span class="sidebar-info5 text-simyo">
                                                        Recuerde al cliente que debe recargar {{ (getRechargePrice(simyoGlobals.getMobilePrice(mobile)))|toFixedDecimals }}€ para su activación
                                                    </span>
                                                </div>

                                                <br>

                                                <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                                    <span class="sidebar-info"><b>Total</b></span>
                                                    <small class="text-muted trash-carrito">
                                                        <!-- Precio tarifa -->
                                                        <span class="sidebar-info2">
                                                            <b>{{ (priceMobileMonth(mobile))|toFixedDecimals }} €/mes</b>
                                                        </span>
                                                    </small>
                                                </div>
                                            </div>
                                            <hr>

                                            <!-- Importe Total -->
                                            <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                <h5 class="total-color"><b>Pago mensual</b></h5>
                                                <h5 class="text-auto"><b>{{ (totalMensualPrice)|toFixedDecimals }} €/mes</b></h5>
                                            </div>

                                            <div *ngIf="totalPriceInicial > 0">
                                                <br>
                                                <ng-container *ngIf="order.mobiles.length">
                                                    <div class="resumen d-flex justify-content-between align-items-center" *ngFor="let mobile of order.mobiles">
                                                        <ng-container *ngIf="mobile.terminal_oid">
                                                            <!-- Terminal -->
                                                            <span class="sidebar-info5">
                                                                <img src="./assets/img/smartphone2.svg" class="img-fluid" width="13">
                                                                {{ mobile.terminal_oid?.terminal?.brand }} {{ mobile.terminal_oid?.terminal?.model }} {{ mobile.terminal_oid?.is_installment ? '' : '(Pago único)' }}
                                                            </span>
                                                            <small class="text-muted">
                                                                <!-- Precio terminal -->
                                                                <span class="sidebar-info3" *ngIf="mobile.terminal_oid.is_installment">
                                                                    <b>{{ (+mobile.terminal_oid?.terminal?.upfront)|toFixedDecimals }} €</b>
                                                                </span>
                                                                <span class="sidebar-info3" *ngIf="!mobile.terminal_oid.is_installment">
                                                                    <b>{{ +mobile.terminal_oid?.terminal?.price }} €</b>
                                                                </span>
                                                            </small>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                                <!-- Importe Total -->
                                                <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                    <h5 class="total-color">
                                                        <b>Pago inicial</b>
                                                    </h5>
                                                    <h5 class="text-auto">
                                                        <b>{{ (totalPriceInicial)|toFixedDecimals }} €</b>
                                                    </h5>
                                                </div>
                                            </div>

                                            <div class="resumen-total total d-flex justify-content-between align-items-center">
                                                <span class="total-color">
                                                    <b><span>{{getTaxType(order.tax_type)}} </span>incluido</b>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #viewNotMobiles>
                        <div class="bg-white">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="px-4 py-4">
                                        <b>Este pedido no contiene datos sobre los servicios a contratar.</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <!-- Incidencias -->
    <div class="row mb-2" *ngIf="order.doc_warning && order.doc_warning !== ''" id="incidencias">
        <div class="col-md-12">
            <div class="accordion">
                <div class="card mb-3">
                    <div class="card-header bg-rm">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <h6 class="subtitulo-pack2 ms-3">
                                    <b>Incidencias</b>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white">
                        <div class="row">
                            <div class="col-md-12">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item cuerpo-pack-2">
                                        <h5 class="tar-name-7 mt-2 ms-3">{{ order.doc_warning }}</h5>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Firma Nueva -->
    <div class="mb-2" *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature) && order?.super_status !== superStatus.draft">
        <app-simyo-documentation-signature [dataOrderToSignature]="dataOrderToSignature" [documentationMode]="documentationMode.viewDocumentation" [fullOrder]="order" [signatureFormGroup]="signatureFormGroup"></app-simyo-documentation-signature>
    </div>

    <!-- Botones -->
    <div class="my-4 d-flex justify-content-center" *ngIf="!this.permSv.hasPermFromV2(allPermissions.simyo.tuvo_activaciones)">
        <button (click)="continueDraftOrder()" class="btn btn-simyo-o2 btn-sm w-100" *ngIf="order.super_status === superStatus.draft && !permSv.hasPermFromV2(allPermissions.simyo.nbss_block)" [disabled]="disabledButton">
            Continuar la tramitación donde la había dejado
        </button>
        <button (click)="openModalNewProcess(true)" class="btn btn-simyo-o2 btn-sm w-100" *ngIf="order.super_status !== superStatus.draft && !permSv.hasPermFromV2(allPermissions.simyo.nbss_block)" [disabled]="disabledButton">
            Nueva tramitación copiando los datos de este cliente
        </button>
        <button (click)="openModalNewProcess(false)" class="btn btn-simyo-o2 btn-sm w-100 mt-2" *ngIf="(order.super_status === superStatus.cancelled || order.can_retry) && !permSv.hasPermFromV2(allPermissions.simyo.nbss_block)" [disabled]="disabledButton">
            Retramitación con los productos cancelados
        </button>
        <button (click)="removeDraft()" class="btn btn-simyo-o2 btn-sm w-100 mt-2" *ngIf="order.super_status === superStatus.draft" [disabled]="disabledButton">
            Eliminar borrador
        </button>
    </div>
    <br><br>
</div>

<div class="container mt-3" *ngIf="orderNotFound">
    <div class="row">
        <div class="col-md-12">
            <h5 class="title-step mt-4 text-center" >
                Lo sentimos, el pedido que está buscando no existe
            </h5>
            <div class="d-flex justify-content-center">
                <button (click)="goBack()" class="btn btn-simyo-o2 btn-sm mt-2">
                    Volver al listado
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #infoBroadband>
    <div class="d-flex flex-column">
        <span>{{ order?.broadband?.substatus?.description }}</span>
    </div>
</ng-template>
