<div class="modal-container">
    <h5 class="title-step mt-0 mb-3 text-center">Sesión caducada</h5>

    <div>
        <p>La sesión ha caducado, inicia de nuevo sesión desde DIGO</p>
    </div>

    <div class="align-items-center">
        <button class="btn btn-orange d-block w-100" (click)="goToLogin()">Iniciar sesión</button>
    </div>
<div>

