import { createReducer, on } from '@ngrx/store';
import { BonosAdicionalesOrder } from '../models/bonosAdicionalesInfoResponse';
import { OrangeBonos } from '../models/masterAllResponse';
import { MsisdnInfo } from '../models/msisdnInfoResponse';
import { filteredOrderList, resetOrderList, resetState, selectedBono, setConfiFormData, setCustomerInfo, setMasterAll, setOrderInfo, setOrderList, setTotal, showShoppingCart, updateBalanceMsisdn } from './orange.actions';
import { additionalBonosInitialState } from './orange.state';


const orangeReducer = createReducer(additionalBonosInitialState,
    // Additional bonos
    on(setCustomerInfo, (state, { info }) => {
        // Añadimos el valor del tipo de documento
        if (state?.master?.document_types && state?.master?.document_types.length && info && info?.document_type_id !== null) {
            const foundDoc = state.master.document_types.find(doc => +doc.document_type_id === +info.document_type_id);
            if (foundDoc) {
                info = {...info, document_type_name: foundDoc.name};
            }
        }
        // Formateamos los bonos activos por si tienen más de uno
        if (info && info?.active_bonos && info?.active_bonos?.length) {            
            info = {...info, active_bonos_name: info.active_bonos.map(x => x).join(', ').replace(/,(?!.*,)/gmi, ' y')};
        }
        // Añadimos todos los bonos del cliente
        if (info && info?.available_bonos) {        
            let customerBonos: OrangeBonos[] = [];
            state.master.bonos.map(masterBono => {
                if (masterBono.available) {
                    customerBonos.push({...masterBono, active: info.available_bonos.some(bono => +bono === +masterBono.bono_id), blocked_msg: getBlockedMsg(info, masterBono) });
                }
            });
            info = {...info, available_bonos_info: customerBonos};
        }
        return { 
            ...state,
            configuration_form_values: {
                ...state.configuration_form_values,
                customer_info: info
            }
        }
    }),

    on(setConfiFormData, (state, { form }) => ({ 
            ...state, 
            configuration_form_values: {
                ...state.configuration_form_values,
                msisdn: form.msisdn,
                rate: form.rate,
                correctBalance: form.correctBalance
            } 
        })
    ),

    on(updateBalanceMsisdn, (state, { balance }) => ({ 
            ...state, 
            configuration_form_values: {
                ...state.configuration_form_values,
                customer_info: {
                    ...state.configuration_form_values.customer_info,
                    balance: balance.toString()
                }
            }
        })
    ),

    on(setOrderInfo, (state, { order }) => (
        { ...state, orderInfo: order })
    ),

    on(setOrderList, (state, { orderList }) => {
        // Concatenamos los nuevos valores
        orderList = {...state.orderList, data: state.orderList.data.concat(orderList.data)};
        return { 
            ...state, 
            orderList: orderList,
            orderListFiltered: orderList.data
        };
    }),

    on(filteredOrderList, (state, { text }) => {
        function filterOrderListByText() : BonosAdicionalesOrder[] {
            return state.orderList.data.filter(item => {
                const fullName: string = item.customer_full_name.toLowerCase();
                const fullNameNormalized = fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                let document: string;
    
                if (item.document_number) {
                    document = item.document_number.toLowerCase();
                }
    
                return document ? fullName.includes(text.toLowerCase()) || fullNameNormalized.includes(text.toLowerCase()) ||
                    document.includes(text.toLowerCase()) : fullName.includes(text.toLowerCase()) ||
                    fullNameNormalized.includes(text.toLowerCase());
            });
        }
        return { 
            ...state,
            orderListFiltered: filterOrderListByText()
        };
    }),

    on(selectedBono, (state, { selectedBono }) => ({ 
            ...state, 
            configuration_form_values: {
                ...state.configuration_form_values,
                customer_info: {
                    ...state.configuration_form_values.customer_info,
                    available_bonos_info: state.configuration_form_values.customer_info.available_bonos_info.map(bono => {return {...bono, selectedBono: selectedBono === null ? false : bono.bono_id === selectedBono.bono_id}})
                }
            }
        })
    ),

    on(resetOrderList, (state) => (
        { ...state, orderList: additionalBonosInitialState.orderList, orderListFiltered: []})
    ),
    
    // Generic
    on(setTotal, (state, { total }) => (
        { ...state, shopping_cart_total: total })
    ),

    on(showShoppingCart, (state, { show }) => (
        { ...state, show_shopping_cart: show })
    ),

    on(setMasterAll, (state, { master }) => (
        { ...state, master: master })
    ),

    on(resetState, (state) => (
        state = additionalBonosInitialState)
    ),
);

function getBlockedMsg(customerInfo: MsisdnInfo, bono: OrangeBonos) : string {
    if (customerInfo?.blocked_bonos && customerInfo?.blocked_bonos.length) {
        const foundBloquedBono = customerInfo.blocked_bonos.find(blockedBono => +blockedBono.bono_id === +bono.bono_id);
        return foundBloquedBono ? foundBloquedBono.msg : '';
    }
    return '';
}

export function orangeAdditionalBonosReducer(state, action) {
    return orangeReducer(state, action);
}