<wc-ocs-theme class="modal-kyc">
    <wc-ocs-doiposupload
        process-type="ESIM"
        [showmodal] = "false"
        docid="{{docid}}"
        typedocid="{{typeDocidKyC}}"
        (outputdata-emitter)="receiveOutput($event.detail)"
        double-check="0"
        sfid="{{idPdV}}"
        brand="{{brand}}"
        systemid="{{systemId}}"
    >
    </wc-ocs-doiposupload>
</wc-ocs-theme>