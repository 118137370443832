import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class EContratoService {
    private originalOpen;
    private originalSend;
    constructor(private authService: AuthService) {}

    overrideXMLHttpRequest() {
        const urlAllowed = [
            '/eContratoKyCWS',
            '/eContratoKyCWeb',
            '/econtratokyc',
        ];
        let currentUrl = '';
        let token = this.authService.getToken();
        let open = XMLHttpRequest.prototype.open;
        this.originalOpen = XMLHttpRequest.prototype.open;
        window.XMLHttpRequest.prototype.open = function (method, url, ...rest) {
            if (urlAllowed.some((allowed) => url.includes(allowed))) {
                currentUrl = url;
            }
            open.call(this, method, url, ...rest);
        };

        let send = XMLHttpRequest.prototype.send;
        this.originalSend = XMLHttpRequest.prototype.send;
        XMLHttpRequest.prototype.send = function () {
            var xhr = this;
            if (currentUrl !== '') {
                currentUrl = '';
                this.setRequestHeader('Authorization', `Bearer ${token}`);                
                // Sobrescribir el método onload para manejar la respuesta
                /*xhr.onload = function () {
                    console.log(xhr);
                    console.log(xhr.status);
                    if (xhr.status >= 200 && xhr.status < 300) {
                    // Solicitud exitosa
                    var responseData = xhr.responseText; // Aquí tienes la respuesta
                    console.log('Respuesta:', responseData);

                    // Puedes realizar acciones adicionales con la respuesta aquí
                    } else {
                    // Solicitud fallida
                    console.error('Error en la solicitud:', xhr.status, xhr.statusText);
                    }
                };*/
            }
            send.apply(xhr, arguments);
        };
    }

    restoreXMLHttpRequest() {
        if (this.originalOpen != null) {
            XMLHttpRequest.prototype.open = this.originalOpen;
        }
        if (this.originalSend != null) {
            XMLHttpRequest.prototype.send = this.originalSend;
        }
    }
}
