import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RmSubheaderComponent} from './components/rm-subheader/rm-subheader.component';
import {RmListadoActivacionesComponent} from './components/rm-listado-activaciones/rm-listado-activaciones.component';
import {RmOrderDetailComponent} from './components/rm-order-detail/rm-order-detail.component';
import {SharedModule} from '../shared/shared.module';
import {RepublicaMovilRouting} from './replublica-movil.routing';
import {MatSnackBarModule} from '@angular/material/snack-bar';


@NgModule({
    declarations: [
        RmSubheaderComponent,
        RmListadoActivacionesComponent,
        RmOrderDetailComponent
    ],
    exports: [
        RmSubheaderComponent,
        MatSnackBarModule
    ],
    imports: [
        CommonModule,
        RepublicaMovilRouting,
        SharedModule,
    ]
})
export class RepublicaMovilModule {
}
