import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { orangePrepaidTypes } from 'src/app/shared/constantes';
import { OrangePrepaidHiringService } from './orange-prepaid-hiring.service';
import { CreatePrepaidResponse } from '../models/createPrepaid';
import { eSimConstants } from '../models/eSimConstants';

@Injectable({
    providedIn: 'root',
})
export class OrangeOrdersService {
    constructor(private orangePrepaidHiringService: OrangePrepaidHiringService) {}

    public createOrder(
        userData,
        configurationData,
        agreementsData,
        type: string,
        hash: string,
        only_send_email: boolean,
		sfidsOrange,
        orderDraftId: string = null     
    ): Observable<CreatePrepaidResponse> {
        const body: any = this.getBody(userData, configurationData, agreementsData, type, hash, only_send_email, sfidsOrange, false, orderDraftId);

		return type === orangePrepaidTypes.esim ? this.orangePrepaidHiringService.createEsimOrder(body) : this.orangePrepaidHiringService.createPrepaidOrder(body);
    }

	public createPreOrder(
        userData,
        configurationData,
        agreementsData,
        type: string,
        hash: string,
        only_send_email: boolean,
		sfidsOrange,
        orderDraftId: string = null    
    ): Observable<CreatePrepaidResponse> {
        const body: any = this.getBody(userData, configurationData, agreementsData, type, hash, only_send_email, sfidsOrange, false, orderDraftId);

		return type === orangePrepaidTypes.esim ? this.orangePrepaidHiringService.createEsimPreOrder(body) : this.orangePrepaidHiringService.createPrepaidPreOrder(body);
    }

	public createDraftOrderEsim(
        userData,
        configurationData,
        agreementsData,
        type: string,
        hash: string,
        only_send_email: boolean,
		sfidsOrange       
    ): Observable<CreatePrepaidResponse> {
        const body: any = this.getBody(userData, configurationData, agreementsData, type, hash, only_send_email, sfidsOrange, true);

		return type === orangePrepaidTypes.esim ? this.orangePrepaidHiringService.createEsimOrder(body) : this.orangePrepaidHiringService.createPrepaidOrder(body);
    }

	private getBody( userData, configurationData, agreementsData, type: string, hash: string, only_send_email: boolean, sfidsOrange, isDraft: boolean = false, orderDraftId: string = null) {
		const body: any =  {
            contract_type: this.getContractType(type),
            first_name: userData.name,
            last_name: (userData.first_surname + ' ' + userData.second_surname),
            document_type_id: userData.document_type.document_type_id,
            document_number: userData.document_number,
            rate_id: +configurationData.rate.rate_id,
            welcome_pack_id: +configurationData.welcome_pack.welcome_pack_id,
            contracted_price: +configurationData.rate.price,
            msisdns: configurationData.msisdns.filter((msisdn) => msisdn !== ''),
            amount_recharge: +configurationData.balance,
            activation_sfid: sfidsOrange,
            email: userData.email,
            phone_number: '', // Elimnar casi 100%
            birth_date: userData.birth_date && userData.birth_date !== '' ? userData.birth_date.format('YYYY-MM-DD') : null,
            gender_id: userData.sex.gender_id,
            nationality_id: +userData.nacionality.country_id,
            cp: +userData.postal_code,
            province_id: +userData.province.province_id,
            hash: hash,
            only_send_email: only_send_email,
            agreements: {
                policy_accept: agreementsData.check1,
                third_parties_commercial_purposes: agreementsData.check2,
                transfer_data_commercial_purposes: agreementsData.check3,
                data_treatment_commercial_purposes: agreementsData.check4,
                commercial_communication: agreementsData.check5,
            },
			draft: isDraft
        };

		if (type === orangePrepaidTypes.esim) {

            if (configurationData.type_operation && configurationData.type_operation === eSimConstants.portabilityTypeOperation) {
                body.portability_operator = configurationData.portability_operator;
                body.portability_type_client = configurationData.portability_type_client;
                body.portability_old_iccid = configurationData.portability_old_iccid;
            } else {
                body.esim_number_lines = configurationData.esim_number_lines;
            }
            if (isDraft === false) { 
                body.order_id = orderDraftId;
            }
        } else {

            if (type === orangePrepaidTypes.terminal) {
                body.terminal_imei = configurationData.terminal_imei;
            }

            if (type === orangePrepaidTypes.portabilidad) {
                body.portability_operator = configurationData.portability_operator;
                body.portability_type_client = configurationData.portability_type_client;
                body.portability_old_iccid = configurationData.portability_old_iccid;
                body.portability_new_iccid = configurationData.portability_new_iccid;
            }
        }

		return body;
	}
    private getContractType(type) {
        switch (type) {
            case orangePrepaidTypes.esim:
            case orangePrepaidTypes.terminal:
                return type;
            case orangePrepaidTypes.portabilidad:
                return 'portability';
            case orangePrepaidTypes.alta:
                return 'alta';
            default:
                return 'alta';
        }
    }
}
