<ng-container>
    <div class="stepline"></div>
    <mat-horizontal-stepper [@.disabled]="true" labelPosition="bottom" #stepper="matHorizontalStepper" 
        class="row" [linear]="true" [disableRipple]="true">
        <ng-template matStepperIcon="edit" let-index="index">{{index + 1}}</ng-template>
        <mat-step [editable]="isEditable" [stepControl]="configurationFormGroup" label="Configuración" state="number">
            <app-orange-bonos-hiring-msisdn [formGroup]="configurationFormGroup" *ngIf="!configurationFormGroup.get('customer_info').value"></app-orange-bonos-hiring-msisdn>
            <app-orange-bonos-hiring-configuration [formGroup]="configurationFormGroup" [stepper]="stepper" [pdv]="pdv" *ngIf="configurationFormGroup.get('customer_info').value"></app-orange-bonos-hiring-configuration>
        </mat-step>

        <mat-step label="Confirmación" state="number">
            <app-orange-bonos-hiring-confirmation [formGroup]="configurationFormGroup" [pdv]="pdv" (isEditable)="stateEditable($event)"></app-orange-bonos-hiring-confirmation>
        </mat-step>
    </mat-horizontal-stepper>
</ng-container>
