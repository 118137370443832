<div class="content">
    <div class="row">
        <div class="col-lg-9">

        </div>
        <div class="col-lg-3">
            <div class="resumen d-flex justify-content-between align-items-center">
                <div >
                    <mat-form-field>
                        <mat-label>
                            <img src="./assets/img/search2.svg" class="img-fluid icon-search" width="12"> Buscar por nombre o DNI
                        </mat-label>
                        <input matInput id="search-client" type="text" oninvalid="" (input)="searchClient($event.target.value)">
                        <i class="bar"></i>
                    </mat-form-field>
                </div>
                <!-- Filter -->
                <small class="text-muted">
                    <a href="#" data-bs-toggle="collapse" data-bs-target="#filtros" [attr.aria-expanded]="isCollapsed" aria-controls="filtros" title="Filtros avanzados">
                        <img src="./assets/img/filter.svg" class="img-fluid icon-filter" width="23">
                    </a>
                </small>
            </div>
        </div>
    </div>
</div>

<div (window:scroll)="doSomethingOnWindowScroll($event)">
    <div class="row">
        <div class="col-lg-12">
            <div id="filtros" class="filters-collapse collapse" [ngbCollapse]="isCollapsed">

                <form [formGroup]="formGroup">
                    <div class="row">
                        <div class="col-lg-9">
                            <mat-form-field>
                                <mat-label>Nombre y/o apellidos</mat-label>
                                <input matInput formControlName="full_name">
                            </mat-form-field>
                        </div>

                        <div class="col-lg-3">
                            <mat-form-field>
                                <mat-label>NIF/NIE/Pasaporte/CIF</mat-label>
                                <input matInput formControlName="document">
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4">
                            <mat-form-field>
                                <mat-label>Nº teléfono móvil</mat-label>
                                <input matInput formControlName="msisdn_movil" maxlength="9">
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4">
                            <app-datepicker-custom
                                title="Fecha de solicitud desde"
                                [form]="formGroup"
                                name="min_date"
                                [maxDate]="maxDate"
                                (onChangeDate)="setMinDate($event)"
                                ></app-datepicker-custom>
                        </div>

                        <div class="col-lg-4">
                            <app-datepicker-custom
                                title="Fecha de solicitud hasta"
                                [form]="formGroup"
                                name="max_date"
                                [minDate]="minDate"
                                (onChangeDate)="setMaxDate($event)"
                                ></app-datepicker-custom>
                        </div>

                        <div class="col-lg-4">
                            <mat-form-field>
                                <mat-label>Estado</mat-label>
                                <select matNativeControl formControlName="status">
                                    <option value="">Todas</option>
                                    <option *ngFor="let status of (orange$ | async)?.master?.super_statuses" [ngValue]="status.value">
                                        {{ superStatusTranslate[status.super_status_id] }}
                                    </option>
                                </select>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-4">
                            <mat-form-field>
                                <mat-label>Ordenar por fecha</mat-label>
                                <select matNativeControl formControlName="sort_by_date">
                                    <option *ngFor="let type of listSortByDate" [value]="type.id">
                                        {{ type.label }}
                                    </option>
                                </select>
                            </mat-form-field>
                        </div>
                    </div>

                    <button class="btn btn-black-outline mb-1 btn-sm" type="button" (click)="advanceSearch()">
                        Aplicar filtro
                    </button>
                </form>

                <br>
            </div>
        </div>
    </div>
</div>

<div class="content" *ngIf="(orange$ | async)?.orderListFiltered?.length">
    <div class="row">
        <div class="col-lg-12">
            <!--- \\\\\\\Cliente -->
            <div class="card gedf-card mb-3" *ngFor="let order of (orange$ | async)?.orderListFiltered; let i = index">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <ul class="nav nav-pills card-header-pills topnav" id="customer-tab-{{ i }}" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link text-list active" data-bs-toggle="tab" href="#cliente-{{ i }}" role="tab" aria-controls="cliente" aria-selected="true">
                                        Cliente
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-auto d-flex align-items-center">
                            <div *ngIf="order.created_at">
                                <!-- Fecha de solicitud -->
                                <span class="info-secundaria dir-text2 d-inline-block">
                                    Fecha de solicitud: {{ order.created_at | date: 'dd/MM/yyyy, HH:mm' }} <br>
                                </span>
                                <!-- Usuario -->
                                <span class="badge badge-light badge-shape" *ngIf="order.user">
                                    {{ order.user?.username}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body bg-white">
                    <div class="tab-content" id="customer-tab-content-{{ i }}">
                        <div class="tab-pane fade show active" id="cliente-{{ i }}" role="tabpanel">
                            <div class=" mb-0">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <!-- Nombre cliente -->
                                        <h5 class="title-list mb-0">
                                            <span class="text-list" *ngIf="order.customer_full_name">
                                                <b>{{ order.customer_full_name }}</b>
                                                <b *ngIf="order.document_type && order.document_number">
                                                    - {{ order?.document_type }}: {{ order.document_number }}
                                                </b>
                                                <br>
                                            </span>
                                            <!-- MSISDN -->
                                            <span class="font-list" *ngIf="order.msisdn && order.msisdn !== ''">
                                                <br>
                                                <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                                Nº de Orange: <b>{{ order.msisdn }}</b>
                                            </span>
                                            <!-- Bono contratado -->
                                            <span class="font-list" *ngIf="order.bono && order.bono !== ''">
                                                <br>
                                                <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                                Bono adicional: <b>{{ order.bono }} ({{ (+order.contracted_price % 1 !== 0 ? (+order.contracted_price).toFixed(2) : +order.contracted_price) + ' €' }})</b>
                                            </span>
                                        </h5>
                                    </div>
                                    <div  class="col-lg-4 d-flex justify-content-end">
                                        <span class="font-list text-list">
                                            <span class="{{ convertStatusClass(order.super_status) }}"></span>
                                            Estado: <b>{{ order.status }}</b>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="btn-toolbar justify-content-end mb-3">
                            <div class="btn-group">
                                <a [routerLink]="['/orange/bonos-adicionales-order/', order.order_bono_id]" class="btn btn-simyo-o2 btn-sm">
                                    Ver más detalles
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loaded" class="mt-2 mb-5">
    <h5  class="tar-name-preload text-center">
        Obteniendo ordenes...
    </h5>
    <div class="d-flex justify-content-center">
        <img class="" src="./assets/img/spinner-simyo.svg" width="30" alt="Cargando...">
    </div>
    <br>
</div>

<div *ngIf="errorSearch" class="mt-5">
    <h5  class="tar-name-preload text-center">
        Se ha producido un error, inténtalo de nuevo
    </h5>
</div>

<div *ngIf="loaded && !(orange$ | async)?.orderListFiltered?.length && !errorSearch" class="mt-5">
    <h5  class="tar-name-preload text-center">
        No se han encontrado ordenes.
    </h5>
</div>

<div class="mt-4 mb-4 d-flex justify-content-center" *ngIf="body.page < lastPage && loaded && !errorSearch">
    <button class="btn btn-black-outline d-block w-100 mb-1" (click)="loadMore()">
        Cargar más
    </button>
    <br>
</div>

