import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SimyoNormalizadorService {
    private datosNormalizacion: any;
    private localidades: any;
    private direccionesSugeridas: any;
    private resultadosNormalizacion: any;
    private formularioNormalizacion: any;
    private resultadosCobertura: any;
    private msgErroresPeticionNormaliza: any;
    private msgErroresPeticionCobertura: any;
    private thereIsCoverage: any;
    private clientIsCover: any;
    // private thereIsCustomer: any;
    private muestraCobertura: BehaviorSubject<boolean>;
    private muestraNormalizacion: BehaviorSubject<boolean>;
    private muestraBotonNormalizacion: BehaviorSubject<boolean>;
    private muestraErroresPeticionNormaliza: BehaviorSubject<boolean>;
    private muestraErroresPeticionCobertura: BehaviorSubject<boolean>;
    private enableFormNormalizacion: BehaviorSubject<boolean>;

    constructor(
        protected http: HttpClient,
        private auth: AuthService
    ) {
        this.muestraCobertura = new BehaviorSubject<boolean>(false);
        this.muestraBotonNormalizacion = new BehaviorSubject<boolean>(true);
        this.muestraNormalizacion = new BehaviorSubject<boolean>(true);
        this.muestraErroresPeticionNormaliza = new BehaviorSubject<boolean>(false);
        this.muestraErroresPeticionCobertura = new BehaviorSubject<boolean>(false);

        // Resultados
        this.datosNormalizacion = new BehaviorSubject<any>(null);
        this.localidades = new BehaviorSubject<any>(null);
        this.resultadosNormalizacion = new BehaviorSubject<any>(null);
        this.resultadosCobertura = new BehaviorSubject<any>(null);
        this.formularioNormalizacion = new BehaviorSubject<any>(null);
        this.msgErroresPeticionNormaliza = new BehaviorSubject<any>(null);
        this.msgErroresPeticionCobertura = new BehaviorSubject<any>(null);
        this.thereIsCoverage = new BehaviorSubject<boolean>(false);
        this.clientIsCover = new BehaviorSubject<boolean>(false);
        // this.thereIsCustomer = new BehaviorSubject<boolean>(false);
    }

    getHeaders() {
        const token = 'Bearer ' + this.auth.getToken();

        return new HttpHeaders({
            Authorization: token,
            'Content-Type': 'application/json'
        });
    }

    // Enviamos los datos del formulario de normalización del micro y devolvemos las direcciones sugeridas
    normalizarHorizontal(body) {
        return this.http.post(environment.simyoEndPoint + 'coverage/normalization', body, {headers: this.getHeaders()});
    }

    // Enviamos los datos del formulario de cobertura del micro y devolvemos los tipos de acceso
    consultaCobertura(body) {
        return this.http.post(environment.simyoEndPoint + 'coverage/theoretical', body, {headers: this.getHeaders()});
    }

    // Enviamos los datos del formulario de cobertura al micro para la consulta de ninguna de las anteriores (esta consulta no lleva opciones)
    consultaNingunaAnteriores(body) {
        return this.http.post(environment.simyoEndPoint + 'coverage/estimated', body, {headers: this.getHeaders()});
    }

    // GETTERS
    // Devolvemos el estado de la vista del formulario de cobertura
    getMuestraCobertura() {
        return this.muestraCobertura.asObservable();
    }

    // Visibilidad del botón de envío del formulario de normalización
    getMuestraBotonNormalizacion() {
        return this.muestraBotonNormalizacion.asObservable();
    }

    // Devolvemos el estado del formulario
    getMuestraNormalizacion() {
        return this.muestraNormalizacion.asObservable();
    }

    // Devolvemos los datos obtenidos de la consulta de normalización horizontal
    getDatosNormalizacion() {
        return this.datosNormalizacion.asObservable();
    }

    // Devolvemos los datos del formulario de la normalización horizontal
    getFormularioNormalizacion() {
        return this.formularioNormalizacion.asObservable();
    }

    // Devolvemos las localidades
    getLocalidades() {
        return this.localidades.asObservable();
    }

    // Devolvemos las direcciones sugeridas
    getDireccionesSugeridas() {
        return this.direccionesSugeridas;
    }

    // Devolvemos los datos de la consulta de cobertura
    getResultadoCobertura() {
        return this.resultadosCobertura.asObservable();
    }

    // Devolvemos estado mostrar errores petición o resultado vacios
    getErrorPeticionNormaliza() {
        return this.muestraErroresPeticionNormaliza.asObservable();
    }

    // Devolvemos el mensaje de error
    getMsgErrorNormaliza() {
        return this.msgErroresPeticionNormaliza.asObservable();
    }

    // Devolvemos estado mostrar errores petición o resultado vacios
    getErrorPeticionCobertura() {
        return this.muestraErroresPeticionCobertura.asObservable();
    }

    // Devolvemos el mensaje de error
    getMsgErrorCobertura() {
        return this.msgErroresPeticionCobertura.asObservable();
    }

    // SETTERS
    // Cambiamos el estado de la vista del formulario de cobertura
    setMuestraCobertura(nuevoValor) {
        this.muestraCobertura.next(nuevoValor);
    }

    // Cambiamos la vista del formulario
    setMuestraNormalizacion(nuevoValor) {
        this.muestraNormalizacion.next(nuevoValor);
    }

    // Cambiamos el valor de la visibilidad del botón de normalización
    setMuestraBotonNormalizacion(nuevoValor) {
        this.muestraBotonNormalizacion.next(nuevoValor);
    }

    // Cambiamos el obsevador que contiene los datos de la normalizacion horizontal
    setDatosNormalizacion(data) {
        this.datosNormalizacion.next(data);
    }

    // Establecemos los valores del formulario de la normalización horizontal
    setFormularioNormalizacion(data) {
        this.formularioNormalizacion.next(data);
    }

    // Filtramos los datos de las direcciones sugeridas para obtener solo las localidades
    setLocalidades(data) {
        if (data !== null) {
            let tmpLocalidades = data
                .map((el) => {
                    return el.city ? el.city.toUpperCase() : null;
                }).filter((value, index, self) => {
                    return value != null && self.indexOf(value) === index;
                });
            this.localidades.next(tmpLocalidades);
        }
    }

    // Actualizamos los datos de la cobertura, con la consulta realizada
    setResultadoCobertura(data) {
        this.resultadosCobertura.next(data);
    }

    setErrorPeticionNormaliza(valor) {
        this.muestraErroresPeticionNormaliza.next(valor);
    }

    setMsgErrorNormaliza(valor) {
        this.msgErroresPeticionNormaliza.next(valor);
    }

    setErrorPeticionCobertura(valor) {
        this.muestraErroresPeticionCobertura.next(valor);
    }

    setMsgErrorCobertura(valor) {
        this.msgErroresPeticionCobertura.next(valor);
    }

    getThereIsCoverage() {
        return this.thereIsCoverage.asObservable();
    }

    setThereIsCoverage(value) {
        this.thereIsCoverage.next(value);
    }

    getClientIsCover() {
        return this.clientIsCover.asObservable();
    }

    setClientIsCover(value) {
        this.clientIsCover.next(value);
    }

    // Función para resetar los valores a cero
    resetNormalizacion() {
        this.setMuestraCobertura(false);
        this.setMuestraNormalizacion(true);
        this.setMuestraBotonNormalizacion(true);
        this.setFormularioNormalizacion(null);
        this.setLocalidades(null);
        this.setResultadoCobertura(null);
    }


}
