import { Component, Input, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment/moment';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-notification-carousel',
    templateUrl: './notification-carousel.component.html',
    styleUrls: ['./notification-carousel.component.css'],
    providers: [NgbCarouselConfig]
})
export class NotificationCarouselComponent implements OnInit {
    @Input() pdv: Ipdv;

    public today = moment();
    public smFinish = moment('2021-10-31');
    public showNotificationAdditional: boolean = true;
    public showNotificationMayorista: boolean = true;

    constructor(
        public config: NgbCarouselConfig,
        private permSv: PermissionService
    ) {
        config.showNavigationIndicators = false;
        config.pauseOnHover = true;
        config.interval = 10000;
    }

    ngOnInit(): void {
    }

    showSimyoAdditional(): boolean {
        return this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender) && (this.today.isBefore(this.smFinish) || (!environment.production && this.today.isBefore(this.smFinish)));
    }

    showNavigationArrows(): boolean {
        if (this.pdv) {
            const auxArray = [
                this.showSimyoAdditional() && this.showNotificationAdditional,
                this.pdv.alerts && this.pdv.alerts.notificar_cambio_mayorista && this.showNotificationMayorista
            ];
            return auxArray.filter(x => x === true).length > 1;
        }
        return false;
    }

}
