<section class="main-content">
    <div class="col-xl-12">
        <div class="header">
            <a class="back mb-4 cursor-pointer" (click)="goBack()"><i-feather name="arrow-left" class="icon--fe"></i-feather> Volver</a>
            <h5 class="title my-4">Lista de pedidos</h5>
        </div>
        <ng-container *ngIf="(orderList$ | async) && orders.length > 0">
            <div class="orders">
                <div class="order-item" *ngFor="let order of orders; index as i">
                    <div class="image-content">
                        <img class="image" [src]="getImage(order.productImage)" width="70">
                    </div>
                    <div class="data-content">
                        <div>
                            <div class="info">
                                <p class="date">{{convertDate(order.createdAt)}}</p>
                                <p class="type align-left">Tarjeta regalo</p>
                                <button class="more align-right" (click)="viewMoreItem[i] = !viewMoreItem[i]">{{viewMoreItem[i] ? 'Ver menos' : 'Ver detalles' }}</button>
                                <button class="close-detail align-right" hidden><i-feather name="x"></i-feather></button>
                            </div>
                            <div>
                                <p class="title">Tarjeta regalo de {{order.productName}} con {{order.amount}}€</p>
                            </div>
                        </div>
                        <div class="details" [ngClass]="{'viewMoreItem': viewMoreItem[i]}">
                            <div class="items-details">
                                <div class="item-points">
                                    <i-feather name="gift"></i-feather>
                                    <p>Puntos usados: <b>{{order.points}}</b></p>
                                </div>
                                <div class="item-points">
                                    <i-feather name="calendar"></i-feather>
                                    <p>Fecha de caducidad: <b>{{order.expiryDate ? convertDate(order.expiryDate) : 'no caduca'}}</b></p>
                                </div>
                                <div class="item-points">
                                    <i-feather name="user"></i-feather>
                                    <p>Usuario <b>{{order.username}}</b></p>
                                </div>
                                <div class="item-points">
                                    <i-feather name="mail"></i-feather>
                                    <p>Correo electrónico: <b>{{order.email}}</b></p>
                                </div>
                                <div class="item-points orange cursor-pointer" (click)="resendCode(order)" >
                                    <img src="./assets/img/re-mail.svg" width="24" *ngIf="!order.loading || order.loading === false">
                                    <img src="./assets/img/spinner-simyo.svg" width="24" alt="" *ngIf="order.loading === true">
                                    <p>Reenviar código de canjeo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="loadMore" *ngIf="!automaticLoad && (statusOrderList$ | async) === 'initial' && showLoadMore">
            <button class="btn btn-black--outline btn-sm w-100" (click)="nextPage()">Cargar más</button>
        </div>
        <div *ngIf="(statusOrderList$ | async) === 'loading'" class="col-xl-12">
            <div class="my-3 text-center">
                <h5 class="tar-name-preload">Obteniendo pedidos...</h5>
                <div>
                    <img src="./assets/img/spinner-home.svg" width="30" alt="">
                </div>
            </div>
        </div>
        <div *ngIf="(statusOrderList$ | async) === 'initial' && orders.length === 0" class="col-xl-12 orders">
            <div class="my-3 order-item justify-content-center">
                <h5 class="tar-name-preload">No se ha realizado ningún pedido.</h5>
            </div>
        </div>
    </div>
    <div class="mt-5 text-center" *ngIf="(statusOrderList$ | async) === 'error'">
        <h5 class="tar-name-preload">Se ha producido un error, inténtalo de nuevo más tarde</h5>
    </div>
</section>
