import {AbstractControl, ValidatorFn} from '@angular/forms';
import { brands } from 'src/app/shared/constantes';

const maxLengthDocument = {
    dni: 9,
    nie: 10,
    pasaporte: 20,
    cif: 9,
    otro: 20
}
export function dniValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (validDNI(control.value)) {
            return null;
        }

        return {doc: {value: control.value}};
    };
}

export function nieValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (validNIE(control.value)) {
            return null;
        }

        return {doc: {value: control.value}};
    };
}

export function cifValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (validCIF(control.value)) {
            return null;
        }

        return {doc: {value: control.value}};
    };
}

function validDNI(dni) {
    if (dni == null || dni === '') {
        return;
    }
    dni = dni.toUpperCase();
    const dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const letter = dni_letters.charAt(parseInt(dni, 10) % 23);

    return letter === dni.charAt(8) || letter === dni.charAt(9);
}

function validNIE(nie) {
    if (nie == null || nie === '') {
        return;
    }

    nie = nie.toUpperCase();
    let nie_prefix = nie.charAt(0);

    if (nie_prefix !== 'X' && nie_prefix !== 'Y' && nie_prefix !== 'Z') {
        return;
    }

    switch (nie_prefix) {
        case 'X':
            nie_prefix = 0;
            break;
        case 'Y':
            nie_prefix = 1;
            break;
        case 'Z':
            nie_prefix = 2;
            break;
    }

    return validDNI(nie_prefix + nie.substr(1));
}

function validCIF(cif) {
    if (!cif || cif.length !== 9) {
        return false;
    }

    const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    const digits = cif.substr(1, cif.length - 2);
    const letter = cif.substr(0, 1);
    const control = cif.substr(cif.length - 1);
    let sum = 0;
    let i;
    let digit: number;

    if (!letter.match(/[A-Z]/)) {
        return false;
    }

    for (i = 0; i < digits.length; ++i) {
        digit = parseInt(digits[i], 10);

        if (isNaN(digit)) {
            return false;
        }

        if (i % 2 === 0) {
            digit *= 2;

            if (digit > 9) {
                digit = Math.trunc(digit / 10) + Math.trunc(digit % 10);
            }

            sum += digit;
        } else {
            sum += digit;
        }
    }

    sum %= 10;
    if (sum !== 0) {
        digit = 10 - sum;
    } else {
        digit = sum;
    }

    if (letter.match(/[ABEH]/)) {
        return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
        return letters[digit] === control;
    }

    return String(digit) === control || letters[digit] === control;
}

export function getMaxLengthByDoc(brand, value) {
    let maxLengthByDoc;
    if (value && typeof value === 'number') {
        value = value.toString();
    }

    if (brand === brands.simyo) {
        switch (value) {
            case '2':
                return maxLengthByDoc = maxLengthDocument.dni;
            case '1':
                return maxLengthByDoc = maxLengthDocument.nie;
            case '3':
                return maxLengthByDoc = maxLengthDocument.pasaporte;
            case '4':
                return maxLengthByDoc = maxLengthDocument.cif;
        }
    }

    if (brand === brands.rm) {
        switch (value) {
            case '1':
                return maxLengthByDoc = maxLengthDocument.dni;
            case '2':
                return maxLengthByDoc = maxLengthDocument.nie;
            case '3':
                return maxLengthByDoc = maxLengthDocument.pasaporte;
            case '4':
                return maxLengthByDoc = maxLengthDocument.cif;
        }
    }

    if (brand === brands.orange) {
        switch (value) {
            case '1':
                return maxLengthByDoc = maxLengthDocument.cif;
            case '2':
                return maxLengthByDoc = maxLengthDocument.dni;
            case '3':
                return maxLengthByDoc = maxLengthDocument.nie;
            case '4':
                return maxLengthByDoc = maxLengthDocument.pasaporte;
            case '5':
                return maxLengthByDoc = maxLengthDocument.otro;
        }
    }
    return maxLengthByDoc = 9;
}

export function generateLetterDNI(dni) {
    if (dni == null || dni === '') {
        return;
    }
    dni = dni.toUpperCase();
    const dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    return dni_letters.charAt(parseInt(dni, 10) % 23);
}
