import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { SimyoConfirmationService } from '../../services/simyo-confirmation.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SimyoShoppingCartService } from '../../services/simyo-shopping-cart.service';

@Component({
    selector: 'app-simyo-loader',
    templateUrl: './simyo-loader.component.html',
    styleUrls: ['./simyo-loader.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoLoaderComponent implements OnInit, OnDestroy {
    @Output() onPreloadingComplete = new EventEmitter<any>();
    @Input() set startGetStatus(value) {
        if (value) {
            this.initialTime = value;
            this.shoppingCartService.setShowIcon(false);
            this.shoppingCartService.setShow(false);
            this.initOrder();
        }
    }
    @Input() typeOrder: any;

    public finished = false;
    public httpError = false;
    private orderId: any;
    private order: any;
    private initialTime = 0;
    private maxTimeLimit = 60000;
    private timeBetweenRequests = 3000;
    private allSubscription: Subscription[] = [];

    maxPercentage = 80;
    totalDuration = 60;
    percentage = 0;
    timerProgressBar = null;

    constructor(
        private orderService: SimyoOrderService,
        private confirmationService: SimyoConfirmationService,
        private shoppingCartService: SimyoShoppingCartService
    ) { }

    ngOnInit(): void {
        this.allSubscription.push(this.confirmationService.getHttpError()
            .subscribe((data: boolean) => {
                this.httpError = data;
            })
        );
    }

    initOrder() {
        this.timerProgressBar = setInterval(() => this.updateProgressBar(), 100);
        this.allSubscription.push(this.orderService.getOrder()
            .subscribe((data: any) => {
                if (data != null) {
                    this.orderId = data.order_id;
                    if (this.initialTime === 0) {
                        this.initialTime = new Date().getTime();
                    }
                    if (this.orderId) {
                        this.getSummaryData();
                    }
                }
            })
        );
    }

    updateProgressBar(add = 0) {
        const now = new Date().getTime();
        const timeDifference = now - this.initialTime;

        const timeDifferenceSeconds = timeDifference / 1000;
        let _percentage = ((timeDifferenceSeconds / this.totalDuration) * 100) + add;
        if(_percentage > this.maxPercentage) {
            clearInterval(this.timerProgressBar);
            _percentage = this.maxPercentage;
        }

        this.percentage = _percentage;
    }
    getSummaryData() {
        if (this.initialTime === 0) {
            this.initialTime = new Date().getTime();
        }

        this.orderService.getSummaryData(this.orderId)
        .pipe(take(1))
        .subscribe((data: any) => {
            this.order = data.msg[0];
            const now = new Date().getTime();
            const timeDifference = now - this.initialTime;

            if(timeDifference > this.maxTimeLimit) {
                this.getOrder();
                return;
            }

            this.checkStatus();
        });
    }

    getOrder() {
        this.maxPercentage = 100;
        this.totalDuration = 80;
        this.initialTime = new Date().getTime();
        this.timerProgressBar = setInterval(() => this.updateProgressBar(80), 100);
        this.orderService.getOrderFromService(this.orderId)
            .subscribe((data: any) => {
                this.order = data.msg[0];
                this.percentage = 100;
                this.finished = true;
                clearInterval(this.timerProgressBar);
                this.onPreloadingComplete.emit(this.order);
            });
    }

    checkStatus() {
        if (this.order && this.order.exit_progress_bar) {
            console.log('order complete')
            this.finished = true;
            clearInterval(this.timerProgressBar);
            this.getOrder();
        } else if (!this.httpError && !this.finished) {
            console.log('Se volverá a consultar el status en ' + this.timeBetweenRequests + ' milisegundos');
            setTimeout(() => this.getSummaryData(), this.timeBetweenRequests);
        } else if (this.httpError) {
            this.initialTime = 0;
            clearInterval(this.timerProgressBar);
            console.error('Error HTTP');
        } else if (this.finished) {
            this.initialTime = 0;
            clearInterval(this.timerProgressBar);
            console.log('Order complete');
        }
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }
}
