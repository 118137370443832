import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PointsService {

  constructor(
    private http: HttpClient,
    private auth: AuthService) { }

  private getHeaders() {
    const token = 'Bearer ' + this.auth.getToken();
    return new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json'
    });
}
  getPdvPoints() {
    return this.http.get(`${environment.comunEndpoint}pdv/points`, {headers: this.getHeaders()}); 
  }

  getProducts() {
    return this.http.get(`${environment.comunEndpoint}points/product/list`, {headers: this.getHeaders()}); 
  }

  postPointsList(filters) {
    return this.http.post(`${environment.comunEndpoint}points/list`, filters, {headers: this.getHeaders()}); 
  }

  getFilters() {
    return this.http.get(`${environment.comunEndpoint}points/list/filters`, {headers: this.getHeaders()}); 
  }

  postRedeemList(filters) {
    return this.http.post(`${environment.comunEndpoint}points/redeem/list`, filters, {headers: this.getHeaders()}); 
  }

  postRedeemCreate(data) {
    return this.http.post(`${environment.comunEndpoint}pdv/redeem/create`, data, {headers: this.getHeaders()}); 
  }

  resendCode(id) {
    return this.http.post(`${environment.comunEndpoint}pdv/redeem/resend`, {id}, {headers: this.getHeaders()});
  }

  checkIsAcceptedPointsProgram(terms) {
    return terms !== null
    && JSON.parse(terms)?.conditions === true;
  }
}
