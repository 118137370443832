import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimyoService } from '../../services/simyo.service';
import { SimyoOrderService } from '../../services/simyo-order.service';
import { SimyoDraftService } from '../../services/simyo-draft.service';
import { SimyoRateService } from '../../services/simyo-rate.service';
import { documentationTypes, orderType, superStatus, translateSignType, typeCard } from 'src/app/shared/constantes';
import { SimyoGlobals } from '../../classes/simyo-globals';
import { SimyoNormalizadorService } from '../../services/simyo-normalizador.service';
import { UserNotFoundComponent } from 'src/app/shared/components/user-not-found/user-not-found.component';
import { MatDialog } from '@angular/material/dialog';
import { SimyoModalReprocessComponent } from '../simyo-modal-reprocess/simyo-modal-reprocess.component';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { Subscription } from 'rxjs';
import { HomeService, INavigateFromOperation } from 'src/app/home/services/home.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';
import { signatureDocuments } from '../../models/signatureDocuments';
import { documentTypeSimyo, masterAllResponseSimyo, masterAllSimyo, trackTypeSimyo } from '../../models/masterAllSimyo';
import { getUnique } from 'src/app/utils/getUniqueFromArray';
import * as moment from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, take, tap } from 'rxjs/operators';

@Component({
    selector: 'app-simyo-order-detail-beta',
    templateUrl: './simyo-order-detail-beta.component.html',
    styleUrls: ['./simyo-order-detail-beta.component.scss', '../../../../assets/css/simyo-theme.css']
})
export class SimyoOrderDetailBetaComponent implements OnInit, OnDestroy {
    public order = null;
    public rateMaster: any;
    public customerData: any;
    public disabledButton: boolean = false;
    public orderNotFound: boolean = false;
    public superStatus: any = superStatus;
    public allPermissions = allPermissions;
    public loadingCobertura: boolean = false;
    public loaded: boolean = false;
    public dataOperation: any = null;
    public documentationMode: { [key: string]: string } = documentationTypes;
    public dataOrderToSignature: signatureDocuments;
    public translateSignType = translateSignType;


    // Master
    public documentTypes: documentTypeSimyo[];
    public roadTypes: trackTypeSimyo[];

    private allSubscription: Subscription[] = [];

    public signatureFormGroup: FormGroup;

    //Campos nuevos para pasar a los componentes de cliente y resumen
    public typeOrder = '';
    public tramitedDate = '';
    public rateFtth = null;
    constructor(
        private router: Router,
        private modalService: NgbModal,
        private activatedRoute: ActivatedRoute,
        private simyoService: SimyoService,
        private orderService: SimyoOrderService,
        private draftService: SimyoDraftService,
        private rateService: SimyoRateService,
        private normalizadorService: SimyoNormalizadorService,
        private homeService: HomeService,
        public dialog: MatDialog,
        public simyoGlobals: SimyoGlobals,
        public permSv: PermissionService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.getOrderInfo()
        this.allSubscription.push(this.rateService.getRates()
            .subscribe((data: any) => {
                if (data != null) {
                    this.rateMaster = data;
                }
            })
        );

        this.allSubscription.push(this.homeService.getNavigateFromOperation()
            .subscribe((data: any) => {
                this.dataOperation = data;
            })
        );

        this.allSubscription.push(this.simyoService.getMasterAll()
            .subscribe((data: masterAllSimyo) => {
                if (data) {
                    this.roadTypes = data.track_types;
                }
            })
        );

        this.allSubscription.push(this.homeService.getNavigateFromOperation()
            .subscribe((data: INavigateFromOperation) => {
                this.dataOperation = data;
            })
        );

        if (!this.documentTypes || !this.roadTypes) {
            this.simyoService.getMasterAllFromService()
                .subscribe((data: masterAllResponseSimyo) => {
                    if (data && data.msg) {
                        this.simyoService.setMasterAll(data.msg);
                        this.roadTypes = data.msg.track_types;
                        this.documentTypes = data.msg.document_types;
                    }
                });
        }

        this.signatureFormGroup = this.formBuilder.group({
            smsCheck: new FormControl(false),
            smsData: new FormControl(''),
            emailCheck: new FormControl(true),
            emailData: new FormControl(''),
            documentationUploadCheck: new FormControl(false, Validators.requiredTrue),
            documentationUploadType: new FormControl('digital')
        });

    }

    getRatesOfOrderFromService(){
        this.rateService.getRatesFromService(this.order?.tax_type_cp)
        .subscribe((data: any) => {
            this.rateService.setRates(data.msg);
        });
    }

    getOrderInfo(updateOrder?) {
        if (updateOrder) {
            this.loaded = false;
        }
        this.orderService.getOrderFromService(this.activatedRoute.snapshot.paramMap.get('order_id'))
        .pipe(
            tap(data => {
                let order = data.msg[0];
                this.typeOrder = order.type;
                this.tramitedDate = order.tramited_at ?? order.created_at;
                this.rateFtth = order.broadband?.rate;
            })
        )
        .subscribe((data: any) => {
            if (data && data.msg && data.msg.length) {
                this.orderNotFound = false;
                this.order = data.msg[0];
                if (updateOrder) {
                    this.loaded = true;
                }
                this.getRatesOfOrderFromService();
                let allPhonesToContact: any[] = [{phone: this.order.customer.phone_number, type: 'Contacto'}];
                this.order.mobiles.map((mobile, index) => mobile.msisdn !== '' ? allPhonesToContact.push({phone: mobile.msisdn, type: index+1 + 'ª línea'}) : null);

                this.order.mobiles.forEach((mobile, index) => {
                    if(mobile.is_esim == typeCard.sim || mobile.show_qr == false || mobile.super_status !== superStatus.active) return;
                    this.simyoService.queryEsimQR(this.order.customer.id, mobile.new_msisdn).pipe(
                        take(1),
                        map((data: any) => data.msg),
                        tap(data => {
                            if(data) {
                                this.order.mobiles[index].pin = data.PIN;
                                this.order.mobiles[index].qr_code = data.qr_code;
                            }
                        })
                    ).subscribe();
                })
                this.dataOrderToSignature = {
                    orderId: this.order.order_id,
                    email: this.order.customer.email,
                    phones: getUnique(allPhonesToContact, 'phone')
                };

                if (this.order.mobiles && this.order.mobiles.length) {
                    const foundTerminal = this.order.mobiles.find(movil => movil.terminal_oid)
                    if (foundTerminal) {
                        this.simyoService.terminalsAreValid(this.order);
                    }
                }
                if (this.order.customer.id && this.order.customer.id !== '') {
                    this.findCustomer();
                } else {
                    this.loaded = true;
                }
                this.simyoService.setTaxType(this.order.tax_type_cp);
            };
        }, (error) => {
            if (error && error.error && error.error.error && error.error.error.errorCode !== null && error.error.error.errorCode === 462) {
                this.dialog.open(UserNotFoundComponent, {
                    width: '350px',
                    disableClose: true,
                    data: {
                        marca: 'Simyo'
                        }
                });
            } else {
                this.orderNotFound = true;
            }
        });
    }

    async continueDraftOrder() {
        const path = this.draftService.getPath(this.order.type);
        this.simyoService.setTypeOrder(this.order.type);
        const mobileForms = [];
        const forms = {
            fibra: null,
            movilPacks: null,
            cliente: null,
            normalizador: null,
            cobertura: null,
            haveCoverage: true
        };

        if (this.order.type !== orderType.MOBILE && this.order.broadband && this.order.addresses && this.order.addresses.length) {
            this.loadingCobertura = true;
            forms.haveCoverage = await this.simyoGlobals.coverageIsValid(this.order.broadband, this.order.addresses.find(x => x.type === "installation"), this.roadTypes);
            if (forms.haveCoverage) {
                if (this.simyoGlobals.ftthRateIsValid(this.order, this.rateMaster, this.simyoService.getMainLineWithoutSpeed(), this.simyoService.getShowFtthReduced(), this.simyoService.customerHasPackage())) {
                    forms.fibra = {
                        rate: this.draftService.getRate(this.order.broadband.rate, this.order.broadband.additional_info) //TODO no seria mejor que esta tarifa se cogiese del matrix si existe
                    };
                } else {
                    forms.fibra = {
                        rate: null
                    };
                    this.rateService.setErrorRate(true);
                }
            } else {
                forms.fibra = {
                    rate: null
                };
            }
            forms.normalizador = this.draftService.getNormalizador(this.order.addresses.find(x => x.type === "installation"));
            forms.cobertura = this.draftService.getCobertura(this.order.addresses.find(x => x.type === "installation"), this.order.broadband);
        }

        if (this.order.mobiles && this.order.mobiles.length) {
            this.order.mobiles.forEach((item: any, index) => {
                this.setFtthMobilePrice(item.rate.rate_info);
                this.setOldPrice(item.rate.rate_info);
                if (!this.simyoGlobals.mobileRateIsValid(this.order, item, index, this.rateMaster, this.order.type, this.customerData)) {
                    item.rate = null;
                    // item.bonos = [];
                    if (forms.fibra && index === 0) {
                        forms.fibra.rate = null;
                    }
                    if (this.rateService.isAdditional(index, this.customerData, this.order.type, this.order)) {
                        this.rateService.setErrorRateAdditional(true);
                    } else {
                        this.rateService.setErrorRate(true);
                    }
                }
                if (index === 0 && this.order.type === orderType.CONVERGENT && !forms.haveCoverage) {
                    item.rate = null;
                    // item.bonos = [];
                    if (forms.fibra) {
                        forms.fibra.rate = null;
                    }
                }
                if (item.terminal_oid && !item.isValidTerminal) {
                    item.terminal_oid = null;
                }
                mobileForms.push(this.draftService.getMobilePack(item));
            });
            forms.movilPacks = mobileForms;
        }

        forms.cliente = this.draftService.getUserDataFromOrder(this.order);

        if (this.order.customer.id && this.order.customer.id !== '' && this.customerData) {
            this.simyoService.setDataFromCustomer(this.customerData);
            this.simyoService.setCustomerPromotion(this.customerData);
            this.normalizadorService.setClientIsCover(true);
            this.orderService.setCustomerId(this.order);
        } else {
            this.normalizadorService.setClientIsCover(false);
            this.simyoService.setDataFromNotCustomer(this.order);
            forms.cliente.new_bank_account = forms.cliente.bank_account;
        }

        this.orderService.setOrderId(this.order.order_id);
        this.simyoService.setTaxType(this.order.tax_type_cp);
        this.simyoService.setFormsData(forms);
        this.loadingCobertura = false;
        this.router.navigate([path]);
    }

    findCustomer() {
        if (this.order.customer.document && this.order.customer.document !== '' && this.order.customer.document_type_id) {
            const body = {
                document_type_id: this.order.customer.document_type_id,
                document_number: this.order.customer.document,
                birth_date: this.order.customer.birth_date && this.order.customer.birth_date !== '' ? moment(this.order.customer.birth_date).format('YYYY-MM-DD') : undefined,
                zip_code: this.order.tax_type_cp

            }
            this.disabledButton = true;
            this.simyoService.getCustomerInfoFromService(body)
                .subscribe((response : any) => {
                    if (response && response.msg) {
                        this.customerData = response.msg;
                        this.customerData.customer.zip_code = this.order.tax_type_cp;
                        this.customerData.customer.tax_type_cp = this.order.tax_type;
                        this.simyoService.setCustomerPromotion(this.customerData);
                        this.isValidAcctCode();
                        this.orderService.set_existing_client(true);

                    }
                    if (response.error && response.error.errorCode && response.error.errorCode === 's631') {
                        this.orderService.set_existing_client(false);
                    }
                }, (error) => {
                    this.disabledButton = false;
                    this.loaded = true;
                }, () => {
                    this.disabledButton = false;
                    this.loaded = true;
                })
        } else {
            this.loaded = true;
        }
    }

    openModalNewProcess(onlyClient) {
        const modalRef = this.modalService.open(SimyoModalReprocessComponent, {size: 'lg', centered: true})
        modalRef.componentInstance.order = this.order;
        modalRef.componentInstance.rates = this.rateMaster;
        modalRef.componentInstance.customerData = this.customerData;
        modalRef.componentInstance.showOptionClient = onlyClient;
    }

    removeDraft() {
        if (this.permSv.hasPermFromV2(allPermissions.others.users_demo)) {
            this.dialog.open(AlertDialogComponent, {
                data: {message: 'Usuario demostración. No se puede eliminar un borrador'}
            });
        } else {
            if (this.order && this.order.order_id && this.order.order_id !== '') {
                this.simyoService.removeOrder(this.order.order_id)
                    .subscribe((response) => {
                        this.router.navigate(['/simyo/listado']);
                    });
            }
        }
    }

    goBack() {
        this.dataOperation ? this.router.navigate(['/']) : this.router.navigate(['/simyo/listado']);
    }

    moveTo(el) {
        document.getElementById(el).scrollIntoView({behavior: 'smooth'});
    }

    showUpdateOrder(order): boolean {
        if (order) {
            if (order.type === orderType.BROADBAND) {
                return order.super_status !== superStatus.draft && order.broadband && (order.broadband.super_status === superStatus.in_process || (order.broadband.super_status === superStatus.active && !order.broadband.activation_date));
            }
            if (order.type === orderType.CONVERGENT) {
                return order.super_status !== superStatus.draft && (order.broadband && (order.broadband.super_status === superStatus.in_process || (order.broadband.super_status === superStatus.active && !order.broadband.activation_date))) || (order.mobiles && order.mobiles.length && order.mobiles.find(line => (line.status === superStatus.in_process) || (line.status === superStatus.active && !line.activation_date)));
            }
            if (order.type === orderType.MOBILE) {
                return order.super_status !== superStatus.draft && order.mobiles && order.mobiles.length && order.mobiles.find(line => (line.super_status === superStatus.in_process) || (line.super_status === superStatus.active && !line.activation_date));
            }
            return
        }
        return false;
    }

    private isValidAcctCode() {
        if (this.order?.payer?.acct_code && this.customerData?.accounts && this.customerData?.accounts.length) {
            const found = this.customerData.accounts.find((account) => account.acct_code === this.order.payer.acct_code);
            if (!found) {
                this.order.iban = null;
                this.order.payer.acct_code = null;
            }
        }
    }

    public getContractNumber(): string {
        if (this.order.contract_id && this.order.contract_id !== '') {
            return this.order.contract_id.toUpperCase()
        }

        return this.order.is_contingency ? '-' : 'Pendiente de asignar';
    }

    setFtthMobilePrice(rate_info){
        if (!rate_info) {
            return "";
        }

        if (this.order.tax_type === 'igic') {
            rate_info.ftth_100_price = rate_info?.ftth_100_price_igic;
            rate_info.ftth_300_price = rate_info?.ftth_300_price_igic;
            rate_info.ftth_500_price = rate_info?.ftth_500_price_igic;
            rate_info.ftth_600_price = rate_info?.ftth_600_price_igic;
            rate_info.ftth_1_price = rate_info?.ftth_1_price_igic;
        }
        if (this.order.tax_type === 'ipsi_8') {
            rate_info.ftth_100_price = rate_info?.ftth_100_price_ipsi_8;
            rate_info.ftth_300_price = rate_info?.ftth_300_price_ipsi_8;
            rate_info.ftth_500_price = rate_info?.ftth_500_price_ipsi_8;
            rate_info.ftth_600_price = rate_info?.ftth_600_price_ipsi_8;
            rate_info.ftth_1_price = rate_info?.ftth_1_price_ipsi_8;
        }
        if (this.order.tax_type === 'ipsi_10') {
            rate_info.ftth_100_price = rate_info?.ftth_100_price_ipsi_10;
            rate_info.ftth_300_price = rate_info?.ftth_300_price_ipsi_10;
            rate_info.ftth_500_price = rate_info?.ftth_500_price_ipsi_10;
            rate_info.ftth_600_price = rate_info?.ftth_600_price_ipsi_10;
            rate_info.ftth_1_price = rate_info?.ftth_1_price_ipsi_10;

        }
        if (this.order.tax_type === 'iva') {
            rate_info.ftth_100_price = rate_info?.ftth_100_price_iva;
            rate_info.ftth_300_price = rate_info?.ftth_300_price_iva;
            rate_info.ftth_500_price = rate_info?.ftth_500_price_iva;
            rate_info.ftth_600_price = rate_info?.ftth_600_price_iva;
            rate_info.ftth_1_price = rate_info?.ftth_1_price_iva;
        }
    }

    setOldPrice(rate_info){
        if (rate_info) {
            rate_info.old_price = this.getOldPrice(rate_info);
        }
    }

    getOldPrice(rate_info){
        if (!rate_info) {
            return "";
        }
        if (this.order.tax_type === 'igic') {
            return rate_info.old_price_igic;
        }
        if (this.order.tax_type === 'ipsi_8') {
            return rate_info.old_price_ipsi_8;
        }
        if (this.order.tax_type === 'ipsi_10') {
            return rate_info.old_price_ipsi_10;
        }
        if (this.order.tax_type === 'iva') {
            return rate_info.old_price_iva;
        }
        return "";
    }


    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

}
