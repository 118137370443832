import { ActionReducerMap } from '@ngrx/store';
import { ISimyoState, simyoReducer } from 'src/app/simyo/components/redux/simyo.reducer';
import { IOrangeAdditionalBonosState, IOrangePrepaidState } from './orange.state';
import { orangeAdditionalBonosReducer } from './orangeAdditionalBonos.reducer';
import { orangePrepaidReducer } from './orangePrepaid.reducer';

export interface AppState {
    simyo: ISimyoState;
    orangeAdditionalBonos: IOrangeAdditionalBonosState;
    orangePrepaid: IOrangePrepaidState;
}

export const reducers: ActionReducerMap<AppState> = {
    simyo: simyoReducer,
    orangeAdditionalBonos: orangeAdditionalBonosReducer,
    orangePrepaid: orangePrepaidReducer
};
