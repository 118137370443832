export class Payment {
    bill_cycle_type: string; // postpago/prepago
    account_type: string; // sacar de un master
    iban: string;
    document_type_id: string;
    document: string;
    // country: string;
    name: string;
    last_name: string;
    second_last_name: string;
    // company: string;
	acct_code?: string;
}
