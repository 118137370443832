import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {TokenHasExpiredComponent} from '../components/token-has-expired/token-has-expired.component';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    dialogTokenHasExpired: any = null;

    constructor(public dialog: MatDialog) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                // Bad Request: ip address not valid
                if (error && error.error && error.error.error && error.error.error.errorCode === 1004) {
                    if (this.dialogTokenHasExpired == null) {
                        this.dialogTokenHasExpired = this.dialog.open(TokenHasExpiredComponent, {
                            width: '350px',
                            disableClose: true
                        });
                    }

                    return EMPTY;
                }

                return throwError(error);
            })
        );
    }
}
