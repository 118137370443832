import {AbstractControl, ValidatorFn} from '@angular/forms';

export function TaxZone(zipCode: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!zipCode || zipCode == '' || !control.value || control.value.length < 5) {
            return null;
        }
        var zip_code = (control.value + '').slice(0,2);
        var first_zip_code = zipCode.slice(0,2);
        
        if (first_zip_code === zip_code) {
            return null;
        }
        // si el primero es 35 o 38 y el actual es 35 o 38 se mantiene 
        if ((first_zip_code === '35' || first_zip_code === '38') && (zip_code === '35' || zip_code === '38')) {
            return null;
        }
        
        // si alguno pertence a canarias se cambia el tipo de tasas
        if (first_zip_code === '35' || first_zip_code === '38' || zip_code === '35' || zip_code === '38') {
            return {sameTax: true};
        }
        // si alguno pertence a ceuta o melilla se cambia el tipo de tasas
        if (first_zip_code === '51' || first_zip_code === '52' || zip_code === '51' || zip_code === '52') {
            return {sameTax: true};
        }
        // si son distintos pero no pertenecen ni a canarias, ceuta o melilla se mantiene
        return null;
    };
}