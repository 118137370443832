import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SimyoService} from '../../services/simyo.service';
import {DigoService} from '../../../services/digo.service';
import {Subscription} from 'rxjs';
import { allPermissions } from 'src/app/shared/permissions';
import {PermissionService} from 'src/app/shared/services/permission.service';
import {Ipdv} from 'src/app/shared/models/pdvResponse';
import {SimyoTemporaryClosureComponent} from '../simyo-temporary-closure/simyo-temporary-closure.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-simyo-index',
    templateUrl: './simyo-index.component.html',
    styleUrls: ['./simyo-index.component.css', '../../../../assets/css/simyo-theme.css']
})
export class SimyoIndexComponent implements OnInit {
    public pdv: Ipdv;
    public allPermissions = allPermissions;
    private allSubscription: Subscription[] = [];

    constructor(
        private router: Router,
        private digoService: DigoService,
        private simyoService: SimyoService,
        public permSv: PermissionService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: Ipdv) => {
                if (data) {
                    this.pdv = data;
                }
            })
        );

        this.simyoService.setTypeOrder(null);
    }

    navigate(typeOrder: string) {
        if (typeOrder !== 'list') {
            if (this.permSv.hasPermFromV2(this.allPermissions.simyo.nbss_block)) {
                this.modalService.open(SimyoTemporaryClosureComponent, {size: 'lg', centered: true, backdrop: 'static'});
                return;
            }

            this.simyoService.setTypeOrder(typeOrder);
        }

        switch (typeOrder) {
            case 'convergent':
                this.router.navigate(['simyo/convergente']);
                break;
            case 'mobile':
                this.router.navigate(['simyo/solo-movil']);
                break;
            case 'broadband':
                this.router.navigate(['simyo/solo-fibra']);
                break;
            case 'list':
                this.router.navigate(['simyo/listado']);
                break;
        }
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }
}
