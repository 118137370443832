import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SimyoConfirmationService {
    private httpError: any;
    private finished: any;

    constructor() {
        this.httpError = new BehaviorSubject<boolean>(false);
        this.finished = new BehaviorSubject<boolean>(false);
    }

    setHttpError(data) {
        this.httpError.next(data);
    }

    getHttpError() {
        return this.httpError.asObservable();
    }

    getFinished() {
        return this.finished.asObservable();
    }

    setFinished(data) {
        this.finished.next(data);
    }
}
