import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeRate } from '../../models/home-rate';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DigoService } from '../../../services/digo.service';
import { HmRateService } from '../../services/hm-rate.service';
import { environment } from '../../../../environments/environment';
import { SimyoRateService } from 'src/app/simyo/services/simyo-rate.service';
import { Observable, Subscription } from 'rxjs';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { allPermissions } from 'src/app/shared/permissions';
import { tabHome } from 'src/app/shared/constantes';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { SimyoService } from 'src/app/simyo/services/simyo.service';

@Component({
    selector: 'app-hm-rates',
    templateUrl: './hm-rates.component.html',
    styleUrls: ['./hm-rates.component.css', '../../../../assets/css/home-theme.css'],
    encapsulation: ViewEncapsulation.None
})

export class HmRatesComponent implements OnInit, OnDestroy {

    @Input() initRates: Observable<any>;

    public pdv: Ipdv;
    public filterFormGroup: FormGroup;
    public rates: Array<HomeRate> = [];
    public ratesFiltered: Array<HomeRate> = [];

    public brands = {
        republica_movil: 'rm',
        simyo: 'sm',
        orange: 'or',
    };
    public types = {
        convergent: 'convergent',
        broadband: 'broadband',
        mobile: 'mobile',
        all: 'all',
        adicional: 'add'
    };
    public paymentType = {
        postpago: 'postpaid',
        prepago: 'prepaid'
    }

    public brandsWithPermissions = 0;
    public loadBrands = 0;
    public countTypeRates = 0;
    public showConvergentCheck = false;
    public showMobileCheck = false;
    public showBroadbandCheck = false;
    public showAdditionalCheck = false;
    public showMobileFilters = true;
    public showMobilePrepaid = true;
    public showMobilePostpaid = true;
    public allPermissions = allPermissions;

    private smRates: any = [];
    private orRates: any = [];
    private brandFilters = [this.brands.simyo, this.brands.orange];
    public typeFilters = [this.types.convergent, this.types.broadband, this.types.mobile];
    private paymentTypeFilters = [this.paymentType.postpago, this.paymentType.prepago];

    private changeTabSubscription: Subscription;
    private allSubscription: Subscription[] = [];

    public taxesFormGroup: FormGroup;
    taxes = [{name: 'IVA', value: 'iva'}, {name: 'IGIC', value: 'igic'}, {name: 'IPSI 8%', value: 'ipsi_8'}, {name: 'IPSI 10%', value: 'ipsi_10'}];
    selectedTax: string = 'iva';

    constructor(
        private router: Router,
        private digoService: DigoService,
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private rateService: HmRateService,
        private smRateService: SimyoRateService,
        public permSv: PermissionService,
        private simyoService: SimyoService
    ) {
        this.taxesFormGroup = this.formBuilder.group({
            tax: ['iva']
        });
    }

    ngOnInit(): void {
        this.filterFormGroup = this.formBuilder.group({
            brand_am: [true],
            brand_sm: [true],
            brand_or: [true],
            type_convergent: [true],
            type_broadband: [true],
            type_mobile: [true],
            type_additional: [false],
            payment_type_pre: [true],
            payment_type_post: [true]
        });

        this.allSubscription.push(this.digoService.getPDV()
            .subscribe((data: Ipdv) => {
                if (data && this.pdv == null) {
                    this.pdv = data;
                }
            })
        );

        this.changeTabSubscription = this.initRates
            .subscribe((data) => {
                if (data?.tab?.textLabel === tabHome.TARIFAS) {
                    this.getRates();
                    this.filterFormGroup.get('brand_am').patchValue(true);
                    this.filterFormGroup.get('brand_sm').patchValue(true);
                    this.filterFormGroup.get('brand_or').patchValue(true);
                    this.changeFilterBrand(this.brands.simyo, true);
                    this.changeFilterBrand(this.brands.orange, true);
                }
            });
    }

    ngOnDestroy() {
        this.rateService.setFilterRatesByBrand(null);
        this.rateService.setFilterRatesByType(null);
        this.changeTabSubscription.unsubscribe();

        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    getRates(tax?) {

        if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender)) {
            this.brandsWithPermissions += 1;
            this.smRateService.getRatesFromService(tax)
                .subscribe((data: any) => {
                    this.smRates = data.msg;
                    console.log(this.smRates);
                    this.setSmRates();
                    this.loadBrands += 1;
                    if (!tax) {
                        this.setInitialFilter();
                    }
                }, error => {
                    console.error(error);
                    this.loadBrands += 1;
                });
        }

        if (this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)) {
            this.brandsWithPermissions += 1;
            this.digoService.getOrangeRates(this.pdv.info.idpdv)
                .subscribe((data: any) => {
                    this.orRates = data;
                    this.setOrRates();
                    this.loadBrands += 1;
                    this.setInitialFilter();
                }, error => {
                    console.error(error);
                    this.loadBrands += 1;
                });
        }
    }

    setInitialFilter() {
        if (this.loadBrands === this.brandsWithPermissions) {
            this.setFilterRatesByBrandObservable();
        }
    }

    setFilterRatesByBrandObservable() {
        this.allSubscription.push(this.rateService.getFilterRatesByBrand()
            .subscribe((data: any) => {
                if (data != null) {
                    this.filterFormGroup.patchValue({
                        brand_sm: data === this.brands.simyo,
                        brand_or: data === this.brands.orange,
                        type_convergent: true,
                        type_broadband: true,
                        type_mobile: true,
                        type_additional: false,
                        payment_type_pre: true,
                        payment_type_post: true
                    });

                    this.brandFilters = [data];
                    this.typeFilters = [this.types.convergent, this.types.broadband, this.types.mobile];
                    this.paymentTypeFilters = [this.paymentType.postpago, this.paymentType.prepago];


                    this.filter();
                    this.setShowChecks();
                    this.setFilterRatesByTypeObservable();
                }
            })
        );
    }

    setFilterRatesByTypeObservable() {
        this.allSubscription.push(this.rateService.getFilterRatesByType()
            .subscribe((data: any) => {
                if (data != null) {
                    this.filterFormGroup.patchValue({
                        type_convergent: data === this.types.convergent,
                        type_broadband: data === this.types.broadband,
                        type_mobile: data === this.types.mobile,
                        type_additional: data === this.types.adicional,
                        payment_type_pre: data === this.types.mobile,
                        payment_type_post: data === this.types.mobile
                    });

                    this.typeFilters = [data];

                    if (data === this.types.mobile) {
                        this.paymentTypeFilters = [this.paymentType.postpago, this.paymentType.prepago];
                    } else {
                        this.paymentTypeFilters = [];
                    }

                    console.log('filterRatesByTypeObservable', this.filterFormGroup.getRawValue());
                    console.log('filterRatesByTypeObservable', this.brandFilters, this.typeFilters, this.paymentTypeFilters);

                    this.filter();
                    // this.setShowChecks();
                }
            })
        );
    }

    setSmRates() {
        if(!this.pdv?.info?.isCSQ) {
            this.smRates.READY_TO_GO.forEach((rate: any, index: number) => {
                if (rate?.rate_ids?.prepaid !== '') {
                    this.addIfNotExistsRate({
                        id: rate.rate_ids.prepaid,
                        name: rate.name,
                        brand: this.brands.simyo,
                        payment_type: this.paymentType.prepago,
                        price: parseFloat(rate.rate_info.real_price),
                        type: this.types.mobile,
                        data: rate.data,
                        voice: rate.voice,
                        _original: rate,
                    });
                }

                if (rate?.rate_ids?.postpaid !== '') {
                    this.addIfNotExistsRate({
                        id: rate.rate_ids.postpaid,
                        name: rate.name,
                        brand: this.brands.simyo,
                        payment_type: this.paymentType.postpago,
                        price: parseFloat(rate.rate_info.real_price),
                        type: this.types.mobile,
                        data: rate.data,
                        voice: rate.voice,
                        _original: rate,
                    });
                }
            });
        } else {
            this.smRates.RATES.MOBILE.forEach((rate: any, index: number) => {
                if (rate?.rate_ids?.prepaid !== '') {
                    this.addIfNotExistsRate({
                        id: rate.rate_ids.prepaid,
                        name: rate.name,
                        brand: this.brands.simyo,
                        payment_type: this.paymentType.prepago,
                        price: parseFloat(rate.rate_info.real_price),
                        type: this.types.mobile,
                        data: rate.data,
                        voice: rate.voice,
                        _original: rate,
                    });
                }

                if (rate?.rate_ids?.postpaid !== '') {
                    this.addIfNotExistsRate({
                        id: rate.rate_ids.postpaid,
                        name: rate.name,
                        brand: this.brands.simyo,
                        payment_type: this.paymentType.postpago,
                        price: parseFloat(rate.rate_info.real_price),
                        type: this.types.mobile,
                        data: rate.data,
                        voice: rate.voice,
                        _original: rate,
                    });
                }
            });
        }


        this.smRates.RATES.FTTH_NEW.forEach((rate: any, index: number) => {
            if (rate.rate_info && !rate.rate_info.already_client) {
                this.addIfNotExistsRate({
                    id: rate.rate_id,
                    name: 'Fibra ' + rate.name,
                    brand: this.brands.simyo,
                    payment_type: this.paymentType.postpago,
                    price: parseFloat(rate.rate_info.real_price),
                    type: this.types.broadband,
                    data: 'Fibra ' + rate.name,
                    voice: null,
                    _original: rate,
                });
            }
        });

        if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_configurador_tarifas) && this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband) && this.permSv.hasPermFromV2(allPermissions.others.access_night)) {
            this.addIfNotExistsRate({
                id: null,
                name: 'Accede y crea una tarifa a medida',
                brand: this.brands.simyo,
                payment_type: this.paymentType.postpago,
                price: -1,
                type: this.types.all,
                data: null,
                voice: null,
                _original: null,
            });
        }

        this.smRates.RATES.ADDITIONALS.forEach((rate: any, index: number) => {
            if (rate.is_additional || rate.show_additional_for_stand) {
                this.addIfNotExistsRate({
                    id: rate.rate_ids.postpaid,
                    name: rate.name,
                    brand: this.brands.simyo,
                    payment_type: this.paymentType.postpago,
                    price: parseFloat(rate.rate_info.real_price),
                    type: this.types.adicional,
                    data: rate.data,
                    voice: rate.voice,
                    _original: rate,
                    old_price: parseFloat(rate.rate_info.old_price)
                });
            }
        });

        this.filter();
        this.setShowChecks();
    }

    setOrRates() {
        this.orRates.movil.prepago.forEach((rate: any) => {
            this.addIfNotExistsRate({
                id: rate.id,
                name: rate.tarifa,
                price: !isNaN(parseFloat(rate.cuota.replace(',', '.'))) ? parseFloat(rate.cuota) : 0,
                brand: this.brands.orange,
                type: this.types.mobile,
                payment_type: this.paymentType.prepago,
                voice: rate.voz,
                data: rate.datos,
                _original: rate
            });
        });

        this.filter();
        this.setShowChecks();
    }

    getRmPrice(priceOne: number, priceTwo?: any, discount?: number): number {
        let price = priceOne;

        if (priceTwo != null) {
            price += priceTwo;

            if (discount != null) {
                price -= discount;
            }
        }

        price = price * 1.21;

        return price;
    }

    getRmPostpaidData(rate: any): string {
        let data = rate.labelData;

        return data;
    }

    hire(brand: string, type: string, rate?: HomeRate) {
        let path: string;

        if (brand === this.brands.simyo) {
            if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_viejo_frontal)) {
                if (rate.payment_type === this.paymentType.postpago) {
                    window.open(environment.digoEndPoint + 'smy_activa.itx', '_self');
                } else {
                    window.open(environment.digoEndPoint + 'smy_activa.itx?mode=prepaid', '_self');
                }
            } else if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_nuevo_frontal)) {
                path = 'simyo';

                if (type === this.types.all) {
                    path += '/tarifas';
                } else if (type === this.types.convergent) {
                    path += '/convergente';
                } else if (type === this.types.broadband) {
                    rate._original.type_pay = rate.payment_type;
                    this.simyoService.setRateFtth(rate._original);
                    path += '/solo-fibra';
                } else {
                    rate._original.type_pay = rate.payment_type;
                    this.simyoService.setRatesMobile(0, {...rate._original}, );
                    path += '/solo-movil';
                }
            }
        } else if (brand === this.brands.orange) {
            path = '/orange/prepago/alta';
        }

        if (path != null) {
            this.router.navigate([path]);
        }
    }

    details(rate: any) {
       if (rate.brand === this.brands.orange) {
            this.orMoreInfo(rate._original.info_url);
        }
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    orMoreInfo(url) {
        window.open(url, '_blank');
    }

    filter() {
        this.ratesFiltered = this.rates.filter((rate: HomeRate) => {
            return this.brandFilters.includes(rate.brand) &&
                this.typeFilters.length &&
                (this.typeFilters.includes(rate.type) || (rate.type === this.types.all && !this.typeFilters.find(x => x === this.types.adicional))) &&
                (rate.type !== this.types.mobile || this.paymentTypeFilters.includes(rate.payment_type));
        });

        this.ratesFiltered.sort((a: any, b: any) => (a.price > b.price) ? 1 : -1);
    }

    setShowChecks() {
        this.showConvergentCheck = this.rates.filter(item => (item.type === this.types.convergent || item.type === this.types.all) && this.brandFilters.includes(item.brand)).length > 0;
        this.showMobileCheck = this.rates.filter(item => (item.type === this.types.mobile || item.type === this.types.all) && this.brandFilters.includes(item.brand)).length > 0;
        this.showBroadbandCheck = this.rates.filter(item => (item.type === this.types.broadband || item.type === this.types.all) && this.brandFilters.includes(item.brand)).length > 0;
        this.showAdditionalCheck = this.rates.filter(item => (item.type === this.types.adicional || item.type === this.types.all) && this.brandFilters.includes(item.brand)).length > 0;

        this.showMobileFilters = this.showMobileCheck;
        this.showMobilePrepaid = this.rates.filter(item => item.type === this.types.mobile && this.brandFilters.includes(item.brand) && item.payment_type === this.paymentType.prepago).length > 0;
        this.showMobilePostpaid = this.rates.filter(item => item.type === this.types.mobile && this.brandFilters.includes(item.brand) && item.payment_type === this.paymentType.postpago).length > 0;

        this.countTypeRates = 0;
        this.countTypeRates += this.showConvergentCheck ? 1 : 0;
        this.countTypeRates += this.showMobilePrepaid ? 1 : 0;
        this.countTypeRates += this.showMobilePostpaid ? 1 : 0;
        this.countTypeRates += this.showBroadbandCheck ? 1 : 0;
        this.countTypeRates += this.showAdditionalCheck ? 1 : 0;

        if (this.countTypeRates <= 1) {
            if (!this.typeFilters.includes(this.types.convergent)) {
                this.filterFormGroup.get('type_convergent').patchValue(true);
                this.typeFilters.push(this.types.convergent);
            }

            if (!this.typeFilters.includes(this.types.mobile)) {
                this.filterFormGroup.get('type_mobile').patchValue(true);
                this.typeFilters.push(this.types.mobile);
            }

            if (this.typeFilters.includes(this.types.mobile)) {
                if (this.rates.filter(item => this.brandFilters.includes(item.brand) && item.payment_type === this.paymentType.prepago).length > 0) {
                    this.filterFormGroup.get('payment_type_pre').patchValue(true);
                    this.paymentTypeFilters.push(this.paymentType.prepago);
                }

                if (this.rates.filter(item => this.brandFilters.includes(item.brand) && item.payment_type === this.paymentType.postpago).length > 0) {
                    this.filterFormGroup.get('payment_type_post').patchValue(true);
                    this.paymentTypeFilters.push(this.paymentType.postpago);
                }
            }

            if (!this.typeFilters.includes(this.types.broadband)) {
                this.filterFormGroup.get('type_broadband').patchValue(true);
                this.typeFilters.push(this.types.broadband);
            }

            if (this.typeFilters.includes(this.types.adicional)) {
                this.typeFilters = this.typeFilters.filter(type => type !== this.types.adicional);
                this.filterFormGroup.get('type_additional').patchValue(false);
            }

            this.filter();
        }
    }

    changeFilterBrand(brand, value) {
        if (this.brandFilters.length >= this.brandsWithPermissions && !value) {
            this.filterFormGroup.get('brand_sm').patchValue(brand === this.brands.simyo);
            this.filterFormGroup.get('brand_or').patchValue(brand === this.brands.orange);
            this.brandFilters = [brand];
        } else if (value) {
            this.brandFilters.push(brand);
        } else {
            this.brandFilters = this.brandFilters.filter(item => (item !== brand));
        }

        if (brand === this.brands.orange && this.filterFormGroup.get('brand_or').value == true && this.selectedTax != 'iva'){
            this.changeTaxType('iva');
            this.taxesFormGroup.get('tax').patchValue('iva');
        }
        this.filter();
        this.setShowChecks();
    }

    changeFilterType(type, value) {
        if (value) {
            this.typeFilters.push(type);

            if (type === this.types.convergent) {
                if (!this.paymentTypeFilters.includes(this.paymentType.postpago)) {
                    this.paymentTypeFilters.push(this.paymentType.postpago);
                }

                this.filterFormGroup.get('payment_type_post').patchValue(true);
            }
            if (type === this.types.mobile) {
                this.paymentTypeFilters = [this.paymentType.postpago, this.paymentType.prepago];
                this.filterFormGroup.get('payment_type_pre').patchValue(true);
                this.filterFormGroup.get('payment_type_post').patchValue(true);
            }
            if (type === this.types.adicional) {
                this.paymentTypeFilters = [this.paymentType.postpago];
                this.typeFilters = [this.types.adicional];
                this.filterFormGroup.get('type_convergent').patchValue(false);
                this.filterFormGroup.get('type_broadband').patchValue(false);
                this.filterFormGroup.get('type_mobile').patchValue(false);
                this.filterFormGroup.get('payment_type_pre').patchValue(false);
                this.filterFormGroup.get('payment_type_post').patchValue(true);
            } else {
                this.typeFilters = this.typeFilters.filter(type => type !== this.types.adicional);
                this.filterFormGroup.get('type_additional').patchValue(false);
            }
        } else {
            this.typeFilters = this.typeFilters.filter(item => (item !== type));

            if (type === this.types.convergent) {
                if (!this.filterFormGroup.get('type_mobile').value) {
                    if (this.paymentTypeFilters.includes(this.paymentType.postpago)) {
                        this.paymentTypeFilters = this.paymentTypeFilters.filter(item => (item !== this.paymentType.postpago));
                    }

                    this.filterFormGroup.get('payment_type_post').patchValue(false);
                }
            }
            if (type === this.types.mobile) {
                if (this.showBroadbandCheck && this.filterFormGroup.get('type_convergent').value) {
                    if (this.paymentTypeFilters.includes(this.paymentType.prepago)) {
                        this.paymentTypeFilters = this.paymentTypeFilters.filter(item => (item !== this.paymentType.prepago));
                    }

                    this.filterFormGroup.get('payment_type_pre').patchValue(false);
                } else {
                    this.paymentTypeFilters = [];
                    this.filterFormGroup.get('payment_type_pre').patchValue(false);
                    this.filterFormGroup.get('payment_type_post').patchValue(false);
                }
            }
            if (type === this.types.adicional && this.paymentTypeFilters.includes(this.paymentType.postpago)) {
                this.paymentTypeFilters = this.paymentTypeFilters.filter(item => (item !== this.paymentType.postpago));
                this.filterFormGroup.get('payment_type_post').patchValue(false);
            }
        }

        this.filter();
    }

    changeFilterTypePayment(paymentType, value) {
        if (value) {
            if (paymentType === this.paymentType.prepago && this.typeFilters.includes(this.types.adicional)) {
                this.typeFilters = this.typeFilters.filter(type => type !== this.types.adicional);
                this.filterFormGroup.get('type_additional').patchValue(false);
            }
            this.paymentTypeFilters.push(paymentType);
        } else {
            if (paymentType === this.paymentType.postpago && this.typeFilters.includes(this.types.adicional)) {
                this.typeFilters = this.typeFilters.filter(type => type !== this.types.adicional);
                this.filterFormGroup.get('type_additional').patchValue(false);
            }
            this.paymentTypeFilters = this.paymentTypeFilters.filter(item => (item !== paymentType));
        }

        if (this.paymentTypeFilters.length > 0) {
            if (!this.typeFilters.includes(this.types.mobile)) {
                this.typeFilters.push(this.types.mobile);
                this.filterFormGroup.get('type_mobile').patchValue(true);
            }
        } else {
            this.typeFilters = this.typeFilters.filter(type => type !== this.types.mobile);
            this.filterFormGroup.get('type_mobile').patchValue(false);
        }

        this.filter();
    }

    replaceName(text) {
        return text && text !== '' ? text.replaceAll('+', ' + ') : text;
    }

    public changeTaxType(taxType): void {
        this.selectedTax = taxType;
        this.rates = [];
        this.getRates(this.zipCodeSelectedTax());
        if (this.selectedTax != 'iva') {
            this.changeFilterBrand(this.brands.simyo, true);
            this.changeFilterBrand(this.brands.orange, false);
            this.filterFormGroup.get('brand_am').patchValue(false);
            this.filterFormGroup.get('brand_sm').patchValue(true);
            this.filterFormGroup.get('brand_or').patchValue(false);
        }
    }

    zipCodeSelectedTax(){
        if (this.selectedTax == 'iva') {
            return null;
        }
        if (this.selectedTax == 'igic') {
            return '35000';
        }
        if (this.selectedTax == 'ipsi_8') {
            return '52000';
        }
        if (this.selectedTax == 'ipsi_10') {
            return '51000';
        }
    }

    private addIfNotExistsRate(rate) {
        if(this.rates.find(r => (r.id !== undefined && r.id === rate.id) || (r.id === undefined && r.name === rate.name)) !== undefined) {
            return;
        }
        this.rates.push(rate);
    }

    public showButtonHire(rate): boolean {
        if([this.brands.simyo].includes(rate.brand)) return rate.type !== this.types.all && this.permSv.hasPermFromV2(allPermissions.others.access_night);
        return rate.type !== this.types.all;
    }
}
