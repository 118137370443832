import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HmCoverageService {
    private smCoverage: any;
    private smErrorCoverage: any;

    constructor() {
        this.smCoverage = new BehaviorSubject<boolean>(null);
        this.smErrorCoverage = new BehaviorSubject<boolean>(null);
    }

    setSmCoverage(data) {
        this.smCoverage.next(data);
    }

    getSmCoverage() {
        return this.smCoverage.asObservable();
    }

    setSmErrorCoverage(data) {
        this.smErrorCoverage.next(data);
    }

    getSmErrorCoverage() {
        return this.smErrorCoverage.asObservable();
    }
}
