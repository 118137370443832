import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PDV_MULTIMARCA } from 'src/app/shared/constantes';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';

@Component({
    selector: 'app-hm-support',
    templateUrl: './hm-support.component.html',
    styleUrls: ['./hm-support.component.css', '../../../../assets/css/home-theme.css']
})
export class HmSupportComponent implements OnInit {
    public pdv: any;
    public orangeNumber: string;
    public gpvName: string;
    public allPermissions = allPermissions;
    public PDV_MULTIMARCA = PDV_MULTIMARCA;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public permSv: PermissionService
    ) {
        this.pdv = data.pdv;
    }

    ngOnInit(): void {
        this.orangeNumber = this.pdv.gpv.phone?.replace('+34', '').replace(/(.{3})/g, '$1 ').trim();
        this.gpvName = this.pdv.gpv.name;

        console.log(this.pdv.gpv);
    }

}
