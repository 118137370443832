<div class="modal-header">
    <h5 class="modal-title fw-bold ms-2" id="exampleModalLabel">Tu GPV</h5>
    <button type="button" class="close close-m" mat-dialog-close>
        <img src="./assets/img/x-black.svg" width="18" class="close-aviso--1">
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div class="row mb-2">
            <div class="col-12 col-xl">
                <!-- Ficha del comercial -->
                <div class="gestor-m">
                    <!-- Foto del comercial -->
                    <!--<img src="./assets/img/user.png" alt="Person" width="72" height="72">-->
                    <!-- Nombre del comercial -->
                    <a href="" data-bs-toggle="modal" data-bs-target="#comercial"><b>Tu GPV:</b> {{ pdv.gpv.name }} </a>
                </div>
            </div>
            <div class="col-12 col-xl-auto">
                <!-- Conectado --><!--<span class="dot--connect"></span> <span class="connect-m"> Conectado</span>-->
                <!-- Desconectado --><!--span class="dot--desconnect"></span> <span class="connect-m"> Desconectado</span-->
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="container">
        <div class="row mt-2 mb-0">
            <div class="col-12 col-sm col-xl">
                <button (click)="requestCall()" class="btn btn-black--outline btn-sm mt-1">
                    Solicitar llamada
                </button>
            </div>
            <div class="col-12 col-sm-auto col-xl-auto d-flex align-items-center">
                <!--contacto-->
                <span class="fw-bold">
                    {{ phone }}<br>
                    <!--<a href="mailto:{{ pdv.gpv.email }}"><img src="./assets/img/mail.svg" width="18" class="mail-m"></a>-->
                </span>
            </div>
        </div>
    </div>
</div>
