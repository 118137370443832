import {AbstractControl, ValidatorFn} from '@angular/forms';

export function iccidValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (validateICCID(control.value)) {
            return null;
        }
        return {iccid: {value: control.value}};
    };
}

function validateICCID(value) {
    if (value === null) {
        return;
    }
    value = value.toString();

    return (false !== value.match('/^(89)(1)(48[0-9])(\d{13})(\d)$/') && isValidLuhn(value));
}

function isValidLuhn(num: string) {
    const sumTable = [[0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]];
    let sum = 0;
    let flip = 0;

    for (let i = num.length - 1; i >= 0; i--) {
        sum += sumTable[flip++ & 0x1][num[i]];
    }

    return sum % 10 === 0;
}
