
<div class="custom-select">
    <select class="select"
    [formControl]="control"
    (change)="OnChange.emit($event.target.value)">
        <option *ngFor="let option of listOptionsFormated" [value]="option.value" >{{option.label}}</option>
    </select>
    <label class="label" [for]="controlName" *ngIf="label !== ''"  [class.active]="isEmpty == false">{{label}}{{isRequired ? '*': ''}}</label>
    <div class="underline" [ngClass]="{'selected': isFocused}">
        <div class="underline-selected"></div>
    </div>
    <input-error class="error" [id]="id" [control]="control"></input-error>
</div>
