import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SimyoUserDataService {
    private userData: any;

    constructor() {
        this.userData = new BehaviorSubject<any>(null);
    }

    setUserData(data) {
        this.userData.next(data);
    }

    getUserData() {
        return this.userData.asObservable();
    }
}
