import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MsisdnInfoResponse } from 'src/app/orange/models/msisdnInfoResponse';
import { AppState } from 'src/app/orange/redux';
import { setCustomerInfo } from 'src/app/orange/redux/orange.actions';
import { IOrangeAdditionalBonosState } from 'src/app/orange/redux/orange.state';
import { OrangeBonosHiringService } from 'src/app/orange/services/orange-bonos-hiring.service';

@Component({
    selector: 'app-orange-bonos-hiring-msisdn',
    templateUrl: './orange-bonos-hiring-msisdn.component.html',
    styleUrls: ['./orange-bonos-hiring-msisdn.component.css', '../../../../../assets/css/orange-theme.css']
})
export class OrangeBonosHiringMsisdnComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @ViewChild('inactiveUser') inactiveUser: NgbModalRef;

    public orange$: Observable<IOrangeAdditionalBonosState>;
    public loaded: boolean = true;
    public showErrorCustomer: boolean = false;
    public customerControlledError: string = '';

    constructor(
        private orangeStore: Store<AppState>,
        private modalService: NgbModal,
        private orangeBonosHiringService: OrangeBonosHiringService
    ) { }

    ngOnInit(): void {
        this.orange$ = this.orangeStore.pipe(select('orangeAdditionalBonos'));
    }

    searchCustomerInfo() {
        if (this.formGroup.get('msisdn').valid) {
            this.loaded = false;
            this.showErrorCustomer = false;
            this.customerControlledError = '';
            this.orangeBonosHiringService.getMsisdnInfoFromService(this.formGroup.get('msisdn').value)
                .subscribe((response: MsisdnInfoResponse) => {
                    if (response?.error?.error_code.toString().includes('E')) {
                        this.customerControlledError = response.error.msg + ' ('+ response?.error?.error_code.toString() + ')' || 'No se ha podido realizar la comprobación del cliente. Inténtelo de nuevo más tarde';
                        this.modalService.open(this.inactiveUser, {size: 'lg', centered: true, backdrop: 'static', keyboard : false});
                        return;
                    }
                    if (response.msg) {
                        this.customerControlledError = '';
                        this.formGroup.get('customer_info').patchValue(response.msg);
                        this.orangeStore.dispatch(setCustomerInfo({info: response.msg}));
                        return;
                    }
                    this.showErrorCustomer = true;
                }, (error: HttpErrorResponse) => {
                    this.loaded = true;
                    this.showErrorCustomer = true;
                }, () => {
                    this.loaded = true;
                })
        }
    }
}
