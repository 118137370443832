<div class="container content mb-2">
    <!-- Estado tramitacion -->
    <div class="row mb-2">
        <div class="col-md-12">
            <h5 class="title-step mt-4 text-center" ><img src="./assets/img/file-text2.svg" class="img-fluid ico-title" width="20"> Resumen de la activación</h5>
            <!-- Notificación -->
            <div class="alert" [ngClass]="{'alert-danger': orderInfo?.super_status === superStatus.cancelled || orderInfo?.super_status === superStatus.rejected || orderInfo?.super_status === superStatus.error, 'alert-success': orderInfo?.super_status === superStatus.in_process || orderInfo?.super_status === superStatus.active}" role="alert">
                <h4 class="alert-heading mt-2">
                    <b>{{ orderInfo?.status_msg?.title }}</b>
                </h4>
                <p>{{ orderInfo?.status_msg?.description }}</p>
            </div>
        </div>
    </div>

    <!-- Descripcion rapida -->
    <div class="row mb-2">
        <div class="col-md-12">
            <div class="jumbotron-norm mb-3">
                <div class="row ms-1">
                    <!-- Nº de contrato -->
                    <div class="col-lg-2">
                        <h5  class="tar-name-6 mt-2 mb-1">Nº de contrato</h5>
                        <h5  class="tar-name-7 mt-1">{{ orderInfo?.contract_id && orderInfo?.contract_id !== '' ? orderInfo?.contract_id : '-' }}</h5>
                    </div>
                    <!-- Caducidad -->
                    <div class="col-lg-2">
                        <h5  class="tar-name-6 mt-2 mb-1">Fecha</h5>
                        <h5  class="tar-name-7 mt-1">{{date | date:'dd/MM/yyyy':'UTC+1'}}</h5>
                    </div>
                    <!-- Estado -->
                    <div class="col-lg-2">
                        <h5  class="tar-name-6 mt-2 mb-1">Estado</h5>
                        <h5  class="tar-name-7 mt-1 verde">{{ orderInfo?.status_description }}</h5>
                    </div>
                    <!-- Descripcion -->
                    <div class="col-lg-2" *ngIf="orderInfo && orderInfo.error_description && orderInfo.error_description !== ''">
                        <h5  class="tar-name-6 mt-2 mb-1">Descripción</h5>
                        <h5  class="tar-name-7 mt-1">{{ orderInfo.error_description }}</h5>
                    </div>
                    <!-- Documentación -->
                    <div class="col" *ngIf="orderInfo && orderInfo.doc_status && orderInfo.doc_status !== ''">
                        <h5  class="tar-name-6 mt-2 mb-1">Documentación {{ orderInfo?.sign_type && orderInfo?.sign_type !== 'not_required' ? '(' + translateSignType[orderInfo.sign_type] + ')' : '' }}</h5>
                        <h5  class="tar-name-7 mt-1">{{ orderInfo.doc_status }}{{ orderInfo.doc_annotation && orderInfo.doc_annotation !== '' ? ': ' + orderInfo.doc_annotation : '' }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Cliente -->
    <app-simyo-summary-client
    [customer]="order.customer"
    [typeOrder]="typeOrder"
    [broadband]="order.broadband"
    [mobiles]="order.mobiles"
    [date]="date"
    [rateFtth]="rateFtth"
    [contract_id]="orderInfo?.contract_id"></app-simyo-summary-client>

    <!-- Resumen tramitacion -->
    <app-simyo-summary-processing
    [typeOrder]="typeOrder"
    [broadband]="order.broadband"
    [mobiles]="order.mobiles"
    [rateFtth]="rateFtth"
    [customerData]="customerData"
    [ratesMobiles]="ratesMobiles"
    [super_status]="orderInfo?.super_status"
    [date]="date"></app-simyo-summary-processing>

    <!-- Firma Nueva -->
    <div *ngIf="permSv.hasPermFromV2(allPermissions.simyo.permite_new_signature)">
        <app-simyo-documentation-signature [dataOrderToSignature]="dataOrderToSignature" [documentationMode]="documentationMode.viewDocumentation" [fullOrder]="orderInfo" [signatureFormGroup]="signatureFormGroup"></app-simyo-documentation-signature>
    </div>
    <br>
    <br>
</div>

<!-- MODALES -->

<ng-template #modalPrepago let-modal>
    <div class="modal-header bg-simyo-grey">
        <h5 class="modal-title white" id="exampleModalLabel">Importante</h5>
    </div>
    <div class="p-4">
        <div class="pb-4 d-flex justify-content-center" >
            Antes de recargar <b>{{ numLinePrepago > 1 ? 'las líneas' : 'la línea' }} prepago</b> confirma que está en estado <br><b>“Activada pendiente 1ª llamada”</b> en el listado de operaciones
        </div>
        <div class="col-4 mx-auto">
            <a (click)="modal.dismiss('Cross click')" class="btn btn-simyo-o2 d-block w-100 mb-1" role="button">
                Entendido
            </a>
        </div>
    </div>
</ng-template>
