import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BonosAdicionalesOrderListResponse } from 'src/app/orange/models/bonosAdicionalesInfoResponse';
import { MasterAllResponse } from 'src/app/orange/models/masterAllResponse';
import { filteredOrderList, resetOrderList, resetState, setMasterAll, setOrderList } from 'src/app/orange/redux/orange.actions';
import { IOrangeAdditionalBonosState } from 'src/app/orange/redux/orange.state';
import { OrangeBonosHiringService } from 'src/app/orange/services/orange-bonos-hiring.service';
import { listSortByDate, superStatus, superStatusTranslate } from 'src/app/shared/constantes';
import * as moment from 'moment/moment';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { OrangeService } from 'src/app/orange/services/orange.service';
import { AppState } from 'src/app/orange/redux';

@Component({
    selector: 'app-orange-bonos-hiring-list',
    templateUrl: './orange-bonos-hiring-list.component.html',
    styleUrls: ['./orange-bonos-hiring-list.component.css', '../../../../../assets/css/orange-theme.css']
})
export class OrangeBonosHiringListComponent implements OnInit, OnDestroy {

    public orange$: Observable<IOrangeAdditionalBonosState>;
    public lastPage: number = 0;
    public formGroup: FormGroup;
    public loaded: boolean = false;
    public errorSearch: boolean = false;
    public isCollapsed: boolean = true;
    public minDate: Date;
    public maxDate: Date;
    public faCalendarDay = faCalendarDay;
    public superStatusTranslate = superStatusTranslate;
    public body: any = {
        limit: 50,
        page: 1,
        order: {
            created_at: 'desc'
        }
    };

    public listSortByDate = listSortByDate;
    private automaticLoad: boolean = true;

    constructor(
        private orangeBonosHiringService: OrangeBonosHiringService,
        private orangeStore: Store<AppState>,
        private formBuilder: FormBuilder,
        private orangeMasterService: OrangeService
    ) {
        this.orange$ = orangeStore.pipe(select('orangeAdditionalBonos'));
    }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            full_name: [''],
            document: [''],
            msisdn_movil: [''],
            min_date: [''],
            max_date: [''],
            status: [''],
            sort_by_date: ['desc']
        });

        this.orangeMasterService.getMasterAllFromService(false)
            .subscribe((data: MasterAllResponse) => {
                if (data?.msg) {
                    this.orangeStore.dispatch(setMasterAll({master: data.msg}));
                    this.getOrders();
                }
            });
    }

    public advanceSearch() {
        const formData = this.formGroup.getRawValue();
        const filters: any = {};

        if (formData.full_name !== '') {
            filters.customer_full_name = formData.full_name;
        }
        if (formData.document !== '') {
            filters.customer_document = formData.document;
        }
        if (formData.msisdn_movil !== '') {
            filters.msisdn = formData.msisdn_movil;
        }
        if (formData.status) {
            filters.statuses = formData.status;
        }
        if (formData.sort_by_date) {
            this.body.order.created_at = formData.sort_by_date;
        }
        if (formData.min_date != null && formData.max_date != null && formData.min_date !== '' && formData.max_date !== '') {
            filters.created_at = 'bt|' + moment(formData.min_date).format('YYYY-MM-DD 00:00:00') + '|' + moment(formData.max_date).format('YYYY-MM-DD 23:59:59');
        } else if (formData.min_date != null && formData.min_date !== '') {
            filters.created_at = 'gte|' + moment(formData.min_date).format('YYYY-MM-DD 00:00:00');
        } else if (formData.max_date != null && formData.max_date !== '') {
            filters.created_at = 'lte|' + moment(formData.max_date).format('YYYY-MM-DD 23:59:59');
        }

        this.body.page = 1;
        this.body.filters = filters;
        this.orangeStore.dispatch(resetOrderList());
        this.automaticLoad = true;

        this.getOrders();
    }

    public searchClient(text) {
        this.orangeStore.dispatch(filteredOrderList({text: text}));
    }

    @HostListener('window:scroll', ['$event'])
    public doSomethingOnWindowScroll(event: Event) {
        if (this.automaticLoad) {
            const element: any = event.srcElement;
            const scrollOffset = element.children[0].scrollTop;
            const windowHeight = document.documentElement.scrollHeight - window.innerHeight - 200;

            if (scrollOffset > windowHeight && windowHeight > 0) {
                console.log('Cargando siguiente página. A partir de ahora se carga en manual.');
                this.automaticLoad = false;
                this.nextPage();
            }
        }
    }

    public loadMore() {
        this.nextPage();
    }

    public setMinDate(event: MatDatepickerInputEvent<Date>) {
        this.minDate = event.value;
    }

    public setMaxDate(event: MatDatepickerInputEvent<Date>) {
        this.maxDate = event.value;
    }

    public convertStatusClass(status): string {
        switch (status) {
            case superStatus.active:
                return 'dot-complete';
            case superStatus.cancelled:
            case superStatus.error:
            case superStatus.rejected:
                return 'dot-cancel';
            default:
                return 'dot-pending';
        }
    }

    private getOrders() {
        this.loaded = false;
        this.errorSearch = false;
        this.orangeBonosHiringService.listBonosPrepago(this.body)
            .subscribe((data: BonosAdicionalesOrderListResponse) => {
                this.orangeStore.dispatch(setOrderList({orderList: data.msg}));
                this.lastPage = data.msg.last_page;
            }, (error: HttpErrorResponse) => {
                this.errorSearch = true;
                this.loaded = true;
            }, () => {
                this.loaded = true;
            });
    }

    private nextPage() {
        if (this.body.page < this.lastPage) {
            this.body.page += 1;
            this.getOrders();
        }
    }

    ngOnDestroy() {
        this.orangeStore.dispatch(resetState());
    }

}
