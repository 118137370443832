import {AfterViewInit, Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {HmPromoComponent} from '../hm-promo/hm-promo.component';
import {HmSupportComponent} from '../hm-support/hm-support.component';
import {HmNotificationsComponent} from '../hm-notifications/hm-notifications.component';
import {DigoService} from '../../../services/digo.service';
import {HmGpvComponent} from '../hm-gpv/hm-gpv.component';
import * as moment from 'moment/moment';
import {HmManualsComponent} from '../hm-manuals/hm-manuals.component';
import {HomeService, INavigateFromOperation} from '../../services/home.service';
import {HmRateService} from '../../services/hm-rate.service';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {environment} from '../../../../environments/environment';
import { Subject, Subscription } from 'rxjs';
import { SimyoService } from 'src/app/simyo/services/simyo.service';
import { allLocalStorage, PDV_MULTIMARCA, tabHome, notificationsName, pdvAreas } from 'src/app/shared/constantes';
import { allPermissions } from 'src/app/shared/permissions';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { masterAllResponseSimyo } from 'src/app/simyo/models/masterAllSimyo';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { HmModalContactPdvComponent } from '../hm-modal-contact-pdv/hm-modal-contact-pdv.component';
import {CookieService} from '../../services/cookie.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AcceptedPointsProgramComponent } from '../hm-points/modals/accepted-points-program/accepted-points-program.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { HomePointsComponent } from '../hm-points/home-points/home-points.component';
import { PointsService } from '../../services/points.service';
import { BannerComponent } from '../hm-points/modals/banner/banner.component';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: [
        './home.component.css',
        '../../../../assets/css/home-theme.css',
    ],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('@.disabled') disabled = true;
    @ViewChild('tabGroup') tabGroup: MatTabGroup;
    @ViewChild('tabPoints') tabPoints: HomePointsComponent;

    public version: string;
    public lastAccess: string;
    public pdv: Ipdv;
    public notifications = [];
    public changeTab: Subject<any> = new Subject<any>();
    public allPermissions = allPermissions;
    public PDV_MULTIMARCA = PDV_MULTIMARCA;
    public notFinishDate = moment('2021-09-11');
    public today = moment();
    public clicoError: string = '';
    public clicoLoading: boolean = false;
    public tabHome = tabHome;
    private allSubscription: Subscription[] = [];
    public selectedIndexTab = 0;

    public showRedeemPoints = false;
    public rewardSelected = null;
    public amountSelected = null;
    public availablePoints = null;
    public currentSelectedIndex = 0;
    public showContentPoints = 'points';
    public dataPoints = null;
    constructor(
        private digoService: DigoService,
        private homeService: HomeService,
        private rateService: HmRateService,
        private dialog: MatDialog,
        private simyoService: SimyoService,
        public permSv: PermissionService,
        private cookieService: CookieService,
        private modalService: NgbModal,
        private pointsService: PointsService
    ) {
        this.version = environment.version;
    }

    ngOnInit(): void {
        this.allSubscription.push(
            this.digoService.getPDV().subscribe((data: Ipdv) => {
                if (data) {
                    this.pdv = data;
                    this.notificationConf();
                    this.setLastAccess();
                    this.checkIfShowAlert();
                }
            })
        );

        this.simyoService
            .getMasterAllFromService()
            .subscribe((data: masterAllResponseSimyo) => {
                if (data && data.msg) {
                    this.simyoService.setMasterAll(data.msg);
                }
            });
    }

    ngAfterViewInit() {
        this.allSubscription.push(
            this.homeService.getViewBrandRates().subscribe((data: any) => {
                if (data != null) {
                    setTimeout(() => {
                        this.tabGroup.selectedIndex =
                            this.homeService.findIndexToMove(
                                this.tabGroup,
                                tabHome.TARIFAS
                            );
                        this.rateService.setFilterRatesByBrand(data);
                        this.homeService.setViewBrandRates(null);
                    });
                }
            })
        );

        this.allSubscription.push(
            this.homeService
                .getNavigateFromOperation()
                .subscribe((data: INavigateFromOperation) => {
                    if (data) {
                        this.tabGroup.selectedIndex =
                            this.homeService.findIndexToMove(
                                this.tabGroup,
                                tabHome.OPERACIONES
                            );
                    }
                })
        );

        this.allSubscription.push(
            this.homeService
                .getNavigateFromOperationPosVenta()
                .subscribe((data: INavigateFromOperation) => {
                    if (data) {
                        this.tabGroup.selectedIndex =
                            this.homeService.findIndexToMove(
                                this.tabGroup,
                                tabHome.OPERACIONES_POSVENTA
                            );
                    }
                })
        );

        this.allSubscription.push(
            this.homeService.getGoToTab().subscribe((data: any) => {
                if (data && data !== '') {
                    this.tabGroup.selectedIndex =
                        this.homeService.findIndexToMove(this.tabGroup, data);
                    this.homeService.setGoToTab('');
                }
            })
        );
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map((subs) => subs.unsubscribe());
        }
    }

    getPdv() {
        this.digoService.getPdvFromService().subscribe((data: Ipdv) => {
            this.digoService.setPDV(data);
        });
    }

    openPromoDialog() {
        const promoSaveDate = localStorage.getItem(
            allLocalStorage.promoSaveDate
        );
        const show =
            promoSaveDate == null || this.today.diff(promoSaveDate, 'days') > 0;

        localStorage.setItem(
            allLocalStorage.promoSaveDate,
            this.today.format('YYYY-MM-DD')
        );

        if (show) {
            this.dialog.open(HmPromoComponent, {
                backdropClass: 'md-backdrop',
                panelClass: 'md-container',
            });
        }
    }

    openGpvDialog() {
        this.dialog.open(HmGpvComponent, {
            backdropClass: 'md-backdrop',
            maxWidth: 500,
            panelClass: 'md-container-white',
            width: '100%',
            data: {
                pdv: this.pdv,
            },
        });
    }

    openSupportDialog() {
        this.dialog.open(HmSupportComponent, {
            backdropClass: 'md-backdrop',
            maxWidth: 800,
            minWidth: 500,
            panelClass: 'md-container-white',
            data: {
                pdv: this.pdv,
            },
        });
    }

    openNotificationsDialog() {
        const dialogRef = this.dialog.open(HmNotificationsComponent, {
            backdropClass: 'md-backdrop',
            maxWidth: 800,
            panelClass: 'md-container-white',
            width: '100%',
            data: {
                pdv: this.pdv,
            },
        });

        dialogRef.afterClosed().subscribe(() => {
            this.notifications = dialogRef.componentInstance.notifications;
        });
    }

    openContactPdvDialog(showAlert: boolean = false) {
        this.dialog.open(HmModalContactPdvComponent, {
            backdropClass: 'md-backdrop',
            maxWidth: 800,
            panelClass: 'md-container-white',
            width: '100%',
            data: {
                pdv: this.pdv,
                showAlert,
            },
        });
    }

    openManualsDialog() {
        this.dialog.open(HmManualsComponent, {
            backdropClass: 'md-backdrop',
            maxWidth: 800,
            panelClass: 'md-container-white',
            width: '100%',
            data: {
                pdv: this.pdv,
            },
        });
    }

    setLastAccess() {
        if (
            this.pdv.info.last_access &&
            this.pdv.info.last_access !== '' &&
            this.pdv.info.last_access !== 'N/A'
        ) {
            this.lastAccess = moment(this.pdv.info.last_access).format(
                'DD/MM/YYYY HH:mm UTCZ'
            );
        }
    }

    public tabChanged(event) {
        this.doKeepAlive();
        if (event?.tab?.textLabel === tabHome.PUNTOS) {
            this.tabPoints.changeContent('points');
            if(this.pointsService.checkIsAcceptedPointsProgram(this.pdv.info.points_terms) === false) {
                this.askAcceptedPointsProgram(event);
                return;
            }
        }

        this.currentSelectedIndex = this.tabGroup.selectedIndex;
        this.changeTab.next(event);
    }

    doKeepAlive() {
        this.digoService.keepAlive().subscribe({
            next: (resp: any) => {
                if (!resp || resp !== 200) {
                    this.digoService.goLogin();
                }
            },
            error: (error: HttpErrorResponse) => {
                this.digoService.goLogin();
            },
        });
    }

    notificationConf() {
        // Pasos para añadir una nueva notificacion
        /*
            1. Añadimos en constantes.ts en notificationsName el identificador de la notificacion
            2. Añadimos la notificacion en el componente HmNotificationsComponent asignandole el identificador del punto 1
                2.1 Recordar en esta funcion añadir en el if el nuevo documento y en el click al descargar llamar a la función para que lo elimine
            3. En esta función realizar una llamada a la funcion addNewNotificacion con el identificador del punto 1
        */

        // Pasos para eliminar una notificacion
        /*
            1. Eliminamos del componente HmNotificationsComponent la comunicación a eliminar
                1.1 Recordar en esta funcion eliminar en el if el viejo documento
            2. En esta función realizar una llamada a la función removeOldNotification con el identificador elimnado
        */
        if (
            localStorage.getItem(allLocalStorage.notificationHome) ||
            localStorage.getItem(allLocalStorage.notificationHome) === ''
        ) {
            let storageNotifications =
                localStorage.getItem(allLocalStorage.notificationHome) === ''
                    ? []
                    : localStorage
                          .getItem(allLocalStorage.notificationHome)
                          .split(',');
            // Si queremos meter otra notificacion
            //this.addNewNotificacion(storageNotifications, notificationsName.notSimyoAdicionales);

            this.addNewNotificacion(storageNotifications, notificationsName.notComercialJuly);
            this.addNewNotificacion(storageNotifications, notificationsName.notJazztelRevista);
            this.addNewNotificacion(storageNotifications, notificationsName.notSimyoCommercial);
            this.addNewNotificacion(storageNotifications, notificationsName.notJazztelCommercial);
            this.addNewNotificacion(storageNotifications, notificationsName.notOrangeCommercial);

            if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)) {
                this.addNewNotificacion(
                    storageNotifications,
                    notificationsName.notSimyoFibra500
                );
            }
            if (this.permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)) {
                this.addNewNotificacion(
                    storageNotifications,
                    notificationsName.notJazztelOferta
                );
                this.addNewNotificacion(
                    storageNotifications,
                    notificationsName.notJazztelProvision
                );
            }
            if (
                this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender) &&
                +this.pdv.info.area === pdvAreas.nuevosCanales
            ) {
                this.addNewNotificacion(
                    storageNotifications,
                    notificationsName.notSimyoUpdateStandAlone
                );
            }
            // Si queremos eliminar una notificacion
            //this.removeOldNotification(storageNotifications, notificationsName.notSimyoAdicionales);
            this.removeOldNotification(
                storageNotifications,
                notificationsName.notAmenaClose
            );
            this.removeOldNotification(
                storageNotifications,
                notificationsName.notSimyoMR
            );

            this.notifications = storageNotifications;
        } else {
            let allNotifications = [];
            if (this.pdv) {
                if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender)) {
                    allNotifications.push(
                        notificationsName.notSimyoAdicionales,
                        notificationsName.notSimyoCommercial,
                    );
                }
                if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)) {
                    allNotifications.push(notificationsName.notSimyoFibra500);
                }
                if (this.permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)) {
                    allNotifications.push(
                        notificationsName.notJazztelOferta,
                        notificationsName.notJazztelProvision,
                        notificationsName.notJazztelRevista,
                        notificationsName.notJazztelCommercial
                    );
                }
                if (
                    this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender) &&
                    +this.pdv.info.area === pdvAreas.nuevosCanales
                ) {
                    allNotifications.push(
                        notificationsName.notSimyoUpdateStandAlone
                    );
                }
                if (
                    this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_prepago) || this.permSv.hasPermFromV2(allPermissions.orange.permite_ventas_pospago)
                ) {
                    allNotifications.push(
                        notificationsName.notOrangeCommercial
                    );
                }
            }
            localStorage.setItem(
                allLocalStorage.notificationHome,
                allNotifications.toString()
            );
            this.notifications = allNotifications;
        }
    }

    addNewNotificacion(storageNotifications, newNotification) {
        if (
            !storageNotifications.includes(newNotification) &&
            !localStorage.getItem('new_' + newNotification)
        ) {
            storageNotifications.push(newNotification);
            localStorage.setItem(
                allLocalStorage.notificationHome,
                storageNotifications.toString()
            );
            localStorage.setItem('new_' + newNotification, 'true');
        }
    }

    removeOldNotification(storageNotifications, oldNotification) {
        if (storageNotifications.includes(oldNotification)) {
            const index = storageNotifications.indexOf(oldNotification);
            if (index > -1) {
                storageNotifications.splice(index, 1);
            }
            localStorage.setItem(
                allLocalStorage.notificationHome,
                storageNotifications.toString()
            );
            localStorage.removeItem('new_' + oldNotification);
        }
    }

    getUrlClico() {
        this.clicoError = '';
        this.clicoLoading = true;
        this.homeService.getUrlClicoFromService().subscribe(
            (data: any) => {
                if (data.result?.url) {
                    const url = data.result.url;
                    window.open(url, '_blank');
                }
                if (data.result?.error) {
                    this.clicoError = data.result?.error;
                }
                this.clicoLoading = false;
            },
            () => {
                this.clicoError =
                    'No se ha podido obtener el acceso a Clico. Pongase en contacto con su administrador';
                this.clicoLoading = false;
            }
        );
    }

    checkIfShowAlert() {
        if (
            this.pdv.info.support.showAlert &&
            this.cookieService.getCookie('showAlertSupportContact') === ''
        ) {
            this.openContactPdvDialog(true);
            this.cookieService.setCookie({
                name: 'showAlertSupportContact',
                value: 'true',
            });
        }
    }

    

    goToRedeemPoints(_rewardSelected) {
        this.showRedeemPoints = true;
        this.availablePoints = _rewardSelected.availablePoints;
        this.rewardSelected = _rewardSelected.reward;
        this.amountSelected = _rewardSelected.amount;
    }

    goBackHomePoints(event) {
        this.showRedeemPoints = false;
        this.showContentPoints = event;
    }

    updatePoints(event) {
        this.dataPoints = event;
    }

    private askAcceptedPointsProgram(event) {
        const dialogRef = this.dialog.open(
            AcceptedPointsProgramComponent,
            {
                backdropClass: 'md-backdrop',
                maxWidth: 800,
                panelClass: 'md-container-white',
                width: '100%',
            }
        );
        dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
            if (result === false) {
                this.tabGroup.selectedIndex = this.currentSelectedIndex;
                return;
            }
            this.digoService
                .putTermsInfo({
                    points_terms: JSON.stringify(result),
                    id_pdv: this.pdv.info.idpdv,
                    key: environment.digoApiKey,
                })
                .subscribe(() => {
                    this.pdv.info.points_terms = JSON.stringify(result);
                    this.currentSelectedIndex = this.tabGroup.selectedIndex;
                    this.changeTab.next(event);
                });
        });
    }
}
