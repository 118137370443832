import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import { PointOrderComponent } from './components/hm-points/order/order.component';
import { TestComponentComponent } from './test-component/test-component.component';


const routes: Routes = [
    {path: '', component: HomeComponent, data: {title:'Home'}},
    {path: 'test-component', component: TestComponentComponent, data: {title:'Test'}}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HomeRoutingModule {
}
