import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { priceSwapSim, simyoDocStatus, superStatus, superStatusTranslate, typeCard } from 'src/app/shared/constantes';
import { SimyoDuplicadosSimService } from 'src/app/simyo/services/simyo-duplicados-sim.service';
import { SimyoService } from 'src/app/simyo/services/simyo.service';
import { downloadFile } from 'src/app/utils/downloadFile';

@Component({
    selector: 'app-simyo-ds-detail',
    templateUrl: './simyo-ds-detail.component.html',
    styleUrls: ['./simyo-ds-detail.component.scss', '../../../../../assets/css/simyo-theme.css']
})
export class SimyoDsDetailComponent implements OnInit {

    public loaded: boolean = false;
    public orderNotFound: boolean = false;
    public simSwapOrder: any;
    public superStatus = superStatus;
    public superStatusTranslate = superStatusTranslate;
    public priceSwapSim: number = priceSwapSim;
    public simyoDocStatus = simyoDocStatus;
    public showErrorUploadFile: boolean = false;
    public uploadingFile: boolean = false;

    /*QR eSIM*/
    public isCollapsed: boolean[] = [];
    public statusDisableSend = ['loading', 'success'];
    public statusSendQR$: BehaviorSubject<string> = new BehaviorSubject(
        'initial'
    );
    messageBlocked = 'Deben transcurrir al menos 5 minutos entre reenvios de QR';
    public typeCard = typeCard;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private simyoSimSwapService: SimyoDuplicadosSimService,
        private simyoService: SimyoService
    ) { }

    ngOnInit(): void {
        this.getOrderDetail();
    }

    getOrderDetail() {
        this.simyoSimSwapService.orderSimSwap(this.activatedRoute.snapshot.paramMap.get('duplicados_id'))
            .subscribe((data: any) => {
                if (data && data.msg) {
                    this.simSwapOrder = data.msg;
                    
                    if(this.simSwapOrder.is_esim == typeCard.esim && this.simSwapOrder.show_qr && this.simSwapOrder.status === superStatus.active) {
                        this.simyoService.queryEsimQR(this.simSwapOrder.customer_id, this.simSwapOrder.msisdn).pipe(
                            take(1),
                            map((data: any) => data.msg),
                            tap(data => {
                                if(data) {
                                    this.simSwapOrder.pin = data.PIN;
                                    this.simSwapOrder.qr_code = data.qr_code;
                                }
                            })
                        ).subscribe();
                    }
                };
            }, (error) => {
                this.orderNotFound = true;
            }, () => {
                this.orderNotFound = false;
                this.loaded = true;
            });
    }

    descargar(file) {
        if (this.simSwapOrder) {
            file.downloaded = true;
            this.simyoSimSwapService.getFileUploadSimSwap(this.simSwapOrder.sim_swap_id, file.hash)
                .subscribe(data => {
                    if (data) {
                        file.downloaded = false;
                        downloadFile(data, file.file_name);
                    }
                }, () => {
                    file.downloaded = false;
                });
        }
    }

    goBack() {
        this.router.navigate(['/simyo/listado-duplicados']);
    }

    convertStatusClass(status): string {
        switch (status) {
            case 'OK':
            case 'FIRMADO DIGITALMENTE':
                return 'dot-complete';
            case 'KO':
                return 'dot-cancel-red';
            default:
                return 'dot-pending';
        }
    }

    dropFile(ev) {
        ev.preventDefault();
        if (ev.dataTransfer && ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
            this.uploadFile(ev.dataTransfer.files[0]);
        }
    }

    fileChanged(ev) {
        ev.preventDefault();
        if (ev.target && ev.target.files && ev.target.files.length > 0) {
            this.uploadFile(ev.target.files[0]);
        }
    }

    allowDrop(ev) {
        ev.preventDefault();
    }

    uploadFile(file) {
        this.uploadingFile = true;
        this.showErrorUploadFile = false;
        this.simyoSimSwapService.uploadFileSimSwap(file, this.simSwapOrder.sim_swap_id)
            .subscribe((data: any) => {
                if (data && data.msg) {
                    this.simSwapOrder.documents.push(data.msg);
                }
                this.getOrderDetail();
            }, (error) => {
                this.showErrorUploadFile = true;
                this.uploadingFile = false;
            }, () => {
                this.uploadingFile = false;
            });

    }

    public changeStateSendEmail(index) {
        this.simSwapOrder.showSendEmail = !this.simSwapOrder.showSendEmail;
        this.isCollapsed[index] = true;
    }

    public changeStateInfoEsim(index) {
        this.isCollapsed[index] = !this.isCollapsed[index];
        this.simSwapOrder.showSendEmail = false;
    }

    public hideEsim(index) {
        if (this.isCollapsed[index] === undefined) {
            this.isCollapsed[index] = true;
        }
        
        return this.isCollapsed[index]
    }

    public sendEmail() {
        let currentStatus = this.statusSendQR$.getValue();
        if(currentStatus === 'blocked') {
            return;
        }
        
        this.statusSendQR$.next('loading');
        let data = {
            customer_id: this.simSwapOrder.customer_id, 
            msisdn: this.simSwapOrder.msisdn,
            sim_swap_id: this.simSwapOrder.sim_swap_id
        }
        this.simyoService.sendEsimQR(data).pipe(
            take(1),
            catchError(err => {
                let status = 'error'; 
                if(err.error.error.errorCode === 403) {
                    status = 'blocked';
                }               
                
                this.statusSendQR$.next(status);
                return of({});
            }),
            tap((data: any) => {
                if(data.hasOwnProperty('msg')) {
                    this.statusSendQR$.next('success');
                }
                setTimeout(() => {
                    this.statusSendQR$.next('initial');
                }, 10000);
            })
        ).subscribe();
    }

    getTextButtonSendQR(status) {
        if(status === 'loading') return 'Enviando...';
        if(status === 'success') return 'Enviado';
        return 'Enviar';
    }
}
