<div class="container content">
    <div class="col-lg-12 mb-2">
        <h5 class="title-step text-center">
            <img src="./assets/img/linea-conf.svg" class="img-fluid ico-title transf-ico" width="20"> Selección de bono
        </h5>
    </div>
    <div class="row mb-4">
        <div class="card card-body m-3 bg-white col">
            <h6 class="fw-bold mb-0 cursor-pointer" (click)="showPersonalInfo = !showPersonalInfo">
                Información del cliente
                <span class="ms-4 fw-normal" style="font-size: 0.9rem;" *ngIf="!showPersonalInfo">
                    {{ (orange$ | async)?.configuration_form_values?.customer_info?.first_name + ' ' + (orange$ | async)?.configuration_form_values?.customer_info?.last_name + ' - ' + (orange$ | async)?.configuration_form_values?.customer_info?.msisdn + ' - Saldo: ' + (+(orange$ | async)?.configuration_form_values?.customer_info?.balance === 0 ? +(orange$ | async)?.configuration_form_values?.customer_info?.balance : (+(orange$ | async)?.configuration_form_values?.customer_info?.balance).toFixed(2))  + ' €' }}
                    <span [ngbTooltip]="disabledRequestBalance ? tooltipTimeToRequest : ''" [placement]="'left-top'">
                        <i-feather (click)="updateBalance(); $event.stopPropagation()" name="refresh-cw" class="icon--fe ms-1 cursor-pointer" [ngClass]="{'fa-spin': loadingBalance, 'disable-click': loadingBalance || disabledRequestBalance }" style="width: 16px !important; height: auto;"></i-feather>
                    </span>
                </span>
                <i-feather [name]="showPersonalInfo ? 'chevron-up' : 'chevron-down'" class="img-fluid font float-end" style="width: 20px;"></i-feather>
            </h6>
            <div class="row m-0" *ngIf="showPersonalInfo">
                <div class="row col-12 ps-3">
                    <!-- Nombre -->
                    <div class="col-3">
                        <h5 class="tar-name-6 mt-2 mb-1">
                            Nombre
                        </h5>
                        <h5 class="tar-name-7 mt-1">
                            {{ (orange$ | async)?.configuration_form_values?.customer_info?.first_name + ' ' + (orange$ | async)?.configuration_form_values?.customer_info?.last_name }}
                        </h5>
                    </div>
                    <!-- Documento de identidad -->
                    <div class="col-3">
                        <h5 class="tar-name-6 mt-2 mb-1">
                            Documento de identidad
                        </h5>
                        <h5 class="tar-name-7 mt-1">
                            {{ ((orange$ | async)?.configuration_form_values?.customer_info?.document_type_name && (orange$ | async)?.configuration_form_values?.customer_info?.document_type_name !== '') ? ((orange$ | async)?.configuration_form_values?.customer_info?.document_type_name + ': ' + (orange$ | async)?.configuration_form_values?.customer_info?.document_number) : (orange$ | async)?.configuration_form_values?.customer_info?.document_number }}
                        </h5>
                    </div>
                    <!-- Msisdn -->
                    <div class="col-2">
                        <h5 class="tar-name-6 mt-2 mb-1">
                            Msisdn
                        </h5>
                        <h5 class="tar-name-7 mt-1">
                            {{ (orange$ | async)?.configuration_form_values?.customer_info?.msisdn }}
                        </h5>
                    </div>
                    <!-- Saldo -->
                    <div>
                        <h5 class="tar-name-6 mt-2 mb-1">
                            Saldo
                        </h5>
                        <h5 class="tar-name-7 mt-1">
                            {{ +(orange$ | async)?.configuration_form_values?.customer_info?.balance === 0 ? +(orange$ | async)?.configuration_form_values?.customer_info?.balance : (+(orange$ | async)?.configuration_form_values?.customer_info?.balance).toFixed(2) }} €
                            <span [ngbTooltip]="disabledRequestBalance ? tooltipTimeToRequest : ''" [placement]="'left-top'">
                                <i-feather (click)="updateBalance(); $event.stopPropagation()" name="refresh-cw" class="icon--fe ms-1 cursor-pointer" [ngClass]="{'fa-spin': loadingBalance, 'disable-click': loadingBalance || disabledRequestBalance }" style="width: 16px !important; height: auto;"></i-feather>
                            </span>
                        </h5>
                    </div>
                </div>
                <div class="row col-12 ps-3">
                    <!-- Tarifa prepago -->
                    <div class="col-3">
                        <h5 class="tar-name-6 mt-2 mb-1">
                            Tarifa prepago
                        </h5>
                        <h5 class="tar-name-7 mt-1">
                            {{ (orange$ | async)?.configuration_form_values?.customer_info?.rate_name || 'Tarifa desconocida' }}
                        </h5>
                    </div>
                    <!-- Estado de la tarifa -->
                    <div class="col-3" *ngIf="!(orange$ | async)?.configuration_form_values?.customer_info?.has_benefit_line_status">
                        <h5 class="tar-name-6 mt-2 mb-1">
                            Estado de la tarifa
                        </h5>
                        <h5 class="tar-name-7 mt-1">
                            {{ (orange$ | async)?.configuration_form_values?.customer_info?.has_benefit_line_status ? 'Activa' : 'Caducada' }}
                        </h5>
                    </div>
                    <!-- Otros bonos contratados -->
                    <div class="col-6" *ngIf="(orange$ | async)?.configuration_form_values?.customer_info?.active_bonos && (orange$ | async)?.configuration_form_values?.customer_info?.active_bonos.length">
                        <h5 class="tar-name-6 mt-2 mb-1">
                            Otros bonos contratados
                        </h5>
                        <h5 class="tar-name-7 mt-1">
                            {{ (orange$ | async)?.configuration_form_values?.customer_info?.active_bonos_name }}
                        </h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-body m-3 bg-white col" style="padding-bottom: 5px;">
            <h6 style="position: relative; left: 0px;" class="mb-4 fw-bold">
                Bonos disponibles
            </h6>
            <div class="row header-type-bonos p-1 mb-3">
                <b>BONOS DE LLAMADAS</b>
            </div>
            <div *ngIf="deadlineEcuador() || startRamadan()">
                <div class="promo-card margins mb-3" *ngIf="startRamadan()">
                    <div class="promo">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="promo-title"><span><b>RAMADÁN</b></span></td>
                                    <td class="promo-desc"><span>Del 3 al 30 de abril, tu bono Marruecos te sale gratis. Cómpralo y te devolvemos los 5€ en tu saldo </span><small>(máx. 72h tras la activación)</small></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="m-3 mt-0" *ngIf="deadlineEcuador()">
                    <p>            *A partir del 23/abril, el bono de Ecuador cambia sus condiciones, incluyendo 50 minutos a fijos y móviles de Ecuador por 5 euros (IVA incl)
                    </p>
                </div>
            </div>

            <div *ngIf="deadlineEcuador() && deadlineMorocco() && !startRamadan()" class="promo-card margins mb-3">
                <div class="promo">
                    <table>
                        <tbody>
                            <tr>
                                <td class="promo-title"><span><b>BONO MARRUECOS/ECUADOR</b></span></td>
                                <td class="promo-desc"><span>A partir del <b>3 de abril (Bono Marruecos)/23 de abril (Bono Ecuador),</b> cambiarán las condiciones, ofrecerán <b>50 minutos</b> a los mismos destinos actuales, por <b>5 euros</b> IVA incluido</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row" style="justify-content: center;">
                <ng-container *ngFor="let bono of (orange$ | async)?.configuration_form_values?.customer_info?.available_bonos_info">
                    <div class="col-lg-2 hvr-float" *ngIf="bono.available && bono.type === 'voice'" style="padding-bottom: 15px;">
                        <div class="card-tar card-white mb-5 mb-lg-0" [ngClass]="{'card-disabled': !bono.active, 'selected-bono': bono.selectedBono}">
                            <div class="card-simyo-header" style="padding-top: 20px !important;">
                                <p  class="tar-simyo-tar text-center">
                                    <span class="badge rounded-pill badge-simyo" style="position: absolute; top: 5px; left: 5px;" [ngStyle]="{'background-color': colorsBonosAdditional[bono.type] }">
                                        {{ translateBonosAdditional[bono.type] }}
                                    </span>
                                    <b>{{ bono.name }}</b>
                                    <a class="cursor-pointer" *ngIf="bono.complete_description" (click)="openModal(infoBono, bono)" style="position: relative; bottom: 2px;">
                                        <i-feather name="alert-circle" class="icon-info ms-1"></i-feather>
                                    </a>
                                </p>
                            </div>
                            <div class="card-tar-body  ribbon-parent">
                                <ng-container *ngIf="bono.promo">
                                    <span placement="right" class="ribbon left">PROMO</span>
                                </ng-container>
                                <!-- Servicios contratados -->
                                <p  class="fw-bold mb-0 text-center" *ngIf="bono.data || bono.voice">
                                    {{ bono.data ? bono.data : '' }} {{ bono.voice ? bono.voice : '' }}
                                </p>
                                <!-- Servicios contratados -->
                                <p  class="tar-simyo-des fw-bold mb-2 text-center">
                                    {{ bono.price ? (bono.price + '€') : '' }}
                                </p>
                                <!-- Contratar -->
                                <button id="tarifas-prepago2" class="btn btn-simyo2 d-block w-100 btn-sm mb-2" (click)="setBonoRate(bono);">
                                    Continuar
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="row header-type-bonos p-1 mb-3">
                <b>BONOS DE GB / SMS</b>
            </div>

            <div class="row" style="justify-content: center;">
                <ng-container *ngFor="let bono of (orange$ | async)?.configuration_form_values?.customer_info?.available_bonos_info">
                    <div class="col-lg-2 hvr-float" *ngIf="bono.available && (bono.type === 'sms' || bono.type === 'internet')" style="padding-bottom: 15px;">
                        <div class="card-tar card-white mb-5 mb-lg-0" [ngClass]="{'card-disabled': !bono.active, 'selected-bono': bono.selectedBono}">
                            <div class="card-simyo-header" style="padding-top: 20px !important;">
                                <p  class="tar-simyo-tar text-center">
                                    <span class="badge rounded-pill badge-simyo" style="position: absolute; top: 5px; left: 5px;" [ngStyle]="{'background-color': colorsBonosAdditional[bono.type] }">
                                        {{ translateBonosAdditional[bono.type] }}
                                    </span>
                                    <b>{{ bono.name }}</b>
                                    <a class="cursor-pointer" *ngIf="bono.complete_description" (click)="openModal(infoBono, bono)" style="position: relative; bottom: 2px;">
                                        <i-feather name="alert-circle" class="icon-info ms-1"></i-feather>
                                    </a>
                                </p>
                            </div>
                            <div class="card-tar-body ribbon-parent">
                                <ng-container *ngIf="bono.promo">
                                    <span placement="right" class="ribbon left">PROMO</span>
                                </ng-container>
                                <!-- Servicios contratados -->
                                <p  class="fw-bold mb-0 text-center" *ngIf="bono.data || bono.voice">
                                    {{ bono.data ? bono.data : '' }} {{ bono.voice ? bono.voice : '' }}
                                </p>
                                <!-- Servicios contratados -->
                                <p  class="tar-simyo-des fw-bold mb-2 text-center">
                                    {{ bono.price ? (bono.price + '€') : '' }}
                                </p>
                                <!-- Contratar -->
                                <button id="tarifas-prepago2" class="btn btn-simyo2 d-block w-100 btn-sm mb-2" (click)="setBonoRate(bono);">
                                    Continuar
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <mat-error class="row" style="margin-bottom: 15px;" *ngIf="selectedBonoNotAvailable !== ''">
                {{ selectedBonoNotAvailable }}
            </mat-error>
        </div>

        <div class="col-lg-12" *ngIf="(orange$ | async)?.configuration_form_values.rate">
            <div class="row">
                <div class="col-xl-6">
                    <div class="card bg-white configurador mt-3 pb-4">
                        <h6 style="position: relative; left: 0px;" class="fw-bold">
                            {{ (orange$ | async)?.configuration_form_values?.rate?.name }}
                        </h6>
                        <div class="row">
                            <div class="col-lg-12 mb-2">
                                <h6 style="font-size: 0.95rem !important;" [innerHTML]="(orange$ | async)?.configuration_form_values?.rate?.summary_description"></h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-6">
                    <div class="card bg-white configurador mt-3" style="padding-bottom: 35px;">
                        <!-- Saldo -->
                        <h6 style="position: relative; left: 0px;" class="fw-bold" [ngClass]="{'mb-4': +(orange$ | async)?.configuration_form_values?.rate?.price > +(orange$ | async)?.configuration_form_values?.customer_info?.balance, 'mb-0': !(+(orange$ | async)?.configuration_form_values?.rate?.price > +(orange$ | async)?.configuration_form_values?.customer_info?.balance)}">
                            <b style="color: red;" *ngIf="+(orange$ | async)?.configuration_form_values?.rate?.price > +(orange$ | async)?.configuration_form_values?.customer_info?.balance">
                               Saldo insuficiente para el bono seleccionado: {{ (orange$ | async)?.configuration_form_values?.customer_info?.balance }} €
                            </b>
                            <b *ngIf="!(+(orange$ | async)?.configuration_form_values?.rate?.price > +(orange$ | async)?.configuration_form_values?.customer_info?.balance)">
                                Saldo: {{ (orange$ | async)?.configuration_form_values?.customer_info?.balance }} €
                            </b>
                            <span [ngbTooltip]="disabledRequestBalance ? tooltipTimeToRequest : ''" [placement]="'left-top'">
                                <i-feather (click)="updateBalance()" name="refresh-cw" class="icon--fe ms-3 cursor-pointer" [ngClass]="{'fa-spin': loadingBalance, 'disable-click': loadingBalance || disabledRequestBalance }" style="width: 16px !important; height: auto;"></i-feather>
                            </span>
                        </h6>
                        <!-- Realizar una recarga -->
                        <div class="card-tar card-white" style="position: relative; top: -4px;" *ngIf="+(orange$ | async)?.configuration_form_values?.rate?.price > +(orange$ | async)?.configuration_form_values?.customer_info?.balance">
                            <div class="card-simyo-header">
                                <div class="resumen d-flex justify-content-between align-items-center" style="position: relative; top: -10px;">
                                    <span>
                                        <img src="./assets/img/saldo.svg" class="img-fluid font" width="13">
                                        <b>
                                            Realice una recarga
                                        </b>
                                    </span>
                                    <small class="text-muted">
                                        <span class="sidebar-info3">
                                            <a href="{{ baseUrl }}recarga_saldo_origen.itx" class="btn btn-white btn-sm" target="_blank"
                                                *ngIf="permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && +pdv?.info_recargas?.saldo_promo > 0 && !permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv?.info_recargas?.usuario_dexga > 0">
                                                Añadir saldo
                                            </a>
                                        </span>
                                        <span class="sidebar-info3">
                                            <a href="{{ baseUrl }}recarga_saldo.itx" class="btn btn-white btn-sm" target="_blank"
                                                *ngIf="permSv.hasPermFromV2(allPermissions.others.recharges_permite_saldo_promocional) && +pdv?.info_recargas?.saldo_promo > 0 && +pdv?.info_recargas?.usuario_dexga === 0">
                                                Añadir saldo
                                            </a>
                                        </span>
                                        <span class="sidebar-info3">
                                            <a href="{{ baseUrl }}recargas_dexga_recarga.itx" class="btn btn-white btn-sm" target="_blank"
                                                *ngIf="!permSv.hasPermFromV2(allPermissions.others.recharges_bloquea_saldo_dexga) && +pdv?.info_recargas?.usuario_dexga > 0 && +pdv?.info_recargas?.saldo_promo === 0">
                                                Añadir saldo
                                            </a>
                                        </span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <!-- Error al consultar el saldo -->
                        <mat-error class="mt-2" *ngIf="errorRequestBalance">
                            <b>
                                Error al consultar su saldo. Inténtelo de nuevo más tarde
                            </b>
                        </mat-error>
                    </div>
                </div>
            </div>
            <!-- Continuar proceso -->
            <div class="d-flex justify-content-center">
                <br>
                <input #ratePrice style="display: none" type="text" [value]="(orange$ | async)?.configuration_form_values?.rate?.price">
                <input #customerBalance style="display: none" type="text" [value]="(orange$ | async)?.configuration_form_values?.customer_info?.balance">
                <a class="btn btn-black d-block w-100 mb-1 pt-3 fw-bold" style="padding-bottom: 20px !important;" (click)="evaluateNextStep(+ratePrice.value <= +customerBalance.value)" role="button">
                    Continuar <img src="./assets/img/arrow-right-white.svg" class="img-fluid ico-title" width="15">
                </a>
                <br><br>
            </div>
        </div>
    </div>
</div>

<ng-template #infoBono let-modal>
    <div class="modal-header bg-black">
        <h5 class="modal-title white" id="exampleModalLabel">Información sobre el bono</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><img src="./assets/img/times-white.svg" class="img-fluid ico-title" width="20"></span>
        </button>
    </div>
    <div class="modal-body m-3">
        <div class="row">
            <div class="col-lg-12">
                <!-- Marruecos -->
                <p *ngIf="selectedBono.name === 'Bono Marruecos' && deadlineMorocco() && startRamadan()"><b style="color: #ff5900">*Del 3 al 30 de abril, tu bono Marruecos te sale gratis. Cómpralo y te devolvemos los 5€ en tu saldo (máx. 72h tras la activación).</b></p>
                <p *ngIf="selectedBono.name === 'Bono Marruecos' && deadlineMorocco() && !startRamadan()"><b style="color: #ff5900">*A partir del 3/abril, el Bono Marruecos cambia sus condiciones, incluyendo 50 minutos a fijos y móviles de Marruecos por 5 euros IVA incluido.</b></p>
                <!-- Ecuador-->
                <p *ngIf="selectedBono.name === 'Bono Ecuador' && deadlineEcuador()"><b style="color: #ff5900">*A partir del 23/abril, el bono de Ecuador cambia sus condiciones, incluyendo 50 minutos a fijos y móviles de Ecuador por 5 euros IVA incluido.</b></p>
                <h6 [innerHTML]='selectedBono.complete_description'></h6>
            </div>
        </div>
    </div>
</ng-template>

<!-- Alerta recarga -->
<ng-template #modalRecarga let-modal>
    <div class="modal-header bg-black">
        <h5 class="modal-title white" id="exampleModalLabel">Recargar línea</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20"></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p class="my-3">
                        Es necesario recargar la línea <b>{{ (orange$ | async)?.configuration_form_values?.customer_info?.msisdn }}</b> con <b>{{ +(orange$ | async)?.configuration_form_values?.rate?.price - +(orange$ | async)?.configuration_form_values?.customer_info?.balance }}€</b> para continuar con el proceso.
                    </p>
                    <a class="btn btn-black fw-bold float-end" role="button" (click)="modal.dismiss('Cross click')">
                        Aceptar
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tooltipTimeToRequest>
    <div class="d-flex flex-column">
        <span>{{ 'Debe esperar ' + timeToRequest + ' segundo para actualizar su saldo' }}</span>
    </div>
</ng-template>
