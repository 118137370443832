export class Broadband {
    rate_id: string;
    gescal: string;
    price?: any;
    access_type: string;
    commercial_deal: string;
    virtual?: boolean;
    iv_frontal_confirmation?: boolean;
    discount?: number;
    total_without_discount?: string;
    type_discount?: string;
    name?: string;
}
