<div class="modal-container">
    <div class="modal-header bg-black">
        <h4 class="modal-title white fw-bold">Bienvenido al Programa de Puntos de Digo</h4>
        <button type="button" class="close" (click)="dialogRef.close(false)">
            <span aria-hidden="true"><img src="./assets/img/times.svg" class="img-fluid ico-title" width="24"></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 d-flex align-items-center">                
                    <p class="message">                        
                            <b>CONDICIONES GENERALES DE PARTICIPACIÓN EN EL PROGRAMA DE DINAMIZACIÓN “ATRÉVETE” DE ORANGE</b><br><br>
                            
                            Las presentes Condiciones Generales (en adelante, las “Condiciones”) rigen la participación en el programa de
                            dinamización (el “Programa”) ofrecido por Orange (en adelante, “Orange”) destinado a los puntos de venta que
                            comercializan líneas de Orange.<br><br>
                            
                            PRIMERA.- Objeto y Descripción del Programa: El Programa tiene como finalidad incentivar y recompensar a los puntos
                            de venta autorizados por Orange mediante la asignación de puntos por las activaciones de líneas realizadas. Dichos
                            puntos
                            serán canjeables por diversos premios, incluyendo, pero no limitándose a, tarjetas de Amazon, Netflix, Spotify, entre
                            otros (los “Premios”).<br><br>
                            
                            SEGUNDA.- Vigencia del Programa: El Programa tendrá una duración indefinida. No obstante, Orange se reserva el derecho
                            de finalizar el Programa en cualquier momento, comprometiéndose a informar de dicha decisión con una antelación mínima
                            de quince (15) días a través de la página de inicio de la herramienta digital utilizada para la gestión del Programa y
                            mediante comunicación directa a los puntos de venta.
                            <br><br>
                            
                            TERCERA.- Condiciones de Participación: Podrán participar en el Programa aquellos puntos de venta que cumplan con los
                            requisitos establecidos por Orange. La participación en el Programa implica la aceptación íntegra y sin reservas de las
                            presentes Condiciones y de las eventuales modificaciones que Orange pueda introducir en el futuro.<br><br>
                            
                            CUARTA.- Mecanismo de Acumulación y Uso de Puntos: Los puntos se acumularán conforme a las activaciones de líneas
                            realizadas por el punto de venta y podrán ser canjeados conforme a las condiciones establecidas en la herramienta.
                            Orange se reserva el derecho de modificar las condiciones de acumulación y uso de puntos en cualquier momento.
                            <br><br>
                            
                            QUINTA.- Tipos de Incentivos y Condiciones Específicas:<br>
                            a. Desde el Primer Alta: Se otorgarán puntos desde la primera activación de línea.<br>
                            b. Con Objetivo: Se asignarán puntos por activaciones siempre que se cumplan los objetivos marcados por Orange.<br>
                            c. Incrementales: Se otorgarán puntos adicionales por aquellas activaciones que superen los objetivos individuales,
                            siempre sujetos al cumplimiento de los objetivos globales.
                            <br><br>
                            
                            SEXTA.- Pérdida de Puntos y Sanciones: El fraude, la mala praxis en la contratación o cualquier otra conducta contraria
                            a las buenas prácticas comerciales y éticas resultarán en la pérdida inmediata de los puntos acumulados y podrán dar
                            lugar a sanciones adicionales, incluyendo la exclusión del Programa.
                            <br><br>
                            
                            SÉPTIMA.- Valoración y Variabilidad de los Premios: El valor en puntos de los Premios estará sujeto a cambios, que serán
                            debidamente comunicados a los participantes. Orange no garantiza la disponibilidad permanente de determinados Premios.
                            <br><br>
                            
                            OCTAVA.- Fiscalidad de los Premios: Los participantes serán responsables del cumplimiento de las obligaciones fiscales
                            derivadas de la recepción de los Premios, debiendo declararlos conforme a la normativa fiscal vigente. Orange no asume
                            ninguna responsabilidad al respecto.<br><br>
                            
                            NOVENA.- Modificación y Terminación del Programa: Orange se reserva el derecho de modificar o terminar el Programa, así
                            como las presentes Condiciones, en cualquier momento. Cualquier cambio será comunicado a los participantes de manera
                            adecuada.<br><br>
                            
                            DÉCIMA.- Legislación Aplicable y Jurisdicción: Las presentes Condiciones se regirán e interpretarán conforme a la
                            legislación española. Cualquier disputa que surja en relación con el Programa será sometida a los tribunales competentes
                            del domicilio de Orange.<br><br>
                            
                            La participación en el Programa implica la aceptación plena y sin reservas de estas Condiciones y de cualquier
                            modificación que Orange pueda introducir. Estas Condiciones están sujetas a cambios y es responsabilidad del
                            participante revisarlas periódicamente.
                    </p>
                </div>
                <div class="col-lg-12">
                    <div class="conditions-form" [formGroup]="termsAndConditionFormGroup">
                        <div class="checkbox icheck-login" [ngClass]="{'error': hasFormError}">
                            <input type="checkbox" id="primary-5" formControlName="conditions" value="1"/>
                            <label for="primary-5" class="font--terms">Acepto las condiciones de uso</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <button type="button" class="btn mb-2 px-4 btn-modal" (click)="validateForm()">
                        Aceptar y unirme
                    </button>
                </div>
            </div>
        </div>
    </div> 
</div>

