<div class="custom-header">
    <button mat-icon-button class="example-double-arrow mat-focus-indicator example-double-arrow mat-icon-button mat-button-base" (click)="previousClicked('year')">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>

    <span class="custom-header-label">{{periodLabel}}</span>
    
    <button mat-icon-button class="example-double-arrow mat-focus-indicator example-double-arrow mat-icon-button mat-button-base" (click)="nextClicked('year')">
        <mat-icon>keyboard_arrow_right</mat-icon>
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>