<div class="card mb-3">
    <div class="card-header bg-rm">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <h6 class="subtitulo-pack2">
                    <img src="./assets/img/file-text-negro.svg" class="img-fluid ico-title me-2" width="13">
                    <b>Resumen de la tramitación</b>
                </h6>
            </div>
        </div>
    </div>

    <div class="bg-white">
        <div class="row">
            <div class="col-md-12">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item cuerpo-pack-2">

                        <!-- RESUMEN MOVIL PACK -->
                        <div *ngIf="(typeOrder === orderType.CONVERGENT || typeOrder === orderType.BROADBAND) && rateFtth">
                            <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                <h5 class="tar-name-7 mt-2 text-start">
                                    <b>{{isDetail ? 'Fibra': 'Alta Fibra'}}</b>
                                </h5>
                                <small class="text-muted trash-carrito" *ngIf="showButtonsEditLine">
                                    <!-- editar producto -->
                                    <a (click)="goToStep(0)">
                                        <img src="./assets/img/edit.svg" class="img-fluid icon-nav-sidebar" width="15">
                                    </a>
                                </small>
                            </div>

                            <div class="resumen d-flex justify-content-between align-items-center">
                                <span class="sidebar-info4 simyo"> Conceptos</span>
                                <small class="text-muted"></small>
                            </div>

                            <div class="resumen d-flex justify-content-between align-items-center">
                                <!-- tarifa -->
                                <span class="sidebar-info5">
                                    <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                    {{ (fiberIndirectTechnologies.includes(broadband?.access_type) ? 'Fibra IND ' : 'Fibra ') + rateFtth.name }} {{ broadband.type_discount != null && !isDetail ? '*' : '' }}
                                </span>
                                <small class="text-muted">
                                    <!-- Precio tarifa -->
                                    <span class="sidebar-info3">
                                        <b>{{ getPriceBroadbandWithoutDiscount()|toFixedDecimals }} €/mes</b>
                                    </span>
                                </small>
                            </div>

                            <!-- Descuento -->
                            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="broadband.type_discount != null && broadband.discount != null">
                                <!-- tarifa -->
                                <span class="sidebar-info5">
                                    {{messageBroadbandTypeDiscount()}}
                                </span>
                                <small class="text-muted trash-carrito">
                                    <!-- Precio tarifa -->
                                    <span class="sidebar-info3">
                                        <b>-{{ broadband.discount|toFixedDecimals }} €/mes</b>
                                    </span>
                                </small>
                            </div>

                            <div class="resumen d-flex justify-content-between align-items-center ps-3"
                                *ngIf="super_status !== superStatus.draft
                                        && broadband
                                        && ((broadband.status_description && broadband.status_description !== '') || broadband.substatus)">
                                <span class="sidebar-info5">
                                    Estado fibra:
                                    <span [ngbTooltip]="broadband?.substatus?.description && broadband?.substatus?.description !== '' ? infoBroadband : ''" [placement]="'right'">
                                        <b [ngClass]="{'cursor-pointer': broadband?.substatus}">{{ broadband.substatus?.name && broadband.substatus?.name !== '' ? broadband.substatus.name : broadband.status_description }} </b>
                                        <span *ngIf="broadband?.substatus?.description && broadband?.substatus?.description !== ''" class="px-2 custom-info cursor-pointer">+info</span>
                                    </span>
                                </span>
                            </div>
                            <div class="resumen d-flex justify-content-start ps-3" *ngIf="showAllInfo">
                                <span class="sidebar-info5">
                                    Tipo de instalación:
                                </span>
                                <div class="fw-bold ms-1 sidebar-info5" [innerHTML]="getTextInstallationType(broadband)"></div>
                            </div>

                            <div class="resumen d-flex justify-content-between align-items-center ps-3"
                                *ngIf="broadband && broadband.activation_date && broadband.activation_date !== ''">
                                <span class="sidebar-info5">
                                    Fecha de activación: <b> {{ broadband.activation_date | date: 'dd/MM/yyyy'}}</b>
                                </span>
                            </div>

                            <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                <span class="sidebar-info">
                                    <b>Total</b>
                                </span>
                                <small class="text-muted trash-carrito">
                                    <!-- Precio tarifa -->
                                    <span class="sidebar-info2">
                                        <b>{{ (getPriceBroadband())|toFixedDecimals }} €/mes</b>
                                    </span>
                                </small>
                            </div>
                        </div>

                        <!-- RESUMEN LÍNEA ADICIONAL -->
                        <div *ngFor="let mobile of mobiles; index as i">
                            <hr *ngIf="typeOrder !== orderType.MOBILE || i > 0">

                            <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                <h5 class="tar-name-7 mt-2 text-start">
                                    <b>{{ (i + 1) + 'ª línea móvil' }}  <small *ngIf="isMainLine(mobile)">(principal)</small></b>
                                    <i-feather *ngIf="isMainLine(mobile)" class="icon-main-line ms-2" width="18" name="award"></i-feather>
                                </h5>
                                <span class="d-flex justify-content-end align-items-center" *ngIf="showButtonsEditLine">
                                    <small class="text-muted trash-carrito">
                                        <!-- editar producto -->
                                        <a (click)="goToStep(i + (rateFtth ? 1 : 0))">
                                            <img src="./assets/img/edit.svg" class="img-fluid icon-nav-sidebar" width="15">
                                        </a>
                                    </small>
                                    <small class="text-muted trash-carrito" *ngIf="i > 0">
                                        <!-- eliminar producto -->
                                        <a (click)="removeExtraLine(i)" class="ps-2">
                                            <img src="./assets/img/trash.svg" class="img-fluid icon-nav-sidebar" width="15">
                                        </a>
                                    </small>
                                </span>
                            </div>

                            <!-- CONCEPTOS -->
                            <div class="resumen d-flex justify-content-between align-items-center">
                                <span class="sidebar-info4 simyo"> Conceptos</span>
                                <small class="text-muted">
                                </small>
                            </div>

                            <!-- Tipo tarifa movil -->
                            <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                <span class="sidebar-info5">
                                    <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                    {{ getTitleTypeMobile(mobile) }}
                                </span>
                            </div>

                            <!-- Precio tarifa movil -->
                            <div class="resumen d-flex justify-content-between align-items-center">
                                <!-- tarifa -->
                                <span class="sidebar-info5">
                                    <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                    <span> {{ getDataMobile(mobile, 'data') }} + {{ getDataMobile(mobile, 'voice') }} {{ mobile.contract_type === 'postpaid' ? '(Pospago)' : '(Prepago)' }}{{ isMainLine(mobile) && !isDetail ? '*' : '' }}</span>
                                    <ng-container *ngIf="mobile.super_status === superStatus.active">
                                        <span *ngIf="mobile.is_esim == typeCard.esim && mobile.show_email" class="px-2 mx-2 custom-info cursor-pointer" (click)="changeStateSendEmail(i)">
                                            {{ mobile.showSendEmail ? 'Ocultar eSIM por e-mail' : 'Enviar eSIM por e-mail' }}
                                        </span>
                                        <span *ngIf="mobile.is_esim == typeCard.esim && mobile.show_qr && mobile.pin"><a (click)="changeStateInfoEsim(i)" class="px-2 mx-2 custom-info cursor-pointer a-link">
                                            {{ hideEsim(i) ? 'Ver' : 'Ocultar'}} eSIM</a>
                                        </span>
                                    </ng-container>
                                </span>
                                <small class="text-muted">
                                    <!-- Precio tarifa -->
                                    <span class="sidebar-info3">
                                        <b>{{ mobile.total_without_discount|toFixedDecimals }} €/mes</b>
                                    </span>
                                </small>
                            </div>

                            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="isMainLine(mobile) && typeOrder === orderType.MOBILE">
                                <span class="sidebar-info5">
                                    {{isDetail ? '' : '*'}}Al contratar esta tarifa tendrás un descuento de {{ discountWithoutFtth()|toFixedDecimals }}€ en tu tarifa estándar de fibra si aún no lo tienes.
                                </span>
                            </div>

                            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="mobile.discount && mobile.discount > 0">
                                <span class="sidebar-info5">
                                    Descuento por línea adicional
                                </span>
                                <small class="text-muted trash-carrito">
                                    <!-- Precio tarifa -->
                                    <span class="sidebar-info3">
                                        <b>-{{ mobile.discount|toFixedDecimals }} €/mes</b>
                                    </span>
                                </small>
                            </div>

                            <div class="resumen" *ngIf="mobile.show_qr && !hideEsim(i) && mobile.pin">
                                <div>
                                    <div class="px-4 pb-2">
                                        <p><b class="col-6 px-0">PIN: </b><span class="simyo">{{ mobile.pin }}</span></p>
                                        <img [src]="mobile.qr_code" [width]="150" class="img-fluid" alt="Qr code"/>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="(statusSendQR$ | async) as statusSendQR">
                                <div class="resumen d-flex align-items-center p-3 row" *ngIf="mobile.showSendEmail">
                                    <div class="col-12 px-0">
                                        <mat-form-field class="col-6 me-3">
                                            <mat-label>Correo electrónico</mat-label>
                                            <input matInput type="text" disabled [ngModel]="customerData.customer.email">
                                        </mat-form-field>
                                        <button (click)="sendEmail(i)" target="_blank" class="btn btn-black-outline btn-sm" [disabled]="statusDisableSend.includes(statusSendQR[i])">
                                            <b>{{getTextButtonSendQR((statusSendQR$ | async))}}</b>
                                        </button>
                                    </div>
                                    <mat-error class="col-12 px-0" *ngIf="statusSendQR[i] == 'error'">
                                        <p>Error al enviar el email. Inténtalo de nuevo más tarde</p>
                                    </mat-error>
                                    <p class="message-status success mb-0" *ngIf="statusSendQR[i] == 'success'">El email se ha enviado correctamente.</p>
                                    <p class="message-status block mb-0" *ngIf="statusSendQR[i] == 'blocked'">{{messageBlocked}}</p>
                                </div>
                            </ng-container>

                            <!-- Bonos
                            <ng-container *ngIf="mobile.bonos && mobile.bonos.length">
                                <div class="resumen d-flex justify-content-between align-items-center" *ngFor="let bono of mobile.rate.bonos">
                                    <!- Bono ->
                                    <span class="sidebar-info5">
                                        <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                        {{ bono.rate_info.description }}
                                    </span>
                                    <small class="text-muted">
                                        <!- Precio bono ->
                                        <span class="sidebar-info3">
                                            <b>{{ bono.rate_info?.price|toFixedDecimals }} €/mes</b>
                                        </span>
                                    </small>
                                </div>
                                <br>
                            </ng-container>-->

                            <!-- Terminal -->
                            <ng-container *ngIf="getTerminalByMobile(mobile) as terminal">
                                <div class="resumen d-flex justify-content-between align-items-center">
                                    <!-- terminal -->
                                    <span class="sidebar-info5">
                                        <img src="./assets/img/smartphone2.svg" class="img-fluid" width="13">
                                        {{ terminal.brand }} {{ terminal.model }}
                                        <span *ngIf="terminal.paymentType === 'VAP' || terminal.is_installment == true">
                                            ({{ terminal.dues }} meses)
                                        </span>
                                    </span>
                                    <small class="text-muted">
                                        <!-- Precio terminal -->
                                        <span class="sidebar-info3">
                                            <b *ngIf="terminal.paymentType === 'unico' || terminal.is_installment == false">
                                                Pago único
                                            </b>

                                            <b *ngIf="terminal.paymentType === 'VAP' || terminal.is_installment == true">
                                                {{ terminal.installment|toFixedDecimals }} €/mes
                                            </b>
                                        </span>
                                    </small>
                                </div>
                                <!--<br>-->
                            </ng-container>

                            <div class="resumen d-flexflex-column  justify-content-between align-items-start" *ngIf="showAllInfo">
                                <span class="ms-3 d-block" *ngIf="!mobile?.portability && mobile?.new_msisdn && mobile?.new_msisdn != ''">
                                    <small>Línea Móvil: <strong>{{ mobile?.new_msisdn }}</strong></small>
                                </span>

                                <span class="ms-3 d-block" *ngIf="mobile?.portability && mobile?.msisdn != ''">
                                    <small>Número Portabilidad Móvil: <strong>{{ mobile?.msisdn }}</strong></small>
                                </span>

                                <span class="ms-3 d-block" *ngIf="super_status !== superStatus.draft && mobile.status_description && mobile.status_description != ''">
                                    <small>Estado línea móvil: <strong>{{ mobile.status_description }}</strong></small>
                                </span>

                                <span class="ms-3 d-block" *ngIf="mobile?.new_iccid && mobile?.new_iccid !== ''">
                                    <small>ICCID: <strong>{{ mobile?.new_iccid }}</strong></small>
                                </span>

                                <span class="ms-3 d-block" *ngIf="mobile.portability && mobile.processing_date != '' && mobile.processing_date != undefined ">
                                    <small>Fecha de portabilidad: <strong>{{ mobile.processing_date | date: 'dd/MM/yyyy' : '+1000' }}</strong></small>
                                </span>

                                <span class="ms-3 d-block" *ngIf="mobile.activation_date && mobile.activation_date != ''">
                                    <small>Fecha de activación: <strong>{{ mobile.activation_date | date: 'dd/MM/yyyy' }}</strong></small>
                                </span>
                            </div>

                            <!-- Recordatorio movil y convergente -->
                            <!-- <div class="resumen d-flex justify-content-between align-items-center" *ngIf="(typeOrder === orderType.MOBILE || typeOrder === orderType.CONVERGENT) && mobile.contract_type === 'prepaid' && getRechargePrice(+getDataMobile(mobile, 'rate_info')?.real_price + simyoGlobals.pricesBonos(mobile.rate?.bonos)) > 0"> -->
                            <div class="resumen d-flex justify-content-between align-items-center" *ngIf="(typeOrder === orderType.MOBILE || typeOrder === orderType.CONVERGENT) && mobile.contract_type === 'prepaid' && mobile.recharge &&  mobile.recharge > 0">
                                <!-- Titulo -->
                                <span class="sidebar-info5 text-simyo">
                                    <!-- Recuerde al cliente que debe recargar {{ getRechargePrice(+getDataMobile(mobile, 'rate_info')?.real_price + +simyoGlobals.pricesBonos(mobile.rate.bonos))|toFixedDecimals }}€ para su activación -->
                                    Recuerde al cliente que debe recargar {{ mobile.recharge|toFixedDecimals }}€ para su activación
                                </span>
                            </div>

                            <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                <span class="sidebar-info"><b>Total</b></span>
                                <small class="text-muted trash-carrito">
                                    <!-- Precio tarifa -->
                                    <span class="sidebar-info2">
                                        <b>{{ mobile.total_line|toFixedDecimals }} €/mes</b>
                                    </span>
                                </small>
                            </div>
                        </div>
                        <hr>

                        <!-- Importe Total -->
                        <div class="resumen-total total d-flex justify-content-between align-items-center">
                            <h5 class="total-color"><b>Pago mensual</b></h5>
                            <h5 class="text-auto"><b>{{ totalPriceMonthly|toFixedDecimals }} €/mes</b></h5>
                        </div>
                        <hr>

                        <div *ngIf="totalPriceInitial > 0">
                            <!-- Terminal -->
                            <ng-container *ngIf="mobiles && mobiles.length">
                                <div class="resumen d-flex justify-content-between align-items-center" *ngFor="let mobile of mobiles">
                                    <ng-container *ngIf="getTerminalByMobile(mobile) as terminal">
                                        <!-- Bono -->
                                        <span class="sidebar-info5">
                                            <img src="./assets/img/smartphone2.svg" class="img-fluid" width="13">
                                            {{ terminal.brand }} {{ terminal.model }} {{ (terminal.paymentType === 'unico'  || terminal.is_installment == false) ? '(Pago único)' : '' }}
                                        </span>
                                        <small class="text-muted">
                                            <!-- Precio bono -->
                                            <span class="sidebar-info3" *ngIf="terminal.paymentType === 'VAP' || terminal.is_installment == true">
                                                <b>{{ terminal.upfront|toFixedDecimals }} €</b>
                                            </span>
                                            <span class="sidebar-info3" *ngIf="mobile.rate.terminal?.paymentType === 'unico' || terminal.is_installment == false">
                                                <b>{{ terminal.price|toFixedDecimals }} €</b>
                                            </span>
                                        </small>
                                    </ng-container>
                                </div>
                                <br>
                            </ng-container>


                            <!-- Importe Total -->
                            <div class="resumen-total total d-flex justify-content-between align-items-center">
                                <h5 class="total-color"><b>Pago inicial</b></h5>
                                <h5 class="text-auto"><b>{{ totalPriceInitial|toFixedDecimals }} €</b></h5>
                            </div>
                            <hr>
                        </div>

                        <div class="resumen-total total d-flex justify-content-between align-items-center">
                            <span class="total-color">
                                <b><span>{{getTaxType()}} </span>incluido</b>
                            </span>
                        </div>
                        <hr>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<ng-template #infoBroadband>
    <div class="d-flex flex-column">
        <span>{{ broadband?.substatus?.description }}</span>
    </div>
</ng-template>
