<div class="container p-0" [formGroup]="agreementsFormGroup">
    <div class="area-check">
        <div class="checkbox icheck-primary">
            <input type="checkbox" id="check1" class="accept-check" formControlName="check1"/>
            <label for="check1">
                Recibirás información de descuentos y promociones personalizadas, permitiéndonos el uso de tus datos de tráfico, navegación y localización.
                <a class="cursor-pointer" (click)="openMoreInfo(check1);$event.preventDefault()" title="Más información">
                    <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white" width="20">
                </a>
            </label>
            <mat-error *ngIf="errorHandling('check1', 'required')">
                <small class="ps-4 ms-1">Campo obligatorio.</small>
            </mat-error>
        </div>

        <div class="checkbox icheck-primary">
            <input type="checkbox" id="check2" class="accept-check" formControlName="check2"/>
            <label for="check2">
                Recibirás ofertas que te interesen de compañías con las que colaboramos según tus preferencias.
                <a class="cursor-pointer" (click)="openMoreInfo(check1);$event.preventDefault()" title="Más información">
                    <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white" width="20">
                </a>
            </label>
            <mat-error *ngIf="errorHandling('check2', 'required')">
                <small class="ps-4 ms-1">Campo obligatorio.</small>
            </mat-error>
        </div>

        <div class="checkbox icheck-primary">
            <input type="checkbox" id="check3" class="accept-check" formControlName="check3"/>
            <label for="check3">
                Podrás beneficiarte de los mejores productos de empresas participadas y del Grupo Orange comunicándoles tus datos.
                <a class="cursor-pointer" (click)="openMoreInfo(check1);$event.preventDefault()" title="Más información">
                    <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white" width="20">
                </a>
            </label>
            <mat-error *ngIf="errorHandling('check3', 'required')">
                <small class="ps-4 ms-1">Campo obligatorio.</small>
            </mat-error>
        </div>

        <div class="checkbox icheck-primary">
            <input type="checkbox" id="check4" class="accept-check" formControlName="check4"/>
            <label for="check4">
                Acepto que simyo utilice información de fuentes externas para personalizar las comunicaciones y ofertas según mi perfil comercial.
                <a class="cursor-pointer" (click)="openMoreInfo(check1);$event.preventDefault()" title="Más información">
                    <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white" width="20">
                </a>
            </label>
            <mat-error *ngIf="errorHandling('check4', 'required')">
                <small class="ps-4 ms-1">Campo obligatorio.</small>
            </mat-error>
        </div>

        <div class="checkbox icheck-primary">
            <input type="checkbox" id="check5" class="accept-check" formControlName="check5"/>
            <label for="check5">
                Acepto la comunicación de mis datos a entidades con las que simyo colabora para recibir información comercial adaptada a mis gustos y preferencias.
                <a class="cursor-pointer" (click)="openMoreInfo(check1);$event.preventDefault()" title="Más información">
                    <img src="./assets/img/info.svg" class="img-fluid ico-title icon-white" width="20">
                </a>
            </label>
            <mat-error *ngIf="errorHandling('check5', 'required')">
                <small class="ps-4 ms-1">Campo obligatorio.</small>
            </mat-error>
        </div>
    </div>

</div>

<ng-template #check1 let-modal>
    <div class="modal-header bg-simyo-grey">
        <h5 class="modal-title white" id="exampleModalLabel">Más información</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">
                <img src="./assets/img/times2.svg" class="img-fluid ico-title" width="20">
            </span>
        </button>
    </div>
    <div class="p-5">
        Simyo te enviará ofertas y promociones de sus propios productos y servicios que puedan resultarte de tu interés, en base a la información que disponemos de tus datos generados por tus servicios, como tráfico, navegación, y localización. (P. ej. Conociendo tus hábitos de consumo podemos ofrecerte la tarifa que mejor se adapta a tus necesidades y las ofertas que están más cerca de ti)
    </div>
</ng-template>
